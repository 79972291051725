import { TranslationString } from 'shared/entities/localization';
import { Permission } from 'shared/entities/permissions';

import { ManagerExternals } from '../user';

import { CabinetManagerRoleType, ProjectManagerRoleType } from './server';

export interface IManagerBase {
  name: string;
  email: string;
}

export interface IProjectManagerEntity {
  id: string;
  name: string;
  projectRole: ProjectManagerRoleType;
}

export interface ICabinetManager extends IManagerBase {
  cabinetRole: CabinetManagerRoleType | null;
  projects: IProjectManagerEntity[];
  isRoleChangeable: boolean;
  externalIds: ManagerExternals | null;
}

export interface ICabinetManagerModel extends ICabinetManager {
  linkedInTg: boolean;
  linkedInVb: boolean;
  linkedInVk: boolean;
}

export interface IProjectManager extends IManagerBase {
  projectRole: ProjectManagerRoleType | null;
  isRoleChangeable: boolean;
}

export interface ICabinetManagerEdit {
  name: string;
  email: string;
  password: string;
  role: CabinetManagerRoleType | null;
}

export interface ICabinetManagerAddingEdit {
  email: string;
  role: CabinetManagerRoleType;
}

export interface IProjectManagerAddingEdit {
  email: string;
  role: ProjectManagerRoleType;
  cabinetRole: CabinetManagerRoleType;
  projectId: string;
}

export interface IProjectManagerEdit {
  name: string;
  email: string;
  password: string;
  projectId: string;
  role: ProjectManagerRoleType;
}

export enum CabinetManagerSelectorTitleType {
  email = 'email',
  name = 'name'
}

export interface IManagerRoleEntity<R> {
  role: R;
  name: TranslationString;
  description: TranslationString;
  permissions: Permission[];
}
