import { action, makeObservable, observable, runInAction } from 'mobx';

import { JobKind, JobStatus } from 'shared/entities/jobs';
import JobModel from 'shared/models/job/JobModel';
import { apiUrls } from 'shared/entities/domain';
import { IJobsStore } from 'shared/entities/store/jobsStore';
import { IRootStore } from 'shared/entities/store/rootStore';

import { createJobKey } from './utils';

export default class JobsStore implements IJobsStore {
  rootStore: IRootStore;

  jobs: Map<string, JobModel> = new Map<string, JobModel>();

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      rootStore: observable,
      jobs: observable,
      detach: action,
      startJob: action,
      cancelJob: action
    });
  }

  async startJob({
    kind,
    key,
    params
  }: {
    kind: JobKind;
    key: string;
    params: any;
  }): Promise<JobModel | null> {
    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.JOBS_START,
      {
        method: 'POST',
        data: {
          kind,
          key,
          params
        }
      }
    );

    if (!isError) {
      this.detachByKind({ kind, key });

      const job = new JobModel({
        kind,
        key,
        status: JobStatus.planned,
        rootStore: this.rootStore,
        jobsStore: this
      });

      job.startPolling();

      runInAction(() => {
        this.jobs.set(createJobKey({ key, kind }), job);
      });

      return job;
    }

    return null;
  }

  async cancelJob({
    kind,
    key
  }: {
    kind: JobKind;
    key: string;
  }): Promise<BaseResponse> {
    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.JOBS_CANCEL,
      {
        method: 'POST',
        data: {
          kind,
          key
        }
      }
    );

    if (!isError) {
      const job = this.jobs.get(createJobKey({ key, kind }));

      if (job) {
        runInAction(() => {
          job.updateStatus(JobStatus.cancelling);
        });
      }
    }

    return {
      isError
    };
  }

  async getJob({
    kind,
    key
  }: {
    kind: JobKind;
    key: string;
  }): Promise<JobModel | null> {
    const job = this.jobs.get(createJobKey({ key, kind }));
    if (job) {
      return job;
    }

    const fetchedJob = await JobModel.getJob({
      kind,
      key,
      jobsStore: this,
      rootStore: this.rootStore
    });
    if (fetchedJob) {
      runInAction(() => {
        this.jobs.set(createJobKey({ key, kind }), fetchedJob);
      });
      return fetchedJob;
    }

    return null;
  }

  detachByKind({ kind, key }: { kind: JobKind; key: string | null }): void {
    const job = this.jobs.get(createJobKey({ key, kind }));

    if (job) {
      job.stopPolling();
      this.jobs.delete(createJobKey({ key, kind }));
    }
  }

  detach(): void {
    Array.from(this.jobs.values()).forEach((job) => job.stopPolling());
  }
}
