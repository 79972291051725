import { IAmoCRMBaseAccount } from 'shared/entities/amocrm';
import { generateId } from 'shared/entities/common/utils';

export class AmoCRMBaseAccountModel implements IAmoCRMBaseAccount {
  readonly id: string;
  readonly accountName: string;

  constructor({ id, accountName }: IAmoCRMBaseAccount) {
    this.id = id;
    this.accountName = accountName;
  }

  get settingsLink(): string {
    return `https://${this.accountName}.amocrm.ru/amo-market`;
  }

  get installedIntegrationsLink(): string {
    return `https://${this.accountName}.amocrm.ru/amo-market#category-installed`;
  }

  static fromDefaultParams(accountName: string): AmoCRMBaseAccountModel {
    return new AmoCRMBaseAccountModel({
      accountName,
      id: generateId()
    });
  }
}
