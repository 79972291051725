import * as React from 'react';
import { observer } from 'mobx-react';

import ChannelForm from './ChannelForm';
import Creation from './Creation';

import './CreationContainer.modules.scss';

type Props = {
  showContent?: boolean;
};

const CreationContainer: React.FC<Props> = ({ showContent = true }) => {
  return (
    <div styleName="creation-container">
      {showContent && (
        <>
          <Creation />
          <ChannelForm />
        </>
      )}
    </div>
  );
};

export default observer(CreationContainer);
