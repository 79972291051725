import { IModeProject, IEnabledProjectSetting } from './client';
import { ModeProjectServer, EnabledProjectSettingServer } from './server';

export const normalizeModeProject = (raw: ModeProjectServer): IModeProject => ({
  enabled: raw.enabled,
  in: raw.in
});

export const normalizeEnabledProjectSetting = (
  raw: EnabledProjectSettingServer
): IEnabledProjectSetting => ({
  enabled: raw.enabled,
  for: raw.for
});
