import { validateNumberInInterval, Validator } from 'shared/entities/validator';

export const validateOgrn: Validator<string> = (value) => {
  return /^(\d{13}|\d{15})$/.test(value)
    ? null
    : (t) => t('cabinet.errors.requisites.ogrn', { ns: 'entities' });
};

export const validateInn: Validator<string> = (value) => {
  return /^(\d{10}|\d{12})$/.test(value)
    ? null
    : (t) => t('cabinet.errors.requisites.inn', { ns: 'entities' });
};

export const validateKpp: Validator<string> = (value) => {
  if (!value) {
    return null;
  }

  return /^\d{9}$/.test(value)
    ? null
    : (t) => t('cabinet.errors.requisites.kpp', { ns: 'entities' });
};

export const validateBalance: Validator<number> = (value) => {
  return validateNumberInInterval({ value, min: 0, max: 1000000 })
    ? null
    : (t) => t('cabinet.errors.requisites.balance', { ns: 'entities' });
};
