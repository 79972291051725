import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import {
  IconSize,
  CommonIconProps,
  LogoIconProps
} from 'shared/entities/components/Icon';

const ViberIcon: React.FC<LogoIconProps> = ({ color, ...rest }) => {
  const fill = color === 'original' ? '#834995' : 'currentColor';

  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{ viewBox: '0 0 32 32' }}
      {...rest}
    >
      <g clipPath="url(#clip0_1435_95770)">
        <path
          d="M28.3434 17.9318C27.5559 24.4362 22.9109 24.8425 22.0496 25.1268C21.684 25.2487 18.2977 26.1093 14.0346 25.825L9.42211 30.9412L9.41399 25.0456L9.38149 25.0375C3.17774 23.275 3.38899 16.6568 3.45399 13.1893C3.52711 9.72184 4.16024 6.87121 6.05274 4.96309C9.44649 1.81246 16.4546 2.28371 16.4546 2.28371C22.3665 2.30809 25.2002 4.13496 25.8584 4.74434C28.0427 6.65246 29.1552 11.2325 28.3434 17.9318ZM27.4746 3.11996C26.6952 2.38934 23.5602 0.0587143 16.5684 0.0262143C16.5684 0.0262143 8.32586 -0.485661 4.30649 3.29059C2.07336 5.58059 1.28586 8.93434 1.19649 13.0925C1.11524 17.25 1.00961 25.0375 8.34211 27.1487H8.35024V30.3725C8.35024 30.3725 8.30149 31.68 9.14586 31.94C10.1609 32.265 10.754 31.2743 11.7284 30.2018C12.2646 29.6175 12.9952 28.7562 13.5471 28.0987C18.5659 28.5293 22.4227 27.5381 22.8534 27.4006C23.869 27.0593 29.594 26.3125 30.5277 18.525C31.4777 10.485 30.049 5.40996 27.4746 3.11996Z"
          fill={fill}
        />
        <path
          d="M23.0001 18.9061C23.0001 18.9061 22.0582 18.1023 21.6032 17.7611C21.1726 17.4361 20.4826 16.998 20.1007 16.7786C19.4107 16.3886 18.7038 16.6323 18.4113 17.0223L17.8026 17.8017C17.4938 18.1998 17.0151 18.1267 16.8932 18.1023C16.0001 17.8748 15.0501 17.323 14.0751 16.3805C13.0926 15.4305 12.4913 14.4398 12.2807 13.4086L12.2644 13.3436C12.2076 12.9211 12.2807 12.7267 12.4513 12.4342L12.4838 12.4098C12.8901 12.028 13.6532 11.5655 13.7507 11.1267C14.0513 10.3636 12.7519 8.76359 12.5732 8.48734C12.5732 8.48734 11.6313 7.24484 11.2982 7.00109C10.9407 6.70047 10.3807 6.52984 9.81193 6.87922L9.77943 6.90359C8.15506 7.99984 7.57068 8.63359 7.73318 9.56734L7.81443 9.94922C8.63443 12.5398 10.2263 15.3005 12.5894 17.7611C14.9282 20.1892 17.4619 21.643 19.9551 22.6498C20.6044 22.8692 21.2788 22.6823 21.9688 22.0892L21.9769 22.0811C22.5051 21.5855 22.9269 21.058 23.2357 20.4892V20.4811C23.5519 19.8561 23.4463 19.288 23.0001 18.9061Z"
          fill={fill}
        />
        <path
          d="M19.1182 13.2301C19.0207 13.2301 18.9394 13.1488 18.9313 13.0513C18.8988 12.3776 18.7201 11.8657 18.3951 11.5244C18.0701 11.1832 17.5913 10.9963 16.9576 10.9557C16.8519 10.9476 16.7788 10.8582 16.7788 10.7526C16.7869 10.6469 16.8682 10.5657 16.9738 10.5738C17.7044 10.6144 18.2651 10.8419 18.6551 11.2557C19.0451 11.6701 19.2638 12.2626 19.2963 13.0338C19.3044 13.1394 19.2232 13.2288 19.1176 13.2369"
          fill={fill}
        />
        <path
          d="M20.9617 13.8636C20.8561 13.8636 20.7749 13.7743 20.7749 13.6686C20.8074 12.3774 20.4255 11.2893 19.6542 10.4286C19.2724 9.99802 18.8017 9.6574 18.2655 9.42177C17.7292 9.17802 17.0961 9.03177 16.4061 8.98302C16.3005 8.9749 16.2274 8.88552 16.2355 8.7799C16.2436 8.67427 16.333 8.59302 16.4386 8.60115C17.9249 8.7149 19.1024 9.2424 19.9386 10.1768C20.7749 11.1105 21.1892 12.2961 21.1567 13.6849C21.1486 13.7824 21.0674 13.8636 20.9617 13.8636Z"
          fill={fill}
        />
        <path
          d="M22.8541 14.6268C22.7484 14.6268 22.6672 14.5455 22.6672 14.4399C22.6591 13.303 22.4884 12.2718 22.1553 11.3786C21.8222 10.4936 21.3272 9.71363 20.6772 9.05613C20.0278 8.40676 19.2803 7.91113 18.4766 7.57801C17.6647 7.24488 16.7628 7.07426 15.7884 7.07426C15.6828 7.07426 15.6016 6.98488 15.6016 6.87926C15.6016 6.77363 15.6828 6.69238 15.7884 6.69238C17.8347 6.70863 19.5647 7.40676 20.9372 8.77926C21.6272 9.46926 22.1553 10.298 22.5047 11.2399C22.8541 12.1736 23.0328 13.2536 23.0409 14.4393C23.0409 14.5374 22.9597 14.6186 22.8541 14.6268Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1435_95770">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export const ViberOriginalIcon: React.FC<CommonIconProps> = (props) => (
  <ViberIcon color="original" {...props} />
);

export const ViberTransparentIcon: React.FC<CommonIconProps> = (props) => (
  <ViberIcon color="transparent" {...props} />
);
