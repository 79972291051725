import * as React from 'react';
import { observer } from 'mobx-react';

import { NutIcon } from 'shared/newComponents/icons';
import Avatar from 'shared/newComponents/user/Avatar';
import {
  AvatarSize,
  AvatarType
} from 'shared/newEntities/components/user/Avatar';
import { useVKCreationStore } from 'stores/VKCreationStore';
import {
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';

import './Trigger.modules.scss';

const Trigger: React.FC = () => {
  const vkCreationStore = useVKCreationStore();

  return (
    <div styleName="trigger">
      <div styleName="trigger__left">
        {vkCreationStore.vkAccountName !== null && (
          <Avatar
            size={AvatarSize.xs}
            type={AvatarType.name}
            styleName="trigger__icon"
            content={vkCreationStore.vkAccountName}
          />
        )}
        <Typography
          fontType={TypographyType.text}
          fontWeight={TypographyWeight.heavy}
          fontSize={TypographySize.m}
          styleName="trigger__title"
        >
          {!vkCreationStore.vkAccount
            ? (t) =>
                t('ChannelsModal.ChannelForm.VK.VKAccount.notConnected', {
                  ns: 'newContainers'
                })
            : vkCreationStore.vkAccountShortName}
        </Typography>
      </div>
      {!vkCreationStore.vkAccount ? null : (
        <NutIcon styleName="trigger__nut-icon" />
      )}
    </div>
  );
};

export default observer(Trigger);
