import * as React from 'react';

import { noop } from 'shared/entities/common/utils';

type Props = {
  type: string;
  onClick?: (type: string) => void;
  comp: React.ElementType<{ className?: string; onClick?: () => void }>;
  className?: string;
};

const RightOption: React.FC<Props> = ({
  type,
  onClick = noop,
  comp: Comp,
  className
}: Props) => {
  const handleClick = React.useCallback(() => {
    onClick(type);
  }, []);

  return (
    <div className={className} onClick={handleClick}>
      <Comp />
    </div>
  );
};

export default React.memo(RightOption);
