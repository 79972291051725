import { computed, makeObservable } from 'mobx';

import { FieldModel } from 'shared/models/form';
import { validateIsEmpty } from 'shared/entities/validator';
import { TelegramPaymentKind } from 'shared/entities/telegramPayments';

export class TgPaymentsCabinetPhysicalProductsParams {
  readonly token: FieldModel = new FieldModel<string>('', [validateIsEmpty]);
  readonly channelId: FieldModel<string | null> = new FieldModel<string | null>(
    null,
    [validateIsEmpty]
  );
  readonly kind: FieldModel<TelegramPaymentKind | null> =
    new FieldModel<TelegramPaymentKind | null>(null, [validateIsEmpty]);

  constructor() {
    makeObservable(this, {
      creatingDisabled: computed
    });
  }

  get creatingDisabled(): boolean {
    return (
      this.token.isError ||
      this.channelId.isError ||
      this.kind.isError ||
      !this.token.value ||
      !this.channelId.value ||
      !this.kind.value
    );
  }

  validate() {
    this.token.validate();
    this.channelId.validate();
    this.kind.validate();
  }

  toJson() {
    return {
      token: this.token.value,
      channel_id: this.channelId.value,
      payment_provider_kind: this.kind.value
    };
  }
}
