import * as React from 'react';

import { IconSize } from 'shared/entities/components/Icon';
import { SelectorTopItem } from 'shared/newEntities/components/Selector';
import {
  TypographyWeight,
  TypographySize,
  TypographyType
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';

import './TopItem.modules.scss';

type Props = SelectorTopItem & {
  onClose: () => void;
};

const TopItem: React.FC<Props> = ({ Icon, title, onClick, onClose }) => {
  const handleClick = React.useCallback(() => {
    onClick(onClose);
  }, [onClick, onClose]);

  return (
    <div styleName="top-item-container">
      <div styleName="top-item" onClick={handleClick}>
        <Icon styleName="top-item__icon" iconSize={IconSize.XM} />
        <Typography
          fontType={TypographyType.text}
          fontSize={TypographySize.m}
          fontWeight={TypographyWeight.semiBold}
          fontColor={null}
        >
          {title}
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(TopItem);
