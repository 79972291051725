import { action, makeObservable, observable } from 'mobx';

import BaseEditStore from 'stores/editStore/BaseEditStore';
import { validateTextField, Validator } from 'shared/entities/validator';
import { ScenariosStore } from 'stores/scenariosStore';
import { IScenarioEdit } from 'shared/entities/scenario';
import { LoadingStage } from 'shared/entities/meta';

import ScenarioCommonModel from './ScenarioCommonModel';
import ScenarioEditModel from './ScenarioEditModel';

export default class ScenarioEditStore extends BaseEditStore<ScenarioEditModel> {
  scenariosStore: ScenariosStore;

  validators: Record<keyof Omit<IScenarioEdit, 'id'>, Validator<any>[]> = {
    name: [validateTextField],
    channelIds: [],
    kind: []
  };

  constructor({
    entity = new ScenarioEditModel(),
    scenariosStore
  }: {
    entity?: ScenarioEditModel;
    scenariosStore: ScenariosStore;
  }) {
    super({ initialData: entity });

    this.scenariosStore = scenariosStore;

    makeObservable(this, {
      scenariosStore: observable,
      create: action.bound,
      update: action.bound
    });
  }

  async create(): Promise<{
    stage: LoadingStage;
    scenario: ScenarioCommonModel | null;
  }> {
    const editScenarioModel = this.save();

    if (!editScenarioModel) {
      return { stage: LoadingStage.NOT_STARTED, scenario: null };
    }

    return this.scenariosStore.create(editScenarioModel);
  }

  async update(): Promise<BaseResponse> {
    const editScenarioModel = this.save();

    if (!editScenarioModel) {
      return { isError: true };
    }

    return this.scenariosStore.update(editScenarioModel);
  }

  async copy(): Promise<BaseResponse<ScenarioCommonModel>> {
    const editScenarioModel = this.save();

    if (!editScenarioModel) {
      return { isError: true };
    }

    return this.scenariosStore.copy(editScenarioModel);
  }
}
