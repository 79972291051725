import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const BookIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      {...props}
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
    >
      <path
        d="M20.2575 3C18.7655 3 17.3102 3.46672 15.8991 3.91874C14.543 4.35605 13.2604 4.76765 12.0036 4.76765C10.7468 4.76765 9.46423 4.35605 8.10817 3.91874C6.70067 3.46672 5.24172 3 3.74969 3C3.33442 3 3 3.33442 3 3.74969V18.4862C3 18.9015 3.33442 19.2359 3.74969 19.2359C5.00652 19.2359 6.28908 19.6475 7.64513 20.0848C9.05263 20.5368 10.5079 21.0036 11.9999 21.0036C13.492 21.0036 14.9472 20.5368 16.3548 20.0848C17.7108 19.6475 18.9934 19.2359 20.2502 19.2359C20.6655 19.2359 20.9999 18.9015 20.9999 18.4862V3.74969C21.0072 3.33442 20.6728 3 20.2575 3V3ZM4.49938 17.777V4.54715C5.51734 4.66475 6.55735 4.99917 7.64513 5.35196C8.81744 5.72681 10.0191 6.11635 11.2503 6.23395V19.4638C10.2323 19.3462 9.19228 19.0117 8.1045 18.6626C6.93587 18.2804 5.73048 17.8946 4.49938 17.777V17.777ZM19.5079 17.777C18.2767 17.8982 17.0714 18.2841 15.9027 18.6589C14.8149 19.0081 13.7749 19.3425 12.757 19.4601V6.23028C13.9881 6.109 15.1935 5.72313 16.3621 5.34829C17.4499 4.99917 18.4899 4.66475 19.5079 4.54348V17.777V17.777Z"
        fill="currentColor"
      />
      <path
        d="M9.34234 7.8874L6.8397 7.13771C6.44281 7.02012 6.02387 7.24429 5.90627 7.64118C5.78867 8.03808 6.01284 8.45702 6.40974 8.57462C6.40974 8.57462 9.05569 9.35738 9.12552 9.35738C9.44891 9.35738 9.74658 9.14791 9.84213 8.82084C9.9634 8.42762 9.73923 8.00868 9.34234 7.8874Z"
        fill="currentColor"
      />
      <path
        d="M9.34234 11.6579L6.8397 10.9082C6.44281 10.7906 6.02387 11.0148 5.90627 11.4117C5.78867 11.8086 6.01284 12.2275 6.40974 12.3451C6.40974 12.3451 9.05569 13.1279 9.12552 13.1279C9.44891 13.1279 9.74658 12.9184 9.84213 12.5913C9.9634 12.1981 9.73923 11.7792 9.34234 11.6579V11.6579Z"
        fill="currentColor"
      />
      <path
        d="M9.34234 15.4333L6.8397 14.6836C6.44281 14.566 6.02387 14.7902 5.90627 15.1871C5.78867 15.584 6.01284 16.0029 6.40974 16.1205C6.40974 16.1205 9.05569 16.9033 9.12552 16.9033C9.44891 16.9033 9.74658 16.6938 9.84213 16.3667C9.9634 15.9698 9.73923 15.5509 9.34234 15.4333Z"
        fill="currentColor"
      />
      <path
        d="M14.1602 8.82084C14.2594 9.14791 14.5571 9.35738 14.8768 9.35738C14.9466 9.35738 17.5926 8.57462 17.5926 8.57462C17.9895 8.45702 18.2136 8.03808 18.096 7.64118C17.9748 7.24429 17.5595 7.02012 17.1626 7.13771L14.6636 7.8874C14.2667 8.00868 14.0426 8.42762 14.1602 8.82084V8.82084Z"
        fill="currentColor"
      />
      <path
        d="M17.1663 10.9079L14.6636 11.6576C14.2667 11.7752 14.0426 12.1941 14.1602 12.591C14.2594 12.9181 14.5571 13.1275 14.8768 13.1275C14.9466 13.1275 17.5926 12.3448 17.5926 12.3448C17.9895 12.2272 18.2136 11.8082 18.096 11.4113C17.9784 11.0144 17.5632 10.7939 17.1663 10.9079V10.9079Z"
        fill="currentColor"
      />
      <path
        d="M17.1663 14.6836L14.6636 15.4333C14.2667 15.5509 14.0426 15.9698 14.1602 16.3667C14.2594 16.6938 14.5571 16.9033 14.8768 16.9033C14.9466 16.9033 17.5926 16.1205 17.5926 16.1205C17.9895 16.0029 18.2136 15.584 18.096 15.1871C17.9784 14.7902 17.5632 14.566 17.1663 14.6836Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BookIcon;
