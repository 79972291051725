import {
  ScenarioBaseParamsServer,
  ScenarioBaseServer,
  ScenarioCommonServer,
  ScenarioDataServer,
  ScenarioKind,
  ScenarioReactionServer
} from './server';
import {
  IScenarioBase,
  IScenarioCommon,
  IScenarioParams,
  IScenarioReaction,
  IScenarioData
} from './client';

export const normalizeScenarioBase = (
  raw: ScenarioBaseServer
): IScenarioBase => ({
  id: raw._id,
  name: raw.name
});

export const normalizeScenarioBaseParamsServer = (
  raw: ScenarioBaseParamsServer
): Omit<IScenarioParams, 'channelIds'> & { channelIds: string[] | null } => ({
  ...normalizeScenarioBase(raw),
  updateId: raw.update_id,
  channelIds: raw.channel_ids,
  hasProdVersion: raw.has_been_published,
  hasChanges: raw.has_changes,
  prodEnabled: raw.enabled_in.prod,
  devEnabled: raw.enabled_in.dev,
  kind: raw.kind,
  dateUpdated: new Date(raw.date_updated),
  dateCreated: new Date(raw.date_created),
  tags: raw.tags || null,
  preset: raw.preset || null
});

export const normalizeScenarioCommonServer = (
  raw: ScenarioCommonServer
): Omit<IScenarioCommon, 'template' | 'channelIds'> & {
  channelIds: string[] | null;
} => ({
  ...normalizeScenarioBaseParamsServer(raw),
  kind: ScenarioKind.common
});

export const normalizeScenarioReactionServer = (
  raw: ScenarioReactionServer
): Omit<IScenarioReaction, 'channelIds'> & { channelIds: string[] | null } => ({
  ...normalizeScenarioBaseParamsServer(raw),
  kind: ScenarioKind.reaction,
  reaction: raw.reaction
});

export const normalizeScenarioData = (
  raw: ScenarioDataServer
): IScenarioData => ({
  id: raw._id,
  name: raw.name,
  blockId: raw.block_id
});
