import {
  DocumentServer,
  PaymentDocumentKind,
  PaymentDocumentSubject
} from 'shared/entities/documents';

import { DocumentModelType } from './types';
import { ServiceInvoiceDocumentModel } from './ServiceInvoiceDocumentModel';
import { SetupInvoiceDocumentModel } from './SetupInvoiceDocumentModel';
import { ServiceAndSetupInvoiceDocumentModel } from './ServiceAndSetupInvoiceDocumentModel';
import { ServiceActDocumentModel } from './ServiceActDocumentModel';
import { SetupActDocumentModel } from './SetupActDocumentModel';
import { ServiceAndSetupActDocumentModel } from './ServiceAndSetupActDocumentModel';
import { SupAgreementDocumentModel } from './SupAgreementDocumentModel';
import { OtherDocumentModel } from './OtherDocumentModel';
import { ServiceSFDocumentModel } from './ServiceSFDocumentModel';
import { SetupSFDocumentModel } from './SetupSFDocumentModel';

export const getDocumentModelFromRaw = (
  raw: DocumentServer,
  cabinetId: string
): DocumentModelType | null => {
  switch (raw.kind) {
    case PaymentDocumentKind.invoice: {
      if (raw.subject === PaymentDocumentSubject.service) {
        return ServiceInvoiceDocumentModel.fromJson(raw, cabinetId);
      }
      if (raw.subject === PaymentDocumentSubject.setup) {
        return SetupInvoiceDocumentModel.fromJson(raw, cabinetId);
      }
      if (raw.subject === PaymentDocumentSubject.serviceAndSetup) {
        return ServiceAndSetupInvoiceDocumentModel.fromJson(raw, cabinetId);
      }

      return null;
    }
    case PaymentDocumentKind.act: {
      if (raw.subject === PaymentDocumentSubject.service) {
        return ServiceActDocumentModel.fromJson(raw, cabinetId);
      }
      if (raw.subject === PaymentDocumentSubject.setup) {
        return SetupActDocumentModel.fromJson(raw, cabinetId);
      }
      if (raw.subject === PaymentDocumentSubject.serviceAndSetup) {
        return ServiceAndSetupActDocumentModel.fromJson(raw, cabinetId);
      }

      return null;
    }
    case PaymentDocumentKind.suppAgreement: {
      if (raw.subject === PaymentDocumentSubject.setup) {
        return SupAgreementDocumentModel.fromJson(raw, cabinetId);
      }

      return null;
    }
    case PaymentDocumentKind.other: {
      if (raw.subject === PaymentDocumentSubject.other) {
        return OtherDocumentModel.fromJson(raw, cabinetId);
      }

      return null;
    }
    case PaymentDocumentKind.sf: {
      if (raw.subject === PaymentDocumentSubject.service) {
        return ServiceSFDocumentModel.fromJson(raw, cabinetId);
      }
      if (raw.subject === PaymentDocumentSubject.setup) {
        return SetupSFDocumentModel.fromJson(raw, cabinetId);
      }

      return null;
    }
  }
};
