import { AxiosRequestConfig, Method } from 'axios';

import { IRootStore } from 'shared/entities/store/rootStore';

import { matchSmartIdsToSmartHeaders, SmartIds } from '../client';

export const createSmartHeader = (
  smartId: SmartIds,
  value: string
): Record<string, string> => {
  return {
    [matchSmartIdsToSmartHeaders[smartId]]: value
  };
};

export const transformData = (
  data: Record<string, any> = {}
): { data: Record<string, any>; headers: Record<string, any> } => {
  const dataKeys: Array<string> = Object.keys(data);
  let resData = {};
  let resHeaders = {};

  dataKeys.forEach((key: string | SmartIds) => {
    if (key in SmartIds) {
      resHeaders = Object.assign(
        resHeaders,
        createSmartHeader(key as SmartIds, data[key])
      );
    } else {
      resData = Object.assign(resData, { [key]: data[key] });
    }
  });

  return {
    data: resData,
    headers: resHeaders
  };
};

export const configApiCall = (
  methodType: Method,
  rawData: Record<string, any>,
  config: AxiosRequestConfig,
  multipartFormData: boolean
): AxiosRequestConfig => {
  // eslint-disable-next-line prefer-const
  let { data, headers } = transformData(rawData);

  config.headers = Object.assign(config.headers || {}, headers);

  if (multipartFormData) {
    config.headers['Content-Type'] = 'multipart/form-data';

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, value);
      }
    });

    data = formData;
  }

  if (methodType === 'GET') {
    config.params = data;
  } else {
    if (config.data === null || config.data === undefined) {
      config.data = data;
    }
  }

  return config;
};

export const createShopbackData = (
  rootStore: IRootStore,
  data: ({ shopId?: string } & Record<string, any>) | undefined
): Record<string, any> => {
  if (data?.shopId) {
    const { shopId, ...rest } = data;
    return {
      ...rest,
      [SmartIds.shopback]: rootStore.shopbackStore.shopbackTokenFromLS,
      [SmartIds.shopbackProject]: shopId
    };
  }
  return {
    ...data,
    [SmartIds.shopback]: rootStore.shopbackStore.shopbackTokenFromLS
  };
};
