import { action, makeObservable, observable } from 'mobx';

import { IOpenState } from 'shared/entities/state';

export default class OpenStateModel implements IOpenState {
  opened: boolean;

  constructor(opened = false) {
    this.opened = opened;

    makeObservable(this, {
      opened: observable,

      close: action,
      toggle: action,
      open: action
    });
  }

  close = (): void => {
    this.opened = false;
  };

  open = (): void => {
    this.opened = true;
  };

  toggle = (): void => {
    this.opened = !this.opened;
  };
}
