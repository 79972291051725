import { action, makeObservable, observable, runInAction } from 'mobx';

import {
  IVerifiedDomainBase,
  normalizeDomainBase,
  DomainBaseServer
} from 'shared/entities/verifiedDomains';
import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStage } from 'shared/entities/meta';
import { apiUrls } from 'shared/entities/domain';
import { AppNotificationType } from 'shared/entities/appNotifications';

export default class VerifiedDomainBaseModel implements IVerifiedDomainBase {
  id: string;
  projectId: string;
  domain: string;
  dateCreated: Date;

  infoIsOpen: boolean;

  rootStore: IRootStore;

  removingStage: LoadingStage = LoadingStage.NOT_STARTED;

  ref: HTMLDivElement | null = null;

  constructor(
    { id, projectId, domain, dateCreated }: IVerifiedDomainBase,
    rootStore: IRootStore
  ) {
    this.id = id;
    this.projectId = projectId;
    this.domain = domain;
    this.dateCreated = dateCreated;
    this.rootStore = rootStore;

    makeObservable(this, {
      infoIsOpen: observable,
      removingStage: observable,
      ref: observable.ref,

      toggleInfoIsOpen: action,
      remove: action,
      changeRef: action
    });
  }

  isEqual = (value: string): boolean => {
    return (
      this.domain === value ||
      `http://${this.domain}` === value ||
      `https://${this.domain}` === value
    );
  };

  changeRef = (value: HTMLDivElement | null): void => {
    this.ref = value;
  };

  async remove(): Promise<BaseResponse> {
    if (this.removingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this.removingStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api(
      apiUrls.VERIFIED_DOMAINS_DELETE,
      {
        method: 'POST',
        data: {
          _id: this.id
        }
      }
    );

    if (response.isError) {
      runInAction(() => {
        this.removingStage = LoadingStage.ERROR;
      });

      return {
        isError: true
      };
    }

    this.rootStore.appNotificationsStore.open({
      type: AppNotificationType.success,
      title: (t) =>
        t(
          'domain.VerifiedDomainBaseModel.notifications.domainSuccessfullyRemoved',
          {
            ns: 'models'
          }
        )
    });

    return {
      isError: false
    };
  }

  toggleInfoIsOpen = (): void => {
    this.infoIsOpen = !this.infoIsOpen;
  };

  static fromJson(
    raw: DomainBaseServer,
    rootStore: IRootStore
  ): VerifiedDomainBaseModel {
    return new VerifiedDomainBaseModel(normalizeDomainBase(raw), rootStore);
  }
}
