import * as React from 'react';
import { observer } from 'mobx-react';

import { ApiCallErrorRaw } from 'shared/entities/network';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import { useSupportPopupStore } from 'stores/index';

import './NetworkErrorMessageProps.modules.scss';

export type NetworkErrorMessageProps = {
  url: string;
  error: {
    status: number;
  } & Omit<ApiCallErrorRaw<any, any>, 'status'>;
};

const NetworkErrorMessage: React.FC<NetworkErrorMessageProps> = ({
  url,
  error
}) => {
  const supportPopupStore = useSupportPopupStore();
  return (
    <TypedTransComponent ns="stores" i18nKey="NetworkStore.notifications.base">
      <b>URL</b>: {{ url }}
      <br />
      <br />
      <b>Код</b>: {{ code: error.code || error.status }}
      {!!error.message && (
        <>
          <br />
          <br />
          <b>Сообщение</b>: {{ message: error.message }}
        </>
      )}
      {!!JSON.stringify(error.data) && (
        <>
          <br /> <br />
          <b>Данные</b>:{' '}
          <div styleName="data">{{ data: JSON.stringify(error.data) }}</div>
        </>
      )}
      <br />
      <br />
      Сделайте скриншот с этой ошибкой и напишите нам в{' '}
      <span className="link-new" onClick={supportPopupStore.openChat}>
        поддержку
      </span>
    </TypedTransComponent>
  );
};

export default observer(NetworkErrorMessage);
