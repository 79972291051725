import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const GraphIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M6.94624 22H4.03106C2.90801 22 2 21.092 2 19.9689V17.0298C2 15.9068 2.90801 14.9988 4.03106 14.9988H6.94624C8.0693 14.9988 8.9773 15.9068 8.9773 17.0298V19.945C9.0012 21.0681 8.0693 22 6.94624 22ZM4.03106 15.9546C3.43369 15.9546 2.95579 16.4325 2.95579 17.0298V19.945C2.95579 20.5424 3.43369 21.0203 4.03106 21.0203H6.94624C7.54361 21.0203 8.02151 20.5424 8.02151 19.945V17.0298C8.02151 16.4325 7.54361 15.9546 6.94624 15.9546H4.03106Z"
        fill="currentColor"
      />
      <path
        d="M19.9453 22H17.0301C15.907 22 14.999 21.092 14.999 19.9689V17.0298C14.999 15.9068 15.907 14.9988 17.0301 14.9988H19.9453C21.0683 14.9988 21.9763 15.9068 21.9763 17.0298V19.945C22.0002 21.0681 21.0683 22 19.9453 22ZM17.0301 15.9546C16.4327 15.9546 15.9548 16.4325 15.9548 17.0298V19.945C15.9548 20.5424 16.4327 21.0203 17.0301 21.0203H19.9453C20.5426 21.0203 21.0205 20.5424 21.0205 19.945V17.0298C21.0205 16.4325 20.5426 15.9546 19.9453 15.9546H17.0301Z"
        fill="currentColor"
      />
      <path
        d="M13.3022 9.00119H10.387C9.26396 9.00119 8.35596 8.09319 8.35596 6.97013V4.03106C8.35596 2.908 9.26396 2 10.387 2H13.3022C14.4253 2 15.3333 2.908 15.3333 4.03106V6.94624C15.3333 8.0693 14.4253 9.00119 13.3022 9.00119ZM10.387 2.95579C9.78965 2.95579 9.31175 3.43369 9.31175 4.03106V6.94624C9.31175 7.54361 9.78965 8.02151 10.387 8.02151H13.3022C13.8996 8.02151 14.3775 7.54361 14.3775 6.94624V4.03106C14.3775 3.43369 13.8996 2.95579 13.3022 2.95579H10.387Z"
        fill="currentColor"
      />
      <path
        d="M12.4421 8.52319H11.4863V12.1791H12.4421V8.52319Z"
        fill="currentColor"
      />
      <path
        d="M18.0095 15.4767L17.9618 13.8757C17.9379 13.0872 17.3166 12.4659 16.5281 12.4659H7.40023C6.6117 12.4659 5.96654 13.1111 5.96654 13.8996V15.4767H5.01074V13.8996C5.01074 12.5854 6.08601 11.5101 7.40023 11.5101H16.5281C17.8423 11.5101 18.8937 12.5376 18.9176 13.8518L18.9653 15.4528L18.0095 15.4767Z"
        fill="currentColor"
      />
      <path
        d="M12.4421 8.66663H11.4863V12.1792H12.4421V8.66663Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default GraphIcon;
