const splitArray = <T>(arr: T[], maxElements: number): T[][] => {
  const resultArrayLength = Math.ceil(arr.length / maxElements);
  const resultArray: any[][] = [];

  for (let i = 0; i < resultArrayLength; i++) {
    resultArray.push(arr.slice(i * maxElements, (i + 1) * maxElements));
  }

  return resultArray;
};

export default splitArray;
