import { observer } from 'mobx-react';
import * as React from 'react';

import { TranslationNode } from 'shared/entities/localization';
import { useTranslationsStore } from 'stores/index';

type Props = {
  children: TranslationNode;
};
const TransComp: React.FC<Props> = ({ children }) => {
  const store = useTranslationsStore();
  if (typeof children === 'function') {
    return <>{children(store.localization.t)}</>;
  }

  return <>{children}</>;
};

export default observer(TransComp);
