import * as query from 'query-string';
import { StringifyOptions, ParseOptions } from 'query-string';

import { DOMAIN, PORT, ReservedDomain, PROTOCOL_STRING } from './config';

export const getSubDomain = (): string | null => {
  const domains = document.domain.split('.');

  return domains.length > DOMAIN.split('.').length ? domains[0] : null;
};

export const getIsMainDomain = (): boolean => getSubDomain() === null;

export const getIsAuthDomain = (): boolean =>
  getSubDomain() === ReservedDomain.auth;

export const createLocation = (
  { subDomain, url }: { subDomain?: string | null; url: string } = {
    url: ''
  }
): string => {
  const domainPart = subDomain ? `${subDomain}.${DOMAIN}` : DOMAIN;

  return `${PROTOCOL_STRING}${domainPart}${PORT ? `:${PORT}` : ''}${url}`;
};

export const getUrlFromPathAndParamsObject = (
  path: string,
  params: Record<string, any>
): string => {
  const paramsString = getParamsStringFromParamsObject(params);

  return `${path}${paramsString}`;
};

export const getParamsStringFromParamsObject = (
  params: Record<string, any>
): string => {
  const string = stringifyParams(params);

  return string.length ? `?${string}` : '';
};

export const stringifyParams = (
  params: Record<string, any>,
  options: StringifyOptions = {}
): string => {
  return query.stringify(params, {
    arrayFormat: 'none',
    ...options
  });
};

export const parseParams = <
  T extends Record<string, any> = Record<string, any>
>(
  paramString: string,
  options: ParseOptions = {}
): T => {
  return query.parse(paramString, {
    arrayFormat: 'none',
    ...options
  }) as T;
};
