import { Subscription } from 'centrifuge/build/subscription';

import { FieldModel } from 'shared/models/form';
import {
  PubSubWsEvent,
  WsSubscriptionEvent,
  WsSubscriptionListener,
  WsSubscriptionState
} from 'shared/entities/ws';

export class WsSubscription {
  readonly channel: string;
  readonly state: FieldModel<WsSubscriptionState> =
    new FieldModel<WsSubscriptionState>(WsSubscriptionState.unsubscribed);
  private subscription: Subscription;
  readonly subscribedToStateEvents: FieldModel<boolean> =
    new FieldModel<boolean>(false);
  // флаг для обозначения подписки, которую необходимо удалить
  // удаление должно происходить только после того, как state = unsubscribed
  readonly deleted: FieldModel<boolean> = new FieldModel<boolean>(false);
  readonly listeners: WsSubscriptionListener[] = [];

  constructor({
    channel,
    subscription,
    listeners = []
  }: {
    channel: string;
    subscription: Subscription;
    listeners?: WsSubscriptionListener[];
  }) {
    this.channel = channel;
    this.subscription = subscription;
    this.listeners = listeners;

    this.handleSubscribingState = this.handleSubscribingState.bind(this);
    this.handleSubscribedState = this.handleSubscribedState.bind(this);
    this.handleUnsubscribedState = this.handleUnsubscribedState.bind(this);
    this.handlePublicationEvent = this.handlePublicationEvent.bind(this);
  }

  subscribe(): void {
    if (this.state.value === WsSubscriptionState.subscribed) {
      return;
    }

    this.subscribeToStateEvents();
    this.subscription.subscribe();
  }

  unsubscribe(): void {
    this.subscription.unsubscribe();
  }

  requestToDelete(): void {
    this.deleted.changeValue(true);
    this.unsubscribe();
  }

  addListeners(listeners: WsSubscriptionListener[]): void {
    listeners.forEach((listener) => {
      this.addListener(listener);
    });
  }

  addListener(listener: WsSubscriptionListener): void {
    if (this.listeners.includes(listener)) {
      return;
    }

    this.listeners.push(listener);
  }

  private subscribeToStateEvents(): BaseResponse {
    if (this.subscribedToStateEvents.value) {
      return {
        isError: true
      };
    }

    this.subscription.on(
      WsSubscriptionState.subscribing,
      this.handleSubscribingState
    );

    this.subscription.on(
      WsSubscriptionState.subscribed,
      this.handleSubscribedState
    );

    this.subscription.on(
      WsSubscriptionState.unsubscribed,
      this.handleUnsubscribedState
    );

    this.subscription.on(
      WsSubscriptionEvent.publication,
      this.handlePublicationEvent
    );

    this.subscribedToStateEvents.changeValue(true);

    return {
      isError: false
    };
  }

  private handleSubscribingState() {
    this.state.changeValue(WsSubscriptionState.subscribing);
  }

  private handleSubscribedState() {
    this.state.changeValue(WsSubscriptionState.subscribed);
  }

  private handleUnsubscribedState() {
    this.state.changeValue(WsSubscriptionState.unsubscribed);

    if (this.deleted.value) {
      PubSub.publish(PubSubWsEvent.subscriptionRemove, {
        channel: this.channel
      });
    }
  }

  private handlePublicationEvent({ data }) {
    this.listeners.forEach((listener) => listener(data));
  }
}
