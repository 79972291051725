import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStage } from 'shared/entities/meta';
import { apiUrls } from 'shared/entities/domain';
import {
  GoogleAuthAvailableTokensServer,
  GoogleAuthInfo,
  GoogleAuthInfoServer,
  GoogleTableErrorCode,
  mapGoogleTableErrorCodeToMessage,
  normalizeGoogleAuthInfo
} from 'shared/entities/google';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { IGoogleStore } from 'shared/entities/store/googleStore';
import ListModel from 'shared/models/ListModel';
import { SelectorItem } from 'shared/entities/components/Selector';
import { PollingWindowModel } from 'shared/models/polling';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';

export default class GoogleStore
  extends ComponentLoadingStore
  implements IGoogleStore
{
  private _rootStore: IRootStore;
  private _googleTokenPolling: PollingWindowModel;
  private _authUrlStage: LoadingStage = LoadingStage.NOT_STARTED;
  private _loadingStage: LoadingStage = LoadingStage.NOT_STARTED;
  private _loadAuthInfoStage: LoadingStage = LoadingStage.NOT_STARTED;
  private _duplicateTokenStage: LoadingStage = LoadingStage.NOT_STARTED;
  private _revokeTokenStage: LoadingStage = LoadingStage.NOT_STARTED;
  private _tokenInfo: GoogleAuthInfo | null = null;

  private _selectedEmail: string | null = null;

  private _availableTokens: ListModel<SelectorItem> =
    new ListModel<SelectorItem>();

  constructor(rootStore: IRootStore) {
    super();

    this._rootStore = rootStore;
    this._googleTokenPolling = new PollingWindowModel({
      rootStore,
      cb: this.checkUserToken
    });

    makeObservable<
      GoogleStore,
      | '_authUrlStage'
      | '_tokenInfo'
      | '_loadAuthInfoStage'
      | '_revokeTokenStage'
      | '_duplicateTokenStage'
      | '_availableTokens'
      | '_selectedEmail'
      | '_loadingStage'
    >(this, {
      _authUrlStage: observable,
      _tokenInfo: observable,
      _loadAuthInfoStage: observable,
      _revokeTokenStage: observable,
      _duplicateTokenStage: observable,
      _availableTokens: observable,
      _selectedEmail: observable,
      _loadingStage: observable,

      getUserToken: action,
      loadAuthInfo: action,
      getAuthUrl: action,
      revokeToken: action,
      changeTokenInfo: action,
      duplicateToken: action,
      changeSelectedEmail: action,

      authUrlStage: computed,
      loadAuthInfoStage: computed,
      duplicateTokenStage: computed,
      revokeTokenStage: computed,
      pollingStage: computed,
      availableTokens: computed,
      selectedEmail: computed,
      tokenInfo: computed,
      loadingStage: computed
    });
  }

  get canBeLoaded(): boolean {
    return this._rootStore.initialized;
  }

  get loaded(): boolean {
    return this.loadingStage === LoadingStage.SUCCESS;
  }

  get loadingStage(): LoadingStage {
    return this._loadingStage;
  }

  get selectedEmail(): string | null {
    return this._selectedEmail;
  }

  get availableTokens(): ListModel<SelectorItem> {
    return this._availableTokens;
  }

  get pollingStage(): LoadingStage {
    return this._googleTokenPolling.pollingStage.value;
  }

  get loadAuthInfoStage(): LoadingStage {
    return this._loadAuthInfoStage;
  }

  get duplicateTokenStage(): LoadingStage {
    return this._duplicateTokenStage;
  }

  get revokeTokenStage(): LoadingStage {
    return this._revokeTokenStage;
  }

  get tokenInfo(): GoogleAuthInfo | null {
    return this._tokenInfo;
  }

  get authUrlStage(): LoadingStage {
    return this._authUrlStage;
  }

  stopPolling = (): void => {
    this._googleTokenPolling.stopPolling();
  };

  changeTokenInfo = (value: GoogleAuthInfo | null): void => {
    this._tokenInfo = value;
  };

  getAuthUrl = async (): Promise<BaseResponse<{ authUrl: string }>> => {
    if (this._authUrlStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this._authUrlStage = LoadingStage.LOADING;

    const response = await this._rootStore.networkStore.api<{
      auth_url: string;
    }>(apiUrls.OAUTH_GOOGLE_GET_URL);

    if (!response.isError) {
      runInAction(() => {
        this._authUrlStage = LoadingStage.SUCCESS;
      });

      return {
        isError: false,
        data: { authUrl: response.data.auth_url }
      };
    }

    runInAction(() => {
      this._authUrlStage = LoadingStage.ERROR;
    });

    this._rootStore.appNotificationsStore.open({
      title: (t) =>
        t('integrationStores.GoogleStore.errors.gettingAuthUrlError', {
          ns: 'stores'
        }),
      type: AppNotificationType.error
    });

    return {
      isError: true
    };
  };

  getUserToken = async (): Promise<void> => {
    const response = await this.getAuthUrl();

    if (response.isError) {
      return;
    }

    this._googleTokenPolling.openWindow(response.data.authUrl);
  };

  private checkUserToken = async (): Promise<BaseResponse> => {
    const response = await this.loadAuthInfo({ isPolling: true });

    if (
      (!response.isError && this._tokenInfo) ||
      (response.isError &&
        response.data !== GoogleTableErrorCode.TOKEN_NOT_FOUND)
    ) {
      return {
        isError: false
      };
    }

    return {
      isError: true
    };
  };

  async load(): Promise<
    BaseResponse<GoogleAuthInfoServer, GoogleTableErrorCode | null>
  > {
    if (this._loadingStage === LoadingStage.LOADING) {
      return {
        isError: true,
        data: null
      };
    }

    this._loadingStage = LoadingStage.LOADING;

    const response = await this.loadAuthInfo({ isPolling: false });

    if (
      !response.isError ||
      response.data === GoogleTableErrorCode.TOKEN_NOT_FOUND
    ) {
      runInAction(() => {
        this._loadingStage = LoadingStage.SUCCESS;
      });
    } else {
      runInAction(() => {
        this._loadingStage = LoadingStage.ERROR;
      });
    }

    return response;
  }

  //метод работает при первой загрузке настроек, и во время полллинга за токеном
  //три варинта ответа:
  // 1) 200 - все ок, получаем инфу о пользователе
  // 2) 404 - токена пользователя нет, необходимо авторизоваться,
  // либо использовать для авторизации из поля data любой email из массива
  // 3) все остальные ошибки - неизвестные
  async loadAuthInfo({
    isPolling
  }: {
    isPolling: boolean;
  }): Promise<BaseResponse<GoogleAuthInfoServer, GoogleTableErrorCode | null>> {
    if (this._loadAuthInfoStage === LoadingStage.LOADING) {
      return {
        isError: true,
        data: null
      };
    }

    this._loadAuthInfoStage = LoadingStage.LOADING;

    const response = await this._rootStore.networkStore.api<
      GoogleAuthInfoServer,
      GoogleTableErrorCode,
      GoogleAuthAvailableTokensServer
    >(apiUrls.OAUTH_GOOGLE_GET_TOKEN, {
      method: 'GET',
      data: {
        is_polling: isPolling ? true : undefined
      },
      errorsMap: mapGoogleTableErrorCodeToMessage,
      showExpectedError: false
    });

    if (!response.isError) {
      runInAction(() => {
        this.changeTokenInfo(normalizeGoogleAuthInfo(response.data));
        this._loadAuthInfoStage = LoadingStage.SUCCESS;
      });
      return {
        isError: false,
        data: response.data
      };
    }

    //  если вернулась ошибка:
    //  1) код ошибки не 404, какая-то неизвестная ошибка
    //  2) код ошибки 404, в поле data будет лежать информация о доступных email
    if (response.isError) {
      if (response.data?.code === GoogleTableErrorCode.TOKEN_NOT_FOUND) {
        const errorData = response.data.data;

        runInAction(() => {
          const { entities, keys } = errorData.available_tokens.reduce(
            (acc, email) => ({
              ...acc,
              entities: {
                ...acc.entities,
                [email]: { id: email, title: email }
              },
              keys: [...acc.keys, email]
            }),
            { entities: {}, keys: [] }
          );

          this._availableTokens.addEntities({ entities, keys, initial: true });
          this._loadAuthInfoStage = LoadingStage.SUCCESS;
        });
      } else {
        runInAction(() => {
          this._loadAuthInfoStage = LoadingStage.ERROR;
        });
      }
    }

    return {
      isError: true,
      data: response.data?.code || null
    };
  }

  revokeToken = async (): Promise<BaseResponse> => {
    if (this._revokeTokenStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this._revokeTokenStage = LoadingStage.LOADING;

    const { isError } = await this._rootStore.networkStore.api(
      apiUrls.OAUTH_GOOGLE_REVOKE_TOKEN,
      { method: 'POST' }
    );

    runInAction(() => {
      if (isError) {
        this._revokeTokenStage = LoadingStage.ERROR;
      } else {
        this._revokeTokenStage = LoadingStage.SUCCESS;
        this.changeTokenInfo(null);
      }
    });

    return {
      isError
    };
  };

  changeSelectedEmail = (value: string): void => {
    this._selectedEmail = value;
  };

  duplicateToken = async (): Promise<void> => {
    if (
      this._duplicateTokenStage === LoadingStage.LOADING ||
      !this._selectedEmail
    ) {
      return;
    }

    this._duplicateTokenStage = LoadingStage.LOADING;

    const response = await this._rootStore.networkStore.api<
      GoogleAuthInfoServer,
      GoogleTableErrorCode
    >(apiUrls.OAUTH_GOOGLE_DUPLICATE_TOKEN, {
      method: 'POST',
      data: {
        email: this._selectedEmail
      }
    });

    runInAction(() => {
      if (!response.isError) {
        this._duplicateTokenStage = LoadingStage.SUCCESS;
        this.changeTokenInfo(normalizeGoogleAuthInfo(response.data));
      } else {
        this._duplicateTokenStage = LoadingStage.ERROR;
      }
    });
  };

  reset(): void {
    this._availableTokens.reset();
    this._selectedEmail = null;
    this._tokenInfo = null;
    this._authUrlStage = LoadingStage.NOT_STARTED;
    this._revokeTokenStage = LoadingStage.NOT_STARTED;
    this._loadAuthInfoStage = LoadingStage.NOT_STARTED;
    this._duplicateTokenStage = LoadingStage.NOT_STARTED;
    this._loadingStage = LoadingStage.NOT_STARTED;
    this._googleTokenPolling.stopPolling();
  }
}
