import { isSameDay } from 'date-fns';

import { areMonthsEqual } from 'shared/entities/common/utils';
import {
  IDatePicker,
  IDateRangePicker
} from 'shared/newEntities/components/DatePicker';

import DateBaseModel from './DateBaseModel';

export abstract class CalendarDateModel<
  T extends IDateRangePicker | IDatePicker
> extends DateBaseModel {
  readonly datePicker: T;
  readonly monthDate: Date;

  constructor({
    date,
    monthDate,
    datePicker
  }: {
    date: Date;
    monthDate: Date;
    datePicker: T;
  }) {
    super(date);

    this.monthDate = monthDate;
    this.datePicker = datePicker;
  }
  get isToday(): boolean {
    return isSameDay(this.date, new Date());
  }

  /**
   * Дата является датой месяца, где ее показывают
   * @returns {boolean}
   */
  get isInCalendarMonth(): boolean {
    return areMonthsEqual(this.date, this.monthDate);
  }

  abstract get isActive(): boolean;
}
