import * as React from 'react';

import { ChannelKind, OpenedChannelKind } from 'shared/entities/channels';

import Telegram from './Telegram';
import Viber from './Viber';
import Jivo from './Jivo';
import VK from './VK';
import WhatsApp from './WhatsApp';
import Wazzup from './Wazzup';

import './Form.modules.scss';

type Props = {
  channelKind: OpenedChannelKind;
};

const mapChannelKindToComponent: Record<
  OpenedChannelKind | ChannelKind.WHATSAPP,
  React.FC
> = {
  [ChannelKind.TELEGRAM]: Telegram,
  [ChannelKind.VK]: VK,
  [ChannelKind.VB]: Viber,
  [ChannelKind.JIVO]: Jivo,
  [ChannelKind.WHATSAPP]: WhatsApp,
  [ChannelKind.WZ]: Wazzup
};

const Form: React.FC<Props> = ({ channelKind }) => {
  const Component = mapChannelKindToComponent[channelKind];
  return (
    <div styleName="form">
      <Component />
    </div>
  );
};

export default Form;
