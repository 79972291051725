/**
 * Достает из ссылки на пост id поста
 * @param link - ссылка формата https://vk.com/smartbot?w=wall-161272419_19814
 * @returns {VarSpecial.postId | null}
 */
const getPostIdFromLink = (link: string): string | null => {
  const REGEX = /^.+\?w=wall-\d+_(?<postId>\d+)$/;

  const matchGroups = link.match(REGEX)?.groups;

  if (!matchGroups || !matchGroups.postId) {
    return null;
  }

  return matchGroups.postId;
};

export default getPostIdFromLink;
