import { TFunctionType } from 'shared/entities/localization';

import { pluralize } from '../pluralize';

import { IntervalUnit } from './server';

export const pluralizeIntervalUnit = (
  value: number,
  unit: IntervalUnit,
  rCase = false,
  t: TFunctionType
): string => {
  if (!rCase) {
    switch (unit) {
      case IntervalUnit.seconds:
        return pluralize(value, {
          one: t('timeout.pluralizeIntervalUnit.notRCase.seconds.one', {
            ns: 'entities'
          }),
          two: t('timeout.pluralizeIntervalUnit.notRCase.seconds.two', {
            ns: 'entities'
          }),
          five: t('timeout.pluralizeIntervalUnit.notRCase.seconds.five', {
            ns: 'entities'
          })
        });
      case IntervalUnit.minutes:
        return pluralize(value, {
          one: t('timeout.pluralizeIntervalUnit.notRCase.minutes.one', {
            ns: 'entities'
          }),
          two: t('timeout.pluralizeIntervalUnit.notRCase.minutes.two', {
            ns: 'entities'
          }),
          five: t('timeout.pluralizeIntervalUnit.notRCase.minutes.five', {
            ns: 'entities'
          })
        });
      case IntervalUnit.hours:
        return pluralize(value, {
          one: t('timeout.pluralizeIntervalUnit.notRCase.hours.one', {
            ns: 'entities'
          }),
          two: t('timeout.pluralizeIntervalUnit.notRCase.hours.two', {
            ns: 'entities'
          }),
          five: t('timeout.pluralizeIntervalUnit.notRCase.hours.five', {
            ns: 'entities'
          })
        });
      case IntervalUnit.days:
        return pluralize(value, {
          one: t('timeout.pluralizeIntervalUnit.notRCase.days.one', {
            ns: 'entities'
          }),
          two: t('timeout.pluralizeIntervalUnit.notRCase.days.two', {
            ns: 'entities'
          }),
          five: t('timeout.pluralizeIntervalUnit.notRCase.days.five', {
            ns: 'entities'
          })
        });
      case IntervalUnit.months:
        return pluralize(value, {
          one: t('timeout.pluralizeIntervalUnit.notRCase.months.one', {
            ns: 'entities'
          }),
          two: t('timeout.pluralizeIntervalUnit.notRCase.months.two', {
            ns: 'entities'
          }),
          five: t('timeout.pluralizeIntervalUnit.notRCase.months.five', {
            ns: 'entities'
          })
        });
      case IntervalUnit.weeks:
        return pluralize(value, {
          one: t('timeout.pluralizeIntervalUnit.notRCase.weeks.one', {
            ns: 'entities'
          }),
          two: t('timeout.pluralizeIntervalUnit.notRCase.weeks.two', {
            ns: 'entities'
          }),
          five: t('timeout.pluralizeIntervalUnit.notRCase.weeks.five', {
            ns: 'entities'
          })
        });
      case IntervalUnit.tomorrow:
        return t('timeout.pluralizeIntervalUnit.tomorrow', {
          ns: 'entities'
        });
    }
  }

  switch (unit) {
    case IntervalUnit.seconds:
      return pluralize(value, {
        one: t('timeout.pluralizeIntervalUnit.rCase.seconds.one', {
          ns: 'entities'
        }),
        two: t('timeout.pluralizeIntervalUnit.rCase.seconds.two', {
          ns: 'entities'
        }),
        five: t('timeout.pluralizeIntervalUnit.rCase.seconds.five', {
          ns: 'entities'
        })
      });
    case IntervalUnit.minutes:
      return pluralize(value, {
        one: t('timeout.pluralizeIntervalUnit.rCase.minutes.one', {
          ns: 'entities'
        }),
        two: t('timeout.pluralizeIntervalUnit.rCase.minutes.two', {
          ns: 'entities'
        }),
        five: t('timeout.pluralizeIntervalUnit.rCase.minutes.five', {
          ns: 'entities'
        })
      });
    case IntervalUnit.hours:
      return pluralize(value, {
        one: t('timeout.pluralizeIntervalUnit.rCase.hours.one', {
          ns: 'entities'
        }),
        two: t('timeout.pluralizeIntervalUnit.rCase.hours.two', {
          ns: 'entities'
        }),
        five: t('timeout.pluralizeIntervalUnit.rCase.hours.five', {
          ns: 'entities'
        })
      });
    case IntervalUnit.days:
      return pluralize(value, {
        one: t('timeout.pluralizeIntervalUnit.rCase.days.one', {
          ns: 'entities'
        }),
        two: t('timeout.pluralizeIntervalUnit.rCase.days.two', {
          ns: 'entities'
        }),
        five: t('timeout.pluralizeIntervalUnit.rCase.days.five', {
          ns: 'entities'
        })
      });
    case IntervalUnit.months:
      return pluralize(value, {
        one: t('timeout.pluralizeIntervalUnit.rCase.months.one', {
          ns: 'entities'
        }),
        two: t('timeout.pluralizeIntervalUnit.rCase.months.two', {
          ns: 'entities'
        }),
        five: t('timeout.pluralizeIntervalUnit.rCase.months.five', {
          ns: 'entities'
        })
      });
    case IntervalUnit.weeks:
      return pluralize(value, {
        one: t('timeout.pluralizeIntervalUnit.rCase.weeks.one', {
          ns: 'entities'
        }),
        two: t('timeout.pluralizeIntervalUnit.rCase.weeks.two', {
          ns: 'entities'
        }),
        five: t('timeout.pluralizeIntervalUnit.rCase.weeks.five', {
          ns: 'entities'
        })
      });
    case IntervalUnit.tomorrow:
      return t('timeout.pluralizeIntervalUnit.tomorrow', {
        ns: 'entities'
      });
  }
};
