import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const PortalInIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M15.6429 5.55358C16.2001 4.57858 16.8751 4 17.6251 4C19.4894 4 21.0001 7.65358 21.0001 12.175C21.0001 16.6857 19.4894 20.35 17.6251 20.35C16.8858 20.35 16.2001 19.7714 15.6429 18.7964"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.31786 15.1964H8.52857C9.075 15.1964 9.51429 15.6357 9.51429 16.1822V16.8679C9.51429 18.3143 11.25 19.0429 12.2893 18.0357L17.1 13.3429C17.7536 12.7 17.7536 11.65 17.1 11.0072L12.2893 6.31429C11.2607 5.30715 9.51429 6.03571 9.51429 7.48214V8.16786C9.51429 8.71429 9.075 9.15359 8.52857 9.15359H4.31786C3.58929 9.15359 3 9.74287 3 10.4714V13.8679C3 14.6072 3.58929 15.1964 4.31786 15.1964Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default PortalInIcon;
