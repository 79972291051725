import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const RemoveTransitionIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
    >
      <path
        d="M21 12.1244C21 11.5722 20.7772 11.0297 20.3509 10.6228L14.1023 4.59696C12.7557 3.2988 10.5178 4.25789 10.5275 6.12764L10.5372 8.28803L5.72233 8.31709C4.21103 8.32678 2.99037 9.55713 3.00006 11.0684L3.00975 13.3838C3.01943 14.8951 4.24978 16.1158 5.76108 16.1061L10.5759 16.077L10.5856 18.2374C10.5953 19.3999 11.4769 20.204 12.4554 20.3203"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8805 14.7207H15.5554L15.7686 18.964C15.8073 19.7293 16.437 20.3299 17.2023 20.3299H17.8805H18.5586C19.324 20.3299 19.9537 19.7293 19.9924 18.964L20.2056 14.7207H17.8805Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.761 14.7207H20.9999"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7761 14.0329C16.7761 13.4225 17.2702 12.9285 17.8805 12.9285C18.4909 12.9285 18.9849 13.4225 18.9849 14.0329"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default RemoveTransitionIcon;
