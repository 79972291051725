import { observer } from 'mobx-react';
import * as React from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';

import ExternalLink from 'shared/newComponents/ExternalLink';
import { ChannelModelType } from 'shared/models/channel';
import PhotoWithChannel from 'shared/newComponents/PhotoWithChannel';
import {
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import Status from 'shared/newComponents/Status';
import { StatusType } from 'shared/newEntities/components/Status';
import { ArrowIcon, RemoveIcon } from 'shared/newComponents/icons';
import { Button } from 'shared/newComponents/buttons';
import {
  ButtonColor,
  ButtonSize,
  ButtonStyle
} from 'shared/newEntities/components/Button';
import { ComponentType } from 'shared/entities/components/Component';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { IconSize } from 'shared/entities/components/Icon';
import { CHANNEL_REMOVE_TIMEOUT_MS } from 'shared/models/channel/ChannelBaseModel';
import { Checkbox } from 'shared/newComponents/form';
import CroppedContent from 'shared/newComponents/CroppedContent';
import { useModalState } from 'shared/entities/common/hooks';
import { ChannelKind } from 'shared/entities/channels';

import LinkedReference from '../LinkedReference';

import RemoveModal from './RemoveModal';

import './ChannelItem.modules.scss';

type Props = {
  channel: ChannelModelType;
  className?: string;
  isSelected: boolean;
};

const ChannelItem: React.FC<Props> = ({ channel, className, isSelected }) => {
  const { store } = useChannelsModalStore();
  const removeModalState = useModalState();

  const select = React.useCallback(() => {
    store.selectedChannelId.changeValue(channel.id);
  }, [channel.id]);

  const linkClick = React.useCallback<
    React.MouseEventHandler<HTMLAnchorElement>
  >((e) => e.stopPropagation(), []);

  const removeButtonClick = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >((e) => {
    e.stopPropagation();
    removeModalState.open();
  }, []);

  const cancelRemoving = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (e) => {
      e.stopPropagation();
      channel.cancelRemoving();
    },
    [channel]
  );

  const handleCheckboxChange = (
    value: boolean,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();

    if (!store.scenario) {
      return;
    }

    if (value) {
      store.scenario.addChannelId(channel.id);
    } else {
      store.scenario.removeChannelId(channel.id);
    }
  };

  const removingExtraInfo = React.useMemo(() => {
    return channel.kind === ChannelKind.WZ
      ? (t) =>
          t('ChannelsModal.ChannelTable.ChannelItem.RemoveModal.extraInfo.wz', {
            ns: 'newContainers'
          })
      : undefined;
  }, [channel.kind]);

  const isRemoving = !!channel.removingTimeout.value;

  return (
    <>
      <RemoveModal
        opened={removeModalState.opened}
        onClose={removeModalState.close}
        onRemove={channel.initRemoving}
        channelName={channel.name}
        extraInfo={removingExtraInfo}
      />
      <div
        styleName={cn('channel-item', isSelected && 'channel-item_selected')}
        className={className}
        onClick={select}
      >
        <div styleName="channel-item__name-container">
          {!isRemoving &&
            !!store.scenario &&
            Array.isArray(store.scenario.channelIds.value) && (
              <Checkbox
                value={store.scenario.channelIds.value.includes(channel.id)}
                onChange={handleCheckboxChange}
                styleName="channel-item__checkbox"
              />
            )}
          <PhotoWithChannel
            styleName="channel-item__icon"
            channelKind={channel.kind}
            photoUrl={channel.photoUrl}
            size={IconSize.L}
            name={channel.name}
          />
          <CroppedContent popupContent={channel.name}>
            <ExternalLink
              fontType={TypographyType.text}
              fontSize={TypographySize.s}
              fontWeight={TypographyWeight.heavy}
              fontColor={null}
              styleName="channel-item__name"
              onClick={linkClick}
              href={channel.url}
            >
              {channel.name}
            </ExternalLink>
          </CroppedContent>
        </div>
        {!isRemoving && (
          <>
            {store.withAccounts && (
              <div styleName="channel-item__status">
                {!channel.linked ? (
                  <LinkedReference
                    trigger={
                      <div>
                        <Status type={StatusType.warning} />
                      </div>
                    }
                  />
                ) : (
                  <Status type={StatusType.success} />
                )}
              </div>
            )}
            <div styleName="channel-item__status">
              <Status
                type={
                  channel.hasTokenErrors || channel.tokens.isEmpty
                    ? StatusType.error
                    : StatusType.success
                }
              />
            </div>
            <div styleName="channel-item__icon_remove">
              <Button
                type={ComponentType.button}
                onClick={removeButtonClick}
                styleName="channel-item__icon"
                color={ButtonColor.gray}
                style={ButtonStyle.transparent}
                size={ButtonSize.sm}
                Icon={RemoveIcon}
              />
            </div>
            <Button
              type={ComponentType.button}
              onClick={select}
              styleName="channel-item__icon"
              color={ButtonColor.gray}
              style={ButtonStyle.transparent}
              size={ButtonSize.sm}
              Icon={ArrowIcon}
            />
          </>
        )}
        {isRemoving && (
          <>
            <div styleName="channel-item__cancel-row">
              <motion.div
                initial={{ width: '100%' }}
                animate={{ width: '0' }}
                transition={{
                  duration: CHANNEL_REMOVE_TIMEOUT_MS / 1000,
                  ease: 'linear'
                }}
                styleName="channel-item__cancel-row-inner"
              />
            </div>
            <div styleName="channel-item__cancel-button-container">
              <Button
                type={ComponentType.button}
                onClick={cancelRemoving}
                color={ButtonColor.blue}
                style={ButtonStyle.transparent}
                size={ButtonSize.sm}
                withoutPadding
              >
                {(t) =>
                  t('ChannelsModal.ChannelTable.ChannelItem.cancel', {
                    ns: 'newContainers'
                  })
                }
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default observer(ChannelItem);
