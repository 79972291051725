import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const CodeIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.S}
      {...props}
      svgProps={{ viewBox: '0 0 24 24', ...(props.svgProps || {}) }}
    >
      <path
        d="M6.545 6.6c0-.477.192-.935.533-1.273A1.827 1.827 0 0 1 8.364 4.8a.914.914 0 0 0 .642-.264.895.895 0 0 0 0-1.272A.914.914 0 0 0 8.364 3c-.965 0-1.89.38-2.572 1.054A3.582 3.582 0 0 0 4.727 6.6v2.7c0 .477-.191.935-.532 1.273a1.827 1.827 0 0 1-1.286.527.914.914 0 0 0-.643.264.895.895 0 0 0 0 1.272c.17.17.402.264.643.264.482 0 .945.19 1.286.527.34.338.532.796.532 1.273v2.7c0 .955.383 1.87 1.065 2.546A3.655 3.655 0 0 0 8.364 21a.914.914 0 0 0 .642-.264.895.895 0 0 0 0-1.272.914.914 0 0 0-.642-.264c-.483 0-.945-.19-1.286-.527a1.791 1.791 0 0 1-.533-1.273v-2.7a3.57 3.57 0 0 0-.332-1.484A3.603 3.603 0 0 0 5.29 12c.391-.336.705-.75.922-1.216A3.57 3.57 0 0 0 6.545 9.3V6.6Zm14.546 4.5c-.482 0-.945-.19-1.286-.527a1.791 1.791 0 0 1-.532-1.273V6.6c0-.955-.383-1.87-1.065-2.546A3.655 3.655 0 0 0 15.636 3a.914.914 0 0 0-.643.264.896.896 0 0 0 0 1.272c.171.17.402.264.643.264.483 0 .945.19 1.286.527.341.338.532.796.532 1.273v2.7A3.572 3.572 0 0 0 18.71 12a3.572 3.572 0 0 0-1.255 2.7v2.7c0 .477-.191.935-.532 1.273a1.827 1.827 0 0 1-1.286.527.914.914 0 0 0-.643.264.896.896 0 0 0 0 1.272c.171.17.402.264.643.264.965 0 1.89-.38 2.572-1.054a3.582 3.582 0 0 0 1.065-2.546v-2.7c0-.477.191-.935.532-1.273.341-.337.804-.527 1.286-.527a.914.914 0 0 0 .643-.264.895.895 0 0 0 0-1.272.914.914 0 0 0-.643-.264Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CodeIcon;
