import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const ExchangeIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M20.7494 14.0681C20.7494 15.818 19.6995 15.943 19.4995 15.943H4.13163L7.43769 12.637L6.55649 11.7495L2.18175 16.1243C1.93942 16.368 1.93942 16.7617 2.18175 17.0055L6.55649 21.3802L7.43769 20.499L4.13163 17.1929H19.4995C20.3619 17.1929 21.9993 16.568 21.9993 14.0681V12.1932H20.7494V14.0681Z"
        fill="currentColor"
      />
      <path
        d="M3.25066 10.3183C3.25066 8.56842 4.3006 8.44343 4.50059 8.44343H19.8684L16.5624 11.7495L17.4436 12.6307L21.8183 8.25594C22.0606 8.01217 22.0606 7.61852 21.8183 7.37474L17.4436 3L16.5561 3.88745L19.8684 7.1935H4.50059C3.63814 7.1935 2.00073 7.81847 2.00073 10.3183V12.1932H3.25066V10.3183Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ExchangeIcon;
