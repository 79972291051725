import {
  ChannelCommonServer,
  CommonChannelKind,
  IChannelBase,
  ICommonChannelModel,
  normalizeBaseChannel
} from 'shared/entities/channels';
import { IRootStore } from 'shared/entities/store/rootStore';
import ListModel from 'shared/models/ListModel';
import { FieldModel } from 'shared/models/form';

import ChannelTokenModel from './ChannelTokenModel';
import ChannelBaseModel from './ChannelBaseModel';

export default class CommonChannelModel
  extends ChannelBaseModel
  implements ICommonChannelModel
{
  readonly kind: CommonChannelKind;
  constructor({
    kind,
    ...params
  }: Omit<IChannelBase, 'tokens' | 'scenarioIds'> & {
    rootStore: IRootStore;
    tokens: ListModel<ChannelTokenModel>;
    scenarioIds: FieldModel<string[]>;
    kind: CommonChannelKind;
  }) {
    super(params);

    this.kind = kind;
  }

  static fromJson(
    raw: ChannelCommonServer,
    rootStore: IRootStore
  ): CommonChannelModel {
    const collection = raw.tokens.reduce<{
      keys: string[];
      entities: Record<string, ChannelTokenModel>;
    }>(
      (acc, token) => ({
        ...acc,
        keys: [...acc.keys, token._id],
        entities: {
          ...acc.entities,
          [token._id]: ChannelTokenModel.fromJson({
            raw: token,
            rootStore,
            channelId: raw._id
          })
        }
      }),
      { keys: [], entities: {} }
    );
    return new CommonChannelModel({
      ...normalizeBaseChannel(raw),
      linked: raw.manager_linked,
      kind: raw.kind,
      tokens: new ListModel<ChannelTokenModel>(collection),
      scenarioIds: new FieldModel<string[]>(raw.scenario_ids),
      rootStore
    });
  }
}
