import cn from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import ModalEventsHandler from 'shared/components/windows/ModalEventsHandler';
import { PORTAL_IDS } from 'shared/entities/app';
import { useEventListener } from 'shared/entities/common/hooks';
import {
  PopupEventType,
  PopupPosition,
  PopupProps,
  PopupRef
} from 'shared/entities/components/Popup';
import { useUiStore } from 'stores/index';

import BasePopup from '../BasePopup';

type Props = PopupProps;

const PopupContainer = React.forwardRef<PopupRef, Props>(
  (
    {
      trigger,
      children,
      position = [PopupPosition.topCenter],
      on,
      className,
      triggerClassname,
      containerFullHeight = false,
      contentStyle,
      closeOnDocumentClick = true,
      onClose,
      onOpen,
      disabled = false,
      closeOnEscape = true,
      isOpen,
      nested
    }: Props,
    ref
  ) => {
    const uiStore = useUiStore();
    const [zIndex, setZIndex] = React.useState<number>(1000);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const popupRef = React.useRef<{
      open: () => void;
      close: () => void;
      toggle: () => void;
      setPosition: () => void;
    } | null>(null);
    const [opened, setOpened] = React.useState(false);

    const handleOpen = React.useCallback(() => {
      setOpened(true);
      onOpen?.();
    }, [onOpen]);

    const handleClose = React.useCallback(() => {
      setOpened(false);
      onClose?.();
    }, [onClose]);

    const handleScroll = React.useCallback(
      (e) => {
        if (uiStore.isMobile) {
          return;
        }

        const popupsPortal = document.getElementById(PORTAL_IDS.POPUPS);
        // закрываем попап, если скрол не внутри попапа (и не скролл в инпуте)
        if (
          popupRef.current &&
          opened &&
          !popupsPortal?.contains(e.target) &&
          e.target.nodeName !== 'INPUT'
        ) {
          popupRef.current.close();
        }
      },
      [opened, uiStore.isMobile]
    );

    useEventListener({
      eventName: 'scroll',
      handler: handleScroll,
      getElement: () => document,
      options: true
    });

    React.useImperativeHandle(ref, () => {
      return {
        setPosition: () => {
          popupRef.current?.setPosition();
        },
        container: containerRef.current
      };
    });

    const actualCloseOnEscape =
      on === PopupEventType.hover ? false : closeOnEscape;

    return (
      <div
        ref={containerRef}
        className={cn(
          containerFullHeight && 'popup__container_full-height',
          triggerClassname
        )}
      >
        <BasePopup
          ref={popupRef}
          on={on}
          open={isOpen}
          trigger={trigger}
          position={position}
          className={cn('popup', className)}
          closeOnDocumentClick={closeOnDocumentClick}
          closeOnEscape={false}
          onClose={handleClose}
          onOpen={handleOpen}
          contentStyle={{
            background: 'none',
            padding: '4px',
            border: 'none',
            boxShadow: 'none',
            maxWidth: uiStore.isMobileSize ? '70%' : '320px',
            width: 'auth',
            maxHeight: '80vh',
            zIndex,
            ...contentStyle
          }}
          arrow={false}
          disabled={disabled}
          nested={nested}
          keepTooltipInside
        >
          {/* @ts-ignore TODO bug in i18next https://github.com/i18next/react-i18next/issues/1543 */}
          {(close, opened) => {
            return (
              <ModalEventsHandler
                onClose={close}
                opened={opened}
                onZIndexChange={setZIndex}
                closeOnEscape={actualCloseOnEscape}
                render={({ onClose }) => {
                  return React.isValidElement(children) || children === null ? (
                    <>{children}</>
                  ) : (
                    children(onClose, opened)
                  );
                }}
              />
            );
          }}
        </BasePopup>
      </div>
    );
  }
);

export default observer(PopupContainer);
