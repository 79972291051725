import * as React from 'react';
import { observer } from 'mobx-react';

import { Input } from 'shared/newComponents/form';
import { ITgChannelModel } from 'shared/entities/channels';
import { ComponentType } from 'shared/entities/components/Component';
import { ButtonColor, ButtonSize } from 'shared/newEntities/components/Button';
import { RemoveIcon } from 'shared/newComponents/icons';
import { Button } from 'shared/newComponents/buttons';

import './TelegramMenu.modules.scss';

type Props = {
  channel: ITgChannelModel;
};

const TelegramMenu: React.FC<Props> = ({ channel }) => {
  const button = channel.button;

  return (
    <div styleName="menu">
      <Input
        label={(t) =>
          t('ChannelsModal.SelectedChannel.Content.MenuTelegram.name.label', {
            ns: 'newContainers'
          })
        }
        placeholder={(t) =>
          t(
            'ChannelsModal.SelectedChannel.Content.MenuTelegram.name.placeholder',
            {
              ns: 'newContainers'
            }
          )
        }
        value={button.text.value}
        onChange={button.text.changeValue}
        error={button.text.error}
        onBlur={button.text.validate}
      />
      <Input
        label={(t) =>
          t('ChannelsModal.SelectedChannel.Content.MenuTelegram.link.label', {
            ns: 'newContainers'
          })
        }
        placeholder={(t) =>
          t(
            'ChannelsModal.SelectedChannel.Content.MenuTelegram.link.placeholder',
            {
              ns: 'newContainers'
            }
          )
        }
        value={button.url.value}
        onChange={button.url.changeValue}
        error={button.url.error}
        onBlur={button.url.validate}
        styleName="menu__input"
      />
      <div styleName="menu__buttons">
        {button.saved.value && (
          <Button
            styleName="menu__remove"
            type={ComponentType.button}
            onClick={channel.removeButton}
            size={ButtonSize.lg}
            color={ButtonColor.lightRed}
            loadingStage={button.removingStage.value}
            Icon={RemoveIcon}
          />
        )}
        <Button
          styleName="menu__update"
          type={ComponentType.button}
          onClick={channel.updateButton}
          size={ButtonSize.lg}
          disabled={button.isError}
          loadingStage={button.updatingStage.value}
        >
          {(t) =>
            t(
              'ChannelsModal.SelectedChannel.Content.MenuTelegram.updateButton',
              {
                ns: 'newContainers'
              }
            )
          }
        </Button>
      </div>
    </div>
  );
};

export default observer(TelegramMenu);
