import * as React from 'react';
import { observer } from 'mobx-react';
import { motion } from 'framer-motion';

import Section from 'shared/newComponents/Section';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { channelKindEntities } from 'shared/entities/channels';
import { IconSize } from 'shared/entities/components/Icon';
import {
  TranslationNode,
  useTypedTranslation
} from 'shared/entities/localization';

import { useSelectAnimation } from '../../hooks';

import Form from './Form';

import './ChannelForm.modules.scss';

const ChannelForm: React.FC = () => {
  const { t } = useTypedTranslation('newContainers');
  const { store } = useChannelsModalStore();
  const { showContent, ref } = useSelectAnimation({
    selected: !!store.selectedChannelKind.value,
    withMargin: false,
    width: store.wazzupOrJivoSelected ? '100%' : '320px'
  });
  const selectedChannelKind = store.selectedChannelKind.value;

  const title: TranslationNode = React.useMemo(() => {
    if (selectedChannelKind) {
      const { title, Icon } = channelKindEntities[selectedChannelKind];
      let ActualIcon = Icon;
      let actualTitle = title;
      if (store.selectedViaJivoChannelKind) {
        const { title: channelTitle, Icon: ChannelIcon } =
          channelKindEntities[store.selectedViaJivoChannelKind];
        actualTitle = t('ChannelsModal.ChannelForm.titleWithJivo', {
          channel: channelTitle
        });
        ActualIcon = ChannelIcon;
      } else if (store.selectedViaWazzupChannelKind) {
        const { title: channelTitle, Icon: ChannelIcon } =
          channelKindEntities[store.selectedViaWazzupChannelKind];
        actualTitle = t('ChannelsModal.ChannelForm.titleViaWazzup', {
          channel: channelTitle
        });
        ActualIcon = ChannelIcon;
      }

      return (
        <div styleName="channel-form__title">
          <ActualIcon
            iconSize={IconSize.S}
            styleName="channel-form__title-icon"
          />
          {actualTitle}
        </div>
      );
    }
    return null;
  }, [
    selectedChannelKind,
    store.selectedViaJivoChannelKind,
    store.selectedViaWazzupChannelKind
  ]);

  return (
    <motion.div ref={ref} initial={false} styleName="channel-form-wrapper">
      <Section
        styleName="channel-form"
        title={title}
        goBack={{
          onClick: store.unSelectChannelKind,
          title: (t) =>
            t('ChannelsModal.ChannelForm.goBackTitle', {
              ns: 'newContainers'
            })
        }}
      >
        {showContent && selectedChannelKind && (
          <div styleName="channel-form__content">
            <Form channelKind={selectedChannelKind} />
          </div>
        )}
      </Section>
    </motion.div>
  );
};

export default observer(ChannelForm);
