import { BaseResponseCode } from 'shared/entities/network';

import { TranslationString } from '../localization';

export enum ListEntityType {
  users = 'users'
}

export const listEntityTypesOrder = [ListEntityType.users];

export enum ListEntityTag {
  unsubscribed = 'unsubscribed'
}

export type ListEntityServer = {
  _id: string;
  name: string;
  type: ListEntityType;
  tag: ListEntityTag;
  count: number;
};

export const mapListsCreateErrorCodeToMessage =
  (code: BaseResponseCode.conflict): TranslationString =>
  (t) =>
    t(`usersList.errors.createLists.${code}`, {
      ns: 'entities'
    });
