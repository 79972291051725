import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const ThumbsDownIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <path
        d="M7.00635 15.2146C7.00635 15.2146 9.5466 16.4531 9.9361 16.9407C10.3256 17.4284 10.3044 18.1145 11.0453 18.9085C11.7863 19.7025 12.6246 19.8061 13.3443 20.5008C14.064 21.1956 15.2537 23.5992 15.5628 24.6866C15.8719 25.7741 15.2749 27.375 16.672 27.5002C18.0692 27.6253 19.0937 26.4041 19.4621 25.3425C19.7839 24.292 19.7971 23.1685 19.5002 22.1104C19.2546 21.0014 17.904 18.6841 17.904 18.6841C17.904 18.6841 18.3571 18.5374 21.1895 18.559C24.0218 18.5805 25.1014 17.506 24.9998 16.3107C24.932 15.5805 24.6248 14.8949 24.1277 14.3645C24.3828 14.0534 24.5699 13.6904 24.6765 13.2997C24.7831 12.9091 24.8067 12.4997 24.7458 12.099C24.6855 11.7086 24.536 11.3381 24.3096 11.0173C24.0831 10.6965 23.7858 10.4344 23.4418 10.2521C23.6448 9.9455 23.7713 9.59306 23.8104 9.22535C23.8495 8.85765 23.8 8.48577 23.6662 8.14196C23.5428 7.84454 23.3608 7.57615 23.1313 7.35346C22.9019 7.13076 22.63 6.9585 22.3326 6.84738C22.3326 6.84738 22.8237 5.61322 22.1674 4.71565C21.5112 3.81808 20.2665 3.55054 19.4197 3.51601C16.7948 3.41245 8.79301 4.49557 8.79301 4.49557C8.79301 4.49557 7.68374 6.31661 7.34504 10.0234C7.00634 13.7302 7.00635 15.2146 7.00635 15.2146Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </BaseIcon>
  );
};

export default ThumbsDownIcon;
