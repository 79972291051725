import {
  InvoiceStatus,
  PaymentDocumentKind,
  PaymentDocumentSubject,
  SetupInvoiceDocumentServer,
  ICommonDocument,
  normalizeCommonDocument
} from 'shared/entities/documents';

import { SetupDocumentParamsTableModel } from './SetupDocumentParamsTableModel';
import { BaseInvoiceDocumentModel } from './BaseInvoiceDocumentModel';

export class SetupInvoiceDocumentModel extends BaseInvoiceDocumentModel {
  readonly kind: PaymentDocumentKind.invoice = PaymentDocumentKind.invoice;
  readonly subject: PaymentDocumentSubject.setup = PaymentDocumentSubject.setup;
  readonly params: SetupDocumentParamsTableModel;

  constructor({
    params,
    ...rest
  }: ICommonDocument & {
    params: SetupDocumentParamsTableModel;
    status: InvoiceStatus;
    cabinetId: string;
  }) {
    super(rest);
    this.params = params;
  }

  static fromJson(
    raw: SetupInvoiceDocumentServer,
    cabinetId: string
  ): SetupInvoiceDocumentModel {
    return new SetupInvoiceDocumentModel({
      ...normalizeCommonDocument(raw),
      status: raw.status,
      params: SetupDocumentParamsTableModel.fromJson(raw.params),
      cabinetId
    });
  }
}
