import { FieldModel } from 'shared/models/form';
import { validateIsEmpty } from 'shared/entities/validator';
import { generateId } from 'shared/entities/common/utils';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import { UserPaymentQiwiMethodServer } from 'shared/entities/userPayment';

export class NotConnectedQiwiWallet {
  readonly id: string;
  readonly token: FieldModel = new FieldModel<string>('', [validateIsEmpty]);
  readonly title: string;
  readonly creatingStage: LoadingStageModel = new LoadingStageModel();
  readonly rootStore: IRootStore;

  constructor(
    { title, id }: { title: string; id: string },
    rootStore: IRootStore
  ) {
    this.title = title;
    this.id = id;
    this.rootStore = rootStore;
  }

  async create(): Promise<BaseResponse<UserPaymentQiwiMethodServer>> {
    if (this.creatingStage.isLoading || this.token.isError) {
      return {
        isError: true
      };
    }

    this.creatingStage.loading();

    const response =
      await this.rootStore.networkStore.api<UserPaymentQiwiMethodServer>(
        apiUrls.OAUTH_QIWI_OBTAIN_TOKEN,
        {
          method: 'POST',
          data: {
            token: this.token.value
          }
        }
      );

    if (response.isError) {
      this.creatingStage.error();

      return {
        isError: true
      };
    } else {
      this.creatingStage.success();

      return {
        isError: false,
        data: response.data
      };
    }
  }

  static fromDefaultParams(
    title: string,
    rootStore: IRootStore
  ): NotConnectedQiwiWallet {
    return new NotConnectedQiwiWallet({ title, id: generateId() }, rootStore);
  }
}
