import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const CopyIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M14.5859 22H7.12499C5.40181 22 4 20.5981 4 18.8749V8.28907C4 6.56589 5.40181 5.16407 7.12499 5.16407H14.5859C16.3091 5.16407 17.711 6.56589 17.711 8.28907V18.8749C17.711 20.5981 16.3091 22 14.5859 22ZM7.12499 6.72656C6.26348 6.72656 5.56249 7.42755 5.56249 8.28907V18.8749C5.56249 19.7365 6.26348 20.4375 7.12499 20.4375H14.5859C15.4475 20.4375 16.1484 19.7365 16.1484 18.8749V8.28907C16.1484 7.42755 15.4475 6.72656 14.5859 6.72656H7.12499ZM20.8359 16.9219V5.125C20.8359 3.40183 19.4342 2 17.711 2H9.03905C8.60753 2 8.2578 2.34973 8.2578 2.78125C8.2578 3.21277 8.60753 3.56251 9.03905 3.56251H17.711C18.5724 3.56251 19.2735 4.26349 19.2735 5.125V16.9219C19.2735 17.3533 19.6232 17.7031 20.0547 17.7031C20.4862 17.7031 20.8359 17.3533 20.8359 16.9219Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CopyIcon;
