import { action, computed, makeObservable, observable } from 'mobx';

import {
  ChannelKind,
  ChannelTgServer,
  IChannelBase,
  ITgChannelModel,
  normalizeBaseChannel,
  CommandError,
  mapCommandErrorCodeToMessage,
  ChannelTgButtonServer
} from 'shared/entities/channels';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import ChannelTokenModel from 'shared/models/channel/ChannelTokenModel';
import { FieldModel } from 'shared/models/form';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { TgCommandServer } from 'shared/entities/tgCommand';
import { OpenStateModel } from 'shared/models/openState';

import ListModel from '../../ListModel';
import CommandModel from '../../command/CommandModel';
import ChannelBaseModel from '../ChannelBaseModel';

import TgButtonMenuModel from './TgButtonMenuModel';

export default class TgChannelModel
  extends ChannelBaseModel
  implements ITgChannelModel
{
  readonly kind: ChannelKind.TELEGRAM = ChannelKind.TELEGRAM;
  readonly commands: ListModel<CommandModel>;
  readonly updateMenuStage: LoadingStageModel = new LoadingStageModel();
  readonly button: TgButtonMenuModel;

  readonly selectedCommandId: FieldModel<string | null>;
  readonly newCommand: CommandModel = CommandModel.fromDefaultParams();
  readonly commandsFormState: OpenStateModel;

  constructor({
    commands,
    button,
    rootStore,
    selectedCommandId,
    ...rest
  }: Omit<IChannelBase, 'tokens' | 'scenarioIds'> & {
    selectedCommandId: string | null;
    scenarioIds: FieldModel<string[]>;
    tokens: ListModel<ChannelTokenModel>;
    commands: ListModel<CommandModel>;
    button: TgButtonMenuModel;
    rootStore: IRootStore;
  }) {
    super({ ...rest, rootStore });

    this.commands = commands;
    this.button = button;
    this.selectedCommandId = new FieldModel(selectedCommandId);
    this.commandsFormState = new OpenStateModel(false);

    makeObservable(this, {
      commands: observable,

      addCommand: action,
      removeCommand: action,
      removeButton: action,
      updateCommand: action,

      selectedCommand: computed
    });
  }

  get selectedCommand(): CommandModel {
    return this.selectedCommandId.value
      ? this.commands.getEntity(this.selectedCommandId.value) || this.newCommand
      : this.newCommand;
  }

  unselectCommand = (): void => {
    this.selectedCommandId.changeValue(null);
  };

  closeCommand = (): void => {
    this.unselectCommand();
    this.commandsFormState.close();
  };

  saveNewCommand = async (): Promise<BaseResponse> => {
    const isError = this.newCommand.validate();

    if (isError) {
      return {
        isError: true
      };
    }

    this.newCommand.updatingStage.loading();

    const response = await this.updateMenu({
      commands: [
        ...this.validCommands.map((command) => command.toJson()),
        this.newCommand.toJson()
      ],
      menuButton: this.button.saved.value ? this.button.toJson() : null
    });

    if (response.isError) {
      this.newCommand.updatingStage.error();
    } else {
      const entity = this.newCommand.getCopy();
      entity.saved.changeValue(true);
      this.commands.addEntity({
        entity,
        key: entity.id,
        start: true
      });
      this.newCommand.reset();
      this.selectedCommandId.changeValue(entity.id);
      this.newCommand.updatingStage.success();
    }

    return {
      isError: response.isError
    };
  };

  updateCommand = async (): Promise<BaseResponse> => {
    if (!this.selectedCommandId.value) {
      return {
        isError: true
      };
    }
    const updatedCommand = this.commands.getEntity(
      this.selectedCommandId.value
    );

    if (!updatedCommand) {
      return {
        isError: true
      };
    }

    const isError = updatedCommand.validate();

    if (isError) {
      return {
        isError: true
      };
    }

    updatedCommand.updatingStage.loading();

    const response = await this.updateMenu({
      commands: this.validCommands.map((command) => command.toJson()),
      menuButton: this.button.saved.value ? this.button.toJson() : null
    });
    if (response.isError) {
      updatedCommand.updatingStage.error();
    } else {
      updatedCommand.save();
      updatedCommand.updatingStage.success();
    }

    return {
      isError: response.isError
    };
  };

  updateButton = async (): Promise<BaseResponse> => {
    if (this.button.updatingStage.isLoading) {
      return {
        isError: true
      };
    }

    const isError = this.button.validate();

    if (isError) {
      return {
        isError: true
      };
    }

    this.button.updatingStage.loading();

    const response = await this.updateMenu({
      commands: this.validCommands.map((command) => command.toJson()),
      menuButton: this.button.toJson()
    });

    if (response.isError) {
      this.button.updatingStage.error();
    } else {
      this.button.updatingStage.success();
      this.button.saved.changeValue(true);
    }

    return response;
  };

  removeCommand = async (id: string): Promise<BaseResponse> => {
    const command = this.commands.getEntity(id);

    if (!command) {
      return {
        isError: true
      };
    }

    command.removingStage.loading();

    const response = await this.updateMenu({
      commands: this.validCommands
        .filter((command) => command.id !== id)
        .map((command) => command.toJson()),
      menuButton: this.button.saved.value ? this.button.toJson() : null
    });

    if (response.isError) {
      command.removingStage.error();
    } else {
      this.commands.removeEntity(id);
      this.closeCommand();
      command.removingStage.success();
    }

    return response;
  };

  addCommand = (): void => {
    const command = CommandModel.fromDefaultParams();

    this.commands.addEntity({ entity: command, key: command.id });
  };

  saveCommand = async (): Promise<void> => {
    if (this.selectedCommand.saved.value) {
      this.updateCommand();
    } else {
      this.saveNewCommand();
    }
  };

  get validCommands(): CommandModel[] {
    return this.commands.items.filter((command) => !command.isError);
  }

  removeButton = async (): Promise<BaseResponse> => {
    if (this.removingStage.isLoading) {
      return {
        isError: true
      };
    }
    this.button.removingStage.loading();

    const response = await this.updateMenu({
      commands: this.validCommands.map((command) => command.toJson()),
      menuButton: null
    });

    if (response.isError) {
      this.button.removingStage.error();
    } else {
      this.button.removingStage.success();
      this.button.reset();
    }

    return response;
  };

  private updateMenu = async ({
    commands,
    menuButton
  }: {
    commands: TgCommandServer[];
    menuButton: ChannelTgButtonServer | null;
  }): Promise<BaseResponse> => {
    return this.rootStore.networkStore.api<{}, CommandError>(
      apiUrls.CHANNELS_ADD_TG_MENU,
      {
        method: 'POST',
        data: {
          commands: commands,
          channel_id: this.id,
          menu_button: menuButton
        },
        errorsMap: mapCommandErrorCodeToMessage,
        showUnexpectedError: false
      }
    );
  };

  static fromJson(raw: ChannelTgServer, rootStore: IRootStore): TgChannelModel {
    const collection = raw.tokens.reduce<{
      keys: string[];
      entities: Record<string, ChannelTokenModel>;
    }>(
      (acc, token) => ({
        ...acc,
        keys: [...acc.keys, token._id],
        entities: {
          ...acc.entities,
          [token._id]: ChannelTokenModel.fromJson({
            raw: token,
            rootStore,
            channelId: raw._id
          })
        }
      }),
      { keys: [], entities: {} }
    );

    const commands = raw.extra?.commands
      ? new ListModel<CommandModel>(
          raw.extra.commands.reduce(
            (acc, item) => {
              const commandModel = CommandModel.fromJson(item);

              return {
                ...acc,
                entities: {
                  ...acc.entities,
                  [commandModel.id]: commandModel
                },
                keys: [...acc.keys, commandModel.id]
              };
            },
            { entities: {}, keys: [] }
          )
        )
      : new ListModel<CommandModel>();

    return new TgChannelModel({
      ...normalizeBaseChannel(raw),
      linked: raw.manager_linked,
      commands: commands,
      button: raw.extra?.menu_button
        ? TgButtonMenuModel.fromJson(raw.extra.menu_button)
        : TgButtonMenuModel.fromDefaultParams(),
      rootStore,
      tokens: new ListModel<ChannelTokenModel>(collection),
      scenarioIds: new FieldModel<string[]>(raw.scenario_ids),
      selectedCommandId: null
    });
  }
}
