import { Bucket } from '../bucket';
import { IList } from '../list';
import { ITgCommand } from '../tgCommand';
import { IField } from '../form';
import { ILoadingStageModel } from '../loadingStage';
import { IOpenState } from '../state';

import {
  ChannelKind,
  ChannelKindType,
  ChannelTokenScope,
  ChannelTokenStatus,
  CommonChannelKind
} from './server';

export interface IChannelBasic {
  id: string;
  externalId: string;
  domain: string;
  name: string;
  url: string;
  usersTotal: number;
  tokens: IList<IChannelToken>;
  photoUrl: string | null;
  rootChatId: string;
}

export interface IChannelBase extends IChannelBasic {
  linked: boolean;
  scenarioIds: IField<string[]>;
}

export interface IChannelTgButton {
  readonly text: IField;
  readonly url: IField;
  readonly saved: IField<boolean>;
  readonly removingStage: ILoadingStageModel;
  readonly updatingStage: ILoadingStageModel;
  readonly isError: boolean;
}

export interface IChannelEnabled extends IChannelBasic {
  kind: ChannelKind;
  enabled: boolean;
}

export type ChannelTokenRevokingOptions =
  | {
      value: true;
      revokedAt: Date;
    }
  | { value: false };

export interface IChannelToken {
  id: string;
  channelId: string;
  scopes: ChannelTokenScope[];
  revokingOptions: ChannelTokenRevokingOptions;
  status: ChannelTokenStatus;
  error: string | null;
  readonly removingStage: ILoadingStageModel;
}

export interface IBaseChannelModel extends IChannelBase {
  readonly removingStage: ILoadingStageModel;
  readonly runChannelStage: ILoadingStageModel;
  readonly newToken: IField;
  readonly creatingNewTokenStage: ILoadingStageModel;
  readonly hasNoTokens: boolean;
  readonly hasTokenErrors: boolean;
  readonly hasManyTokenErrors: boolean;

  removeToken(id: string): Promise<BaseResponse>;
  runFromBlock(params: { blockId: string; bucket: Bucket }): Promise<void>;
  addToken(token: IChannelToken): void;
  createToken(): Promise<BaseResponse>;
  handleChangeScenarioIds(scenarioId: string): void;
}

export interface ICommonChannelModel extends IBaseChannelModel {
  kind: CommonChannelKind;
}

export interface ITgChannelModel extends IBaseChannelModel {
  kind: ChannelKind.TELEGRAM;
  commands: IList<ITgCommand>;
  button: IChannelTgButton;
  updateButton(): Promise<BaseResponse>;
  removeButton(): Promise<BaseResponse>;
  removeCommand(id: string): Promise<BaseResponse>;
  updateCommand(id: string): Promise<BaseResponse>;
  readonly selectedCommandId: IField<string | null>;
  readonly selectedCommand: ITgCommand;
  unselectCommand(): void;
  readonly commandsFormState: IOpenState;
  saveCommand(): Promise<void>;
  closeCommand(): void;
}

export type IChannelModel = ITgChannelModel | ICommonChannelModel;

export interface IChannelCreationStore {
  readonly isError: boolean;
  selectedPlatform: ChannelKindType | null;

  save(): Promise<BaseResponse>;
}

export enum MobileChannelsModalTab {
  createChannel = 'createChannel',
  selectChannels = 'selectChannels'
}

export enum PubSubChannelEvent {
  removeScenarioId = 'removeScenarioId',
  addScenarioId = 'addScenarioId'
}

export type PubSubHandleScenarioEventPayload = {
  channelId: string;
  scenarioId: string;
};
