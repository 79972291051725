import { computed, makeObservable } from 'mobx';

import { CabinetCategory, CabinetServer } from 'shared/entities/cabinet';
import {
  validateTextFieldMaxLength,
  ValidatorResult
} from 'shared/entities/validator';

import { FieldModel } from '../form';

export default class CabinetEditModel {
  readonly name: FieldModel<string> = new FieldModel<string>('', [
    validateTextFieldMaxLength
  ]);

  constructor() {
    makeObservable(this, {
      isError: computed
    });
  }

  toJson(): Partial<CabinetServer> {
    return {
      name: this.name.value,
      category: CabinetCategory.other
    };
  }

  get isError(): boolean {
    return this.name.isError;
  }

  validate(): ValidatorResult {
    return this.name.validate();
  }

  reset(): void {
    this.name.reset();
  }

  resetErrors(): void {
    this.name.resetError();
  }
}
