import {
  Bank131Icon,
  CLICKIcon,
  EcompayIcon,
  GlobalPayIcon,
  LeoGamingIcon,
  LiqPayIcon,
  PayMasterIcon,
  PaymegaIcon,
  PaymeIcon,
  PsbIcon,
  SberIcon,
  SmartGlocalIcon,
  StripeIcon,
  YooKassaIcon,
  SettingsBagIcon,
  RobokassaKZIcon
} from 'shared/components/icons';
import { SelectorItem } from 'shared/entities/components/Selector';
import { TgStarIcon } from 'shared/newComponents/icons';

export enum TelegramPaymentKind {
  stripe = 'stripe',
  smartGlocal = 'smart_glocal',
  unlimint = 'unlimint',
  tranzzo = 'tranzzo',
  yookassa = 'yookassa',
  sberbank = 'sberbank',
  psb = 'psb',
  bank131 = 'bank131',
  payme = 'payme',
  click = 'click',
  liqpay = 'liqpay',
  leogaming = 'leogaming',
  cascad = 'cascad',
  portmone = 'Portmone',
  paymega = 'paymega',
  ecommpay = 'ecommpay',
  paymaster = 'paymaster',
  globalPayUz = 'global_pay_uz',
  ipay88 = 'ipay88',
  robokassaKz = 'robokassa_kz',
  stars = 'stars'
}

export const telegramPaymentEntities: Record<
  TelegramPaymentKind,
  SelectorItem
> = {
  [TelegramPaymentKind.stripe]: {
    id: TelegramPaymentKind.stripe,
    title: 'Stripe',
    Icon: StripeIcon
  },
  [TelegramPaymentKind.smartGlocal]: {
    id: TelegramPaymentKind.smartGlocal,
    title: 'Smart Glocal',
    Icon: SmartGlocalIcon
  },
  [TelegramPaymentKind.unlimint]: {
    id: TelegramPaymentKind.unlimint,
    title: 'Unlimint',
    Icon: SettingsBagIcon
  },
  [TelegramPaymentKind.tranzzo]: {
    id: TelegramPaymentKind.tranzzo,
    title: 'Tranzzo',
    Icon: SettingsBagIcon
  },
  [TelegramPaymentKind.yookassa]: {
    id: TelegramPaymentKind.yookassa,
    title: 'ЮKassa',
    Icon: YooKassaIcon
  },
  [TelegramPaymentKind.sberbank]: {
    id: TelegramPaymentKind.sberbank,
    title: 'Сбербанк',
    Icon: SberIcon
  },
  [TelegramPaymentKind.psb]: {
    id: TelegramPaymentKind.psb,
    title: 'ПСБ',
    Icon: PsbIcon
  },
  [TelegramPaymentKind.bank131]: {
    id: TelegramPaymentKind.bank131,
    title: 'Банк 131',
    Icon: Bank131Icon
  },
  [TelegramPaymentKind.payme]: {
    id: TelegramPaymentKind.payme,
    title: 'Pay me',
    Icon: PaymeIcon
  },
  [TelegramPaymentKind.click]: {
    id: TelegramPaymentKind.click,
    title: 'CLICK',
    Icon: CLICKIcon
  },
  [TelegramPaymentKind.liqpay]: {
    id: TelegramPaymentKind.liqpay,
    title: 'LiqPay',
    Icon: LiqPayIcon
  },
  [TelegramPaymentKind.leogaming]: {
    id: TelegramPaymentKind.leogaming,
    title: 'LeoGaming',
    Icon: LeoGamingIcon
  },
  [TelegramPaymentKind.cascad]: {
    id: TelegramPaymentKind.cascad,
    title: 'Cascad',
    Icon: SettingsBagIcon
  },
  [TelegramPaymentKind.portmone]: {
    id: TelegramPaymentKind.portmone,
    title: 'Portmone',
    Icon: SettingsBagIcon
  },
  [TelegramPaymentKind.paymega]: {
    id: TelegramPaymentKind.paymega,
    title: 'Paymega',
    Icon: PaymegaIcon
  },
  [TelegramPaymentKind.ecommpay]: {
    id: TelegramPaymentKind.ecommpay,
    title: 'ECOMMPAY',
    Icon: EcompayIcon
  },
  [TelegramPaymentKind.paymaster]: {
    id: TelegramPaymentKind.paymaster,
    title: 'PayMaster',
    Icon: PayMasterIcon
  },
  [TelegramPaymentKind.globalPayUz]: {
    id: TelegramPaymentKind.globalPayUz,
    title: 'Global Pay',
    Icon: GlobalPayIcon
  },
  [TelegramPaymentKind.ipay88]: {
    id: TelegramPaymentKind.ipay88,
    title: 'ipay88',
    Icon: SettingsBagIcon
  },
  [TelegramPaymentKind.robokassaKz]: {
    id: TelegramPaymentKind.robokassaKz,
    title: 'Robokassa.KZ',
    Icon: RobokassaKZIcon
  },
  [TelegramPaymentKind.stars]: {
    id: TelegramPaymentKind.stars,
    title: 'Telegram Stars',
    Icon: TgStarIcon
  }
};

export const telegramPaymentOrder: TelegramPaymentKind[] = [
  TelegramPaymentKind.yookassa,
  TelegramPaymentKind.psb,
  TelegramPaymentKind.stripe,
  TelegramPaymentKind.smartGlocal,
  TelegramPaymentKind.unlimint,
  TelegramPaymentKind.tranzzo,
  TelegramPaymentKind.bank131,
  TelegramPaymentKind.click,
  TelegramPaymentKind.leogaming,
  TelegramPaymentKind.cascad,
  TelegramPaymentKind.portmone,
  TelegramPaymentKind.paymega,
  TelegramPaymentKind.ecommpay,
  TelegramPaymentKind.globalPayUz,
  TelegramPaymentKind.ipay88,
  TelegramPaymentKind.robokassaKz
];
