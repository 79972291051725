import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const GetCourseIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M9.10654 16.2267C8.31665 16.8918 7.40728 17.2244 6.37837 17.2244C5.66125 17.2244 4.97532 17.0997 4.32056 16.8502C3.6658 16.5905 3.09419 16.2163 2.60572 15.7278C2.11724 15.2394 1.72751 14.6418 1.43651 13.935C1.1455 13.2284 1 12.4229 1 11.5187C1 10.7392 1.1455 10.0169 1.43651 9.35174C1.72751 8.67619 2.12764 8.09419 2.6369 7.60572C3.14615 7.10685 3.74375 6.71712 4.42969 6.43651C5.12601 6.14551 5.87431 6 6.67457 6C7.17343 6 7.65149 6.05716 8.10884 6.17148C8.56611 6.27541 8.98701 6.43651 9.37152 6.65476C9.7665 6.86262 10.1198 7.11724 10.4317 7.41864C10.7434 7.70964 10.9981 8.04222 11.1955 8.41637L8.93503 10.1468C8.71683 9.7103 8.38941 9.36213 7.95292 9.10231C7.5164 8.83209 7.05392 8.69698 6.56544 8.69698C6.20169 8.69698 5.86912 8.76973 5.56772 8.91523C5.26632 9.06074 5.0065 9.2634 4.78825 9.52322C4.56999 9.78305 4.3985 10.0948 4.27379 10.4586C4.14907 10.812 4.08672 11.2017 4.08672 11.6278C4.08672 12.0643 4.14907 12.4593 4.27379 12.8126C4.3985 13.166 4.56999 13.4725 4.78825 13.7324C5.01689 13.9818 5.28711 14.1793 5.59889 14.3248C5.91069 14.4599 6.25885 14.5274 6.64339 14.5274C7.5268 14.5274 8.30626 14.1637 8.98181 13.4362H7.06431V11.2537H11.6165V17.1465H9.10654V16.2267Z"
        fill="#2C3E50"
      />
      <path
        d="M12.4216 11.5624C12.4216 10.8869 12.5464 10.2217 12.7958 9.56697C13.0556 8.91221 13.4298 8.32501 13.9183 7.80536C14.4067 7.28571 14.9991 6.86479 15.6955 6.54262C16.3918 6.22043 17.1869 6.05934 18.0806 6.05934C19.1511 6.05934 20.0761 6.28279 20.8555 6.72969C21.6454 7.16619 22.2326 7.7482 22.6172 8.47571L20.2943 10.1282C20.1905 9.85797 20.0501 9.63453 19.8734 9.45785C19.7071 9.28117 19.5201 9.14086 19.3122 9.03693C19.1043 8.933 18.8861 8.86025 18.6575 8.81868C18.4392 8.7771 18.2262 8.75632 18.0183 8.75632C17.5818 8.75632 17.2025 8.84466 16.8803 9.02134C16.5685 9.18763 16.3086 9.41108 16.1008 9.69169C15.9033 9.96191 15.7526 10.2737 15.6487 10.6271C15.5551 10.97 15.5084 11.313 15.5084 11.656C15.5084 12.0405 15.5655 12.4095 15.6799 12.7628C15.7942 13.1162 15.9605 13.4279 16.1787 13.6982C16.397 13.9684 16.662 14.1867 16.9738 14.353C17.296 14.5088 17.6546 14.5868 18.0495 14.5868C18.2573 14.5868 18.4704 14.566 18.6886 14.5244C18.9069 14.4725 19.1147 14.3945 19.3122 14.2906C19.5201 14.1763 19.7019 14.0359 19.8578 13.8697C20.0241 13.693 20.1541 13.4799 20.2476 13.2305L22.7263 14.7115C22.5601 15.1168 22.3054 15.4806 21.9625 15.8027C21.6298 16.125 21.2453 16.3951 20.8088 16.6134C20.3827 16.8317 19.9202 16.9979 19.4214 17.1123C18.9329 17.2266 18.4548 17.2838 17.9871 17.2838C17.1661 17.2838 16.4126 17.1227 15.7266 16.8005C15.0511 16.4679 14.4639 16.0314 13.965 15.491C13.4766 14.9506 13.0972 14.3374 12.827 13.6514C12.5568 12.9655 12.4216 12.2691 12.4216 11.5624Z"
        fill="#22C2B5"
      />
    </BaseIcon>
  );
};

export default GetCourseIcon;
