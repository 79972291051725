export class HttpError extends Error {
  constructor(message: string | undefined) {
    super(message);
    this.name = 'HttpError';
  }
}

export class NotFoundError extends Error {
  constructor(message: string | undefined) {
    super(message);
    this.name = 'NotFoundError';
  }
}

export class CarrotQuestError extends Error {
  constructor(message?: string | undefined) {
    super(message);
    this.name = 'CarrotQuestError';
  }
}

export class TimeoutError extends Error {
  constructor(message: string | undefined) {
    super(message);
    this.name = 'TimeoutError';
  }
}

export class BadRequestError extends Error {
  constructor(message?: string | undefined) {
    super(message);
    this.name = 'BadRequestError';
  }
}
