import { REG_EXP_CIRILLICA } from 'shared/entities/regExp/url';

import getURLObjectFromUrl from './getURLObjectFromUrl';

const getUrlWithoutProtocol = (url: string): string | null => {
  if (!url.match(REG_EXP_CIRILLICA)) {
    const urlObj = getURLObjectFromUrl(url);

    if (!urlObj) {
      return null;
    }

    return urlObj.host + urlObj.pathname + urlObj.search + urlObj.hash;
  }

  const matchGroups = url.match(
    /^((\w+:\/\/)*([-a-zA-Z0-9а-яА-Яё()@:%_+.~#?&//=]+))/
  );

  if (!matchGroups) {
    return null;
  }
  return matchGroups[3];
};

export default getUrlWithoutProtocol;
