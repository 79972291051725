import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const RussiaIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <clipPath id="a">
        <path d="m3 5h18v14h-18z" />
      </clipPath>
      <g clipPath="url(#a)" clipRule="evenodd" fillRule="evenodd">
        <path d="m3 5h18v14h-18z" fill="#fff" />
        <path d="m3 9.66669h18v9.33331h-18z" fill="#0039a6" />
        <path d="m3 14.3333h18v4.6667h-18z" fill="#d52b1e" />
      </g>
    </BaseIcon>
  );
};

export default RussiaIcon;
