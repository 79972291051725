import { BaseResponseCode } from 'shared/entities/network';
import { TranslationString } from 'shared/entities/localization';

export enum ManagerAddingErrorCode {
  managerNotFound = 'manager_not_found'
}

export const mapManagerAddingErrorCodeToMessage =
  (
    code: ManagerAddingErrorCode | BaseResponseCode.conflict
  ): TranslationString =>
  (t) =>
    t(`manager.addingCabinetManagerErrors.${code}`, {
      ns: 'entities'
    });
