import { observer } from 'mobx-react';
import * as React from 'react';

import {
  OpenedChannelKind,
  openedChannelKindOrder
} from 'shared/entities/channels';
import Channel from 'shared/newComponents/Channel';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { useAnalyticsStore } from 'stores/index';
import { AnalyticsEvent } from 'shared/entities/analytics';

import './ChannelKinds.modules.scss';

type Props = {
  className?: string;
};

const ChannelKinds: React.FC<Props> = ({ className }) => {
  const { store } = useChannelsModalStore();
  const analyticsStore = useAnalyticsStore();

  const handleClick = React.useCallback((kind: OpenedChannelKind | null) => {
    if (kind) {
      analyticsStore.sendEvent(AnalyticsEvent.creationChannelOpenForm, {
        channel_kind: kind
      });
    }
    store.selectedChannelKind.changeValue(kind);
  }, []);

  return (
    <div styleName="kinds" className={className}>
      {[...openedChannelKindOrder].map((kind) => (
        <Channel
          key={kind}
          kind={kind as OpenedChannelKind}
          onClick={handleClick}
          selected={store.selectedChannelKind.value === kind}
        />
      ))}
    </div>
  );
};

export default observer(ChannelKinds);
