const copyToClipboard = async (text: string): Promise<BaseResponse> => {
  try {
    await navigator.clipboard.writeText(text);
    return {
      isError: false
    };
  } catch (e) {
    return {
      isError: true
    };
  }
};

export default copyToClipboard;
