import { isEqual, isValid } from 'date-fns';

import {
  Validator,
  ValidatorResult,
  validatorTransFunctions
} from '../../validator';
import { DateFormat } from '../client';

import { parseDate } from './transformDate';

export const validateIsDate: Validator<Date | null> = (value) => {
  return isValid(value)
    ? null
    : (t) => t('validators.errors.invalidDate', { ns: 'entities' });
};

export const validateDate = (
  date: string | null,
  withoutTime = false
): ValidatorResult => {
  if (!date) {
    return validatorTransFunctions.requiredField;
  }

  const dateObj = withoutTime
    ? parseDate(date, DateFormat.time)
    : parseDate(date, DateFormat.dateTime);

  return dateObj
    ? null
    : (t) =>
        t('validators.errors.invalidDateValue', {
          ns: 'entities'
        });
};

export const validateDateInterval = (
  first: Date | null,
  second: Date | null
): ValidatorResult => {
  if (!first || !second) {
    return (t) =>
      t('validators.errors.invalidDateInterval', {
        ns: 'entities'
      });
  }

  return isEqual(first, second) || first > second
    ? (t) =>
        t('validators.errors.invalidDateInterval', {
          ns: 'entities'
        })
    : null;
};
