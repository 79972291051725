import { computed, makeObservable, observable } from 'mobx';

import { BaseResponseCodeType, SmartIds } from 'shared/entities/network';
import { IRootStore } from 'shared/entities/store/rootStore';
import { AppPlugins } from 'shared/entities/app';
import { IAuthFlowStore } from 'shared/entities/store/authFlowStore';
import DomainsStore from 'stores/integrationsStore/domainsStore';
import GoogleStore from 'stores/integrationsStore/googleStore';
import ApiIntegrationStore from 'stores/integrationsStore/apiIntegrationStore';
import GetCourseStore from 'stores/integrationsStore/getCourseStore';
import UserPaymentsStore from 'stores/integrationsStore/userPaymentsStore';
import AmoCRMStore from 'stores/integrationsStore/amoCRMStore';
import { KeyboardEventsStore } from 'stores/KeyboardEventsStore';
import { ShopbackStore } from 'stores/shopback/ShopbackStore';
import { ICabinetModel } from 'shared/entities/cabinet';
import { SmartbotAIInfoStore } from 'stores/smartbotAIInfoStore';

import { SettingsStore } from '../settingsStore';
import { AuthFlowStore } from '../authFlowStore';
import { RouterStore } from '../routerStore';
import { ABStore } from '../aBStore';
import { ListsStore } from '../listsStore';
import { CabinetStore } from '../cabinetStore';
import { ProjectStore } from '../projectStore';
import { ChannelsStore } from '../channelsStore';
import { UserStore } from '../userStore';
import { CabinetsStore } from '../cabinetsStore';
import { ProjectsStore } from '../projectsStore';
import { VariablesStore } from '../variablesStore';
import { JobsStore } from '../jobsStore';
import { ChatsStore } from '../chatsStore';
import { AppNotificationsStore } from '../appNotificationsStore';
import { UiStore } from '../uiStore';
import { PermissionsStore } from '../permissionsStore';
import { NetworkStore } from '../networkStore';
import { PluginsStore } from '../pluginsStore';
import { AnalyticsStore } from '../analyticsStore';
import { CabinetManagersStore } from '../managers/cabinetManagersStore';
import { PaymentStore } from '../paymentStore';
import { IntegrationsStore } from '../integrationsStore';
import { WebSocketStore } from '../webSocketStore';
import { TranslationsStore } from '../translationsStore';
import { SupportPopupStore } from '../supportPopupStore';
import { SmartbotAIBotsStore } from '../smartbotAIBotsStore/SmartbotAIBotsStore';

export default class RootStore implements IRootStore {
  analyticsStore: AnalyticsStore = new AnalyticsStore(this);

  uiStore: UiStore = new UiStore(this);

  variablesStore: VariablesStore = new VariablesStore(this);

  userStore: UserStore;

  cabinetsStore: CabinetsStore = new CabinetsStore(this);

  projectsStore: ProjectsStore = new ProjectsStore(this);

  // стор для хранения текущего проекта
  projectStore: ProjectStore = new ProjectStore(this);

  // стор для хранения текущего кабинета
  cabinetStore: CabinetStore = new CabinetStore(this);

  channelsStore = new ChannelsStore(this);

  // стор авторизации
  authFlowStore: IAuthFlowStore;

  routerStore: RouterStore = new RouterStore();

  jobsStore: JobsStore = new JobsStore(this);

  aBStore: ABStore = new ABStore(this);

  supportPopupStore: SupportPopupStore = new SupportPopupStore(this);

  chatsStore: ChatsStore = new ChatsStore(this);

  listsStore: ListsStore = new ListsStore(this);

  cabinetManagersStore: CabinetManagersStore = new CabinetManagersStore(this);

  pluginsStore: PluginsStore;

  appNotificationsStore: AppNotificationsStore = new AppNotificationsStore(
    this
  );

  permissionsStore: PermissionsStore = new PermissionsStore(this);

  networkStore: NetworkStore = new NetworkStore(this);

  paymentStore: PaymentStore = new PaymentStore(this);

  settingsStore: SettingsStore = new SettingsStore(this);

  integrationsStore: IntegrationsStore = new IntegrationsStore(this);

  websocketStore: WebSocketStore = new WebSocketStore(this);

  translationsStore: TranslationsStore;

  keyboardEventsStore: KeyboardEventsStore = new KeyboardEventsStore();

  shopbackStore: ShopbackStore = new ShopbackStore(this);

  smartbotAIBotsStore: SmartbotAIBotsStore = new SmartbotAIBotsStore(this);

  smartbotAIInfoStore: SmartbotAIInfoStore = new SmartbotAIInfoStore(this);

  constructor({
    plugins,
    translationsStore
  }: {
    plugins?: AppPlugins;
    translationsStore: TranslationsStore;
  }) {
    this.userStore = new UserStore(this);
    this.pluginsStore = new PluginsStore(this, plugins);
    this.authFlowStore = plugins?.auth
      ? new plugins.auth.Store(this)
      : new AuthFlowStore(this);
    this.translationsStore = translationsStore;

    makeObservable(this, {
      cabinetStore: observable,
      projectStore: observable,
      permissionsStore: observable,
      routerStore: observable,

      cabinetDomain: computed,
      cabinetId: computed,
      projectId: computed,
      initialized: computed,
      cabinetInitialized: computed,
      projectName: computed
    });
  }

  get userPaymentsStore(): UserPaymentsStore {
    return this.integrationsStore.userPaymentsStore;
  }

  get domainsStore(): DomainsStore {
    return this.integrationsStore.domainsStore;
  }

  get googleStore(): GoogleStore {
    return this.integrationsStore.googleStore;
  }

  get apiIntegrationStore(): ApiIntegrationStore {
    return this.integrationsStore.apiIntegrationStore;
  }

  get getCourseStore(): GetCourseStore {
    return this.integrationsStore.getCourseStore;
  }

  get amoCRMStore(): AmoCRMStore {
    return this.integrationsStore.amoCRMStore;
  }

  get cabinetDomain(): string | null {
    const cabinet = this.cabinetStore.entity;
    return cabinet ? cabinet.domain : null;
  }

  get cabinetId(): string | null {
    const cabinet = this.cabinetStore.entity;
    return cabinet ? cabinet.id : null;
  }

  get projectId(): string | null {
    return this.projectStore.projectId;
  }

  get projectName(): string {
    return this.projectStore.entity?.name || '';
  }

  get cabinetInitialized(): boolean {
    return this.cabinetStore.initialized;
  }

  get initialized(): boolean {
    return this.projectStore.initialized && this.cabinetStore.initialized;
  }

  initComponentLoadingStoresReactions = (): void => {
    this.cabinetsStore.initReactions();
    this.channelsStore.initReactions();
    this.listsStore.initReactions();
    this.variablesStore.initReactions();
    this.cabinetManagersStore.initReactions();
    this.settingsStore.initReactions();
    this.integrationsStore.initReactions();
    this.shopbackStore.initReactions();
    this.uiStore.initReactions();
    this.smartbotAIBotsStore.initReactions();

    this.keyboardEventsStore.addEventListener();
  };

  disposeAllComponentLoadingStoresReactions = (): void => {
    this.cabinetsStore.disposeAll();
    this.channelsStore.disposeAll();
    this.listsStore.disposeAll();
    this.variablesStore.disposeAll();
    this.cabinetManagersStore.disposeAll();
    this.settingsStore.disposeAll();
    this.integrationsStore.disposeAll();
    this.shopbackStore.disposeAll();
    this.uiStore.disposeAll();
    this.smartbotAIBotsStore.disposeAll();

    this.keyboardEventsStore.removeEventListener();
  };

  createData<T = Record<string, any>>(
    data: T
  ): {
    [SmartIds.cabinetId]: string;
    [SmartIds.projectId]: string;
  } & T {
    return {
      [SmartIds.cabinetId]: this.cabinetId || '',
      [SmartIds.projectId]: this.projectId || '',
      ...data
    };
  }

  async initCabinet(
    cabinetDomain: string
  ): Promise<
    BaseResponse<ICabinetModel, { code: BaseResponseCodeType | null }>
  > {
    return this.cabinetStore.load(cabinetDomain);
  }

  static async init(plugins?: AppPlugins): Promise<RootStore> {
    const translationsStore = await TranslationsStore.init(
      plugins?.internationalDomain?.turnOn || false
    );

    return new RootStore({
      translationsStore,
      plugins
    });
  }
}
