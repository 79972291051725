import {
  SmartBlockType,
  SmartEndpointColor,
  SmartSourceParams
} from 'lib/smart-graph/entities/block';
import {
  TFunctionType,
  TranslationMap,
  TranslationString
} from 'shared/entities/localization';

import { Point } from '../../geometry';
import { IRootStore } from '../../store/rootStore';

export type TransitionValue = string | null;

export type TransitionsMap = Record<string, TransitionValue>;

export enum BlockKind {
  // input
  chatMessage = 'chat_message',
  portalIn = 'portal_in',
  preFilter = 'pre_filter',
  mailingSend = 'mailing_send',
  commentAdd = 'comment_add',
  webAppData = 'web_app_data',
  webhook = 'webhook',
  defaultAnswer = 'default_answer',
  firstMessage = 'first_message',
  groupJoin = 'group_join',
  messageAllow = 'message_allow',
  messageDeny = 'message_deny',
  groupLeave = 'group_leave',
  likeAdd = 'like_add',
  likeRemove = 'like_remove',
  wallRepost = 'wall_repost',
  vkMarketItemMessage = 'vk_market_item_message',
  vkFormLead = 'lead_forms_new',
  commentRemove = 'comment_remove',

  // condition
  basic = 'basic',
  isGroupMember = 'is_group_member',
  conditionSwitch = 'switch',
  randomChoice = 'random_choice',

  // action
  sendMessage = 'send_message',
  setVar = 'set_vars',
  userInput = 'user_input',
  extractData = 'extract_data',
  sendStats = 'write_stats_event',
  executeSq = 'exec_sq',
  addToList = 'add_to_list',
  removeFromList = 'remove_from_list',
  portalOut = 'portal_out',
  planEvent = 'plan_event',
  cancelEvent = 'cancel_event',
  httpRequest = 'http_request',
  notifyManagers = 'notify_managers',
  readGoogleSheets = 'read_google_sheets',
  setCellGoogleSheets = 'set_cell_google_sheets',
  addRowGoogleSheets = 'add_row_google_sheets',
  preFilterBreak = 'pre_filter_break',
  preFilterContinue = 'pre_filter_continue',
  sendEmail = 'send_email',
  setTimer = 'set_timer',
  switchContext = 'switch_context',
  notifyOperator = 'notify_operator',
  communicateAI = 'communicate_ai',
  askGpt = 'ask_gpt',
  tgSendDice = 'tg_send_dice',
  kickFromChat = 'kick_from_chat',

  // GetCourse
  getCourseUserImport = 'getcourse_user_import',
  getCourseDealImport = 'getcourse_deal_import',
  // amoCRM
  amoCRMAddLead = 'amocrm_add_lead',
  amoCRMEditLead = 'amocrm_edit_lead',

  //information
  note = 'note'
}

export type BlockKindType = BlockKind | string;

export enum BlockType {
  condition = 'condition',
  input = 'input',
  action = 'action',
  information = 'information',
  mailing = 'mailing'
}

export enum BlockSection {
  conditions = 'conditions',
  inputs = 'inputs',
  actions = 'actions',
  integrations = 'integrations',
  extra = 'extra'
}

export const mapBlockSectionToTitle: TranslationMap<
  BlockSection,
  TranslationString
> = {
  [BlockSection.inputs]: (t) =>
    t('block.common.mapBlockSectionToTitle.inputs', { ns: 'entities' }),
  [BlockSection.actions]: (t) =>
    t('block.common.mapBlockSectionToTitle.actions', { ns: 'entities' }),
  [BlockSection.conditions]: (t) =>
    t('block.common.mapBlockSectionToTitle.conditions', { ns: 'entities' }),
  [BlockSection.integrations]: (t) =>
    t('block.common.mapBlockSectionToTitle.integrations', { ns: 'entities' }),
  [BlockSection.extra]: (t) =>
    t('block.common.mapBlockSectionToTitle.extra', { ns: 'entities' })
};

export enum BlockTag {}

export type BlockStatsServer = {
  executed_count: number;
};

export type BlockBaseServer<T extends BlockType, K extends BlockKindType> = {
  _id: string;
  scenario_id: string;
  type: T;
  kind: K;
  stats?: BlockStatsServer;
};

export type EditableAttrsBlockServer<
  Params extends Record<string, any> | null
> = {
  transitions: TransitionsMap;
  params: Params extends null ? {} : Params;
  ui_state: Point;
  name?: string;
  tags?: BlockTag[];
};

export enum Port {
  DEFAULT = 'default',
  MAIN_PORT_NAME = 'ok',
  TIMEOUT_PORT_NAME = 'timeout',
  ERROR_PORT_NAME = 'error',
  TARGET_PORT_NAME = 'target',
  YES = 'yes',
  NO = 'no',
  FIRST_ANSWER_PORT_NAME = 'first_answer'
}

export const mapPortToTitle: TranslationMap<Port> = Object.values(Port).reduce<
  TranslationMap<Port, TranslationString>
>(
  (acc, item) => ({
    ...acc,
    [item]: (t: TFunctionType) =>
      t(`block.common.mapPortToTitle.${item}`, {
        ns: 'entities'
      })
  }),
  {} as TranslationMap<Port, TranslationString>
);

export const mapBlockKindToSmartBlockType: Record<BlockType, SmartBlockType> = {
  [BlockType.input]: SmartBlockType.input,
  [BlockType.mailing]: SmartBlockType.input,
  [BlockType.action]: SmartBlockType.action,
  [BlockType.information]: SmartBlockType.action,
  [BlockType.condition]: SmartBlockType.condition
};

export const mapPortToSmartSourceParams: Record<
  Port,
  Omit<SmartSourceParams, 'color'>
> = {
  [Port.MAIN_PORT_NAME]: {
    anchors: ['Bottom']
  },
  [Port.YES]: {
    anchors: ['Right']
  },
  [Port.NO]: {
    anchors: ['Left']
  },
  [Port.TARGET_PORT_NAME]: {
    anchors: ['Right']
  },
  [Port.DEFAULT]: {
    anchors: ['Bottom']
  },
  [Port.ERROR_PORT_NAME]: {
    anchors: ['Bottom']
  },
  [Port.TIMEOUT_PORT_NAME]: {
    anchors: ['Bottom']
  },
  [Port.FIRST_ANSWER_PORT_NAME]: {
    anchors: ['Right']
  }
};

export const mapBlockTypeToCommonSmartEndpointColor: Record<
  BlockType,
  SmartEndpointColor
> = {
  [BlockType.input]: SmartEndpointColor.blue,
  [BlockType.mailing]: SmartEndpointColor.blue,
  [BlockType.action]: SmartEndpointColor.gray,
  [BlockType.condition]: SmartEndpointColor.gray,
  [BlockType.information]: SmartEndpointColor.gray
};

export const itemPortSmartSourceParams: SmartSourceParams = {
  anchors: ['Right'],
  color: SmartEndpointColor.gray,
  nested: true
};

export type CreateActualNameParams<P, T> = {
  params: P;
  extraInfo: T;
  rootStore: IRootStore;
  t: TFunctionType;
};

export type ActualNameCreator<P, T = null> = (
  params: CreateActualNameParams<P, T>
) => string;
