import { makeObservable, action } from 'mobx';

import BaseEditStore from 'stores/editStore/BaseEditStore';
import { validateTextField } from 'shared/entities/validator';
import { IStatLabelsStore } from 'shared/entities/store/statLabelsStore';
import { StatLabelEditModel } from 'shared/models/stats';
import { mapErrorCodeToMessage } from 'shared/entities/common/utils';
import {
  IStatLabel,
  mapStatLabelCreatingErrorCodeToMessage
} from 'shared/entities/stats';

export default class StatLabelEditStore extends BaseEditStore<StatLabelEditModel> {
  validators = {
    title: [validateTextField]
  };

  statLabelsStore: IStatLabelsStore;

  constructor({ statLabelsStore }: { statLabelsStore: IStatLabelsStore }) {
    super({
      initialData: new StatLabelEditModel({
        title: ''
      })
    });
    this.statLabelsStore = statLabelsStore;

    makeObservable(this, {
      create: action
    });
  }

  async create(): Promise<BaseResponse<IStatLabel>> {
    const params = this.save();

    if (!params) {
      return {
        isError: true
      };
    }
    const response = await this.statLabelsStore.create(params.title);

    if (response.isError && response.data.errorCode) {
      this.setError(
        'title',
        mapErrorCodeToMessage({
          errorCode: response.data.errorCode,
          map: mapStatLabelCreatingErrorCodeToMessage
        })
      );
    }

    if (!response.isError) {
      this.reset();
      return { isError: false, data: response.data.entity };
    }

    return { isError: true };
  }
}
