import { BaseResponseCode } from '../network';
import { TranslationString } from '../localization';
import { SelectorEntities } from '../components/Selector';
import { FileBaseServer } from '../file';

import { ProjectCommonVarType } from './client';

export enum VarSpecial {
  messageText = '%message_text%',
  firstName = '%first_name%',
  lastName = '%last_name%',
  username = '%username%',
  sex = '%sex%',
  birthdate = '%birthdate%',
  userOid = '%user_oid%',
  //внешний
  userId = '%user_id%',
  channelKind = '%channel_kind%',
  channelOid = '%channel_oid%',
  channelId = '%channel_id%',
  time = '%time%',
  date = '%date%',
  datetime = '%datetime%',
  minute = '%minute%',
  hour = '%hour%',
  day = '%day%',
  month = '%month%',
  year = '%year%',
  weekday = '%weekday%',
  timestamp = '%timestamp%',
  messagesReceived = '%messages_received%',
  realm = '%realm%',
  maxInputConfidence = '%max_input_confidence%',
  likedId = '%liked_id%',
  likedType = '%liked_type%',
  postId = '%post_id%',
  ref = '%ref%',
  refSource = '%ref_source%',
  userLink = '%user_link%',
  userLat = '%user_lat%',
  userLong = '%user_long%',
  rawEvent = '%raw_event%',
  productPrice = '%product_price%',
  productTitle = '%product_title%',
  productId = '%product_id%',
  publicApiData = '%public_api_data%',
  webAppData = '%web_app_data%',
  vkFormLeadId = '%vk_form_lead_id%',
  vkFormId = '%vk_form_id%',
  vkFormName = '%vk_form_name%',
  vkFormAdId = '%vk_form_ad_id%',
  vkFormAnswers = '%vk_form_answers%',
  amocrmContactId = '%amocrm_contact_id%',
  amocrmLeadId = '%amocrm_lead_id%',
  chatId = '%chat_id%',
  chatOid = '%chat_oid%',
  chatTitle = '%chat_title%',
  shopbackData = '%shopback_data%',
  shopbackText = '%shopback_text%',
  shopbackTotal = '%shopback_total%',
  shopbackTotalStr = '%shopback_total_str%',
  paymentId = '%payment_id%',
  paymentOId = '%payment_oid%',
  aiMessageText = '%ai_message_text%',
  externalChannelKind = '%external_channel_kind%',
  wazzup24LeadId = '%wazzup24_lead_id%',
  tgSendDiceValue = '%tg_send_dice_value%'
}

export enum VarLevel {
  project = 'project',
  user = 'user'
}

export enum VarType {
  str = 'str',
  num = 'num',
  list = 'list',
  dict = 'dict',
  bool = 'bool',
  attachment = 'attachment',
  expr = 'expr',
  date = 'date',
  datetime = 'datetime'
}

export enum TemporaryVarType {
  temporary = 'temporary'
}

export type VarExtraType = VarType | TemporaryVarType;

export type CommonVarType =
  | VarType.str
  | VarType.num
  | VarType.bool
  | VarType.attachment
  | VarType.dict
  | VarType.expr
  | VarType.list;

export type DateVarType = VarType.date | VarType.datetime;

export enum VarBoolValue {
  True = 'True',
  False = 'False'
}

export enum VarRealmValue {
  bot = 'bot',
  chat = 'chat',
  comments = 'comments',
  businessAccount = 'business_account'
}

export enum VarLikedType {
  video = 'video',
  photo = 'photo',
  comment = 'comment',
  post = 'post',
  note = 'note',
  topicComment = 'topic_comment',
  photoComment = 'photo_comment',
  videoComment = 'video_comment',
  market = 'market',
  marketComment = 'market_comment'
}

export const varLikedTypeEntities: SelectorEntities<
  VarLikedType,
  TranslationString
> = {
  [VarLikedType.video]: {
    id: VarLikedType.video,
    title: (t) =>
      t('vars.varLikedType.video', {
        ns: 'entities'
      })
  },
  [VarLikedType.photo]: {
    id: VarLikedType.photo,
    title: (t) =>
      t('vars.varLikedType.photo', {
        ns: 'entities'
      })
  },
  [VarLikedType.comment]: {
    id: VarLikedType.comment,
    title: (t) =>
      t('vars.varLikedType.comment', {
        ns: 'entities'
      })
  },
  [VarLikedType.post]: {
    id: VarLikedType.post,
    title: (t) =>
      t('vars.varLikedType.post', {
        ns: 'entities'
      })
  },
  [VarLikedType.topicComment]: {
    id: VarLikedType.topicComment,
    title: (t) =>
      t('vars.varLikedType.topicComment', {
        ns: 'entities'
      })
  },
  [VarLikedType.photoComment]: {
    id: VarLikedType.photoComment,
    title: (t) =>
      t('vars.varLikedType.photoComment', {
        ns: 'entities'
      })
  },
  [VarLikedType.videoComment]: {
    id: VarLikedType.videoComment,
    title: (t) =>
      t('vars.varLikedType.videoComment', {
        ns: 'entities'
      })
  },
  [VarLikedType.market]: {
    id: VarLikedType.market,
    title: (t) =>
      t('vars.varLikedType.market', {
        ns: 'entities'
      })
  },
  [VarLikedType.marketComment]: {
    id: VarLikedType.marketComment,
    title: (t) =>
      t('vars.varLikedType.marketComment', {
        ns: 'entities'
      })
  },
  [VarLikedType.note]: {
    id: VarLikedType.note,
    title: (t) =>
      t('vars.varLikedType.note', {
        ns: 'entities'
      })
  }
};

export const varLikedTypeOrder: VarLikedType[] = [
  VarLikedType.post,
  VarLikedType.photo,
  VarLikedType.comment,
  VarLikedType.video,
  VarLikedType.topicComment,
  VarLikedType.photoComment,
  VarLikedType.videoComment,
  VarLikedType.market,
  VarLikedType.marketComment,
  VarLikedType.note
];

export const varRealmValueEntities: SelectorEntities<
  VarRealmValue,
  TranslationString
> = {
  [VarRealmValue.bot]: {
    id: VarRealmValue.bot,
    title: (t) =>
      t('vars.varRealm.bot', {
        ns: 'entities'
      })
  },
  [VarRealmValue.chat]: {
    id: VarRealmValue.chat,
    title: (t) =>
      t('vars.varRealm.chat', {
        ns: 'entities'
      })
  },
  [VarRealmValue.comments]: {
    id: VarRealmValue.comments,
    title: (t) =>
      t('vars.varRealm.comments', {
        ns: 'entities'
      })
  },
  [VarRealmValue.businessAccount]: {
    id: VarRealmValue.businessAccount,
    title: (t) =>
      t('vars.varRealm.business_account', {
        ns: 'entities'
      })
  }
};

export const orderRealmValue: VarRealmValue[] = [
  VarRealmValue.bot,
  VarRealmValue.chat,
  VarRealmValue.comments,
  VarRealmValue.businessAccount
];

export const varTypeEntities: SelectorEntities<VarType, TranslationString> = {
  [VarType.num]: {
    id: VarType.num,
    title: (t) =>
      t('vars.types.num', {
        ns: 'entities'
      })
  },
  [VarType.str]: {
    id: VarType.str,
    title: (t) =>
      t('vars.types.str', {
        ns: 'entities'
      })
  },
  [VarType.list]: {
    id: VarType.list,
    title: (t) =>
      t('vars.types.list', {
        ns: 'entities'
      })
  },
  [VarType.dict]: {
    id: VarType.dict,
    title: (t) =>
      t('vars.types.dict', {
        ns: 'entities'
      })
  },
  [VarType.bool]: {
    id: VarType.bool,
    title: (t) =>
      t('vars.types.bool', {
        ns: 'entities'
      })
  },
  [VarType.attachment]: {
    id: VarType.attachment,
    title: (t) =>
      t('vars.types.attachment', {
        ns: 'entities'
      })
  },
  [VarType.expr]: {
    id: VarType.expr,
    title: (t) =>
      t('vars.types.expr', {
        ns: 'entities'
      })
  },
  [VarType.date]: {
    id: VarType.date,
    title: (t) =>
      t('vars.types.date', {
        ns: 'entities'
      })
  },
  [VarType.datetime]: {
    id: VarType.datetime,
    title: (t) =>
      t('vars.types.datetime', {
        ns: 'entities'
      })
  }
};

export const mapVarLevelToVarTypeOrder: Record<VarLevel, VarType[]> = {
  [VarLevel.user]: [
    VarType.num,
    VarType.str,
    VarType.list,
    VarType.dict,
    VarType.bool,
    VarType.attachment,
    VarType.date,
    VarType.datetime
  ],
  [VarLevel.project]: [
    VarType.num,
    VarType.str,
    VarType.list,
    VarType.dict,
    VarType.bool,
    VarType.attachment,
    VarType.expr,
    VarType.date,
    VarType.datetime
  ]
};

export const varLevelEntities: SelectorEntities<VarLevel> = {
  [VarLevel.project]: {
    id: VarLevel.project,
    title: (t) =>
      t('vars.levels.project', {
        ns: 'entities'
      })
  },
  [VarLevel.user]: {
    id: VarLevel.user,
    title: (t) =>
      t('vars.levels.user', {
        ns: 'entities'
      })
  }
};

export const varLevelOrder: VarLevel[] = [VarLevel.user, VarLevel.project];

export const varBoolEntities: SelectorEntities<
  VarBoolValue,
  TranslationString
> = {
  [VarBoolValue.True]: {
    id: VarBoolValue.True,
    title: (t) => t('vars.varBool.true', { ns: 'entities' })
  },
  [VarBoolValue.False]: {
    id: VarBoolValue.False,
    title: (t) =>
      t('vars.varBool.false', {
        ns: 'entities'
      })
  }
};

export const varBoolOrder = [VarBoolValue.True, VarBoolValue.False];

export type BaseVarServer = {
  key: string;
  value: string;
};

export type TemporaryVarServer = {
  key: string;
};

export type UserAttachmentVarServer = {
  key: string;
  value: FileBaseServer | null;
};

export type UserDateVarServer = {
  key: string;
  value: string | null;
};

export type MapProjectTypeToValueServer = {
  [VarType.attachment]: FileBaseServer | null;
  [VarType.num]: string;
  [VarType.bool]: VarBoolValue;
  [VarType.str]: string;
  [VarType.dict]: string;
  [VarType.list]: string;
  [VarType.expr]: string;
  [VarType.date]: string | null;
  [VarType.datetime]: string | null;
};

type ProjectBaseVarServer<T extends VarType> = {
  _id: string;
  type: T;
  key: string;
  project_id: string;
  // глобальная/локальная
  level: VarLevel;
  value: MapProjectTypeToValueServer[T];
  per_chat: boolean;
  protected?: boolean;
};

export type ProjectAttachmentVarServer =
  ProjectBaseVarServer<VarType.attachment>;

export type ProjectBoolVarServer = ProjectBaseVarServer<VarType.bool>;

export type ProjectCommonVarServer = ProjectBaseVarServer<ProjectCommonVarType>;

export type ProjectDateVarServer = ProjectBaseVarServer<
  VarType.date | VarType.datetime
>;

export type ProjectVarServer =
  | ProjectAttachmentVarServer
  | ProjectBoolVarServer
  | ProjectCommonVarServer
  | ProjectDateVarServer;

export type SpecialVarServer = {
  type: VarType;
  key: VarSpecial;
};

export type RenderVarServer = {
  result: string;
};

export enum UpdateVarErrorCode {
  duplicateVariable = 'duplicate_variable',
  variableNotFound = 'variable_not_found'
}

export enum CommonVarErrorCode {
  invalidValueForType = 'invalid_value_for_type',
  invalidVarFunction = 'invalid_var_function'
}

export const mapVarCommonErrorToMessage =
  (code: CommonVarErrorCode): TranslationString =>
  (t) =>
    t(`vars.errors.common.${code}`, {
      ns: 'entities'
    });

export const mapVarCreateErrorToMessage =
  (code: BaseResponseCode.conflict): TranslationString =>
  (t) =>
    t(`vars.errors.create.${code}`, {
      ns: 'entities'
    });

export const mapVarUpdateErrorToMessage =
  (code: UpdateVarErrorCode): TranslationString =>
  (t) =>
    t(`vars.errors.update.${code}`, {
      ns: 'entities'
    });
