import * as React from 'react';

import { CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const PencilIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M20.4974 11.4976C20.22 11.4976 19.9951 11.7223 19.9951 11.9998V17.6503C19.9951 18.9436 18.9429 19.9951 17.6503 19.9951H6.34944C5.05612 19.9951 4.00452 18.9429 4.00452 17.6503V6.34944C4.00452 5.05612 5.05675 4.00452 6.34944 4.00452H11.9998C12.2774 4.00452 12.5021 3.77976 12.5021 3.50226C12.5021 3.22476 12.2774 3 11.9998 3H6.34944C4.50239 3 3 4.50301 3 6.34944V17.6496C3 19.4967 4.50239 20.9991 6.34944 20.9991H17.6496C19.4967 20.9991 20.9991 19.4967 20.9991 17.6496V11.9992C20.9997 11.7223 20.7749 11.4976 20.4974 11.4976Z"
        fill="currentColor"
      />
      <path
        d="M7.45153 12.2278L6.66675 15.4353C6.53491 15.9727 6.68998 16.527 7.08175 16.9188C7.38122 17.2183 7.77675 17.3797 8.18671 17.3797C8.31228 17.3797 8.4391 17.3646 8.56529 17.3338L11.7722 16.5491C12.0509 16.4806 12.3058 16.3375 12.5093 16.1341L20.5404 8.10232C20.8367 7.80599 21 7.41172 21 6.99233C21 6.57295 20.8367 6.17867 20.5404 5.88234L18.1183 3.46019C17.8219 3.16386 17.4277 3 17.0083 3C16.5889 3 16.1946 3.16323 15.8983 3.46019L7.86653 11.4907C7.66374 11.6935 7.51997 11.9483 7.45153 12.2278ZM8.32609 16.3576C8.04671 16.426 7.85962 16.2759 7.79182 16.2082C7.72401 16.1403 7.57396 15.9527 7.64239 15.6738L8.28654 13.0433L10.9573 15.7141L8.32609 16.3576ZM16.6077 4.17026C16.7144 4.06353 16.8564 4.00452 17.0076 4.00452C17.1589 4.00452 17.3009 4.06353 17.4075 4.17026L19.8297 6.5924C19.9365 6.69914 19.9954 6.84102 19.9954 6.99233C19.9954 7.14363 19.9365 7.28552 19.8297 7.39225L11.943 15.279L8.721 12.057L16.6077 4.17026Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default PencilIcon;
