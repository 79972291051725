import * as Sentry from '@sentry/browser';

import { SMARTBOT_ENVIRONMENT } from 'shared/entities/app';

const initSentry = (): void => {
  if (!process.env.IS_PROD) {
    return;
  }

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: SMARTBOT_ENVIRONMENT
  });
};

export default initSentry;
