import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import {
  IconSize,
  CommonIconProps,
  LogoIconProps
} from 'shared/entities/components/Icon';

const JivoIcon: React.FC<LogoIconProps> = ({ color, ...rest }) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{ viewBox: '0 0 32 32' }}
      {...rest}
    >
      <g clipPath="url(#clip0_1435_95813)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5324 9.87078C13.0897 10.1017 13.0396 10.2487 13.0396 11.3191C13.0396 12.304 13.0782 12.4547 13.384 12.6624L13.5824 12.7972H14.8418H16.1013L16.0874 19.0858L16.0737 25.3743L15.8718 25.7521C15.4744 26.496 14.8526 26.8899 13.9837 26.9483C13.0645 27.01 13.0137 27.091 13.0137 28.493C13.0137 29.9564 13.1123 30.077 14.2239 29.9714C16.5233 29.7531 18.2254 28.3271 18.8642 26.0835L18.9777 25.6855L18.9917 18.0096C19.0074 9.40322 19.0372 10.085 18.6365 9.87998C18.3812 9.74929 13.7813 9.74099 13.5324 9.87078Z"
          fill={color === 'original' ? '#081C33' : 'currentColor'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0536 0.0239702C13.0028 0.156483 13.0867 2.01608 13.163 2.45018C13.7039 5.52495 15.4849 7.64905 18.3339 8.61697C19.067 8.86605 19.0207 8.8833 18.9808 8.37567C18.6896 4.67282 17.539 2.23767 15.4283 0.857299C14.5836 0.304815 13.1402 -0.201639 13.0536 0.0239702Z"
          fill={color === 'original' ? '#1BC339' : 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1435_95813">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export const JivoOriginalIcon: React.FC<CommonIconProps> = (props) => (
  <JivoIcon color="original" {...props} />
);

export const JivoTransparentIcon: React.FC<CommonIconProps> = (props) => (
  <JivoIcon color="transparent" {...props} />
);
