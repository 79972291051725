import {
  ITelegramPaymentMethodModel,
  IUserPaymentMethod,
  UserPaymentMethodKind,
  UserPaymentTelegramMethodServer
} from 'shared/entities/userPayment';
import { IRootStore } from 'shared/entities/store/rootStore';
import { TelegramPaymentKind } from 'shared/entities/telegramPayments';
import { ChannelKind, ITgChannelModel } from 'shared/entities/channels';

import { UserPaymentMethodModel } from './UserPaymentMethodModel';

export class TelegramPaymentMethodModel
  extends UserPaymentMethodModel
  implements ITelegramPaymentMethodModel
{
  readonly providerKind: UserPaymentMethodKind.tg;
  tgPaymentProvider: {
    tgPaymentProviderKind: TelegramPaymentKind;
    tgChannelId: string;
  };

  constructor(
    params: Omit<IUserPaymentMethod, 'providerKind'> & {
      providerKind: UserPaymentMethodKind.tg;
      tgPaymentProvider: {
        tgPaymentProviderKind: TelegramPaymentKind;
        tgChannelId: string;
      };
    },
    rootStore: IRootStore
  ) {
    super(params, rootStore);

    this.providerKind = params.providerKind;
    this.tgPaymentProvider = params.tgPaymentProvider;
  }

  get channel(): ITgChannelModel | null {
    const channel = this._rootStore.channelsStore.channels.getEntity(
      this.tgPaymentProvider.tgChannelId
    );

    if (!channel) {
      return null;
    }

    return channel.kind === ChannelKind.TELEGRAM ? channel : null;
  }

  static fromJson(
    raw: UserPaymentTelegramMethodServer,
    rootStore: IRootStore
  ): TelegramPaymentMethodModel | null {
    if (raw.provider_kind !== UserPaymentMethodKind.tg) {
      return null;
    }

    return new TelegramPaymentMethodModel(
      {
        id: raw._id,
        externalId: raw.external_id,
        title: raw.verbose_name,
        providerKind: raw.provider_kind,
        dateCreated: new Date(raw.date_created),
        isEnabled: raw.is_enabled,
        isTest: raw.is_test,
        vatCode: raw.vat_code,
        tgPaymentProvider: {
          tgPaymentProviderKind: raw.extra.tg_payment_provider.kind,
          tgChannelId: raw.extra.tg_payment_provider.channel_id
        }
      },
      rootStore
    );
  }
}
