import { VarType } from 'shared/entities/vars';
import {
  validateNumberValue,
  validateArrayJsonValue,
  validateMapJsonValue
} from 'shared/entities/validator';

export const mapVarTypeToValidator = {
  [VarType.num]: validateNumberValue,
  [VarType.list]: validateArrayJsonValue,
  [VarType.dict]: validateMapJsonValue
};

export enum VarUpdateStage {
  form = 'form',
  confirm = 'confirm'
}
