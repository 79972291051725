import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const ConditionIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M5.07293 21.9218C6.77006 21.9218 8.14585 20.546 8.14585 18.8489C8.14585 17.1518 6.77006 15.776 5.07293 15.776C3.37579 15.776 2 17.1518 2 18.8489C2 20.546 3.37579 21.9218 5.07293 21.9218Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4841 22C20.1813 22 21.557 20.6243 21.557 18.9271C21.557 17.23 20.1813 15.8542 18.4841 15.8542C16.787 15.8542 15.4111 17.23 15.4111 18.9271C15.4111 20.6243 16.787 22 18.4841 22Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.778 8.14585C13.4751 8.14585 14.851 6.77008 14.851 5.07295C14.851 3.37582 13.4751 2 11.778 2C10.0808 2 8.70508 3.37582 8.70508 5.07295C8.70508 6.77008 10.0808 8.14585 11.778 8.14585Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4846 15.7761C18.4846 13.8881 16.9481 12.3516 15.0601 12.3516H8.48455C6.59651 12.3516 5.06006 13.8881 5.06006 15.7761"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7788 12.3515V8.14575"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default ConditionIcon;
