import * as React from 'react';
import { useAnimate } from 'framer-motion';

import { useModalState } from 'shared/entities/common/hooks';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';

export const useSelectAnimation = ({
  selected,
  withMargin = true,
  width = '320px'
}: {
  selected: boolean;
  withMargin?: boolean;
  width?: string;
}) => {
  const { store } = useChannelsModalStore();
  const [ref, animate] = useAnimate();
  const { opened: showContent, open, close } = useModalState();
  const openedStyle = !store.withSections
    ? {
        width: '100%',
        opacity: 1,
        height: '100%'
      }
    : {
        width,
        marginLeft: withMargin ? 12 : 0,
        opacity: 1
      };

  React.useEffect(() => {
    const changeState = async () => {
      if (selected) {
        await animate(ref.current, openedStyle, {
          stiffness: 0
        });
        open();
      } else {
        close();
        animate(
          ref.current,
          {
            width: 0,
            marginLeft: 0,
            opacity: 0
          },
          {
            stiffness: 0
          }
        );
      }
    };

    changeState();
  }, [selected]);

  return {
    ref,
    showContent
  };
};

export const useUnSelectAnimation = ({
  selected,
  withMargin = true,
  width = '320px',
  increased
}: {
  selected: boolean;
  withMargin?: boolean;
  width?: string;
  increased?: boolean;
}) => {
  const [ref, animate] = useAnimate();
  const { opened: showContent, open, close } = useModalState();

  React.useEffect(() => {
    const changeState = async () => {
      if (selected) {
        close();
        animate(
          ref.current,
          {
            width: 0,
            marginRight: 0,
            opacity: 0
          },
          {
            stiffness: 0
          }
        );
      } else {
        await animate(
          ref.current,
          {
            width: increased ? '50%' : width,
            marginRight: withMargin ? 12 : 0,
            opacity: 1
          },
          {
            stiffness: 0
          }
        );
        open();
      }
    };

    changeState();
  }, [selected, increased]);

  return {
    ref,
    showContent
  };
};
