import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const BoldIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{ viewBox: '0 0 24 24', ...(props.svgProps || {}) }}
    >
      <path
        d="M16.9939 9.08333C16.9939 8.54666 16.8939 8.01622 16.6985 7.52C16.5049 7.02785 16.2189 6.5783 15.8562 6.19622C15.4981 5.81917 15.0692 5.51825 14.5946 5.31111C14.1251 5.10626 13.6191 5.0004 13.1077 5H8.11077C7.81616 5 7.53385 5.12289 7.32539 5.34222C7.11582 5.56432 6.99924 5.8597 7 6.16667V17.8333C7 18.1429 7.11693 18.44 7.32539 18.6578C7.53308 18.8771 7.81616 19 8.11 19H13.1077C13.8849 19.0024 14.6438 18.762 15.2808 18.3116C15.9292 17.8522 16.4283 17.2085 16.7146 16.4621C17.0062 15.7092 17.0762 14.8817 16.9162 14.0868C16.7594 13.2975 16.3772 12.572 15.8169 12C16.1904 11.6176 16.4858 11.1646 16.6862 10.6669C16.8885 10.1652 16.9931 9.62699 16.9939 9.08333V9.08333ZM13.1077 16.6667H9.22077V13.1667H13.1077C13.5492 13.1667 13.9731 13.351 14.2854 13.6792C14.5977 14.0074 14.7731 14.4523 14.7731 14.9167C14.7731 15.381 14.5977 15.8259 14.2854 16.1541C14.1334 16.3154 13.9507 16.4439 13.7483 16.532C13.5459 16.6201 13.328 16.6659 13.1077 16.6667V16.6667ZM13.1077 10.8333H9.22077V7.33333H13.1077C13.5492 7.33333 13.9731 7.51766 14.2854 7.84589C14.5977 8.17411 14.7731 8.619 14.7731 9.08333C14.7731 9.54766 14.5977 9.99255 14.2854 10.3208C14.1334 10.482 13.9507 10.6106 13.7483 10.6987C13.5459 10.7868 13.328 10.8326 13.1077 10.8333Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BoldIcon;
