import { IField } from 'shared/entities/form';
import { AIAggregatedDocType, AIDocError } from 'shared/entities/smartbotAI';

import { IRootStore } from '../store/rootStore';
import { ILoadingStageModel } from '../loadingStage';
import { LoadingStage } from '../meta';
import { IScenarioData } from '../scenario';
import { AITarget } from '../smartbotAIInfo';

export interface ISessionData {
  sessionId: string;
  subscriptionToken: string;
  subscriptionChannel: string;
}

export interface IAIBot {
  readonly id: IField<string>;
  readonly intentTargetTitle: string | null;
  readonly scenarios: IScenarioData[];
  readonly params: IField<IAIEducationParams>;
  readonly name: string;
}

export interface IAIEducationParams {
  readonly target: IField<AITarget>;
  readonly testMessagesAvailable: IField<number | null>;
  readonly name: IField<string>;
}

export interface IAIDoc {
  readonly rootStore: IRootStore;
  readonly renderId: string;
  readonly id: IField<string | null>;
  readonly url: IField<string | null>;
  readonly cost: IField<number | null>;
  readonly title: string;
  readonly removingStage: ILoadingStageModel;
  readonly errors: IField<AIDocError[] | null>;
  readonly progress: IField<number>;
  readonly type: AIAggregatedDocType;

  get withErrors(): boolean;

  get saved(): boolean;

  remove(): Promise<BaseResponse>;
}

export type AIDocModelParams<
  T extends AIAggregatedDocType,
  I extends string | null = string | null
> = {
  rootStore: IRootStore;
  id: I;
  cost: number;
  title: string;
  url: string | null;
  errors: AIDocError[] | null;
  progress: number;
  type: T;
  botId: string;
};

export type AIAggregatedDocModelParams<T extends AIAggregatedDocType> = {
  loadingStage: LoadingStage;
  loadingProgress: number;
} & AIDocModelParams<T>;

export type AICommonDocModelParams<T extends AIAggregatedDocType> = {
  dateCreated: Date;
} & AIDocModelParams<T, string>;

export enum AIDocErrorStage {
  indexing = 'indexing',
  loading = 'loading'
}
