import { JobKind } from 'shared/entities/jobs';

export const createJobKey = ({
  key,
  kind
}: {
  key: string | null;
  kind: JobKind;
}): string => {
  if (!key) {
    return `${kind}`;
  }
  return `${kind}-${key}`;
};

export const getParamsFromJobKey = (
  value: string
): { key: string | null; kind: JobKind } | null => {
  const data = value.split('-', 2);
  if (data.length !== 2) {
    return null;
  }
  return { kind: data[0] as JobKind, key: data[1] || null };
};
