import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const ResizeUp: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
    >
      <path
        d="M12.9698 11.0303C13.2627 11.3232 13.7376 11.3232 14.0305 11.0303L19.5001 5.56065V8.99999C19.5001 9.4142 19.8359 9.74998 20.2501 9.74998C20.6643 9.74998 21.0001 9.4142 21.0001 8.99999V3.75C21.0001 3.74976 21.0001 3.74948 21.0001 3.74923C21.0001 3.7248 20.9988 3.70036 20.9964 3.676C20.9953 3.66482 20.9932 3.65396 20.9916 3.64295C20.9897 3.62977 20.9882 3.61658 20.9856 3.60351C20.9831 3.59089 20.9796 3.57862 20.9765 3.56621C20.9736 3.55488 20.9712 3.54353 20.9678 3.53232C20.9641 3.52015 20.9596 3.50841 20.9553 3.49653C20.9513 3.48535 20.9476 3.47406 20.943 3.46302C20.9384 3.45191 20.933 3.4413 20.9279 3.43047C20.9225 3.41911 20.9175 3.40765 20.9115 3.39651C20.906 3.38614 20.8996 3.37629 20.8936 3.36624C20.8871 3.3553 20.8809 3.34423 20.8738 3.33358C20.8664 3.32247 20.8581 3.31203 20.8501 3.30134C20.8434 3.29244 20.8373 3.2833 20.8301 3.27465C20.7988 3.23644 20.7637 3.20135 20.7255 3.16999C20.7168 3.16289 20.7077 3.15671 20.6988 3.15003C20.6881 3.14201 20.6777 3.13375 20.6666 3.12633C20.6559 3.11919 20.6448 3.11301 20.6339 3.1065C20.6238 3.10049 20.614 3.09416 20.6036 3.08861C20.5925 3.08267 20.581 3.0776 20.5697 3.07226C20.5588 3.06716 20.5482 3.06175 20.5371 3.05714C20.5261 3.05257 20.5148 3.04888 20.5036 3.04487C20.4917 3.04058 20.48 3.03605 20.4678 3.03236C20.4566 3.02898 20.4453 3.02649 20.4339 3.02364C20.4215 3.02051 20.4093 3.01703 20.3966 3.01453C20.3836 3.01193 20.3703 3.01039 20.3572 3.00852C20.3461 3.00694 20.3353 3.00487 20.3241 3.00374C20.2998 3.00135 20.2753 3.00005 20.2509 3.00005C20.2507 3.00005 20.2504 3.00001 20.2501 3.00001H15.0001C14.5859 3.00001 14.2502 3.33579 14.2502 3.75C14.2502 4.16421 14.5859 4.49999 15.0001 4.49999L18.4395 4.49999L12.9698 9.96967C12.6769 10.2626 12.6769 10.7374 12.9698 11.0303Z"
        fill="currentColor"
      />
      <path
        d="M3.00374 20.324C3.00483 20.3352 3.00694 20.3461 3.00852 20.3571C3.01042 20.3702 3.01197 20.3834 3.01453 20.3965C3.01703 20.4091 3.02051 20.4214 3.02364 20.4338C3.02649 20.4451 3.02895 20.4565 3.03236 20.4677C3.03605 20.4799 3.04058 20.4916 3.04487 20.5035C3.04888 20.5147 3.05257 20.526 3.05714 20.537C3.06175 20.5481 3.06713 20.5587 3.07226 20.5695C3.0776 20.5809 3.08267 20.5924 3.08861 20.6035C3.09416 20.6139 3.10049 20.6237 3.1065 20.6338C3.11304 20.6447 3.11919 20.6558 3.12633 20.6664C3.13375 20.6775 3.14204 20.688 3.15002 20.6987C3.1567 20.7076 3.16286 20.7167 3.16999 20.7254C3.20135 20.7636 3.2364 20.7987 3.27465 20.83C3.2833 20.8371 3.29244 20.8433 3.30134 20.85C3.31202 20.858 3.32247 20.8663 3.33358 20.8737C3.34423 20.8808 3.3553 20.887 3.36624 20.8935C3.37629 20.8995 3.38613 20.9059 3.39651 20.9114C3.40765 20.9173 3.41911 20.9224 3.43047 20.9278C3.44129 20.9329 3.45195 20.9383 3.46302 20.9429C3.47406 20.9474 3.48535 20.9511 3.49652 20.9551C3.50841 20.9594 3.52015 20.964 3.53231 20.9677C3.54349 20.971 3.55488 20.9735 3.5662 20.9764C3.57861 20.9795 3.59085 20.983 3.60351 20.9855C3.61658 20.9881 3.6298 20.9896 3.64295 20.9915C3.65399 20.9931 3.66482 20.9951 3.676 20.9963C3.70061 20.9987 3.72529 21 3.75 21L8.99999 21C9.4142 21 9.74998 20.6642 9.74998 20.25C9.74998 19.8358 9.4142 19.5 8.99999 19.5L5.56065 19.5L11.0303 14.0303C11.3232 13.7375 11.3232 13.2626 11.0303 12.9697C10.7374 12.6768 10.2626 12.6768 9.96967 12.9697L4.49999 18.4394L4.49999 15C4.49999 14.5858 4.16421 14.25 3.75 14.25C3.33579 14.25 3.00001 14.5858 3.00001 15L3.00001 20.25C2.99998 20.2747 3.00131 20.2994 3.00374 20.324Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ResizeUp;
