import * as React from 'react';

export type UseModalStateResult = {
  opened: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

const useModalState = (initialValue = false): UseModalStateResult => {
  const [opened, setOpened] = React.useState(initialValue);

  const open = React.useCallback(() => setOpened(true), []);
  const close = React.useCallback(() => setOpened(false), []);
  const toggle = React.useCallback(() => {
    setOpened((p) => !p);
  }, [opened]);

  return {
    opened,
    open,
    close,
    toggle
  };
};

export default useModalState;
