import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps } from 'shared/entities/components/Icon';

const EnvelopeIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon svgProps={{ viewBox: '0 0 24 24' }} {...props}>
      <path
        d="M18.3288 5.00965C18.3074 5.00813 18.2853 5.0066 18.2639 5.00508C18.2515 5.00457 18.239 5.00356 18.226 5.00305C18.2204 5.00305 18.2142 5.00254 18.2085 5.00203C18.169 5.00051 18.1289 5 18.0888 5H5.91236C5.87225 5 5.83216 5.00051 5.79262 5.00203C5.78697 5.00203 5.78075 5.00254 5.7751 5.00305C5.76211 5.00356 5.74912 5.00457 5.73613 5.00508C5.71467 5.00609 5.69376 5.00762 5.6723 5.00965C4.17827 5.11987 3 6.24743 3 7.61829V16.8978C3 18.3418 4.30649 19.5161 5.91179 19.5161H18.0882C19.6941 19.5161 21 18.3413 21 16.8978V7.6188C21.0006 6.24743 19.8223 5.12038 18.3288 5.00965ZM18.0882 5.81266C18.1187 5.81266 18.1492 5.81368 18.1792 5.81469C18.901 5.84415 19.2868 6.30178 19.4066 6.72182C19.5252 7.1378 19.4348 7.74831 18.7875 8.09979L12.7126 11.3972C12.2731 11.6359 11.7275 11.6359 11.288 11.3972L5.21252 8.09979C4.56577 7.74831 4.47483 7.1378 4.59345 6.72182C4.71319 6.30229 5.09898 5.84466 5.82086 5.81469C5.8508 5.81368 5.88129 5.81266 5.91123 5.81266H18.0882ZM18.0882 18.704H5.91179C4.80469 18.704 3.90376 17.8939 3.90376 16.8984V8.00633C4.09185 8.32225 4.37654 8.595 4.74595 8.79563L10.8209 12.093C11.1846 12.2906 11.5925 12.3891 12.0003 12.3891C12.4081 12.3891 12.8159 12.2906 13.1797 12.093L19.2546 8.79563C19.6235 8.59551 19.9087 8.32225 20.0968 8.00633V16.8984C20.0968 17.8939 19.1959 18.704 18.0882 18.704Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default EnvelopeIcon;
