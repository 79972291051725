import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const NutIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XM}
      svgProps={{
        viewBox: '0 0 20 20'
      }}
      {...props}
    >
      <path
        d="M7.82926 16.1426L8.31629 17.2379C8.46108 17.564 8.69736 17.841 8.99648 18.0355C9.29561 18.2299 9.64472 18.3334 10.0015 18.3333C10.3582 18.3334 10.7074 18.2299 11.0065 18.0355C11.3056 17.841 11.5419 17.564 11.6867 17.2379L12.1737 16.1426C12.3471 15.7539 12.6387 15.4298 13.007 15.2166C13.3777 15.0028 13.8065 14.9118 14.232 14.9564L15.4237 15.0833C15.7784 15.1208 16.1364 15.0546 16.4543 14.8927C16.7721 14.7309 17.0362 14.4802 17.2144 14.1713C17.3929 13.8625 17.478 13.5085 17.4592 13.1524C17.4405 12.7962 17.3188 12.4531 17.1089 12.1648L16.4033 11.1953C16.1521 10.8476 16.0179 10.429 16.02 9.99996C16.0199 9.57212 16.1554 9.15525 16.407 8.80922L17.1126 7.83977C17.3225 7.55142 17.4442 7.20835 17.4629 6.85219C17.4817 6.49602 17.3966 6.14208 17.2181 5.83329C17.0399 5.52432 16.7758 5.2737 16.458 5.11182C16.1401 4.94993 15.7821 4.88373 15.4274 4.92126L14.2357 5.04811C13.8102 5.0928 13.3814 5.00173 13.0107 4.78792C12.6417 4.5735 12.35 4.24776 12.1774 3.85737L11.6867 2.762C11.5419 2.43594 11.3056 2.15889 11.0065 1.96446C10.7074 1.77003 10.3582 1.66657 10.0015 1.66663C9.64472 1.66657 9.29561 1.77003 8.99648 1.96446C8.69736 2.15889 8.46108 2.43594 8.31629 2.762L7.82926 3.85737C7.6567 4.24776 7.36498 4.5735 6.99592 4.78792C6.62527 5.00173 6.19648 5.0928 5.77092 5.04811L4.57555 4.92126C4.22083 4.88373 3.86284 4.94993 3.54498 5.11182C3.22713 5.2737 2.96307 5.52432 2.78481 5.83329C2.60633 6.14208 2.5213 6.49602 2.54004 6.85219C2.55877 7.20835 2.68047 7.55142 2.89037 7.83977L3.59592 8.80922C3.84754 9.15525 3.98304 9.57212 3.98296 9.99996C3.98304 10.4278 3.84754 10.8447 3.59592 11.1907L2.89037 12.1601C2.68047 12.4485 2.55877 12.7916 2.54004 13.1477C2.5213 13.5039 2.60633 13.8578 2.78481 14.1666C2.96324 14.4754 3.22734 14.7259 3.54514 14.8878C3.86295 15.0496 4.22086 15.1159 4.57555 15.0787L5.76722 14.9518C6.19278 14.9071 6.62157 14.9982 6.99222 15.212C7.36266 15.4258 7.65573 15.7516 7.82926 16.1426Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99999 12.5C11.3807 12.5 12.5 11.3807 12.5 9.99996C12.5 8.61925 11.3807 7.49996 9.99999 7.49996C8.61928 7.49996 7.49999 8.61925 7.49999 9.99996C7.49999 11.3807 8.61928 12.5 9.99999 12.5Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default NutIcon;
