import { makeObservable, observable } from 'mobx';

import { IEdit } from 'shared/entities/edit';

export default class ListEditModel implements IEdit {
  name: string;

  constructor({ name }: { name: string }) {
    this.name = name;

    makeObservable(this, {
      name: observable
    });
  }

  toJson() {
    return {
      name: this.name
    };
  }

  getCopy(): ListEditModel {
    return new ListEditModel({
      name: this.name
    });
  }
}
