import {
  IProjectBaseVar,
  ProjectDateVarServer,
  VarType
} from 'shared/entities/vars';
import { IRootStore } from 'shared/entities/store/rootStore';
import { DateFormat, transformDate } from 'shared/entities/date';

import ProjectVarBaseModel from './ProjectVarBaseModel';

export default class ProjectDateVarModel
  extends ProjectVarBaseModel<VarType.date | VarType.datetime>
  implements IProjectBaseVar<VarType.date | VarType.datetime>
{
  get example(): string {
    if (!this.value) {
      return '';
    }

    return transformDate(
      this.value,
      this.type === VarType.date
        ? DateFormat.dayMonthYear
        : DateFormat.dayMonthYearTime
    );
  }
  static fromJson(
    raw: ProjectDateVarServer & { rootStore: IRootStore }
  ): ProjectDateVarModel {
    return new ProjectDateVarModel({
      id: raw._id,
      projectId: raw.project_id,
      level: raw.level,
      key: raw.key,
      protectedProperty: raw.protected,
      rootStore: raw.rootStore,
      type: raw.type,
      value: raw.value ? new Date(raw.value) : null
    });
  }

  getCopy(): ProjectDateVarModel {
    return new ProjectDateVarModel({
      key: this.key,
      level: this.level,
      value: this.value,
      projectId: this.projectId,
      type: this.type,
      id: this.id,
      rootStore: this.rootStore,
      protectedProperty: this.protectedProperty
    });
  }
}
