import {
  ISpecialVar,
  SpecialVarServer,
  getVarSpecialEntity,
  VarSpecial,
  VarExtraType,
  removeEnclosedSymbols
} from 'shared/entities/vars';
import { TranslationString } from 'shared/entities/localization';

export default class SpecialVarModel implements ISpecialVar {
  readonly type: VarExtraType;

  readonly key: VarSpecial;

  readonly title: TranslationString;

  readonly description: TranslationString;

  readonly example: TranslationString;

  constructor({ key, type, title, description, example }: ISpecialVar) {
    this.key = key;
    this.type = type;
    this.title = title;
    this.description = description;
    this.example = example;
  }

  get enclosedKey(): string | null {
    return removeEnclosedSymbols(this.key);
  }

  static fromJson(raw: SpecialVarServer): SpecialVarModel {
    return new SpecialVarModel({
      ...getVarSpecialEntity(raw.key),
      key: raw.key,
      type: raw.type
    });
  }
}
