import { action, computed, makeObservable, observable } from 'mobx';

import { IList } from 'shared/entities/list';
import { LoadingStage } from 'shared/entities/meta';

export default class ListModel<T, C extends string | number | symbol = string>
  implements IList<T, C>
{
  private _keys: C[];

  private _entities: Record<C, T>;

  private _loadingStage: LoadingStage = LoadingStage.NOT_STARTED;

  private _creatingStage: LoadingStage = LoadingStage.NOT_STARTED;

  private _isInitialLoad = true;

  private _hasMore = true;

  private _total: number;

  // дата последнего запроса
  private _lastDateUpdate: Date | null = null;

  readonly disabledLoadMore: boolean;

  constructor(
    {
      keys,
      entities,
      disabledLoadMore = false
    }: { keys: C[]; entities: Record<C, T>; disabledLoadMore?: boolean } = {
      keys: [],
      entities: {} as Record<C, T>
    }
  ) {
    makeObservable<
      ListModel<T, C>,
      | '_keys'
      | '_entities'
      | '_loadingStage'
      | '_creatingStage'
      | '_isInitialLoad'
      | '_hasMore'
      | '_total'
      | '_lastDateUpdate'
    >(this, {
      _keys: observable,
      _entities: observable,
      _loadingStage: observable,
      _creatingStage: observable,
      _isInitialLoad: observable,
      _hasMore: observable,
      _total: observable,
      _lastDateUpdate: observable,

      reset: action,
      removeEntity: action,
      removeEntities: action,
      addEntity: action,
      addEntities: action,
      setIsInitialLoad: action,
      setLoadingStage: action,
      setCreatingStage: action,
      setHasMore: action,
      setLastDateUpdate: action,
      setTotal: action,
      toStart: action,
      addEntityByIndex: action,

      keys: computed,
      entities: computed,
      length: computed,
      items: computed,
      loadingStage: computed,
      creatingStage: computed,
      isInitialLoad: computed,
      hasMore: computed,
      lastDateUpdate: computed,
      total: computed,
      isEmpty: computed
    });

    this._keys = keys;
    this._entities = entities;
    this.disabledLoadMore = disabledLoadMore;
  }

  get keys(): C[] {
    return this._keys;
  }

  get entities(): Record<C, T> {
    return this._entities;
  }

  get loadingStage(): LoadingStage {
    return this._loadingStage;
  }

  get creatingStage(): LoadingStage {
    return this._creatingStage;
  }

  get isEmpty(): boolean {
    return !this.length;
  }

  get isInitialLoad(): boolean {
    return this._isInitialLoad;
  }

  get hasMore(): boolean {
    return this.disabledLoadMore ? false : this._hasMore;
  }

  get lastDateUpdate(): Date | null {
    return this._lastDateUpdate;
  }

  get total(): number {
    return this._total;
  }

  get items(): T[] {
    const arr: T[] = [];
    this._keys.forEach((id: C) => {
      const item = this._entities[id];

      if (item) {
        arr.push(item);
      }
    });

    return arr;
  }

  get length(): number {
    return this.items.length;
  }

  setIsInitialLoad = (value: boolean): void => {
    this._isInitialLoad = value;
  };

  setLoadingStage = (value: LoadingStage): void => {
    this._loadingStage = value;
  };

  setCreatingStage = (value: LoadingStage): void => {
    this._creatingStage = value;
  };

  setHasMore = (value: boolean): void => {
    this._hasMore = value;
  };

  setLastDateUpdate = (value: Date | null): void => {
    this._lastDateUpdate = value;
  };

  setTotal = (value: number): void => {
    this._total = value;
  };

  addEntity = ({
    entity,
    key,
    start = false
  }: {
    entity: T;
    key: C;
    start?: boolean;
  }): void => {
    this._entities[key] = entity;
    if (start) {
      this._keys.unshift(key);
    } else {
      this._keys.push(key);
    }
  };

  addEntities = ({
    entities,
    keys,
    initial,
    start
  }: {
    entities: Record<C, T>;
    keys: C[];
    initial: boolean;
    start?: boolean;
  }): void => {
    if (initial) {
      this._entities = entities;
      this._keys = keys;
      return;
    }

    this._entities = {
      ...this._entities,
      ...entities
    };
    if (start) {
      this._keys.unshift(...keys);
    } else {
      this._keys.push(...keys);
    }
  };

  addEntityByIndex = ({
    entity,
    key,
    index
  }: {
    entity: T;
    key: C;
    index: number;
  }): void => {
    this._entities[key] = entity;
    this.keys.splice(index, 0, key);
  };

  reset = (): void => {
    this._keys = [];
    this._entities = {} as Record<C, T>;
    this._hasMore = true;
    this._isInitialLoad = true;
    this.setLoadingStage(LoadingStage.NOT_STARTED);
  };

  removeEntity = (keyParam: C): void => {
    this._keys = this._keys.filter((key) => key !== keyParam);
    delete this._entities[keyParam];
  };

  removeEntities = (keyParams: C[]): void => {
    keyParams.forEach(this.removeEntity);
  };

  getEntity = (keyParam: C): T | null => {
    return this._entities[keyParam] || null;
  };

  getEntityByIndex = (index: number): T | null => {
    const id = this.keys[index];

    return this.getEntity(id);
  };

  getEntityAndIndex = (key: C): { item: T; index: number } | null => {
    const index = this.keys.indexOf(key);

    if (index !== -1) {
      const item = this.items[index];

      if (!item) {
        return null;
      }

      return {
        item,
        index
      };
    }

    return null;
  };

  /**
   * Перемещает элемент с ключом keyParam в начало списка
   * @param keyParam
   */
  toStart = (keyParam: C): void => {
    const foundIndex = this.keys.indexOf(keyParam);

    if (foundIndex === -1) {
      return;
    }

    this.keys.splice(foundIndex, 1);
    this.keys.splice(0, 0, keyParam);
  };
}
