import { generateId } from '../common/utils';

import { BillingServer, PriceServer } from './server';
import { IBilling, IPrice } from './client';

const getMaxUsersTitleForElement = ({
  index,
  prices
}: {
  index: number;
  prices: PriceServer[];
}): string | null => {
  const price = prices[index];

  if (!price) {
    return null;
  }

  switch (index) {
    // если первый тариф, то "< {макс кол-во подписчиков этого тарифа}"
    case 0: {
      if (price.max_users_count === null) {
        return null;
      }

      return `< ${price.max_users_count.toLocaleString()}`;
    }

    // если последний тариф, то "{макс кол-во подписчиков предыдущего тарифа} - ∞"
    case prices.length - 1: {
      const prevEl = prices[index - 1];

      if (
        price.max_users_count !== null ||
        typeof prevEl.max_users_count !== 'number'
      ) {
        return null;
      }

      return `${prevEl.max_users_count.toLocaleString()} - ∞`;
    }

    // тариф в середине, то "{макс кол-во подписчиков предыдущего тарифа} - {макс кол-во подписчиков этого тарифа}"
    default: {
      const prevPrice = prices[index - 1];

      if (
        prevPrice.max_users_count === null ||
        price.max_users_count === null
      ) {
        return null;
      }

      return `${prevPrice.max_users_count.toLocaleString()} - ${price.max_users_count.toLocaleString()}`;
    }
  }
};

export const normalizePrices = (
  prices: PriceServer[]
): {
  entities: Record<string, IPrice>;
  keys: string[];
} => {
  return prices.reduce<{
    entities: Record<string, IPrice>;
    keys: string[];
  }>(
    (acc, raw, index) => {
      const maxUsersCountTitle = getMaxUsersTitleForElement({
        index,
        prices
      });

      if (maxUsersCountTitle === null) {
        return acc;
      }

      const normalizedPrice: IPrice = {
        id: generateId(),
        maxUsersCount: raw.max_users_count,
        maxUsersCountTitle,
        price: raw.price,
        profitably: raw.is_profitable
      };
      acc.entities[normalizedPrice.id] = normalizedPrice;
      acc.keys.push(normalizedPrice.id);

      return acc;
    },
    {
      entities: {},
      keys: []
    }
  );
};

export const normalizeBilling = (raw: BillingServer): IBilling => ({
  balance: raw.balance,
  trialUntil: new Date(raw.trial_until)
});
