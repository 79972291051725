import { IVKCreationStore, IVKGroup, VkGroupServer } from 'shared/entities/vk';
import {
  ChannelCommonServer,
  ChannelKind,
  ChannelServer,
  CreateChannelError,
  mapCreateChannelErrorToMessage
} from 'shared/entities/channels';
import { apiUrls } from 'shared/entities/domain';
import CommonChannelModel from 'shared/models/channel/CommonChannelModel';
import ChannelTokenModel from 'shared/models/channel/ChannelTokenModel';
import { IRootStore } from 'shared/entities/store/rootStore';
import { PollingWindowModel } from 'shared/models/polling';

import { FieldModel } from '../form';
import { LoadingStageModel } from '../loadingStage';

export class VKGroupModel implements IVKGroup {
  readonly id: number;
  readonly name: string;
  readonly url: string;
  readonly img: string;
  private rootStore: IRootStore;
  private vkCreationStore: IVKCreationStore;
  readonly created: FieldModel<boolean> = new FieldModel<boolean>(false);
  readonly creatingStage: LoadingStageModel = new LoadingStageModel();
  private groupTokenPolling: PollingWindowModel;

  constructor({
    id,
    img,
    url,
    name,
    vkCreationStore,
    rootStore
  }: IVKGroup & {
    vkCreationStore: IVKCreationStore;
    rootStore: IRootStore;
  }) {
    this.id = id;
    this.img = img;
    this.url = url;
    this.name = name;
    this.rootStore = rootStore;
    this.vkCreationStore = vkCreationStore;

    this.groupTokenPolling = new PollingWindowModel({
      rootStore,
      cb: this.checkToken
    });
  }

  createChannel = async (): Promise<BaseResponse> => {
    if (this.creatingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.creatingStage.loading();

    const response = await this.rootStore.networkStore.api<
      {
        channel: ChannelCommonServer;
      },
      CreateChannelError.channelAlreadyExist
    >(apiUrls.CHANNELS_VK_CREATE, {
      method: 'POST',
      data: {
        group_id: this.id
      },
      errorsMap: mapCreateChannelErrorToMessage
    });

    if (!response.isError) {
      const channel = CommonChannelModel.fromJson(
        response.data.channel,
        this.rootStore
      );
      this.rootStore.channelsStore.addCreatedChannel(channel);
    } else {
      this.creatingStage.error();
      return {
        isError: true
      };
    }

    const authUrlResponse = await this.rootStore.networkStore.api<{
      auth_url: string;
    }>(apiUrls.OAUTH_VK_GROUP_GET_URL, {
      method: 'GET',
      data: {
        group_id: this.id
      }
    });

    if (authUrlResponse.isError) {
      this.creatingStage.error();
      return {
        isError: true
      };
    }

    const { isError } = await this.groupTokenPolling.openWindow(
      authUrlResponse.data.auth_url
    );

    if (isError) {
      this.creatingStage.error();

      return {
        isError: true
      };
    }

    return {
      isError: false
    };
  };

  reset(): void {
    this.groupTokenPolling.closePollingBanner();
    this.groupTokenPolling.stopPolling();
  }

  private checkToken = async (): Promise<BaseResponse> => {
    const response = await this.rootStore.networkStore.api<{
      channels: Array<ChannelServer>;
    }>(apiUrls.CHANNELS_LIST);

    if (response.isError) {
      return {
        isError: true
      };
    }

    const foundChannel = response.data.channels.find(
      (channel) => String(channel.external_id) === String(this.id)
    );

    if (!foundChannel || foundChannel.kind !== ChannelKind.VK) {
      return {
        isError: false
      };
    }

    const token = foundChannel.tokens[0];

    if (!token) {
      return {
        isError: true
      };
    }

    let channel = this.rootStore.channelsStore.channels.items.find(
      (channel) => String(channel.externalId) === String(this.id)
    );

    if (!channel) {
      channel = CommonChannelModel.fromJson(foundChannel, this.rootStore);

      this.rootStore.channelsStore.addChannel(channel);
    }

    const tokenModel = ChannelTokenModel.fromJson({
      raw: token,
      channelId: channel.id,
      rootStore: this.rootStore
    });

    channel.addToken(tokenModel);

    this.created.changeValue(true);
    this.vkCreationStore.removeGroup(this.id);

    return {
      isError: false
    };
  };

  static fromJson(
    raw: VkGroupServer & {
      vkCreationStore: IVKCreationStore;
      rootStore: IRootStore;
    }
  ): VKGroupModel {
    return new VKGroupModel({
      ...raw,
      img: raw.photo_50,
      url: `https://vk.com/${raw.screen_name}`
    });
  }
}
