import { IRootStore } from '../../store/rootStore';
import { TFunctionType } from '../../localization';

import {
  BlockBaseServer,
  BlockKindType,
  BlockStatsServer,
  BlockType,
  EditableAttrsBlockServer,
  TransitionsMap
} from './server';
import {
  BlockBaseNormalized,
  IBlockStats,
  IBlockConfig,
  IPort,
  PortsMap
} from './client';
import {
  CAN_NOT_BE_TARGET_BLOCKS,
  DISABLED_COPYING_BLOCKS,
  DISABLED_PARAMS_EDITING_BLOCKS,
  DISABLED_PORTS_EDITING_BLOCKS,
  DISABLED_REMOVING_BLOCKS,
  REPEATED_FETCH_BLOCKS,
  REQUIRED_NAME_BLOCKS
} from './config';

export const normalizeBlockConfig = (
  kind: BlockKindType,
  type: BlockType
): IBlockConfig => ({
  disabledParamsEditing: DISABLED_PARAMS_EDITING_BLOCKS.includes(kind),
  disabledPortsEditing: DISABLED_PORTS_EDITING_BLOCKS.includes(kind),
  disabledRemoving: DISABLED_REMOVING_BLOCKS.includes(kind),
  disabledCopying: DISABLED_COPYING_BLOCKS.includes(kind),
  repeatedFetchingOnOpen: REPEATED_FETCH_BLOCKS.includes(kind),
  requiredName: REQUIRED_NAME_BLOCKS.includes(kind),
  canBeTarget: !CAN_NOT_BE_TARGET_BLOCKS.includes(type)
});

export const normalizeBlockStats = (stats: BlockStatsServer): IBlockStats => {
  return {
    executedCount: stats.executed_count
  };
};

export const createDefaultPort = (
  portName: string,
  targetId: string | null = null
): IPort => {
  return {
    portName,
    targetId
  };
};

export const getPortsFromJSON = (transitions: TransitionsMap): PortsMap => {
  const result: PortsMap = new Map();

  Object.keys(transitions).forEach((portName) => {
    result.set(portName, createDefaultPort(portName, transitions[portName]));
  });

  return result;
};

export const normalizeCommonFields = <
  T extends BlockType,
  K extends BlockKindType,
  Params extends Record<string, any>
>(
  raw: BlockBaseServer<T, K> & EditableAttrsBlockServer<Params>,
  { rootStore }: { rootStore: IRootStore }
): BlockBaseNormalized<T, K> => {
  return {
    id: raw._id,
    scenarioId: raw.scenario_id,
    tags: raw.tags,
    type: raw.type,
    kind: raw.kind,
    ports: getPortsFromJSON(raw.transitions),
    name: raw.name || '',
    config: normalizeBlockConfig(raw.kind, raw.type),
    position: raw.ui_state,
    stats: raw.stats ? normalizeBlockStats(raw.stats) : raw.stats,
    actualName: (t) =>
      createActualBlockName({
        rootStore,
        blockName: raw.name,
        blockKind: raw.kind,
        t
      })
  };
};

/**
 * Возвращает актуальное названия блока
 * @param rootStore - RootStore
 * @param blockKind - kind блока
 * @param blockName - название блока по полю name
 * @param paramsName - название блока исходя из параметров
 * @param extraInfoName - название блока использующее информацию extraInfo для переходов
 * @param t
 * @returns string
 */
export const createActualBlockName = ({
  rootStore,
  blockName,
  paramsName,
  blockKind,
  t
}: {
  t: TFunctionType;
  rootStore: IRootStore;
  blockKind: BlockKindType;
  blockName?: string;
  paramsName?: string;
}): string => {
  if (blockName) {
    return blockName;
  }

  const blockKindName =
    rootStore.pluginsStore.blockStaticMap[blockKind]?.name(t) || '';

  return paramsName || blockKindName;
};
