import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const SlashEyeIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon {...props} iconSize={IconSize.S}>
      <path
        d="M13.359 11.238C15.06 9.72 16 8 16 8C16 8 13 2.5 8.00002 2.5C7.03961 2.50331 6.09008 2.70342 5.21002 3.088L5.98002 3.859C6.62746 3.62315 7.31097 3.50168 8.00002 3.5C10.12 3.5 11.879 4.668 13.168 5.957C13.7884 6.58069 14.3445 7.26513 14.828 8C14.77 8.087 14.706 8.183 14.633 8.288C14.298 8.768 13.803 9.408 13.168 10.043C13.003 10.208 12.831 10.371 12.651 10.529L13.359 11.238Z"
        fill="currentColor"
      />
      <path
        d="M11.297 9.176C11.5201 8.55185 11.5615 7.87715 11.4162 7.23042C11.2708 6.5837 10.9449 5.99153 10.4762 5.52282C10.0075 5.05412 9.41529 4.72814 8.76857 4.58283C8.12184 4.43752 7.44715 4.47885 6.82299 4.702L7.64599 5.525C8.0303 5.47 8.42214 5.50525 8.79046 5.62797C9.15878 5.75068 9.49346 5.9575 9.76798 6.23201C10.0425 6.50653 10.2493 6.84121 10.372 7.20953C10.4947 7.57785 10.53 7.96969 10.475 8.354L11.297 9.176ZM8.35399 10.475L9.17599 11.297C8.55183 11.5202 7.87714 11.5615 7.23041 11.4162C6.58368 11.2709 5.99151 10.9449 5.52281 10.4762C5.0541 10.0075 4.72813 9.41531 4.58282 8.76858C4.43751 8.12186 4.47883 7.44716 4.70199 6.823L5.52499 7.646C5.46998 8.03031 5.50523 8.42215 5.62795 8.79047C5.75067 9.1588 5.95748 9.49347 6.232 9.76799C6.50652 10.0425 6.8412 10.2493 7.20952 10.372C7.57784 10.4948 7.96968 10.53 8.35399 10.475Z"
        fill="currentColor"
      />
      <path
        d="M3.35 5.46999C3.17 5.62999 2.997 5.79199 2.832 5.95699C2.21165 6.58068 1.65552 7.26512 1.172 7.99999L1.367 8.28799C1.702 8.76799 2.197 9.40799 2.832 10.043C4.121 11.332 5.881 12.5 8 12.5C8.716 12.5 9.39 12.367 10.02 12.14L10.79 12.912C9.90994 13.2965 8.9604 13.4967 8 13.5C3 13.5 0 7.99999 0 7.99999C0 7.99999 0.939 6.27899 2.641 4.76199L3.349 5.47099L3.35 5.46999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.646 14.354L1.646 2.354L2.354 1.646L14.354 13.646L13.646 14.354Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SlashEyeIcon;
