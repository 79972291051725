import { SetupDocumentParamsServer } from 'shared/entities/documents';
/**
 * Для актов и счет-фактур suppAgId и techSpecUrl нет
 */
export class SetupDocumentParamsTableModel {
  readonly sum: number;
  readonly suppAgId: string | null;
  readonly techSpecUrl: string;

  constructor({
    sum,
    suppAgId,
    techSpecUrl
  }: {
    sum: number;
    suppAgId: string | null;
    techSpecUrl: string;
  }) {
    this.sum = sum;
    this.suppAgId = suppAgId;
    this.techSpecUrl = techSpecUrl;
  }

  static fromJson(
    raw: SetupDocumentParamsServer
  ): SetupDocumentParamsTableModel {
    return new SetupDocumentParamsTableModel({
      sum: raw.sum,
      suppAgId: raw.supp_ag_id || null,
      techSpecUrl: raw.tech_spec_url || ''
    });
  }
}
