import * as React from 'react';

import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';
import BaseIcon from 'shared/newComponents/icons/BaseIcon';

const EyeIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
    >
      <path
        d="M2.42012 12.8399C2.28394 12.6242 2.21584 12.5164 2.17772 12.3501C2.14909 12.2252 2.14909 12.0282 2.17772 11.9033C2.21584 11.737 2.28394 11.6292 2.42012 11.4135C3.54553 9.63155 6.8954 5.12671 12.0004 5.12671C17.1054 5.12671 20.4553 9.63155 21.5807 11.4135C21.7169 11.6292 21.785 11.737 21.8231 11.9033C21.8517 12.0282 21.8517 12.2252 21.8231 12.3501C21.785 12.5164 21.7169 12.6242 21.5807 12.8399C20.4553 14.6219 17.1054 19.1267 12.0004 19.1267C6.8954 19.1267 3.54553 14.6219 2.42012 12.8399Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0004 15.1267C13.6573 15.1267 15.0004 13.7836 15.0004 12.1267C15.0004 10.4699 13.6573 9.12671 12.0004 9.12671C10.3435 9.12671 9.0004 10.4699 9.0004 12.1267C9.0004 13.7836 10.3435 15.1267 12.0004 15.1267Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default EyeIcon;
