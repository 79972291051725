import { VAR_REG_EXP_GLOBALLY } from 'shared/entities/regExp/var';

import { IBaseVarModel } from '../client';

/**
 * Получает из значения переменной все подстроки, удовлетворяющие regExp, и фильтрует по всем ключам переменных
 * @param variable
 * @returns {IBaseVarModel[]}
 * @param availableVars - массив доступных переменных
 */
const parseValueForGettingVarsKeys = ({
  variable,
  availableVars
}: {
  variable: {
    value: string;
  };
  availableVars: IBaseVarModel[];
}): IBaseVarModel[] => {
  const coincidences = Array.from(
    variable.value.match(VAR_REG_EXP_GLOBALLY) || []
  );

  return coincidences.reduce<IBaseVarModel[]>((acc, coincidence) => {
    const foundAvailableVar = availableVars.find(
      (availableVar) => availableVar.key === coincidence
    );

    return foundAvailableVar ? [...acc, foundAvailableVar] : acc;
  }, []);
};

export { parseValueForGettingVarsKeys };
