import { makeObservable, observable } from 'mobx';

import {
  CabinetManagerRoleType,
  ICabinetManagerEdit
} from 'shared/entities/manager';
import { IEdit } from 'shared/entities/edit';

export default class CabinetManagerEditModel
  implements ICabinetManagerEdit, IEdit
{
  name: string;
  email: string;
  password: string;
  role: CabinetManagerRoleType | null;

  constructor({
    name,
    email,
    password,
    role
  }: {
    name: string;
    email: string;
    password: string;
    role: CabinetManagerRoleType | null;
  }) {
    this.name = name;
    this.email = email;
    this.password = password;
    this.role = role;

    makeObservable(this, {
      name: observable,
      email: observable,
      password: observable,
      role: observable
    });
  }

  toJson(): ICabinetManagerEdit {
    return {
      name: this.name,
      email: this.email,
      password: this.password,
      role: this.role
    };
  }

  getCopy(): CabinetManagerEditModel {
    return new CabinetManagerEditModel({
      name: this.name,
      password: this.password,
      email: this.email,
      role: this.role
    });
  }
}
