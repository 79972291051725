import {
  ActAndSFStatus,
  PaymentDocumentKind,
  PaymentDocumentSubject,
  SetupActDocumentServer,
  ICommonDocument,
  normalizeCommonDocument
} from 'shared/entities/documents';

import { SetupDocumentParamsTableModel } from './SetupDocumentParamsTableModel';
import { BaseDocumentModel } from './BaseDocumentModel';

export class SetupActDocumentModel extends BaseDocumentModel {
  readonly status: ActAndSFStatus;
  readonly kind: PaymentDocumentKind.act = PaymentDocumentKind.act;
  readonly subject: PaymentDocumentSubject.setup = PaymentDocumentSubject.setup;
  readonly params: SetupDocumentParamsTableModel;

  constructor({
    params,
    status,
    ...rest
  }: ICommonDocument & {
    params: SetupDocumentParamsTableModel;
    status: ActAndSFStatus;
    cabinetId: string;
  }) {
    super(rest);
    this.params = params;
    this.status = status;
  }

  static fromJson(
    raw: SetupActDocumentServer,
    cabinetId: string
  ): SetupActDocumentModel {
    return new SetupActDocumentModel({
      ...normalizeCommonDocument(raw),
      params: SetupDocumentParamsTableModel.fromJson(raw.params),
      status: raw.status,
      cabinetId
    });
  }
}
