import * as React from 'react';
import { observer } from 'mobx-react';
import FormatInput from 'input-format/react';
import { parseDigit, templateFormatter, templateParser } from 'input-format';

import { InputProps } from 'shared/newEntities/components/Input';
import Input from 'shared/newComponents/form/Input';
import { DateFormat } from 'shared/entities/date';
import { ValidatorResult } from 'shared/entities/validator';
import { useKeyboardEventsStore } from 'stores/index';
import { KeyboardEventType } from 'shared/entities/keyboardEvents';
import { DateBaseModel } from 'shared/models/components/DatePicker';
import { WrappedInputProps } from 'shared/entities/components/Input';
import {
  DateInputDateFormat,
  DateInputProps
} from 'shared/newEntities/components/DateInput';

import {
  mapFormatToPlaceholder,
  mapFormatToTemplate,
  transformToString,
  validateStringValue
} from './utils';

import './DateInput.modules.scss';

const DateInput = <P extends InputProps | WrappedInputProps>({
  value,
  onChange,
  InputComponent,
  dateFormat = DateFormat.dayMonthYear,
  required = true,
  error,
  onError,
  onBlur,
  invalidErrorTitle,
  bothFormatIsValid = true,
  ...rest
}: DateInputProps<P>) => {
  const ref = React.useRef<HTMLInputElement | null>(null);
  const [stringValue, setStringValue] = React.useState<string>(
    transformToString(value, dateFormat)
  );
  const [validError, setValidError] = React.useState<ValidatorResult>(null);

  const [focused, setFocused] = React.useState<boolean>(false);
  const [actualDateFormat, setActualDateFormat] =
    React.useState<DateInputDateFormat>(dateFormat);
  const keyboardEventsStore = useKeyboardEventsStore();

  const invalidDateError = invalidErrorTitle
    ? invalidErrorTitle
    : (t) =>
        t('validators.errors.invalidDateValue', {
          ns: 'entities'
        });

  const handleError = React.useCallback(
    (value: ValidatorResult) => {
      setValidError(() => value);
      onError?.(value);
    },
    [onError]
  );

  React.useEffect(() => {
    if (value || !required) {
      handleError(null);
    }
    setStringValue(transformToString(value, actualDateFormat));
  }, [value, handleError, required, actualDateFormat]);

  const handleChange = React.useCallback(
    (value: string) => {
      setStringValue(value);
      handleError(null);
      if (!value) {
        if (required) {
          handleError((t) =>
            t('validators.errors.requiredField', {
              ns: 'entities'
            })
          );
          return;
        }
        onChange(null);
        return;
      }
      const result: BaseResponse<{ date: Date; format: DateInputDateFormat }> =
        validateStringValue(value, bothFormatIsValid ? undefined : dateFormat);

      if (!result.isError) {
        setActualDateFormat(result.data.format);
        const model = new DateBaseModel(result.data.date, result.data.format);
        onChange(model);
      } else {
        handleError(invalidDateError);
      }
    },
    [handleError, onChange, required, dateFormat, bothFormatIsValid]
  );

  const handleFocus = React.useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = React.useCallback(() => {
    setFocused(false);
    onBlur?.();
  }, [onBlur]);

  const handleEnter = React.useCallback(() => {
    ref.current?.blur();

    return false;
  }, []);

  React.useEffect(() => {
    if (focused) {
      keyboardEventsStore.addListener({
        type: KeyboardEventType.enter,
        listener: handleEnter
      });
    }

    return () => {
      if (focused) {
        keyboardEventsStore.removeListener({
          type: KeyboardEventType.enter,
          listener: handleEnter
        });
      }
    };
  }, [focused, handleEnter]);

  const Component = InputComponent ?? Input;
  const template = mapFormatToTemplate[dateFormat];
  const placeholder = mapFormatToPlaceholder[dateFormat];

  return (
    <FormatInput
      ref={ref}
      styleName="container"
      value={stringValue}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      parse={templateParser(template, parseDigit)}
      format={templateFormatter(template)}
      inputComponent={Component}
      onFocus={handleFocus}
      error={validError ?? error}
      {...rest}
    />
  );
};

export default observer(DateInput);
