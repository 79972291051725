import { action, makeObservable, observable } from 'mobx';

import {
  ITemplateModel,
  ScenarioTemplateServer
} from 'shared/entities/scenario';
import { IRootStore } from 'shared/entities/store/rootStore';
import { TemplateCategory } from 'shared/entities/templates';
import { AppNotificationType } from 'shared/entities/appNotifications';

import { FieldModel } from '../form';

export default class TemplateModel implements ITemplateModel {
  readonly categories: FieldModel<TemplateCategory[]>;
  visible: boolean;
  description: string;

  readonly useCount: number;

  private rootStore: IRootStore;

  constructor({
    rootStore,
    visible,
    categories,
    description,
    useCount
  }: {
    visible: boolean;
    description: string;
    categories: TemplateCategory[];
    rootStore: IRootStore;
    useCount: number;
  }) {
    this.rootStore = rootStore;
    this.visible = visible;
    this.description = description;
    this.categories = new FieldModel<TemplateCategory[]>(categories);
    this.useCount = useCount;

    makeObservable(this, {
      visible: observable,
      description: observable,

      setIsVisible: action,
      changeDescription: action
    });
  }

  setIsVisible = (value: boolean): void => {
    this.visible = value;
  };

  changeDescription = (value: string): void => {
    this.description = value;
  };

  changeCategories = (value: TemplateCategory[]) => {
    if (!value.length) {
      this.rootStore.appNotificationsStore.open({
        type: AppNotificationType.warning,
        title: (t) =>
          t('scenario.TemplateModel.noCategories', {
            ns: 'models'
          })
      });
    }
    this.categories.changeValue(value);
  };

  toJson(): ScenarioTemplateServer {
    return {
      categories: this.rootStore.permissionsStore.canManageTemplate
        ? this.categories.value
        : undefined,
      visible: this.visible,
      description: this.rootStore.permissionsStore.canManageTemplate
        ? this.description
        : undefined
    };
  }

  static fromJson(
    raw: ScenarioTemplateServer,
    rootStore: IRootStore
  ): TemplateModel {
    return new TemplateModel({
      rootStore,
      visible: raw.visible,
      description: raw.description || '',
      categories: raw.categories || [],
      useCount: raw.use_count ?? 0
    });
  }

  static fromDefaultParams(rootStore: IRootStore): TemplateModel {
    return new TemplateModel({
      rootStore,
      visible: false,
      description: '',
      categories: [],
      useCount: 0
    });
  }
}
