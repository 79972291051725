export enum Color {
  COLOR_WHITE = 'color_white',
  COLOR_GRAY_1 = 'color_gray_1',
  COLOR_GRAY_2 = 'color_gray_2',
  COLOR_GRAY_3 = 'color_gray_3',
  COLOR_GRAY_4 = 'color_gray_4',
  COLOR_GRAY_5 = 'color_gray_5',
  COLOR_GRAY_8 = 'color_gray_8',
  COLOR_GRAY_9 = 'color_gray_9',
  COLOR_GRAY_13 = 'color_gray_13',
  COLOR_RED = 'color_red',
  COLOR_ORANGE_1 = 'color_orange_1',
  COLOR_ORANGE_2 = 'color_orange_2',
  COLOR_YELLOW = 'color_yellow',
  COLOR_GREEN_1 = 'color_green_1',
  COLOR_GREEN_2 = 'color_green_2',
  COLOR_GREEN_3 = 'color_green_3',
  COLOR_BLUE_1 = 'color_blue_1',
  COLOR_BLUE_2 = 'color_blue_2',
  COLOR_BLUE_5 = 'color_blue_5',
  COLOR_BLUE_6 = 'color_blue_6',
  COLOR_BLUE_12 = 'color_blue_12',
  COLOR_BLUE_14 = 'color_blue_14',
  COLOR_BLUE_3 = 'color_blue_3',
  COLOR_PURPLE_1 = 'color_purple_1',
  COLOR_PURPLE_2 = 'color_purple_2',
  COLOR_PINK_1 = 'color_pink_1',
  COLOR_PINK_2 = 'color_pink_2',
  COLOR_PINK_3 = 'color_pink_3',

  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  NEGATIVE = 'negative',
  SPECIAL = 'special',
  POSITIVE = 'positive'
}

export interface IColor {
  id: Color;
  interpretation: string;
  fontColor: Color;
}

export const mapColors: Record<Color, IColor> = {
  [Color.COLOR_WHITE]: {
    id: Color.COLOR_WHITE,
    interpretation: '#ffffff',
    fontColor: Color.COLOR_BLUE_12
  },
  [Color.COLOR_GRAY_1]: {
    id: Color.COLOR_GRAY_1,
    interpretation: '#f6f8f9',
    fontColor: Color.COLOR_GRAY_13
  },
  [Color.COLOR_GRAY_2]: {
    id: Color.COLOR_GRAY_2,
    interpretation: '#8ea3a6',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_GRAY_3]: {
    id: Color.COLOR_GRAY_3,
    interpretation: '#e5e9ec',
    fontColor: Color.COLOR_GRAY_13
  },
  [Color.COLOR_GRAY_4]: {
    id: Color.COLOR_GRAY_4,
    interpretation: '#d3d7db',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_GRAY_8]: {
    id: Color.COLOR_GRAY_8,
    interpretation: '#99a6b2',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_GRAY_9]: {
    id: Color.COLOR_GRAY_9,
    interpretation: '#8c9ba9',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_GRAY_13]: {
    id: Color.COLOR_GRAY_13,
    interpretation: '#5f7487',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_RED]: {
    id: Color.COLOR_RED,
    interpretation: '#e53b52',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_ORANGE_1]: {
    id: Color.COLOR_ORANGE_1,
    interpretation: '#fa6237',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_ORANGE_2]: {
    id: Color.COLOR_ORANGE_2,
    interpretation: '#fb9926',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_YELLOW]: {
    id: Color.COLOR_YELLOW,
    interpretation: '#edc22b',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_GREEN_1]: {
    id: Color.COLOR_GREEN_1,
    interpretation: '#a5cd3f',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_GREEN_2]: {
    id: Color.COLOR_GREEN_2,
    interpretation: '#66d073',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_GREEN_3]: {
    id: Color.COLOR_GREEN_3,
    interpretation: '#40c4ab',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_BLUE_1]: {
    id: Color.COLOR_BLUE_1,
    interpretation: '#2cabe7',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_BLUE_2]: {
    id: Color.COLOR_BLUE_2,
    interpretation: '#4588dd',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_BLUE_3]: {
    id: Color.COLOR_BLUE_3,
    interpretation: '#61b2ea',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_BLUE_6]: {
    id: Color.COLOR_BLUE_6,
    interpretation: '#deedf4',
    fontColor: Color.COLOR_BLUE_12
  },
  [Color.COLOR_PURPLE_1]: {
    id: Color.COLOR_PURPLE_1,
    interpretation: '#7a73ed',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_PURPLE_2]: {
    id: Color.COLOR_PURPLE_2,
    interpretation: '#a967e0',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_PINK_1]: {
    id: Color.COLOR_PINK_1,
    interpretation: '#e167e1',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_PINK_2]: {
    id: Color.COLOR_PINK_2,
    interpretation: '#e8529d',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_PINK_3]: {
    id: Color.COLOR_PINK_3,
    interpretation: '#fa92ad',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_BLUE_12]: {
    id: Color.COLOR_BLUE_12,
    interpretation: '#168ee0',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_BLUE_5]: {
    id: Color.COLOR_BLUE_5,
    interpretation: '#98cdf1',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_BLUE_14]: {
    id: Color.COLOR_BLUE_14,
    interpretation: '#1479bd',
    fontColor: Color.COLOR_WHITE
  },
  [Color.COLOR_GRAY_5]: {
    id: Color.COLOR_GRAY_5,
    interpretation: '#bfc7cf',
    fontColor: Color.COLOR_WHITE
  },
  [Color.PRIMARY]: {
    id: Color.PRIMARY,
    interpretation: '#5181b8',
    fontColor: Color.COLOR_WHITE
  },
  [Color.SECONDARY]: {
    id: Color.SECONDARY,
    interpretation: '#e6ebf1',
    fontColor: Color.COLOR_WHITE
  },
  [Color.POSITIVE]: {
    id: Color.POSITIVE,
    interpretation: '#4bb34b',
    fontColor: Color.COLOR_WHITE
  },
  [Color.NEGATIVE]: {
    id: Color.NEGATIVE,
    interpretation: '#e64646',
    fontColor: Color.COLOR_WHITE
  },
  [Color.SPECIAL]: {
    id: Color.SPECIAL,
    interpretation: '#a967e0',
    fontColor: Color.COLOR_WHITE
  }
};

export const colorsOrder: Color[] = [
  Color.COLOR_GRAY_1,
  Color.COLOR_GRAY_2,
  Color.COLOR_RED,
  Color.COLOR_ORANGE_1,
  Color.COLOR_ORANGE_2,
  Color.COLOR_YELLOW,
  Color.COLOR_GREEN_1,
  Color.COLOR_GREEN_2,
  Color.COLOR_GREEN_3,
  Color.COLOR_BLUE_1,
  Color.COLOR_BLUE_2,
  Color.COLOR_PURPLE_1,
  Color.COLOR_PURPLE_2,
  Color.COLOR_PINK_1,
  Color.COLOR_PINK_2,
  Color.COLOR_PINK_3
];
