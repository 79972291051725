import { computed, makeObservable } from 'mobx';

import {
  IUserPaymentMethodModel,
  UserPaymentMethodKind
} from 'shared/entities/userPayment';
import ListModel from 'shared/models/ListModel';
import { NotConnectedQiwiWallet } from 'shared/models/qiwi';
import { CommonUserPaymentMethodModel } from 'shared/models/userPayment';
import { IRootStore } from 'shared/entities/store/rootStore';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';

export default class QiwiStore extends ComponentLoadingStore {
  readonly notConnectedWallets: ListModel<NotConnectedQiwiWallet> =
    new ListModel<NotConnectedQiwiWallet>();
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;

    this.addWallet = this.addWallet.bind(this);
    this.createMethod = this.createMethod.bind(this);
    this.removeWallet = this.removeWallet.bind(this);

    makeObservable(this, {
      entitiesLength: computed
    });
  }

  get loaded(): boolean {
    return true;
  }

  get canBeLoaded(): boolean {
    return true;
  }

  get methods(): IUserPaymentMethodModel[] {
    return this.rootStore.integrationsStore.userPaymentsStore.methods.items.filter(
      (method) => method.providerKind === UserPaymentMethodKind.qiwi
    );
  }

  get entitiesLength(): number {
    return this.notConnectedWallets.length + this.methods.length;
  }

  addWallet(): void {
    const wallet = NotConnectedQiwiWallet.fromDefaultParams(
      `Новый кошелек ${this.notConnectedWallets.length + 1}`,
      this.rootStore
    );

    this.notConnectedWallets.addEntity({ entity: wallet, key: wallet.id });
  }

  removeWallet(notConnectedWalletId: string): void {
    this.notConnectedWallets.removeEntity(notConnectedWalletId);
  }

  async createMethod(notConnectedWalletId: string): Promise<BaseResponse> {
    const wallet = this.notConnectedWallets.getEntity(notConnectedWalletId);

    if (!wallet) {
      return {
        isError: true
      };
    }

    const response = await wallet.create();

    if (!response.isError) {
      const method = CommonUserPaymentMethodModel.fromJson(
        response.data,
        this.rootStore
      );

      this.notConnectedWallets.removeEntity(notConnectedWalletId);
      this.rootStore.integrationsStore.userPaymentsStore.methods.addEntity({
        entity: method,
        key: method.id
      });

      return {
        isError: false
      };
    }

    return {
      isError: true
    };
  }

  async load(): Promise<BaseResponse> {
    return {
      isError: false
    };
  }

  reset(): void {
    this.notConnectedWallets.reset();
  }
}
