import { observer } from 'mobx-react';
import * as React from 'react';

import { useRootStore } from 'stores/index';

const SupportPopup: React.FC = () => {
  const rootStore = useRootStore();

  React.useEffect(() => {
    rootStore.supportPopupStore.initialize();

    return () => {
      rootStore.supportPopupStore.disposeAll();
    };
  }, []);

  React.useEffect(() => {
    if (
      rootStore.cabinetInitialized &&
      rootStore.supportPopupStore.isCarrotquestModalAvailable
    ) {
      rootStore.supportPopupStore.initCarrotquestIdentify();
    }
  }, [
    rootStore.cabinetInitialized,
    rootStore.supportPopupStore.isCarrotquestModalAvailable
  ]);

  return null;
};

export default observer(SupportPopup);
