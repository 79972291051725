import { resetDateTime } from 'shared/entities/common/utils';
import { DateFormat } from 'shared/entities/date';
import {
  DatePickerDateFormat,
  IDateBase
} from 'shared/newEntities/components/DatePicker';

export default class DateBaseModel implements IDateBase {
  readonly date: Date;
  readonly id: string;
  readonly dateFormat: DatePickerDateFormat;

  constructor(
    date: Date,
    dateFormat: DatePickerDateFormat = DateFormat.dayMonthYear
  ) {
    this.date =
      dateFormat === DateFormat.dayMonthYearTime ? date : resetDateTime(date);
    this.id = this.date.toISOString();
    this.dateFormat = dateFormat;
  }

  isEqual(model: DateBaseModel): boolean {
    return this.id === model.id;
  }

  getCopy(): DateBaseModel {
    return new DateBaseModel(this.date, this.dateFormat);
  }
}
