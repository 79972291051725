import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const PaymegaIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.8068C17.5228 21.8068 22 17.3729 22 11.9034C22 11.6631 21.9914 11.4249 21.9744 11.1889C21.5995 15.6124 17.1596 17.8626 11.1882 17.8626C7.62181 17.8626 4.74678 17.0982 2.78826 15.7638C4.3077 19.3153 7.86005 21.8068 12 21.8068ZM6.16145 3.86234C7.80425 2.69038 9.8207 2 12 2C15.2825 2 18.1957 3.56635 20.019 5.98553C18.1064 4.19556 14.9514 3.23805 11.1882 3.23805C9.31107 3.23805 7.62455 3.45795 6.16145 3.86234ZM2.02258 12.5742C2.00761 12.3525 2 12.1288 2 11.9034C2 10.5949 2.25625 9.3456 2.72176 8.20218C4.20426 7.70842 6.3028 7.54195 8.60958 7.54195C12.5453 7.54195 14.8072 8.55205 14.8072 10.5283C14.8072 12.5046 12.5453 13.5147 8.60958 13.5147C5.91156 13.5147 3.49837 13.287 2.02258 12.5742Z"
        fill="url(#paint0_linear_5093_1600)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5093_1600"
          x1="22"
          y1="2"
          x2="2.19417"
          y2="21.9991"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.197917" stopColor="#E4FF33" />
          <stop offset="1" stopColor="#FF5E1A" />
        </linearGradient>
      </defs>
    </BaseIcon>
  );
};

export default PaymegaIcon;
