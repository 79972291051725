import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const OneNumberIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXLM}
      svgProps={{
        viewBox: '0 0 48 48'
      }}
      {...props}
    >
      <path
        d="M28.8984 10V38.5938H25.2852V14.5117L18 17.168V13.9062L28.332 10H28.8984Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default OneNumberIcon;
