import * as React from 'react';

import { ComponentType } from 'shared/entities/components/Component';
import { TranslationNode } from 'shared/entities/localization';
import { LoadingStage } from 'shared/entities/meta';
import {
  CommonIconProps,
  IconSize,
  IconStyle
} from 'shared/entities/components/Icon';
import {
  TypographyProps,
  TypographyType,
  TypographySize,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import {
  LoaderSpinnerSize,
  LoaderSpinnerColor
} from 'shared/newEntities/components/LoaderSpinner';

export enum ButtonSize {
  xs = 'xs',
  s = 's',
  sm = 'sm',
  m = 'm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = '2xl'
}

export enum ButtonColor {
  blue = 'blue',
  red = 'red',
  lightBlue = 'lightBlue',
  lightRed = 'lightRed',
  gray = 'gray',
  lightGray = 'lightGray',
  cian = 'cian',
  violet = 'violet',
  purple = 'purple',
  redSecondary = 'redSecondary',
  redTertiary = 'redTertiary',
  green = 'green'
}
export enum ButtonStyle {
  transparent = 'transparent',
  common = 'common'
}

export enum ButtonContentPosition {
  start = 'start',
  center = 'center',
  left = 'left'
}

export const mapButtonSizeToIconSize: Record<ButtonSize, IconSize> = {
  [ButtonSize.xs]: IconSize.S,
  [ButtonSize.s]: IconSize.XM,
  [ButtonSize.sm]: IconSize.XM,
  [ButtonSize.m]: IconSize.XM,
  [ButtonSize.md]: IconSize.XM,
  [ButtonSize.lg]: IconSize.XM,
  [ButtonSize.xl]: IconSize.XM,
  [ButtonSize.xxl]: IconSize.L
};

export const mapButtonSizeToLoaderSpinnerSize: Record<
  ButtonSize,
  LoaderSpinnerSize
> = {
  [ButtonSize.xs]: LoaderSpinnerSize.sm,
  [ButtonSize.s]: LoaderSpinnerSize.sm,
  [ButtonSize.sm]: LoaderSpinnerSize.sm,
  [ButtonSize.m]: LoaderSpinnerSize.sm,
  [ButtonSize.md]: LoaderSpinnerSize.sm,
  [ButtonSize.lg]: LoaderSpinnerSize.sm,
  [ButtonSize.xl]: LoaderSpinnerSize.sm,
  [ButtonSize.xxl]: LoaderSpinnerSize.md
};

export const mapButtonColorToLoaderColor: Record<
  ButtonColor,
  LoaderSpinnerColor
> = {
  [ButtonColor.blue]: LoaderSpinnerColor.white,
  [ButtonColor.red]: LoaderSpinnerColor.white,
  [ButtonColor.lightBlue]: LoaderSpinnerColor.white,
  [ButtonColor.lightRed]: LoaderSpinnerColor.white,
  [ButtonColor.gray]: LoaderSpinnerColor.blue,
  [ButtonColor.lightGray]: LoaderSpinnerColor.blue,
  [ButtonColor.cian]: LoaderSpinnerColor.white,
  [ButtonColor.violet]: LoaderSpinnerColor.white,
  [ButtonColor.purple]: LoaderSpinnerColor.white,
  [ButtonColor.redSecondary]: LoaderSpinnerColor.blue,
  [ButtonColor.redTertiary]: LoaderSpinnerColor.white,
  [ButtonColor.green]: LoaderSpinnerColor.white
};

export const buttonFontProps: Record<ButtonSize, TypographyProps<'div'>> = {
  [ButtonSize.xs]: {
    fontType: TypographyType.text,
    fontSize: TypographySize.xs
  },
  [ButtonSize.s]: {
    fontType: TypographyType.text,
    fontSize: TypographySize.s
  },
  [ButtonSize.sm]: {
    fontType: TypographyType.text,
    fontSize: TypographySize.s
  },
  [ButtonSize.m]: {
    fontType: TypographyType.text,
    fontSize: TypographySize.s
  },
  [ButtonSize.md]: {
    fontType: TypographyType.text,
    fontSize: TypographySize.s
  },
  [ButtonSize.lg]: {
    fontType: TypographyType.text,
    fontSize: TypographySize.m
  },
  [ButtonSize.xl]: {
    fontType: TypographyType.text,
    fontSize: TypographySize.s
  },
  [ButtonSize.xxl]: {
    fontType: TypographyType.text,
    fontSize: TypographySize.m
  }
};

export type BaseButtonProps = {
  className?: string;
  children?: TranslationNode | null;
  loadingStage?: LoadingStage;
  disabled?: boolean;
  fullWidth?: boolean;
  Icon?: React.FC<CommonIconProps>;
  RightIcon?: React.FC<CommonIconProps>;
  size?: ButtonSize;
  color?: ButtonColor;
  transparentBorder?: boolean;
  style?: ButtonStyle;
  withoutPadding?: boolean;
  contentPosition?: ButtonContentPosition;
  withoutBorder?: boolean;
  iconStyle?: IconStyle;
  fontWeight?: TypographyWeight;
};
export type ComponentButtonProps =
  | {
      type: ComponentType.link;
      to: string;
      onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    }
  | {
      type: ComponentType.button;
      onClick: React.MouseEventHandler<HTMLButtonElement>;
    }
  | {
      type: ComponentType.a;
      href: string;
      target?: string;
      rel?: string;
      onClick?: React.MouseEventHandler<HTMLAnchorElement>;
      download?: boolean;
    };

export type ButtonProps = BaseButtonProps & ComponentButtonProps;
