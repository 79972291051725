import { observer } from 'mobx-react';
import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { ITgChannelModel } from 'shared/entities/channels';
import { Input, TextArea } from 'shared/newComponents/form';
import FadeScroll from 'shared/newComponents/FadeScroll';
import { Button } from 'shared/newComponents/buttons';
import { ComponentType } from 'shared/entities/components/Component';
import { ButtonColor, ButtonSize } from 'shared/newEntities/components/Button';
import { RemoveIcon } from 'shared/newComponents/icons';
import { useUiStore } from 'stores/index';

import CommandSelector from './CommandSelector';

import './Commands.modules.scss';

type Props = {
  channel: ITgChannelModel;
};

const Commands: React.FC<Props> = ({ channel }) => {
  const selectedCommand = channel.selectedCommand;
  const uiStore = useUiStore();

  React.useEffect(() => {
    return () => {
      channel.closeCommand();
    };
  }, []);

  React.useEffect(() => {
    if (channel.selectedCommandId.value) {
      channel.commandsFormState.open();
    }
  }, [channel.selectedCommandId.value]);

  return (
    <div styleName="commands">
      <CommandSelector styleName="commands__selector" channel={channel} />
      <AnimatePresence>
        {channel.commandsFormState.opened && (
          <>
            <motion.div
              initial={{ flexGrow: 0, opacity: 0 }}
              animate={{ flexGrow: 1, opacity: 1 }}
              exit={{ flexGrow: 0, opacity: 0 }}
              key="form"
              styleName="commands__form-container"
            >
              <FadeScroll withoutFade styleName="commands__form">
                <Input
                  label={(t) =>
                    t(
                      'ChannelsModal.SelectedChannel.Content.Commands.name.label',
                      {
                        ns: 'newContainers'
                      }
                    )
                  }
                  placeholder="start"
                  value={selectedCommand.commandModel.value}
                  onChange={selectedCommand.commandModel.changeValue}
                  onBlur={selectedCommand.commandModel.validate}
                  error={selectedCommand.commandModel.error}
                  prefix="/"
                />
                <TextArea
                  label={(t) =>
                    t(
                      'ChannelsModal.SelectedChannel.Content.Commands.description.label',
                      {
                        ns: 'newContainers'
                      }
                    )
                  }
                  placeholder={(t) =>
                    t(
                      'ChannelsModal.SelectedChannel.Content.Commands.description.placeholder',
                      {
                        ns: 'newContainers'
                      }
                    )
                  }
                  styleName="commands__description"
                  value={selectedCommand.descriptionModel.value}
                  onChange={selectedCommand.descriptionModel.changeValue}
                  onBlur={selectedCommand.descriptionModel.validate}
                  error={selectedCommand.descriptionModel.error}
                />
              </FadeScroll>
            </motion.div>
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'unset', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              key="buttons"
              styleName="commands__buttons"
            >
              <Button
                styleName="commands__remove"
                type={ComponentType.button}
                onClick={() => channel.removeCommand(selectedCommand.id)}
                size={uiStore.isMobileSize ? ButtonSize.md : ButtonSize.lg}
                color={ButtonColor.lightRed}
                loadingStage={selectedCommand.removingStage.value}
                Icon={RemoveIcon}
              />
              <Button
                styleName="commands__update"
                type={ComponentType.button}
                onClick={channel.saveCommand}
                size={uiStore.isMobileSize ? ButtonSize.md : ButtonSize.lg}
                disabled={
                  selectedCommand.isError || !selectedCommand.hasChanged
                }
                loadingStage={selectedCommand.updatingStage.value}
              >
                {(t) =>
                  t(
                    'ChannelsModal.SelectedChannel.Content.Commands.buttonTitle',
                    {
                      ns: 'newContainers'
                    }
                  )
                }
              </Button>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default observer(Commands);
