import * as React from 'react';

import { TransitionLeftArrowIcon } from 'shared/newComponents/icons';
import { TranslationNode } from 'shared/entities/localization';
import {
  BaseButtonProps,
  ButtonColor,
  ButtonStyle,
  ComponentButtonProps
} from 'shared/newEntities/components/Button';
import { Button } from 'shared/newComponents/buttons';

type Props = Omit<
  BaseButtonProps,
  'Icon' | 'RightIcon' | 'children' | 'style' | 'color'
> &
  ComponentButtonProps & {
    children: TranslationNode;
  };

const TransitionTitle: React.FC<Props> = (props: Props) => {
  return (
    <Button
      {...props}
      color={ButtonColor.blue}
      style={ButtonStyle.transparent}
      Icon={TransitionLeftArrowIcon}
      withoutPadding
    />
  );
};

export default TransitionTitle;
