import { IListEntity } from './client';
import { ListEntityServer } from './server';

export const normalizeList = (raw: ListEntityServer): IListEntity => ({
  id: raw._id,
  title: raw.name,
  type: raw.type,
  tag: raw.tag,
  count: raw.count
});
