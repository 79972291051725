import { CategoryWithPhotoType } from '@shopback/ui/lib/types/categories';
import { AttachmentType } from '@shopback/ui/src/types/attachment';

import { TFunctionType } from 'shared/entities/localization';

import oranges from './oranges.png';
import blueberries from './blueberries.png';
import defaultImage from './defaultImage.png';
import mandarins from './mandarins.png';
import apples from './apples.png';

export const createCategoriesPreview = (
  t: TFunctionType
): CategoryWithPhotoType[] => [
  {
    id: 'fruits',
    name: t('shopback.preview.categories.fruits', {
      ns: 'entities'
    }),
    attachment: {
      src: oranges,
      width: 360,
      height: 400
    }
  },
  {
    id: 'berries',
    name: t('shopback.preview.categories.berries', {
      ns: 'entities'
    }),
    attachment: {
      src: blueberries,
      width: 360,
      height: 480
    }
  }
];

export const createChildCategoriesPreview = (
  t: TFunctionType
): CategoryWithPhotoType[] => [
  {
    id: 'mandarins',
    name: t('shopback.preview.childCategories.mandarins', {
      ns: 'entities'
    }),
    attachment: {
      src: mandarins,
      width: 564,
      height: 366
    }
  },
  {
    id: 'apples',
    name: t('shopback.preview.childCategories.apples', {
      ns: 'entities'
    }),
    attachment: {
      src: apples,
      width: 644,
      height: 404
    }
  }
];

export const defaultAttachment: AttachmentType = {
  src: defaultImage,
  width: 392,
  height: 424
};
