import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import {
  IconSize,
  CommonIconProps,
  LogoIconProps
} from 'shared/entities/components/Icon';

const FacebookIcon: React.FC<LogoIconProps> = ({ color, ...rest }) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXL}
      svgProps={{ viewBox: '0 0 40 40' }}
      {...rest}
    >
      <g clipPath="url(#clip0_6304_26)">
        <path
          d="M16.7 39.8C7.2 38.1 0 29.9 0 20C0 9 9 0 20 0C31 0 40 9 40 20C40 29.9 32.8 38.1 23.3 39.8L22.2 38.9H17.8L16.7 39.8Z"
          fill={
            color === 'original'
              ? 'url(#paint0_linear_6304_26)'
              : 'currentColor'
          }
        />
        <path
          d="M27.8 25.6L28.7 20H23.4V16.1C23.4 14.5 24 13.3 26.4 13.3H29V8.20005C27.6 8.00005 26 7.80005 24.6 7.80005C20 7.80005 16.8 10.6 16.8 15.6V20H11.8V25.6H16.8V39.7001C17.9 39.9001 19 40 20.1 40C21.2 40 22.3 39.9001 23.4 39.7001V25.6H27.8Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6304_26"
          x1="20.001"
          y1="38.6089"
          x2="20.001"
          y2="-0.00736777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0062E0" />
          <stop offset="1" stopColor="#19AFFF" />
        </linearGradient>
        <clipPath id="clip0_6304_26">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export const FacebookOriginalIcon: React.FC<CommonIconProps> = (props) => (
  <FacebookIcon color="original" {...props} />
);

export const FacebookTransparentIcon: React.FC<CommonIconProps> = (props) => (
  <FacebookIcon color="transparent" {...props} />
);
