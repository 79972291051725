import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const DndIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M9.5 18C9.0875 18 8.7345 17.8533 8.441 17.5597C8.147 17.2657 8 16.9125 8 16.5C8 16.0875 8.147 15.7343 8.441 15.4403C8.7345 15.1467 9.0875 15 9.5 15C9.9125 15 10.2658 15.1467 10.5598 15.4403C10.8533 15.7343 11 16.0875 11 16.5C11 16.9125 10.8533 17.2657 10.5598 17.5597C10.2658 17.8533 9.9125 18 9.5 18ZM14 18C13.5875 18 13.2345 17.8533 12.941 17.5597C12.647 17.2657 12.5 16.9125 12.5 16.5C12.5 16.0875 12.647 15.7343 12.941 15.4403C13.2345 15.1467 13.5875 15 14 15C14.4125 15 14.7657 15.1467 15.0598 15.4403C15.3533 15.7343 15.5 16.0875 15.5 16.5C15.5 16.9125 15.3533 17.2657 15.0598 17.5597C14.7657 17.8533 14.4125 18 14 18ZM9.5 13.5C9.0875 13.5 8.7345 13.353 8.441 13.059C8.147 12.7655 8 12.4125 8 12C8 11.5875 8.147 11.2343 8.441 10.9402C8.7345 10.6467 9.0875 10.5 9.5 10.5C9.9125 10.5 10.2658 10.6467 10.5598 10.9402C10.8533 11.2343 11 11.5875 11 12C11 12.4125 10.8533 12.7655 10.5598 13.059C10.2658 13.353 9.9125 13.5 9.5 13.5ZM14 13.5C13.5875 13.5 13.2345 13.353 12.941 13.059C12.647 12.7655 12.5 12.4125 12.5 12C12.5 11.5875 12.647 11.2343 12.941 10.9402C13.2345 10.6467 13.5875 10.5 14 10.5C14.4125 10.5 14.7657 10.6467 15.0598 10.9402C15.3533 11.2343 15.5 11.5875 15.5 12C15.5 12.4125 15.3533 12.7655 15.0598 13.059C14.7657 13.353 14.4125 13.5 14 13.5ZM9.5 9C9.0875 9 8.7345 8.853 8.441 8.559C8.147 8.2655 8 7.9125 8 7.5C8 7.0875 8.147 6.7345 8.441 6.441C8.7345 6.147 9.0875 6 9.5 6C9.9125 6 10.2658 6.147 10.5598 6.441C10.8533 6.7345 11 7.0875 11 7.5C11 7.9125 10.8533 8.2655 10.5598 8.559C10.2658 8.853 9.9125 9 9.5 9ZM14 9C13.5875 9 13.2345 8.853 12.941 8.559C12.647 8.2655 12.5 7.9125 12.5 7.5C12.5 7.0875 12.647 6.7345 12.941 6.441C13.2345 6.147 13.5875 6 14 6C14.4125 6 14.7657 6.147 15.0598 6.441C15.3533 6.7345 15.5 7.0875 15.5 7.5C15.5 7.9125 15.3533 8.2655 15.0598 8.559C14.7657 8.853 14.4125 9 14 9Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DndIcon;
