import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';

import { AppPlugins } from 'shared/entities/app';
import { RootStore } from 'stores/rootStore';
import AppNotifications from 'shared/containers/AppNotifications';
import { useLocalAsyncStore } from 'shared/entities/common/hooks';

import App from './App';

type Props = {
  plugins?: AppPlugins;
};

const Root: React.FC<Props> = ({ plugins }: Props) => {
  const rootStoreContext = useLocalAsyncStore(() => RootStore.init(plugins));

  React.useEffect(() => {
    if (!rootStoreContext) {
      return;
    }

    if (rootStoreContext.store.pluginsStore.analyticsEnabled) {
      rootStoreContext.store.analyticsStore.initialize();
    }

    rootStoreContext.store.initComponentLoadingStoresReactions();
    rootStoreContext.store.websocketStore.initializeReactions();

    return () => {
      rootStoreContext.store.disposeAllComponentLoadingStoresReactions();
      rootStoreContext.store.websocketStore.disposeAll();
      rootStoreContext.store.analyticsStore.reset();
    };
  }, [rootStoreContext]);

  if (!rootStoreContext) {
    return null;
  }

  return (
    <Provider {...rootStoreContext}>
      <BrowserRouter>
        <App />
        <AppNotifications />
      </BrowserRouter>
    </Provider>
  );
};

export default React.memo(Root);
