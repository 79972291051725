import { TimeoutServer } from './server';
import { ITimeout } from './client';

export const normalizeTimeout = (
  raw: TimeoutServer,
  withVarInterval = false
): ITimeout => ({
  interval: raw.interval,
  unit: raw.unit,
  withVarInterval
});
