import * as React from 'react';

export enum PopupPosition {
  topLeft = 'top left',
  topCenter = 'top center',
  topRight = 'top right',
  rightTop = 'right top',
  rightCenter = 'right center',
  rightBottom = 'right bottom',
  bottomLeft = 'bottom left',
  bottomCenter = 'bottom center',
  bottomRight = 'bottom right',
  leftTop = 'left top',
  leftCenter = 'left center',
  leftBottom = 'left bottom',
  centerCenter = 'center center'
}

export enum PopupEventType {
  hover = 'hover',
  click = 'click',
  focus = 'focus',
  rightClick = 'right-click'
}

export type PopupChildren =
  | React.ReactElement
  | ((close: () => void, isOpen: boolean) => React.ReactElement)
  | null;

export type PopupTrigger =
  | React.ReactElement
  | ((isOpen: boolean) => React.ReactElement);

export type PopupProps = {
  trigger: PopupTrigger;
  children: PopupChildren;
  position?: PopupPosition | PopupPosition[];
  on?: PopupEventType;
  className?: string;
  triggerClassname?: string;
  containerFullHeight?: boolean;
  contentStyle?: React.CSSProperties;
  onOpen?: () => void;
  onClose?: () => void;
  disabled?: boolean;
  closeOnEscape?: boolean;
  closeOnDocumentClick?: boolean;
  isOpen?: boolean;
  nested?: boolean;
};

export type PopupRef = {
  setPosition: () => void;
  container: HTMLDivElement | null;
} | null;
