import { observer } from 'mobx-react';
import { motion } from 'framer-motion';
import * as React from 'react';

import Section from 'shared/newComponents/Section';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import TransitionTitle from 'shared/newComponents/TransitionTitle';
import { ComponentType } from 'shared/entities/components/Component';
import {
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';

import { useSelectAnimation } from '../hooks';

import Content from './Content';

import './SelectedChannel.modules.scss';

const SelectedChannel: React.FC = () => {
  const { store } = useChannelsModalStore();

  const selectedChannel = store.selectedChannel;
  const { ref, showContent } = useSelectAnimation({
    selected: !!selectedChannel,
    width: '50%'
  });

  return (
    <motion.div ref={ref} styleName="selected-channel">
      <Section>
        {showContent && selectedChannel && (
          <div styleName="channel">
            <TransitionTitle
              type={ComponentType.button}
              onClick={store.unselect}
              styleName="channel__back"
            >
              {(t) =>
                t(
                  !store.withSections
                    ? 'ChannelsModal.SelectedChannel.back.toSelect'
                    : 'ChannelsModal.SelectedChannel.back.toCreate',
                  {
                    ns: 'newContainers'
                  }
                )
              }
            </TransitionTitle>
            <Typography
              fontType={TypographyType.text}
              fontSize={TypographySize.l}
              fontWeight={TypographyWeight.semiBold}
              styleName="channel__title"
            >
              {selectedChannel.name}
            </Typography>
            <Content styleName="channel__content" />
          </div>
        )}
      </Section>
    </motion.div>
  );
};

export default observer(SelectedChannel);
