import { BaseConditionParamsServer } from '../condition';
import { ChannelKind } from '../channels';
import { ScenarioBaseParamsServer, ScenarioKind } from '../scenario';

export type MailingServer = MailingRequiredServer & MailingOptionalServer;

export type MailingRequiredServer = {
  whitelists: string[] | null;
  blacklists: string[];
  conditions: BaseConditionParamsServer;
  planned_date: string | null;
};

export type MailingOptionalServer = {
  _id: string;
  progress: number;
  started_at: string | null;
  finished_at: string | null;
  status: MailingStatus;
  channel_status: ChannelMailingStatusesServer;
  test: boolean;
  messages_sent: number;
  messages_received: number;
};

export type ChannelMailingStatusesServer = Record<
  string,
  ChannelMailingStatusServer
>;

export type ChannelMailingStatusServer = {
  status: MailingStatus;
  progress: number;
  chunks_sent: number;
  chunks_total: number;
  messages_sent: number;
  messages_received: number;
};

export type ChannelMailingServer = {
  _id: string;
  kind: ChannelKind;
  name: string;
  users_matched: number;
  users_total: number;
  reach?: number;
};

export type ScenarioMailingServer = ScenarioBaseParamsServer & {
  kind: ScenarioKind.mailing;
  mailing: MailingServer;
};

export type ScenarioMailingRequiredServer = ScenarioBaseParamsServer & {
  kind: ScenarioKind.mailing;
  mailing: MailingRequiredServer;
};

export enum MailingStatus {
  draft = 'draft',
  planned = 'planned',
  started = 'started',
  inProgress = 'in_progress',
  canceled = 'canceled',
  finished = 'finished',
  chunking = 'chunking',
  failed = 'failed'
}

export enum MailingStatusGroup {
  draft = 'draft',
  general = 'general',
  planned = 'planned'
}
