import { computed, makeObservable } from 'mobx';

import {
  AIAggregatedDocType,
  AIDocsServer,
  AIBotPayload,
  AIBotServer,
  IAIBot,
  mapAIBotGetErrorToTitle,
  AIDocServer
} from 'shared/entities/smartbotAI';
import { FieldModel } from 'shared/models/form';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import { getDomainNameFromUrl } from 'shared/entities/common/utils';
import { AnalyticsEvent } from 'shared/entities/analytics';
import { renderTranslationString } from 'shared/entities/localization';
import { IScenarioData, normalizeScenarioData } from 'shared/entities/scenario';
import { mapAITargetToTitle } from 'shared/entities/smartbotAIInfo';

import { OpenStateModel } from '../openState';
import NewListModel from '../NewListModel';
import { LoadingStageModel } from '../loadingStage';

import { AIAggregatedPdfDocModel } from './AIAggregatedPdfDocModel';
import { AIAggregatedSiteDocModel } from './AIAggregatedSiteDocModel';
import { AIAggregatedDocModel } from './AIAggregatedDocModel';
import { AIEducationParamsModel } from './AIEducationParamsModel';

const INTERVAL = 10000;
export const TEXTS_MAX_LENGTH = 5500;

export class AIBotModel implements IAIBot {
  readonly id: FieldModel<string>;

  readonly params: FieldModel<AIEducationParamsModel>;

  readonly pdfDocs: NewListModel<AIAggregatedPdfDocModel> =
    new NewListModel<AIAggregatedPdfDocModel>();
  readonly siteDocs: NewListModel<AIAggregatedSiteDocModel> =
    new NewListModel<AIAggregatedSiteDocModel>();
  readonly rootStore: IRootStore;

  readonly siteIndexingModalState = new OpenStateModel();
  private timerId: ReturnType<typeof setTimeout> | null = null;
  readonly scenarios: Array<IScenarioData>;
  readonly editNameStage: LoadingStageModel = new LoadingStageModel();

  constructor({
    id,
    params,
    scenarios,
    rootStore
  }: {
    id: string;
    params: AIEducationParamsModel;
    scenarios: IScenarioData[];
    rootStore: IRootStore;
  }) {
    this.id = new FieldModel(id);
    this.params = new FieldModel(params);
    this.scenarios = scenarios;
    this.rootStore = rootStore;

    makeObservable(this, {
      intentTargetTitle: computed,
      allDocs: computed,
      idsInProgress: computed
    });
  }

  get name(): string {
    return this.params.value.name.value;
  }

  get intentTargetTitle(): string | null {
    const title = mapAITargetToTitle(this.params.value.target.value);

    return renderTranslationString(title, this.rootStore.translationsStore.t);
  }

  get allDocs(): AIAggregatedDocModel<
    AIAggregatedDocType.pdf | AIAggregatedDocType.site
  >[] {
    return [...this.siteDocs.items, ...this.pdfDocs.items];
  }

  get idsInProgress(): string[] {
    return this.allDocs
      .filter((doc) => doc.id.value !== null && doc.inProgress)
      .map((doc) => doc.id.value) as string[];
  }

  async update(): Promise<BaseResponse> {
    const response = await this.rootStore.networkStore.api(
      apiUrls.AI_BOTS_UPDATE,
      {
        method: 'POST',
        data: this.toJson()
      }
    );

    if (!response.isError) {
      this.rootStore.analyticsStore.sendEvent(
        AnalyticsEvent.smartbotAIBotUpdate
      );
    }

    return response;
  }

  editName = async (name: FieldModel<string>): Promise<BaseResponse> => {
    name.validate();

    if (this.editNameStage.isLoading || name.isError) {
      return {
        isError: true
      };
    }

    this.editNameStage.loading();
    const data = this.toJson();
    data.name = name.value;

    const response = await this.rootStore.networkStore.api(
      apiUrls.AI_BOTS_UPDATE,
      {
        method: 'POST',
        data
      }
    );

    if (!response.isError) {
      this.params.value.name.changeValue(name.value);
      this.editNameStage.success();
    } else {
      this.editNameStage.error();
    }
    return {
      isError: response.isError
    };
  };

  toJson(): AIBotPayload & { _id: string } {
    return {
      _id: this.id.value,
      ...this.params.value.toJson()
    };
  }

  async uploadPdfDocs(files: File[]): Promise<void> {
    await Promise.all(
      files.map(async (file) => {
        const doc = AIAggregatedPdfDocModel.fromDefaultParams({
          file,
          rootStore: this.rootStore,
          botId: this.id.value
        });
        this.pdfDocs.addEntity({ key: doc.renderId, entity: doc, start: true });
        const response = await doc.upload(file);
        if (!response.isError) {
          return;
        }
      })
    );

    this.startUpdatingDocsStatus();
  }

  indexSite = async (url: string): Promise<BaseResponse> => {
    const domain = getDomainNameFromUrl(url) || url;

    const site = this.siteDocs.items.find((doc) => doc.id.value === domain);

    const model =
      site ||
      AIAggregatedSiteDocModel.fromDefaultParams({
        url,
        rootStore: this.rootStore,
        botId: this.id.value
      });
    // если сайт уже индексировали, он переместится наверх
    if (site) {
      this.siteDocs.removeEntity(model.renderId);
    }

    this.siteDocs.addEntity({
      key: model.renderId,
      entity: model,
      start: true
    });

    const response = await model.upload({
      url
    });

    if (response.isError) {
      return {
        isError: true
      };
    }

    this.startUpdatingDocsStatus();
    return {
      isError: false
    };
  };

  removeSiteDoc = async (renderId: string): Promise<BaseResponse> => {
    const doc = this.siteDocs.getEntity(renderId);

    if (!doc) {
      return {
        isError: true
      };
    }

    // документа не загрузился до конца
    if (doc.id.value === null) {
      this.siteDocs.removeEntity(renderId);

      return {
        isError: false
      };
    }

    const response = await doc.remove();

    if (!response.isError) {
      this.siteDocs.removeEntity(renderId);
    }

    return response;
  };

  removePdfDoc = async (renderId: string): Promise<BaseResponse> => {
    const doc = this.pdfDocs.getEntity(renderId);

    if (!doc) {
      return {
        isError: true
      };
    }

    // документа не загрузился до конца
    if (doc.id.value === null) {
      this.pdfDocs.removeEntity(renderId);

      return {
        isError: false
      };
    }

    const response = await doc.remove();

    if (!response.isError) {
      this.pdfDocs.removeEntity(renderId);
    }

    return response;
  };

  async getAggregatedDocs(rootStore: IRootStore): Promise<BaseResponse> {
    const response = await rootStore.networkStore.api<AIDocsServer>(
      apiUrls.AI_AGGREGATED_DOCS_LIST,
      {
        method: 'GET',
        data: {
          ai_bot_id: this.id.value
        }
      }
    );

    if (response.isError) {
      return { isError: true };
    }

    const pdfDocs: {
      keys: string[];
      entities: Map<string, AIAggregatedPdfDocModel>;
    } = {
      keys: [],
      entities: new Map()
    };
    const siteDocs: {
      keys: string[];
      entities: Map<string, AIAggregatedSiteDocModel>;
    } = {
      keys: [],
      entities: new Map()
    };

    response.data.docs.forEach((doc) => {
      if (doc.type === AIAggregatedDocType.pdf) {
        const model = AIAggregatedPdfDocModel.fromJson({
          raw: doc,
          botId: this.id.value,
          rootStore
        });
        pdfDocs.keys.push(model.renderId);
        pdfDocs.entities.set(model.renderId, model);
      } else {
        const model = AIAggregatedSiteDocModel.fromJson({
          raw: doc,
          botId: this.id.value,
          rootStore
        });
        siteDocs.keys.push(model.renderId);
        siteDocs.entities.set(model.renderId, model);
      }
    });
    this.pdfDocs.addEntities({
      entities: pdfDocs.entities,
      keys: pdfDocs.keys,
      initial: true
    });
    this.siteDocs.addEntities({
      entities: siteDocs.entities,
      keys: siteDocs.keys,
      initial: true
    });
    return {
      isError: false
    };
  }

  updateDocsStatus = async (): Promise<BaseResponse> => {
    const response = await this.rootStore.networkStore.api<AIDocsServer>(
      apiUrls.AI_AGGREGATED_DOCS_POLL,
      {
        method: 'POST',
        data: {
          ai_bot_id: this.id.value,
          agg_keys: this.idsInProgress
        }
      }
    );

    if (response.isError) {
      return {
        isError: true
      };
    }
    //для того, чтобы не обходить несколько раз кладем в Map
    const pdfDocsMap: Map<string, AIDocServer> = new Map();
    const siteDocsMap: Map<string, AIDocServer> = new Map();
    response.data.docs.forEach((rawDoc) => {
      if (rawDoc.type === AIAggregatedDocType.pdf) {
        pdfDocsMap.set(rawDoc.id, rawDoc);
      } else {
        siteDocsMap.set(rawDoc.id, rawDoc);
      }
    });

    if (pdfDocsMap.size) {
      this.pdfDocs.items.forEach((model) => {
        if (model.id.value === null) {
          return;
        }
        const doc = pdfDocsMap.get(model.id.value);
        if (!doc) {
          return;
        }
        model.errors.changeValue(doc.errors || null);
        model.progress.changeValue(doc.progress);
      });
    }
    if (siteDocsMap.size) {
      this.siteDocs.items.forEach((model) => {
        if (model.id.value === null) {
          return;
        }
        const doc = siteDocsMap.get(model.id.value);
        if (!doc) {
          return;
        }
        model.errors.changeValue(doc.errors || null);
        model.progress.changeValue(doc.progress);
      });
    }

    return { isError: false };
  };

  startUpdatingDocsStatus = async (): Promise<BaseResponse> => {
    return new Promise((resolve) => {
      this.clearTimer();
      if (this.idsInProgress.length === 0) {
        resolve({ isError: false });
      }
      const request = async () => {
        if (this.idsInProgress.length === 0) {
          this.clearTimer();
          resolve({ isError: false });
        } else {
          this.updateDocsStatus();
        }
      };
      this.timerId = setInterval(request, INTERVAL);
    });
  };

  clearTimer = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  };

  static async load(
    rootStore: IRootStore,
    botId: string
  ): Promise<BaseResponse<AIBotModel>> {
    const response = await rootStore.networkStore.api<{ ai_bot: AIBotServer }>(
      apiUrls.AI_BOTS_GET,
      {
        method: 'GET',
        data: {
          _id: botId
        },
        errorsMap: mapAIBotGetErrorToTitle,
        showExpectedError: false
      }
    );

    if (response.isError) {
      return {
        isError: true
      };
    }

    return {
      isError: false,
      data: AIBotModel.fromJson(response.data.ai_bot, rootStore)
    };
  }

  static fromJson(raw: AIBotServer, rootStore: IRootStore) {
    return new AIBotModel({
      params: AIEducationParamsModel.fromJson(raw),
      id: raw._id,
      scenarios: raw.scenarios ? raw.scenarios.map(normalizeScenarioData) : [],
      rootStore
    });
  }
}
