import * as React from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router';

import { AppNotificationOpenParams } from 'shared/entities/appNotifications';
import { noop } from 'shared/entities/common/utils';
import TextHighlighter from 'shared/components/TextHighlighter';
import { ComponentType } from 'shared/entities/components/Component';
import TransComp from 'shared/components/TransComp';
import { CloseIcon } from 'shared/newComponents/icons';
import NotificationCircle from 'shared/newComponents/NotificationCircle';
import Typography from 'shared/newComponents/Typography';
import {
  TypographyColor,
  TypographySize,
  TypographyType
} from 'shared/newEntities/components/Typography';
import { Button } from 'shared/newComponents/buttons';

import './BaseNotification.modules.scss';

type BaseNotificationProps = AppNotificationOpenParams & {
  onClose?: () => void;
};

const BaseNotification: React.FC<BaseNotificationProps> = ({
  type,
  onClose = noop,
  button,
  breakText = false,
  title,
  highlightedParts
}: BaseNotificationProps) => {
  const history = useHistory();

  const handleClickButton = (e) => {
    if (button?.type === ComponentType.button) {
      button.onClick(e);
    }

    if (button?.type === ComponentType.link) {
      history.push(button.to);
    }
    onClose();
  };

  return (
    <div styleName="base-notification">
      <NotificationCircle type={type} styleName="base-notification__circle" />
      <div
        styleName={cn(
          'base-notification__content',
          !button && 'base-notification__content-text'
        )}
      >
        <Typography
          fontType={TypographyType.text}
          fontSize={TypographySize.s}
          fontColor={TypographyColor.secondary}
          styleName={cn(
            'base-notification__title',
            breakText && 'base-notification__title_break'
          )}
        >
          <TransComp>
            {typeof title === 'string' ? (
              <TextHighlighter text={title} query={highlightedParts} />
            ) : (
              title
            )}
          </TransComp>
        </Typography>
        {button && (
          <Button
            {...button}
            styleName="base-notification__content-button"
            type={ComponentType.button}
            onClick={handleClickButton}
          />
        )}
      </div>
      <CloseIcon
        action={{
          type: ComponentType.svg,
          onClick: onClose
        }}
        styleName="base-notification__close"
      />
    </div>
  );
};

export default React.memo(BaseNotification);
