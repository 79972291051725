import { action, makeObservable, observable, runInAction } from 'mobx';

import {
  ConfirmedVerifiedDomainServer,
  IVerifiedUnconfirmedDomain,
  mapVerifiedDomainsErrorToMessage,
  normalizeDomainBase,
  normalizeVerifiedUnconfirmedDomainParams,
  UnconfirmedVerifiedDomainServer,
  VerifiedDomainsError,
  VerifiedTabs
} from 'shared/entities/verifiedDomains';
import { apiUrls } from 'shared/entities/domain';
import { LoadingStage } from 'shared/entities/meta';
import { IRootStore } from 'shared/entities/store/rootStore';
import { AppNotificationType } from 'shared/entities/appNotifications';

import VerifiedDomainBaseModel from './VerifiedDomainBaseModel';

export default class VerifiedUnconfirmedDomainModel
  extends VerifiedDomainBaseModel
  implements IVerifiedUnconfirmedDomain
{
  verifiedBy: null;
  verifiedAt: null;
  metaContent: string;
  dnsContent: string | null;
  file: {
    content: string;
    path: string;
    name: string;
  };

  activeTab: VerifiedTabs;

  checkStage: LoadingStage = LoadingStage.NOT_STARTED;

  constructor(
    {
      id,
      projectId,
      domain,
      dateCreated,
      verifiedBy,
      verifiedAt,
      metaContent,
      dnsContent,
      file
    }: IVerifiedUnconfirmedDomain,
    rootStore: IRootStore
  ) {
    super({ id, projectId, domain, dateCreated }, rootStore);

    this.verifiedBy = verifiedBy;
    this.verifiedAt = verifiedAt;
    this.metaContent = metaContent;
    this.dnsContent = dnsContent;
    this.file = file;

    this.activeTab = this.dnsContent ? VerifiedTabs.dns : VerifiedTabs.meta;

    makeObservable(this, {
      activeTab: observable,
      checkStage: observable,

      setActiveTab: action,
      check: action,
      create: action
    });
  }

  setActiveTab = (value: VerifiedTabs): void => {
    this.activeTab = value;
  };

  create = async (): Promise<void> => {
    this.toggleInfoIsOpen();
    this.setActiveTab(this.dnsContent ? VerifiedTabs.dns : VerifiedTabs.meta);
  };

  async check(): Promise<BaseResponse<ConfirmedVerifiedDomainServer, null>> {
    if (this.checkStage === LoadingStage.LOADING) {
      return {
        isError: true,
        data: null
      };
    }

    this.checkStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api<
      ConfirmedVerifiedDomainServer,
      VerifiedDomainsError
    >(apiUrls.VERIFIED_DOMAINS_CHECK, {
      method: 'POST',
      data: {
        _id: this.id,
        verified_by: this.activeTab
      },
      errorsMap: mapVerifiedDomainsErrorToMessage
    });

    if (!response.isError) {
      this.rootStore.appNotificationsStore.open({
        type: AppNotificationType.success,
        title: (t) =>
          t(
            'domain.VerifiedUnconfirmedDomainModel.notifications.domainSuccessfullyVerified',
            {
              ns: 'models'
            }
          )
      });

      runInAction(() => {
        this.checkStage = LoadingStage.SUCCESS;
      });
      return {
        isError: false,
        data: response.data
      };
    }

    runInAction(() => {
      this.checkStage = LoadingStage.ERROR;
    });

    return {
      isError: true,
      data: response.data?.code || null
    };
  }

  static fromJson(
    raw: UnconfirmedVerifiedDomainServer,
    rootStore: IRootStore
  ): VerifiedUnconfirmedDomainModel {
    return new VerifiedUnconfirmedDomainModel(
      {
        ...normalizeVerifiedUnconfirmedDomainParams(raw),
        ...normalizeDomainBase(raw)
      },
      rootStore
    );
  }
}
