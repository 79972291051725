import { ProjectServer } from './server';
import { IProject } from './client';

export const normalizeProject = (raw: ProjectServer): IProject => ({
  id: raw._id,
  name: raw.name,
  // TODO убрать когда добавят permissions при создании проекта
  permissions: raw.permissions || [],
  role: raw.role
});
