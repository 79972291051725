import * as React from 'react';

import { SelectorItem } from 'shared/entities/components/Selector';
import { IconSize } from 'shared/entities/components/Icon';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import { CloseIcon } from 'shared/newComponents/icons';
import { ComponentType } from 'shared/entities/components/Component';

import './SelectedOption.modules.scss';

type Props = SelectorItem & {
  onRemove: () => void;
  className?: string;
};

const SelectedOption: React.FC<Props> = ({
  Icon,
  title,
  onRemove,
  className
}) => {
  return (
    <div styleName="option" className={className}>
      <div styleName="option__left">
        {Icon && <Icon styleName="option__icon" iconSize={IconSize.S} />}
        <Typography
          fontType={TypographyType.text}
          fontSize={TypographySize.s}
          fontWeight={TypographyWeight.heavy}
          fontColor={TypographyColor.secondary}
          styleName="option__title"
        >
          {title}
        </Typography>
        <CloseIcon
          action={{
            type: ComponentType.svg,
            onClick: onRemove
          }}
          styleName="option__close-icon"
          iconSize={IconSize.S}
        />
      </div>
    </div>
  );
};

export default React.memo(SelectedOption);
