import { computed, makeObservable, reaction } from 'mobx';

import { ComponentLoadingStore } from 'stores/componentLoadingStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import ListModel from 'shared/models/ListModel';
import { LoadingStage } from 'shared/entities/meta';
import { apiUrls } from 'shared/entities/domain';
import {
  AmoCRMAccountModel,
  AmoCRMBaseAccountModel
} from 'shared/models/amoCRM';
import { validateIsEmpty, validateLatin } from 'shared/entities/validator';
import { FieldModel } from 'shared/models/form';
import { AmoCRMAccountServer } from 'shared/entities/amocrm';
import { LoadingStageModel } from 'shared/models/loadingStage';

export default class AmoCRMStore extends ComponentLoadingStore {
  private rootStore: IRootStore;
  readonly accounts: ListModel<AmoCRMAccountModel> =
    new ListModel<AmoCRMAccountModel>();
  readonly notConnectedAccounts: ListModel<AmoCRMBaseAccountModel> =
    new ListModel<AmoCRMBaseAccountModel>();
  readonly accountName: FieldModel = new FieldModel('', [
    validateIsEmpty,
    validateLatin
  ]);
  readonly token: FieldModel<string | null> = new FieldModel<string | null>(
    null
  );
  readonly tokenGettingStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingStage: LoadingStageModel = new LoadingStageModel();

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;

    this.removeNotConnectedAccount = this.removeNotConnectedAccount.bind(this);

    makeObservable(this, {
      disabledAdding: computed,
      itemsLength: computed
    });
  }

  initReactions(): void {
    super.initReactions();

    this.addReaction({
      key: 'added not connected account',
      reaction: reaction(
        () => this.notConnectedAccounts.length,
        (length, prevLength) => {
          if (prevLength > length) {
            return;
          }

          this.handleAddingNotConnectedAccount();
        }
      )
    });
  }

  get itemsLength(): number {
    return this.accounts.length + this.notConnectedAccounts.length;
  }

  get disabledAdding(): boolean {
    return !this.accountName.value.length || this.accountName.isError;
  }

  get loaded(): boolean {
    return this.accounts.loadingStage === LoadingStage.SUCCESS;
  }

  get canBeLoaded(): boolean {
    return this.rootStore.initialized;
  }

  getToken = async (): Promise<BaseResponse> => {
    if (this.tokenGettingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.tokenGettingStage.loading();

    const response = await this.rootStore.networkStore.api<{ token: string }>(
      apiUrls.AMOCRM_GET_TOKEN
    );

    if (response.isError) {
      this.tokenGettingStage.error();
    } else {
      this.tokenGettingStage.success();
      this.token.changeValue(response.data.token);
    }

    return {
      isError: response.isError
    };
  };

  addNotConnectedAccount = (): BaseResponse => {
    if (this.disabledAdding) {
      return {
        isError: true
      };
    }

    const account = AmoCRMBaseAccountModel.fromDefaultParams(
      this.accountName.value
    );

    this.notConnectedAccounts.addEntity({
      entity: account,
      key: account.id
    });

    this.accountName.changeValue('');
    this.accountName.resetError();

    return {
      isError: false
    };
  };

  async load(): Promise<BaseResponse> {
    if (this.accounts.loadingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this.accounts.setLoadingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      accounts: AmoCRMAccountServer[];
    }>(apiUrls.AMOCRM_ACCOUNTS_LIST);

    if (response.isError) {
      this.accounts.setLoadingStage(LoadingStage.ERROR);
    } else {
      const { keys, entities } = response.data.accounts.reduce<{
        entities: Record<string, AmoCRMAccountModel>;
        keys: string[];
      }>(
        (acc, account) => ({
          ...acc,
          entities: {
            ...acc.entities,
            [account._id]: AmoCRMAccountModel.fromJson(account, this.rootStore)
          },
          keys: [...acc.keys, account._id]
        }),
        { entities: {}, keys: [] }
      );
      this.accounts.addEntities({ keys, entities, initial: true });
      this.accounts.setLoadingStage(LoadingStage.SUCCESS);
    }

    return {
      isError: response.isError
    };
  }

  removeNotConnectedAccount(id: string): void {
    this.notConnectedAccounts.removeEntity(id);
  }

  reset(): void {
    this.accounts.reset();
    this.notConnectedAccounts.reset();
    this.accountName.changeValue('');
    this.accountName.resetError();
  }

  private handleAddingNotConnectedAccount() {
    if (this.token.value) {
      return;
    }

    this.getToken();
  }
}
