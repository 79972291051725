import * as React from 'react';

const useLocalStore = <T>(initialValueCreator: () => T): { store: T } => {
  const storeCtx = React.useRef<{ store: T }>({
    store: initialValueCreator()
  });

  return storeCtx.current;
};

export default useLocalStore;
