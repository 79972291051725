import { action, makeObservable, observable, runInAction } from 'mobx';

import {
  IProjectManager,
  ProjectManagerRoleType,
  ProjectManagerServer
} from 'shared/entities/manager';
import { apiUrls } from 'shared/entities/domain';
import { LoadingStage } from 'shared/entities/meta';
import { IBaseManagersStore } from 'shared/entities/store/managersStore';
import BaseManagerModel from 'shared/models/manager/BaseManagerModel';
import { IRootStore } from 'shared/entities/store/rootStore';

export default class ProjectManagerModel
  extends BaseManagerModel<ProjectManagerModel>
  implements IProjectManager
{
  projectRole: ProjectManagerRoleType | null;

  isRoleChangeable: boolean;

  constructor({
    projectRole,
    email,
    name,
    managersStore,
    isRoleChangeable,
    rootStore
  }: IProjectManager & {
    managersStore: IBaseManagersStore<ProjectManagerModel>;
    rootStore: IRootStore;
  }) {
    super({ name, managersStore, email, rootStore });
    this.projectRole = projectRole;
    this.isRoleChangeable = isRoleChangeable;

    makeObservable(this, {
      projectRole: observable,
      isRoleChangeable: observable,
      changeRole: action
    });
  }

  async changeRole({
    role
  }: {
    role: ProjectManagerRoleType;
  }): Promise<LoadingStage> {
    if (this.changingRoleStage === LoadingStage.LOADING) {
      return LoadingStage.ERROR;
    }

    this.changingRoleStage = LoadingStage.LOADING;

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.MANAGERS_CHANGE_PROJECT_ROLE,
      {
        method: 'POST',
        data: {
          email: this.email,
          project_role: role
        }
      }
    );

    runInAction(() => {
      if (!isError) {
        this.projectRole = role;
        this.changingRoleStage = LoadingStage.SUCCESS;
      } else {
        this.changingRoleStage = LoadingStage.ERROR;
      }
    });

    return this.changingRoleStage;
  }

  static fromJson(
    raw: ProjectManagerServer & {
      managersStore: IBaseManagersStore<ProjectManagerModel>;
      rootStore: IRootStore;
    }
  ): ProjectManagerModel {
    return new ProjectManagerModel({
      email: raw.email,
      name: raw.name,
      projectRole: raw.project_role,
      isRoleChangeable: raw.is_role_changeable,
      managersStore: raw.managersStore,
      rootStore: raw.rootStore
    });
  }
}
