type FoundSubStr = {
  // индекс, по которому была найдена subStr
  index: number;
  subStr: string;
};

/**
 * Находит подстроки и их индекс
 * @param str
 * @param regExpPattern
 * @returns {FoundSubStr[]}
 */
const findIndicesOfSubstring = (
  str: string,
  regExpPattern: string
): FoundSubStr[] => {
  const regExp = new RegExp(regExpPattern, 'g');

  let curResult: RegExpExecArray | null;

  const result: FoundSubStr[] = [];

  while ((curResult = regExp.exec(str)) !== null) {
    const subStr = curResult[0];

    if (subStr) {
      result.push({
        subStr,
        index: regExp.lastIndex - subStr.length
      });
    }
  }

  return result;
};

export default findIndicesOfSubstring;
