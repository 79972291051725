import { action, makeObservable, observable, runInAction } from 'mobx';
import * as React from 'react';

import {
  IProject,
  IProjectModel,
  normalizeProject,
  ProjectServer
} from 'shared/entities/projects';
import { Permission } from 'shared/entities/permissions';
import { IRootStore } from 'shared/entities/store/rootStore';
import { ProjectManagerRoleType } from 'shared/entities/manager';
import { LoadingStage } from 'shared/entities/meta';
import { apiUrls } from 'shared/entities/domain';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { validateNameField, ValidatorResult } from 'shared/entities/validator';
import TypedTransComponent from 'shared/components/TypedTransComponent';

export default class ProjectModel implements IProjectModel {
  readonly id: string;

  readonly permissions: Permission[];

  readonly role: ProjectManagerRoleType;

  readonly rootStore: IRootStore;

  private _name: string;

  private _editValue: string;

  private _updatingStage: LoadingStage = LoadingStage.NOT_STARTED;

  constructor({
    id,
    name,
    permissions,
    rootStore,
    role
  }: IProject & { rootStore: IRootStore }) {
    this.id = id;
    this.permissions = permissions;
    this.rootStore = rootStore;
    this.role = role;
    this._name = name;
    this._editValue = name;

    makeObservable<ProjectModel, '_updatingStage' | '_name' | '_editValue'>(
      this,
      {
        _name: observable,
        _editValue: observable,
        _updatingStage: observable,

        changeEditValue: action,
        update: action.bound
      }
    );
  }

  get name(): string {
    return this._name;
  }

  get editValue(): string {
    return this._editValue;
  }

  get updatingStage(): LoadingStage {
    return this._updatingStage;
  }

  changeEditValue = (value: string): void => {
    this._editValue = value;
  };

  validateEditValue = (): ValidatorResult => {
    return validateNameField(this._editValue);
  };

  async update(): Promise<void> {
    if (
      this._updatingStage === LoadingStage.LOADING ||
      this._name === this._editValue
    ) {
      return;
    }

    const error = this.validateEditValue();

    if (error) {
      const transError = (t) =>
        t('validators.errors.maxNameLengthField', {
          ns: 'entities'
        });

      this._editValue = this._name;

      if (error === transError) {
        this.rootStore.appNotificationsStore.open({
          title: error,
          type: AppNotificationType.error
        });
      }

      return;
    }

    this._updatingStage = LoadingStage.LOADING;

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.PROJECTS_UPDATE,
      {
        method: 'POST',
        data: {
          name: this._editValue
        }
      }
    );

    if (!isError) {
      runInAction(() => {
        this._updatingStage = LoadingStage.SUCCESS;
        this._name = this._editValue;
        this.rootStore.appNotificationsStore.open({
          title: (
            <TypedTransComponent
              ns="models"
              i18nKey="project.ProjectModel.notifications.nameUpdated"
            >
              Название проекта успешно изменено на <b>{{ name: this._name }}</b>
            </TypedTransComponent>
          ),
          type: AppNotificationType.success,
          breakText: true
        });
      });
    } else {
      runInAction(() => {
        this._updatingStage = LoadingStage.ERROR;
      });
    }
  }

  static fromJson(raw: ProjectServer, rootStore: IRootStore): ProjectModel {
    return new ProjectModel({ ...normalizeProject(raw), rootStore });
  }
}
