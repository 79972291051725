import * as React from 'react';
import { motion, useAnimate } from 'framer-motion';

import {
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import { IconSize } from 'shared/entities/components/Icon';
import { DropdownContentItem } from 'shared/newEntities/components/DropdownContent';
import Reference from 'shared/newComponents/Reference/Reference';
import Modal from 'shared/newComponents/modals/Modal';

import { ArrowIcon } from '../icons';
import Typography from '../Typography';

import './DropdownContent.modules.scss';

type Props = DropdownContentItem & {
  opened: boolean;
  onClick: (id: string | null) => void;
  titleLimited?: boolean;
  withModal?: boolean;
  className?: string;
};

const DropdownContent: React.FC<Props> = ({
  id,
  opened,
  onClick,
  title,
  reference,
  className,
  children,
  titleLimited = true,
  withModal
}) => {
  const [ref, animate] = useAnimate<HTMLDivElement>();
  const [showContent, setShowContent] = React.useState(false);

  const handleClick = React.useCallback(() => {
    onClick(opened ? null : id);
  }, [onClick, id, opened]);

  React.useEffect(() => {
    const toggle = async () => {
      if (opened) {
        setShowContent(true);
        animate(ref.current, {
          height: 'auto',
          opacity: 1,
          paddingTop: 12,
          flexGrow: 1,
          overflowY: 'hidden'
        });
      } else {
        await animate(ref.current, {
          height: 0,
          opacity: 0,
          paddingTop: 0,
          flexGrow: 'unset',
          overflowY: 'unset'
        });
        setShowContent(false);
      }
    };
    if (withModal) {
      return;
    }
    toggle();
  }, [opened, withModal]);

  return (
    <>
      {withModal && (
        <Modal
          styleName="modal"
          opened={opened}
          onClose={handleClick}
          title={title}
          titleFontSize={TypographySize.m}
        >
          {children}
        </Modal>
      )}
      <div styleName="container" className={className}>
        <div styleName="header" onClick={handleClick}>
          <div styleName="title__container">
            <Typography
              fontWeight={TypographyWeight.heavy}
              fontType={TypographyType.text}
              fontSize={TypographySize.m}
              fontColor={null}
              styleName={titleLimited ? 'title_limited' : ''}
            >
              {title}
            </Typography>
            {reference && <Reference styleName="reference" {...reference} />}
          </div>
          <ArrowIcon
            direction={opened ? 'top' : 'bottom'}
            iconSize={IconSize.L}
            styleName="icon"
          />
        </div>
        <motion.div
          initial={{
            height: 0
          }}
          ref={ref}
          styleName="content"
        >
          {showContent && children}
        </motion.div>
      </div>
    </>
  );
};

export default React.memo(DropdownContent);
