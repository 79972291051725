import { FieldModel } from 'shared/models/form';
import { validateIsEmpty } from 'shared/entities/validator';
import { generateId } from 'shared/entities/common/utils';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import { UserPaymentProdamusMethodServer } from 'shared/entities/userPayment';

export class NotConnectedProdamusCompany {
  readonly id: string;
  readonly secret: FieldModel = new FieldModel<string>('', [validateIsEmpty]);
  readonly title: string;
  readonly creatingStage: LoadingStageModel = new LoadingStageModel();
  readonly rootStore: IRootStore;
  readonly callbackUrl: FieldModel<string | null> = new FieldModel<
    string | null
  >(null);
  readonly gettingCallbackUrlStage: LoadingStageModel = new LoadingStageModel();

  constructor(
    { title, id }: { title: string; id: string },
    rootStore: IRootStore
  ) {
    this.title = title;
    this.id = id;
    this.rootStore = rootStore;
  }

  get companySettingsLink(): string {
    return `https://${this.title}.payform.ru/settings/`;
  }

  getCallbackUrl = async (): Promise<BaseResponse> => {
    if (this.gettingCallbackUrlStage.isLoading || this.callbackUrl.value) {
      return {
        isError: true
      };
    }

    this.gettingCallbackUrlStage.loading();

    const response = await this.rootStore.networkStore.api<{
      callback_url: string;
    }>(apiUrls.OAUTH_PRODAMUS_GET_CALLBACK_URL);

    if (response.isError) {
      this.gettingCallbackUrlStage.error();
    } else {
      this.callbackUrl.changeValue(response.data.callback_url);
      this.gettingCallbackUrlStage.success();
    }

    return response;
  };

  async create(): Promise<BaseResponse<UserPaymentProdamusMethodServer>> {
    if (this.creatingStage.isLoading || this.secret.isError) {
      return {
        isError: true
      };
    }

    this.creatingStage.loading();

    const response =
      await this.rootStore.networkStore.api<UserPaymentProdamusMethodServer>(
        apiUrls.OAUTH_PRODAMUS_OBTAIN_SECRET,
        {
          method: 'POST',
          data: {
            secret: this.secret.value,
            account_name: this.title
          }
        }
      );

    if (response.isError) {
      this.creatingStage.error();

      return {
        isError: true
      };
    } else {
      this.creatingStage.success();

      return {
        isError: false,
        data: response.data
      };
    }
  }

  static fromDefaultParams(
    title: string,
    rootStore: IRootStore
  ): NotConnectedProdamusCompany {
    return new NotConnectedProdamusCompany(
      { title, id: generateId() },
      rootStore
    );
  }
}
