import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStage } from 'shared/entities/meta';
import { apiUrls } from 'shared/entities/domain';
import ListModel from 'shared/models/ListModel';
import {
  CommonUserPaymentMethodModel,
  TelegramPaymentMethodModel,
  YooMoneyPaymentMethodModel
} from 'shared/models/userPayment';
import {
  UserPaymentMethodKind,
  UserPaymentMethodServer
} from 'shared/entities/userPayment';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';
import { IUserPaymentsStore } from 'shared/entities/store/userPaymentsStore';
import { TinkoffPaymentMethodModel } from 'shared/models/userPayment/TinkkoffPaymentMethodModel';

export default class UserPaymentsStore
  extends ComponentLoadingStore
  implements IUserPaymentsStore
{
  protected _rootStore: IRootStore;
  readonly methods: ListModel<
    | CommonUserPaymentMethodModel
    | YooMoneyPaymentMethodModel
    | TelegramPaymentMethodModel
    | TinkoffPaymentMethodModel
  > = new ListModel<
    | CommonUserPaymentMethodModel
    | YooMoneyPaymentMethodModel
    | TelegramPaymentMethodModel
    | TinkoffPaymentMethodModel
  >();

  constructor(rootStore: IRootStore) {
    super();

    this._rootStore = rootStore;

    this.removeMethod = this.removeMethod.bind(this);
  }

  get loaded(): boolean {
    return this.methods.loadingStage === LoadingStage.SUCCESS;
  }

  get canBeLoaded(): boolean {
    return this._rootStore.initialized;
  }

  async removeMethod(id: string): Promise<BaseResponse> {
    const method = this.methods.getEntity(id);

    if (!method) {
      return {
        isError: true
      };
    }

    const response = await method.remove();

    if (response.isError) {
      return {
        isError: true
      };
    }

    this.methods.removeEntity(id);

    return {
      isError: false
    };
  }

  async load(): Promise<BaseResponse> {
    if (this.methods.loadingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this.methods.setLoadingStage(LoadingStage.LOADING);

    const response = await this._rootStore.networkStore.api<{
      methods: UserPaymentMethodServer[];
    }>(apiUrls.USER_PAYMENTS_METHODS_LIST);

    if (response.isError) {
      this.methods.setLoadingStage(LoadingStage.ERROR);

      return {
        isError: true
      };
    } else {
      const collection = response.data.methods.reduce(
        (acc, method) => {
          let model:
            | YooMoneyPaymentMethodModel
            | CommonUserPaymentMethodModel
            | TelegramPaymentMethodModel
            | TinkoffPaymentMethodModel
            | null;

          switch (method.provider_kind) {
            case UserPaymentMethodKind.yooMoney: {
              model = YooMoneyPaymentMethodModel.fromJson(
                method,
                this._rootStore
              );
              break;
            }
            case UserPaymentMethodKind.tg: {
              model = TelegramPaymentMethodModel.fromJson(
                method,
                this._rootStore
              );
              break;
            }
            case UserPaymentMethodKind.tinkoff: {
              model = TinkoffPaymentMethodModel.fromJson(
                method,
                this._rootStore
              );
              break;
            }
            default: {
              model = CommonUserPaymentMethodModel.fromJson(
                method,
                this._rootStore
              );
            }
          }

          if (!model) {
            return acc;
          }

          return {
            ...acc,
            entities: {
              ...acc.entities,
              [method._id]: model
            },
            keys: [...acc.keys, method._id]
          };
        },
        {
          entities: {},
          keys: []
        }
      );
      this.methods.addEntities({ ...collection, initial: true });
      this.methods.setLoadingStage(LoadingStage.SUCCESS);

      return {
        isError: false
      };
    }
  }

  reset(): void {
    this.methods.reset();
  }
}
