import { observer } from 'mobx-react';
import * as React from 'react';

import { UnlinkIcon } from 'shared/newComponents/icons';
import { SelectorBaseOption } from 'shared/newComponents/SelectorBase';
import { useVKCreationStore } from 'stores/VKCreationStore';

type Props = {
  onClose: () => void;
};

const Content: React.FC<Props> = ({ onClose }) => {
  const vkCreationStore = useVKCreationStore();

  const handleUnlink = React.useCallback(async () => {
    const response = await vkCreationStore.disconnectAccount();

    if (!response.isError) {
      onClose();
    }
  }, []);

  return (
    <>
      <SelectorBaseOption
        onClick={handleUnlink}
        Icon={UnlinkIcon}
        title={(t) =>
          t('ChannelsModal.ChannelForm.VK.VKAccount.unlink', {
            ns: 'newContainers'
          })
        }
      />
    </>
  );
};

export default observer(Content);
