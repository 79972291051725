import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import { IPaymentMethod, PaymentMethodServer } from 'shared/entities/payment';
import { LoadingStage } from 'shared/entities/meta';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';

export default class PaymentMethodModel implements IPaymentMethod {
  readonly id: string;
  readonly title: string;
  readonly type: string;
  readonly dateCreated: Date;

  private _removingStage: LoadingStage = LoadingStage.NOT_STARTED;
  private _rootStore: IRootStore;

  constructor({
    id,
    title,
    type,
    dateCreated,
    rootStore
  }: IPaymentMethod & { rootStore: IRootStore }) {
    this.id = id;
    this.type = type;
    this.title = title;
    this.dateCreated = dateCreated;

    this._rootStore = rootStore;

    makeObservable<PaymentMethodModel, '_removingStage'>(this, {
      _removingStage: observable,

      removingStage: computed,

      remove: action
    });
  }

  get removingStage(): LoadingStage {
    return this._removingStage;
  }

  remove = async (): Promise<BaseResponse> => {
    if (this._removingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this._removingStage = LoadingStage.LOADING;

    const response = await this._rootStore.networkStore.api(
      apiUrls.PAYMENTS_REMOVE_METHODS,
      {
        method: 'POST',
        data: {
          _id: this.id
        }
      }
    );

    runInAction(() => {
      this._removingStage = response.isError
        ? LoadingStage.ERROR
        : LoadingStage.SUCCESS;
    });

    return {
      isError: response.isError
    };
  };

  static fromJson(
    raw: PaymentMethodServer,
    rootStore: IRootStore
  ): PaymentMethodModel {
    return new PaymentMethodModel({
      id: raw._id,
      type: raw.type,
      title: raw.title,
      dateCreated: new Date(raw.date_created),
      rootStore
    });
  }
}
