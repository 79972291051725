import {
  PaymentDocumentKind,
  PaymentDocumentSubject,
  SuppAgreementDocumentServer,
  normalizeCommonDocument
} from 'shared/entities/documents';

import { BaseDocumentModel } from './BaseDocumentModel';

export class SupAgreementDocumentModel extends BaseDocumentModel {
  readonly kind: PaymentDocumentKind.suppAgreement =
    PaymentDocumentKind.suppAgreement;
  readonly subject: PaymentDocumentSubject.setup = PaymentDocumentSubject.setup;

  static fromJson(
    raw: SuppAgreementDocumentServer,
    cabinetId: string
  ): SupAgreementDocumentModel {
    return new SupAgreementDocumentModel({
      ...normalizeCommonDocument(raw),
      cabinetId
    });
  }
}
