import * as React from 'react';
import cn from 'classnames';

import { LoadingSpinnerColor, LoadingSpinnerSize } from './config';
import './LoaderSpinner.modules.scss';

type Props = {
  centered?: boolean;
  size?: LoadingSpinnerSize;
  color?: LoadingSpinnerColor;
  className?: string;
};

const LoaderSpinner: React.FC<Props> = ({
  centered = false,
  size = LoadingSpinnerSize.s,
  color = LoadingSpinnerColor.blue,
  className
}: Props) => {
  return (
    <div
      styleName={cn(
        'loader-spinner-wrapper',
        centered && 'loader-spinner-wrapper_centered'
      )}
      className={className}
    >
      <svg
        styleName={cn(
          'loader-spinner',
          `loader-spinner_${size}`,
          `loader-spinner_${color}`
        )}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10.0029" r="8" stroke="#E4E8EB" strokeWidth="2" />
        <path
          d="M14 3.07324C16.3912 4.45648 18 7.04186 18 10.003C18 14.4213 14.4183 18.003 10 18.003C8.9391 18.003 7.92643 17.7965 7 17.4215"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default React.memo(LoaderSpinner);
