import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const InfinityIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M5.636 16C2.91 16 2 14 2 12C2 10 2.91 8 5.636 8C9.273 8 14.727 16 18.364 16C21.09 16 22 14 22 12C22 10 21.09 8 18.364 8C14.727 8 9.273 16 5.636 16V16Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default InfinityIcon;
