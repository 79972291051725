import * as React from 'react';
import { observer } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import PubSub from 'pubsub-js';

import { AppNotificationEvents } from 'stores/appNotificationsStore';
import { AppNotificationOpenParams } from 'shared/entities/appNotifications';
import Portal from 'shared/components/Portal';
import { PORTAL_IDS } from 'shared/entities/app';
import { useAppNotificationsStore } from 'stores/index';
import BaseNotification from 'shared/newComponents/BaseNotification';

import 'react-toastify/dist/ReactToastify.css';

const AUTO_CLOSE_TIMEOUT = 5000;
const MAX_NOTIFICATIONS_COUNT = 4;

const AppNotifications: React.FC = () => {
  const appNotificationsStore = useAppNotificationsStore();

  const open = React.useCallback((_, params: AppNotificationOpenParams) => {
    toast(
      ({ closeToast }) => <BaseNotification {...params} onClose={closeToast} />,
      {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose:
          typeof params.timeout === 'number'
            ? params.timeout
            : AUTO_CLOSE_TIMEOUT,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        closeButton: false,
        delay: 0
      }
    );
  }, []);

  const openForbiddenError = React.useCallback(
    (_, params: AppNotificationOpenParams) => {
      toast(
        ({ closeToast }) => (
          <BaseNotification {...params} onClose={closeToast} />
        ),
        {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose:
            typeof params.timeout === 'number'
              ? params.timeout
              : AUTO_CLOSE_TIMEOUT,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          closeButton: false,
          delay: 0,
          onClose: appNotificationsStore.setForbiddenErrorClosed
        }
      );
    },
    []
  );

  React.useEffect(() => {
    const openToken = PubSub.subscribe(AppNotificationEvents.open, open);
    const openForbiddenErrorToken = PubSub.subscribe(
      AppNotificationEvents.openForbiddenError,
      openForbiddenError
    );
    appNotificationsStore.setInitialized(true);

    return () => {
      appNotificationsStore.setInitialized(false);
      appNotificationsStore.setForbiddenErrorClosed();
      PubSub.unsubscribe(openToken);
      PubSub.unsubscribe(openForbiddenErrorToken);
    };
  }, []);

  return (
    <Portal portalId={PORTAL_IDS.MODALS}>
      <ToastContainer limit={MAX_NOTIFICATIONS_COUNT} />
    </Portal>
  );
};

export default observer(AppNotifications);
