import * as React from 'react';
import { observer } from 'mobx-react';
import { motion } from 'framer-motion';

import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { MobileChannelsModalTab } from 'shared/entities/channels';

import SelectedChannel from '../SelectedChannel';
import CreationContainer from '../CreationContainer';

import ChannelsList from './ChannelsList';

import './MobileContainer.modules.scss';

type Props = {
  className?: string;
};

const MobileContainer: React.FC<Props> = ({ className }) => {
  const { store } = useChannelsModalStore();

  const isVisible =
    store.channelsModalSelectedMobileTab.value ===
    MobileChannelsModalTab.createChannel;

  return (
    <div styleName="mobile-container" className={className}>
      <motion.div
        animate={isVisible ? 'visible' : 'hidden'}
        transition={{
          stiffness: 0
        }}
        variants={{
          visible: { opacity: 1, x: 0 },
          hidden: { opacity: 0, x: '-100%' }
        }}
        styleName="mobile-container__left"
      >
        <CreationContainer />
      </motion.div>
      <motion.div
        animate={!isVisible ? 'visible' : 'hidden'}
        transition={{
          stiffness: 0
        }}
        variants={{
          visible: { opacity: 1, x: '-100%' },
          hidden: { opacity: 0, x: 0 }
        }}
        styleName="mobile-container__right"
      >
        <ChannelsList />
        <SelectedChannel />
      </motion.div>
    </div>
  );
};

export default observer(MobileContainer);
