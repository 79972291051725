import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const dateToISOWithTimezone = (date: Date, tz: string): string => {
  return dayjs(date).tz(tz, true).toISOString();
};

export { dateToISOWithTimezone };
