import * as React from 'react';
import * as Sentry from '@sentry/browser';

import NetworkErrorMessage from 'shared/newComponents/NetworkErrorMessage';
import { INetworkStore } from 'shared/entities/store/networkStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import {
  api,
  ApiCallErrorRaw,
  ApiCallResponse,
  ApiParams,
  ApiParamsShopback,
  BaseResponseCode,
  ResponseCodes
} from 'shared/entities/network';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { createShopbackData } from 'shared/entities/network/utils/helpers';
import {
  BadRequestError,
  HttpError,
  NotFoundError
} from 'shared/models/errors';
import ForbiddenErrorMessage from 'shared/newComponents/ForbiddenErrorMessage';

export class NetworkStore implements INetworkStore {
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  apiShopback = async <
    ResponseData extends Record<string, any> = {},
    ErrorCode extends string = string,
    ErrorData extends Record<string, any> = {}
  >(
    endpoint: string,
    {
      method,
      data,
      config,
      multipartFormData,
      errorsMap,
      showExpectedError = true,
      showUnexpectedError = true
    }: ApiParamsShopback<ErrorCode> = {
      method: 'GET',
      showExpectedError: true,
      showUnexpectedError: true
    }
  ): Promise<ApiCallResponse<ResponseData, ErrorCode, ErrorData>> => {
    const shopbackData = createShopbackData(this.rootStore, data);

    const result = await this.api<ResponseData, ErrorCode, ErrorData>(
      endpoint,
      {
        method,
        data: shopbackData,
        config,
        multipartFormData,
        errorsMap,
        showExpectedError,
        showUnexpectedError,
        showAuthModal: false
      }
    );

    if (!result.isError) {
      return result;
    }

    if (result.data && result.data.status === ResponseCodes.UNAUTHORIZED) {
      const response = await this.rootStore.shopbackStore.reload();

      if (response.isError) {
        return result;
      }

      const shopbackData = createShopbackData(this.rootStore, data);

      return this.api<ResponseData, ErrorCode, ErrorData>(endpoint, {
        method,
        data: shopbackData,
        config,
        multipartFormData,
        errorsMap,
        showExpectedError,
        showUnexpectedError,
        showAuthModal: false
      });
    }

    return result;
  };

  api = async <
    ResponseData extends Record<string, any> = {},
    ErrorCode extends string = string,
    ErrorData extends Record<string, any> = {}
  >(
    endpoint: string,
    {
      method,
      data,
      config,
      multipartFormData,
      errorsMap,
      showExpectedError = true,
      showUnexpectedError = true,
      showAuthModal = true,
      expectedErrorCodes
    }: ApiParams<ErrorCode> = {
      method: 'GET',
      showExpectedError: true,
      showUnexpectedError: true
    }
  ): Promise<ApiCallResponse<ResponseData, ErrorCode, ErrorData>> => {
    const result = await api<ResponseData, ErrorCode, ErrorData>(
      endpoint,
      method,
      this.rootStore.createData(data),
      config,
      multipartFormData
    );

    if (result.isError && !result.isCancelled) {
      const sendToSentry = () => {
        if (!result.data) {
          return;
        }
        Sentry.withScope((scope) => {
          const requestData = this.rootStore.createData(data);

          scope.setExtras({
            status: result.data?.status,
            code: result.data?.code,
            url: endpoint,
            requestData: JSON.stringify(requestData),
            responseData: JSON.stringify(result.data?.data)
          });
          if (
            result.data?.status === ResponseCodes.NOT_FOUND ||
            result.data?.code === BaseResponseCode.notFound
          ) {
            Sentry.captureException(new NotFoundError(endpoint));
            return;
          }
          if (
            result.data?.status === ResponseCodes.BAD_REQUEST ||
            result.data?.code === BaseResponseCode.badRequest
          ) {
            Sentry.captureException(new BadRequestError(endpoint));
            return;
          }
          Sentry.captureException(new HttpError(endpoint));
        });
      };

      if (result.data) {
        // сессия истекла
        if (result.data.status === ResponseCodes.UNAUTHORIZED) {
          if (this.rootStore.userStore.isAuthorized && showAuthModal) {
            this.rootStore.userStore.resetAuth();
            this.rootStore.uiStore.openAuthorizedModal();
          }

          return result;
        }

        if (!expectedErrorCodes?.includes(result.data.code)) {
          const isErrorExpected =
            errorsMap &&
            !!errorsMap(result.data.code)(this.rootStore.translationsStore.t);

          if (isErrorExpected) {
            if (showExpectedError) {
              const errorStr = errorsMap(result.data.code);
              this.rootStore.appNotificationsStore.open({
                type: AppNotificationType.error,
                title: errorStr
              });
            }
          } else {
            sendToSentry();

            if (showUnexpectedError) {
              this.showUnexpectedError(result.data, endpoint);
            }
          }
        }
      } else {
        if (showUnexpectedError) {
          this.showUnexpectedError(result.data, endpoint);
        }
      }
    }

    return result;
  };

  showUnexpectedError = (
    error:
      | ({
          status: number;
        } & Omit<ApiCallErrorRaw<any, any>, 'status'>)
      | null,
    url: string
  ): void => {
    if (!error) {
      this.rootStore.appNotificationsStore.open({
        type: AppNotificationType.error,
        title: (t) =>
          t('NetworkStore.notifications.unknown', {
            ns: 'stores'
          }),
        timeout: 10000
      });

      return;
    }

    if (error.code === BaseResponseCode.forbidden || error.status === 403) {
      this.rootStore.appNotificationsStore.openForbiddenError({
        type: AppNotificationType.error,
        title: <ForbiddenErrorMessage />,
        timeout: 10000
      });
    } else {
      this.rootStore.appNotificationsStore.open({
        type: AppNotificationType.error,
        title: <NetworkErrorMessage url={url} error={error} />,
        timeout: 10000
      });
    }
  };
}
