import { TranslationString } from 'shared/entities/localization';

import {
  ProjectSettingsTypes,
  ProjectEnableForSetting
} from '../projectSettingsTypes';
import { SelectorEntities } from '../components/Selector';
export interface IModeProject {
  enabled: boolean;
  in: ProjectSettingsTypes;
}

export interface IEnabledProjectSetting {
  enabled: boolean;
  for: ProjectEnableForSetting;
}

export enum UnstopDelay {
  beforeResponse = -1,
  min5 = 5,
  min10 = 10,
  min15 = 15,
  min30 = 30,
  min45 = 45,
  hour1 = 60,
  hour3 = 180,
  hour24 = 1440
}

export const unstopDelaySelectorEntities: SelectorEntities<
  UnstopDelay,
  TranslationString
> = {
  [UnstopDelay.min5]: {
    id: UnstopDelay.min5,
    title: (t) => t('projectSettings.5min', { ns: 'entities' })
  },
  [UnstopDelay.min10]: {
    id: UnstopDelay.min10,
    title: (t) => t('projectSettings.10min', { ns: 'entities' })
  },
  [UnstopDelay.min15]: {
    id: UnstopDelay.min15,
    title: (t) => t('projectSettings.15min', { ns: 'entities' })
  },
  [UnstopDelay.min30]: {
    id: UnstopDelay.min30,
    title: (t) => t('projectSettings.30min', { ns: 'entities' })
  },
  [UnstopDelay.min45]: {
    id: UnstopDelay.min45,
    title: (t) => t('projectSettings.45min', { ns: 'entities' })
  },
  [UnstopDelay.hour1]: {
    id: UnstopDelay.hour1,
    title: (t) => t('projectSettings.1hour', { ns: 'entities' })
  },
  [UnstopDelay.hour3]: {
    id: UnstopDelay.hour3,
    title: (t) => t('projectSettings.3hour', { ns: 'entities' })
  },
  [UnstopDelay.hour24]: {
    id: UnstopDelay.hour24,
    title: (t) => t('projectSettings.24hour', { ns: 'entities' })
  },
  [UnstopDelay.beforeResponse]: {
    id: UnstopDelay.beforeResponse,
    title: (t) => t('projectSettings.beforeResponse', { ns: 'entities' })
  }
};

export const orderUnstopDelaySelectorOptions = [
  UnstopDelay.min5,
  UnstopDelay.min10,
  UnstopDelay.min15,
  UnstopDelay.min30,
  UnstopDelay.min45,
  UnstopDelay.hour1,
  UnstopDelay.hour3,
  UnstopDelay.hour24
];

export const botOrderUnstopDelaySelectorOptions = [
  UnstopDelay.beforeResponse,
  ...orderUnstopDelaySelectorOptions
];
