import * as React from 'react';
import { observer } from 'mobx-react';

import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { ChannelKind } from 'shared/entities/channels';
import { Input } from 'shared/newComponents/form';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import {
  TypographyColor,
  TypographySize,
  TypographyType
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import ExternalLink from 'shared/newComponents/ExternalLink';
import { Button } from 'shared/newComponents/buttons';
import { ComponentType } from 'shared/entities/components/Component';
import { BOT_FATHER_LINK } from 'shared/entities/app';
import { ButtonSize } from 'shared/newEntities/components/Button';

import './TokenCreation.modules.scss';

type Props = {
  className?: string;
};

const TokenCreation: React.FC<Props> = ({ className }) => {
  const { store } = useChannelsModalStore();

  const channel = store.selectedChannel;

  if (
    !channel ||
    // показываем создание токена:
    // 1) если это VK, то всегда
    // 2) если это другие каналы, то в случае если токена нет
    (channel.kind !== ChannelKind.VK && channel.tokens.length > 0)
  ) {
    return null;
  }

  return (
    <div className={className}>
      <Input
        value={channel.newToken.value}
        onChange={channel.newToken.changeValue}
        placeholder="986.********.ku8"
        onBlur={channel.newToken.validate}
        error={channel.newToken.error}
        label={(t) =>
          t(
            'ChannelsModal.SelectedChannel.Content.Tokens.creation.inputLabel',
            {
              ns: 'newContainers'
            }
          )
        }
      />
      {channel.kind === ChannelKind.VK && (
        <Typography
          styleName="token-creation__caption"
          fontType={TypographyType.text}
          fontSize={TypographySize.s}
          fontColor={TypographyColor.tertiary}
        >
          <TypedTransComponent
            ns="newContainers"
            i18nKey="ChannelsModal.SelectedChannel.Content.Tokens.creation.vk"
          >
            Чтобы добавить токен, перейдите в{' '}
            <ExternalLink
              fontType={TypographyType.text}
              fontSize={TypographySize.s}
              fontColor={TypographyColor.link}
              href={`https://vk.com/club${channel.externalId}?act=tokens`}
              underline
            >
              настройки группы
            </ExternalLink>{' '}
            и нажмите «Создать ключ».
          </TypedTransComponent>
        </Typography>
      )}
      {channel.kind === ChannelKind.TELEGRAM && (
        <Typography
          styleName="token-creation__caption"
          fontType={TypographyType.text}
          fontSize={TypographySize.s}
          fontColor={TypographyColor.tertiary}
        >
          <TypedTransComponent
            ns="newContainers"
            i18nKey="ChannelsModal.SelectedChannel.Content.Tokens.creation.tg"
          >
            Чтобы добавить токен, перейдите в{' '}
            <ExternalLink
              fontType={TypographyType.text}
              fontSize={TypographySize.s}
              fontColor={TypographyColor.link}
              href={BOT_FATHER_LINK}
              underline
            >
              BotFather
            </ExternalLink>{' '}
            и нажмите «API Token».
          </TypedTransComponent>
        </Typography>
      )}
      <Button
        styleName="token-creation__button"
        loadingStage={channel.creatingNewTokenStage.value}
        type={ComponentType.button}
        onClick={channel.createToken}
        size={ButtonSize.lg}
        fullWidth
        disabled={channel.newToken.isError}
      >
        {(t) =>
          t('ChannelsModal.SelectedChannel.Content.Tokens.creation.button', {
            ns: 'newContainers'
          })
        }
      </Button>
    </div>
  );
};

export default observer(TokenCreation);
