import * as React from 'react';

import { ChannelKind } from 'shared/entities/channels';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import { documentationLinks, wazzupLinks } from 'shared/entities/app';
import ExternalLink from 'shared/newComponents/ExternalLink';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';

import './ChannelReferenceDescription.modules.scss';

type Props = {
  channelKind:
    | ChannelKind.WABA
    | ChannelKind.WHATSAPP
    | ChannelKind.TELEGRAM_PERSONAL;
};

const ChannelReferenceDescription: React.FC<Props> = ({
  channelKind
}: Props) => {
  const linkFontProps = React.useMemo(() => {
    return {
      fontType: TypographyType.text,
      fontSize: TypographySize.xs,
      fontColor: TypographyColor.link,
      fontWeight: TypographyWeight.normal
    };
  }, []);

  switch (channelKind) {
    case ChannelKind.WABA:
      return (
        <TypedTransComponent
          i18nKey={
            'ChannelsModal.Creation.connectViaChannel.channel.wz.referenceDescription.waba'
          }
          ns={'newContainers'}
        >
          <ExternalLink {...linkFontProps} href={wazzupLinks.whatsappWabaDiff}>
            Чем отличается WABA и&nbsp;WhatsApp&nbsp;*
          </ExternalLink>
          <br />
          *&nbsp;Smartbot пока не&nbsp;поддерживает работу с&nbsp;шаблонами
          и&nbsp;рассылками.
        </TypedTransComponent>
      );
    case ChannelKind.WHATSAPP:
      return (
        <ExternalLink {...linkFontProps} href={wazzupLinks.whatsappWabaDiff}>
          {(t) =>
            t(
              'ChannelsModal.Creation.connectViaChannel.channel.wz.referenceDescription.whatsapp',
              { ns: 'newContainers' }
            )
          }
        </ExternalLink>
      );
    case ChannelKind.TELEGRAM_PERSONAL:
      return (
        <TypedTransComponent
          i18nKey={
            'ChannelsModal.Creation.connectViaChannel.channel.wz.referenceDescription.tgPersonal'
          }
          ns={'newContainers'}
        >
          Подключите к аккаунту Telegram Business и настройте бота для личного
          аккаунта.
          <br />
          <ExternalLink
            {...linkFontProps}
            href={documentationLinks.addingTgBusiness}
          >
            Как работает Telegram Business
          </ExternalLink>
          <br />
        </TypedTransComponent>
      );
  }
};

export default React.memo(ChannelReferenceDescription);
