import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const CircledCheckIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      svgProps={{ viewBox: '0 0 20 20' }}
      iconSize={IconSize.XM}
      {...props}
    >
      <path
        d="M5.75008 10L8.25008 12.5L13.2501 7.50002M17.8334 10C17.8334 14.6024 14.1025 18.3334 9.50008 18.3334C4.89771 18.3334 1.16675 14.6024 1.16675 10C1.16675 5.39765 4.89771 1.66669 9.50008 1.66669C14.1025 1.66669 17.8334 5.39765 17.8334 10Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default CircledCheckIcon;
