import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const UpdateIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.S}
      svgProps={{ viewBox: '0 0 16 16' }}
      {...props}
    >
      <path
        d="M2.99969 4.75784C3.89413 3.41314 4.77855 2.61571 6.31865 2.21067C7.85876 1.80563 9.49041 1.98852 10.9082 2.72511C12.3259 3.4617 13.4326 4.70149 14.0211 6.2124M14.4297 2.03318V6.2124L10.3433 6.2124"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.43 11.4541C12.5356 12.7988 11.6511 13.5962 10.111 14.0012C8.57093 14.4063 6.93928 14.2234 5.52151 13.4868C4.10375 12.7502 2.99707 11.5104 2.40863 9.99951M2 14.1787V9.99951H6.08635"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default UpdateIcon;
