import {
  IUserAttachmentVar,
  UserAttachmentVarServer
} from 'shared/entities/vars';
import { IFileBase, normalizeFileBase } from 'shared/entities/file';
import { FieldModel } from 'shared/models/form';

export default class UserAttachmentVarModel implements IUserAttachmentVar {
  readonly key: string;
  readonly value: FieldModel<IFileBase | null>;

  constructor({ key, value }: { key: string; value: IFileBase | null }) {
    this.key = key;
    this.value = new FieldModel<IFileBase | null>(value);
  }

  static fromJson(raw: UserAttachmentVarServer): UserAttachmentVarModel {
    return new UserAttachmentVarModel({
      key: raw.key,
      value: raw.value ? normalizeFileBase(raw.value) : null
    });
  }
}
