import { computed, makeObservable } from 'mobx';

import { IRootStore } from 'shared/entities/store/rootStore';
import { Permission } from 'shared/entities/permissions';
import { IProjectModel } from 'shared/entities/projects';
import { ICabinetModel } from 'shared/entities/cabinet';

export default class PermissionsStore {
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      cabinet: computed,
      canEditManagers: computed,
      canViewBilling: computed,
      canViewManagers: computed,
      canEditProject: computed,
      canViewProject: computed,
      canCreateProject: computed,
      canPublishScenarios: computed,
      canViewProjectManagers: computed,
      canCreateCabinet: computed,
      canRemoveProject: computed,
      canRemoveCabinet: computed,
      canEditCabinet: computed,
      shouldPay: computed,
      canViewUnpublishedFeatures: computed,
      canManageTemplate: computed,
      isELamaUser: computed,
      canViewBalancePage: computed
    });
  }

  get cabinet(): ICabinetModel | null {
    return this.rootStore.cabinetStore.entity;
  }

  get project(): IProjectModel | null {
    return this.rootStore.projectStore.entity;
  }

  get canEditManagers(): boolean {
    return this.cabinet
      ? this.cabinet.permissions.includes(Permission.EDIT_MANAGERS)
      : false;
  }

  get canViewBilling(): boolean {
    return this.cabinet
      ? this.cabinet.permissions.includes(Permission.ACCESS_BILLING)
      : false;
  }

  get canViewBalancePage(): boolean {
    if (!this.cabinet) {
      return false;
    }
    return this.canViewBilling && !this.isELamaUser;
  }

  get canViewManagers(): boolean {
    return this.cabinet
      ? this.cabinet.permissions.includes(Permission.VIEW_MANAGERS)
      : false;
  }

  get canViewProjectManagers(): boolean {
    return this.project
      ? this.project.permissions.includes(Permission.VIEW_MANAGERS)
      : false;
  }

  get canEditProject(): boolean {
    return this.project
      ? this.project.permissions.includes(Permission.EDIT_PROJECT)
      : false;
  }

  get canViewProject(): boolean {
    return this.project
      ? this.project.permissions.includes(Permission.VIEW_PROJECT)
      : false;
  }

  get canPublishScenarios(): boolean {
    if (
      this.rootStore.pluginsStore.notConsideredRoles.includes(
        Permission.PUBLISH_SCENARIOS
      )
    ) {
      return this.canEditProject;
    }

    return this.project
      ? this.project.permissions.includes(Permission.PUBLISH_SCENARIOS)
      : false;
  }

  get canCreateProject(): boolean {
    return this.cabinet
      ? this.cabinet.permissions.includes(Permission.CREATE_PROJECT)
      : false;
  }

  get canCreateCabinet(): boolean {
    if (
      this.rootStore.pluginsStore.notConsideredRoles.includes(
        Permission.CREATE_CABINET
      )
    ) {
      return true;
    }

    return this.cabinet
      ? this.cabinet.permissions.includes(Permission.CREATE_CABINET)
      : false;
  }

  get canRemoveProject(): boolean {
    return this.cabinet
      ? this.cabinet.permissions.includes(Permission.DELETE_PROJECT)
      : false;
  }

  get canRemoveCabinet(): boolean {
    return this.cabinet
      ? this.cabinet.permissions.includes(Permission.DELETE_CABINET)
      : false;
  }

  get canEditCabinet(): boolean {
    return this.cabinet
      ? this.cabinet.permissions.includes(Permission.EDIT_CABINET)
      : false;
  }

  get canViewUnpublishedFeatures(): boolean {
    return this.cabinet
      ? this.cabinet.permissions.includes(
          Permission.SA_VIEW_UNPUBLISHED_FEATURES
        )
      : false;
  }

  get canManageTemplate(): boolean {
    return this.cabinet
      ? this.cabinet.permissions.includes(Permission.SA_MANAGE_TEMPLATES)
      : false;
  }

  get shouldPay(): boolean {
    return !!this.rootStore.cabinetStore.entity?.shouldPay;
  }

  get isELamaUser(): boolean {
    return !!this.rootStore.userStore.userExtra?.registeredViaElama;
  }
}
