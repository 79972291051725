import { makeObservable, observable } from 'mobx';

import {
  CabinetManagerRoleType,
  IProjectManagerAddingEdit,
  ProjectManagerRoleType
} from 'shared/entities/manager';
import { IEdit } from 'shared/entities/edit';

export default class ProjectManagerAddingEditModel
  implements IProjectManagerAddingEdit, IEdit
{
  email: string;
  role: ProjectManagerRoleType;
  cabinetRole: CabinetManagerRoleType;
  projectId: string;

  constructor({
    email,
    role,
    projectId,
    cabinetRole
  }: {
    email: string;
    role: ProjectManagerRoleType;
    cabinetRole: CabinetManagerRoleType;
    projectId: string;
  }) {
    this.email = email;
    this.role = role;
    this.projectId = projectId;
    this.cabinetRole = cabinetRole;

    makeObservable(this, {
      email: observable,
      role: observable
    });
  }

  toJson(): {
    cabinet_role: CabinetManagerRoleType;
    projects: { _id: string; role: ProjectManagerRoleType }[];
    email: string;
  } {
    return {
      email: this.email,
      cabinet_role: this.cabinetRole,
      projects: [{ _id: this.projectId, role: this.role }]
    };
  }

  getCopy(): ProjectManagerAddingEditModel {
    return new ProjectManagerAddingEditModel({
      email: this.email,
      role: this.role,
      cabinetRole: this.cabinetRole,
      projectId: this.projectId
    });
  }
}
