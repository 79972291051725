import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const ScissorsIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXM}
      svgProps={{
        viewBox: '0 0 20 21'
      }}
      {...props}
    >
      <path
        d="M2.5 7.1L19 15.5M19 5.5L2.5 13.9M4 1.5C5.65685 1.5 7 2.84315 7 4.5C7 6.15685 5.65685 7.5 4 7.5C2.34315 7.5 1 6.15685 1 4.5C1 2.84315 2.34315 1.5 4 1.5ZM4 13.5C5.65685 13.5 7 14.8431 7 16.5C7 18.1569 5.65685 19.5 4 19.5C2.34315 19.5 1 18.1569 1 16.5C1 14.8431 2.34315 13.5 4 13.5Z"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default ScissorsIcon;
