const openUrlInNewBrowserTab = (url: string) => {
  const linkElement = document.createElement('a');
  window.document.body.appendChild(linkElement);
  linkElement.setAttribute('target', '_blank');
  linkElement.setAttribute('href', url);
  linkElement.click();
  linkElement.remove();
};

export default openUrlInNewBrowserTab;
