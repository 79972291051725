import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const AddUserIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <path
        d="M14.9199 14.5794C18.0258 14.5794 20.5509 11.992 20.5509 8.78972C20.5509 5.58744 18.0258 3 14.9199 3C11.814 3 9.28882 5.58744 9.28882 8.78972C9.28882 11.992 11.814 14.5794 14.9199 14.5794Z"
        strokeWidth="1.2"
        stroke="currentColor"
      />
      <path
        d="M17.6722 28.5414H9.71804C9.21301 28.5414 8.68274 28.4389 8.22821 28.234C7.74844 28.029 7.31916 27.7216 6.9909 27.3373C6.63738 26.9531 6.35962 26.4919 6.20811 25.9796C6.03135 25.4672 5.98085 24.9548 6.0061 24.4168C6.28386 20.5229 7.9757 15.2968 14.9956 15.2968C18.8338 15.2968 21.0811 16.8595 22.3437 18.9089"
        strokeWidth="1.2"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M20.7529 25.8515H28.0001"
        strokeWidth="1.2"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M24.3638 29.5661V22.1112"
        strokeWidth="1.2"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </BaseIcon>
  );
};

export default AddUserIcon;
