import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const Bank131Icon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8333 2H4.16667C2.97005 2 2 2.97005 2 4.16667V19.8333C2 21.0299 2.97005 22 4.16667 22H19.8333C21.03 22 22 21.0299 22 19.8333V4.16667C22 2.97005 21.03 2 19.8333 2ZM7.33334 8.66669H8.99999V15.3333H7.83334V9.83331H6.66667L7.33334 8.66669ZM15.6667 8.66669H17.3334V15.3333H16.1667V9.83331H15L15.6667 8.66669ZM11.5 12L12.6667 9.83331H10L10.6667 8.66669H14.5L13.8334 9.83331L13.1855 11.0364C13.9612 11.3915 14.5 12.1745 14.5 13.0833C14.5 14.326 13.4926 15.3333 12.25 15.3333C11.0074 15.3333 10 14.326 10 13.0833H11.1667C11.1667 13.6816 11.6517 14.1667 12.25 14.1667C12.8483 14.1667 13.3333 13.6816 13.3333 13.0833C13.3333 12.485 12.8483 12 12.25 12H11.5Z"
        fill="#FF333A"
      />
    </BaseIcon>
  );
};

export default Bank131Icon;
