import * as React from 'react';

import { DateRangePickerModel } from './DateRangePickerModel';

const DateRangePickerContext = React.createContext<{
  store: DateRangePickerModel;
}>({
  store: new DateRangePickerModel(null, () => {})
});

const DateRangePickerProvider = DateRangePickerContext.Provider;

const useDateRangePicker = (): DateRangePickerModel => {
  const context = React.useContext(DateRangePickerContext);

  return context.store;
};

export { DateRangePickerProvider, useDateRangePicker, DateRangePickerModel };
