import * as React from 'react';

import { EventListenerProps } from '../../eventListener';

import useEventListener from './useEventListenerHook';

const useEnterClickEventListener = ({
  handler,
  options,
  getElement,
  deps,
  withMetaKey = false,
  withShiftKey = false
}: EventListenerProps & {
  withMetaKey?: boolean;
  withShiftKey?: boolean;
}): void => {
  const handleEnterKeyDown = React.useCallback(
    (event: KeyboardEvent): void => {
      const isEnterClicked = event.key === 'Enter' || event.keyCode === 13;
      const withShiftKeyCondition = withShiftKey && event.shiftKey;
      const withMetaKeyCondition = withMetaKey && event.metaKey;
      const withoutKeysCondition =
        !withMetaKey && !withShiftKey && !event.metaKey && !event.shiftKey;
      if (
        isEnterClicked &&
        (withShiftKeyCondition || withMetaKeyCondition || withoutKeysCondition)
      ) {
        handler(event);
      }
    },
    [handler, withMetaKey, withShiftKey]
  );

  useEventListener({
    eventName: 'keydown',
    handler: handleEnterKeyDown,
    options,
    deps,
    getElement
  });
};

export default useEnterClickEventListener;
