import { computed, makeObservable } from 'mobx';

import { FieldModel } from 'shared/models/form';
import { LoadingStageModel } from 'shared/models/loadingStage';
import {
  CabinetRequisitesServer,
  LegalForm,
  LegalFormDataServer,
  OtherLegalForm,
  OtherLegalFormParams,
  validateInn,
  validateKpp,
  validateOgrn
} from 'shared/entities/cabinet';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { validateIsEmpty } from 'shared/entities/validator';

export default class CabinetRequisitesModel {
  readonly cabinetId: string;
  readonly name: FieldModel<string>;
  readonly legalForm: FieldModel<LegalForm | OtherLegalForm.other | null>;
  readonly legalFormFull: FieldModel<string>;
  readonly legalFormShort: FieldModel<string>;
  readonly lpr: FieldModel<string>;
  readonly ogrn: FieldModel<string>;
  readonly inn: FieldModel<string>;
  readonly kpp: FieldModel<string>;
  readonly legalAddress: FieldModel<string>;
  readonly postAddress: FieldModel<string>;
  readonly phone: FieldModel<string>;
  //bank
  readonly bankAccount: FieldModel<string>;
  readonly bankBic: FieldModel<string>;
  readonly bankName: FieldModel<string>;
  readonly bankAddress: FieldModel<string>;
  readonly bankCorrAccount: FieldModel<string>;

  readonly savingStage: LoadingStageModel = new LoadingStageModel();
  readonly saved: FieldModel<boolean>;
  readonly rootStore: IRootStore;

  constructor(
    {
      name,
      legalForm,
      otherLegalFormParams,
      kpp,
      legalAddress,
      phone,
      postAddress,
      lpr,
      ogrn,
      inn,
      bankAccount,
      bankBic,
      bankAddress,
      bankCorrAccount,
      bankName,
      cabinetId,
      saved
    }: {
      name: string;
      legalForm: LegalForm | OtherLegalForm.other | null;
      otherLegalFormParams: OtherLegalFormParams;
      lpr: string;
      ogrn: string;
      inn: string;
      kpp: string;
      legalAddress: string;
      postAddress: string;
      phone: string;
      bankAccount: string;
      bankBic: string;
      bankName: string;
      bankAddress: string;
      bankCorrAccount: string;
      cabinetId: string;
      saved: boolean;
    },
    rootStore: IRootStore
  ) {
    this.cabinetId = cabinetId;
    this.name = new FieldModel<string>(name);
    this.legalForm = new FieldModel<LegalForm | OtherLegalForm.other | null>(
      legalForm,
      [validateIsEmpty]
    );
    this.legalFormFull = new FieldModel(otherLegalFormParams.legalFormFull, [
      validateIsEmpty
    ]);
    this.legalFormShort = new FieldModel(otherLegalFormParams.legalFormShort);

    this.kpp = new FieldModel<string>(kpp, [validateKpp]);
    this.lpr = new FieldModel<string>(lpr);
    this.ogrn = new FieldModel<string>(ogrn, [validateOgrn]);
    this.inn = new FieldModel<string>(inn, [validateInn]);
    this.legalAddress = new FieldModel<string>(legalAddress);
    this.postAddress = new FieldModel<string>(postAddress);
    this.phone = new FieldModel<string>(phone);
    this.bankAccount = new FieldModel<string>(bankAccount);
    this.bankBic = new FieldModel<string>(bankBic);
    this.bankName = new FieldModel<string>(bankName);
    this.bankAddress = new FieldModel<string>(bankAddress);
    this.bankCorrAccount = new FieldModel<string>(bankCorrAccount);
    this.saved = new FieldModel<boolean>(saved);

    this.rootStore = rootStore;

    makeObservable(this, {
      isError: computed,
      withOtherLegalForm: computed,
      touched: computed
    });
  }

  get isError(): boolean {
    return (
      this.legalForm.isError ||
      this.kpp.isError ||
      this.ogrn.isError ||
      this.inn.isError ||
      (this.legalForm.value === OtherLegalForm.other &&
        (this.legalFormFull.isError || this.legalFormShort.isError))
    );
  }

  get touched(): boolean {
    return (
      this.legalForm.touched ||
      this.kpp.touched ||
      this.ogrn.touched ||
      this.inn.touched ||
      (this.legalForm.value === OtherLegalForm.other &&
        (this.legalFormFull.touched || this.legalFormShort.touched)) ||
      this.name.touched ||
      this.lpr.touched ||
      this.legalAddress.touched ||
      this.postAddress.touched ||
      this.phone.touched ||
      this.bankAccount.touched ||
      this.bankBic.touched ||
      this.bankName.touched ||
      this.bankAddress.touched ||
      this.bankCorrAccount.touched
    );
  }

  get withOtherLegalForm(): boolean {
    return this.legalForm.value === OtherLegalForm.other;
  }

  validate = (): void => {
    this.legalForm.validate();
    this.kpp.validate();
    this.ogrn.validate();
    this.inn.validate();

    if (this.withOtherLegalForm) {
      this.legalFormFull.validate();
    }
  };

  save = async (withNotification = true): Promise<BaseResponse> => {
    if (!this.touched) {
      return {
        isError: false
      };
    }
    this.validate();
    const json = this.toJson();
    if (this.savingStage.isLoading || !json || this.isError) {
      return {
        isError: true
      };
    }

    this.savingStage.loading();

    const response = await this.rootStore.networkStore.api(
      apiUrls.PAYMENT_DOCUMENTS_SET_REQUISITES,
      {
        method: 'POST',
        data: json
      }
    );

    if (response.isError) {
      this.savingStage.error();
    } else {
      this.savingStage.success();
      if (withNotification) {
        this.rootStore.appNotificationsStore.open({
          title: (t) =>
            t('cabinet.CabinetRequisiteModel.notification', { ns: 'models' }),
          type: AppNotificationType.success
        });
      }
      this.resetTouched();
      this.saved.changeValue(true);
    }

    return {
      isError: response.isError
    };
  };

  toJson(): CabinetRequisitesServer | null {
    if (!this.legalForm.value) {
      return null;
    }

    let legalFormData: LegalFormDataServer;
    if (this.legalForm.value === OtherLegalForm.other) {
      legalFormData = {
        legal_form: this.legalFormShort.value,
        legal_form_full: this.legalFormFull.value
      };
    } else {
      legalFormData = {
        legal_form: this.legalForm.value
      };
    }

    return {
      name: this.name.value,
      lpr: this.lpr.value,
      ogrn: this.ogrn.value,
      inn: this.inn.value,
      kpp: this.kpp.value,
      legal_address: this.legalAddress.value,
      post_address: this.postAddress.value,
      phone: this.phone.value,
      bank: {
        account: this.bankAccount.value,
        bic: this.bankBic.value,
        name: this.bankName.value,
        address: this.bankAddress.value,
        corr_account: this.bankCorrAccount.value
      },
      ...legalFormData
    };
  }

  resetTouched = () => {
    this.legalForm.resetTouched();
    this.kpp.resetTouched();
    this.ogrn.resetTouched();
    this.inn.resetTouched();
    this.name.resetTouched();
    this.lpr.resetTouched();
    this.legalAddress.resetTouched();
    this.postAddress.resetTouched();
    this.phone.resetTouched();
    this.bankAccount.resetTouched();
    this.bankBic.resetTouched();
    this.bankName.resetTouched();
    this.bankAddress.resetTouched();
    this.bankCorrAccount.resetTouched();

    if (this.legalForm.value === OtherLegalForm.other) {
      this.legalFormFull.resetTouched();
      this.legalFormShort.resetTouched();
    }
  };

  reset = () => {
    this.legalForm.reset();
    this.kpp.reset();
    this.ogrn.reset();
    this.inn.reset();
    this.name.reset();
    this.lpr.reset();
    this.legalAddress.reset();
    this.postAddress.reset();
    this.phone.reset();
    this.bankAccount.reset();
    this.bankBic.reset();
    this.bankName.reset();
    this.bankAddress.reset();
    this.bankCorrAccount.reset();

    if (this.legalForm.value === OtherLegalForm.other) {
      this.legalFormFull.reset();
      this.legalFormShort.reset();
    }
  };

  static fromDefaultParams(
    cabinetId: string,
    rootStore: IRootStore
  ): CabinetRequisitesModel {
    return new CabinetRequisitesModel(
      {
        legalForm: null,
        name: '',
        kpp: '',
        legalAddress: '',
        postAddress: '',
        phone: '',
        lpr: '',
        ogrn: '',
        inn: '',
        bankName: '',
        bankCorrAccount: '',
        bankAddress: '',
        bankBic: '',
        bankAccount: '',
        cabinetId,
        saved: false,
        otherLegalFormParams: { legalFormFull: '', legalFormShort: '' }
      },
      rootStore
    );
  }

  static fromJson(
    {
      name,
      kpp,
      legal_address,
      phone,
      post_address,
      inn,
      lpr,
      ogrn,
      bank,
      ...legalFormData
    }: CabinetRequisitesServer,
    cabinetId: string,
    rootStore: IRootStore
  ): CabinetRequisitesModel {
    const params = legalFormData.legal_form_full
      ? {
          legalFormFull: legalFormData.legal_form_full,
          legalFormShort: legalFormData.legal_form
        }
      : { legalFormFull: '', legalFormShort: '' };

    return new CabinetRequisitesModel(
      {
        name,
        legalForm: legalFormData.legal_form_full
          ? OtherLegalForm.other
          : (legalFormData.legal_form as LegalForm),
        otherLegalFormParams: params,
        kpp,
        legalAddress: legal_address,
        phone,
        postAddress: post_address,
        lpr,
        ogrn,
        inn,
        bankName: bank.name,
        bankCorrAccount: bank.corr_account,
        bankAddress: bank.address,
        bankBic: bank.bic,
        bankAccount: bank.account,
        cabinetId,
        saved: true
      },
      rootStore
    );
  }
}
