const getFileNameFromUrl = (url: string): string => {
  const matchGroup = url.match(/\/(?<fileName>[^/.]+\.[^/.]+)$/)?.groups;

  if (!matchGroup || !matchGroup.fileName) {
    return url;
  }

  return matchGroup.fileName;
};

export default getFileNameFromUrl;
