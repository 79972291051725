import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const ClockIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon iconSize={IconSize.L} {...props}>
      <path
        d="M14.4942 13.2362L12.5422 11.7723V8.79009C12.5422 8.49024 12.2998 8.24786 12 8.24786C11.7001 8.24786 11.4578 8.49024 11.4578 8.79009V12.0434C11.4578 12.2142 11.538 12.3752 11.6747 12.4772L13.8435 14.1038C13.9411 14.177 14.055 14.2123 14.1683 14.2123C14.3337 14.2123 14.4963 14.138 14.6026 13.9948C14.7827 13.7557 14.7339 13.4157 14.4942 13.2362Z"
        fill="currentColor"
      />
      <path
        d="M12 5C8.13996 5 5 8.13996 5 12C5 15.86 8.13996 19 12 19C15.86 19 19 15.86 19 12C19 8.13996 15.86 5 12 5ZM12 17.9156C8.73857 17.9156 6.08443 15.2614 6.08443 12C6.08443 8.73857 8.73857 6.08443 12 6.08443C15.262 6.08443 17.9156 8.73857 17.9156 12C17.9156 15.2614 15.2614 17.9156 12 17.9156Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ClockIcon;
