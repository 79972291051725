import { AttachmentType } from '@shopback/ui/src/types/attachment';

import { TFunctionType } from 'shared/entities/localization';

import cherry1 from './cherry1.png';
import bananas from './bananas.png';
import marocMandarins from './marocMandarins.png';
import clementines from './clementines.png';

export const createProductsPreview = (
  t: TFunctionType
): {
  id: string;
  name: string;
  description: string;
  price: number;
  attachments: AttachmentType[];
}[] => [
  {
    id: 'bananas',
    name: t('shopback.preview.products.bananas.name', {
      ns: 'entities'
    }),
    description: t('shopback.preview.products.bananas.description', {
      ns: 'entities'
    }),
    price: 150,
    attachments: [
      {
        src: bananas,
        width: 399,
        height: 347
      }
    ]
  },
  {
    id: 'cherry',
    name: t('shopback.preview.products.cherry.name', {
      ns: 'entities'
    }),
    description: t('shopback.preview.products.cherry.description', {
      ns: 'entities'
    }),
    price: 200,
    attachments: [
      {
        src: cherry1,
        width: 375,
        height: 400
      }
    ]
  }
];

export const createChildProductsPreview = (
  t: TFunctionType
): {
  id: string;
  name: string;
  description: string;
  price: number;
  attachment: AttachmentType;
}[] => [
  {
    id: 'marocMandarins',
    name: t('shopback.preview.products.marocMandarins.name', {
      ns: 'entities'
    }),
    description: t('shopback.preview.products.marocMandarins.description', {
      ns: 'entities'
    }),
    price: 150,
    attachment: {
      src: marocMandarins,
      width: 403,
      height: 403
    }
  },
  {
    id: 'clementines',
    name: t('shopback.preview.products.clementines.name', {
      ns: 'entities'
    }),
    description: t('shopback.preview.products.clementines.description', {
      ns: 'entities'
    }),
    price: 200,
    attachment: {
      src: clementines,
      width: 533,
      height: 400
    }
  }
];
