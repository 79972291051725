import { makeObservable, computed } from 'mobx';

import { LoadingStageModel } from 'shared/models/loadingStage';
import { apiUrls } from 'shared/entities/domain';
import {
  AIAggregatedDocModelParams,
  AIAggregatedDocType
} from 'shared/entities/smartbotAI';
import { LoadingStage } from 'shared/entities/meta';
import { AppNotificationType } from 'shared/entities/appNotifications';

import { FieldModel } from '../form';

import { AIDocModel } from './AIDocModel';

export abstract class AIAggregatedDocModel<
  T extends AIAggregatedDocType
> extends AIDocModel<T> {
  readonly loadingStage: LoadingStageModel;
  readonly loadingProgress: FieldModel<number>;

  constructor({
    loadingStage,
    loadingProgress,
    ...params
  }: AIAggregatedDocModelParams<T>) {
    super(params);
    this.loadingStage = new LoadingStageModel(loadingStage);
    this.loadingProgress = new FieldModel<number>(loadingProgress);
    makeObservable(this, {
      progressView: computed,
      inProgress: computed
    });
  }

  get withErrors(): boolean {
    return (
      !!this.errors.value?.length ||
      this.loadingStage.value === LoadingStage.ERROR
    );
  }

  get inProgress(): boolean {
    return (
      this.loadingStage.isLoading ||
      (this.loadingStage.successfullyLoaded && this.progress.value < 100)
    );
  }

  abstract get progressView(): number;

  async remove(): Promise<BaseResponse> {
    if (this.removingStage.isLoading || this.id.value === null) {
      return {
        isError: true
      };
    }

    this.removingStage.loading();

    const response = await this.rootStore.networkStore.api(
      apiUrls.AI_AGGREGATED_DOCS_DELETE,
      {
        method: 'POST',
        data: {
          ai_bot_id: this.botId,
          agg_key: this.id.value
        }
      }
    );

    if (response.isError) {
      this.removingStage.error();
    } else {
      this.removingStage.success();
    }

    return response;
  }

  showNotification(): void {
    this.rootStore.appNotificationsStore.open({
      type: AppNotificationType.error,
      title: (t) =>
        t('education.left.docs.notification', {
          ns: 'smartbotAIPage'
        })
    });
  }
}
