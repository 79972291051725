import { action, computed, makeObservable, observable } from 'mobx';

import { IField } from 'shared/entities/form';
import { Validator, ValidatorResult } from 'shared/entities/validator';

export class FieldModel<T = string> implements IField<T> {
  protected _value: T;
  protected _error: ValidatorResult;
  readonly validators: Validator<T>[];
  private _touched = false;
  protected _initialValue: T;

  constructor(value: T, validators: Validator<T>[] = []) {
    this._value = value;
    this._initialValue = value;
    this.validators = validators;

    makeObservable<
      FieldModel<T>,
      '_value' | '_error' | '_touched' | '_initialValue'
    >(this, {
      _value: observable,
      _error: observable,
      _touched: observable,
      _initialValue: observable,

      value: computed,
      initialValue: computed,
      error: computed,
      isError: computed,
      touched: computed,

      changeValue: action.bound,
      validate: action.bound,
      resetError: action.bound,
      setError: action.bound,
      resetTouched: action.bound,
      setTouched: action.bound,
      updateInitialValue: action.bound
    });
  }

  get value(): T {
    return this._value;
  }

  get initialValue(): T {
    return this._initialValue;
  }

  get error(): ValidatorResult {
    return this._error;
  }

  setError(error: ValidatorResult): void {
    this._error = error;
  }

  changeValue(value: T): void {
    this._value = value;
    this.resetError();
    this._value === this._initialValue
      ? this.resetTouched()
      : this.setTouched();
  }

  validate(): ValidatorResult {
    for (let i = 0; i < this.validators.length; i++) {
      const error = this.validators[i](this._value);
      this.setError(error);

      if (error) {
        return error;
      }
    }

    return null;
  }

  getCopy(): FieldModel<T> {
    return new FieldModel<T>(this.value, [...this.validators]);
  }

  isEqual(model: FieldModel<T>): boolean {
    return this.value === model.value;
  }

  get touched(): boolean {
    return this._touched;
  }

  get isError(): boolean {
    return !!this._error;
  }

  setTouched(): void {
    this._touched = true;
  }

  resetError(): void {
    this._error = null;
  }

  resetTouched(): void {
    this._touched = false;
    this._initialValue = this.value;
  }

  reset(): void {
    this.resetError();
    this._value = this._initialValue;
    this._touched = false;
  }

  updateInitialValue = () => {
    this._initialValue = this.value;
  };
}
