import * as React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { useUiStore } from 'stores';
import { useEventListener } from 'shared/entities/common/hooks';

import './ResizeableWindow.modules.scss';

const EXTRA_WIDTH = 472;

type Props = {
  children: React.ReactNode;
};

const ResizeableWindow: React.FC<Props> = ({ children }: Props) => {
  const uiStore = useUiStore();

  const [width, setWidth] = React.useState<number | undefined>(
    !uiStore.isMobileDevice ? EXTRA_WIDTH : undefined
  );

  const [isDragging, setIsDragging] = React.useState(false);

  const onMouseDown = React.useCallback(() => {
    setIsDragging(true);
  }, []);

  const onMouseUp = React.useCallback(() => {
    setIsDragging(false);
  }, []);

  const onMouseMove = React.useCallback(
    (e: MouseEvent) => {
      if (!isDragging) {
        return;
      }
      setWidth((currentSize) => currentSize && currentSize - e.movementX / 2);
    },
    [isDragging]
  );

  useEventListener({
    eventName: 'mousemove',
    handler: onMouseMove,
    getElement: () => document
  });

  useEventListener({
    eventName: 'mouseup',
    handler: onMouseUp,
    getElement: () => document
  });

  return (
    <>
      <div
        styleName={cn(
          'resizeable-window',
          isDragging && 'resizeable-window_unselect'
        )}
        style={{ width: width }}
      >
        <div styleName="resizeable-window__bar" onMouseDown={onMouseDown} />
        {children}
      </div>
    </>
  );
};

export default observer(ResizeableWindow);
