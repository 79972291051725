import { capitalizeFirstLetter } from '../common/utils';

import { IManagerRoleEntity, IProjectManagerEntity } from './client';
import {
  ProjectManagerEntityServer,
  ManagerRoleEntity,
  CabinetManagerRoleType,
  ProjectManagerRoleType
} from './server';

export const normalizeProjectManagerEntity = (
  raw: ProjectManagerEntityServer
): IProjectManagerEntity => ({
  id: raw._id,
  name: raw.name,
  projectRole: raw.role
});

export const normalizeCabinetRoleEntity = (
  raw: ManagerRoleEntity<CabinetManagerRoleType>
): IManagerRoleEntity<CabinetManagerRoleType> => ({
  ...raw,
  name: (t) =>
    capitalizeFirstLetter(
      t(`manager.roles.cabinet.${raw.role}.name`, {
        ns: 'entities'
      })
    ),
  description: (t) =>
    t(`manager.roles.cabinet.${raw.role}.description`, {
      ns: 'entities'
    })
});

export const normalizeProjectRoleEntity = (
  raw: ManagerRoleEntity<ProjectManagerRoleType>
): IManagerRoleEntity<ProjectManagerRoleType> => ({
  ...raw,
  name: (t) =>
    capitalizeFirstLetter(
      t(`manager.roles.project.${raw.role}.name`, {
        ns: 'entities'
      })
    ),
  description: (t) =>
    t(`manager.roles.project.${raw.role}.description`, {
      ns: 'entities'
    })
});
