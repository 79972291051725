import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const VkShoppingIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 56 56' }}
      {...props}
    >
      <path d="M0 0h56v56H0z" />
      <path
        d="M28 6a10 10 0 0 1 10 10h2.59c2.23 0 3.04.23 3.85.67a4.54 4.54 0 0 1 1.9 1.89c.43.81.66 1.62.66 3.85v13.77c0 4.46-.46 6.07-1.34 7.7a9.09 9.09 0 0 1-3.78 3.78c-1.63.88-3.24 1.34-7.7 1.34H21.82c-4.46 0-6.07-.46-7.7-1.34a9.09 9.09 0 0 1-3.78-3.78C9.46 42.25 9 40.64 9 36.18V22.41c0-2.23.23-3.04.67-3.85a4.54 4.54 0 0 1 1.89-1.9c.81-.43 1.62-.66 3.85-.66H18A10 10 0 0 1 28 6Zm12.83 13H15.41c-1.58 0-2 .08-2.44.31-.29.16-.5.37-.66.66-.22.41-.3.82-.3 2.2L12 36.18c0 3.61.25 4.93.98 6.29a6.09 6.09 0 0 0 2.55 2.55c1.36.73 2.68.98 6.29.98h12.36c3.61 0 4.93-.25 6.29-.98a6.09 6.09 0 0 0 2.55-2.55c.73-1.36.98-2.68.98-6.29V22.41c0-1.58-.08-2-.31-2.44-.16-.29-.37-.5-.66-.66-.41-.22-.82-.3-2.2-.3Zm-21.29 3c.33 0 .58.06.8.17.2.12.37.28.49.5.11.2.17.46.17.79v.08c0 .33-.06.58-.17.8-.12.2-.28.37-.5.49-.2.11-.46.17-.79.17h-.08c-.33 0-.58-.06-.8-.17a1.18 1.18 0 0 1-.49-.5 1.63 1.63 0 0 1-.17-.79v-.08c0-.33.06-.58.17-.8.12-.2.28-.37.5-.49.2-.11.46-.17.79-.17h.08Zm17 0c.33 0 .58.06.8.17.2.12.37.28.49.5.11.2.17.46.17.79v.08c0 .33-.06.58-.17.8-.12.2-.28.37-.5.49-.2.11-.46.17-.79.17h-.08c-.33 0-.58-.06-.8-.17a1.18 1.18 0 0 1-.49-.5 1.63 1.63 0 0 1-.17-.79v-.08c0-.33.06-.58.17-.8.12-.2.28-.37.5-.49.2-.11.46-.17.79-.17h.08ZM28 9a7 7 0 0 0-7 7h14a7 7 0 0 0-7-7Z"
        id="market_outline_56__Combined-Shape"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default VkShoppingIcon;
