import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import LoaderSpinner, {
  LoadingSpinnerColor,
  LoadingSpinnerSize
} from 'shared/components/animations/LoaderSpinner';
import {
  ButtonColor,
  ButtonFontStyle,
  ButtonProps,
  ButtonRadius,
  ButtonStyle
} from 'shared/entities/components/Button';
import { LoadingStage } from 'shared/entities/meta';
import { ComponentType } from 'shared/entities/components/Component';
import { IconSize } from 'shared/entities/components/Icon';
import {
  PopupEventType,
  PopupPosition
} from 'shared/entities/components/Popup';
import { Popup } from 'shared/components/popups';
import TransComp from 'shared/components/TransComp';

import './Button.modules.scss';

const Button = React.forwardRef(
  (props: ButtonProps, ref: React.MutableRefObject<any | null>) => {
    const {
      className,
      children,
      disabled,
      loadingStage,
      color = ButtonColor.blue,
      fontStyle = ButtonFontStyle.base,
      radius = ButtonRadius.m,
      fullWidth = false,
      widthBoxShadow = false,
      Icon,
      collapse,
      fixHeight = true,
      iconSize = IconSize.S,
      hideCollapsedReference = false
    } = props;

    const style = React.useMemo(() => {
      return collapse
        ? ButtonStyle.transparent
        : props.style || ButtonStyle.common;
    }, [collapse, props.style]);

    const loaderColor = React.useMemo(() => {
      switch (color) {
        case ButtonColor.green:
          return LoadingSpinnerColor.green;
        case ButtonColor.red:
          return LoadingSpinnerColor.red;
        case ButtonColor.purple:
          return LoadingSpinnerColor.purple;
        default:
          return LoadingSpinnerColor.blue;
      }
    }, [color]);

    const showCollapsedButton = collapse && Icon;

    const content = (
      <>
        <div styleName="button__spinner">
          <LoaderSpinner color={loaderColor} size={LoadingSpinnerSize.s} />
        </div>
        {showCollapsedButton && (
          <Popup
            disabled={hideCollapsedReference}
            trigger={
              <div styleName="button__icon">
                <Icon iconSize={iconSize} />
              </div>
            }
            position={[PopupPosition.topCenter]}
            on={PopupEventType.hover}
          >
            <TransComp>{children}</TransComp>
          </Popup>
        )}
        {!showCollapsedButton && (
          <>
            {Icon && (
              <div styleName="button__icon">
                <Icon iconSize={iconSize} />
              </div>
            )}
            {children && (
              <div
                styleName={cn('button__title', `button__title_${fontStyle}`)}
              >
                <TransComp>{children}</TransComp>
              </div>
            )}
          </>
        )}
      </>
    );

    const styleName = cn(
      'button',
      `button_${radius}`,
      `button_${color}`,
      `button_${style}`,
      fixHeight && 'button_fix-height',
      Icon && 'button_icon',
      !showCollapsedButton && children && 'button_title',
      fullWidth && 'button_full',
      loadingStage === LoadingStage.LOADING && 'button_loading',
      widthBoxShadow && 'button_box-shadow'
    );

    const commonProps = {
      className,
      disabled
    };

    switch (props.type) {
      case ComponentType.link:
        return (
          <Link
            styleName={styleName}
            {...commonProps}
            to={props.to}
            ref={ref}
            target={props.target}
          >
            {content}
          </Link>
        );
      case ComponentType.a: {
        const extraProps =
          props.target === '_blank'
            ? {
                rel: 'noopener noreferrer'
              }
            : {};
        return (
          <a
            styleName={styleName}
            {...commonProps}
            href={props.href}
            target={props.target}
            {...extraProps}
            ref={ref}
          >
            {content}
          </a>
        );
      }
      case ComponentType.button:
        return (
          <button
            styleName={styleName}
            {...commonProps}
            type="button"
            onClick={props.onClick}
            ref={ref}
          >
            {content}
          </button>
        );
    }
  }
);

export default React.memo(Button);
