import {
  action,
  makeObservable,
  observable,
  reaction,
  runInAction
} from 'mobx';
import debounce from 'lodash/debounce';
import * as React from 'react';

import {
  IScenarioCommon,
  IScenarioCommonModel,
  normalizeScenarioCommonServer,
  ScenarioCommonServer,
  ScenarioKind,
  ScenarioTemplateError,
  mapTemplateApplyingErrorToMessage,
  mapScenarioUpdateErrorToMessage,
  ScenarioUpdateError
} from 'shared/entities/scenario';
import { apiUrls } from 'shared/entities/domain';
import { LoadingStage } from 'shared/entities/meta';
import { IRootStore } from 'shared/entities/store/rootStore';
import { AppNotificationType } from 'shared/entities/appNotifications';
import TemplateModel from 'shared/models/scenario/TemplateModel';
import { Bucket } from 'shared/entities/bucket';
import { AnalyticsEvent } from 'shared/entities/analytics';
import TypedTransComponent from 'shared/components/TypedTransComponent';

import ScenarioEditModel from './ScenarioEditModel';
import ScenarioBaseModel from './ScenarioBaseModel';

const DEBOUNCE_DELAY = 500;

export default class ScenarioCommonModel
  extends ScenarioBaseModel
  implements IScenarioCommonModel
{
  removingStage: LoadingStage = LoadingStage.NOT_STARTED;
  copyStage: LoadingStage = LoadingStage.NOT_STARTED;
  applyStage: LoadingStage = LoadingStage.NOT_STARTED;

  kind: ScenarioKind.common = ScenarioKind.common;

  template: TemplateModel | null;

  constructor(
    params: Omit<IScenarioCommon, 'channelIds'> & {
      channelIds: string[] | null;
      rootStore: IRootStore;
      template: TemplateModel | null;
    }
  ) {
    super(params);

    makeObservable(this, {
      removingStage: observable,
      copyStage: observable,
      applyStage: observable,
      template: observable,

      changeTitle: action,
      setIsTemplate: action,
      remove: action,
      updateTemplate: action.bound,
      copy: action.bound,
      apply: action.bound
    });

    this.template = params.template;
  }

  initializeReactions(): void {
    super.initializeReactions();

    this.addReaction({
      key: 'template',
      reaction: reaction(() => this.template, this.updateTemplate)
    });

    this.addReaction({
      key: 'visible',
      reaction: reaction(() => this.template?.visible, this.updateTemplate)
    });

    this.addReaction({
      key: 'description',
      reaction: reaction(
        () => this.template?.description,
        debounce(() => this.updateTemplate(), DEBOUNCE_DELAY)
      )
    });

    this.addReaction({
      key: 'categories',
      reaction: reaction(
        () => this.template?.categories.value?.length,
        this.updateTemplate
      )
    });
  }

  async updateTemplate(): Promise<BaseResponse> {
    if (this.updatingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this.updatingStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api<
      {
        scenario: ScenarioCommonServer;
      },
      ScenarioUpdateError
    >(apiUrls.SCENARIOS_UPDATE, {
      method: 'POST',
      data: {
        template: this.template ? this.template?.toJson() : null,
        update_id: undefined,
        kind: ScenarioKind.common,
        name: this.name,
        _id: this.id,
        bucket: Bucket.dev
      },
      errorsMap: mapScenarioUpdateErrorToMessage,
      showExpectedError: false
    });

    if (!response.isError) {
      runInAction(() => {
        this.updatingStage = LoadingStage.SUCCESS;
      });
    } else {
      runInAction(() => {
        this.updatingStage = LoadingStage.ERROR;
      });
    }

    return {
      isError: response.isError
    };
  }

  async apply(): Promise<BaseResponse<{ scenarioId: string }>> {
    if (
      this.applyStage === LoadingStage.LOADING ||
      !this.template ||
      !this.rootStore.projectId
    ) {
      return {
        isError: true
      };
    }

    this.applyStage = LoadingStage.LOADING;

    this.rootStore.analyticsStore.sendEvent(AnalyticsEvent.templateApply, {
      template_title: this.name
    });

    const response = await this.rootStore.networkStore.api<
      {
        scenario: ScenarioCommonServer;
      },
      ScenarioTemplateError
    >(apiUrls.TEMPLATES_APPLY, {
      method: 'POST',
      data: {
        _id: this.id,
        publish: true
      },
      errorsMap: mapTemplateApplyingErrorToMessage,
      showExpectedError: false
    });

    if (!response.isError) {
      await this.rootStore.variablesStore.load();

      runInAction(() => {
        this.applyStage = LoadingStage.SUCCESS;
      });
      this.rootStore.appNotificationsStore.open({
        type: AppNotificationType.success,
        title: (t) =>
          t(
            'scenario.ScenarioCommonModel.notifications.scenarioSuccessfullyCreatedFromTemplate',
            {
              ns: 'models'
            }
          )
      });

      return {
        isError: false,
        data: {
          scenarioId: response.data.scenario._id
        }
      };
    } else {
      runInAction(() => {
        this.applyStage = LoadingStage.ERROR;
      });
    }

    return {
      isError: response.isError
    };
  }

  changeTitle = (value: string): void => {
    this.name = value;
  };

  setIsTemplate = (value: boolean): void => {
    if (!this.hasProdVersion && value) {
      this.rootStore.appNotificationsStore.open({
        type: AppNotificationType.warning,
        title: (t) =>
          t('scenario.ScenarioCommonModel.notifications.needToPublish', {
            ns: 'models'
          })
      });
      return;
    }

    if (!value) {
      this.template = null;
      return;
    }

    if (!this.template) {
      this.template = TemplateModel.fromDefaultParams(this.rootStore);
    }
  };

  async remove(): Promise<LoadingStage> {
    if (this.removingStage === LoadingStage.LOADING) {
      return LoadingStage.ERROR;
    }

    this.removingStage = LoadingStage.LOADING;

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.SCENARIOS_DELETE,
      {
        method: 'POST',
        data: {
          _id: this.id
        }
      }
    );

    if (!isError) {
      this.rootStore.appNotificationsStore.open({
        timeout: 20000,
        type: AppNotificationType.success,
        title: (
          <TypedTransComponent
            ns="models"
            i18nKey="scenario.ScenarioCommonModel.notifications.scenarioSuccessfullyRemoved"
          >
            Сценарий <b>{{ scenarioName: this.name }}</b> успешно удален
          </TypedTransComponent>
        )
      });

      runInAction(() => {
        this.removingStage = LoadingStage.SUCCESS;
      });
    } else {
      runInAction(() => {
        this.removingStage = LoadingStage.ERROR;
      });
    }

    return this.removingStage;
  }

  async copy(
    editScenarioModel: ScenarioEditModel
  ): Promise<BaseResponse<ScenarioCommonModel>> {
    if (this.copyStage === LoadingStage.LOADING) {
      return { isError: true };
    }

    this.copyStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api<{
      scenario: ScenarioCommonServer;
    }>(apiUrls.SCENARIOS_COPY, {
      method: 'POST',
      data: {
        scenario_id: editScenarioModel.id,
        name: editScenarioModel.name
      }
    });

    if (!response.isError) {
      this.rootStore.appNotificationsStore.open({
        type: AppNotificationType.success,
        title: (t) =>
          t(
            'scenario.ScenarioCommonModel.notifications.scenarioSuccessfullyCopied',
            {
              ns: 'models'
            }
          )
      });

      runInAction(() => {
        this.copyStage = LoadingStage.SUCCESS;
      });
    } else {
      runInAction(() => {
        this.copyStage = LoadingStage.ERROR;
      });
    }

    return !response.isError
      ? {
          isError: false,
          data: ScenarioCommonModel.fromJson(
            response.data.scenario,
            this.rootStore
          )
        }
      : {
          isError: true
        };
  }

  static fromJson(
    serverScenario: ScenarioCommonServer,
    rootStore: IRootStore
  ): ScenarioCommonModel {
    return new ScenarioCommonModel({
      ...normalizeScenarioCommonServer(serverScenario),
      template: serverScenario.template
        ? TemplateModel.fromJson(serverScenario.template, rootStore)
        : null,
      rootStore
    });
  }
}
