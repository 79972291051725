import {
  BaseDocumentServer,
  IDocumentsStore,
  PaymentDocumentKind,
  PaymentDocumentSubject,
  ServiceDocumentMode,
  ServiceDocumentStage
} from 'shared/entities/documents';
import { apiUrls, urls } from 'shared/entities/domain';
import { IRootStore } from 'shared/entities/store/rootStore';
import { FieldModel } from 'shared/models/form';
import { LoadingStageModel } from 'shared/models/loadingStage';

export class CreatingInvoiceModel {
  readonly managersNotified: FieldModel<boolean> = new FieldModel<boolean>(
    false
  );
  readonly rootStore: IRootStore;
  readonly documentsStore: IDocumentsStore;

  readonly creatingStage: LoadingStageModel = new LoadingStageModel();
  readonly cabinetId: string;

  constructor({
    cabinetId,
    documentsStore,
    rootStore
  }: {
    cabinetId: string;
    documentsStore: IDocumentsStore;
    rootStore: IRootStore;
  }) {
    this.cabinetId = cabinetId;
    this.documentsStore = documentsStore;
    this.rootStore = rootStore;
  }

  get data() {
    const stage = this.rootStore.cabinetStore.isLegalEntity
      ? ServiceDocumentStage.prolong
      : ServiceDocumentStage.start;

    return {
      kind: PaymentDocumentKind.invoice,
      subject: PaymentDocumentSubject.service,
      name: '',
      managers_notified: this.managersNotified.value,
      params: {
        sum: this.rootStore.paymentStore.paymentFill.priceToPay.value,
        stage,
        mode: ServiceDocumentMode.sum
      }
    };
  }

  create = async (): Promise<
    { isError: true } | { isError: false; data: BaseDocumentServer }
  > => {
    if (this.creatingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.creatingStage.loading();

    const response = await this.rootStore.networkStore.api<BaseDocumentServer>(
      apiUrls.PAYMENT_DOCUMENTS_CREATE,
      {
        method: 'POST',
        data: this.data
      }
    );

    if (response.isError) {
      this.creatingStage.error();

      return {
        isError: true
      };
    } else {
      this.creatingStage.success();
      this.reset();
      this.rootStore.routerStore.push(urls.SETTINGS.CABINET.DOCUMENTS.create());

      return {
        isError: false,
        data: response.data
      };
    }
  };

  reset = (): void => {
    this.managersNotified.reset();
  };
}
