import * as React from 'react';

import Typography from 'shared/newComponents/Typography';
import {
  TypographySize,
  TypographyType
} from 'shared/newEntities/components/Typography';

type Props = {
  className?: string;
};

const BannedChannelText: React.FC<Props> = ({ className }: Props) => {
  return (
    <Typography
      className={className}
      fontType={TypographyType.text}
      fontSize={TypographySize.xs}
    >
      {(t) =>
        t('Channel.bannedDescription', {
          ns: 'newComponents'
        })
      }
    </Typography>
  );
};

export default BannedChannelText;
