import * as React from 'react';
import { observer } from 'mobx-react';

import {
  ChannelKind,
  channelKindEntities,
  connectViaJivoChannelKindOrder,
  connectViaWazzupChannelKindOrder
} from 'shared/entities/channels';
import Channel from 'shared/newComponents/Channel';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import Typography from 'shared/newComponents/Typography';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import { useTypedTranslation } from 'shared/entities/localization';
import Reference from 'shared/newComponents/Reference';
import { TagSize, TagType } from 'shared/newEntities/components/Tag';
import Tag from 'shared/newComponents/Tag';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import ExternalLink from 'shared/newComponents/ExternalLink';
import { wazzupLinks } from 'shared/entities/app';

import ChannelReferenceDescription from './ChannelReferenceDescription';

import './ConnectionViaChannel.modules.scss';

const mapChannelKindToOrder = {
  [ChannelKind.JIVO]: connectViaJivoChannelKindOrder,
  [ChannelKind.WZ]: connectViaWazzupChannelKindOrder
};

type Props = {
  className?: string;
  showContent: boolean;
  channelKind: ChannelKind.JIVO | ChannelKind.WZ;
};

const ConnectionViaChannel: React.FC<Props> = ({
  className,
  showContent,
  channelKind
}: Props) => {
  const { t } = useTypedTranslation('newContainers');
  const { store } = useChannelsModalStore();
  const { title: channelTitle } = channelKindEntities[channelKind];
  const title = t('ChannelsModal.Creation.connectViaChannel.title', {
    channel: channelTitle
  });

  const reference = React.useMemo(() => {
    if (channelKind === ChannelKind.WZ) {
      return (
        <TypedTransComponent
          i18nKey="ChannelsModal.Creation.connectViaChannel.referenceTitle.wz"
          ns={'newContainers'}
        >
          Интеграция с&nbsp;указанными каналами происходит через сервис партнера{' '}
          <ExternalLink
            fontType={TypographyType.text}
            fontSize={TypographySize.xs}
            fontColor={TypographyColor.link}
            underline
            href={wazzupLinks.referal}
          >
            Wazzup24
          </ExternalLink>
          .
          <br />
          Подключите аккаунт Wazzup, чтобы бот отвечал клиентам в&nbsp;уже
          подключенных или новых каналах.
        </TypedTransComponent>
      );
    } else {
      return t(
        `ChannelsModal.Creation.connectViaChannel.referenceTitle.${channelKind}`
      );
    }
  }, [channelKind]);

  const handleClick = React.useCallback(
    (kind) => {
      if (channelKind === ChannelKind.JIVO) {
        store.selectChannelViaJivo(kind);
      } else {
        store.selectChannelViaWazzup(kind);
      }
    },
    [channelKind]
  );

  return (
    <div styleName="connect-via-channel" className={className}>
      <div styleName="connect-via-channel__header">
        <div styleName="connect-via-channel__header-left">
          <Typography
            fontType={TypographyType.text}
            fontSize={TypographySize.m}
            fontWeight={TypographyWeight.heavy}
          >
            {title}
          </Typography>
          <Reference title={reference} titleWeight={TypographyWeight.normal} />
        </div>
        {showContent && (
          <Tag size={TagSize.s} type={TagType.base}>
            new
          </Tag>
        )}
      </div>
      <div styleName="connect-via-channel__kinds">
        {mapChannelKindToOrder[channelKind].map((kind) => {
          const { title, fullTitle } = channelKindEntities[kind];
          return (
            <Channel
              key={kind}
              kind={kind}
              onClick={handleClick}
              selected={store.isSelectedChannel(channelKind, kind)}
              withoutLabel={true}
              referenceTitle={(t) =>
                t(
                  `ChannelsModal.Creation.connectViaChannel.channel.${channelKind}.referenceTitle`,
                  { ns: 'newContainers', channel: fullTitle ?? title }
                )
              }
              referenceDescription={
                channelKind === ChannelKind.WZ &&
                (kind === ChannelKind.WABA ||
                  kind === ChannelKind.WHATSAPP ||
                  kind === ChannelKind.TELEGRAM_PERSONAL) ? (
                  <ChannelReferenceDescription channelKind={kind} />
                ) : null
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default observer(ConnectionViaChannel);
