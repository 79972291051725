import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps } from 'shared/entities/components/Icon';

const CircledPlusIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon {...props}>
      <path
        d="M9.00001 0C4.0371 0 0 4.03772 0 9C0 13.9623 4.03773 18 9.00001 18C13.9623 18 18 13.9623 18 9C18 4.03772 13.9629 0 9.00001 0ZM9.00001 16.9929C4.59322 16.9929 1.0077 13.4074 1.0077 9.00063C1.0077 4.59384 4.59322 1.00833 9.00001 1.00833C13.4068 1.00833 16.9923 4.59384 16.9923 9.00063C16.9923 13.4074 13.4074 16.9929 9.00001 16.9929Z"
        fill="currentColor"
      />
      <path
        d="M13.0388 8.49686H9.50429V4.96173C9.50429 4.68336 9.27882 4.45789 9.00044 4.45789C8.72206 4.45789 8.49659 4.68336 8.49659 4.96173V8.49623H4.96212C4.68374 8.49623 4.45825 8.72171 4.45825 9.00008C4.45825 9.27846 4.68374 9.50393 4.96212 9.50393H8.49659V13.0384C8.49659 13.3168 8.72206 13.5423 9.00044 13.5423C9.27882 13.5423 9.50429 13.3168 9.50429 13.0384V9.50393H13.0388C13.3172 9.50393 13.5426 9.27846 13.5426 9.00008C13.5426 8.72171 13.3165 8.49686 13.0388 8.49686Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CircledPlusIcon;
