import * as React from 'react';

import {
  SelectorItem,
  SelectorKey,
  SelectorType
} from 'shared/newEntities/components/Selector';
import { SelectorBaseOption } from 'shared/newComponents/SelectorBase';

type Props = SelectorItem & {
  onClick: (id: SelectorKey) => void;
  className?: string;
  largeOption?: boolean;
} & (
    | {
        type: SelectorType.single;
        value: SelectorKey | null;
      }
    | {
        type: SelectorType.multi;
        value: SelectorKey[];
      }
  );

const SelectorOption: React.FC<Props> = (props) => {
  const { id, onClick, rightComponent, ...rest } = props;

  const handleClick = React.useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  const isSelected = React.useMemo((): boolean => {
    if (rest.type === SelectorType.single) {
      if (!rest.value) {
        return false;
      }

      return rest.value === id;
    }

    return rest.value.some((curValue) => curValue === id);
  }, [rest.type, rest.value, id]);

  const rightComponentProps = React.useMemo(() => {
    if (!rightComponent) {
      return {};
    }

    return {
      RightIcon: rightComponent.Icon,
      onRightIconClick: (e: React.MouseEvent) => {
        e.stopPropagation();
        rightComponent.onIconClick?.(id);
      }
    };
  }, [rightComponent, id]);

  return (
    <SelectorBaseOption
      selected={isSelected}
      onClick={handleClick}
      selectView={rest.type === SelectorType.multi ? 'checkbox' : 'icon'}
      {...rightComponentProps}
      {...rest}
    />
  );
};

export default React.memo(SelectorOption);
