import { action, computed, makeObservable, observable, reaction } from 'mobx';
// eslint-disable-next-line import/named
import { debounce } from 'lodash';

import { IRootStore } from 'shared/entities/store/rootStore';
import { IManagerExternal } from 'shared/entities/user';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { PollingWindowModel } from 'shared/models/polling';
import { apiUrls } from 'shared/entities/domain';
import { ChannelKind } from 'shared/entities/channels';
import { IVKCreationStore, VkGroupServer } from 'shared/entities/vk';
import ListModel from 'shared/models/ListModel';
import { FieldModel } from 'shared/models/form';
import { ReactionsHandlerStore } from 'stores/reactionsHandlerStore';
import { VKGroupModel } from 'shared/models/channel';

export class VKCreationStore
  extends ReactionsHandlerStore
  implements IVKCreationStore
{
  private rootStore: IRootStore;
  readonly gettingAuthUrlStage: LoadingStageModel = new LoadingStageModel();
  readonly removingStage: LoadingStageModel = new LoadingStageModel();
  private tokenPolling: PollingWindowModel;
  readonly groups: ListModel<VKGroupModel, number> = new ListModel<
    VKGroupModel,
    number
  >();
  readonly loadingGroupsStage: LoadingStageModel = new LoadingStageModel();
  readonly search: FieldModel = new FieldModel('');
  private filteredGroupIds: number[] = [];

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;

    this.tokenPolling = new PollingWindowModel({
      rootStore,
      cb: this.checkUserToken
    });

    makeObservable<VKCreationStore, 'filteredGroupIds'>(this, {
      filteredGroupIds: observable.ref,

      vkAccount: computed,
      vkAccountName: computed,
      vkAccountShortName: computed,
      filteredGroups: computed,

      resetFilteredGroups: action.bound,
      removeGroup: action
    });
  }

  get filteredGroups(): VKGroupModel[] {
    return this.filteredGroupIds.reduce<VKGroupModel[]>((acc, id) => {
      const group = this.groups.getEntity(id);

      if (group) {
        acc.push(group);
      }

      return acc;
    }, []);
  }

  filter = (): void => {
    this.filteredGroupIds = this.groups.items
      .filter((item) =>
        item.name.toLowerCase().includes(this.search.value.toLowerCase())
      )
      .map((item) => item.id);
  };

  resetFilteredGroups(): void {
    this.filteredGroupIds = [];
  }

  debouncedFilter = debounce(this.filter, 100);

  get vkAccount(): IManagerExternal | null {
    if (!this.rootStore.userStore.userTokens?.vk) {
      return null;
    }

    return this.rootStore.userStore.userExternals?.vk || null;
  }

  get vkAccountName(): string | null {
    return !this.vkAccount
      ? null
      : `${this.vkAccount.firstName}${
          this.vkAccount.lastName ? ` ${this.vkAccount.lastName}` : ''
        }`;
  }

  get vkAccountShortName(): string | null {
    return !this.vkAccount
      ? null
      : `${this.vkAccount.firstName}${
          this.vkAccount.lastName.length
            ? ` ${this.vkAccount.lastName[0]}.`
            : ''
        }`;
  }

  init(): void {
    this.addReaction({
      key: 'search',
      reaction: reaction(() => this.search.value, this.debouncedFilter)
    });
    if (!this.vkAccount) {
      return;
    }

    this.loadGroups();
  }

  reset(): void {
    this.tokenPolling.stopPolling();
    this.tokenPolling.closePollingBanner();
    this.disposeAll();
  }

  removeGroup(id: number): void {
    this.groups.removeEntity(id);
    this.filteredGroupIds = this.filteredGroupIds.filter((val) => val !== id);
  }

  disconnectAccount = async (): Promise<BaseResponse> => {
    if (this.removingStage.isLoading) {
      return {
        isError: true
      };
    }
    this.removingStage.loading();

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.OAUTH_TOKEN_REVOKE,
      {
        method: 'POST',
        data: {
          channel_kind: ChannelKind.VK
        }
      }
    );

    if (!isError) {
      this.rootStore.userStore.removeToken(ChannelKind.VK);
      this.groups.reset();
      this.resetFilteredGroups();
      this.search.reset();
      this.removingStage.success();

      return {
        isError: false
      };
    } else {
      this.removingStage.error();

      return {
        isError: true
      };
    }
  };

  connectAccount = async (): Promise<BaseResponse> => {
    const response = await this.getAuthUrl();

    if (response.isError) {
      return {
        isError: true
      };
    }

    return this.tokenPolling.openWindow(response.data.authUrl);
  };

  loadGroups = async (): Promise<BaseResponse> => {
    if (this.loadingGroupsStage.isLoading) {
      return {
        isError: true
      };
    }

    this.loadingGroupsStage.loading();

    const response = await this.rootStore.networkStore.api<{
      groups: VkGroupServer[];
    }>(apiUrls.OAUTH_VK_GET_VK_GROUPS);

    if (!response.isError) {
      const collection = response.data.groups.reduce<{
        keys: number[];
        entities: Record<number, VKGroupModel>;
      }>(
        (acc, item) => {
          return {
            ...acc,
            keys: [...acc.keys, item.id],
            entities: {
              ...acc.entities,
              [item.id]: VKGroupModel.fromJson({
                ...item,
                rootStore: this.rootStore,
                vkCreationStore: this
              })
            }
          };
        },
        { keys: [], entities: {} }
      );

      this.groups.addEntities({
        ...collection,
        initial: true
      });
      this.filter();
      this.loadingGroupsStage.success();

      return {
        isError: false
      };
    } else {
      this.loadingGroupsStage.error();

      return {
        isError: true
      };
    }
  };

  /**
   * Получение url для авторизации пользователя через oauth vk
   * @returns {Promise<{isError: true} | {isError: false, data: {authUrl: string}}>}
   */
  private getAuthUrl = async (): Promise<BaseResponse<{ authUrl: string }>> => {
    if (this.gettingAuthUrlStage.isLoading) {
      return {
        isError: true
      };
    }

    this.gettingAuthUrlStage.loading();

    const response = await this.rootStore.networkStore.api<{
      auth_url: string;
    }>(apiUrls.OAUTH_VK_GET_URL);

    if (!response.isError) {
      this.gettingAuthUrlStage.success();

      return {
        isError: false,
        data: {
          authUrl: response.data.auth_url
        }
      };
    }

    this.gettingAuthUrlStage.error();

    return {
      isError: true
    };
  };

  /**
   * Проверка того, есть ли токен vk у пользователя
   * @returns {Promise<{isError: boolean} | {isError: boolean}>}
   */
  private checkUserToken = async (): Promise<BaseResponse> => {
    const { isError } = await this.rootStore.userStore.loadAuthInfo();

    if (!isError && this.rootStore.userStore.userTokens?.vk) {
      this.loadGroups();
      return {
        isError: false
      };
    }

    return {
      isError: true
    };
  };
}
