import {
  PaymentDocumentKind,
  PaymentDocumentSubject,
  OtherDocumentServer,
  normalizeCommonDocument
} from 'shared/entities/documents';

import { BaseDocumentModel } from './BaseDocumentModel';

export class OtherDocumentModel extends BaseDocumentModel {
  readonly kind: PaymentDocumentKind.other = PaymentDocumentKind.other;
  readonly subject: PaymentDocumentSubject.other = PaymentDocumentSubject.other;

  static fromJson(
    raw: OtherDocumentServer,
    cabinetId: string
  ): OtherDocumentModel {
    return new OtherDocumentModel({
      ...normalizeCommonDocument(raw),
      cabinetId
    });
  }
}
