import * as React from 'react';

import { DatePickerModel } from './DatePickerModel';

const DatePickerContext = React.createContext<{
  store: DatePickerModel;
}>({
  store: new DatePickerModel({ value: null, cb: () => {} })
});

const DatePickerProvider = DatePickerContext.Provider;

const useDatePicker = (): DatePickerModel => {
  const context = React.useContext(DatePickerContext);

  return context.store;
};

export { DatePickerProvider, useDatePicker, DatePickerModel };
