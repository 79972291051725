import * as React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import {
  CommonIconProps,
  iconSideSizes,
  IconSize
} from 'shared/entities/components/Icon';
import { ComponentType } from 'shared/entities/components/Component';
import { noop } from 'shared/entities/common/utils';

import './BaseIcon.modules.scss';

type Props = CommonIconProps & {
  children?: React.ReactNode;
};

const BaseIcon: React.FC<Props> = (props: Props) => {
  const {
    iconSize = IconSize.M,
    className,
    children,
    svgProps = {},
    iconStyle
  } = props;

  const sideSize = iconSideSizes[iconSize];

  if (!props.action) {
    return (
      <svg
        className={className}
        styleName={cn(iconStyle && `base-icon_${iconStyle}`)}
        width={sideSize}
        height={sideSize}
        viewBox={`0 0 ${sideSize} ${sideSize}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...svgProps}
      >
        {children}
      </svg>
    );
  }

  if (props.action.type === ComponentType.link) {
    return (
      <Link
        styleName="base-icon"
        to={props.action.to}
        target={props.action.target}
        className={className}
      >
        <svg
          styleName={cn(iconStyle && `base-icon_${iconStyle}`)}
          width={sideSize}
          height={sideSize}
          viewBox={`0 0 ${sideSize} ${sideSize}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...svgProps}
        >
          {children}
        </svg>
      </Link>
    );
  }

  if (props.action.type === ComponentType.a) {
    return (
      <a
        styleName="base-icon"
        href={props.action.href}
        target={props.action.target}
        className={className}
        onClick={props.action.onClick}
      >
        <svg
          styleName={cn(iconStyle && `base-icon_${iconStyle}`)}
          width={sideSize}
          height={sideSize}
          viewBox={`0 0 ${sideSize} ${sideSize}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...svgProps}
        >
          {children}
        </svg>
      </a>
    );
  }

  return (
    <svg
      onClick={
        props.action.type === ComponentType.svg ? props.action.onClick : noop
      }
      className={className}
      styleName={cn(iconStyle && `base-icon_${iconStyle}`)}
      width={sideSize}
      height={sideSize}
      viewBox={`0 0 ${sideSize} ${sideSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      {children}
    </svg>
  );
};

export default React.memo<Props>(BaseIcon);
