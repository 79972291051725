import { TranslationMap } from 'shared/entities/localization';

import { VerifiedByType } from './server';

export interface IVerifiedDomainBase {
  id: string;
  projectId: string;
  domain: string;
  dateCreated: Date;
}

export interface IVerifiedConfirmedDomainParams {
  verifiedBy: VerifiedByType;
  verifiedAt: Date;
}

export interface IVerifiedConfirmedDomain
  extends IVerifiedDomainBase,
    IVerifiedConfirmedDomainParams {}

export interface IVerifiedUnconfirmedDomainParams {
  verifiedBy: null;
  verifiedAt: null;
  metaContent: string;
  dnsContent: string | null;
  file: {
    content: string;
    path: string;
    name: string;
  };
}

export interface IVerifiedUnconfirmedDomain
  extends IVerifiedDomainBase,
    IVerifiedUnconfirmedDomainParams {}

export enum VerifiedTabs {
  dns = 'dns',
  meta = 'meta',
  file = 'file'
}

export enum ConfirmationType {
  confirmed = 'confirmed',
  unconfirmed = 'unconfirmed'
}

export const confirmationInfoMap: TranslationMap<VerifiedByType> = {
  [VerifiedByType.dns]: (t) =>
    t('verifiedDomains.dns', {
      ns: 'entities'
    }),
  [VerifiedByType.file]: (t) =>
    t('verifiedDomains.file', {
      ns: 'entities'
    }),
  [VerifiedByType.meta]: (t) =>
    t('verifiedDomains.meta', {
      ns: 'entities'
    }),
  [VerifiedByType.manual]: (t) =>
    t('verifiedDomains.manual', {
      ns: 'entities'
    }),
  [VerifiedByType.whitelisted]: (t) =>
    t('verifiedDomains.whitelisted', {
      ns: 'entities'
    })
};
