import { SelectorEntities } from 'shared/newEntities/components/Selector';

import { TranslationString } from '../localization';

export enum TypeOfTgProducts {
  physical = 'physical',
  digital = 'digital'
}

export const typeOfTgProductsOrder: TypeOfTgProducts[] = [
  TypeOfTgProducts.physical,
  TypeOfTgProducts.digital
];

export const typeOfTgProductsEntities: SelectorEntities<
  TypeOfTgProducts,
  TranslationString
> = {
  [TypeOfTgProducts.physical]: {
    id: TypeOfTgProducts.physical,
    title: (t) =>
      t('integrations.tg.typeOfTgProducts.physical', { ns: 'entities' })
  },
  [TypeOfTgProducts.digital]: {
    id: TypeOfTgProducts.digital,
    title: (t) =>
      t('integrations.tg.typeOfTgProducts.digital', { ns: 'entities' })
  }
};
