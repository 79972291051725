import PubSub from 'pubsub-js';

import { ReactionsHandlerStore } from 'stores/reactionsHandlerStore';

export default class PubSubObserver extends ReactionsHandlerStore {
  private pubSubTokens: Record<string, string> = {};

  subscribeEvent(
    event: string,
    handler: (event: string, data: Record<string, any>) => void
  ): PubSubObserver {
    this.pubSubTokens[event] = PubSub.subscribe(event, handler);

    return this;
  }

  unsubscribeAll(): void {
    Object.values(this.pubSubTokens).forEach((token) => {
      PubSub.unsubscribe(token);
    });

    this.disposeAll();
  }
}
