import { makeObservable, observable } from 'mobx';

import { IEdit } from 'shared/entities/edit';
import { ApiTokenScope } from 'shared/entities/apiIntegration';

export default class ApiTokenEditModel implements IEdit {
  name: string;
  scope: ApiTokenScope[];

  constructor({ name, scope }: { name: string; scope: ApiTokenScope[] }) {
    this.name = name;
    this.scope = scope;

    makeObservable(this, {
      name: observable,
      scope: observable
    });
  }

  getCopy(): ApiTokenEditModel {
    return new ApiTokenEditModel({
      name: this.name,
      scope: [...this.scope]
    });
  }

  toJson(): { name: string; scope: ApiTokenScope[] } {
    return {
      name: this.name,
      scope: this.scope
    };
  }
}
