import { action, makeObservable, observable } from 'mobx';

import {
  EnabledProjectSettingServer,
  IEnabledProjectSetting,
  normalizeEnabledProjectSetting
} from 'shared/entities/projectSettings';
import {
  ProjectEnableForSetting,
  SettingsType
} from 'shared/entities/projectSettingsTypes';
import { ISettingsStore } from 'shared/entities/store/settingsStore';

export default class EnabledProjectSettingModel
  implements IEnabledProjectSetting
{
  enabled: boolean;
  for: ProjectEnableForSetting;
  settingsStore: ISettingsStore;

  constructor({
    params,
    settingsStore
  }: {
    params: IEnabledProjectSetting;
    settingsStore: ISettingsStore;
  }) {
    makeObservable(this, {
      enabled: observable,
      for: observable,
      changeEnabled: action,
      changeFor: action
    });

    this.enabled = params.enabled;
    this.for = params.for;
    this.settingsStore = settingsStore;
  }

  changeEnabled = async (value: boolean, withRequest = true): Promise<void> => {
    this.enabled = value;
    if (!withRequest) {
      return;
    }
    const { isError } = await this.settingsStore.update(
      { [SettingsType.botEnabled]: this.toJson() },
      (t) =>
        t(
          'models.projectSettings.EnabledProjectSettingModel.notifications.enabledError',
          {
            ns: 'models'
          }
        )
    );
    if (isError) {
      this.enabled = !this.enabled;
    }
  };

  changeFor = async (
    value: ProjectEnableForSetting,
    withRequest = true
  ): Promise<void> => {
    const prevValue = this.for;
    this.for = value;
    if (!withRequest) {
      return;
    }
    const { isError } = await this.settingsStore.update(
      { [SettingsType.botEnabled]: this.toJson() },
      (t) =>
        t(
          'models.projectSettings.EnabledProjectSettingModel.notifications.forError',
          {
            ns: 'models'
          }
        )
    );
    if (isError) {
      this.for = prevValue;
    }
  };

  toJson() {
    return {
      enabled: this.enabled,
      for: this.for
    };
  }

  static fromJson({
    raw,
    settingsStore
  }: {
    raw: EnabledProjectSettingServer;
    settingsStore: ISettingsStore;
  }): EnabledProjectSettingModel {
    return new EnabledProjectSettingModel({
      params: normalizeEnabledProjectSetting(raw),
      settingsStore
    });
  }
}
