import { action, makeObservable, runInAction } from 'mobx';
import * as React from 'react';

import {
  BaseVarServer,
  CommonVarErrorCode,
  IUserVar,
  UserDateVarServer,
  mapVarCommonErrorToMessage
} from 'shared/entities/vars';
import { UserItemModel } from 'shared/models/users';
import { apiUrls } from 'shared/entities/domain';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { IRootStore } from 'shared/entities/store/rootStore';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import { FieldModel } from 'shared/models/form';

export default abstract class UserVarModel<T> implements IUserVar<T> {
  readonly key: string;
  readonly value: FieldModel<T>;
  readonly savedValue: FieldModel<T>;

  readonly chatId: string;

  readonly user: UserItemModel;

  readonly rootStore: IRootStore;
  private abortController: AbortController | null = null;

  constructor({
    key,
    value,
    chatId,
    user,
    rootStore
  }: {
    key: string;
    value: T;
    chatId: string;
    user: UserItemModel;
    rootStore: IRootStore;
  }) {
    this.key = key;
    this.value = new FieldModel<T>(value);
    this.chatId = chatId;
    this.user = user;
    this.rootStore = rootStore;
    this.savedValue = new FieldModel<T>(value);

    makeObservable(this, {
      save: action.bound
    });
  }

  abstract toJson(): BaseVarServer | UserDateVarServer;

  async save(): Promise<void> {
    if (this.savedValue.isEqual(this.value)) {
      return;
    }
    this.cancelSaving();

    const abortController = new AbortController();
    this.abortController = abortController;

    const params = this.toJson();
    const response = await this.rootStore.networkStore.api<
      {},
      CommonVarErrorCode
    >(apiUrls.USERS_UPDATE_VAR, {
      method: 'POST',
      data: {
        user_id: this.user.id,
        chat_id: this.chatId,
        ...params
      },
      config: {
        signal: abortController.signal
      },
      errorsMap: mapVarCommonErrorToMessage
    });

    runInAction(() => {
      if (!response.isError) {
        this.savedValue.changeValue(this.value.value);

        this.user.rootStore.appNotificationsStore.open({
          title: (
            <TypedTransComponent
              ns="models"
              i18nKey="var.user.UserCommonVarModel.notifications.nameUpdated"
            >
              Значение переменной <b>{{ key: this.key }}</b> успешно обновлено
            </TypedTransComponent>
          ),
          type: AppNotificationType.success
        });
      } else {
        this.value.changeValue(this.savedValue.value);
      }
    });
  }

  private cancelSaving(): void {
    if (!this.abortController) {
      return;
    }

    this.abortController.abort();
    this.abortController = null;
  }
}
