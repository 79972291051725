import * as React from 'react';
import { observer } from 'mobx-react';

import { ComponentType } from 'shared/entities/components/Component';
import {
  TranslationString,
  useTypedTranslation
} from 'shared/entities/localization';
import FormModal from 'shared/newComponents/modals/FormModal';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import TransComp from 'shared/components/TransComp';

type Props = {
  opened: boolean;
  onClose: () => void;
  onRemove: () => void;
  channelName: string;
  extraInfo?: TranslationString;
};

const RemoveModal: React.FC<Props> = ({
  opened,
  onClose,
  onRemove,
  channelName,
  extraInfo
}: Props) => {
  const { t } = useTypedTranslation('newContainers');

  const handleRemove = React.useCallback(() => {
    onRemove();
    onClose();
  }, [onRemove, onClose]);

  return (
    <FormModal
      title={(t) =>
        t('ChannelsModal.ChannelTable.ChannelItem.RemoveModal.title', {
          ns: 'newContainers'
        })
      }
      opened={opened}
      onClose={onClose}
      ok={{
        children: t(
          'ChannelsModal.ChannelTable.ChannelItem.RemoveModal.removeButtonTitle'
        ),
        type: ComponentType.button,
        onClick: handleRemove
      }}
      onEnter={handleRemove}
    >
      <TypedTransComponent
        i18nKey="ChannelsModal.ChannelTable.ChannelItem.RemoveModal.answer"
        ns={'newContainers'}
      >
        Вы&nbsp;уверены, что хотите удалить канал <b>{{ channelName }}</b>?
      </TypedTransComponent>
      <div>
        <TransComp>{extraInfo ?? ''}</TransComp>
      </div>
      <TypedTransComponent
        i18nKey="ChannelsModal.ChannelTable.ChannelItem.RemoveModal.description"
        ns={'newContainers'}
      >
        Также будут удалены все пользователи этого канала и&nbsp;их&nbsp;данные
        (значения переменных, диалоги и&nbsp;т.д.).
        <br />
        Это действие будет невозможно отменить.
      </TypedTransComponent>
    </FormModal>
  );
};

export default observer(RemoveModal);
