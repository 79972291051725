import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const DefaultAnswerIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <path
        d="M11.2906 26.1566C12.7931 26.7376 14.3913 27.0317 16.0022 27.0235C22.6296 27.0235 28 22.0908 28 16.0096C28 9.9283 22.6296 5 16.0022 5C9.37475 5 4 9.9283 4 16.0096C4.00226 17.968 4.56313 19.8852 5.61676 21.536"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeWidth="1.2"
      />
      <path
        d="M11.2688 26.1604L4.30762 27.0446L5.59928 21.5572"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeWidth="1.2"
      />
      <path
        d="M12 13.7165C11.9996 12.9818 12.217 12.2635 12.6249 11.6524C13.0327 11.0413 13.6127 10.5648 14.2913 10.2834C14.97 10.0019 15.7169 9.92803 16.4375 10.0711C17.1582 10.2142 17.8202 10.5677 18.3399 11.0871C18.8596 11.6065 19.2135 12.2683 19.357 12.9888C19.5005 13.7094 19.4271 14.4563 19.1461 15.1352C18.865 15.814 18.3889 16.3942 17.7781 16.8024C17.1672 17.2107 16.449 17.4286 15.7143 17.4286"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeWidth="1.2"
      />
      <path
        d="M15.7144 17.4286V19.5792"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeWidth="1.2"
      />
      <path
        d="M15.7142 23C16.2271 23 16.6428 22.5843 16.6428 22.0715C16.6428 21.5586 16.2271 21.1429 15.7142 21.1429C15.2014 21.1429 14.7856 21.5586 14.7856 22.0715C14.7856 22.5843 15.2014 23 15.7142 23Z"
        fill="currentColor"
        strokeWidth="1.2"
      />
    </BaseIcon>
  );
};

export default DefaultAnswerIcon;
