import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const EditIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XM}
      svgProps={{
        viewBox: '0 0 20 20'
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0824 2.14117C14.7144 1.50882 15.7394 1.50839 16.3719 2.1402L17.8585 3.62502C18.4913 4.25711 18.4917 5.28256 17.8594 5.91518L10.842 12.9362C10.6165 13.1619 10.3293 13.3159 10.0166 13.3789L6.88185 14.0105C6.63309 14.0606 6.37575 13.9828 6.19637 13.8034C6.01699 13.6239 5.93938 13.3665 5.98965 13.1178L6.62242 9.9868C6.68549 9.67471 6.83919 9.38814 7.06427 9.16294L14.0824 2.14117ZM15.3012 3.2122C15.2605 3.17161 15.1947 3.17164 15.1541 3.21226L8.13592 10.234C8.12146 10.2485 8.11159 10.2669 8.10754 10.2869L7.70073 12.2999L9.71732 11.8936C9.73741 11.8895 9.75586 11.8796 9.77035 11.8651L16.7878 4.84408C16.8284 4.80346 16.8283 4.73761 16.7877 4.69702L15.3012 3.2122ZM5.00903 4.54821C3.99988 4.54821 3.18184 5.36626 3.18184 6.37532V14.991C3.18184 16.0001 3.99988 16.8181 5.00903 16.8181H13.6249C14.6341 16.8181 15.4521 16.0001 15.4521 14.991L15.4521 10.6832C15.4521 10.2648 15.7913 9.9256 16.2097 9.9256C16.6281 9.9256 16.9673 10.2648 16.9673 10.6832L16.9673 14.991C16.9673 16.8369 15.4708 18.3333 13.6249 18.3333H5.00903C3.16313 18.3333 1.66669 16.8369 1.66669 14.991V6.37532C1.66669 4.52942 3.16313 3.03306 5.00903 3.03306H9.31698C9.73538 3.03306 10.0746 3.37224 10.0746 3.79063C10.0746 4.20903 9.73538 4.54821 9.31698 4.54821H5.00903Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default EditIcon;
