import { VAR_REG_EXP_WORD } from 'shared/entities/regExp/var';

/**
 * Проверяет, что входная строка - переменная
 * @param value
 * @returns {boolean}
 */
const parseValueForGettingVarKey = ({ value }: { value: string }): boolean => {
  const trimmedValue = value.trim();

  return VAR_REG_EXP_WORD.test(trimmedValue);
};

export { parseValueForGettingVarKey };
