/**
 * Получает инициалы из username
 * @param username
 * @returns {`${string} ${string}` | string}
 */
export const getInitialsFromUsername = (username: string): string => {
  if (!username.length) {
    return '';
  }

  const arr = username.split(' ');

  if (arr.length === 2) {
    return `${arr[0][0]}${arr[1][0]}`;
  }

  return username[0];
};
