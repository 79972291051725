import { Normalizer } from '../normalizer';

import { Collection } from './client';

export type Result<EntityType, C> = {
  isError: boolean;
  collection: Collection<EntityType, C>;
};

export const normalizeCollection = <ServerJson, EntityType, C = string>(
  from: ServerJson[],
  normalizer: Normalizer<ServerJson, EntityType>,
  fieldKey = 'key'
): Result<EntityType, C> => {
  const res: Collection<EntityType, C> = {
    keys: [],
    entities: {}
  };

  try {
    from.forEach((item) => {
      res.keys.push(item[fieldKey]);
      res.entities[item[fieldKey]] = normalizer(item);
    });
  } catch (e) {
    return {
      isError: true,
      collection: {
        keys: [],
        entities: {}
      }
    };
  }

  return {
    isError: false,
    collection: res
  };
};
