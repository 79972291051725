import * as React from 'react';
import { observer } from 'mobx-react';
import { motion } from 'framer-motion';
import cn from 'classnames';

import Section from 'shared/newComponents/Section';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import FadeScroll from 'shared/newComponents/FadeScroll';
import { ChannelKind } from 'shared/entities/channels';

import { useUnSelectAnimation } from '../../hooks';

import ChannelKinds from './ChannelKinds';
import ConnectionViaChannel from './ConnectionViaChannel';
import styles from './Creation.modules.scss';

const Creation: React.FC = () => {
  const { store } = useChannelsModalStore();
  const { ref, showContent } = useUnSelectAnimation({
    selected: !!store.selectedChannelKind.value,
    withMargin: false,
    width: !store.withSections ? '100%' : '320px'
  });

  return (
    <motion.div ref={ref} initial={false}>
      <Section
        styleName="creation"
        contentClassName={styles['creation__container']}
        title={(t) =>
          t('ChannelsModal.Creation.title', {
            ns: 'newContainers'
          })
        }
      >
        {
          <FadeScroll styleName="creation__content-wrapper">
            <div
              styleName={cn(
                'creation__content',
                !showContent && 'creation__content_hidden'
              )}
            >
              <ChannelKinds styleName="creation__kinds" />
              <ConnectionViaChannel
                styleName="creation__connect-via-channel"
                showContent={showContent}
                channelKind={ChannelKind.JIVO}
              />
              <ConnectionViaChannel
                styleName="creation__connect-via-channel"
                showContent={showContent}
                channelKind={ChannelKind.WZ}
              />
            </div>
          </FadeScroll>
        }
      </Section>
    </motion.div>
  );
};

export default observer(Creation);
