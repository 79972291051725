import * as React from 'react';
import { computed, makeObservable } from 'mobx';

import {
  BlocksPlugin,
  ChannelsPlugin,
  ManagersPlugin,
  AppPlugins,
  VariablesPlugin,
  ModulesPlugin,
  defaultModulesAccess,
  AuthPlugin,
  AnalyticsPlugin,
  InternationalDomainPlugin
} from 'shared/entities/app';
import {
  ChannelKind,
  channelKindEntities,
  channelKindOrder,
  ChannelKindType,
  ChannelListItem,
  channelsKindsList
} from 'shared/entities/channels';
import { IPluginsStore } from 'shared/entities/store/pluginsStore';
import {
  CabinetManagerRoleEntity,
  ProjectManagerRoleEntity
} from 'shared/entities/manager';
import { Permission } from 'shared/entities/permissions';
import { INewList } from 'shared/entities/list';
import {
  BlockComponentsMap,
  BlockConfigMap,
  BlockStaticConfigMap
} from 'shared/entities/block/constructors';
import { BlockKind } from 'shared/entities/block/common';
import { ChannelCreationComponentProps } from 'shared/entities/components/ChannelCreation';
import { IRootStore } from 'shared/entities/store/rootStore';
import { blockStaticConfigMap } from 'shared/SmartGraph/blockStaticConfigMap';
import {
  IntegrationTab,
  integrationTabsOrder,
  mapIntegrationTabToConfig
} from 'shared/entities/integrations';

export default class PluginsStore implements IPluginsStore {
  blocksPlugin: BlocksPlugin | null;
  channelsPlugin: ChannelsPlugin | null;
  managersPlugin: ManagersPlugin | null;
  variablesPlugin: VariablesPlugin | null;
  modulesPlugin: ModulesPlugin | null;
  authPlugin: AuthPlugin | null;
  analyticsPlugin: AnalyticsPlugin | null;
  internationalDomainPlugin: InternationalDomainPlugin | null;

  rootStore: IRootStore;

  constructor(rootStore: IRootStore, plugins?: AppPlugins) {
    this.variablesPlugin = plugins?.variables || null;
    this.managersPlugin = plugins?.managers || null;
    this.blocksPlugin = plugins?.blocks || null;
    this.channelsPlugin = plugins?.channels || null;
    this.modulesPlugin = plugins?.modules || null;
    this.authPlugin = plugins?.auth || null;
    this.analyticsPlugin = plugins?.analytics || null;
    this.internationalDomainPlugin = plugins?.internationalDomain || null;

    this.rootStore = rootStore;

    makeObservable(this, {
      channelsOrder: computed,
      channelConditionList: computed,
      integrationTabsOrder: computed
    });
  }

  get integrationTabsOrder(): IntegrationTab[] {
    return integrationTabsOrder.filter((integration) => {
      const config = mapIntegrationTabToConfig[integration];
      return (
        !config.onlyForSa ||
        (config.onlyForSa &&
          this.rootStore.permissionsStore.canViewUnpublishedFeatures)
      );
    });
  }

  get notConsideredRoles(): Permission[] {
    return this.managersPlugin
      ? this.managersPlugin.notConsideredPermissions
      : [];
  }

  get CabinetManagerForm():
    | React.FC<{
        cabinetManagersRoles: INewList<CabinetManagerRoleEntity>;
      }>
    | null
    | undefined {
    if (!this.managersPlugin) {
      return null;
    }

    return this.managersPlugin.cabinetManagerForm;
  }

  get ProjectManagerForm():
    | React.FC<{
        cabinetManagersRoles: INewList<CabinetManagerRoleEntity>;
        projectManagersRoles: INewList<ProjectManagerRoleEntity>;
      }>
    | null
    | undefined {
    if (!this.managersPlugin) {
      return null;
    }

    return this.managersPlugin.projectManagerForm;
  }

  get blockStaticMap(): BlockStaticConfigMap {
    if (!this.blocksPlugin) {
      return blockStaticConfigMap;
    }

    return {
      ...blockStaticConfigMap,
      ...this.blocksPlugin.blockStaticMap
    };
  }

  get extraBlockComponentsMap(): BlockComponentsMap | null {
    return this.blocksPlugin?.blockComponentsMap || null;
  }

  get extraBlockConstructorMap(): BlockConfigMap | null {
    return this.blocksPlugin?.blockConstructorMap || null;
  }

  get channelKindEntities(): Record<ChannelKindType, ChannelListItem> {
    if (!this.channelsPlugin) {
      return channelKindEntities;
    }

    return {
      ...channelKindEntities,
      ...this.channelsPlugin.extraChannels.entities
    };
  }

  get channelsOrder(): ChannelKindType[] {
    if (!this.channelsPlugin) {
      return channelKindOrder;
    }

    return [
      ...channelKindOrder.filter((channelKind) => {
        if (
          !this.channelsPlugin ||
          this.rootStore.permissionsStore.canViewUnpublishedFeatures
        ) {
          return true;
        }

        return !this.channelsPlugin.hiddenChannels.includes(channelKind);
      }),
      ...this.channelsPlugin.extraChannels.order
    ];
  }

  get channelConditionList(): ChannelListItem[] {
    if (!this.channelsPlugin) {
      return channelsKindsList;
    }

    const extraConditionList: ChannelListItem[] = [];

    this.channelsPlugin.extraChannels.order.forEach((channelKind) => {
      if (!this.channelsPlugin) {
        return;
      }

      extraConditionList.push(
        this.channelsPlugin.extraChannels.entities[channelKind]
      );
    });

    return [
      ...channelsKindsList.filter((channel) => {
        if (
          !this.channelsPlugin ||
          this.rootStore.permissionsStore.canViewUnpublishedFeatures
        ) {
          return true;
        }

        return !this.channelsPlugin.hiddenChannels.includes(
          channel.id as ChannelKind
        );
      }),
      ...extraConditionList
    ];
  }

  get extraChannelCreationComponents(): Record<
    ChannelKindType,
    React.FC<ChannelCreationComponentProps>
  > | null {
    if (!this.channelsPlugin) {
      return null;
    }

    return Object.keys(this.channelsPlugin.extraChannels.entities).reduce(
      (acc, channelKey) => {
        if (!this.channelsPlugin) {
          return acc;
        }
        const channel = this.channelsPlugin.extraChannels.entities[channelKey];

        if (!channel) {
          return acc;
        }

        return {
          ...acc,
          [channelKey]: channel.ChannelCreationComponent
        };
      },
      {}
    );
  }

  get allowProtectedVariables(): boolean {
    return !!this.variablesPlugin?.allowProtected;
  }

  get modulesAccess(): ModulesPlugin {
    return this.modulesPlugin || defaultModulesAccess;
  }

  get defaultGlobalSettings(): boolean | null {
    if (!this.blocksPlugin?.extraSettings) {
      return null;
    }

    return this.blocksPlugin.extraSettings.globalInputParam.defaultValue;
  }

  get excludeBlocks(): BlockKind[] | null {
    return this.blocksPlugin?.excludeBlocks || null;
  }

  get analyticsEnabled(): boolean {
    return this.analyticsPlugin ? this.analyticsPlugin.enabled : true;
  }

  get internationalDomainTurnOn(): boolean {
    return this.internationalDomainPlugin
      ? this.internationalDomainPlugin.turnOn
      : false;
  }

  get linkToAuth(): string | null {
    return this.authPlugin?.redirectToAuthUrl || null;
  }
}
