import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const PsbIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3334 2L8.66675 8.36822H15.3334V21.1041L22.0001 14.7514V2H15.3334Z"
        fill="#F26126"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66667 21.1041L15.3333 14.7361H8.66667V2L2 8.35286V21.1041H8.66667Z"
        fill="#FFFFFE"
      />
    </BaseIcon>
  );
};

export default PsbIcon;
