import * as React from 'react';

import {
  TypographySize,
  TypographyType,
  TypographyWeight,
  TypographyColor
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import { TranslationString } from 'shared/entities/localization';

import './ChannelTag.modules.scss';

type Props = {
  className?: string;
  children: TranslationString | string;
};

const ChannelTag: React.FC<Props> = ({ className, children }) => {
  return (
    <div styleName="tag" className={className}>
      <Typography
        fontType={TypographyType.text}
        fontSize={TypographySize.xs}
        fontColor={TypographyColor.white}
        fontWeight={TypographyWeight.heavy}
      >
        {children}
      </Typography>
    </div>
  );
};

export default React.memo(ChannelTag);
