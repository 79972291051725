import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const UserIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XM}
      svgProps={{
        viewBox: '0 0 18 21'
      }}
      {...props}
    >
      <path
        d="M17 19.5C17 18.1044 17 17.4067 16.8278 16.8389C16.44 15.5605 15.4395 14.56 14.1611 14.1722C13.5933 14 12.8956 14 11.5 14H6.5C5.10444 14 4.40665 14 3.83886 14.1722C2.56045 14.56 1.56004 15.5605 1.17224 16.8389C1 17.4067 1 18.1044 1 19.5M13.5 6C13.5 8.48528 11.4853 10.5 9 10.5C6.51472 10.5 4.5 8.48528 4.5 6C4.5 3.51472 6.51472 1.5 9 1.5C11.4853 1.5 13.5 3.51472 13.5 6Z"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default UserIcon;
