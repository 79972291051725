import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const DiceIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXL}
      svgProps={{
        viewBox: '0 0 40 40'
      }}
      {...props}
    >
      <path
        d="M11.0449 4.16645C5.31621 5.60054 2.39464 6.37097 2.28785 6.46251C2.21157 6.53116 2.10477 6.71424 2.059 6.8668C2.00561 7.04988 1.99035 10.6427 2.00561 17.9504L2.02849 28.7671L2.25733 28.9959C2.59297 29.3316 20.3893 37.8674 20.7555 37.8674C20.9157 37.8674 21.175 37.7759 21.4039 37.6386C21.6175 37.5089 25.3247 35.5637 29.6422 33.3135C33.9674 31.0632 37.5678 29.1638 37.6594 29.0875C37.7433 29.0188 37.8577 28.8358 37.9035 28.6984C38.0255 28.3628 38.0332 7.28635 37.9187 6.8668C37.873 6.71424 37.7738 6.53116 37.6899 6.45488C37.5068 6.29469 20.4046 1.99244 19.9545 2.00007C19.7867 2.0077 15.7743 2.97647 11.0449 4.16645ZM27.392 5.38695L34.448 7.14904L34.1047 7.27109C33.914 7.33212 30.8399 8.40768 27.2699 9.65106C23.6999 10.8944 20.7479 11.909 20.7097 11.909C20.5877 11.909 5.73575 7.14904 5.76626 7.11853C5.78152 7.10327 8.9777 6.28706 12.8757 5.31067C17.2695 4.21222 20.0232 3.54857 20.1453 3.57909C20.252 3.60197 23.5092 4.41818 27.392 5.38695ZM12.3646 10.9021L19.9545 13.3354L19.9774 24.6022C19.9851 30.7962 19.9698 35.8612 19.9393 35.8536C19.9012 35.8536 16.2244 34.0915 11.7543 31.9404L3.63039 28.0348L3.60751 18.0725L3.59225 8.11018L4.17962 8.29326C4.50763 8.39242 8.192 9.56715 12.3646 10.9021ZM36.3931 18.103V28.0424L29.0396 31.8717C24.9967 33.9847 21.648 35.7087 21.6022 35.7087C21.4725 35.7087 21.4802 13.3888 21.6175 13.3049C21.7319 13.2286 36.1719 8.18646 36.3016 8.17884C36.3702 8.17121 36.3931 10.2079 36.3931 18.103Z"
        fill="currentColor"
      />
      <path
        d="M19.6876 5.814C18.6807 5.92843 17.9331 6.41662 17.9331 6.94296C17.9331 7.20995 18.3679 7.61424 18.8332 7.79731C19.4435 8.02615 20.9691 8.08718 21.6632 7.91173C22.3345 7.73629 22.7464 7.49982 22.9219 7.17181C23.0516 6.92771 23.0592 6.86668 22.9676 6.6531C22.8609 6.38611 22.4032 6.08099 21.8768 5.9208C21.4268 5.79112 20.3588 5.73772 19.6876 5.814Z"
        fill="currentColor"
      />
      <path
        d="M14.0275 16.1808C12.9443 16.7529 13.1579 18.843 14.4165 20.0177C15.2938 20.8263 16.1558 20.895 16.766 20.2008C17.0864 19.8347 17.2008 19.3083 17.0864 18.6371C16.8118 16.8978 15.1717 15.5782 14.0275 16.1808Z"
        fill="currentColor"
      />
      <path
        d="M10.1524 19.0946C9.67186 19.3463 9.4659 19.7659 9.4659 20.4905C9.4659 22.7942 11.9603 24.5029 12.8833 22.8324C13.3333 22.0238 12.9672 20.5439 12.0976 19.6591C11.4568 18.9954 10.7398 18.7895 10.1524 19.0946Z"
        fill="currentColor"
      />
      <path
        d="M6.36126 21.8942C5.46114 22.4586 5.63659 24.358 6.68164 25.4794C7.52073 26.3795 8.60392 26.4329 9.03872 25.6014C9.26757 25.1666 9.25994 24.1673 9.01584 23.5647C8.78699 22.9774 8.32168 22.3747 7.86399 22.062C7.36817 21.7187 6.74266 21.6577 6.36126 21.8942Z"
        fill="currentColor"
      />
      <path
        d="M27.3157 17.4927C26.2401 18.019 25.4315 19.5828 25.6451 20.7117C25.7824 21.4593 26.1486 21.8331 26.797 21.8865C28.3836 22.0161 29.9245 19.4073 29.1159 17.9656C28.765 17.3325 28.0327 17.1418 27.3157 17.4927Z"
        fill="currentColor"
      />
      <path
        d="M32.007 20.8644C31.2441 21.2153 30.5271 22.2603 30.3669 23.2443C30.1762 24.358 30.6873 25.1819 31.5645 25.1819C32.5028 25.1743 33.5707 23.9919 33.8148 22.6951C34.0208 21.5814 33.5021 20.6813 32.6553 20.6813C32.5104 20.6813 32.2205 20.7652 32.007 20.8644Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DiceIcon;
