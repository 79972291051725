import { IField } from '../form';
import { TelegramPaymentKind } from '../telegramPayments';
import { ITgChannelModel } from '../channels';
import { SelectorEntities } from '../components/Selector';
import { ILoadingStageModel } from '../loadingStage';
import { TinkoffTaxation } from '../tinkoffPayment';

import { UserPaymentMethodKind, VatCode } from './server';

export interface IUserPaymentMethod {
  id: string;
  externalId: string;
  title: string;
  dateCreated: Date;
  isEnabled: boolean;
  isTest: boolean;
  vatCode: VatCode;
}

export interface IUserPaymentMethodModel extends IUserPaymentMethod {
  readonly removingStage: ILoadingStageModel;
  remove(): Promise<BaseResponse>;
  changeTitle(value: string): void;
  updateVerboseName(): Promise<void>;
  updateVatCode(code: VatCode): Promise<void>;
}

export interface ICommonUserPaymentMethodModel extends IUserPaymentMethodModel {
  providerKind:
    | UserPaymentMethodKind.qiwi
    | UserPaymentMethodKind.yookassa
    | UserPaymentMethodKind.prodamus;
}

export interface ITelegramPaymentMethodModel extends IUserPaymentMethodModel {
  readonly providerKind: UserPaymentMethodKind.tg;
  readonly tgPaymentProvider: {
    tgPaymentProviderKind: TelegramPaymentKind;
    tgChannelId: string;
  };
  readonly channel: ITgChannelModel | null;
}

export interface IYooMoneyPaymentMethodModel extends IUserPaymentMethodModel {
  readonly providerKind: UserPaymentMethodKind.yooMoney;
  readonly callbackUrl: IField<string | null>;
  readonly secret: IField;
  readonly updatingSecretStage: ILoadingStageModel;
  readonly gettingCallbackUrlStage: ILoadingStageModel;

  updateSecret(): Promise<BaseResponse>;
  getCallbackUrl(): Promise<BaseResponse>;
}

export interface ITinkoffPaymentMethodModel extends IUserPaymentMethodModel {
  readonly providerKind: UserPaymentMethodKind.tinkoff;
  readonly taxation: IField<TinkoffTaxation>;

  updateTaxation(taxation: TinkoffTaxation): Promise<void>;
}

export const mapVatCodeSelectorOption: SelectorEntities<VatCode> = {
  [VatCode.noVat]: {
    id: VatCode.noVat,
    title: (t) =>
      t('userPayment.vatCode.noVat', {
        ns: 'entities'
      })
  },
  [VatCode.vat0]: {
    id: VatCode.vat0,
    title: (t) =>
      t('userPayment.vatCode.vat0', {
        ns: 'entities'
      })
  },
  [VatCode.vat10]: {
    id: VatCode.vat10,
    title: (t) =>
      t('userPayment.vatCode.vat10', {
        ns: 'entities'
      })
  },
  [VatCode.vat18]: {
    id: VatCode.vat18,
    title: (t) =>
      t('userPayment.vatCode.vat18', {
        ns: 'entities'
      })
  },
  [VatCode.vat20]: {
    id: VatCode.vat20,
    title: (t) =>
      t('userPayment.vatCode.vat20', {
        ns: 'entities'
      })
  },
  [VatCode.vat10_110]: {
    id: VatCode.vat10_110,
    title: (t) =>
      t('userPayment.vatCode.vat10_110', {
        ns: 'entities'
      })
  },
  [VatCode.vat18_118]: {
    id: VatCode.vat18_118,
    title: (t) =>
      t('userPayment.vatCode.vat18_118', {
        ns: 'entities'
      })
  },
  [VatCode.vat20_120]: {
    id: VatCode.vat20_120,
    title: (t) =>
      t('userPayment.vatCode.vat20_120', {
        ns: 'entities'
      })
  }
};

export const orderVatCodeSelectorOptions = [
  VatCode.noVat,
  VatCode.vat0,
  VatCode.vat10,
  VatCode.vat18,
  VatCode.vat20,
  VatCode.vat10_110,
  VatCode.vat18_118,
  VatCode.vat20_120
];

export const orderVatCodeSelectorYookassaOrTinkoffOptions = [
  VatCode.noVat,
  VatCode.vat0,
  VatCode.vat10,
  VatCode.vat20,
  VatCode.vat10_110,
  VatCode.vat20_120
];
