import {
  SetVarDateOperationServer,
  SetVarNumberOperationServer
} from 'shared/entities/varOperation';
import { pluralizeIntervalUnit } from 'shared/entities/timeout';
import { convertStringToNumber } from 'shared/entities/common/utils';

import { VarType } from '../server';
import { TFunctionType } from '../../localization';

import { getDateVarActualName } from './getVarActualName';

export const getNumberVarOperationActualName = ({
  arg1,
  arg2,
  op
}: SetVarNumberOperationServer): string => {
  return `${arg1} ${op} ${arg2}`;
};

export const getDateVarOperationActualName = (
  { arg1, arg2, op, unit }: SetVarDateOperationServer,
  t: TFunctionType
): string => {
  const dateString = getDateVarActualName(arg1, VarType.datetime);
  const arg2Number = convertStringToNumber(arg2);
  return `${dateString} ${op} ${arg2} ${pluralizeIntervalUnit(
    arg2Number,
    unit,
    true,
    t
  )}`;
};
