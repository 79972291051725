import { action, computed, observable, makeObservable } from 'mobx';

import { IEdit } from 'shared/entities/edit';
import { IEditStore } from 'shared/entities/store/editStore';
import {
  Validator,
  FormErrors,
  ValidatorResult
} from 'shared/entities/validator';

export default class BaseEditStore<Entity extends IEdit>
  implements IEditStore<Entity>
{
  entity: Entity;

  initialData: Entity;

  errors: FormErrors = {};

  validators: Record<string, Validator<any>[]> = {};

  commonError: ValidatorResult;

  touched = false;

  constructor({ initialData }: { initialData: Entity }) {
    this.initialData = initialData.getCopy() as Entity;
    this.entity = initialData;

    makeObservable(this, {
      entity: observable,
      initialData: observable,
      errors: observable,
      validators: observable,
      touched: observable,
      isError: computed,
      setField: action,
      validateField: action,
      resetError: action,
      validateFields: action,
      save: action,
      commonError: observable,
      reset: action,
      setCommonError: action
    });
  }

  get isError(): boolean {
    const hasErrors = Object.values(this.errors).some((e) => Boolean(e));

    const isFieldsFilled = !Object.values(this.entity).some((field) => !field);

    return (
      hasErrors ||
      (!this.touched && !hasErrors && !isFieldsFilled) ||
      !!this.commonError
    );
  }

  setField(fieldName: string, value: any): any {
    this.touched = true;

    this.resetError(fieldName);
    this.entity[fieldName] = value;
  }

  validateField(fieldName: string): boolean {
    const validators = this.validators[fieldName];

    if (!validators) {
      return false;
    }

    let validatorResult: ValidatorResult = null;

    for (const validator of validators) {
      validatorResult = validator(this.entity[fieldName]);

      this.setError(fieldName, validatorResult);
      if (validatorResult) {
        return !!validatorResult;
      }
    }
    return validatorResult !== null;
  }

  setError(fieldName: string, error: ValidatorResult): void {
    this.errors[fieldName] = error;
  }

  setCommonError(error: ValidatorResult): void {
    this.commonError = error;
  }

  resetError(fieldName: string): void {
    this.errors[fieldName] = null;
    this.commonError = null;
  }

  resetErrors(): void {
    Object.keys(this.entity).forEach((key) => {
      this.resetError(key);
    });
  }

  validateFields(): void {
    Object.keys(this.entity).forEach((entityKey: string) => {
      this.validateField(entityKey);
    });
  }

  reset(): void {
    if (this.initialData) {
      this.entity = this.initialData.getCopy() as Entity;
    }

    this.resetErrors();
  }

  save(): Entity | null {
    this.validateFields();

    if (!this.isError) {
      return this.entity;
    }

    return null;
  }
}
