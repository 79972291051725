import { makeObservable, observable } from 'mobx';

import { IEdit } from 'shared/entities/edit';
import {
  IScenarioEdit,
  ScenarioBaseParamsServer,
  ScenarioKind
} from 'shared/entities/scenario';

export default class ScenarioEditModel implements IEdit, IScenarioEdit {
  name: string;

  channelIds: Array<string> | null;

  id: string | null;

  kind: ScenarioKind;

  constructor(
    { name, channelIds, id, kind }: IScenarioEdit = {
      name: '',
      channelIds: null,
      id: null,
      kind: ScenarioKind.common
    }
  ) {
    this.name = name;
    this.channelIds = channelIds;
    this.id = id;
    this.kind = kind;

    makeObservable(this, {
      name: observable,
      channelIds: observable,
      id: observable
    });
  }

  toJson(): Partial<ScenarioBaseParamsServer> {
    return {
      name: this.name,
      channel_ids: this.channelIds,
      kind: this.kind
    };
  }

  getCopy(): ScenarioEditModel {
    return new ScenarioEditModel({
      name: this.name,
      channelIds: this.channelIds,
      id: this.id,
      kind: this.kind
    });
  }
}
