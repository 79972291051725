import { IField } from '../../form';
import { SelectorEntities, SelectorOptions } from '../../components/Selector';
import { TFunctionType } from '../../localization';

import { ProductOptionType } from './server';

export const MAX_PRODUCT_OPTIONS_AMOUNT = 10;

export type ColorProductOptionValue = {
  colorName: string; // Название цвета
  colorCode: string; // HEX-код цвета
};

export type ColorProductOptionField = {
  colorName: IField<ColorProductOptionValue['colorName']>;
  colorCode: IField<ColorProductOptionValue['colorCode']>;
};

export type OtherProductOptionValue = string;

export type OtherProductOptionField = IField;

export const productOptionTypesOrder: ProductOptionType[] = [
  ProductOptionType.color,
  ProductOptionType.other
];

export const productOptionSelectorEntities: SelectorEntities<ProductOptionType> =
  productOptionTypesOrder.reduce(
    (acc, productOptionType): SelectorEntities<ProductOptionType> => ({
      ...acc,
      [productOptionType]: {
        id: productOptionType,
        title: (t: TFunctionType): string =>
          t(`shopback.options.optionTypeName.${productOptionType}`, {
            ns: 'entities'
          })
      }
    }),
    {} as SelectorEntities<ProductOptionType>
  );

export const typeOptionSelectorOptions: SelectorOptions = {
  keys: productOptionTypesOrder,
  entities: productOptionSelectorEntities
};

export enum ProductOptionModelType {
  // Характеристика, у которой можно менять всё и которую можно изменять, удалять
  definable = 'definable',

  // Характеристика, у которой можно менять только значение
  editable = 'editable'
}

export interface IOptionValueModel {
  isError: boolean;
  validate(): boolean;
}

/**
 * Интерфейс модели характеристики товара.
 * serverId имеется всегда, так как, если модель
 * ещё не сохранена на сервере, то это DefinableProductOptionModel
 * @see DefinableProductOptionModel
 */
export interface IProductOptionModel {
  readonly title: string;
  readonly serverId: number;
  readonly optionId: number; // Служебное поле для бэка
  readonly type: ProductOptionType;
  readonly value: string;
  readonly extraValue: string | null;
}

export type ProductOptionModelParams = {
  title: string;
  serverId: number;
  optionId: number;
  type: ProductOptionType;
  value: string;
  extraValue: string | null;
};

export type DefinableProductOptionDefaultCreatingParams = {
  optionType?: ProductOptionType;
  title?: string;
};
