import { GoogleAuthInfoServer } from './server';
import { GoogleAuthInfo } from './client';

export const normalizeGoogleAuthInfo = (
  raw: GoogleAuthInfoServer
): GoogleAuthInfo => {
  return {
    dateCreated: new Date(raw.date_created),
    ...raw
  };
};
