import { makeObservable, observable } from 'mobx';

import {
  CabinetManagerRoleType,
  ICabinetManagerAddingEdit
} from 'shared/entities/manager';
import { IEdit } from 'shared/entities/edit';

export default class CabinetManagerAddingEditModel
  implements ICabinetManagerAddingEdit, IEdit
{
  email: string;
  role: CabinetManagerRoleType;

  constructor({
    email,
    role
  }: {
    email: string;
    role: CabinetManagerRoleType;
  }) {
    this.email = email;
    this.role = role;

    makeObservable(this, {
      email: observable,
      role: observable
    });
  }

  toJson(): { cabinet_role: CabinetManagerRoleType; email: string } {
    return {
      email: this.email,
      cabinet_role: this.role
    };
  }

  getCopy(): CabinetManagerAddingEditModel {
    return new CabinetManagerAddingEditModel({
      email: this.email,
      role: this.role
    });
  }
}
