import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { configure } from 'mobx';

import { IAuthFlowStore } from 'shared/entities/store/authFlowStore';
import { SettingsStore } from 'stores/settingsStore';
import DomainsStore from 'stores/integrationsStore/domainsStore';
import GoogleStore from 'stores/integrationsStore/googleStore';
import ApiIntegrationStore from 'stores/integrationsStore/apiIntegrationStore';
import GetCourseStore from 'stores/integrationsStore/getCourseStore';
import UserPaymentsStore from 'stores/integrationsStore/userPaymentsStore';
import QiwiStore from 'stores/integrationsStore/qiwiStore';
import YookassaStore from 'stores/integrationsStore/yookassaStore';
import YooMoneyStore from 'stores/integrationsStore/yooMoneyStore';
import TelegramPaymentsStore from 'stores/integrationsStore/telegramPaymentsStore';
import ProdamusStore from 'stores/integrationsStore/prodamusStore';
import AmoCRMStore from 'stores/integrationsStore/amoCRMStore';
import { TranslationsStore } from 'stores/translationsStore';
import TinkoffStore from 'stores/integrationsStore/TinkoffStore';
import { SmartbotAIBotsStore } from 'stores/smartbotAIBotsStore';

import { SupportPopupStore } from './supportPopupStore';
import { CabinetManagersStore } from './managers/cabinetManagersStore';
import { PluginsStore } from './pluginsStore';
import { NetworkStore } from './networkStore';
import { CabinetStore } from './cabinetStore';
import { ChannelsStore } from './channelsStore';
import { JobsStore } from './jobsStore';
import { ChatsStore } from './chatsStore';
import { ListsStore } from './listsStore';
import { UserStore } from './userStore';
import { CabinetsStore } from './cabinetsStore';
import { ProjectsStore } from './projectsStore';
import { VariablesStore } from './variablesStore';
import { RootStore } from './rootStore';
import { ProjectStore } from './projectStore';
import { UiStore } from './uiStore';
import { ABStore } from './aBStore';
import { AppNotificationsStore } from './appNotificationsStore';
import { PermissionsStore } from './permissionsStore';
import { RouterStore } from './routerStore';
import { AnalyticsStore } from './analyticsStore';
import { PaymentStore } from './paymentStore';
import { WebSocketStore } from './webSocketStore';
import { KeyboardEventsStore } from './KeyboardEventsStore';
import { SmartbotAIInfoStore } from './smartbotAIInfoStore';

configure({
  computedRequiresReaction: true
});

const useStores = (): RootStore => {
  // @ts-ignore
  return useContext<{ store: RootStore }>(MobXProviderContext).store;
};

export const useRootStore = (): RootStore => {
  return useStores();
};

export const useVariablesStore = (): VariablesStore => {
  const store = useStores();

  return store.variablesStore;
};

export const useUserStore = (): UserStore => {
  const store = useStores();

  return store.userStore;
};

export const useCabinetsStore = (): CabinetsStore => {
  const store = useStores();

  return store.cabinetsStore;
};

export const useProjectsStore = (): ProjectsStore => {
  const store = useStores();

  return store.projectsStore;
};

export const useCabinetStore = (): CabinetStore => {
  const store = useStores();

  return store.cabinetStore;
};

export const useProjectStore = (): ProjectStore => {
  const store = useStores();

  return store.projectStore;
};

export const useAuthFlowStore = (): IAuthFlowStore => {
  const store = useStores();

  return store.authFlowStore;
};

export const useChannelsStore = (): ChannelsStore => {
  const store = useStores();

  return store.channelsStore;
};

export const useJobsStore = (): JobsStore => {
  const store = useStores();

  return store.jobsStore;
};

export const useChatsStore = (): ChatsStore => {
  const store = useStores();

  return store.chatsStore;
};

export const useListsStore = (): ListsStore => {
  const store = useStores();

  return store.listsStore;
};

export const useUiStore = (): UiStore => {
  const store = useStores();

  return store.uiStore;
};

export const useABStore = (): ABStore => {
  const store = useStores();

  return store.aBStore;
};

export const useSupportPopupStore = (): SupportPopupStore => {
  const store = useStores();

  return store.supportPopupStore;
};

export const useSettingsStore = (): SettingsStore => {
  const store = useStores();

  return store.settingsStore;
};

export const useAppNotificationsStore = (): AppNotificationsStore => {
  const store = useStores();
  return store.appNotificationsStore;
};

export const usePermissionsStore = (): PermissionsStore => {
  const store = useStores();

  return store.permissionsStore;
};

export const useRouterStore = (): RouterStore => {
  const store = useStores();

  return store.routerStore;
};

export const usePluginsStore = (): PluginsStore => {
  const store = useStores();

  return store.pluginsStore;
};

export const useNetworkStore = (): NetworkStore => {
  const store = useStores();

  return store.networkStore;
};

export const useCabinetManagersStore = (): CabinetManagersStore => {
  const store = useStores();

  return store.cabinetManagersStore;
};

export const useAnalyticsStore = (): AnalyticsStore => {
  const store = useStores();

  return store.analyticsStore;
};

export const usePaymentStore = (): PaymentStore => {
  const store = useStores();

  return store.paymentStore;
};

export const useUserPaymentsStore = (): UserPaymentsStore => {
  const store = useStores();

  return store.integrationsStore.userPaymentsStore;
};

export const useQiwiStore = (): QiwiStore => {
  const store = useStores();

  return store.integrationsStore.qiwiStore;
};

export const useYookassaStore = (): YookassaStore => {
  const store = useStores();

  return store.integrationsStore.yookassaStore;
};

export const useYoomoneyStore = (): YooMoneyStore => {
  const store = useStores();

  return store.integrationsStore.yooMoneyStore;
};

export const useTelegramPaymentsStore = (): TelegramPaymentsStore => {
  const store = useStores();

  return store.integrationsStore.telegramPaymentsStore;
};

export const useTinkoffStore = (): TinkoffStore => {
  const store = useStores();

  return store.integrationsStore.tinkoffStore;
};

export const useDomainsStore = (): DomainsStore => {
  const store = useStores();

  return store.integrationsStore.domainsStore;
};

export const useGoogleStore = (): GoogleStore => {
  const store = useStores();

  return store.integrationsStore.googleStore;
};

export const useApiIntegrationStore = (): ApiIntegrationStore => {
  const store = useStores();

  return store.integrationsStore.apiIntegrationStore;
};

export const useGetCourseStore = (): GetCourseStore => {
  const store = useStores();

  return store.integrationsStore.getCourseStore;
};

export const useAmoCRMStore = (): AmoCRMStore => {
  const store = useStores();

  return store.integrationsStore.amoCRMStore;
};

export const useProdamusStore = (): ProdamusStore => {
  const store = useStores();

  return store.integrationsStore.prodamusStore;
};

export const useWebsocketStore = (): WebSocketStore => {
  const store = useStores();

  return store.websocketStore;
};

export const useTranslationsStore = (): TranslationsStore => {
  const store = useStores();

  return store.translationsStore;
};

export const useKeyboardEventsStore = (): KeyboardEventsStore => {
  const store = useStores();

  return store.keyboardEventsStore;
};

export const useSmartbotAIBotsStore = (): SmartbotAIBotsStore => {
  const store = useStores();

  return store.smartbotAIBotsStore;
};

export const useSmartbotAIInfoStore = (): SmartbotAIInfoStore => {
  const store = useStores();

  return store.smartbotAIInfoStore;
};
