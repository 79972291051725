export enum WindowSize {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l'
}

export const mapSizeToLimit = {
  [WindowSize.xxs]: 0,
  [WindowSize.xs]: 768,
  [WindowSize.s]: 1280,
  [WindowSize.m]: 1440,
  [WindowSize.l]: 1920
};

export const windowSizesOrder = [
  WindowSize.xxs,
  WindowSize.xs,
  WindowSize.s,
  WindowSize.m,
  WindowSize.l
];
