import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const UserInfoIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M12.1533 1.66777C5.96748 1.58422 0.917472 6.63423 1.00102 12.8201C1.08351 18.7527 5.91407 23.5832 11.8466 23.6657C18.0336 23.7503 23.0825 18.7003 22.9979 12.5144C22.9165 6.58082 18.0859 1.75026 12.1533 1.66777ZM18.8384 18.9726C18.8173 18.9954 18.7915 19.0133 18.7628 19.025C18.734 19.0367 18.7031 19.0419 18.6721 19.0403C18.6411 19.0386 18.6108 19.0302 18.5835 19.0156C18.5561 19.001 18.5323 18.9806 18.5137 18.9557C18.0407 18.3369 17.4615 17.8071 16.803 17.391C15.4567 16.5269 13.7508 16.051 12 16.051C10.2491 16.051 8.54324 16.5269 7.19693 17.391C6.53851 17.8069 5.95928 18.3365 5.48627 18.9552C5.46769 18.98 5.44387 19.0005 5.4165 19.0151C5.38913 19.0297 5.35888 19.0381 5.3279 19.0397C5.29691 19.0413 5.26595 19.0361 5.23721 19.0244C5.20847 19.0127 5.18266 18.9949 5.16159 18.9721C3.60993 17.2971 2.73098 15.1083 2.69317 12.8254C2.60698 7.67966 6.82942 3.37261 11.9772 3.35992C17.1251 3.34723 21.3068 7.52736 21.3068 12.6667C21.3086 15.0048 20.4269 17.2571 18.8384 18.9726Z"
        fill="currentColor"
      />
      <path
        d="M12 6.74414C10.9572 6.74414 10.0144 7.13492 9.34439 7.8451C8.67441 8.55527 8.33968 9.53724 8.4153 10.5911C8.56865 12.6667 10.1767 14.3588 12 14.3588C13.8233 14.3588 15.4282 12.6667 15.5847 10.5917C15.663 9.54782 15.3309 8.57483 14.6498 7.85144C13.9772 7.13757 13.0359 6.74414 12 6.74414Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default UserInfoIcon;
