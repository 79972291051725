import { action, makeObservable, observable } from 'mobx';

import { ChatModel } from 'shared/models/chat';
import { apiUrls } from 'shared/entities/domain';
import { ChatRealmType, ChatServer } from 'shared/entities/chats/server';
import { IChatsStore } from 'shared/entities/store/chatsStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStage } from 'shared/entities/meta';
import { normalizeCollection } from 'shared/entities/collection';
import ListModel from 'shared/models/ListModel';

export default class ChatsStore implements IChatsStore {
  readonly chats: Map<string, ListModel<ChatModel>> = new Map<
    string,
    ListModel<ChatModel>
  >();

  readonly groupChats: ListModel<ChatModel> = new ListModel<ChatModel>();

  readonly rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      chats: observable,
      rootStore: observable,

      loadChannelChats: action,
      getChannelChats: action
    });
  }

  getChannelChats(channelId: string): ListModel<ChatModel> {
    const chats = this.chats.get(channelId);
    if (chats) {
      return chats;
    }

    const newList = new ListModel<ChatModel>();
    this.chats.set(channelId, newList);

    return newList;
  }

  async loadChannelChats(
    channelId: string
  ): Promise<BaseResponse<{ list: ListModel<ChatModel> }>> {
    const list = this.getChannelChats(channelId);

    if (list) {
      if (list.loadingStage === LoadingStage.LOADING) {
        return {
          isError: true
        };
      }

      if (list.loadingStage === LoadingStage.SUCCESS) {
        return {
          isError: false,
          data: { list }
        };
      }
    }

    list.setLoadingStage(LoadingStage.LOADING);

    const response = await this.load(channelId);

    if (response.isError) {
      list.setLoadingStage(LoadingStage.ERROR);

      return {
        isError: true
      };
    }

    list.addEntities({ ...response.data, initial: true });
    list.setLoadingStage(LoadingStage.SUCCESS);

    return {
      isError: false,
      data: {
        list
      }
    };
  }

  async loadGroupChats(): Promise<
    BaseResponse<{ list: ListModel<ChatModel> }>
  > {
    if (this.groupChats.loadingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this.groupChats.setLoadingStage(LoadingStage.LOADING);

    const response = await this.load();

    if (response.isError) {
      this.groupChats.setLoadingStage(LoadingStage.ERROR);

      return {
        isError: true
      };
    }

    this.groupChats.addEntities({ ...response.data, initial: true });
    this.groupChats.setLoadingStage(LoadingStage.SUCCESS);

    return {
      isError: false,
      data: {
        list: this.groupChats
      }
    };
  }

  async load(
    channelId?: string
  ): Promise<
    BaseResponse<{ keys: string[]; entities: Record<string, ChatModel> }>
  > {
    const data = channelId
      ? { channel_id: channelId }
      : { realm: ChatRealmType.chat };

    const response = await this.rootStore.networkStore.api<{
      chats: ChatServer[];
    }>(apiUrls.CHATS_LIST, {
      method: 'GET',
      data
    });

    if (!response.isError) {
      const { collection } = normalizeCollection(
        response.data.chats,
        ChatModel.fromJson,
        '_id'
      );

      return {
        isError: false,
        data: collection
      };
    } else {
      return {
        isError: true
      };
    }
  }

  getActualChats = async (
    channelId: string,
    includeRealm: ChatRealmType[],
    withUnlinkedChats: boolean
  ): Promise<
    BaseResponse<{ keys: string[]; entities: Record<string, ChatModel> }>
  > => {
    let chats = this.getChannelChats(channelId);

    const response = await this.loadChannelChats(channelId);
    if (!response.isError) {
      chats = response.data.list;
    } else {
      return {
        isError: true
      };
    }

    if (!chats.length) {
      return {
        isError: false,
        data: { keys: [], entities: {} }
      };
    }

    const chatOptions = chats.items.reduce(
      (
        acc: { keys: string[]; entities: Record<string, ChatModel> },
        item: ChatModel
      ) => {
        if (
          includeRealm.includes(item.realm) &&
          (withUnlinkedChats || item.isEnabled)
        ) {
          acc.keys.push(item.id);
          acc.entities[item.id] = item;
        }
        return acc;
      },
      { keys: [], entities: {} }
    );

    return {
      isError: false,
      data: chatOptions
    };
  };
}
