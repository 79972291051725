import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import { IRootStore } from 'shared/entities/store/rootStore';
import { CabinetModel } from 'shared/models/cabinet';
import { ICabinetStore } from 'shared/entities/store/cabinetStore';
import { apiUrls, AuthStage, urls } from 'shared/entities/domain';
import { LoadingStage } from 'shared/entities/meta';
import { CabinetServer, ICabinetModel } from 'shared/entities/cabinet';
import {
  BaseResponseCode,
  BaseResponseCodeType
} from 'shared/entities/network';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { DocumentsStore } from 'stores/documentsStore';
import { ServiceActDocumentModel } from 'shared/models/documents';
import { ServiceDocumentStage } from 'shared/entities/documents';

export default class CabinetStore implements ICabinetStore {
  private _rootStore: IRootStore;

  private _entity: CabinetModel | null = null;

  private _removingStage: LoadingStage = LoadingStage.NOT_STARTED;

  private _documentsStore: DocumentsStore;

  readonly loadingStage: LoadingStageModel = new LoadingStageModel();

  constructor(rootStore: IRootStore) {
    makeObservable<
      CabinetStore,
      '_entity' | '_removingStage' | '_documentsStore'
    >(this, {
      _entity: observable,
      _removingStage: observable,
      _documentsStore: observable,

      load: action,
      remove: action.bound,
      reset: action,

      entity: computed,
      removingStage: computed,
      initialized: computed,
      documentsStore: computed,
      hasDocuments: computed,
      isLegalEntity: computed
    });
    this._rootStore = rootStore;
    this._documentsStore = new DocumentsStore({ rootStore });
  }

  get documentsStore(): DocumentsStore {
    return this._documentsStore;
  }

  get hasDocuments(): boolean {
    return !this._documentsStore.documents.isEmpty;
  }

  get initialized(): boolean {
    return this._entity !== null && this.loadingStage.successfullyLoaded;
  }

  get entity(): CabinetModel | null {
    return this._entity;
  }

  get removingStage(): LoadingStage {
    return this._removingStage;
  }

  get isLegalEntity(): boolean {
    if (!this._documentsStore.serviceActDocuments.length) {
      return false;
    }

    const lastServiceActDocument = this._documentsStore.serviceActDocuments[0];

    if (
      lastServiceActDocument instanceof ServiceActDocumentModel &&
      lastServiceActDocument.params.stage !== ServiceDocumentStage.close
    ) {
      return true;
    }

    return false;
  }

  async load(
    cabinetDomain: string
  ): Promise<
    BaseResponse<ICabinetModel, { code: BaseResponseCodeType | null }>
  > {
    this.loadingStage.loading();

    const response = await this._rootStore.networkStore.api<{
      cabinet: CabinetServer;
    }>(apiUrls.CABINETS_GET_BY_DOMAIN, {
      method: 'GET',
      data: {
        domain: cabinetDomain,
        ...this._rootStore.routerStore.utmParams
      },
      expectedErrorCodes: [BaseResponseCode.notFound]
    });

    if (!response.isError) {
      const cabinet = CabinetModel.fromJson(
        response.data.cabinet,
        this._rootStore
      );
      runInAction(async () => {
        this._entity = cabinet;
      });

      this.loadingStage.success();
      if (this._rootStore.permissionsStore.canViewBilling) {
        this._documentsStore.init(cabinet.id);
      }

      return {
        isError: false,
        data: cabinet
      };
    } else {
      this.loadingStage.error();

      return {
        isError: true,
        data: {
          code: response.data?.code || null
        }
      };
    }
  }

  async remove(): Promise<BaseResponse> {
    if (this._removingStage === LoadingStage.LOADING || !this._entity) {
      return {
        isError: true
      };
    }

    this._removingStage = LoadingStage.LOADING;

    const { isError } = await this._rootStore.networkStore.api(
      apiUrls.CABINETS_DELETE,
      {
        method: 'POST'
      }
    );

    if (!isError) {
      const cabinet = this._rootStore.cabinetsStore.cabinets.items.find(
        (cabinet) => cabinet.id !== this._entity?.id
      );

      if (cabinet) {
        runInAction(() => {
          this._removingStage = LoadingStage.SUCCESS;
          this.reset();
        });

        this._rootStore.routerStore.changeSubDomain({
          subDomain: cabinet.domain
        });

        return {
          isError: false
        };
      }

      const response = await this._rootStore.cabinetsStore.load();

      if (!response.isError && response.data.length) {
        runInAction(() => {
          this._removingStage = LoadingStage.SUCCESS;
          this.reset();
        });

        this._rootStore.routerStore.changeSubDomain({
          subDomain: response.data[0].domain
        });

        return {
          isError: false
        };
      }

      this._rootStore.routerStore.changeToAuthDomain({
        path: urls.AUTH.create(AuthStage.CABINET_CREATION)
      });

      return {
        isError: true
      };
    }

    runInAction(() => {
      this._removingStage = LoadingStage.ERROR;
    });

    return {
      isError: true
    };
  }

  reset = (): void => {
    this._entity = null;
    this.loadingStage.reset();
    this._removingStage = LoadingStage.NOT_STARTED;
  };
}
