import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const LiqPayIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.8583 21.3517L2.58059 21.1138C1.90477 20.5389 1.78518 19.4883 2.4012 18.8143L9.63297 10.5677C10.2089 9.89369 11.2615 9.77477 11.9373 10.3893L12.2151 10.6272C12.8909 11.202 13.0097 12.2527 12.3945 12.9267L5.16268 21.1733C4.58679 21.8473 3.57346 21.9266 2.8583 21.3517Z"
        fill="url(#paint0_linear_5092_1573)"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.65888 11.9949L8.52041 11.8363L7.40796 13.105L7.52675 13.2438C9.43382 15.1469 8.61954 17.0697 8.18211 17.7437L8.34103 17.5653C8.59987 17.268 8.99718 16.812 9.41415 16.3164C9.85079 15.5631 10.4668 13.779 8.65888 11.9949Z"
        fill="url(#paint1_linear_5092_1573)"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0436 14.4729L11.341 14.1359C11.3615 14.0963 11.4008 14.0566 11.4205 14.017C11.1026 14.3738 10.7651 14.7505 10.4473 15.1271C10.6856 14.8693 10.8847 14.6513 11.0436 14.4729Z"
        fill="url(#paint2_radial_5092_1573)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4338 10.8254L5.2028 2.5788C4.62611 1.9048 3.57345 1.78586 2.89842 2.40039L2.61991 2.63827C1.94409 3.21315 1.8253 4.26381 2.44133 4.9378L8.54018 11.8364L8.67943 11.995C10.4866 13.7989 9.87135 15.583 9.41425 16.3363C9.77143 15.9201 10.1294 15.5037 10.4669 15.1271C10.7848 14.7505 11.1231 14.3738 11.4409 14.017C11.7588 13.6601 12.0561 13.3232 12.2552 13.1051C12.9507 12.4311 13.0498 11.4994 12.4338 10.8254Z"
        fill="url(#paint3_linear_5092_1573)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0364 21.3517L11.7587 21.1138C11.0829 20.5389 10.9641 19.4883 11.5793 18.8143L18.8111 10.5677C19.387 9.89369 20.4397 9.77477 21.1155 10.3893L21.3932 10.6272C22.069 11.202 22.1878 12.2527 21.5726 12.9267L14.3408 21.1733C13.7649 21.8473 12.7516 21.9266 12.0364 21.3517Z"
        fill="url(#paint4_linear_5092_1573)"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9167 11.9157L17.7782 11.7571L16.6658 13.0258L16.7846 13.1646C18.6916 15.0676 17.8774 16.9905 17.4399 17.6645L17.5988 17.4861C17.8577 17.1888 18.255 16.7328 18.672 16.2372C19.1086 15.4839 19.7246 13.6998 17.9167 11.9157Z"
        fill="url(#paint5_linear_5092_1573)"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2013 14.4729L20.4995 14.1359C20.5191 14.0963 20.5592 14.0566 20.5789 14.017C20.2611 14.3738 19.9236 14.7505 19.6057 15.1271C19.8441 14.8693 20.0628 14.6513 20.2013 14.4729Z"
        fill="url(#paint6_radial_5092_1573)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5922 10.8254L14.3605 2.5788C13.7846 1.9048 12.7319 1.78586 12.0561 2.40039L11.7784 2.63827C11.1025 3.21315 10.9837 4.26381 11.5998 4.9378L17.6986 11.8364L17.8371 11.995C19.645 13.7989 19.029 15.583 18.5727 16.3363C18.9299 15.9201 19.2879 15.5037 19.6254 15.1271C19.9432 14.7505 20.2807 14.3738 20.5986 14.017C20.9164 13.6601 21.2146 13.3232 21.4136 13.1051C22.1083 12.4311 22.2083 11.4994 21.5922 10.8254Z"
        fill="url(#paint7_linear_5092_1573)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5092_1573"
          x1="5.11705"
          y1="23.9256"
          x2="15.1441"
          y2="19.0533"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1FADC3" />
          <stop offset="0.7072" stopColor="#36B98F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5092_1573"
          x1="8.64079"
          y1="16.1271"
          x2="10.5164"
          y2="15.8534"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#123F06" stopOpacity="0.01" />
          <stop offset="1" stopColor="#123F06" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_5092_1573"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.05875 15.5035) scale(4.84376 5.52383)"
        >
          <stop offset="0.4185" stopColor="#123F06" stopOpacity="0.01" />
          <stop offset="1" stopColor="#123F06" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_5092_1573"
          x1="-1.74653"
          y1="12.9276"
          x2="10.4189"
          y2="17.1406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9FDB57" />
          <stop offset="1" stopColor="#71CA5E" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5092_1573"
          x1="14.2865"
          y1="23.9306"
          x2="24.3136"
          y2="19.0583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1FADC3" />
          <stop offset="0.7072" stopColor="#36B98F" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5092_1573"
          x1="17.8986"
          y1="16.0479"
          x2="19.7742"
          y2="15.7742"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#123F06" stopOpacity="0.01" />
          <stop offset="1" stopColor="#123F06" />
        </linearGradient>
        <radialGradient
          id="paint6_radial_5092_1573"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.2251 15.5035) scale(4.84376 5.52383)"
        >
          <stop offset="0.4185" stopColor="#123F06" stopOpacity="0.01" />
          <stop offset="1" stopColor="#123F06" />
        </radialGradient>
        <linearGradient
          id="paint7_linear_5092_1573"
          x1="7.41743"
          y1="12.9312"
          x2="19.5829"
          y2="17.1442"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9FDB57" />
          <stop offset="1" stopColor="#71CA5E" />
        </linearGradient>
      </defs>
    </BaseIcon>
  );
};

export default LiqPayIcon;
