import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const EmptyEnvelopeIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXLM}
      svgProps={{
        viewBox: '0 0 48 37'
      }}
      {...props}
    >
      <path
        d="M47 13.6653L36.854 2.24866C36.367 1.47048 35.656 1 34.907 1H13.093C12.344 1 11.633 1.47048 11.146 2.24767L1 13.6663V22.8367H47V13.6653Z"
        stroke="#DBDEF9"
      />
      <path
        d="M33.613 16.8126C33.613 15.2195 34.607 13.9043 35.84 13.9033H47V31.9057C47 34.0129 45.68 35.74 44.05 35.74H3.95C2.32 35.74 1 34.0119 1 31.9057V13.9033H12.16C13.393 13.9033 14.387 15.2165 14.387 16.8096V16.8314C14.387 18.4245 15.392 19.7109 16.624 19.7109H31.376C32.608 19.7109 33.613 18.4126 33.613 16.8195V16.8126Z"
        fill="#F5F3FF"
        stroke="#DBDEF9"
      />
    </BaseIcon>
  );
};

export default EmptyEnvelopeIcon;
