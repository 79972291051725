import {
  ChannelBaseServer,
  ChannelEnabledServer,
  ChannelKind,
  ExternalChannelKind
} from './server';
import { IChannelBasic, IChannelEnabled } from './client';

export const normalizeBaseChannel = (
  raw: ChannelBaseServer
): Omit<IChannelBasic, 'tokens'> => ({
  id: raw._id,
  name: raw.name,
  domain: raw.domain,
  externalId: raw.external_id,
  usersTotal: raw.users_total,
  url: raw.url,
  photoUrl: raw.extra?.photo_url || null,
  rootChatId: raw.root_chat_id
});

export const normalizeChannelEnabled = (
  raw: ChannelEnabledServer
): Omit<IChannelEnabled, 'tokens'> => ({
  enabled: raw.enabled,
  kind: raw.kind,
  ...normalizeBaseChannel(raw)
});

export const mapExternalChannelToChannelKind: Record<
  ExternalChannelKind,
  ChannelKind
> = {
  [ExternalChannelKind.VK]: ChannelKind.VK,
  [ExternalChannelKind.FB]: ChannelKind.FB,
  [ExternalChannelKind.IG]: ChannelKind.IG,
  [ExternalChannelKind.VB]: ChannelKind.VB,
  [ExternalChannelKind.WHATSAPP]: ChannelKind.WHATSAPP,
  [ExternalChannelKind.AVITO]: ChannelKind.AVITO,
  [ExternalChannelKind.OK]: ChannelKind.OK,
  [ExternalChannelKind.ALIEXPRESS]: ChannelKind.ALIEXPRESS,
  [ExternalChannelKind.TELEGRAM]: ChannelKind.TELEGRAM,
  [ExternalChannelKind.TELEGRAM_PERSONAL]: ChannelKind.TELEGRAM_PERSONAL,
  [ExternalChannelKind.WABA]: ChannelKind.WABA
};
