const createUrl = (url: string): string => `/api/${url}`;

export const apiUrls = {
  AUTH_REGISTER: createUrl('auth/register'),
  AUTH_LOGIN: createUrl('auth/login'),
  AUTH_INFO: createUrl('auth/info'),
  AUTH_LOGOUT: createUrl('auth/logout'),
  AUTH_ACCEPT_INVITATION: createUrl('auth/accept_invitation'),
  AUTH_REQUEST_PASSWORD_RECOVER: createUrl('auth/request_password_recover'),
  AUTH_PASSWORD_RECOVER: createUrl('auth/password_recover'),
  AUTH_EMAIL_UNSUBSCRIBE: createUrl('auth/email_unsubscribe'),
  AUTH_OBTAIN_WS_AUTH_TOKEN: createUrl('auth/obtain_ws_auth_token'),
  AUTH_UPDATE_REFS: createUrl('auth/update_refs'),

  CABINETS_LIST: createUrl('cabinets/list'),
  CABINETS_CREATE: createUrl('cabinets/create'),
  CABINETS_GET_BY_DOMAIN: createUrl('cabinets/get_by_domain'),
  CABINETS_DELETE: createUrl('cabinets/delete'),
  CABINETS_UPDATE: createUrl('cabinets/update'),
  CABINETS_GENERATE_DOMAIN_NAME: createUrl('cabinets/generate_domain_name'),

  PROJECTS_LIST: createUrl('projects/list'),
  PROJECTS_CREATE: createUrl('projects/create'),
  PROJECTS_GET: createUrl('projects/get'),
  PROJECTS_PUBLISH: createUrl('projects/publish'),
  PROJECTS_RESET: createUrl('projects/reset'),
  PROJECTS_TOGGLE: createUrl('projects/toggle'),
  PROJECTS_LIST_SETTINGS: createUrl('projects/list_settings'),
  PROJECTS_SET_SETTINGS: createUrl('projects/set_settings'),
  PROJECTS_DELETE: createUrl('projects/delete'),
  PROJECTS_UPDATE: createUrl('projects/update'),

  CHANNELS_LIST: createUrl('channels/list'),
  CHANNELS_TG_CREATE: createUrl('channels/add_tg'),
  CHANNELS_VB_CREATE: createUrl('channels/add_vb'),
  CHANNELS_VK_CREATE: createUrl('channels/add_vk'),
  CHANNELS_DOM_CREATE: createUrl('channels/add_domrf'),
  CHANNELS_REMOVE: createUrl('channels/remove'),
  CHANNELS_UPDATE: createUrl('channels/update'),
  CHANNELS_LIST_VALID_CHANNELS: createUrl('channels/list_valid_channels'),
  CHANNELS_FB_PAGES: createUrl('channels/list_fb_pages'),
  CHANNELS_ADD_FB: createUrl('channels/add_fb'),
  CHANNELS_ADD_IG: createUrl('channels/add_ig'),
  CHANNELS_IG_ACCOUNTS: createUrl('channels/list_ig_accounts'),
  CHANNELS_ADD_TG_MENU: createUrl('channels/add_tg_menu'),
  CHANNELS_ADD_TOKEN: createUrl('channels/add_token'),
  CHANNELS_REMOVE_TOKEN: createUrl('channels/remove_token'),
  CHANNELS_GET_JV_WEBHOOK_URL: createUrl('channels/get_jv_webhook_url'),
  CHANNELS_ADD_JV: createUrl('channels/add_jv'),
  CHANNELS_ADD_WZ: createUrl('channels/add_wz'),

  SCENARIOS_LIST: createUrl('scenarios/list'),
  SCENARIOS_CREATE: createUrl('scenarios/create'),
  SCENARIOS_UPDATE: createUrl('scenarios/update'),
  SCENARIOS_COPY: createUrl('scenarios/copy'), //TODO
  SCENARIOS_GET: createUrl('scenarios/get'),
  SCENARIOS_ENABLE: createUrl('scenarios/enable'),
  SCENARIOS_DELETE: createUrl('scenarios/delete'),
  SCENARIOS_LIST_SETTINGS: createUrl('scenarios/list_settings'),

  TEMPLATES_LIST: createUrl('templates/list'),
  TEMPLATES_APPLY: createUrl('templates/apply'),

  BLOCKS_LIST: createUrl('blocks/list'),
  BLOCKS_CREATE: createUrl('blocks/create'),
  BLOCKS_UPDATE: createUrl('blocks/update'),
  BLOCKS_GET: createUrl('blocks/get'),
  BLOCKS_GET_EXECUTE_BLOCK_DATA: createUrl('blocks/get_execute_block_data'),
  BLOCKS_COPY: createUrl('blocks/copy'),

  VARS_LIST: createUrl('vars/list'),
  VARS_LIST_SPECIAL: createUrl('vars/list_special'),
  VARS_CREATE: createUrl('vars/create'),
  VARS_UPDATE: createUrl('vars/update'),
  VARS_DELETE: createUrl('vars/delete'),
  VARS_CHECK_SQ: createUrl('vars/check_sq_syntax'),
  VARS_RENDER_TEXT: createUrl('vars/render_text'),

  CLUSTERING_LIST: createUrl('clustering/list_clusters'),
  CLUSTERING_UPLOAD_TEXTS: createUrl('clustering/upload_texts'),
  CLUSTERING_LIST_TEXTS: createUrl('clustering/list_texts'),
  CLUSTERING_REMOVE_TEXTS: createUrl('clustering/remove_texts'),
  CLUSTERING_REMOVE_CLUSTER: createUrl('clustering/remove_cluster'),
  CLUSTERING_ADD_TEXT: createUrl('clustering/add_text'),
  CLUSTERING_INPUTS_LIST: createUrl('clustering/list_inputs'),
  CLUSTERING_REMOVE_ALL: createUrl('clustering/remove_all'),

  JOBS_START: createUrl('jobs/start'),
  JOBS_STATUS: createUrl('jobs/get_status'),
  JOBS_CANCEL: createUrl('jobs/cancel'),

  CHATS_LIST: createUrl('chats/list'),

  USERS_LIST: createUrl('users/list'),
  USERS_DOWNLOAD_CSV: createUrl('users/download_xlsx'),
  USERS_UPLOAD_XLSX: createUrl('users/upload_xlsx'),
  USERS_ADD: createUrl('users/add'),
  USERS_UPLOAD_PLAIN: createUrl('users/upload_plain'),
  USERS_ADD_USERS_TO_LISTS: createUrl('users/add_to_lists'),
  USERS_DELETE: createUrl('users/delete'),
  USERS_REMOVE_USERS_FROM_LISTS: createUrl('users/remove_from_lists'),
  USERS_GET: createUrl('users/get'),
  USERS_GET_USER_VARS: createUrl('users/get_user_vars'),
  USERS_GET_USER_LISTS: createUrl('users/get_user_lists'),
  USERS_GET_USER_CHATS: createUrl('users/get_user_chats'),
  USERS_UPDATE_VAR: createUrl('users/set_var'),
  USERS_PLANNED_EVENTS: createUrl('users/get_user_planned_events'),
  USERS_CANCEL_PLANNED_EVENT: createUrl('users/cancel_user_planned_event'),
  USERS_GET_SAMPLE_FILE: createUrl('users/get_sample_file'),

  LISTS_LIST: createUrl('lists/list'),
  LISTS_GET: createUrl('lists/get'),
  LISTS_CREATE: createUrl('lists/create'),
  LISTS_REMOVE: createUrl('lists/delete'),
  LISTS_RENAME: createUrl('lists/rename'),

  MANAGERS_CABINET_LIST: createUrl('managers/list_by_cabinet'),
  MANAGERS_PROJECT_LIST: createUrl('managers/list_by_project'),
  MANAGERS_CREATE: createUrl('managers/create'),
  MANAGERS_ADD: createUrl('managers/add'),
  MANAGERS_REMOVE: createUrl('managers/remove'),
  MANAGERS_CHANGE_CABINET_ROLE: createUrl('managers/change_cabinet_role'),
  MANAGERS_CHANGE_PROJECT_ROLE: createUrl('managers/change_project_role'),
  MANAGERS_OBTAIN_TOKEN: createUrl('managers/obtain_token'),
  MANAGERS_UPDATE_INFO: createUrl('managers/update_information'),
  MANAGERS_LIST_ROLES: createUrl('managers/list_roles'),
  MANAGERS_UNLINK_FROM_CHANNEL: createUrl('managers/unlink_from_channel'),

  ATTACHMENTS_LIST: createUrl('attachments/list'),
  ATTACHMENTS_UPLOAD: createUrl('attachments/upload'),
  ATTACHMENTS_REMOVE: createUrl('attachments/delete'),
  ATTACHMENTS_RENAME: createUrl('attachments/rename'),
  ATTACHMENTS_UPLOAD_BY_VK_URL: createUrl('attachments/upload_by_vk_url'),

  ATTACHMENTS_BLOCK_LIST: createUrl('attachments/list_by_block'),
  ATTACHMENTS_BLOCK_ADD: createUrl('attachments/add_to_block'),
  ATTACHMENTS_BLOCK_REMOVE: createUrl('attachments/remove_from_block'),
  ATTACHMENTS_BLOCK_LIST_BLOCKS: createUrl(
    'attachments/list_blocks_by_attachment'
  ),

  STATS_LABELS_LIST: createUrl('stats/list_labels'),
  STATS_LIST: createUrl('stats/list_stats'),
  STATS_LIST_CUSTOM_LABELS: createUrl('stats/list_custom_labels'),
  STATS_ADD_CUSTOM_LABEL: createUrl('stats/create_custom_label'),
  STATS_DOWNLOAD: createUrl('stats/download'),

  OAUTH_UPDATE_MANAGER: createUrl('oauth/update_manager'),
  OAUTH_VK_GET_URL: createUrl('oauth/vk/get_url'),
  OAUTH_VK_GET_VK_GROUPS: createUrl('channels/list_vk_groups'),
  OAUTH_VK_GROUP_GET_URL: createUrl('oauth/vk_group/get_url'),

  OAUTH_GOOGLE_GET_URL: createUrl('oauth/google/get_url'),
  OAUTH_GOOGLE_GET_TOKEN: createUrl('oauth/google/get_token'),
  OAUTH_GOOGLE_REVOKE_TOKEN: createUrl('oauth/google/revoke_token'),
  OAUTH_GOOGLE_DUPLICATE_TOKEN: createUrl('oauth/google/duplicate_token'),
  OAUTH_FB_GET_URL: createUrl('oauth/fb/get_url'),
  OAUTH_IG_GET_URL: createUrl('oauth/ig/get_url'),
  OAUTH_TOKEN_REVOKE: createUrl('oauth/token_revoke'),
  OAUTH_TG_LOGIN_REDIRECT: createUrl('oauth/tg/login_redirect'),
  OAUTH_YOOKASSA_GET_URL: createUrl('oauth/yookassa/get_url'),
  OAUTH_QIWI_OBTAIN_TOKEN: createUrl('oauth/qiwi/obtain_token'),
  OAUTH_YOOMONEY_GET_URL: createUrl('oauth/yoomoney/get_url'),
  OAUTH_YOOMONEY_UPDATE_SECRET: createUrl('oauth/yoomoney/update_secret'),
  OAUTH_YOOMONEY_GET_CALLBACK_URL: createUrl('oauth/yoomoney/get_callback_url'),
  OAUTH_TG_OBTAIN_TOKEN: createUrl('oauth/tg/obtain_token'),
  OAUTH_PRODAMUS_OBTAIN_SECRET: createUrl('oauth/prodamus/obtain_secret'),
  OAUTH_PRODAMUS_GET_CALLBACK_URL: createUrl('oauth/prodamus/get_callback_url'),
  OAUTH_TINKOFF_OBTAIN_TOKEN: createUrl('oauth/tinkoff/obtain_terminal_key'),
  OAUTH_LIST_LOGIN_URLS: createUrl('oauth/list_login_urls'),

  AMOCRM_ACCOUNTS_LIST: createUrl('amocrm/list'),
  AMOCRM_PIPELINES_LIST: createUrl('amocrm/list_pipelines'),
  AMOCRM_USERS_LIST: createUrl('amocrm/list_users'),
  AMOCRM_GET_TOKEN: createUrl('amocrm/get_token'),
  AMOCRM_ATTACH_WAZZUP: createUrl('amocrm/attach_wazzup'),
  AMOCRM_DETACH_WAZZUP: createUrl('amocrm/detach_wazzup'),

  GOOGLE_GET_TABLE: createUrl('google/get_table'),

  VERIFIED_DOMAINS_CHECK: createUrl('verified_domains/check'),
  VERIFIED_DOMAINS_LIST: createUrl('verified_domains/list'),
  VERIFIED_DOMAINS_ADD: createUrl('verified_domains/add'),
  VERIFIED_DOMAINS_CHECK_URL: createUrl('verified_domains/check_url'),
  VERIFIED_DOMAINS_DELETE: createUrl('verified_domains/delete'),

  MAILINGS_PLAN: createUrl('mailings/plan'),
  MAILINGS_TEST: createUrl('mailings/test'),
  MAILINGS_CANCEL: createUrl('mailings/cancel'),
  MAILINGS_ESTIMATE_REACH: createUrl('mailings/estimate_reach'),
  MAILINGS_GET_STATUS: createUrl('mailings/get_status'),

  PAYMENTS_GET_INFO: createUrl('payments/get_info'),
  PAYMENTS_PAY: createUrl('payments/pay'),
  PAYMENTS_CALCULATE: createUrl('payments/calculate'),
  PAYMENTS_GET_PRICES: createUrl('payments/get_prices'),
  PAYMENTS_REMOVE_METHODS: createUrl('payments/remove_method'),

  USER_CHAT_STATES_RUN_FROM_BLOCK: createUrl('user_chat_states/run_from_block'),

  USER_PAYMENTS_METHODS_LIST: createUrl('users_payments/methods/list'),
  USER_PAYMENTS_METHODS_DELETE: createUrl('users_payments/methods/delete'),
  USERS_PAYMENTS_METHODS_UPDATE: createUrl('users_payments/methods/update'),

  PUBLIC_API_TOKENS_LIST: createUrl('public_api_tokens/list'),
  PUBLIC_API_TOKENS_REVOKE: createUrl('public_api_tokens/revoke'),
  PUBLIC_API_TOKENS_CREATE: createUrl('public_api_tokens/create'),

  GET_COURSE_TOKENS_CREATE: createUrl('getcourse_tokens/create'),
  GET_COURSE_TOKENS_LIST: createUrl('getcourse_tokens/list'),
  GET_COURSE_TOKENS_REMOVE: createUrl('getcourse_tokens/remove'),
  GET_COURSE_GROUPS_LIST: createUrl('getcourse/list_groups'),

  VK_LIST_MARKET_ITEMS: createUrl('vk/list_market_items'),

  CONVERSATIONS_LIST: createUrl('conversations/list'),
  CONVERSATIONS_LIST_MESSAGES: createUrl('conversations/list_messages'),
  CONVERSATIONS_SEND_MESSAGE: createUrl('conversations/send_message'),
  CONVERSATIONS_START_BOT: createUrl('conversations/start_bot'),
  CONVERSATIONS_STOP_BOT: createUrl('conversations/stop_bot'),
  CONVERSATIONS_GET_CONVERSATION: createUrl('conversations/get_conversation'),
  CONVERSATIONS_GET_CONVERSATION_LITE: createUrl(
    'conversations/get_conversation_lite'
  ),
  CONVERSATIONS_OBTAIN_SUBSCRIPTION_TOKEN: createUrl(
    'conversations/obtain_subscription_token'
  ),

  SHOPBACK_OBTAIN_TOKEN: createUrl('shopback/obtain_token'),
  SHOPBACK_PROJECTS_SCENARIOS: createUrl('shopback/projects_scenarios'),

  PAYMENT_DOCUMENTS_LIST: createUrl('payment_documents/list'),
  PAYMENT_DOCUMENTS_SET_REQUISITES: createUrl(
    'payment_documents/set_requisites'
  ),
  PAYMENT_DOCUMENTS_CREATE: createUrl('payment_documents/create'),
  PAYMENT_DOCUMENTS_REMOVE: createUrl('payment_documents/remove'),
  PAYMENT_DOCUMENTS_NOTIFY_MANAGERS: createUrl(
    'payment_documents/notify_managers'
  ),

  CARROTQUEST_GET_AUTH_INFO: createUrl('carrotquest/get_auth_info'),

  AI_BOTS_LIST: createUrl('ai/bots/list'),
  AI_BOTS_CREATE: createUrl('ai/bots/create'),
  AI_BOTS_UPDATE: createUrl('ai/bots/update'),
  AI_BOTS_GET: createUrl('ai/bots/get'),
  AI_BOTS_DELETE: createUrl('ai/bots/delete'),
  AI_BOTS_INFO: createUrl('ai/bots/info'),
  AI_DEMO_CREATE: createUrl('ai/demo/create'),
  AI_DEMO_ADD_MESSAGE: createUrl('ai/demo/add_message'),
  AI_UPLOAD_DOC: createUrl('ai/vectorized_ai/upload_doc'),
  AI_DELETE_DOC: createUrl('ai/vectorized_ai/delete_doc'), // удаление дока с сайта
  AI_AGGREGATED_DOCS_LIST: createUrl('ai/vectorized_ai/aggregated_docs/list'),
  AI_AGGREGATED_DOCS_POLL: createUrl('ai/vectorized_ai/aggregated_docs/poll'),
  AI_LIST_DOCS: createUrl('ai/vectorized_ai/list_docs'),
  AI_AGGREGATED_DOCS_DELETE: createUrl(
    'ai/vectorized_ai/aggregated_docs/delete'
  ),
  AI_INDEX_SITE: createUrl('ai/vectorized_ai/index_site')
};
