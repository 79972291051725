import { computed, makeObservable } from 'mobx';

import { AmoCRMAccountServer, IAmoCRMAccount } from 'shared/entities/amocrm';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import { LoadingStage } from 'shared/entities/meta';
import {
  ValidatorResult,
  validatorTransFunctions
} from 'shared/entities/validator';
import { AppNotificationType } from 'shared/entities/appNotifications';

import { LoadingStageModel } from '../loadingStage';
import { FieldModel } from '../form';

import { AmoCRMBaseAccountModel } from './AmoCRMBaseAccountModel';

export class AmoCRMAccountModel
  extends AmoCRMBaseAccountModel
  implements IAmoCRMAccount
{
  readonly wazzupChannelId: FieldModel<string | null>;
  readonly attachingWazzupStage: LoadingStageModel = new LoadingStageModel();
  readonly detachingWazzupStage: LoadingStageModel = new LoadingStageModel();
  private rootStore: IRootStore;

  constructor({
    wazzupChannelId,
    rootStore,
    ...params
  }: {
    id: string;
    accountName: string;
    wazzupChannelId: string | null;
    rootStore: IRootStore;
  }) {
    super(params);

    this.rootStore = rootStore;
    this.wazzupChannelId = new FieldModel(wazzupChannelId);

    makeObservable(this, {
      error: computed
    });
  }

  get title(): string {
    return this.accountName;
  }

  get wazzupChannelIdSelected(): boolean {
    return !!this.wazzupChannelId.value;
  }

  get wazzupChannelIdAttached(): boolean {
    return !!this.wazzupChannelId.initialValue && !this.wazzupChannelId.touched;
  }

  get loadingStage(): LoadingStage {
    return this.wazzupChannelIdAttached
      ? this.detachingWazzupStage.value
      : this.attachingWazzupStage.value;
  }

  get error(): ValidatorResult {
    if (!this.wazzupChannelIdAttached && !this.wazzupChannelIdSelected) {
      return validatorTransFunctions.requiredField;
    }
    return null;
  }

  changeWazzupChannelId = async (): Promise<BaseResponse> => {
    if (!this.wazzupChannelIdAttached) {
      if (!this.wazzupChannelIdSelected) {
        return { isError: false };
      }

      return this.attachWazzup();
    }

    return this.detachWazzup();
  };

  private attachWazzup = async (): Promise<BaseResponse> => {
    if (this.attachingWazzupStage.isLoading) {
      return {
        isError: true
      };
    }

    this.attachingWazzupStage.loading();

    const response = await this.rootStore.networkStore.api(
      apiUrls.AMOCRM_ATTACH_WAZZUP,
      {
        method: 'POST',
        data: {
          _id: this.id,
          channel_id: this.wazzupChannelId.value
        }
      }
    );

    if (response.isError) {
      this.attachingWazzupStage.error();
    } else {
      this.wazzupChannelId.resetTouched();
      this.rootStore.appNotificationsStore.open({
        type: AppNotificationType.success,
        title: (t) =>
          t('amoCRM.amoCRMAccountModel.notifications.attachWazzup', {
            ns: 'models'
          })
      });
      this.attachingWazzupStage.success();
    }

    return response;
  };

  private detachWazzup = async (): Promise<BaseResponse> => {
    if (
      this.detachingWazzupStage.isLoading ||
      !this.wazzupChannelId.initialValue
    ) {
      return {
        isError: true
      };
    }

    this.detachingWazzupStage.loading();

    const response = await this.rootStore.networkStore.api(
      apiUrls.AMOCRM_DETACH_WAZZUP,
      {
        method: 'POST',
        data: {
          _id: this.id,
          channel_id: this.wazzupChannelId.initialValue
        }
      }
    );

    if (response.isError) {
      this.detachingWazzupStage.error();
    } else {
      this.wazzupChannelId.changeValue(null);
      this.wazzupChannelId.resetTouched();
      this.rootStore.appNotificationsStore.open({
        type: AppNotificationType.success,
        title: (t) =>
          t('amoCRM.amoCRMAccountModel.notifications.detachWazzup', {
            ns: 'models'
          })
      });
      this.detachingWazzupStage.success();
    }

    return response;
  };

  static fromJson(
    raw: AmoCRMAccountServer,
    rootStore: IRootStore
  ): AmoCRMAccountModel {
    return new AmoCRMAccountModel({
      accountName: raw.account_name,
      id: raw._id,
      wazzupChannelId: raw.wz_channel_id ?? null,
      rootStore
    });
  }
}
