import * as React from 'react';
import { Trans } from 'react-i18next';
import { ReactElement } from 'react';

import {
  NameSpace,
  TranslationKey,
  useTypedTranslation,
  TransChild
} from 'shared/entities/localization';

interface TypedTransComponentProps<N extends NameSpace> {
  ns: N;
  i18nKey: TranslationKey<N>;
  prefix?: string;
  components?: TypedComponents;
  count?: number;
  children?: TransChild | TransChild[];
}

type TypedComponents =
  | React.ReactNode[]
  | {
      [tagName: string]: React.ReactNode;
      bold?: React.ReactNode;
      Link?: React.ReactNode;
    };

const TypedTransComponent = <N extends NameSpace>({
  i18nKey,
  ns,
  components,
  prefix,
  count,
  children
}: TypedTransComponentProps<N>): ReactElement<TypedTransComponentProps<N>> => {
  // компонент Trans не реагирует на изменение языка, хук - реагирует
  useTypedTranslation();

  return (
    <Trans
      ns={ns}
      prefix={prefix}
      i18nKey={i18nKey as any}
      count={count}
      // @ts-ignore
      components={components}
    >
      {children}
    </Trans>
  );
};

export default TypedTransComponent;
