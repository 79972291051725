import {
  ActAndSFStatus,
  PaymentDocumentKind,
  PaymentDocumentSubject,
  ServiceActDocumentServer,
  ICommonDocument,
  normalizeCommonDocument
} from 'shared/entities/documents';

import { ServiceDocumentCommonParamsTableModel } from './ServiceDocumentCommonParamsTableModel';
import { BaseDocumentModel } from './BaseDocumentModel';

export class ServiceActDocumentModel extends BaseDocumentModel {
  readonly status: ActAndSFStatus;
  readonly kind: PaymentDocumentKind.act = PaymentDocumentKind.act;
  readonly subject: PaymentDocumentSubject.service =
    PaymentDocumentSubject.service;
  readonly params: ServiceDocumentCommonParamsTableModel;

  constructor({
    params,
    status,
    ...rest
  }: ICommonDocument & {
    params: ServiceDocumentCommonParamsTableModel;
    status: ActAndSFStatus;
    cabinetId: string;
  }) {
    super(rest);
    this.status = status;
    this.params = params;
  }

  static fromJson(
    raw: ServiceActDocumentServer,
    cabinetId: string
  ): ServiceActDocumentModel {
    return new ServiceActDocumentModel({
      ...normalizeCommonDocument(raw),
      status: raw.status,
      params: ServiceDocumentCommonParamsTableModel.fromJson(raw.params),
      cabinetId
    });
  }
}
