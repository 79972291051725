import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const CheckIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      svgProps={{ viewBox: '0 0 24 24' }}
      iconSize={IconSize.L}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.15455 15.7568L5.28864 11.8909L4 13.1795L9.15455 18.3341L20.2 7.28864L18.9114 6L9.15455 15.7568Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CheckIcon;
