export enum JobKind {
  clusterTexts = 'cluster_texts',
  fetchVkAudience = 'fetch_vk_audience'
}

export enum JobStatus {
  planned = 'planned',
  started = 'started',
  inProgress = 'in_progress',
  finished = 'finished',
  cancelled = 'cancelled',
  cancelling = 'cancelling',
  failed = 'failed'
}

export type JobResultServer = {
  message: string;
  data: any;
};

export type JobParamsServer = Record<string, any>;

export type JobServer = {
  key: string;
  kind: JobKind;
  progress: number;
  result: JobResultServer | null;
  params: JobParamsServer | null;
  started_at: string;
  finished_at: string | null;
  date_created: string;
  // если нет статуса, то job-ы такой нет
  status: JobStatus | null;
  trace_id: string | null;
};
