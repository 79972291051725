import { computed, makeObservable } from 'mobx';

import { FieldModel } from 'shared/models/form';
import { validateIsEmpty } from 'shared/entities/validator';
import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { apiUrls } from 'shared/entities/domain';
import {
  ChannelTgServer,
  CreateChannelError,
  mapCreateChannelErrorToMessage
} from 'shared/entities/channels';
import { TgChannelModel } from 'shared/models/channel';

export class TelegramChannelCreationStore {
  readonly token: FieldModel = new FieldModel<string>('', [validateIsEmpty]);
  readonly creationStage: LoadingStageModel = new LoadingStageModel();
  readonly rootStore: IRootStore;

  constructor({ rootStore }: { rootStore: IRootStore }) {
    this.rootStore = rootStore;

    makeObservable(this, {
      isError: computed
    });
  }

  get isError(): boolean {
    return this.token.isError;
  }

  validate(): boolean {
    this.token.validate();

    return this.isError;
  }

  reset(): void {
    this.token.reset();
  }

  create = async (): Promise<BaseResponse> => {
    if (this.creationStage.isLoading) {
      return {
        isError: true
      };
    }

    const isError = this.validate();

    if (isError) {
      return {
        isError: true
      };
    }

    this.creationStage.loading();

    const response = await this.rootStore.networkStore.api<
      {
        channel: ChannelTgServer;
      },
      CreateChannelError
    >(apiUrls.CHANNELS_TG_CREATE, {
      method: 'POST',
      data: {
        token: this.token.value
      },
      errorsMap: mapCreateChannelErrorToMessage,
      showExpectedError: false
    });

    if (response.isError) {
      this.creationStage.error();

      if (
        response.data?.code &&
        Object.values(CreateChannelError).includes(response.data?.code)
      ) {
        this.token.setError(mapCreateChannelErrorToMessage(response.data.code));
      }

      return {
        isError: true
      };
    }

    const channel = TgChannelModel.fromJson(
      response.data.channel,
      this.rootStore
    );
    this.rootStore.channelsStore.addCreatedChannel(channel);

    this.reset();
    this.creationStage.success();

    return {
      isError: false
    };
  };
}
