import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const EcompayIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M21.6569 10.1332C21.8311 10.2553 21.9499 10.4414 21.9874 10.6508C22.0249 10.8603 21.978 11.076 21.8569 11.251L16.816 18.5C16.5792 18.839 16.2639 19.1157 15.8971 19.3066C15.5302 19.4974 15.1227 19.5966 14.7092 19.5959H2L7.93132 11.0674L9.30115 13.0345L6.28754 17.3549H14.3284C14.3818 17.3565 14.4348 17.3448 14.4826 17.3209C14.5304 17.297 14.5716 17.2616 14.6024 17.2179L19.6077 9.99072H21.1994C21.3628 9.99069 21.5224 10.0404 21.6569 10.1332V10.1332Z"
        fill="currentColor"
      />
      <path
        d="M21.372 4.14163C21.3899 4.19871 21.3899 4.25989 21.372 4.31697C21.3088 4.54151 21.1997 4.75049 21.0515 4.93065C20.6981 5.42653 20.3556 5.93884 20.0022 6.44841L13.8599 15.3276C13.8067 15.405 13.7388 15.4711 13.6599 15.5221C13.5792 15.5756 13.4887 15.6126 13.3936 15.6311C13.2985 15.6496 13.2007 15.6492 13.1058 15.6298C13.0109 15.6105 12.9208 15.5726 12.8405 15.5184C12.7602 15.4642 12.6914 15.3947 12.638 15.3139C10.6737 12.3441 10.271 11.7359 9.98059 11.3003C9.69019 10.8647 9.50937 10.6017 9.47649 10.3853C9.46335 10.2813 9.48261 10.1757 9.53163 10.0831C9.58066 9.99042 9.65708 9.91513 9.75046 9.86749C9.95593 9.73324 10.2546 9.63462 13.0682 10.788C14.8763 9.16066 16.6544 7.53605 18.3749 5.80734C18.8299 5.31277 19.3348 4.86658 19.8817 4.47587C20.0725 4.35198 20.2721 4.2421 20.4789 4.14711C20.6754 4.03355 20.9022 3.98377 21.1282 4.00465C21.2323 4.02931 21.3391 4.06492 21.372 4.14163Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default EcompayIcon;
