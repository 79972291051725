import { makeObservable, observable } from 'mobx';

import { IEdit } from 'shared/entities/edit';

export default class StatLabelEditModel implements IEdit {
  title: string;

  constructor({ title }: { title: string }) {
    this.title = title;

    makeObservable(this, {
      title: observable
    });
  }

  toJson() {
    return {
      title: this.title
    };
  }

  getCopy(): StatLabelEditModel {
    return new StatLabelEditModel({
      title: this.title
    });
  }
}
