import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const GreatBritainIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <g clipPath="url(#clip0_5586_1962)">
        <path d="M3 5H21V19H3V5Z" fill="#012169" />
        <path
          d="M5.10937 5L11.9719 10.2792L18.8062 5H21V6.80833L14.25 12.0292L21 17.2208V19H18.75L12 13.7792L5.27812 19H3V17.25L9.72187 12.0583L3 6.86667V5H5.10937Z"
          fill="white"
        />
        <path
          d="M14.925 13.1958L21 17.8333V19L13.3781 13.1958H14.925ZM9.75 13.7792L9.91875 14.8L4.51875 19H3L9.75 13.7792ZM21 5V5.0875L13.9969 10.5708L14.0531 9.2875L19.5937 5H21ZM3 5L9.72187 10.1333H8.03437L3 6.225V5Z"
          fill="#C8102E"
        />
        <path
          d="M9.77812 5V19H14.2781V5H9.77812ZM3 9.66667V14.3333H21V9.66667H3Z"
          fill="white"
        />
        <path
          d="M3 10.6292V13.4292H21V10.6292H3ZM10.6781 5V19H13.3781V5H10.6781Z"
          fill="#C8102E"
        />
      </g>
      <defs>
        <clipPath id="clip0_5586_1962">
          <rect
            width="18"
            height="14"
            fill="white"
            transform="translate(3 5)"
          />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export default GreatBritainIcon;
