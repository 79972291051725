import * as React from 'react';
import _ from 'lodash';
import { TOptions, TFunction } from 'i18next';

import { translationNameSpaces } from 'localization/translations';
import { GreatBritainIcon, RussiaIcon } from 'shared/components/icons';
import { CommonIconProps } from 'shared/entities/components/Icon';

export const defaultNameSpace: NameSpace = 'authPage';
export type DefaultNameSpace = typeof defaultNameSpace;
export type SupportedLocale = 'en' | 'ru';

export const defaultLanguage: SupportedLocale = 'ru';
export const fallbackLanguage: SupportedLocale = 'en';
export const keySeparator = '.';

export type NameSpace = keyof typeof translationNameSpaces;
type LoadedResources = { [locale in SupportedLocale]: Translation };

type Translation = { [key: string]: string | Translation };

type Translations = {
  [nameSpace in NameSpace]: Translation;
};

type I18nResource = {
  [locale in SupportedLocale]: Translations;
};

const adaptLoadedResources = () => {
  const flatNameSpaces = Object.entries(translationNameSpaces) as [
    NameSpace,
    LoadedResources
  ][];
  const flatResources = flatNameSpaces.map((nameSpace) => {
    const locales = Object.entries(nameSpace[1]) as [
      SupportedLocale,
      Translation
    ][];
    return locales.reduce<I18nResource>((accumulator, locale) => {
      accumulator[locale[0]] = {
        [`${nameSpace[0]}`]: locale[1]
      } as Translations;
      return accumulator;
    }, {} as I18nResource);
  });

  return _.spread(_.partial(_.merge, {}))(flatResources);
};

type AllLoadedNameSpaceType<N extends NameSpace> =
  (typeof translationNameSpaces)[N];
type AllLoadedNameSpaceTypeByLanguage<N extends NameSpace> =
  AllLoadedNameSpaceType<N>[typeof defaultLanguage];

export type RecursiveKeyOf<TObj extends Record<string, unknown>> = {
  [TKey in keyof TObj & (string | number)]: TObj[TKey] extends unknown[]
    ? // eslint-disable-next-line prettier/prettier
      `${TKey}`
    : TObj[TKey] extends Record<string, unknown>
    ? // @ts-ignore
      `${TKey}${typeof keySeparator}${RecursiveKeyOf<TObj[TKey]>}`
    : `${TKey extends `${infer B}_${
        | 'zero'
        | 'one'
        | 'two'
        | 'few'
        | 'many'
        | 'other'}`
        ? B
        : TKey}`;
}[keyof TObj & (string | number)];

type FlattenTypedKey<N extends NameSpace> = AllLoadedNameSpaceTypeByLanguage<N>;

// to type translation keys
export type TranslationKey<N extends NameSpace> = RecursiveKeyOf<
  FlattenTypedKey<N>
>;

// to init i18next
export const resources: I18nResource = adaptLoadedResources();
export const nameSpaceNames = Object.keys(translationNameSpaces) as NameSpace[];

export type TypedNameSpaceOptions<N extends NameSpace> = Omit<
  TOptions,
  'ns'
> & { ns?: N };

export type TypedTranslationOptions<N extends NameSpace> =
  | string
  | TypedNameSpaceOptions<N>
  | undefined;

export type TFunctionParams<N extends NameSpace> = Parameters<
  TFunction<N, undefined>
>;

export type TFunctionType<NE extends NameSpace | undefined = undefined> = <
  N extends NameSpace | undefined = undefined
>(
  key: N extends NameSpace
    ? TranslationKey<N>
    : NE extends NameSpace
    ? TranslationKey<NE>
    : TranslationKey<DefaultNameSpace>,
  options?: N extends NameSpace
    ? TypedTranslationOptions<N>
    : NE extends NameSpace
    ? TypedTranslationOptions<NE>
    : TypedTranslationOptions<DefaultNameSpace>,
  defaultValue?: N extends NameSpace
    ? TFunctionParams<N>
    : (NE extends NameSpace
        ? TFunctionParams<NE>
        : TFunctionParams<DefaultNameSpace>)[1]
) => string;

export type TypedTranslationResponse<
  NE extends NameSpace | undefined = undefined
> = {
  t: TFunctionType<NE>;
  lng: SupportedLocale;
};

export type TranslationString = (t: TFunctionType) => string;

export type TranslationNode =
  | TranslationString
  | React.ReactNode
  | TransChild
  | TransChild[];

export type TranslationMap<
  K extends string,
  // TODO убираем когда определимся
  T extends string | TranslationString = TranslationString | string
> = Record<K, T>;

export type TransChild = React.ReactNode | Record<string, unknown>;

export const languageEntities: Record<
  SupportedLocale,
  {
    title: string;
    Icon: React.FC<CommonIconProps>;
  }
> = {
  ru: {
    title: 'Русский',
    Icon: RussiaIcon
  },
  en: {
    title: 'English',
    Icon: GreatBritainIcon
  }
};
