import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const PencilWithTimerIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon {...props} iconSize={IconSize.L}>
      <path
        d="M17.4883 11.1124L17.6919 10.9089C17.6943 10.9071 17.6961 10.9053 17.6979 10.9035C17.6997 10.9017 17.7015 10.8999 17.7033 10.8975L19.6395 8.96124C19.9745 8.62623 20.1588 8.18134 20.1588 7.70763C20.1588 7.23333 19.9745 6.78844 19.6395 6.45343L16.706 3.51933C16.371 3.18432 15.9255 3 15.4518 3C14.9781 3 14.5326 3.18432 14.1976 3.51933L4.47133 13.2456C4.24198 13.475 4.07987 13.7631 4.00242 14.0783L3.052 17.9628C2.90311 18.5704 3.07844 19.1966 3.52032 19.6385C3.85894 19.9771 4.30563 20.1591 4.76853 20.1591C4.91022 20.1591 5.05372 20.1423 5.19601 20.1074L9.0805 19.1564C9.3957 19.0796 9.6839 18.9175 9.91324 18.6881L12.0032 16.5982C12.4775 18.6083 14.287 20.1092 16.4406 20.1092C18.9545 20.1092 21 18.0637 21 15.5499C21 13.3957 19.499 11.5867 17.4883 11.1124ZM16.4406 19.1486C14.5248 19.1486 12.9536 17.6434 12.8479 15.7534C12.8437 15.6862 12.8419 15.6183 12.8419 15.5499C12.8419 15.0312 12.9524 14.5376 13.1505 14.0916C13.5126 13.2774 14.1682 12.6224 14.9823 12.2604C15.4284 12.0616 15.9219 11.9512 16.4406 11.9512C16.5085 11.9512 16.5763 11.953 16.6436 11.9572C18.5336 12.0622 20.0394 13.6335 20.0394 15.5499C20.0394 17.5342 18.4249 19.1486 16.4406 19.1486ZM14.8766 4.19897C15.0351 4.03987 15.2435 3.96122 15.4518 3.96122C15.6601 3.96122 15.8679 4.03987 16.0264 4.19897L18.9605 7.13246C19.2775 7.44947 19.2775 7.9652 18.9605 8.28221L17.358 9.88464L13.276 5.7996L14.8766 4.19897ZM4.96785 19.1744C4.68507 19.2435 4.40528 19.1654 4.19995 18.9595C3.99402 18.7536 3.91536 18.4738 3.98501 18.1916L4.81895 14.7844L8.37505 18.3405L4.96785 19.1744ZM9.31766 17.9244L5.23442 13.8412L12.597 6.47864L16.679 10.5637L16.2479 10.9948C13.8866 11.0932 11.9846 12.9952 11.8855 15.3566L9.31766 17.9244Z"
        fill="currentColor"
      />
      <path
        d="M18.2756 15.3253H16.9205V13.6148C16.9205 13.3501 16.7049 13.1345 16.4401 13.1345C16.1748 13.1345 15.9598 13.3501 15.9598 13.6148V15.8056C15.9598 16.071 16.1748 16.286 16.4401 16.286H18.2756C18.5409 16.286 18.7559 16.071 18.7559 15.8056C18.7559 15.5403 18.5409 15.3253 18.2756 15.3253Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default PencilWithTimerIcon;
