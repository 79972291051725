import { runInAction } from 'mobx';

import { LoadingStageModel } from 'shared/models/loadingStage';
import { FieldModel } from 'shared/models/form';
import { validateIsEmpty } from 'shared/entities/validator';
import {
  IUserPaymentMethod,
  IYooMoneyPaymentMethodModel,
  UserPaymentMethodKind,
  UserPaymentYooMoneyMethodServer
} from 'shared/entities/userPayment';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';

import { UserPaymentMethodModel } from './UserPaymentMethodModel';

export class YooMoneyPaymentMethodModel
  extends UserPaymentMethodModel
  implements IYooMoneyPaymentMethodModel
{
  readonly providerKind: UserPaymentMethodKind.yooMoney =
    UserPaymentMethodKind.yooMoney;
  readonly secret: FieldModel = new FieldModel<string>('', [validateIsEmpty]);
  readonly callbackUrl: FieldModel<string | null> = new FieldModel<
    string | null
  >(null);
  readonly updatingSecretStage: LoadingStageModel = new LoadingStageModel();
  readonly gettingCallbackUrlStage: LoadingStageModel = new LoadingStageModel();

  constructor(
    params: Omit<IUserPaymentMethod, 'providerKind'> & {
      providerKind: UserPaymentMethodKind.yooMoney;
    },
    rootStore: IRootStore
  ) {
    super(params, rootStore);

    this.providerKind = params.providerKind;
  }

  getCallbackUrl = async (): Promise<BaseResponse> => {
    if (
      this.gettingCallbackUrlStage.isLoading ||
      this.isEnabled ||
      this.callbackUrl.value
    ) {
      return {
        isError: true
      };
    }

    this.gettingCallbackUrlStage.loading();

    const response = await this._rootStore.networkStore.api<{
      callback_url: string;
    }>(apiUrls.OAUTH_YOOMONEY_GET_CALLBACK_URL, {
      method: 'GET',
      data: {
        payment_method_id: this.id
      }
    });

    if (response.isError) {
      this.gettingCallbackUrlStage.error();
    } else {
      this.callbackUrl.changeValue(response.data.callback_url);
      this.gettingCallbackUrlStage.success();
    }

    return response;
  };

  updateSecret = async (): Promise<BaseResponse> => {
    this.secret.validate();

    if (this.secret.isError || this.updatingSecretStage.isLoading) {
      return {
        isError: true
      };
    }

    this.updatingSecretStage.loading();

    const response = await this._rootStore.networkStore.api(
      apiUrls.OAUTH_YOOMONEY_UPDATE_SECRET,
      {
        method: 'POST',
        data: {
          payment_method_id: this.id,
          secret: this.secret.value
        }
      }
    );

    if (response.isError) {
      this.updatingSecretStage.error();

      return {
        isError: true
      };
    } else {
      runInAction(() => {
        this.changeIsEnabled(true);
        this.secret.changeValue('');
        this.updatingSecretStage.success();
      });

      return {
        isError: false
      };
    }
  };

  static fromJson(
    raw: UserPaymentYooMoneyMethodServer,
    rootStore: IRootStore
  ): YooMoneyPaymentMethodModel | null {
    if (raw.provider_kind !== UserPaymentMethodKind.yooMoney) {
      return null;
    }

    return new YooMoneyPaymentMethodModel(
      {
        id: raw._id,
        externalId: raw.external_id,
        title: raw.verbose_name,
        providerKind: raw.provider_kind,
        dateCreated: new Date(raw.date_created),
        isEnabled: raw.is_enabled,
        isTest: raw.is_test,
        vatCode: raw.vat_code
      },
      rootStore
    );
  }
}
