import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router';
import { Helmet } from 'react-helmet';

import { urls } from 'shared/entities/domain';
import {
  useAuthFlowStore,
  useCabinetStore,
  useChannelsStore,
  useJobsStore,
  usePaymentStore,
  useUiStore
} from 'stores';
import PageSpinner from 'shared/components/animations/PageSpinner';
import SentryErrorBoundary from 'shared/components/SentryErrorBoundary';
import {
  useEventListener,
  useRouterStoreUpdates
} from 'shared/entities/common/hooks';
import LoaderSpinner, {
  LoadingSpinnerSize
} from 'shared/components/animations/LoaderSpinner';
import SupportPopup from 'shared/newContainers/SupportPopup';
import ChannelsModal from 'shared/newContainers/modals/ChannelsModal';

import NotificationBanner from '../NotificationBanner';

import ServicePopups from './ServicePopups';

import './App.modules.scss';

const Project = React.lazy(() => import('../Project'));
const Auth = React.lazy(() => import('../Auth'));
const Settings = React.lazy(() => import('../Settings'));
const Subscription = React.lazy(() => import('../Subscription'));
const App: React.FC = () => {
  const authFlowStore = useAuthFlowStore();
  const jobsStore = useJobsStore();
  const uiStore = useUiStore();
  const paymentStore = usePaymentStore();
  const cabinetStore = useCabinetStore();
  const channelsStore = useChannelsStore();
  useRouterStoreUpdates();

  React.useEffect(() => {
    authFlowStore.authorize();

    return (): void => {
      jobsStore.detach();
    };
  }, []);

  React.useEffect(() => {
    uiStore.checkMobileDevice();
    uiStore.changeSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }, []);

  React.useEffect(() => {
    if (cabinetStore.initialized) {
      paymentStore.getInfo();
    }
  }, [cabinetStore.initialized]);

  const resizeListener = React.useCallback(() => {
    uiStore.checkMobileDevice();
    uiStore.changeSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }, []);

  useEventListener({ eventName: 'resize', handler: resizeListener });

  if (
    authFlowStore.authorizingStage.isLoading ||
    authFlowStore.authorizingStage.isNotStarted
  ) {
    return <PageSpinner />;
  }

  return (
    <>
      {uiStore.isMobile && (
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>
      )}
      <SentryErrorBoundary>
        <div styleName="app">
          <div styleName="app__banner">
            <NotificationBanner />
          </div>
          <div styleName="app__content">
            <SentryErrorBoundary>
              <React.Suspense
                fallback={
                  <LoaderSpinner centered size={LoadingSpinnerSize.m} />
                }
              >
                <ChannelsModal
                  opened={channelsStore.channelsModalOpened.opened}
                  onClose={channelsStore.closeModal}
                />
                <Switch>
                  <Route component={Auth} path={urls.AUTH.mask} />
                  <Route component={Settings} path={urls.SETTINGS.mask} />
                  <Route component={Project} path={urls.PROJECT.mask} />
                  <Route
                    component={Subscription}
                    path={urls.SUBSCRIPTION.mask}
                  />
                  <Redirect to={urls.SETTINGS.root} />
                </Switch>
              </React.Suspense>
            </SentryErrorBoundary>
          </div>
        </div>
        <ServicePopups />
        <SupportPopup />
      </SentryErrorBoundary>
    </>
  );
};

export default observer(App);
