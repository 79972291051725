export const i18nKeys = [
  'thanks',
  'dialogs_history',
  'powered_by',
  'powered_by_try',
  'powered_by_self',
  'enter_message',
  'enter_email',
  'enter_phone',
  'leave_email',
  'page_title_new_message',
  'privacy_policy',
  'errorFileType',
  'errorFileSize',
  'errorManyRequests',
  'errorReplyTooBig',
  'errorUserBan',
  'error',
  'close',
  'button_start_message',
  'button_vk',
  'button_viber',
  'button_telegram',
  'button_facebook',
  'button_instagram',
  'button_whatsapp',
  'social_network_page_title',
  'social_network_page_desc_vk',
  'social_network_page_desc_viber',
  'social_network_page_desc_telegram',
  'social_network_page_desc_facebook',
  'social_network_page_desc_instagram',
  'social_network_page_desc_whatsapp',
  'social_network_page_open_button',
  'social_network_qr_code_title',
  'bumperText',
  'bumperButton',
  'confirmSubscriptionWithCheckbox',
  'confirmSubscriptionWithoutCheckbox',
  'appeal',
  'vote_comment',
  'vote_text',
  'vote_thanks',
  'cart_button',
  'cart_no_name',
  'cart_zero',
  'article_link',
  'to_knowledge_base',
  'notification_enter_message',
  'notification_message_sending',
  'notification_message_sent',
  'notification_file_sending',
  'notification_file_sent',
  'notification_typing_status',
  'history_zero_data_title',
  'history_zero_data_desc',
  'history_group_collapse',
  'history_section_actual',
  'history_section_archive',
  'back_to_chat',
  'integration_zoom_button_text',
  'integration_zoom_tip',
  'dateMonth',
  'at',
  'edited',
  'removed',
  'removedMessage',
  'bot_answer_placeholder',
  'bot_buttons_placeholder',
  'connection_problem_toast',
  'input_error_email',
  'input_error_phone',
  'popup_close_all_button',
  'offline',
  'online',
  'whatsapp_auth_text',
  'popup_desc',
  'image_desc',
  'emoji_search_placeholder',
  'emoji_zero_data',
  'emoji_category_people',
  'emoji_category_nature',
  'emoji_category_food',
  'emoji_category_activity',
  'emoji_category_places',
  'emoji_category_objects',
  'emoji_category_symbols',
  'emoji_category_flags'
];

export const dateMonthKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11'
];
