import { observer } from 'mobx-react';
import * as React from 'react';

import FadeScroll from 'shared/newComponents/FadeScroll';
import { useScenariosStore } from 'stores/scenariosStore';
import InfiniteScroll from 'shared/newComponents/InfiniteScroll';
import { LoadingStage } from 'shared/entities/meta';
import SelectorOption from 'shared/newComponents/form/Selector/Content/SelectorOption';
import { SelectorType } from 'shared/entities/components/Selector';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { generateId } from 'shared/entities/common/utils';

import './Content.modules.scss';

type Props = {
  className?: string;
};

const scrollId = generateId();

const Content: React.FC<Props> = ({ className }) => {
  const store = useScenariosStore();
  const { store: channelsModalStore } = useChannelsModalStore();

  React.useEffect(() => {
    store.initializeReactions();
    store.loadCommonScenarios({
      initial: true
    });

    return () => {
      store.disposeAll();
    };
  }, []);

  return (
    <FadeScroll id={scrollId} className={className}>
      <InfiniteScroll
        scrollableTarget={scrollId}
        hasMore={store.commonScenarios.hasMore}
        load={store.loadCommonScenarios}
        dataLength={store.commonScenarios.length}
        isInitialLoad={store.commonScenarios.isInitialLoad}
        isLoading={store.commonScenarios.loadingStage === LoadingStage.LOADING}
      >
        {store.commonScenarios.items.map((item) => (
          <div key={item.id} styleName="item">
            <SelectorOption
              id={item.id}
              title={item.name}
              type={SelectorType.multi}
              onClick={channelsModalStore.handleScenarioClick}
              value={
                channelsModalStore.selectedChannel?.scenarioIds?.value || []
              }
            />
          </div>
        ))}
      </InfiniteScroll>
    </FadeScroll>
  );
};

export default observer(Content);
