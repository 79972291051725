import * as React from 'react';
import cn from 'classnames';

import { SelectIcon } from 'shared/newComponents/icons';
import WithBorder from 'shared/newComponents/WithBorder';
import { useModalState } from 'shared/entities/common/hooks';
import { IconSize } from 'shared/entities/components/Icon';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import { TranslationNode } from 'shared/entities/localization';
import { WithMetaProps } from 'shared/newEntities/components/WithMeta';
import { ReferenceProps } from 'shared/newEntities/components/Reference';
import Reference from 'shared/newComponents/Reference';

import WithMeta from '../WithMeta';

import './Checkbox.modules.scss';

export type Props = WithMetaProps & {
  value: boolean;
  onChange: (
    value: boolean,
    originalEvent: React.MouseEvent<HTMLDivElement>
  ) => void;
  className?: string;
  disabled?: boolean;
  children?: TranslationNode;
  reference?: ReferenceProps;
  aligned?: boolean;
};

const Checkbox: React.FC<Props> = ({
  value,
  onChange,
  className,
  disabled,
  children,
  reference,
  aligned,
  ...metaProps
}) => {
  const { opened: focused, open: focus, close: unfocus } = useModalState();

  const handleChange = React.useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >(
    (e) => {
      onChange(!value, e);
    },
    [onChange, value]
  );

  const isError = !!metaProps.error;

  return (
    <WithMeta {...metaProps} className={className}>
      <div
        tabIndex={0}
        onFocus={focus}
        onBlur={unfocus}
        onClick={handleChange}
        styleName={cn('container', aligned && 'container_aligned')}
      >
        <WithBorder
          error={isError}
          focus={focused}
          disabled={disabled}
          borderRadius={4}
          styleName="checkbox-container"
        >
          <div styleName="checkbox">
            <div styleName="checkbox__icon">
              {value && <SelectIcon iconSize={IconSize.XSM} />}
            </div>
          </div>
        </WithBorder>
        {children && (
          <>
            <Typography
              styleName="container__title"
              fontType={TypographyType.text}
              fontSize={TypographySize.s}
              fontWeight={TypographyWeight.heavy}
              fontColor={TypographyColor.secondary}
            >
              {children}
            </Typography>
            {!!reference && (
              <Reference {...reference} styleName="container__reference" />
            )}
          </>
        )}
      </div>
    </WithMeta>
  );
};

export default React.memo(Checkbox);
