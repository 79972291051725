import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import {
  IconSize,
  CommonIconProps,
  LogoIconProps
} from 'shared/entities/components/Icon';

const InstagramIcon: React.FC<LogoIconProps> = ({ color, ...rest }) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXL}
      svgProps={{ viewBox: '0 0 40 40' }}
      {...rest}
    >
      <g clipPath="url(#clip0_6302_72)">
        <path
          d="M31.0992 39.9335L8.9385 39.9546C4.06315 39.959 0.0709081 35.9745 0.0653679 31.0992L0.0443152 8.9385C0.0398831 4.06315 4.02437 0.0709081 8.89972 0.0653679L31.0604 0.0443152C35.9357 0.0398831 39.928 4.02437 39.9335 8.89972L39.9546 31.0604C39.9601 35.9368 35.9745 39.9291 31.0992 39.9335Z"
          fill={
            color === 'original'
              ? 'url(#paint0_radial_6302_72)'
              : 'currentColor'
          }
        />
        <path
          d="M31.0992 39.9335L8.9385 39.9546C4.06315 39.959 0.0709081 35.9745 0.0653679 31.0992L0.0443152 8.9385C0.0398831 4.06315 4.02437 0.0709081 8.89972 0.0653679L31.0604 0.0443152C35.9357 0.0398831 39.928 4.02437 39.9335 8.89972L39.9546 31.0604C39.9601 35.9368 35.9745 39.9291 31.0992 39.9335Z"
          fill={
            color === 'original'
              ? 'url(#paint1_radial_6302_72)'
              : 'currentColor'
          }
        />
        <path
          d="M20 27.7562C15.7241 27.7562 12.2438 24.277 12.2438 20C12.2438 15.723 15.7241 12.2438 20 12.2438C24.2759 12.2438 27.7562 15.723 27.7562 20C27.7562 24.277 24.2759 27.7562 20 27.7562ZM20 14.4598C16.9452 14.4598 14.4598 16.9452 14.4598 20C14.4598 23.0549 16.9452 25.5402 20 25.5402C23.0549 25.5402 25.5402 23.0549 25.5402 20C25.5402 16.9452 23.0549 14.4598 20 14.4598Z"
          fill="white"
        />
        <path
          d="M28.3102 13.3518C29.2282 13.3518 29.9723 12.6077 29.9723 11.6898C29.9723 10.7718 29.2282 10.0277 28.3102 10.0277C27.3923 10.0277 26.6482 10.7718 26.6482 11.6898C26.6482 12.6077 27.3923 13.3518 28.3102 13.3518Z"
          fill="white"
        />
        <path
          d="M26.6482 34.4044H13.3518C9.0759 34.4044 5.59557 30.9252 5.59557 26.6482V13.3518C5.59557 9.07481 9.0759 5.59558 13.3518 5.59558H26.6482C30.9241 5.59558 34.4044 9.07481 34.4044 13.3518V26.6482C34.4044 30.9252 30.9241 34.4044 26.6482 34.4044ZM13.3518 7.81165C10.297 7.81165 7.81163 10.297 7.81163 13.3518V26.6482C7.81163 29.7031 10.297 32.1884 13.3518 32.1884H26.6482C29.703 32.1884 32.1884 29.7031 32.1884 26.6482V13.3518C32.1884 10.297 29.703 7.81165 26.6482 7.81165H13.3518Z"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_6302_72"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.8811 39.9835) scale(49.7495 49.7495)"
        >
          <stop stopColor="#FFDD55" />
          <stop offset="0.328" stopColor="#FF543F" />
          <stop offset="0.3484" stopColor="#FC5245" />
          <stop offset="0.5039" stopColor="#E64771" />
          <stop offset="0.6433" stopColor="#D53E91" />
          <stop offset="0.7606" stopColor="#CC39A4" />
          <stop offset="0.8414" stopColor="#C837AB" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_6302_72"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.46637 -0.45389) scale(33.0342 22.0107)"
        >
          <stop stopColor="#4168C9" />
          <stop offset="0.9988" stopColor="#4168C9" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_6302_72">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export const InstagramOriginalIcon: React.FC<CommonIconProps> = (props) => (
  <InstagramIcon color="original" {...props} />
);

export const InstagramTransparentIcon: React.FC<CommonIconProps> = (props) => (
  <InstagramIcon color="transparent" {...props} />
);
