import { TranslationString } from '../localization';
import { BaseResponseCode } from '../network';

export enum ReactionKind {
  preFilter = 'pre_filter'
}

export const mapReactionCreateErrorToMessage =
  (code: BaseResponseCode.conflict): TranslationString =>
  (t) =>
    t(`scenario.reaction.errors.createReaction.${code}`, {
      ns: 'entities'
    });
