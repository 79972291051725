import { computed, makeObservable } from 'mobx';

import { FieldModel } from 'shared/models/form';
import {
  validateEmailField,
  validateName,
  validatePassword,
  validateTextField
} from 'shared/entities/validator';

export default class UserEditModel {
  readonly email: FieldModel;
  readonly password: FieldModel = new FieldModel<string>('', [
    validateTextField,
    validatePassword
  ]);
  readonly name: FieldModel;

  constructor({
    name = '',
    email = ''
  }: Partial<{
    email: string;
    name: string;
  }> = {}) {
    this.email = new FieldModel<string>(email, [
      validateTextField,
      validateEmailField
    ]);
    this.name = new FieldModel<string>(name, [validateTextField, validateName]);

    makeObservable(this, {
      isError: computed
    });
  }

  get isError(): boolean {
    return this.email.isError || this.password.isError || this.name.isError;
  }

  reset(): void {
    this.email.reset();
    this.password.reset();
    this.name.reset();
  }

  resetErrors(): void {
    this.email.resetError();
    this.password.resetError();
    this.name.resetError();
  }

  validate(): void {
    this.email.validate();
    this.password.validate();
    this.name.validate();
  }
}
