import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import {
  IconSize,
  CommonIconProps,
  LogoIconProps
} from 'shared/entities/components/Icon';

const WhatsAppIcon: React.FC<LogoIconProps> = ({ color, ...rest }) => {
  const fill = color === 'original' ? '#25D366' : 'currentColor';

  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...rest}
    >
      <g clipPath="url(#clip0_2_5)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7945 4.19459C17.7267 2.13555 14.9765 1.00104 12.0462 1C6.00812 1 1.09402 5.88643 1.09192 11.8927C1.09087 13.8128 1.59562 15.6869 2.55417 17.3386L1 22.984L6.807 21.4692C8.40685 22.3373 10.2084 22.7943 12.0415 22.7949H12.0462C18.0832 22.7949 22.9979 17.9079 23 11.9016C23.001 8.99068 21.8628 6.25415 19.7945 4.19511V4.19459ZM12.0462 20.9552H12.0425C10.409 20.9547 8.80658 20.518 7.40842 19.6933L7.07592 19.4969L3.62986 20.3958L4.54954 17.0544L4.33314 16.7118C3.42186 15.2702 2.94022 13.6039 2.94127 11.8933C2.94337 6.90132 7.0276 2.83966 12.0499 2.83966C14.4817 2.8407 16.7676 3.78352 18.4866 5.4952C20.2058 7.20637 21.1517 9.48167 21.1507 11.9006C21.1485 16.8931 17.0643 20.9547 12.0462 20.9547V20.9552ZM17.0401 14.1743C16.7665 14.038 15.4209 13.3798 15.1698 13.2889C14.9187 13.198 14.7365 13.1526 14.5542 13.4253C14.372 13.6979 13.8473 14.3112 13.6876 14.4924C13.5279 14.6742 13.3682 14.6966 13.0946 14.5603C12.821 14.4239 11.9391 14.1367 10.8933 13.2095C10.0798 12.4876 9.53032 11.5966 9.37069 11.3239C9.21101 11.0513 9.35389 10.9039 9.49041 10.7687C9.61331 10.6465 9.76407 10.4506 9.90114 10.2918C10.0383 10.133 10.0834 10.0191 10.1748 9.83786C10.2662 9.65609 10.2205 9.49735 10.1522 9.36097C10.0839 9.22466 9.53667 7.88484 9.30818 7.34007C9.08598 6.80939 8.86017 6.88147 8.69261 6.8726C8.53292 6.86476 8.35069 6.86319 8.16791 6.86319C7.98512 6.86319 7.68886 6.9311 7.43781 7.20376C7.18677 7.47639 6.47978 8.13505 6.47978 9.47431C6.47978 10.8136 7.46042 12.1085 7.59749 12.2903C7.73457 12.472 9.52769 15.2211 12.2731 16.4005C12.926 16.681 13.436 16.8486 13.8336 16.974C14.4891 17.1814 15.0857 17.1521 15.5574 17.0821C16.0832 17.0038 17.1767 16.4235 17.4047 15.7878C17.6326 15.1521 17.6326 14.6068 17.5644 14.4934C17.4961 14.3801 17.3133 14.3116 17.0397 14.1753L17.0401 14.1743Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_5">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export const WhatsAppOriginalIcon: React.FC<CommonIconProps> = (props) => (
  <WhatsAppIcon color="original" {...props} />
);

export const WhatsAppTransparentIcon: React.FC<CommonIconProps> = (props) => (
  <WhatsAppIcon color="transparent" {...props} />
);
