import { BaseEditStore } from 'stores/editStore';
import { ApiTokenEditModel } from 'shared/models/apiIntegration';
import { validateIsEmpty } from 'shared/entities/validator';
import { ApiTokenScope } from 'shared/entities/apiIntegration';

export default class ApiTokenEditStore extends BaseEditStore<ApiTokenEditModel> {
  validators = {
    name: [validateIsEmpty],
    scope: [validateIsEmpty]
  };

  constructor() {
    super({
      initialData: new ApiTokenEditModel({
        name: '',
        scope: [ApiTokenScope.executeBlock]
      })
    });
  }
}
