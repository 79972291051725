import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const VerifiedIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XSS}
      svgProps={{
        viewBox: '0 0 10 10'
      }}
      {...props}
    >
      <g clipPath="url(#clip0_2801_102301)">
        <path
          d="M7.72212 1.77104C7.8171 2.00074 7.9994 2.18332 8.22895 2.27864L9.03389 2.61205C9.2636 2.7072 9.44611 2.88971 9.54126 3.11942C9.63641 3.34914 9.63641 3.60724 9.54126 3.83695L9.20808 4.64131C9.11289 4.87112 9.11276 5.12949 9.20838 5.35919L9.54098 6.16331C9.58815 6.27708 9.61244 6.39903 9.61246 6.5222C9.61248 6.64536 9.58823 6.76733 9.54109 6.88112C9.49396 6.9949 9.42487 7.09829 9.33776 7.18537C9.25066 7.27245 9.14725 7.3415 9.03345 7.3886L8.22909 7.72178C7.9994 7.81675 7.81682 7.99905 7.7215 8.22861L7.38808 9.03354C7.29293 9.26325 7.11043 9.44576 6.88071 9.54091C6.651 9.63606 6.3929 9.63606 6.16318 9.54091L5.35883 9.20774C5.12911 9.11282 4.8711 9.11301 4.64152 9.20827L3.83659 9.54121C3.60701 9.63614 3.34913 9.63606 3.1196 9.54099C2.89007 9.44591 2.70767 9.26362 2.61245 9.03415L2.27894 8.22898C2.18396 7.99929 2.00166 7.81671 1.77211 7.72139L0.967177 7.38798C0.737563 7.29287 0.555111 7.11048 0.459925 6.88089C0.364739 6.65131 0.36461 6.39333 0.459566 6.16365L0.79274 5.3593C0.887656 5.12958 0.887462 4.87157 0.792201 4.64199L0.459505 3.83648C0.412336 3.72271 0.388047 3.60075 0.388028 3.47759C0.388008 3.35442 0.412257 3.23246 0.45939 3.11867C0.506523 3.00488 0.575616 2.9015 0.662721 2.81442C0.749826 2.72734 0.853235 2.65828 0.967039 2.61119L1.77139 2.27801C2.00089 2.18312 2.18336 2.00105 2.27875 1.77176L2.61216 0.966829C2.70731 0.737115 2.88982 0.554608 3.11953 0.459458C3.34925 0.364307 3.60735 0.364307 3.83706 0.459458L4.64142 0.792632C4.87114 0.887548 5.12915 0.887354 5.35872 0.792092L6.16399 0.459975C6.39368 0.364877 6.65172 0.364897 6.88139 0.460029C7.11106 0.55516 7.29354 0.737615 7.38871 0.967269L7.72222 1.77244L7.72212 1.77104Z"
          fill="#4457FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.95829 3.68938C7.02509 3.58446 7.04747 3.45729 7.02051 3.33586C6.99356 3.21443 6.91946 3.10868 6.81454 3.04188C6.70961 2.97508 6.58245 2.9527 6.46102 2.97966C6.33959 3.00661 6.23384 3.08071 6.16704 3.18563L4.33141 6.07001L3.49141 5.02001C3.41375 4.92287 3.30069 4.86056 3.17709 4.84679C3.05349 4.83302 2.92949 4.86891 2.83235 4.94657C2.73522 5.02423 2.67291 5.13729 2.65914 5.26089C2.64536 5.38449 2.68125 5.5085 2.75891 5.60563L4.00891 7.16813C4.0555 7.22644 4.11533 7.27278 4.18344 7.30329C4.25154 7.33381 4.32595 7.34763 4.40047 7.3436C4.47499 7.33956 4.54747 7.31779 4.61188 7.2801C4.67629 7.2424 4.73077 7.18988 4.77079 7.12688L6.95829 3.68938Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2801_102301">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export default VerifiedIcon;
