import * as React from 'react';

import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const TurnOnIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XM}
      svgProps={{
        viewBox: '0 0 20 20'
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.5C10.4602 2.5 10.8333 2.8731 10.8333 3.33333V9.58333C10.8333 10.0436 10.4602 10.4167 10 10.4167C9.53976 10.4167 9.16667 10.0436 9.16667 9.58333V3.33333C9.16667 2.8731 9.53976 2.5 10 2.5ZM6.21508 4.44856C6.51963 4.79361 6.4868 5.32023 6.14174 5.62478C4.92933 6.69488 4.16667 8.25786 4.16667 10C4.16667 13.2217 6.77834 15.8333 10 15.8333C13.2217 15.8333 15.8333 13.2217 15.8333 10C15.8333 8.25786 15.0707 6.69488 13.8583 5.62478C13.5132 5.32023 13.4804 4.79361 13.7849 4.44856C14.0895 4.1035 14.6161 4.07066 14.9612 4.37522C16.5168 5.74827 17.5 7.75983 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 7.75983 3.48319 5.74827 5.03885 4.37522C5.38391 4.07066 5.91052 4.1035 6.21508 4.44856Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TurnOnIcon;
