import { getFileNameFromUrl } from 'shared/entities/common/utils';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import {
  AIAggregatedDocModelParams,
  AIAggregatedDocType,
  AIDocErrorCode,
  AIDocServer,
  AIUploadDocServerError,
  AIUploadDocServerResponse
} from 'shared/entities/smartbotAI';
import { LoadingStage } from 'shared/entities/meta';

import { AIAggregatedDocModel } from './AIAggregatedDocModel';

const UPLOADING_PART_PROGRESS = 50;
const INDEXING_PART_PROGRESS = 100 - UPLOADING_PART_PROGRESS;

export class AIAggregatedPdfDocModel extends AIAggregatedDocModel<AIAggregatedDocType.pdf> {
  constructor(
    params: Omit<AIAggregatedDocModelParams<AIAggregatedDocType.pdf>, 'type'>
  ) {
    super({ ...params, type: AIAggregatedDocType.pdf });
  }
  //до 50 - это прогресс загрузки  сайта,  50-100 прогресс индексации, рассчитывается 50 + прогресс, приходящий из запроса poll * 0.5
  get progressView(): number {
    if (this.loadingStage.isLoading) {
      return this.loadingProgress.value;
    }
    return this.progress.value === 100
      ? this.progress.value
      : UPLOADING_PART_PROGRESS +
          Math.floor((this.progress.value * INDEXING_PART_PROGRESS) / 100);
  }

  async upload(file: File): Promise<BaseResponse> {
    if (this.loadingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.loadingStage.loading();

    const response = await this.rootStore.networkStore.api<
      AIUploadDocServerResponse,
      AIDocErrorCode,
      AIUploadDocServerError
    >(apiUrls.AI_UPLOAD_DOC, {
      method: 'POST',
      data: {
        ai_bot_id: this.botId,
        file,
        deep: false
      },
      config: {
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total === undefined) {
            return;
          }
          this.loadingProgress.changeValue(
            Math.floor(
              (progressEvent.loaded / progressEvent.total) *
                UPLOADING_PART_PROGRESS
            )
          );
        }
      },
      multipartFormData: true
    });

    if (response.isError) {
      const errors = response.data && [
        {
          // @ts-ignore
          code: response.data.data.code || response.data.code,
          text:
            // @ts-ignore
            response.data.data.text ||
            // @ts-ignore
            response.data.data.fields?.url.join(', ') ||
            response.data.message
        }
      ];
      this.errors.changeValue(errors);
      this.loadingStage.error();
      if (response.data?.status === 500) {
        this.showNotification();
      }
      return {
        isError: true
      };
    }

    this.id.changeValue(response.data.agg_key);
    this.loadingStage.success();
    return {
      isError: false
    };
  }

  static fromDefaultParams({
    file,
    rootStore,
    botId
  }: {
    file: File;
    rootStore: IRootStore;
    botId: string;
  }): AIAggregatedPdfDocModel {
    const model = new AIAggregatedPdfDocModel({
      rootStore,
      id: null,
      botId,
      title: file.name,
      url: null,
      loadingStage: LoadingStage.NOT_STARTED,
      cost: 1,
      errors: null,
      progress: 0,
      loadingProgress: 0
    });

    return model;
  }

  static fromJson({
    raw,
    botId,
    rootStore
  }: {
    raw: AIDocServer;
    botId: string;
    rootStore: IRootStore;
  }): AIAggregatedPdfDocModel {
    return new AIAggregatedPdfDocModel({
      rootStore,
      id: raw.id,
      botId,
      title: getFileNameFromUrl(raw.id),
      url: raw.url,
      loadingStage: LoadingStage.SUCCESS,
      cost: raw.cost,
      errors: raw.errors || null,
      progress: raw.progress,
      loadingProgress: UPLOADING_PART_PROGRESS
    });
  }
}
