import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const RobokassaKZIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      iconSize={IconSize.L}
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_4486_188"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="4"
          width="16"
          height="16"
        >
          <path d="M4 3.99976H20.0002V20H4V3.99976Z" fill="white" />
        </mask>
        <g mask="url(#mask0_4486_188)">
          <g opacity="0.75">
            <path d="M4 3.99976V20H20.0002L4 3.99976Z" fill="#1565C0" />
          </g>
        </g>
        <path d="M4 3.99976V20L20.0002 3.99976H4Z" fill="#1976D2" />
        <path d="M4 3.99976V20L20.0002 3.99976H4Z" fill="#1976D2" />
      </svg>
    </BaseIcon>
  );
};

export default RobokassaKZIcon;
