import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const DragIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      {...props}
      iconSize={IconSize.XXL}
      svgProps={{ viewBox: '0 0 40 40' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1151 12.8648C19.2312 12.7484 19.3692 12.656 19.521 12.593C19.6729 12.53 19.8357 12.4976 20.0001 12.4976C20.1645 12.4976 20.3273 12.53 20.4792 12.593C20.6311 12.656 20.769 12.7484 20.8851 12.8648L25.8851 17.8648C26.0013 17.981 26.0935 18.119 26.1564 18.2708C26.2193 18.4227 26.2517 18.5854 26.2517 18.7498C26.2517 18.9142 26.2193 19.0769 26.1564 19.2288C26.0935 19.3806 26.0013 19.5186 25.8851 19.6348C25.7689 19.751 25.6309 19.8432 25.4791 19.9061C25.3272 19.969 25.1645 20.0014 25.0001 20.0014C24.8358 20.0014 24.673 19.969 24.5212 19.9061C24.3693 19.8432 24.2313 19.751 24.1151 19.6348L21.2501 16.7673V26.2498C21.2501 26.5813 21.1184 26.8993 20.884 27.1337C20.6496 27.3681 20.3316 27.4998 20.0001 27.4998C19.6686 27.4998 19.3507 27.3681 19.1162 27.1337C18.8818 26.8993 18.7501 26.5813 18.7501 26.2498V16.7673L15.8851 19.6348C15.6504 19.8695 15.3321 20.0014 15.0001 20.0014C14.6682 20.0014 14.3498 19.8695 14.1151 19.6348C13.8804 19.4001 13.7485 19.0817 13.7485 18.7498C13.7485 18.4179 13.8804 18.0995 14.1151 17.8648L19.1151 12.8648Z"
        fill="currentColor"
      />
      <path
        d="M11.015 8.355C13.5137 6.20034 16.7006 5.01035 20 5C26.725 5 32.3075 10 32.915 16.4475C36.895 17.01 40 20.3425 40 24.4325C40 28.9225 36.255 32.5 31.7175 32.5H9.4525C4.27 32.5 0 28.415 0 23.295C0 18.8875 3.165 15.2375 7.355 14.3125C7.7125 12.155 9.1 10.005 11.015 8.355ZM12.6475 10.2475C10.755 11.88 9.765 13.8475 9.765 15.3875V16.5075L8.6525 16.63C5.16 17.0125 2.5 19.88 2.5 23.295C2.5 26.9625 5.575 30 9.4525 30H31.7175C34.95 30 37.5 27.47 37.5 24.4325C37.5 21.3925 34.95 18.8625 31.7175 18.8625H30.4675V17.6125C30.47 12.0625 25.82 7.5 20 7.5C17.2997 7.51079 14.6921 8.48608 12.6475 10.25V10.2475Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default React.memo(DragIcon);
