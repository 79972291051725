import { mapSizeToLimit, windowSizesOrder, WindowSize } from './client';

export const calculateWindowSize = (size: number): WindowSize => {
  let [prevSize] = windowSizesOrder;
  windowSizesOrder.some((item, idx) => {
    if (mapSizeToLimit[item] <= size) {
      prevSize = windowSizesOrder[idx];
      return false;
    } else {
      return true;
    }
  });

  return prevSize;
};
