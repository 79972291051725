import { IUserDateVar, UserDateVarServer } from 'shared/entities/vars';
import { UserItemModel } from 'shared/models/users';
import { IRootStore } from 'shared/entities/store/rootStore';

import UserVarModel from './UserVarModel';

export default class UserDateVarModel
  extends UserVarModel<Date | null>
  implements IUserDateVar
{
  toJson(): UserDateVarServer {
    return {
      value: this.value.value?.toISOString() ?? '',
      key: this.key
    };
  }
  static fromJson(
    raw: UserDateVarServer & {
      chatId: string;
      user: UserItemModel;
      rootStore: IRootStore;
    }
  ): UserDateVarModel {
    return new UserDateVarModel({
      value: raw.value ? new Date(raw.value) : null,
      key: raw.key,
      chatId: raw.chatId,
      user: raw.user,
      rootStore: raw.rootStore
    });
  }
}
