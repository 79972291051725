import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const MasterCardIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M14.3744 8.09106H9.6261V15.9083H14.3744V8.09106Z"
        fill="#FF5F00"
      />
      <path
        d="M10.1164 12.0006C10.1158 11.2478 10.2851 10.5048 10.6114 9.82778C10.9378 9.15074 11.4126 8.55738 12.0001 8.09263C11.2725 7.51628 10.3987 7.15787 9.47866 7.05839C8.55856 6.9589 7.62926 7.12235 6.79697 7.53004C5.96467 7.93774 5.26296 8.57324 4.77203 9.3639C4.2811 10.1546 4.02075 11.0685 4.02075 12.0013C4.02075 12.934 4.2811 13.848 4.77203 14.6386C5.26296 15.4293 5.96467 16.0648 6.79697 16.4725C7.62926 16.8802 8.55856 17.0436 9.47866 16.9441C10.3987 16.8447 11.2725 16.4862 12.0001 15.9099C11.4125 15.445 10.9375 14.8514 10.6111 14.1741C10.2848 13.4968 10.1156 12.7535 10.1164 12.0006Z"
        fill="#EB001B"
      />
      <path
        d="M19.9784 12.0006C19.9783 12.9334 19.7179 13.8474 19.2269 14.6381C18.7359 15.4288 18.0341 16.0643 17.2017 16.4719C16.3693 16.8795 15.4399 17.0429 14.5197 16.9433C13.5996 16.8436 12.7258 16.4851 11.9983 15.9085C12.5855 15.4434 13.0602 14.8498 13.3867 14.1728C13.7131 13.4957 13.8827 12.7527 13.8827 11.9999C13.8827 11.2471 13.7131 10.5041 13.3867 9.82702C13.0602 9.14993 12.5855 8.55642 11.9983 8.09125C12.7258 7.51472 13.5996 7.15614 14.5197 7.05651C15.4399 6.95689 16.3693 7.12023 17.2017 7.52786C18.0341 7.9355 18.7359 8.57099 19.2269 9.36168C19.7179 10.1524 19.9783 11.0664 19.9784 11.9992V12.0006Z"
        fill="#F79E1B"
      />
    </BaseIcon>
  );
};

export default MasterCardIcon;
