import * as React from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react';

import { useChannelsModalStore } from 'stores/ChannelsModalStore';

import CreationContainer from '../CreationContainer';
import { useUnSelectAnimation } from '../hooks';

const CreationChannel: React.FC = () => {
  const { store } = useChannelsModalStore();
  const { showContent, ref } = useUnSelectAnimation({
    selected: !!store.selectedChannel,
    increased: store.wazzupOrJivoSelected
  });

  return (
    <motion.div ref={ref}>
      <CreationContainer showContent={showContent} />
    </motion.div>
  );
};

export default observer(CreationChannel);
