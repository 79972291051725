import { MouseEventHandler } from 'react';

import { ExternalLinkProps, ExternalLinkTarget } from '../ExternalLink';

export enum ComponentType {
  link = 'link',
  div = 'div',
  a = 'a',
  button = 'button',
  svg = 'svg'
}

export type IntervalLinkProps = {
  to: string;
  target?: ExternalLinkTarget;
};

export type ComponentLinkProps = {
  type: ComponentType.link;
} & IntervalLinkProps;

export type ComponentExternalLinkProps = {
  type: ComponentType.a;
} & ExternalLinkProps;

export type ComponentSvgProps = {
  type: ComponentType.svg;
  onClick: MouseEventHandler<SVGSVGElement>;
};

export type ComponentDivProps = {
  type: ComponentType.div;
  onClick: MouseEventHandler<HTMLDivElement>;
};

export type ComponentProps = ComponentLinkProps | ComponentDivProps;
