import * as React from 'react';

const useDebouncedEffect = (
  callback: () => void,
  timeout: number,
  deps: Array<any>
) => {
  React.useEffect(() => {
    const timer = setTimeout(callback, timeout);
    return () => clearTimeout(timer);
  }, [...deps, callback]);
};

export default useDebouncedEffect;
