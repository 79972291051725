import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import { LoadingStage } from 'shared/entities/meta';
import { apiUrls } from 'shared/entities/domain';
import {
  IUserPaymentMethodModel,
  UserPaymentMethodKind
} from 'shared/entities/userPayment';
import { IRootStore } from 'shared/entities/store/rootStore';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';

export default class YookassaStore extends ComponentLoadingStore {
  private _connectYookassaStage: LoadingStage = LoadingStage.NOT_STARTED;
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;

    makeObservable<YookassaStore, '_connectYookassaStage'>(this, {
      _connectYookassaStage: observable,

      connectYookassaStage: computed,
      methods: computed,

      connectYookassa: action.bound
    });
  }

  get loaded(): boolean {
    return true;
  }

  get canBeLoaded(): boolean {
    return true;
  }

  get methods(): IUserPaymentMethodModel[] {
    return this.rootStore.userPaymentsStore.methods.items.filter(
      (method) => method.providerKind === UserPaymentMethodKind.yookassa
    );
  }

  get connectYookassaStage(): LoadingStage {
    return this._connectYookassaStage;
  }

  async connectYookassa(): Promise<BaseResponse> {
    if (this._connectYookassaStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this._connectYookassaStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api<{
      auth_url: string;
    }>(apiUrls.OAUTH_YOOKASSA_GET_URL);

    if (response.isError) {
      runInAction(() => {
        this._connectYookassaStage = LoadingStage.ERROR;
      });

      return {
        isError: true
      };
    } else {
      runInAction(() => {
        this._connectYookassaStage = LoadingStage.SUCCESS;
      });
      window.location.href = response.data.auth_url;

      return {
        isError: false
      };
    }
  }

  async load(): Promise<BaseResponse> {
    return {
      isError: false
    };
  }

  reset(): void {
    this._connectYookassaStage = LoadingStage.NOT_STARTED;
  }
}
