import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const UploadIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon iconSize={IconSize.L} {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.61053 9.31555C11.9068 9.31555 13.7683 7.45405 13.7683 5.15777C13.7683 2.8615 11.9068 1 9.61053 1C7.31426 1 5.45276 2.8615 5.45276 5.15777C5.45276 7.45405 7.31426 9.31555 9.61053 9.31555Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.19403 19.3483H5.77275C4.16425 19.3483 2.88642 17.983 3.00801 16.3788C3.21921 13.5906 4.46931 9.84454 9.6724 9.84454C10.9801 9.84454 12.0339 10.0813 12.883 10.4803"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8779 22.6379C18.7067 22.6379 20.9999 20.3447 20.9999 17.5159C20.9999 14.6871 18.7067 12.3939 15.8779 12.3939C13.0491 12.3939 10.7559 14.6871 10.7559 17.5159C10.7559 20.3447 13.0491 22.6379 15.8779 22.6379Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeMiterlimit="10"
        />
        <path
          d="M18.5608 17.5147L13.1956 17.5147"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.8904 19.1851L18.5607 17.5148L16.8904 15.8444"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </BaseIcon>
  );
};

export default UploadIcon;
