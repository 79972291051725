import * as React from 'react';

import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const MenuBurgerIcon: React.FC<CommonIconProps> = (props) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M3 12H21M3 6H21M3 18H15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default MenuBurgerIcon;
