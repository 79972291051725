import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const CopyLinkIcon: React.FC<CommonIconProps> = (props) => {
  return (
    <BaseIcon
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      iconSize={IconSize.L}
      {...props}
    >
      <path
        d="M12.1637 9.78127C11.9395 9.67973 11.6745 9.77904 11.5729 10.0039C11.4714 10.2287 11.5707 10.4932 11.7955 10.5947C12.1492 10.7548 12.4689 10.9763 12.7462 11.2536C14.0054 12.5128 14.0054 14.561 12.7462 15.8202L10.4052 18.1612C9.79546 18.771 8.98483 19.1069 8.12232 19.1069C7.25981 19.1069 6.44918 18.771 5.83939 18.1612C5.22849 17.5514 4.89264 16.7407 4.89264 15.8782C4.89264 15.0157 5.22849 14.205 5.83828 13.5952L8.17923 11.2542C8.35329 11.0801 8.35329 10.7972 8.17923 10.6232C8.00516 10.4491 7.72231 10.4491 7.54824 10.6232L5.20729 12.9642C4.42847 13.7419 4 14.7774 4 15.8782C4 16.979 4.42847 18.0145 5.20729 18.7928C5.98612 19.5711 7.02047 20.0001 8.12176 20.0001C9.22305 20.0001 10.258 19.5716 11.0362 18.7928L13.3772 16.4517C14.9839 14.8449 14.9839 12.23 13.3772 10.6226C13.0235 10.2689 12.6151 9.98602 12.1637 9.78127Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.6"
      />
      <path
        d="M18.7941 5.20511C17.1873 3.5983 14.5725 3.5983 12.9652 5.20511L10.6242 7.54613C9.84595 8.32499 9.41748 9.35993 9.41748 10.4607C9.41748 11.5615 9.84595 12.597 10.6248 13.3753C10.9456 13.6966 11.3132 13.96 11.7171 14.1591C11.7807 14.1904 11.8477 14.2055 11.9141 14.2055C12.0787 14.2055 12.2371 14.114 12.3147 13.9561C12.4234 13.7351 12.3325 13.4673 12.1116 13.3585C11.7952 13.2029 11.5074 12.9959 11.2558 12.7443C10.646 12.1345 10.3101 11.3238 10.3101 10.4613C10.3101 9.59872 10.646 8.78806 11.2558 8.17826L13.5967 5.83723C14.8559 4.578 16.9045 4.578 18.1631 5.83723C19.4223 7.09645 19.4223 9.14457 18.1631 10.4038L15.8222 12.7448C15.6481 12.9189 15.6481 13.2018 15.8222 13.3758C15.9962 13.5499 16.2791 13.5499 16.4532 13.3758L18.7941 11.0348C20.4014 9.42688 20.4014 6.81247 18.7941 5.20511Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.6"
      />
    </BaseIcon>
  );
};

export default CopyLinkIcon;
