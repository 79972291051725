import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const StrikeThroughIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{ viewBox: '0 0 24 24', ...(props.svgProps || {}) }}
    >
      <path
        d="M11.5742 4C14.4062 4 16.5191 5.75644 16.672 8.22222H14.8338C14.5804 6.65511 13.3404 5.72444 11.5138 5.72444C9.56267 5.72444 8.27378 6.74133 8.27378 8.28622C8.27378 9.41867 8.98844 10.0933 10.7716 10.6222L12.6302 11.1378C12.8284 11.1884 13.0213 11.2418 13.2053 11.2978H20V12.9662H16.1138C16.6471 13.6116 16.8871 14.408 16.8871 15.4018C16.8871 18.2107 14.7636 20 11.4116 20C8.304 20 6.16178 18.2844 5.968 15.6462H7.81689C8.04089 17.2471 9.50222 18.2631 11.584 18.2631C13.5556 18.2631 15.008 17.1502 15.008 15.6382C15.008 14.3342 14.0924 13.5093 12.0729 12.9787L12.0267 12.9662H4V11.2978H7.65422C6.79022 10.584 6.39556 9.62667 6.39556 8.35111C6.39556 5.75556 8.47556 4 11.5742 4Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default StrikeThroughIcon;
