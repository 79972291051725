import * as React from 'react';

import {
  CommonIconProps,
  LogoIconProps,
  IconSize
} from 'shared/entities/components/Icon';
import BaseIcon from 'shared/newComponents/icons/BaseIcon';

const AliExpressFullIcon: React.FC<LogoIconProps> = ({
  color,
  ...rest
}: LogoIconProps) => {
  const fillColor = color === 'original' ? '#E43225' : 'currentColor';

  return (
    <BaseIcon
      iconSize={IconSize.XXXXL}
      svgProps={{
        viewBox: '0 0 160 160'
      }}
      {...rest}
    >
      <g clipPath="url(#clip0_6308_82)">
        <path
          d="M40.7 91.2V66H55.6V69.1H43.8V76.9H54.4V80H43.8V87.9H56.4V91.1L40.7 91.2Z"
          fill={fillColor}
        />
        <path
          d="M72 91.2L66.9001 84.5L61.8 91.2H58.2001L65.2 82.3L57.8 73.1H61.8L66.8001 80L71.9001 73.1H75.9001L68.9001 82.3L75.5 91.2H72Z"
          fill={fillColor}
        />
        <path
          d="M80.6 88.5V100.9H77.5V82.3C77.5 77.6 81.1 72.5 86.7 72.5C92.4 72.5 96.7 76.1 96.7 82C96.7 87.8 92.4 91.8 87.4 91.8C85 91.9 81.7 90.8 80.6 88.5ZM93.5 82.1C93.5 78.1 90.9 75.6 86.2 75.9C84 76 80.5 77.6 80.8 83.4C80.9 85.3 82.8 88.8 87.1 88.8C90.8 88.8 93.5 86.7 93.5 82.1Z"
          fill={fillColor}
        />
        <path
          d="M99.3 91.2V73H102.4V75C103.9 73.3 106.2 72.7 108.7 72.7V75.9C108.3 75.8 104.7 75.4 102.4 80.2V91.2H99.3Z"
          fill={fillColor}
        />
        <path
          d="M109.5 82.1C109.5 76.9 113.3 72.6 118.4 72.6C124.9 72.6 127.2 76.9 127.2 82.4V83.9H112.8C113 87.4 116.1 89.1 119 89.1C121.1 89 122.5 88.4 124 86.9L126 89C124.1 90.8 121.7 92 118.8 92C113.4 91.8 109.5 87.8 109.5 82.1ZM118.2 75.6C115.3 75.6 113 78.1 112.9 80.9H124.1C124.1 78.3 122.1 75.6 118.2 75.6Z"
          fill={fillColor}
        />
        <path
          d="M128.9 88.6L131.1 86.6C131 86.6 132.2 87.8 132.4 87.9C132.9 88.4 133.4 88.7 134.1 88.8C136 89.3 139.6 89.2 139.9 86.5C140.1 85 138.9 84.2 137.7 83.6C136 83 134.2 82.8 132.6 82C130.7 81.2 129.5 79.8 129.5 77.6C129.5 72.1 137.3 71.2 140.8 73.8C141 73.9 142.7 75.5 142.6 75.5L140.4 77.3C139.3 75.9 138.2 75.3 135.8 75.3C134.6 75.3 133 75.8 132.6 77.1C132.1 78.9 134.2 79.6 135.5 80C137.3 80.5 139.3 80.8 140.8 81.7C143 83.1 143.5 86 142.7 88.2C141.8 90.7 139.1 91.7 136.7 91.7C133.9 91.9 131.4 90.9 129.4 88.9C129.2 89 128.9 88.6 128.9 88.6Z"
          fill={fillColor}
        />
        <path
          d="M144.7 88.6L146.9 86.6C146.8 86.6 148 87.8 148.2 87.9C148.7 88.4 149.3 88.7 149.9 88.8C151.9 89.3 155.4 89.2 155.7 86.5C155.9 85 154.7 84.2 153.5 83.6C151.8 83 150.1 82.8 148.4 82C146.5 81.2 145.3 79.8 145.3 77.6C145.3 72.1 153.1 71.2 156.6 73.8C156.8 73.9 158.5 75.5 158.4 75.5L156.2 77.3C155.1 75.9 154 75.3 151.6 75.3C150.4 75.3 148.8 75.8 148.4 77.1C148 78.9 150 79.6 151.3 80C153.1 80.5 155 80.8 156.6 81.7C158.8 83.1 159.3 86 158.5 88.2C157.6 90.7 154.9 91.7 152.5 91.7C149.7 91.9 147.2 90.9 145.2 88.9C145.1 89 144.7 88.6 144.7 88.6Z"
          fill={fillColor}
        />
        <path
          d="M19.9 91.2L17.7 85.2H5.5L3.3 91.2H0L9.8 66H13.3L23 91.1L19.9 91.2ZM11.4 69.6L6.8 82.1H16.3L11.4 69.6Z"
          fill={fillColor}
        />
        <path d="M25 91.2V66H28.1V91.1L25 91.2Z" fill={fillColor} />
        <path d="M32.9 91.2V73.4H36V91.2H32.9Z" fill={fillColor} />
        <path
          d="M39 67.6C39 67.5 39 67.5 39 67.6C39 67.4 39 67.4 39 67.4C36.6 67.3 34.6 65.4 34.6 63C34.5 63 34.5 63 34.4 63C34.3 63 34.3 63 34.2 63C34.1 65.4 32.2 67.4 29.8 67.4C29.8 67.5 29.8 67.5 29.8 67.5C29.8 67.6 29.8 67.6 29.8 67.6C32.2 67.7 34.2 69.6 34.2 72C34.3 72 34.3 72 34.4 72C34.5 72 34.5 72 34.6 72C34.7 69.6 36.6 67.7 39 67.6Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_6308_82">
          <rect
            width="158.9"
            height="37.9"
            fill="white"
            transform="translate(0 63)"
          />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export const AliExpressFullOriginalIcon: React.FC<CommonIconProps> = (
  props
) => <AliExpressFullIcon color="original" {...props} />;

export const AliExpressFullTransparentIcon: React.FC<CommonIconProps> = (
  props
) => <AliExpressFullIcon color="transparent" {...props} />;
