import * as React from 'react';
import { observer } from 'mobx-react';
import { AnimatePresence, motion } from 'framer-motion';

import FadeScroll from 'shared/newComponents/FadeScroll';
import { useChannelsStore } from 'stores';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';

import Header from './Header';
import ChannelItem from './ChannelItem';

import './ChannelTable.modules.scss';

type Props = {
  className?: string;
  footer?: React.ReactNode;
};

const ChannelTable: React.FC<Props> = ({ className, footer }) => {
  const channelsStore = useChannelsStore();
  const { store } = useChannelsModalStore();

  return (
    <div styleName="table" className={className}>
      <Header styleName="table__header" />
      <FadeScroll correctBoxShadow={false} styleName="table__content">
        <AnimatePresence>
          {channelsStore.channels.items.map((channel) => (
            <motion.div
              initial={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, overflow: 'hidden', opacity: 0 }}
              transition={{
                duration: 0.5
              }}
              key={channel.id}
            >
              <ChannelItem
                channel={channel}
                styleName="table__item"
                isSelected={store.selectedChannel?.id === channel.id}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </FadeScroll>
      {footer && (
        <motion.div
          initial={{
            marginTop: 0,
            height: 0,
            overflow: 'hidden',
            opacity: 0
          }}
          animate={{
            marginTop: 12,
            height: 'auto',
            overflow: 'initial',
            opacity: 1
          }}
        >
          {footer}
        </motion.div>
      )}
    </div>
  );
};

export default observer(ChannelTable);
