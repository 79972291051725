import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { PopupContainer } from 'shared/components/popups';
import {
  PopupEventType,
  PopupPosition
} from 'shared/entities/components/Popup';
import WithMeta from 'shared/newComponents/form/WithMeta';
import { SelectorBaseProps } from 'shared/newEntities/components/Selector';

import Content from './Content';
import Trigger from './Trigger';

import './SelectorBase.modules.scss';

const SelectorBase: React.FC<SelectorBaseProps> = (props) => {
  const {
    position = PopupPosition.bottomLeft,
    on = PopupEventType.click,
    closeOnDocumentClick,
    withoutBorder,
    sameWidth = true,
    onClose,
    disabled,
    trigger,
    children,
    contentTopNode,
    triggerSize,
    ...metaProps
  } = props;
  const popupRef = React.useRef<{
    container: HTMLDivElement;
    setPosition: () => void;
  } | null>(null);
  const [width, setWidth] = React.useState<number | string>('initial');

  React.useEffect(() => {
    let observer: ResizeObserver | null = null;

    if (popupRef.current?.container && sameWidth) {
      setWidth(popupRef.current.container.clientWidth);

      observer = new ResizeObserver(() => {
        if (popupRef.current?.container) {
          setWidth(popupRef.current.container.clientWidth);
        }
      });

      observer.observe(popupRef.current.container);
    }

    return () => {
      if (observer && sameWidth) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <WithMeta {...metaProps}>
      <PopupContainer
        ref={popupRef}
        position={[position]}
        onClose={onClose}
        on={on}
        disabled={disabled}
        styleName="selector-container"
        contentStyle={{ padding: '6px 0 4px 0' }}
        closeOnDocumentClick={closeOnDocumentClick}
        trigger={(isOpen) => {
          return (
            <div>
              <Trigger
                withoutBorder={withoutBorder}
                isSelectorOpened={isOpen}
                isError={!!metaProps.error}
                disabled={disabled}
                size={triggerSize}
              >
                {trigger}
              </Trigger>
            </div>
          );
        }}
      >
        {(onClose, isOpen) => {
          return (
            <Content
              width={width}
              onClose={onClose}
              isOpen={isOpen}
              extraTopNode={contentTopNode}
            >
              {children}
            </Content>
          );
        }}
      </PopupContainer>
    </WithMeta>
  );
};

export default SelectorBase;
