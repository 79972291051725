import { useCallback } from 'react';

import { IEdit } from '../../edit';
import { IEditStore } from '../../store/editStore';
import { ValidatorResult } from '../../validator';

export type EditField = {
  value: any;
  error: ValidatorResult;
  setValue: (value: any) => void;
  validate: () => boolean;
};

const useEditStoreField = <E extends IEdit>(
  editStore: IEditStore<E>,
  fieldName: keyof E
): EditField => {
  const setValue = useCallback(
    (value: any) => {
      editStore.setField(fieldName as string, value);
    },
    [editStore, editStore.setField, editStore.entity[fieldName], fieldName]
  );

  const validate = useCallback((): boolean => {
    return editStore.validateField(fieldName as string);
  }, [editStore, fieldName, editStore.validateField]);

  return {
    value: editStore.entity[fieldName],
    error: editStore.errors[fieldName],
    setValue,
    validate
  };
};

export default useEditStoreField;
