import { TranslationString } from 'shared/entities/localization';

export type GoogleAuthInfoServer = {
  manager: ManagerInfo;
  date_created: string;
};

export type GoogleAuthAvailableTokensServer = {
  available_tokens: string[];
};

export type ManagerInfo = {
  name: string;
  email: string;
};

export type GoogleTableBaseServer = {
  table_id: string | null;
  table_name: string | null;
  table_url: string | null;
};

export type GoogleTableSheetBaseServer = {
  sheet_name: string | null;
  sheet_id: number | null;
};

export type GoogleTableServer = {
  sheets: GoogleTableSheetServer[];
} & GoogleTableBaseServer;

export type GoogleTableSheetServer = {
  id: number;
  title: string;
};

export enum GoogleTableErrorCode {
  TOKEN_NOT_FOUND = 'token_not_found',
  ACCESS_FORBIDDEN = 'access_forbidden',
  INVALID_TABLE_ID = 'invalid_table_id',
  INSUFFICIENT_SCOPE = 'insufficient_scope',
  TABLE_NOT_FOUND = 'table_not_found'
}

export const mapGoogleTableErrorCodeToMessage =
  (code: GoogleTableErrorCode): TranslationString =>
  (t) =>
    t(`google.errors.${code}`, {
      ns: 'entities'
    });
