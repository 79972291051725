import * as React from 'react';

export type ErrorBoundaryData = {
  error?: Error;
};

export type ErrorBoundaryProps = {
  children: React.ReactNode;
  ErrorComponent: React.ComponentType<ErrorBoundaryData>;
  onError?: (data: Error, info: React.ErrorInfo) => void;
};

type State = {
  error: null | Error;
};

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state: State = {
    error: null
  };

  static getDerivedStateFromError(error: Error): Partial<State> {
    return { error };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    if (this.props.onError) {
      this.props.onError(error, info);
    }
  }

  render() {
    const { children, ErrorComponent } = this.props;
    const { error } = this.state;

    return error ? <ErrorComponent error={error} /> : children;
  }
}
