const regExpToGetVarsKeys = /\{\{([^{}]*)\}\}/g;

/**
 * Получает из значения переменной все SQ-строки
 * @param varModel
 * @returns {string[]}
 */
const parseValueForGettingSQs = ({
  variable
}: {
  variable: {
    value: string;
  };
}): string[] => {
  return variable.value.match(regExpToGetVarsKeys) || [];
};

export { parseValueForGettingSQs };
