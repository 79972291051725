import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const AlignBlockIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <rect
        x="10"
        y="3"
        width="11"
        height="6"
        rx="2"
        fill="white"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="15" y="9" width="1.5" height="14" fill="currentColor" />
      <rect
        x="9"
        y="13"
        width="13"
        height="6"
        rx="2"
        fill="white"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="8"
        y="23"
        width="15"
        height="6"
        rx="2"
        fill="white"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default AlignBlockIcon;
