import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { PopupPosition } from 'shared/entities/components/Popup';
import { TranslationNode } from 'shared/entities/localization';
import { TypographyWeight } from 'shared/newEntities/components/Typography';

import Reference from '../Reference';

import './CroppedContent.modules.scss';

type Props = {
  popupContent?: TranslationNode;
  children: React.ReactElement;
  className?: string;
  titleWeight?: TypographyWeight;
  position?: PopupPosition[];
  disabled?: boolean;
};

/**
 * Компонент, выносящий контент в попап, если он не помещается
 * @param popupContent
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const CroppedContent: React.FC<Props> = ({
  popupContent,
  children,
  className,
  titleWeight,
  position,
  disabled
}: Props) => {
  const realContainer = React.useRef<HTMLDivElement | null>(null);
  const croppedContainer = React.useRef<{
    container: HTMLDivElement;
    setPosition: () => void;
  } | null>(null);

  const [showPopup, setShowPopup] = React.useState(false);

  React.useEffect(() => {
    let observerForReal: ResizeObserver | null = null;
    let observerForCropped: ResizeObserver | null = null;

    if (realContainer.current) {
      observerForReal = new ResizeObserver(handleContainersWidth);
      observerForReal.observe(realContainer.current);
    }

    if (croppedContainer.current?.container) {
      observerForCropped = new ResizeObserver(handleContainersWidth);
      observerForCropped.observe(croppedContainer.current.container);
    }

    return () => {
      if (observerForReal) {
        observerForReal.disconnect();
      }
      if (observerForCropped) {
        observerForCropped.disconnect();
      }
    };
  }, []);

  const handleContainersWidth = React.useCallback(() => {
    if (realContainer.current && croppedContainer.current?.container) {
      setShowPopup(
        croppedContainer.current.container.clientWidth <
          realContainer.current.clientWidth
      );
    }
  }, [realContainer.current, croppedContainer.current?.container]);

  if (!children) {
    return null;
  }

  return (
    <>
      <div
        styleName="cropped-content__real-content"
        className="disabled"
        ref={realContainer}
      >
        {React.cloneElement(children)}
      </div>
      <div styleName="cropped-content-container" className={className}>
        <Reference
          title={popupContent || children}
          ref={croppedContainer}
          trigger={<div styleName="cropped-content">{children}</div>}
          disabled={disabled || !showPopup}
          position={
            position || [
              PopupPosition.topCenter,
              PopupPosition.bottomCenter,
              PopupPosition.rightBottom
            ]
          }
          titleWeight={titleWeight}
        />
      </div>
    </>
  );
};

export default React.memo(CroppedContent);
