import { ValidatorResult } from '../validator';

import { FileType } from './server';
import { mapFileExtensionToFileType } from './client';

export const downloadFile = ({
  blob,
  filename
}: {
  blob: Blob;
  filename: string;
}): void => {
  // @ts-ignore ie support
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE doesn't allow using a blob object directly as link href.
    // Workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    // @ts-ignore ie support
    window.navigator.msSaveBlob(blob, filename);
    return;
  }
  // Other browsers
  // Create a link pointing to the ObjectURL containing the blob
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL);
  }, 100);
};

export const getFileExtensionFromName = (name: string): string | null => {
  const FILE_NAME_REGEX = /^.+\.(?<extension>.+)$/;

  const matchGroups = name.match(FILE_NAME_REGEX)?.groups;

  if (!matchGroups || !matchGroups.extension) {
    return null;
  }

  return !matchGroups || !matchGroups.extension ? null : matchGroups.extension;
};

export const getFileTypeFromName = (name: string): FileType | null => {
  const extension = getFileExtensionFromName(name);

  if (!extension) {
    return null;
  }

  return mapFileExtensionToFileType[extension] || FileType.other;
};

export const validateFileExtension = ({
  file,
  expectedExtensions
}: {
  file: File;
  expectedExtensions: string[];
}): ValidatorResult => {
  const extension = getFileExtensionFromName(file.name);

  if (!extension) {
    return (t) =>
      t('validators.errors.fileInvalidExtension', {
        ns: 'entities'
      });
  }

  return expectedExtensions.includes(extension)
    ? null
    : (t) =>
        t('validators.errors.fileInvalidExtension', {
          ns: 'entities'
        });
};

const MAX_SIZE = 50 * 1024 * 1024;

/**
 * Валидирует размер файла
 * @param size - размер в байтах
 * @return {"Файл превыщает размер 50 MB" | null}
 */
export const validateFileMaxSize = (size: number): ValidatorResult => {
  return size > MAX_SIZE
    ? (t) =>
        t('validators.errors.fileMaxSize', {
          ns: 'entities'
        })
    : null;
};
