import { observer } from 'mobx-react';
import * as React from 'react';

import Typography from 'shared/newComponents/Typography';
import {
  TypographyColor,
  TypographySize,
  TypographyType
} from 'shared/newEntities/components/Typography';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import ExternalLink from 'shared/newComponents/ExternalLink/ExternalLink';
import { VIBER_PARTNERS_LINK } from 'shared/entities/app';
import { Input } from 'shared/newComponents/form';
import { Button } from 'shared/newComponents/buttons';
import { ButtonSize } from 'shared/newEntities/components/Button';
import { ComponentType } from 'shared/entities/components/Component';
import { useLocal } from 'shared/entities/common/hooks';
import { ViberChannelCreationStore } from 'stores/ViberChannelCreationStore';
import { useRootStore } from 'stores/index';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';

import './Viber.modules.scss';

const Viber: React.FC = () => {
  const rootStore = useRootStore();
  const store = useLocal(() => new ViberChannelCreationStore({ rootStore }));
  const { store: modalStore } = useChannelsModalStore();

  const handleCreate = React.useCallback(async () => {
    const { isError } = await store.create();
    if (!isError) {
      modalStore.unSelectChannelKind();
    }
  }, []);

  const token = store.token;

  return (
    <div>
      <Typography fontType={TypographyType.text} fontSize={TypographySize.m}>
        <TypedTransComponent
          ns="newContainers"
          i18nKey="ChannelsModal.ChannelForm.Viber.instruction"
        >
          Создайте бота в Viber через{' '}
          <ExternalLink
            fontType={TypographyType.text}
            fontSize={TypographySize.m}
            fontColor={TypographyColor.link}
            underline
            href={VIBER_PARTNERS_LINK}
          >
            панель администратора
          </ExternalLink>
          . Нажмите кнопку «Create Bot Account», заполните поля и создайте бота.
          Введите полученный токен:
        </TypedTransComponent>
      </Typography>
      <Input
        styleName="viber__input"
        label={(t) =>
          t('ChannelsModal.ChannelForm.token', {
            ns: 'newContainers'
          })
        }
        value={token.value}
        onChange={token.changeValue}
        error={token.error}
        onBlur={token.validate}
        placeholder="000000000:XXXXXXXX..."
      />
      <Button
        styleName="viber__button"
        size={ButtonSize.lg}
        type={ComponentType.button}
        onClick={handleCreate}
        loadingStage={store.creationStage.value}
        disabled={store.isError}
        fullWidth
      >
        {(t) =>
          t('ChannelsModal.ChannelForm.button', {
            ns: 'newContainers'
          })
        }
      </Button>
    </div>
  );
};

export default observer(Viber);
