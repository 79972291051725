const MAX_ATTEMPTS = 10;
const TIMEOUT = 1000 / 60;

const waitUntilDomElementExists = async (
  domElementId: string
): Promise<BaseResponse> => {
  const element = document.getElementById(domElementId);

  if (!element) {
    return {
      isError: false
    };
  }

  let timer: ReturnType<typeof setInterval>;
  let attempts = 0;

  return new Promise<BaseResponse>((resolve) => {
    timer = setInterval(() => {
      attempts++;

      if (attempts > MAX_ATTEMPTS) {
        clearInterval(timer);
        resolve({
          isError: true
        });

        return;
      }

      const element = document.getElementById(domElementId);

      if (!element) {
        clearInterval(timer);
        resolve({
          isError: false
        });
      }
    }, TIMEOUT);
  });
};

export default waitUntilDomElementExists;
