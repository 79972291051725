import * as React from 'react';
import { observer } from 'mobx-react';

import { useLocal } from 'shared/entities/common/hooks';
import { useRootStore } from 'stores/index';

import { ModalEventsHandlerOnCloseCb, ModalEventsHandlerStore } from './store';

export type ModalEventsHandlerRenderer = (params: {
  onClose: () => any;
  zIndex: number;
}) => React.ReactElement;

type Props = {
  opened: boolean;
  onClose: ModalEventsHandlerOnCloseCb;
  render: ModalEventsHandlerRenderer;
  onZIndexChange?: React.Dispatch<React.SetStateAction<number>>;
  closeOnEscape?: boolean;
};

const ModalEventsHandler: React.FC<Props> = ({
  opened,
  onClose,
  render,
  onZIndexChange,
  closeOnEscape = true
}: Props) => {
  const rootStore = useRootStore();

  const store = useLocal(
    () =>
      new ModalEventsHandlerStore({
        rootStore,
        opened,
        cb: onClose,
        closeOnEscape
      })
  );

  React.useLayoutEffect(() => {
    store.changeState(opened);

    if (opened) {
      store.addListeners();
    } else {
      store.removeListeners();
    }
  }, [opened]);

  React.useEffect(() => {
    return () => {
      store.removeListeners();
      store.resetState();
    };
  }, []);

  React.useEffect(() => {
    store.cb.changeValue(onClose || null);
  }, [onClose]);

  React.useEffect(() => {
    if (!onZIndexChange || !store.state.value) {
      return;
    }
    onZIndexChange(store.state.value.zIndex);
  }, [onZIndexChange, store.state.value]);

  if (!store.state.value) {
    return null;
  }

  return render({
    onClose: store.handleClosing,
    zIndex: store.state.value.zIndex
  });
};

export default observer(ModalEventsHandler);
