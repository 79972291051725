import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const AttachIcon: React.FC<CommonIconProps> = ({
  svgProps,
  ...rest
}: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXLM}
      svgProps={{
        viewBox: '0 0 48 48',
        ...svgProps
      }}
      {...rest}
    >
      <path
        d="M12.3507 27.6255L29.2698 10.7051C30.9553 9.02024 33.6159 9.10394 35.4743 10.9611C37.3321 12.819 37.4164 15.4801 35.731 17.1656L19.8365 33.0606C19.0459 33.8513 17.7574 33.852 16.9647 33.0607C16.1733 32.2687 16.1734 30.9801 16.9647 30.1888L27.7319 19.4216C28.0719 19.0816 28.0719 18.5315 27.7319 18.1915C27.3919 17.8515 26.8418 17.8515 26.5018 18.1915L15.7346 28.9581C14.2644 30.4282 14.2645 32.8194 15.7345 34.2901C17.2059 35.7616 19.5979 35.7589 21.0666 34.2901L36.9617 18.3951C39.3649 15.9913 39.2341 12.2594 36.705 9.73041C34.1767 7.20203 30.4443 7.07049 28.0404 9.47439L11.12 26.3947C9.59115 27.9229 8.75 29.963 8.75 32.1371C8.75 34.3112 9.59115 36.3518 11.12 37.8801C12.6482 39.4089 14.6882 40.25 16.8629 40.25C19.0364 40.25 21.077 39.4089 22.6052 37.88L39.5244 20.9596C39.8642 20.6198 39.8646 20.0691 39.5244 19.7295C39.1844 19.3895 38.6343 19.3895 38.2943 19.7295L21.3745 36.6493C20.1745 37.8493 18.5738 38.5096 16.8629 38.5096C15.1513 38.5096 13.55 37.8492 12.3508 36.6493C11.1513 35.4493 10.4904 33.8486 10.4904 32.1371C10.4904 30.4261 11.1508 28.8248 12.3507 27.6255Z"
        fill="currentColor"
      />
      <path
        d="M35.731 17.1656C37.4164 15.4801 37.3321 12.819 35.4743 10.9611C33.6159 9.10394 30.9553 9.02024 29.2698 10.7051L12.3507 27.6255C11.1508 28.8248 10.4904 30.4261 10.4904 32.1371C10.4904 33.8486 11.1513 35.4493 12.3508 36.6493C13.55 37.8492 15.1513 38.5096 16.8629 38.5096C18.5738 38.5096 20.1745 37.8493 21.3745 36.6493L38.2943 19.7295C38.6343 19.3895 39.1844 19.3895 39.5244 19.7295C39.8646 20.0691 39.8642 20.6198 39.5244 20.9596L22.6052 37.88C21.077 39.4089 19.0364 40.25 16.8629 40.25C14.6882 40.25 12.6482 39.4089 11.12 37.8801C9.59115 36.3518 8.75 34.3112 8.75 32.1371C8.75 29.963 9.59115 27.9229 11.12 26.3947L28.0404 9.47439C30.4443 7.07049 34.1767 7.20203 36.705 9.73041C39.2341 12.2594 39.3649 15.9913 36.9617 18.3951L21.0666 34.2901C19.5979 35.7589 17.2059 35.7616 15.7345 34.2901C14.2645 32.8194 14.2644 30.4282 15.7346 28.9581M35.731 17.1656L35.9077 17.3424M35.731 17.1656L19.8365 33.0606C19.0459 33.8513 17.7574 33.852 16.9647 33.0607C16.1733 32.2687 16.1734 30.9801 16.9647 30.1888L27.7319 19.4216C28.0719 19.0816 28.0719 18.5315 27.7319 18.1915C27.3919 17.8515 26.8418 17.8515 26.5018 18.1915L15.7346 28.9581M15.7346 28.9581L15.9114 29.1349"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </BaseIcon>
  );
};

export default AttachIcon;
