import { FieldModel } from 'shared/models/form';
import {
  IUserPaymentMethod,
  ITinkoffPaymentMethodModel,
  UserPaymentMethodKind,
  UserPaymentTinkoffMethodServer
} from 'shared/entities/userPayment';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import { TinkoffTaxation } from 'shared/entities/tinkoffPayment';

import { UserPaymentMethodModel } from './UserPaymentMethodModel';

export class TinkoffPaymentMethodModel
  extends UserPaymentMethodModel
  implements ITinkoffPaymentMethodModel
{
  readonly providerKind: UserPaymentMethodKind.tinkoff =
    UserPaymentMethodKind.tinkoff;
  readonly taxation: FieldModel<TinkoffTaxation>;

  constructor(
    params: Omit<IUserPaymentMethod, 'providerKind'> & {
      providerKind: UserPaymentMethodKind.tinkoff;
      taxation: TinkoffTaxation;
    },
    rootStore: IRootStore
  ) {
    super(params, rootStore);

    this.providerKind = params.providerKind;
    this.taxation = new FieldModel<TinkoffTaxation>(params.taxation);
  }

  updateTaxation = async (taxation: TinkoffTaxation): Promise<void> => {
    if (taxation === this.taxation.value) {
      return;
    }

    const prevTaxation = this.taxation.value;
    this.taxation.changeValue(taxation);

    const { isError } = await this._rootStore.networkStore.api(
      apiUrls.USERS_PAYMENTS_METHODS_UPDATE,
      {
        method: 'POST',
        data: {
          _id: this.id,
          taxation: taxation
        }
      }
    );

    if (isError) {
      this.taxation.changeValue(prevTaxation);
    }
  };

  static fromJson(
    raw: UserPaymentTinkoffMethodServer,
    rootStore: IRootStore
  ): TinkoffPaymentMethodModel | null {
    if (raw.provider_kind !== UserPaymentMethodKind.tinkoff) {
      return null;
    }

    return new TinkoffPaymentMethodModel(
      {
        id: raw._id,
        externalId: raw.external_id,
        title: raw.verbose_name,
        providerKind: raw.provider_kind,
        dateCreated: new Date(raw.date_created),
        isEnabled: raw.is_enabled,
        isTest: raw.is_test,
        vatCode: raw.vat_code,
        taxation: raw.extra.taxation
      },
      rootStore
    );
  }
}
