import * as React from 'react';

import Typography from 'shared/newComponents/Typography';
import Reference from 'shared/newComponents/Reference';
import {
  TypographyType,
  TypographySize,
  TypographyColor,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { urls } from 'shared/entities/domain';
import { useProjectStore, useRouterStore } from 'stores/index';
import { PopupTrigger } from 'shared/entities/components/Popup';

type Props = {
  trigger?: PopupTrigger;
};

const LinkedReference: React.FC<Props> = ({ trigger }: Props) => {
  const { close } = useChannelsModalStore();
  const projectStore = useProjectStore();
  const routerStore = useRouterStore();

  const handleLink = () => {
    if (!projectStore.projectId) {
      return;
    }

    routerStore.push(urls.SETTINGS.PROFILE.create());
    close?.();
  };

  return (
    <Reference
      trigger={trigger}
      title={(t) =>
        t('ChannelsModal.ChannelTable.LinkedReference.title', {
          ns: 'newContainers'
        })
      }
      description={
        <TypedTransComponent
          ns="newContainers"
          i18nKey="ChannelsModal.ChannelTable.LinkedReference.description"
        >
          Привяжите свой аккаунт в социальной сети, чтобы видеть отладочные
          сообщения в чате с ботом и работать с неопубликованной версией
          сценариев.{' '}
          <Typography
            Element="span"
            fontType={TypographyType.text}
            fontSize={TypographySize.xs}
            fontWeight={TypographyWeight.heavy}
            fontColor={TypographyColor.link}
            onClick={handleLink}
            underline
          >
            Привязать
          </Typography>
          .
        </TypedTransComponent>
      }
    />
  );
};

export default React.memo(LinkedReference);
