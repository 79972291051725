import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const RemoveUserIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <path
        d="M16.1119 14.4654C19.2779 14.4654 21.8446 11.8988 21.8446 8.7327C21.8446 5.56662 19.2779 3 16.1119 3C12.9458 3 10.3792 5.56662 10.3792 8.7327C10.3792 11.8988 12.9458 14.4654 16.1119 14.4654Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.929 28.2858H10.8188C10.2937 28.286 9.77434 28.1779 9.29301 27.9682C8.81167 27.7586 8.37876 27.4519 8.02132 27.0674C7.66389 26.6828 7.38962 26.2287 7.21565 25.7333C7.04169 25.238 6.97178 24.7121 7.01028 24.1885C7.29914 20.3445 9.0234 15.1761 16.2004 15.1761C20.1288 15.1761 22.3953 16.7271 23.6796 18.758"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M19.8484 23.3622H28.163"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </BaseIcon>
  );
};

export default RemoveUserIcon;
