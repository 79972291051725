import { IRootStore } from 'shared/entities/store/rootStore';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';
import { IIntegrationsStore } from 'shared/entities/store/integrationsStore';

import DomainsStore from './domainsStore';
import GoogleStore from './googleStore';
import UserPaymentsStore from './userPaymentsStore';
import ApiIntegrationStore from './apiIntegrationStore';
import GetCourseStore from './getCourseStore';
import QiwiStore from './qiwiStore';
import YookassaStore from './yookassaStore';
import YooMoneyStore from './yooMoneyStore';
import TelegramPaymentsStore from './telegramPaymentsStore';
import ProdamusStore from './prodamusStore';
import AmoCRMStore from './amoCRMStore';
import TinkoffStore from './TinkoffStore';

export class IntegrationsStore
  extends ComponentLoadingStore
  implements IIntegrationsStore
{
  private _rootStore: IRootStore;
  readonly userPaymentsStore: UserPaymentsStore;
  readonly domainsStore: DomainsStore;
  readonly googleStore: GoogleStore;
  readonly apiIntegrationStore: ApiIntegrationStore;
  readonly getCourseStore: GetCourseStore;
  readonly amoCRMStore: AmoCRMStore;

  readonly qiwiStore: QiwiStore;
  readonly yookassaStore: YookassaStore;
  readonly yooMoneyStore: YooMoneyStore;
  readonly prodamusStore: ProdamusStore;
  readonly telegramPaymentsStore: TelegramPaymentsStore;
  readonly tinkoffStore: TinkoffStore;

  constructor(rootStore: IRootStore) {
    super();

    this._rootStore = rootStore;
    this.qiwiStore = new QiwiStore(rootStore);
    this.yookassaStore = new YookassaStore(rootStore);
    this.domainsStore = new DomainsStore(rootStore);
    this.googleStore = new GoogleStore(rootStore);
    this.apiIntegrationStore = new ApiIntegrationStore(rootStore);
    this.getCourseStore = new GetCourseStore(rootStore);
    this.amoCRMStore = new AmoCRMStore(rootStore);
    this.prodamusStore = new ProdamusStore(rootStore);
    this.userPaymentsStore = new UserPaymentsStore(rootStore);
    this.yooMoneyStore = new YooMoneyStore(rootStore);
    this.telegramPaymentsStore = new TelegramPaymentsStore(rootStore);
    this.tinkoffStore = new TinkoffStore(rootStore);
  }

  async load(): Promise<BaseResponse<any, any>> {
    const promises = await Promise.all([
      this.domainsStore.load(),
      this.googleStore.load(),
      this.apiIntegrationStore.load(),
      this.getCourseStore.load(),
      this.amoCRMStore.load(),

      this.userPaymentsStore.load(),

      this.qiwiStore.load(),
      this.yookassaStore.load(),
      this.yooMoneyStore.load(),
      this.prodamusStore.load(),
      this.telegramPaymentsStore.load(),
      this.tinkoffStore.load()
    ]);

    if (promises.some((promise) => promise.isError)) {
      return {
        isError: true
      };
    }

    return {
      isError: false
    };
  }

  initReactions(): void {
    super.initReactions();

    this.domainsStore.initReactions();
    this.googleStore.initReactions();
    this.apiIntegrationStore.initReactions();
    this.getCourseStore.initReactions();
    this.amoCRMStore.initReactions();

    this.userPaymentsStore.initReactions();

    this.qiwiStore.initReactions();
    this.yookassaStore.initReactions();
    this.yooMoneyStore.initReactions();
    this.prodamusStore.initReactions();
    this.telegramPaymentsStore.initReactions();
    this.tinkoffStore.initReactions();
  }

  reset(): void {
    this.domainsStore.reset();
    this.googleStore.reset();
    this.apiIntegrationStore.reset();
    this.getCourseStore.reset();
    this.amoCRMStore.reset();

    this.userPaymentsStore.reset();

    this.qiwiStore.reset();
    this.yookassaStore.reset();
    this.yooMoneyStore.reset();
    this.prodamusStore.reset();
    this.telegramPaymentsStore.reset();
    this.tinkoffStore.reset();
  }

  get canBeLoaded(): boolean {
    return this._rootStore.initialized;
  }

  get loaded(): boolean {
    return (
      this.domainsStore.loaded &&
      this.googleStore.loaded &&
      this.apiIntegrationStore.loaded &&
      this.getCourseStore.loaded &&
      this.amoCRMStore.loaded &&
      this.userPaymentsStore.loaded &&
      this.qiwiStore.loaded &&
      this.yookassaStore.loaded &&
      this.yooMoneyStore.loaded &&
      this.prodamusStore.loaded &&
      this.telegramPaymentsStore.loaded &&
      this.tinkoffStore.loaded
    );
  }
}
