import axios, {
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
  Method,
  isCancel
} from 'axios';

import { stringifyParams } from 'shared/entities/domain';

import {
  ApiCallErrorRaw,
  ApiCallResponse,
  ApiCallResponseRaw
} from '../client';

import { configApiCall } from './helpers';

export enum ResponseCodes {
  OK_200 = 200,
  UNAUTHORIZED = 401,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  SERVER_ERROR = 500
}

export const callApi = async <R, E>(
  url: string,
  method: Method = 'GET',
  config: AxiosRequestConfig = {}
): Promise<BaseResponse<AxiosResponse<R>, AxiosError<E>>> => {
  try {
    const response = await axios<R>({
      method,
      url,
      paramsSerializer: {
        serialize: (params) => {
          return stringifyParams(params, { arrayFormat: 'comma' });
        }
      },
      ...config
    });
    return {
      isError: false,
      data: response
    };
  } catch (error) {
    return {
      isError: true,
      data: error
    };
  }
};

export const api = async <
  ResponseData extends Record<string, any> = Record<string, any>,
  ErrorCode extends string = string,
  ErrorData extends Record<string, any> = Record<string, any>
>(
  endpoint: string,
  methodType: Method = 'GET',
  rawData: Record<string, any> = {},
  config: AxiosRequestConfig = {},
  multipartFormData = false
): Promise<ApiCallResponse<ResponseData, ErrorCode, ErrorData>> => {
  const actualConfig = configApiCall(
    methodType,
    rawData,
    config,
    multipartFormData
  );

  const response = await callApi<
    ApiCallResponseRaw<ResponseData>,
    ApiCallErrorRaw<ErrorCode, ErrorData>
  >(endpoint, methodType, actualConfig);

  if (!response.isError) {
    return {
      isError: false,
      status: response.data.status,
      data: response.data.data.data,
      rawData: response.data.data
    };
  }

  return {
    isError: true,
    isCancelled: isCancel(response.data),
    data: response.data.response
      ? {
          ...response.data.response.data,
          status: response.data.response.status
        }
      : null
  };
};
