import * as React from 'react';

import { AppNotificationType } from 'shared/entities/appNotifications';
import { IconComponent, IconSize } from 'shared/entities/components/Icon';

import CircledCheckIcon from '../CircledCheckIcon';
import AlertIcon from '../AlertIcon';
import CircledExclamationMarkIcon from '../CircledExclamationMarkIcon';
import CircledQuestionIcon from '../CircledQuestionIcon';

import './NotificationIcon.modules.scss';

const mapTypeToIcon: Record<AppNotificationType, IconComponent> = {
  [AppNotificationType.success]: CircledCheckIcon,
  [AppNotificationType.warning]: AlertIcon,
  [AppNotificationType.error]: CircledExclamationMarkIcon,
  [AppNotificationType.info]: CircledQuestionIcon
};
type NotificationIconProps = {
  type: AppNotificationType;
  iconSize?: IconSize;
  className?: string;
};

const NotificationIcon: React.FC<NotificationIconProps> = ({
  type,
  iconSize,
  className
}: NotificationIconProps) => {
  const Icon = mapTypeToIcon[type];

  return (
    <Icon
      styleName={`notification-icon_${type}`}
      className={className}
      iconSize={iconSize ?? IconSize.XM}
    />
  );
};

export default React.memo(NotificationIcon);
