import {
  IProjectBaseVar,
  ProjectAttachmentVarServer,
  VarType
} from 'shared/entities/vars';
import { IRootStore } from 'shared/entities/store/rootStore';
import { normalizeFileBase } from 'shared/entities/file';

import ProjectVarBaseModel from './ProjectVarBaseModel';

export default class ProjectAttachmentVarModel
  extends ProjectVarBaseModel<VarType.attachment>
  implements IProjectBaseVar<VarType.attachment>
{
  static fromJson(
    raw: ProjectAttachmentVarServer & { rootStore: IRootStore }
  ): ProjectAttachmentVarModel {
    return new ProjectAttachmentVarModel({
      id: raw._id,
      projectId: raw.project_id,
      level: raw.level,
      key: raw.key,
      protectedProperty: raw.protected,
      type: VarType.attachment,
      value: raw.value ? normalizeFileBase(raw.value) : null,
      rootStore: raw.rootStore
    });
  }

  getCopy(): ProjectAttachmentVarModel {
    return new ProjectAttachmentVarModel({
      key: this.key,
      level: this.level,
      value: this.value,
      projectId: this.projectId,
      type: this.type,
      id: this.id,
      rootStore: this.rootStore,
      protectedProperty: this.protectedProperty
    });
  }
}
