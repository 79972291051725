import { action, makeObservable } from 'mobx';

import {
  IScenarioReaction,
  IScenarioReactionModel,
  mapReactionKindToEntity,
  normalizeScenarioReactionServer,
  ScenarioKind,
  ScenarioReactionServer
} from 'shared/entities/scenario';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls, urls } from 'shared/entities/domain';
import { Bucket } from 'shared/entities/bucket';
import {
  mapReactionCreateErrorToMessage,
  ReactionKind
} from 'shared/entities/reaction';
import { generateId } from 'shared/entities/common/utils';

import ScenarioBaseModel from './ScenarioBaseModel';

export default class ScenarioReactionModel
  extends ScenarioBaseModel
  implements Omit<IScenarioReactionModel, 'channelIds'>
{
  kind: ScenarioKind.reaction;
  reaction: ReactionKind;

  constructor(
    params: Omit<IScenarioReaction, 'channelIds'> & {
      channelIds: string[] | null;
      created?: boolean;
      rootStore: IRootStore;
    }
  ) {
    super(params);

    this.reaction = params.reaction;
    this.kind = params.kind;

    makeObservable(this, {
      create: action.bound
    });
  }

  async create(): Promise<BaseResponse> {
    if (this.created) {
      return {
        isError: false
      };
    }

    const response = await this.rootStore.networkStore.api<{
      scenario: ScenarioReactionServer;
    }>(apiUrls.SCENARIOS_CREATE, {
      method: 'POST',
      data: {
        kind: ScenarioKind.reaction,
        reaction: this.reaction,
        name: this.name,
        channel_ids: this.channelIds.value
      },
      errorsMap: mapReactionCreateErrorToMessage
    });

    if (!response.isError) {
      this.id = response.data.scenario._id;
      this.devEnabled = response.data.scenario.enabled_in.dev;
      this.prodEnabled = response.data.scenario.enabled_in.prod;
      this.hasChanges = response.data.scenario.has_changes;
      this.hasProdVersion = response.data.scenario.has_been_published;
      this.created = true;
    }

    return { isError: response.isError };
  }

  pushToPage = (): void => {
    if (!this.rootStore.projectId || !this.created) {
      return;
    }

    this.rootStore.routerStore.push(
      urls.PROJECT.tabs.SCENARIO.create({
        projectId: this.rootStore.projectId,
        scenarioId: this.id,
        bucket: Bucket.dev
      })
    );
  };

  handleTransition = async (): Promise<void> => {
    if (this.created) {
      this.pushToPage();
      return;
    }

    const { isError } = await this.create();

    if (!isError) {
      this.pushToPage();
      return;
    }
  };

  static fromJson(
    serverScenario: ScenarioReactionServer,
    rootStore: IRootStore
  ): ScenarioReactionModel {
    return new ScenarioReactionModel({
      ...normalizeScenarioReactionServer(serverScenario),
      name: mapReactionKindToEntity[serverScenario.reaction].name,
      rootStore
    });
  }

  static fromDefaultParams(
    rootStore: IRootStore,
    reactionKind: ReactionKind
  ): ScenarioReactionModel {
    return new ScenarioReactionModel({
      id: generateId(),
      updateId: generateId(),
      dateCreated: null,
      dateUpdated: null,
      name: '',
      channelIds: null,
      rootStore,
      reaction: reactionKind,
      hasChanges: false,
      hasProdVersion: false,
      prodEnabled: true,
      devEnabled: true,
      created: false,
      kind: ScenarioKind.reaction,
      preset: null,
      tags: null
    });
  }
}
