import { action, makeObservable, observable, runInAction } from 'mobx';
import debounce from 'lodash/debounce';

import { ISettingsStore } from 'shared/entities/store/settingsStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import {
  EventsQueueMode,
  ProjectSettingsServer,
  UnstopDelay
} from 'shared/entities/projectSettings';
import { LoadingStage } from 'shared/entities/meta';
import { apiUrls } from 'shared/entities/domain';
import {
  EnabledProjectSettingModel,
  ModeProjectModel
} from 'shared/models/projectSettings';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { SettingsType } from 'shared/entities/projectSettingsTypes';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';
import { FieldModel } from 'shared/models/form';
import { TranslationString } from 'shared/entities/localization';

const DEBOUNCED_LIKE_THRESHOLD_DELAY = 200;
const DEFAULT_UNSTOP_BY_MANAGER_DELAY = UnstopDelay.min15;

export default class SettingsStore
  extends ComponentLoadingStore
  implements ISettingsStore
{
  rootStore: IRootStore;
  loadingStage: LoadingStage = LoadingStage.NOT_STARTED;
  updatingStage: LoadingStage = LoadingStage.NOT_STARTED;

  debugMode: ModeProjectModel | null = null;
  useUnpublished: ModeProjectModel | null = null;
  enabledProjectSetting: EnabledProjectSettingModel | null = null;
  likeThreshold: number | null = null;
  timezone: string | null = null;
  eventsQueueMode: EventsQueueMode | null = null;
  unstopByManagerDelay: FieldModel<UnstopDelay> = new FieldModel<UnstopDelay>(
    DEFAULT_UNSTOP_BY_MANAGER_DELAY
  );

  constructor(rootStore: IRootStore) {
    super();

    makeObservable(this, {
      loadingStage: observable,
      debugMode: observable,
      useUnpublished: observable,
      likeThreshold: observable,
      enabledProjectSetting: observable,
      timezone: observable,
      eventsQueueMode: observable,

      updateThreshold: action,
      updateTimezone: action,
      update: action,
      updateEventsQueueMode: action
    });
    this.rootStore = rootStore;
  }

  get loaded(): boolean {
    return this.loadingStage === LoadingStage.SUCCESS;
  }

  get canBeLoaded(): boolean {
    return this.rootStore.initialized;
  }

  private debouncedLikeThreshold = debounce(async (preValue: number) => {
    const { isError } = await this.update(
      { [`${SettingsType.likeThreshold}`]: this.likeThreshold },
      (t) =>
        t('SettingsStore.notifications.likeThresholdError', {
          ns: 'stores'
        })
    );
    if (isError) {
      this.likeThreshold = preValue;
    }
  }, DEBOUNCED_LIKE_THRESHOLD_DELAY);

  updateEventsQueueMode = async (value: EventsQueueMode) => {
    const prevValue = this.eventsQueueMode;
    this.eventsQueueMode = value;
    const { isError } = await this.update(
      { [SettingsType.eventsQueueLockMode]: EventsQueueMode[value] },
      (t) =>
        t('SettingsStore.notifications.eventsQueueLockModeError', {
          ns: 'stores'
        })
    );
    if (!isError) {
      this.rootStore.appNotificationsStore.open({
        title: (t) =>
          t('SettingsStore.notifications.eventsQueueLockModeSuccess', {
            ns: 'stores'
          }),
        type: AppNotificationType.success
      });
    } else {
      this.eventsQueueMode = prevValue;
    }
  };

  updateThreshold = (value: number) => {
    this.debouncedLikeThreshold(this.likeThreshold || 0);
    this.likeThreshold = value;
  };

  updateTimezone = async (value: string) => {
    const prevValue = this.timezone;
    this.timezone = value;
    const { isError } = await this.update(
      { [SettingsType.timezone]: value },
      (t) =>
        t('SettingsStore.notifications.timezoneError', {
          ns: 'stores'
        })
    );
    if (!isError) {
      this.rootStore.appNotificationsStore.open({
        title: (t) =>
          t('SettingsStore.notifications.timezoneSuccess', {
            ns: 'stores'
          }),
        type: AppNotificationType.success
      });
    } else {
      this.timezone = prevValue;
    }
  };

  updateUnstopByManagerDelay = async (value: UnstopDelay) => {
    const prevValue = this.unstopByManagerDelay.value;
    this.unstopByManagerDelay.changeValue(value);
    const { isError } = await this.update(
      { [SettingsType.unstopByManagerDelay]: value },
      (t) =>
        t('SettingsStore.notifications.unstopByManagerDelayError', {
          ns: 'stores'
        })
    );
    if (!isError) {
      this.rootStore.appNotificationsStore.open({
        title: (t) =>
          t('SettingsStore.notifications.unstopByManagerDelaySuccess', {
            ns: 'stores'
          }),
        type: AppNotificationType.success
      });
    } else {
      this.unstopByManagerDelay.changeValue(prevValue);
    }
  };

  async update(
    params: Partial<ProjectSettingsServer>,
    errorMessage: TranslationString
  ): Promise<BaseResponse> {
    if (this.updatingStage === LoadingStage.LOADING) {
      return { isError: true };
    }

    this.updatingStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api(
      apiUrls.PROJECTS_SET_SETTINGS,
      { method: 'POST', data: params }
    );

    runInAction(() => {
      if (response.isError) {
        this.rootStore.appNotificationsStore.open({
          title: errorMessage,
          type: AppNotificationType.error
        });
        this.updatingStage = LoadingStage.ERROR;
        return { isError: true };
      }
      this.updatingStage = LoadingStage.SUCCESS;
      return { isError: false };
    });
    return { isError: response.isError };
  }

  async load(): Promise<BaseResponse> {
    if (this.loadingStage === LoadingStage.LOADING || !this.canBeLoaded) {
      return { isError: true };
    }

    this.loadingStage = LoadingStage.LOADING;

    const response =
      await this.rootStore.networkStore.api<ProjectSettingsServer>(
        apiUrls.PROJECTS_LIST_SETTINGS
      );

    if (!response.isError) {
      runInAction(() => {
        this.debugMode = ModeProjectModel.fromJson({
          raw: response.data.debug_mode,
          settingsStore: this,
          type: SettingsType.debugMode
        });
        this.useUnpublished = ModeProjectModel.fromJson({
          raw: response.data.use_unpublished,
          settingsStore: this,
          type: SettingsType.useUnpublished
        });
        this.enabledProjectSetting = EnabledProjectSettingModel.fromJson({
          raw: response.data.bot_enabled,
          settingsStore: this
        });
        this.unstopByManagerDelay.changeValue(
          response.data.unstop_by_manager_delay
        );
        this.eventsQueueMode = response.data.events_queue_lock_mode;
        this.likeThreshold = response.data.like_threshold;
        this.timezone = response.data.timezone;
        this.loadingStage = LoadingStage.SUCCESS;
      });
    } else {
      runInAction(() => {
        this.loadingStage = LoadingStage.ERROR;
      });
    }

    return { isError: response.isError };
  }

  reset() {
    this.debugMode = null;
    this.useUnpublished = null;
    this.enabledProjectSetting = null;
    this.eventsQueueMode = null;
    this.likeThreshold = null;
    this.timezone = null;
    this.loadingStage = LoadingStage.NOT_STARTED;
    this.updatingStage = LoadingStage.NOT_STARTED;
  }
}
