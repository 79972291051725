import { observer } from 'mobx-react';
import * as React from 'react';

import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import FadeScroll from 'shared/newComponents/FadeScroll';

import Token from './Token';
import TokenCreation from './TokenCreation';

import './Tokens.modules.scss';

const Tokens: React.FC = () => {
  const { store } = useChannelsModalStore();

  const channel = store.selectedChannel;

  if (!channel) {
    return null;
  }

  const isEmpty = !channel.tokens.items.length;

  return (
    <div styleName="tokens">
      {isEmpty && (
        <Typography
          styleName="tokens__stub"
          fontType={TypographyType.text}
          fontSize={TypographySize.s}
          fontColor={TypographyColor.secondary}
          fontWeight={TypographyWeight.heavy}
        >
          {(t) =>
            t('ChannelsModal.SelectedChannel.Content.Tokens.stub', {
              ns: 'newContainers'
            })
          }
        </Typography>
      )}
      {!isEmpty && (
        <FadeScroll styleName="tokens__list">
          {channel.tokens.items.map((token) => (
            <Token key={token.id} styleName="tokens__token" token={token} />
          ))}
        </FadeScroll>
      )}
      <TokenCreation styleName="tokens__creation" />
    </div>
  );
};

export default observer(Tokens);
