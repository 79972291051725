import { DateFormat, parseDate, transformDate } from 'shared/entities/date';
import { DateBaseModel } from 'shared/models/components/DatePicker';
import { DateInputDateFormat } from 'shared/newEntities/components/DateInput';

export const mapFormatToTemplate = {
  [DateFormat.dayMonthYear]: 'xx.xx.xxxx',
  [DateFormat.dayMonthYearTime]: 'xx.xx.xxxx xx:xx'
};

export const mapFormatToPlaceholder = {
  [DateFormat.dayMonthYear]: '__.__.____',
  [DateFormat.dayMonthYearTime]: '__.__.____ __:__'
};

export const transformToString = (
  model: DateBaseModel | null,
  format: DateInputDateFormat
): string => {
  if (!model) {
    return '';
  }
  switch (format) {
    case DateFormat.dayMonthYear:
      return transformDate(model.date, DateFormat.dayMonthYearWithoutDots);
    case DateFormat.dayMonthYearTime:
      return transformDate(model.date, DateFormat.dayMonthYearTimeWithoutDots);
  }
};

const validateDate = (value: string): BaseResponse<Date> => {
  const groups = value.match(
    /^(?<day>\d{2})(?<month>\d{2})(?<year>\d{4})$/
  )?.groups;

  if (!groups || !groups.day || !groups.month || !groups.year) {
    return { isError: true };
  }

  const date = parseDate(
    `${groups.day}.${groups.month}.${groups.year}`,
    DateFormat.dayMonthYear
  );
  if (!date) {
    return { isError: true };
  }
  return {
    isError: false,
    data: date
  };
};
const validateDateTime = (value: string): BaseResponse<Date> => {
  const groups = value.match(
    /^(?<day>\d{2})(?<month>\d{2})(?<year>\d{4})(?<hours>\d{2})(?<minutes>\d{2})$/
  )?.groups;

  if (
    !groups ||
    !groups.day ||
    !groups.month ||
    !groups.year ||
    !groups.hours ||
    !groups.minutes
  ) {
    return { isError: true };
  }

  const date = parseDate(
    `${groups.day}.${groups.month}.${groups.year} ${groups.hours}:${groups.minutes}`,
    DateFormat.dayMonthYearTime
  );

  if (!date) {
    return { isError: true };
  }
  return {
    isError: false,
    data: date
  };
};
/*
 * Определяет, является ли строка датой
 * @param value: string
 * @param dateFormat?: DateInputDateFormat - если undefined, поддерживаем оба формата
 * @returns BaseResponse<Date>
 */
export const validateStringValue = (
  value: string,
  dateFormat?: DateInputDateFormat
): BaseResponse<{ date: Date; format: DateInputDateFormat }> => {
  if (dateFormat) {
    if (dateFormat === DateFormat.dayMonthYear) {
      const dateResult = validateDate(value);
      return dateResult.isError
        ? dateResult
        : {
            isError: false,
            data: { date: dateResult.data, format: DateFormat.dayMonthYear }
          };
    } else {
      const dateTimeResult = validateDateTime(value);
      return dateTimeResult.isError
        ? dateTimeResult
        : {
            isError: false,
            data: {
              date: dateTimeResult.data,
              format: DateFormat.dayMonthYearTime
            }
          };
    }
  }
  const result = validateDate(value);
  if (result.isError) {
    const dateTimeResult = validateDateTime(value);
    return dateTimeResult.isError
      ? dateTimeResult
      : {
          isError: false,
          data: {
            date: dateTimeResult.data,
            format: DateFormat.dayMonthYearTime
          }
        };
  }
  return {
    isError: false,
    data: { date: result.data, format: DateFormat.dayMonthYear }
  };
};
