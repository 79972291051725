import { runInAction } from 'mobx';

import { LoadingStageModel } from 'shared/models/loadingStage';

const POLLING_TIMEOUT = 2000;

export type TimeoutHandlerType = () => Promise<BaseResponse> | BaseResponse;

export class PollingModel {
  readonly pollingStage: LoadingStageModel = new LoadingStageModel();

  protected timeout: ReturnType<typeof setInterval> | null = null;
  private pollingAttemptsCount = 0;
  private cb: TimeoutHandlerType;
  private cbOnMaxAttempts: TimeoutHandlerType | null;
  private maxAttemptsCount: number;

  constructor({
    cb,
    cbOnMaxAttempts = null,
    maxAttemptsCount = 60
  }: {
    cb: TimeoutHandlerType;
    cbOnMaxAttempts?: TimeoutHandlerType | null;
    maxAttemptsCount?: number;
  }) {
    this.cb = cb;
    this.cbOnMaxAttempts = cbOnMaxAttempts;
    this.maxAttemptsCount = maxAttemptsCount;

    this.stopPolling = this.stopPolling.bind(this);
    this.startPolling = this.startPolling.bind(this);
  }

  stopPolling(): void {
    if (!this.timeout) {
      return;
    }

    clearInterval(this.timeout);
    this.timeout = null;
    this.pollingAttemptsCount = 0;
    this.pollingStage.reset();
  }

  private handleCallback = async (
    resolve: (response: BaseResponse) => void
  ) => {
    if (this.pollingAttemptsCount >= this.maxAttemptsCount) {
      this.cbOnMaxAttempts?.();
      this.stopPolling();

      resolve({
        isError: true
      });
      return;
    }

    this.pollingStage.loading();

    const { isError } = await this.cb();

    this.pollingAttemptsCount++;

    if (!isError) {
      runInAction(() => {
        this.stopPolling();
      });

      resolve({
        isError: false
      });
    }
  };

  startPolling(): Promise<BaseResponse> {
    return new Promise<BaseResponse>((resolve) => {
      if (this.timeout) {
        this.stopPolling();
      }

      this.timeout = setInterval(
        () => this.handleCallback(resolve),
        POLLING_TIMEOUT
      );
    });
  }
}
