import * as React from 'react';
import { createPortal } from 'react-dom';

import { AnalyticsEventObj } from 'shared/entities/analytics';
import { PORTAL_IDS } from 'shared/entities/app';
import { convertStringToNumber } from 'shared/entities/common/utils';
import { AnalyticsModel } from 'shared/models/analytics/AnalyticsModel';

const YM_CONTAINER = process.env.IS_PROD
  ? '88450674'
  : (process.env.YM_CONTAINER as string);

export default class YandexAnalytics extends AnalyticsModel {
  protected get enabled(): boolean {
    return true;
  }

  private get ymContainer(): number {
    return convertStringToNumber(YM_CONTAINER);
  }

  protected executeScript(onSuccess: () => void, onError: () => void): void {
    const ymContainerNode = document.getElementById(PORTAL_IDS.YANDEX_METRIKA);

    if (!ymContainerNode) {
      return;
    }

    (function (m, e, t, r, i, k, a) {
      m[i] =
        m[i] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (m[i].a = m[i].a || []).push(arguments);
        };
      // @ts-ignore
      m[i].l = 1 * new Date();
      // @ts-ignore
      k = e.createElement(t);
      // @ts-ignore
      a = e.getElementsByTagName(t)[0];
      // @ts-ignore
      k.async = 1;
      // @ts-ignore
      k.src = r;
      if (k) {
        // @ts-ignore
        k.onload = onSuccess;
        // @ts-ignore
        k.onerror = onError;
      }
      // @ts-ignore
      a.parentNode.insertBefore(k, a);
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
    createPortal(
      <div>
        <img
          src={`https://mc.yandex.ru/watch/${YM_CONTAINER}`}
          style={{
            position: 'absolute',
            left: '-9999px'
          }}
          alt=""
        />
      </div>,
      ymContainerNode
    );

    window.ym(this.ymContainer, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    });
  }

  sendEvent(event: AnalyticsEventObj): void {
    window.ym(this.ymContainer, 'reachGoal', event.type, event.payload || {});
  }

  /**
   * Получение id яндекс метрики
   * @returns {Promise<BaseResponse<string>>}
   */
  getId = async (): Promise<BaseResponse<string>> => {
    if (!this.initialized.value) {
      return {
        isError: true
      };
    }

    return new Promise((res) => {
      window.ym(this.ymContainer, 'getClientID', (id) =>
        res({
          isError: false,
          data: id
        })
      );
    });
  };
}
