import * as React from 'react';
import { observer } from 'mobx-react';

import { useChannelsModalStore } from 'stores/ChannelsModalStore';

import CreationChannel from './CreationChannel';
import Content from './Content';
import SelectedChannel from './SelectedChannel';
import Tabs from './Tabs';
import MobileContainer from './MobileContainer';

import './ChannelsContent.modules.scss';

type ChannelsContentProps = {
  footer?: React.ReactNode;
  className?: string;
};

const ChannelsContent: React.FC<ChannelsContentProps> = ({
  footer,
  className
}: ChannelsContentProps) => {
  const { store } = useChannelsModalStore();
  return (
    <>
      {store.withSections && (
        <div styleName="channels" className={className}>
          <CreationChannel />
          <Content styleName="channels__content" footer={footer} />
          <SelectedChannel />
        </div>
      )}
      {!store.withSections && (
        <div styleName="channels channels_column" className={className}>
          <Tabs inModal={store.isModal} />
          <MobileContainer styleName="channels_mobile" />
        </div>
      )}
    </>
  );
};

export default observer(ChannelsContent);
