import {
  ProjectSettingsTypes,
  ProjectEnableForSetting
} from '../projectSettingsTypes';

export type ModeProjectServer = {
  enabled: boolean;
  in: ProjectSettingsTypes;
};

export type EnabledProjectSettingServer = {
  enabled: boolean;
  for: ProjectEnableForSetting;
};

export enum EventsQueueMode {
  project = 'project',
  user = 'user'
}

export type ProjectSettingsServer = {
  debug_mode: ModeProjectServer;
  use_unpublished: ModeProjectServer;
  bot_enabled: EnabledProjectSettingServer;
  like_threshold: number;
  timezone: string;
  events_queue_lock_mode: EventsQueueMode;
  //в минутах
  unstop_by_manager_delay: number;
};
