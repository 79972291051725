import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const ChannelsIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M19.6465 3C18.8126 3 18.0985 3.5137 17.8099 4.23725L12.9693 7.05944H7.38786C4.96848 7.05944 3 9.00397 3 11.3939C3 13.7838 4.96848 15.7284 7.38786 15.7284H8.51733V18.7228C8.51733 19.9782 9.55105 21 10.8226 21H11.3109C12.5818 21 13.6161 19.9789 13.6161 18.7228V16.0892L17.8277 18.4591C18.127 19.1601 18.8297 19.6537 19.6471 19.6537C20.7354 19.6537 21.6207 18.7792 21.6207 17.7042V4.94954C21.6207 3.87453 20.7354 3 19.6465 3ZM4.01533 11.3933C4.01533 9.55588 5.52847 8.06115 7.38851 8.06115H12.6014V14.7248H9.02532H7.38851C5.52847 14.7254 4.01533 13.2307 4.01533 11.3933ZM12.6008 18.7222C12.6008 19.4251 12.0218 19.997 11.3102 19.997H10.822C10.1104 19.997 9.5314 19.4251 9.5314 18.7222V15.7277H12.6002V18.7222H12.6008ZM13.6155 7.8469L17.6729 5.48077V17.2181L13.6155 14.9353V7.8469ZM20.606 17.7036C20.606 18.226 20.176 18.6508 19.6471 18.6508C19.1182 18.6508 18.6882 18.226 18.6882 17.7036V4.94954C18.6882 4.42707 19.1182 4.00233 19.6471 4.00233C20.176 4.00233 20.606 4.42707 20.606 4.94954V17.7036Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ChannelsIcon;
