import * as React from 'react';
import { observer } from 'mobx-react';

import TypedTransComponent from 'shared/components/TypedTransComponent';
import { useSupportPopupStore } from 'stores/index';

const ForbiddenErrorMessage: React.FC = () => {
  const supportPopupStore = useSupportPopupStore();
  return (
    <TypedTransComponent
      ns="stores"
      i18nKey="NetworkStore.notifications.forbidden"
    >
      У&nbsp;вас нет доступа к&nbsp;редактированию этого проекта.
      <br />
      Обратитесь к&nbsp;администратору кабинета для повышения прав.
      <br />
      Если у&nbsp;вас остались вопросы, напишите в{' '}
      <span className="link-new" onClick={supportPopupStore.openChat}>
        тех.&nbsp;поддержку
      </span>
    </TypedTransComponent>
  );
};

export default observer(ForbiddenErrorMessage);
