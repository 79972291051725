import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import {
  ISqCondition,
  ISqConditionModel,
  SqConditionServer
} from 'shared/entities/condition';
import { validateIsEmpty, ValidatorResult } from 'shared/entities/validator';
import { generateId } from 'shared/entities/common/utils';
import { LoadingStage } from 'shared/entities/meta';
import { apiUrls } from 'shared/entities/domain';
import { IRootStore } from 'shared/entities/store/rootStore';
import { TFunctionType } from 'shared/entities/localization';
import { BaseResponseCode } from 'shared/entities/network';

type SqCodeState = BaseResponse<
  null,
  {
    error: string;
  }
> | null;

export default class SqConditionModel implements ISqConditionModel {
  id: string;
  expr: string;

  private _textError: ValidatorResult = null;
  private _codeState: SqCodeState = null;
  private _validatingCodeStage: LoadingStage = LoadingStage.NOT_STARTED;
  private _rootStore: IRootStore;

  constructor({ expr, id }: ISqCondition, rootStore: IRootStore) {
    this.id = id;
    this.expr = expr;
    this._rootStore = rootStore;

    makeObservable<
      SqConditionModel,
      '_textError' | '_codeState' | '_validatingCodeStage'
    >(this, {
      expr: observable,
      _textError: observable,
      _codeState: observable.ref,
      _validatingCodeStage: observable,

      changeExpr: action.bound,
      validate: action.bound,
      resetError: action.bound,
      validateText: action.bound,
      validateCode: action.bound,

      error: computed,
      textError: computed,
      validatingCodeStage: computed,
      codeState: computed
    });
  }

  get validatingCodeStage(): LoadingStage {
    return this._validatingCodeStage;
  }

  get textError(): ValidatorResult {
    return this._textError;
  }

  get codeState(): SqCodeState {
    return this._codeState;
  }

  get hint(): ValidatorResult {
    if (this.textError) {
      return this.textError;
    }

    if (this._codeState) {
      if (this._codeState.isError) {
        return this._codeState.data.error;
      }

      return (t: TFunctionType) =>
        t('condition.SqConditionModel.errors.noErrors', {
          ns: 'models'
        });
    }

    return null;
  }

  get error(): ValidatorResult {
    const errorMessage = (t: TFunctionType) =>
      t('condition.SqConditionModel.errors.hasErrors', {
        ns: 'models'
      });

    if (this.textError) {
      return errorMessage;
    }

    return this.codeState && this.codeState.isError ? errorMessage : null;
  }

  validateText(): void {
    this._textError = validateIsEmpty(this.expr);
  }

  validate(): void {
    this.validateText();
  }

  async validateCode(): Promise<void> {
    if (this._validatingCodeStage === LoadingStage.LOADING) {
      return;
    }

    this._validatingCodeStage = LoadingStage.LOADING;

    const response = await this._rootStore.networkStore.api(
      apiUrls.VARS_CHECK_SQ,
      {
        method: 'POST',
        data: {
          expr: this.expr
        },
        showUnexpectedError: false,
        expectedErrorCodes: [BaseResponseCode.badRequest]
      }
    );

    runInAction(() => {
      if (response.isError) {
        this._validatingCodeStage = LoadingStage.ERROR;

        const error = response.data?.message;

        if (!error) {
          return;
        }

        this._codeState = {
          isError: true,
          data: {
            error: error
          }
        };
      } else {
        this._codeState = {
          isError: false,
          data: null
        };
        this._validatingCodeStage = LoadingStage.SUCCESS;
      }
    });
  }

  resetError(): void {
    this._textError = null;
    this._codeState = null;
  }

  changeExpr(value: string): void {
    this.resetError();
    this.expr = value;
  }

  toJson(): SqConditionServer {
    return {
      expr: this.expr
    };
  }

  getCopy(): SqConditionModel {
    return new SqConditionModel(
      {
        id: this.id,
        expr: this.expr
      },
      this._rootStore
    );
  }

  isEqual(condition: SqConditionModel): boolean {
    return this.expr === condition.expr;
  }

  static fromJson(
    raw: SqConditionServer,
    rootStore: IRootStore
  ): SqConditionModel {
    return new SqConditionModel(
      {
        id: generateId(),
        expr: raw.expr
      },
      rootStore
    );
  }

  static fromDefaultParams({
    rootStore,
    value = ''
  }: {
    rootStore: IRootStore;
    value?: string;
  }): SqConditionModel {
    return new SqConditionModel(
      {
        id: generateId(),
        expr: value
      },
      rootStore
    );
  }
}
