import {
  UserTokensServer,
  ManagerExternalServer,
  ManagerExternalsServer,
  UserExtraServer,
  OauthInfoServer
} from './server';
import {
  UserTokens,
  IManagerExternal,
  ManagerExternals,
  IUserExtra,
  IOauthInfo
} from './client';

export const normalizeUserTokens = (raw: UserTokensServer): UserTokens => ({
  vk: raw.vk || false,
  fb: raw.fb || false,
  ig: raw.ig || false
});

export const normalizeManagerExternal = (
  raw: ManagerExternalServer
): IManagerExternal => ({
  id: raw.id,
  firstName: raw.first_name || '',
  lastName: raw.last_name || '',
  link: raw.link || '',
  username: raw.username || ''
});

export const normalizeManagerExternals = (
  raw: ManagerExternalsServer
): ManagerExternals => {
  return Object.entries(raw).reduce<ManagerExternals>(
    (acc, [rawKey, rawValue]) => ({
      ...acc,
      [rawKey]: normalizeManagerExternal(rawValue)
    }),
    {} as ManagerExternals
  );
};

export const normalizeUserExtra = (raw: UserExtraServer): IUserExtra => ({
  registeredViaElama: raw.registered_via_elama || false
});

export const normalizeOauthInfo = (raw: OauthInfoServer): IOauthInfo => ({
  ...raw,
  tgBotId: raw.tg_bot_id
});
