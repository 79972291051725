import { SelectorEntities } from 'shared/entities/components/Selector';

export enum ApiTokenScope {
  executeBlock = 'execute_block',
  manageVars = 'manage_vars'
}

export const apiTokenScopeEntities: SelectorEntities<ApiTokenScope> = {
  [ApiTokenScope.executeBlock]: {
    id: ApiTokenScope.executeBlock,
    title: (t) =>
      t('apiIntegration.apiTokenScopes.executeBlock', {
        ns: 'entities'
      })
  },
  [ApiTokenScope.manageVars]: {
    id: ApiTokenScope.manageVars,
    title: (t) =>
      t('apiIntegration.apiTokenScopes.manageVars', {
        ns: 'entities'
      })
  }
};

export const apiTokenScopeOrder: ApiTokenScope[] = [ApiTokenScope.executeBlock];

export type ApiTokenServer = {
  _id: string;
  name: string;
  access_token: string;
  scope: ApiTokenScope[];
  date_created: string;
};
