import { observer } from 'mobx-react';
import * as React from 'react';
import cn from 'classnames';

import {
  ChannelTokenStatus,
  IChannelToken,
  mapChannelTokenScopeToBadgeColor
} from 'shared/entities/channels';
import {
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import Badge from 'shared/newComponents/Badge';
import { Button } from 'shared/newComponents/buttons';
import {
  ButtonColor,
  ButtonSize,
  ButtonStyle
} from 'shared/newEntities/components/Button';
import {
  CircledExclamationMarkIcon,
  RemoveIcon
} from 'shared/newComponents/icons';
import { ComponentType } from 'shared/entities/components/Component';
import { IconSize } from 'shared/entities/components/Icon';
import Reference from 'shared/newComponents/Reference';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import { useSupportPopupStore } from 'stores';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { noop } from 'shared/entities/common/utils';

import './Token.modules.scss';

type Props = {
  className?: string;
  token: IChannelToken;
};

const Token: React.FC<Props> = ({ className, token }) => {
  const supportPopupStore = useSupportPopupStore();
  const isError = token.status !== ChannelTokenStatus.ok;
  const { store } = useChannelsModalStore();

  const channel = store.selectedChannel;

  if (!channel) {
    return null;
  }

  const handleRemove = React.useCallback(() => {
    if (!channel) {
      return;
    }

    channel.removeToken(token.id);
  }, [token.id]);

  return (
    <div className={className}>
      <div styleName={cn('token', isError && 'token_error')}>
        <div styleName="token__left">
          <Typography
            styleName="token__title"
            fontType={TypographyType.text}
            fontSize={TypographySize.m}
            fontWeight={TypographyWeight.heavy}
          >
            {token.id}
          </Typography>
          {!!token.scopes.length && (
            <div styleName="token__badges">
              {token.scopes.map((scope) => (
                <Badge
                  styleName="token__badge"
                  key={scope}
                  color={mapChannelTokenScopeToBadgeColor[scope]}
                >
                  {(t) =>
                    t(
                      `ChannelsModal.SelectedChannel.Content.Tokens.scopes.${scope}`,
                      {
                        ns: 'newContainers'
                      }
                    )
                  }
                </Badge>
              ))}
            </div>
          )}
        </div>
        <Button
          styleName="token__button"
          type={ComponentType.button}
          onClick={handleRemove}
          size={ButtonSize.sm}
          color={ButtonColor.lightRed}
          loadingStage={token.removingStage.value}
          Icon={RemoveIcon}
        />
      </div>
      {isError && (
        <div styleName="token__error-container">
          <div styleName="token__error">
            <CircledExclamationMarkIcon
              styleName="token__error-icon"
              iconSize={IconSize.XM}
            />
            <Typography
              styleName="token__error-title"
              fontType={TypographyType.text}
              fontSize={TypographySize.s}
              fontColor={null}
            >
              {(t) =>
                t('ChannelsModal.SelectedChannel.Content.Tokens.error.title', {
                  ns: 'newContainers'
                })
              }
            </Typography>
          </div>
          <Reference
            title={(t) =>
              t(
                'ChannelsModal.SelectedChannel.Content.Tokens.error.reference.title',
                {
                  ns: 'newContainers'
                }
              )
            }
            description={
              token.error ? (
                <TypedTransComponent
                  ns="newContainers"
                  i18nKey="ChannelsModal.SelectedChannel.Content.Tokens.error.reference.descriptionWithError"
                >
                  Если проблема сохранится, напишите в{' '}
                  <span
                    onClick={supportPopupStore.openChat}
                    styleName="token__link"
                  >
                    поддержку
                  </span>{' '}
                  и прикрепите это сообщение:
                  <br />
                  <br />«{{ error: token.error }}»
                </TypedTransComponent>
              ) : (
                <TypedTransComponent
                  ns="newContainers"
                  i18nKey="ChannelsModal.SelectedChannel.Content.Tokens.error.reference.description"
                >
                  Если проблема сохранится, напишите в{' '}
                  <span
                    onClick={supportPopupStore.openChat}
                    styleName="token__link"
                  >
                    поддержку
                  </span>
                </TypedTransComponent>
              )
            }
            trigger={
              <div>
                <Button
                  type={ComponentType.button}
                  onClick={noop}
                  color={ButtonColor.blue}
                  style={ButtonStyle.transparent}
                  withoutPadding
                >
                  {(t) =>
                    t(
                      'ChannelsModal.SelectedChannel.Content.Tokens.error.reference.button',
                      {
                        ns: 'newContainers'
                      }
                    )
                  }
                </Button>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};

export default observer(Token);
