import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const DocumentIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{ viewBox: '0 0 24 24' }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.1062 6.73606L14.7161 3.5291C14.3575 3.18871 13.8817 2.99927 13.3872 3H6.93359C5.8673 3 5 3.86731 5 4.9336V19.0664C5 20.1327 5.8673 21 6.93359 21H16.7773C17.8436 21 18.7109 20.1327 18.7109 19.0664V8.14055C18.7105 7.8777 18.6566 7.61769 18.5527 7.37627C18.4487 7.13485 18.2969 6.91705 18.1062 6.73606V6.73606ZM17.0818 7.21875H14.457C14.4104 7.21875 14.3657 7.20023 14.3327 7.16727C14.2998 7.1343 14.2812 7.08959 14.2812 7.04297V4.56938L17.0818 7.21875ZM16.7773 19.9453H6.93359C6.44879 19.9453 6.05469 19.5512 6.05469 19.0664V4.9336C6.05469 4.44879 6.44879 4.05469 6.93359 4.05469H13.2266V7.04297C13.2266 7.72149 13.7785 8.27344 14.457 8.27344H17.6562V19.0664C17.6562 19.5512 17.2621 19.9453 16.7773 19.9453Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.6"
        />
        <path
          d="M15.6172 10.0312H7.88281C7.74295 10.0312 7.60882 10.0868 7.50992 10.1857C7.41103 10.2846 7.35547 10.4187 7.35547 10.5586C7.35547 10.6985 7.41103 10.8326 7.50992 10.9315C7.60882 11.0304 7.74295 11.0859 7.88281 11.0859H15.6172C15.757 11.0859 15.8912 11.0304 15.9901 10.9315C16.089 10.8326 16.1445 10.6985 16.1445 10.5586C16.1445 10.4187 16.089 10.2846 15.9901 10.1857C15.8912 10.0868 15.757 10.0312 15.6172 10.0312ZM15.6172 12.8438H7.88281C7.74295 12.8438 7.60882 12.8993 7.50992 12.9982C7.41103 13.0971 7.35547 13.2312 7.35547 13.3711C7.35547 13.511 7.41103 13.6451 7.50992 13.744C7.60882 13.8429 7.74295 13.8984 7.88281 13.8984H15.6172C15.757 13.8984 15.8912 13.8429 15.9901 13.744C16.089 13.6451 16.1445 13.511 16.1445 13.3711C16.1445 13.2312 16.089 13.0971 15.9901 12.9982C15.8912 12.8993 15.757 12.8438 15.6172 12.8438ZM10.4394 15.6562H7.88281C7.74295 15.6562 7.60882 15.7118 7.50992 15.8107C7.41103 15.9096 7.35547 16.0437 7.35547 16.1836C7.35547 16.3235 7.41103 16.4576 7.50992 16.5565C7.60882 16.6554 7.74295 16.7109 7.88281 16.7109H10.4394C10.5792 16.7109 10.7134 16.6554 10.8123 16.5565C10.9112 16.4576 10.9667 16.3235 10.9667 16.1836C10.9667 16.0437 10.9112 15.9096 10.8123 15.8107C10.7134 15.7118 10.5792 15.6562 10.4394 15.6562V15.6562Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.6"
        />
      </svg>
    </BaseIcon>
  );
};

export default DocumentIcon;
