import { SelectorEntities } from 'shared/entities/components/Selector';
import { IField } from 'shared/entities/form';
import { TFunctionType } from 'shared/entities/localization';
import { Validator, ValidatorResult } from 'shared/entities/validator';

import { VarType } from '../server';

export enum DateFieldType {
  date = 'date',
  variable = 'variable'
}

export const dateFieldTypeEntities: SelectorEntities<DateFieldType> =
  Object.values(DateFieldType).reduce<SelectorEntities<DateFieldType>>(
    (acc, item) => ({
      ...acc,
      [item]: {
        id: item,
        title: (t: TFunctionType) =>
          t(`block.ActionSetTimer.dateFieldTypeEntities.${item}`, {
            ns: 'entities'
          })
      }
    }),
    {} as SelectorEntities<DateFieldType>
  );

export const dateFieldTypeOrder: DateFieldType[] = [
  DateFieldType.date,
  DateFieldType.variable
];

export interface IDateVarValueModel {
  readonly date: IField<Date | null>;
  readonly variable: IField<string | null>;
  readonly fieldType: IField<DateFieldType>;
  readonly dateValidator?: Validator<Date>;
  readonly varType: VarType.date | VarType.datetime;

  readonly actualName: string;
  readonly actualValue: string;
  readonly error: ValidatorResult;

  changeFieldType(value: DateFieldType);
  validate(): void;
  resetError(): void;
  toJson(): string;
  getCopy(): IDateVarValueModel;
  isEqual(rhs: IDateVarValueModel): boolean;
}
