import { isSameDay } from 'date-fns';
import { computed, makeObservable } from 'mobx';

import {
  getPositionOfDateInInterval,
  validateIntervalAndReshuffle
} from 'shared/entities/common/utils';
import {
  DatePositionInInterval,
  IDateRangePicker
} from 'shared/newEntities/components/DatePicker';

import { CalendarDateModel } from './CalendarDateModel';

export default class IntervalDateModel extends CalendarDateModel<IDateRangePicker> {
  constructor(params: {
    date: Date;
    monthDate: Date;
    datePicker: IDateRangePicker;
  }) {
    super(params);

    makeObservable(this, {
      isActive: computed,
      positionInInterval: computed
    });
  }

  get isActive(): boolean {
    return (
      (!!this.datePicker.firstValue.value &&
        isSameDay(this.datePicker.firstValue.value.date, this.date)) ||
      (!!this.datePicker.secondValue.value &&
        isSameDay(this.datePicker.secondValue.value.date, this.date))
    );
  }

  /**
   * Позиция данной даты в интервалах
   * @returns {DatePositionInInterval | null}
   */
  get positionInInterval(): DatePositionInInterval {
    if (this.datePicker.firstValue.value && this.datePicker.secondValue.value) {
      return getPositionOfDateInInterval({
        date: this.date,
        interval: [
          this.datePicker.firstValue.value.date,
          this.datePicker.secondValue.value.date
        ]
      });
    }

    if (
      this.datePicker.firstValue.value &&
      this.datePicker.hoverableDate.value
    ) {
      return getPositionOfDateInInterval({
        date: this.date,
        interval: validateIntervalAndReshuffle(
          this.datePicker.firstValue.value.date,
          this.datePicker.hoverableDate.value.date
        )
      });
    }

    return null;
  }
}
