import { BaseResponseCode } from 'shared/entities/network';
import { TranslationString } from 'shared/entities/localization';

import { ExtraBlocksServer } from '../block/server';
import { Permission } from '../permissions';
import { ProjectManagerRoleType } from '../manager';

export type ProjectServer = {
  _id: string;
  name: string;
  permissions: Permission[];
  role: ProjectManagerRoleType;
};

export type ProjectOptionsServer = {
  extra_blocks: ExtraBlocksServer;
};

export type GetProjectServer = {
  _id: string;
  name: string;
  permissions: Permission[];
  options: ProjectOptionsServer;
  role: ProjectManagerRoleType;
};

export const mapBaseErrorCodeToProjectErrorToMessage =
  (
    code: BaseResponseCode.notFound | BaseResponseCode.forbidden
  ): TranslationString =>
  (t) =>
    t(`projects.errors.getting.${code}`, {
      ns: 'entities'
    });
