import * as React from 'react';
import cn from 'classnames';

import BlurrableWrapper from 'shared/components/Blurable';
import Portal from 'shared/components/Portal';
import { PORTAL_IDS } from 'shared/entities/app';
import ModalEventsHandler from 'shared/components/windows/ModalEventsHandler';

import './FadeBackground.modules.scss';

type Props = {
  opened: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  contentFullSize?: boolean;
  withPadding?: boolean;
  widthLikePage?: boolean;
  className?: string;
};

const FadeBackground: React.FC<Props> = ({
  opened,
  onClose,
  children,
  contentFullSize = false,
  withPadding = false,
  widthLikePage = false,
  className
}: Props) => {
  return (
    <ModalEventsHandler
      opened={opened}
      onClose={onClose || null}
      render={({ onClose, zIndex }) => (
        <Portal portalId={PORTAL_IDS.MODALS}>
          <div
            styleName="background"
            style={{
              zIndex
            }}
          >
            <BlurrableWrapper
              styleName="background__blurrable"
              onBlur={onClose}
            >
              <div
                styleName={cn(
                  contentFullSize && 'background__content_full',
                  withPadding && 'background__content_padding',
                  widthLikePage && 'background__content_like-page'
                )}
                className={className}
              >
                {children}
              </div>
            </BlurrableWrapper>
          </div>
        </Portal>
      )}
    />
  );
};

export default FadeBackground;
