import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { action, computed, makeObservable, observable } from 'mobx';
import Cookies from 'js-cookie';

import {
  fallbackLanguage,
  defaultNameSpace,
  keySeparator,
  nameSpaceNames,
  resources,
  SupportedLocale,
  TFunctionType
} from 'shared/entities/localization';
import { CookieKey } from 'shared/entities/localStorage';
import { DOMAIN } from 'shared/entities/domain';

export class TranslationsStore {
  private _t: TFunctionType;
  private _lng: SupportedLocale;

  constructor({ t, lng }: { t: TFunctionType; lng: SupportedLocale }) {
    this._t = t;
    this._lng = lng;

    makeObservable<TranslationsStore, '_lng' | '_t'>(this, {
      _lng: observable,
      _t: observable,

      lng: computed,

      changeLng: action
    });
  }

  get t(): TFunctionType {
    return this._t;
  }

  get localization(): {
    t: TFunctionType;
    lng: SupportedLocale;
  } {
    return {
      t: this.t,
      lng: this.lng
    };
  }

  get lng(): SupportedLocale {
    return this._lng;
  }

  changeLng = async (lng: SupportedLocale): Promise<void> => {
    this._t = await i18n.changeLanguage(lng);
    this._lng = lng;
    this.setLngToCookie();
  };

  setLngToCookie = (): void => {
    Cookies.set(CookieKey.lng, this.lng, {
      domain: `.${DOMAIN}`
    });
  };

  static getLng(internationalDomain: boolean): SupportedLocale {
    const cookie = Cookies.get(CookieKey.lng);

    if (cookie && (cookie === 'en' || cookie === 'ru')) {
      return cookie;
    }

    return internationalDomain ? 'en' : 'ru';
  }

  static async init(internationalDomain: boolean): Promise<TranslationsStore> {
    // @ts-ignore
    const t: TFunctionType = await i18n
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        parseMissingKeyHandler: () => null,
        ns: nameSpaceNames,
        defaultNS: defaultNameSpace,
        lng: TranslationsStore.getLng(internationalDomain),
        resources,
        compatibilityJSON: 'v4',
        fallbackLng: fallbackLanguage,
        keySeparator: keySeparator,
        interpolation: { escapeValue: false, skipOnVariables: false }
      });

    return new TranslationsStore({
      t,
      lng: i18n.language as SupportedLocale
    });
  }
}
