import { action, computed, makeObservable, reaction } from 'mobx';

import { IComponentLoadingStore } from 'shared/entities/store/componentLoadingStore';

import { ReactionsHandlerStore } from '../reactionsHandlerStore';

export default abstract class ComponentLoadingStore
  extends ReactionsHandlerStore
  implements IComponentLoadingStore
{
  abstract load(): Promise<BaseResponse<any, any>>;
  abstract reset(): void;

  abstract get canBeLoaded(): boolean;
  abstract get loaded(): boolean;

  protected constructor() {
    super();

    makeObservable(this, {
      canBeLoaded: computed,
      loaded: computed,

      reset: action,
      load: action
    });
  }

  initReactions(): void {
    this.addReaction({
      key: 'canBeLoaded',
      reaction: reaction(
        () => this.canBeLoaded,
        () => {
          if (!this.canBeLoaded) {
            this.reset();
          }
        }
      )
    });
  }
}
