import { UtmTag } from 'shared/entities/domain';

export type CarrotQuestAuthDataServer = {
  _id: string;
  auth_hash: string;
  extra_info: CarrotQuestAuthExtraInfoServer;
};

export type CarrotQuestAuthExtraInfoServer = {
  phone: string | null;
  cabinet_trial: string | null;
} & Partial<
  Record<UtmTag, string | null> & {
    ym_id: string | null;
    roistat_visit: string | null;
  }
>;

export enum CarrotQuestEvent {
  botRequest = 'bot_request',
  whatsappExperimentClickPay = 'whatsapp_experiment_click_pay',
  requestBusinessTier = 'request_business_tier'
}

export type CarrotQuestConnectSettings = {
  messenger_position?: 'left_bottom' | 'right_bottom';
  messenger_indent?: { vertical: number; horizontal: number };
  messenger_mobile_indent?: { vertical: number; horizontal: number };
};
