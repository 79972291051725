const KEY_REGEXP = /^%(?<key>[^%]+)%$/;

const formatVariableKey = (key: string): string => `%${key}%`;
const removeEnclosedSymbols = (key: string): string | null => {
  const matchedGroups = key.match(KEY_REGEXP)?.groups;

  if (!matchedGroups || !matchedGroups.key) {
    return null;
  }

  return !matchedGroups || !matchedGroups.key ? null : matchedGroups.key;
};

export { formatVariableKey, removeEnclosedSymbols };
