import { BlockKindType } from '../block/common';

import {
  HeaderBlockItem,
  HeaderBlockItemCommon,
  HeaderBlockItemType
} from './client';

const filterHeaderCommonBlockItem = ({
  option,
  excludeBlockKinds,
  canViewUnpublishedFeatures
}: {
  option: HeaderBlockItemCommon;
  excludeBlockKinds: BlockKindType[];
  canViewUnpublishedFeatures: boolean;
}): boolean =>
  !excludeBlockKinds.includes(option.kind) &&
  (!option.onlyForSa || (option.onlyForSa && canViewUnpublishedFeatures));

const filterHeaderCommonBlockItems = ({
  options,
  excludeBlockKinds,
  canViewUnpublishedFeatures
}: {
  options: HeaderBlockItemCommon[];
  excludeBlockKinds: BlockKindType[];
  canViewUnpublishedFeatures: boolean;
}): HeaderBlockItemCommon[] =>
  options.filter((option) =>
    filterHeaderCommonBlockItem({
      option,
      excludeBlockKinds,
      canViewUnpublishedFeatures
    })
  );

export const filterHeaderBlockItem = ({
  options,
  excludeBlockKinds,
  canViewUnpublishedFeatures
}: {
  options: HeaderBlockItem[];
  excludeBlockKinds: BlockKindType[];
  canViewUnpublishedFeatures: boolean;
}): HeaderBlockItem[] => {
  const filtered: HeaderBlockItem[] = [];

  options.forEach((option) => {
    if (option.type === HeaderBlockItemType.common) {
      if (
        filterHeaderCommonBlockItem({
          option,
          excludeBlockKinds,
          canViewUnpublishedFeatures
        })
      ) {
        filtered.push(option);
      }
      return;
    }

    const filteredClusterOptions = filterHeaderCommonBlockItems({
      options: option.items,
      excludeBlockKinds,
      canViewUnpublishedFeatures
    });

    if (filteredClusterOptions.length) {
      filtered.push({
        ...option,
        items: filteredClusterOptions
      });
    }
  });

  return filtered.filter(Boolean);
};
