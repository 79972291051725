import {
  ChannelTokenRevokingOptions,
  ChannelTokenScope,
  ChannelTokenServer,
  ChannelTokenStatus,
  IChannelToken
} from 'shared/entities/channels';
import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { apiUrls } from 'shared/entities/domain';

export default class ChannelTokenModel implements IChannelToken {
  readonly id: string;
  readonly channelId: string;
  readonly scopes: ChannelTokenScope[];
  readonly revokingOptions: ChannelTokenRevokingOptions;
  readonly error: string | null;
  readonly revokedAt: Date | null;
  readonly removingStage: LoadingStageModel = new LoadingStageModel();
  readonly status: ChannelTokenStatus;

  private rootStore: IRootStore;

  constructor(
    {
      id,
      revokingOptions,
      scopes,
      status,
      error,
      channelId
    }: Omit<IChannelToken, 'removingStage'> & {
      channelId: string;
    },
    rootStore: IRootStore
  ) {
    this.id = id;
    this.revokingOptions = revokingOptions;
    this.scopes = scopes;
    this.status = status;
    this.error = error;
    this.channelId = channelId;

    this.rootStore = rootStore;
  }

  async remove(): Promise<BaseResponse> {
    if (this.removingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.removingStage.loading();

    const response = await this.rootStore.networkStore.api(
      apiUrls.CHANNELS_REMOVE_TOKEN,
      {
        method: 'POST',
        data: {
          channel_id: this.channelId,
          token_id: this.id
        }
      }
    );

    response.isError
      ? this.removingStage.error()
      : this.removingStage.loading();

    return {
      isError: response.isError
    };
  }

  static fromJson({
    raw,
    channelId,
    rootStore
  }: {
    raw: ChannelTokenServer;
    channelId: string;
    rootStore: IRootStore;
  }): ChannelTokenModel {
    return new ChannelTokenModel(
      {
        id: raw._id,
        status: raw.status,
        revokingOptions:
          raw.is_revoked && raw.revoked_at
            ? {
                value: true,
                revokedAt: new Date(raw.revoked_at)
              }
            : {
                value: false
              },
        scopes: raw.scopes,
        error: raw.error,
        channelId
      },
      rootStore
    );
  }
}
