import { AxiosRequestConfig, Method } from 'axios';

import { TranslationString } from '../localization';

export enum BaseResponseCode {
  ok = 'ok',
  forbidden = 'forbidden',
  notFound = 'not_found',
  notAuthorized = 'not_authorized',
  badRequest = 'bad_request',
  conflict = 'conflict'
}

export type BaseResponseCodeType<C extends string = string> = BaseResponseCode &
  C;

enum ApiCallStatus {
  ok = 'ok',
  error = 'error'
}

export type ApiCallErrorRaw<
  C extends string = string,
  D extends Record<string, any> = Record<string, any>
> = {
  status: ApiCallStatus.error;
  data: D;
  code: BaseResponseCodeType<C>;
  message: string;
};

export type ApiCallResponseRaw<
  D extends Record<string, any> = Record<string, any>
> = {
  status: ApiCallStatus.ok;
  data: D;
};

export type ApiParams<ErrorCode extends string = string> = {
  method?: Method;
  data?: Record<string, any>;
  config?: AxiosRequestConfig;
  multipartFormData?: boolean;
  errorsMap?: (code: ErrorCode) => TranslationString;
  showExpectedError?: boolean;
  showUnexpectedError?: boolean;
  showAuthModal?: boolean;
  /**
   * массив ошибок, которые обработаны и их не нужно показывать и отправлять в Sentry
   */
  expectedErrorCodes?: ErrorCode[];
};

export type ApiParamsShopback<ErrorCode extends string = string> = Omit<
  ApiParams<ErrorCode>,
  'data'
> & {
  data?: { shopId?: string } & Record<string, any>;
};

export type ApiCallResponse<
  ResponseData extends Record<string, any> = {},
  ErrorCode extends string = string,
  ErrorData extends Record<string, any> = {}
> =
  | {
      isError: false;
      status: number;
      data: ResponseData;
      rawData: any;
    }
  | {
      isError: true;
      isCancelled: boolean;
      data:
        | ({
            status: number;
          } & Omit<ApiCallErrorRaw<ErrorCode, ErrorData>, 'status'>)
        | null;
    };

export enum SmartHeaders {
  CABINET = 'x-spro-cabinet',
  PROJECT = 'x-spro-project',
  BUCKET = 'x-spro-bucket',
  SHOPBACK = 'x-shopback-token',
  SHOPBACK_PROJECT = 'x-shopback-project'
}

export enum SmartIds {
  projectId = 'projectId',
  cabinetId = 'cabinetId',
  bucket = 'bucket',
  shopback = 'shopback',
  shopbackProject = 'shopbackProject'
}

export const matchSmartIdsToSmartHeaders: {
  [key in SmartIds]: SmartHeaders;
} = {
  [SmartIds.cabinetId]: SmartHeaders.CABINET,
  [SmartIds.projectId]: SmartHeaders.PROJECT,
  [SmartIds.bucket]: SmartHeaders.BUCKET,
  [SmartIds.shopback]: SmartHeaders.SHOPBACK,
  [SmartIds.shopbackProject]: SmartHeaders.SHOPBACK_PROJECT
};
