import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  when
} from 'mobx';
import * as Sentry from '@sentry/browser';

import UserModel from 'shared/models/UserModel';
import { ValidatorResult } from 'shared/entities/validator';
import { apiUrls } from 'shared/entities/domain';
import { IRootStore } from 'shared/entities/store/rootStore';
import { IUserStore } from 'shared/entities/store/userStore';
import { LoadingStage } from 'shared/entities/meta';
import {
  IUser,
  ManagerExternals,
  normalizeManagerExternals,
  normalizeUserTokens,
  UserAcceptInvitationServerType,
  UserAuthServer,
  UserTokens,
  mapRegistrationErrorToMessage,
  mapAuthErrorToMessage,
  LoginErrors,
  mapLoginErrorToMessage,
  RegistrationErrors,
  AuthErrors,
  BaseAuthLoginErrors,
  mapBaseLoginAuthErrors,
  IUserExtra,
  normalizeUserExtra
} from 'shared/entities/user';
import { AppNotificationType } from 'shared/entities/appNotifications';
import {
  AcceptInvitationErrorCode,
  mapAcceptInvitationErrorCodeToMessage
} from 'shared/entities/manager';
import { mapErrorCodeToMessage } from 'shared/entities/common/utils';
import { ChannelKind, openedChannelKindOrder } from 'shared/entities/channels';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { AccountModel } from 'shared/models/AccountModel';
import ListModel from 'shared/models/ListModel/ListModel';
import { TimeoutError } from 'shared/models/errors';

export default class UserStore implements IUserStore {
  rootStore: IRootStore;

  user: UserModel | null;

  userTokens: UserTokens | null = null;

  userExtra: IUserExtra | null = null;

  userExternals: ManagerExternals | null;

  authStage: LoadingStage = LoadingStage.NOT_STARTED;

  registerStage: LoadingStage = LoadingStage.NOT_STARTED;

  loginStage: LoadingStage = LoadingStage.NOT_STARTED;

  logoutStage: LoadingStage = LoadingStage.NOT_STARTED;

  acceptStage: LoadingStage = LoadingStage.NOT_STARTED;

  shopbackAuthorizeStage: LoadingStageModel = new LoadingStageModel();

  readonly accounts: ListModel<AccountModel> = new ListModel<AccountModel>({
    entities: {},
    keys: []
  });

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      user: observable,
      authStage: observable,
      registerStage: observable,
      loginStage: observable,
      logoutStage: observable,
      acceptStage: observable,
      userTokens: observable,
      userExtra: observable,
      userExternals: observable,
      accounts: observable.ref,

      isAuthorized: computed,
      showedAccounts: computed,

      authorize: action,
      register: action,
      login: action,
      logout: action.bound,
      resetAuth: action,
      acceptInvitation: action,
      applyChanges: action,
      initAccounts: action
    });

    this.rootStore = rootStore;
  }

  get isAuthorized(): boolean {
    return !!this.user;
  }

  get showedAccounts(): AccountModel[] {
    return this.accounts.items.filter(
      (item) => item.manager.value || item.linkParams.value
    );
  }

  removeToken = (
    kind: ChannelKind.VK | ChannelKind.FB | ChannelKind.IG
  ): void => {
    if (!this.userTokens) {
      return;
    }

    this.userTokens[kind] = false;

    if (!this.userExternals) {
      return;
    }

    delete this.userExternals[kind];
  };

  resetAuth = (): void => {
    this.user = null;
  };

  applyChanges = (raw: UserAuthServer): UserModel => {
    this.user = UserModel.fromJson(raw.manager);
    this.userTokens = normalizeUserTokens(raw.tokens);
    this.userExtra = normalizeUserExtra(raw.manager.extra);
    this.userExternals = normalizeManagerExternals(raw.manager.external_ids);

    return this.user;
  };

  async register(data: {
    email: string;
    password: string;
    name: string;
  }): Promise<BaseResponse<IUser, RegistrationErrors | null>> {
    if (this.registerStage === LoadingStage.LOADING) {
      return {
        isError: true,
        data: null
      };
    }

    this.registerStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api<
      UserAuthServer,
      RegistrationErrors
    >(apiUrls.AUTH_REGISTER, {
      method: 'POST',
      data,
      errorsMap: mapRegistrationErrorToMessage,
      showExpectedError: false
    });

    if (!response.isError) {
      const user = this.applyChanges(response.data);
      await this.updateRefs();

      runInAction(() => {
        this.registerStage = LoadingStage.SUCCESS;
      });

      return {
        isError: false,
        data: user
      };
    } else {
      runInAction(() => {
        this.registerStage = LoadingStage.ERROR;
      });

      return {
        isError: true,
        data: response.data?.code || null
      };
    }
  }

  async loadAuthInfo<P = Record<string, any>>(): Promise<
    BaseResponse<UserAuthServer<P>, ValidatorResult>
  > {
    const response = await this.rootStore.networkStore.api<
      UserAuthServer<P>,
      AuthErrors
    >(apiUrls.AUTH_INFO, {
      errorsMap: mapAuthErrorToMessage,
      showExpectedError: false
    });

    if (!response.isError) {
      this.applyChanges(response.data);

      return {
        // @ts-ignore TODO разобраться почему never
        isError: false,
        data: response.data
      };
    }

    return {
      isError: true,
      data: response.data?.code || null
    };
  }

  async authorize<P = Record<string, any>>(): Promise<
    BaseResponse<UserAuthServer<P>, ValidatorResult>
  > {
    if (this.authStage === LoadingStage.LOADING) {
      return {
        isError: true,
        data: null
      };
    }

    this.authStage = LoadingStage.LOADING;

    const response = await this.loadAuthInfo<P>();

    if (!response.isError) {
      runInAction(() => {
        this.authStage = LoadingStage.SUCCESS;
      });
    } else {
      runInAction(() => {
        this.authStage = LoadingStage.ERROR;
      });
    }

    return response;
  }

  async acceptInvitation(
    data: UserAcceptInvitationServerType
  ): Promise<BaseResponse> {
    if (this.acceptStage === LoadingStage.LOADING) {
      return { isError: true };
    }
    this.acceptStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api<
      UserAcceptInvitationServerType,
      AcceptInvitationErrorCode
    >(apiUrls.AUTH_ACCEPT_INVITATION, {
      method: 'POST',
      data: {
        ...data
      },
      errorsMap: mapAcceptInvitationErrorCodeToMessage,
      showUnexpectedError: false,
      showExpectedError: false
    });

    if (!response.isError) {
      runInAction(() => {
        this.acceptStage = LoadingStage.SUCCESS;
      });
      await this.authorize();
    } else {
      runInAction(() => {
        this.acceptStage = LoadingStage.ERROR;
      });

      if (response.data?.code !== AcceptInvitationErrorCode.managerNotFound) {
        this.rootStore.appNotificationsStore.open({
          type: AppNotificationType.error,
          title: mapErrorCodeToMessage({
            errorCode: response.data?.code || null,
            map: mapAcceptInvitationErrorCodeToMessage,
            defaultMessage: (t) =>
              t('manager.acceptInvitationErrors.unknown', {
                ns: 'entities'
              })
          })
        });
      }
    }

    return {
      isError: response.isError
    };
  }

  async login({
    email,
    password
  }): Promise<BaseResponse<null, BaseAuthLoginErrors | LoginErrors | null>> {
    this.loginStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api<
      UserAuthServer,
      BaseAuthLoginErrors | LoginErrors
    >(apiUrls.AUTH_LOGIN, {
      method: 'POST',
      data: {
        email,
        password
      },
      errorsMap: (code: BaseAuthLoginErrors | LoginErrors) => {
        if (
          Object.values(BaseAuthLoginErrors).includes(
            code as BaseAuthLoginErrors
          )
        ) {
          return mapBaseLoginAuthErrors(code as BaseAuthLoginErrors);
        }

        return mapLoginErrorToMessage(code as LoginErrors);
      },
      showExpectedError: false
    });

    if (!response.isError) {
      this.applyChanges(response.data);

      runInAction(() => {
        this.loginStage = LoadingStage.SUCCESS;
        this.rootStore.uiStore.closeAuthorizedModal();
      });
    } else {
      runInAction(() => {
        this.loginStage = LoadingStage.ERROR;
      });
    }

    return response.isError
      ? {
          isError: true,
          data: response.data?.code || null
        }
      : {
          isError: false,
          data: null
        };
  }

  async logout(): Promise<BaseResponse> {
    if (!this.isAuthorized || this.logoutStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this.logoutStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api(
      apiUrls.AUTH_LOGOUT,
      { method: 'POST' }
    );

    runInAction(() => {
      if (!response.isError) {
        this.user = null;
        this.logoutStage = LoadingStage.SUCCESS;
      } else {
        this.logoutStage = LoadingStage.ERROR;
      }
    });

    return {
      isError: response.isError
    };
  }

  updateRefs = async (): Promise<BaseResponse> => {
    try {
      await when(() => false, { timeout: 4000 });
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtras(error.message);
        Sentry.captureException(
          new TimeoutError('Yandex Analytics was not initialized in 4000 ms')
        );
      });
    }

    const ymIdResponse = await this.rootStore.analyticsStore.ym.getId();

    return this.rootStore.networkStore.api(apiUrls.AUTH_UPDATE_REFS, {
      method: 'POST',
      data: {
        refs: {
          ...this.rootStore.routerStore.utmParams,
          open: this.rootStore.routerStore.openingParams.open,
          ym_id: !ymIdResponse.isError ? ymIdResponse.data : undefined,
          roistat_visit:
            this.rootStore.analyticsStore.roistat.id.value || undefined
        }
      }
    });
  };

  initAccounts = async (): Promise<void> => {
    const accountModels = await Promise.all(
      openedChannelKindOrder.map((kind) => {
        return AccountModel.init({
          kind,
          manager: this.userExternals?.[kind] || null,
          rootStore: this.rootStore
        });
      })
    );
    const { keys, entities } = accountModels.reduce(
      (acc, model) => {
        if (!model) {
          return acc;
        }
        return {
          ...acc,
          entities: {
            ...acc.entities,
            [model.kind]: model
          },
          keys: [...acc.keys, model.kind]
        };
      },
      { entities: {}, keys: [] }
    );
    this.accounts.addEntities({ keys, entities, initial: true });
  };
}
