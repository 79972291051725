import { action, makeObservable, observable } from 'mobx';

import BaseEditStore from 'stores/editStore/BaseEditStore';
import {
  CabinetManagerRoleType,
  ICabinetManagerAddingEdit
} from 'shared/entities/manager';
import {
  Validator,
  validateEmailField,
  validateIsEmpty,
  validateTextField
} from 'shared/entities/validator';
import { CabinetManagersStore } from 'stores/managers/cabinetManagersStore';
import { CabinetManagerAddingEditModel } from 'shared/models/manager';
import { mapErrorCodeToMessage } from 'shared/entities/common/utils';

import { mapManagerAddingErrorCodeToMessage } from './config';

export default class CabinetManagerAddingEditStore extends BaseEditStore<CabinetManagerAddingEditModel> {
  validators: Record<keyof ICabinetManagerAddingEdit, Validator<any>[]> = {
    email: [validateTextField, validateEmailField],
    role: [validateIsEmpty]
  };

  managersStore: CabinetManagersStore;

  constructor({
    managersStore,
    role
  }: {
    managersStore: CabinetManagersStore;
    role: CabinetManagerRoleType;
  }) {
    super({
      initialData: new CabinetManagerAddingEditModel({
        email: '',
        role
      })
    });

    this.managersStore = managersStore;

    makeObservable(this, {
      managersStore: observable,

      add: action.bound
    });
  }

  async add(): Promise<BaseResponse> {
    const entity = this.save();

    if (!entity) {
      return {
        isError: true
      };
    }

    const response = await this.managersStore.add(entity.toJson());

    if (!response.isError) {
      this.reset();
      return {
        isError: false
      };
    } else {
      this.setError(
        'email',
        mapErrorCodeToMessage({
          errorCode: response.data.errorCode,
          map: mapManagerAddingErrorCodeToMessage
        })
      );

      return {
        isError: true
      };
    }
  }

  validateRole(): BaseResponse<{ role: CabinetManagerRoleType }> {
    const isError = this.validateField('role');

    if (!isError) {
      return {
        isError: false,
        data: { role: this.entity.role }
      };
    }

    return {
      isError: true
    };
  }
}
