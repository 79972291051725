import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const RepostIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <path
        d="M8.43435 19.573C10.3311 19.573 11.8687 18.0354 11.8687 16.1387C11.8687 14.242 10.3311 12.7043 8.43435 12.7043C6.53761 12.7043 5 14.242 5 16.1387C5 18.0354 6.53761 19.573 8.43435 19.573Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M23.5657 9.86868C25.4624 9.86868 27 8.33107 27 6.43434C27 4.53761 25.4624 3 23.5657 3C21.669 3 20.1313 4.53761 20.1313 6.43434C20.1313 8.33107 21.669 9.86868 23.5657 9.86868Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M23.5657 29.2774C25.4624 29.2774 27 27.7398 27 25.8431C27 23.9464 25.4624 22.4088 23.5657 22.4088C21.669 22.4088 20.1313 23.9464 20.1313 25.8431C20.1313 27.7398 21.669 29.2774 23.5657 29.2774Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.6717 8.28778L11.3267 14.2847"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.6717 23.989L11.3267 17.9921"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </BaseIcon>
  );
};

export default RepostIcon;
