import * as React from 'react';
import cn from 'classnames';

import { ChannelKind, channelKindEntities } from 'shared/entities/channels';
import { IconSize } from 'shared/entities/components/Icon';
import Reference from 'shared/newComponents/Reference';
import {
  TranslationNode,
  TranslationString,
  useTypedTranslation
} from 'shared/entities/localization';
import { TagSize, TagType } from 'shared/newEntities/components/Tag';
import Tag from 'shared/newComponents/Tag';

import ChannelTag from '../ChannelTag';

import './Channel.modules.scss';

type Props = {
  kind: ChannelKind;
  onClick?: (kind: ChannelKind | null) => void;
  selected?: boolean;
  withoutLabel?: boolean;
  referenceTitle?: TranslationString;
  referenceDescription?: TranslationNode;
};

const Channel: React.FC<Props> = ({
  kind,
  onClick,
  selected,
  withoutLabel,
  referenceTitle,
  referenceDescription
}) => {
  const { t } = useTypedTranslation('newComponents');

  const {
    Icon,
    TransparentIcon,
    FullIcon,
    FullTransparentIcon,
    label,
    title,
    banned,
    tag
  } = channelKindEntities[kind];

  const ActualIcon = FullIcon ?? Icon;
  const ActualTransparentIcon = FullTransparentIcon ?? TransparentIcon;

  const refTitle = referenceTitle ?? title;

  const handleClick = React.useCallback(() => {
    if (selected) {
      onClick?.(null);
    } else {
      onClick?.(kind);
    }
  }, [kind, selected]);

  const description = React.useMemo(() => {
    if (!banned && !referenceDescription) {
      return null;
    }
    return (
      <>
        {referenceDescription && referenceDescription}
        {banned && (
          <div
            styleName={cn(referenceDescription && 'channel__reference-banned')}
          >
            {t('Channel.bannedDescription')}
          </div>
        )}
      </>
    );
  }, [banned, referenceDescription, t]);

  const iconSize: IconSize.XLL | IconSize.XXL | IconSize.XL =
    React.useMemo(() => {
      switch (kind) {
        case ChannelKind.WABA:
          return IconSize.XLL;
        case (ChannelKind.AVITO, ChannelKind.ALIEXPRESS):
          return IconSize.XXL;
        default:
          return IconSize.XL;
      }
    }, [kind]);

  return (
    <Reference
      disabledOnMobile
      trigger={(isOpen) => (
        <div
          onClick={handleClick}
          styleName={cn(
            'channel',
            selected && 'channel_selected',
            `channel_${iconSize}`
          )}
        >
          {selected || (isOpen && !banned) ? (
            <ActualIcon iconSize={iconSize} />
          ) : (
            <ActualTransparentIcon iconSize={iconSize} />
          )}
          {tag ? (
            <Tag
              type={TagType.main}
              size={TagSize.s}
              styleName="channel__label channel__tag"
            >
              {(t) => t(`Channel.tagTitle.${tag}`, { ns: 'newComponents' })}
            </Tag>
          ) : (
            !withoutLabel &&
            label && <ChannelTag styleName="channel__label">{label}</ChannelTag>
          )}
        </div>
      )}
      title={refTitle}
      description={description}
    />
  );
};

export default React.memo(Channel);
