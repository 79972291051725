import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import {
  IconSize,
  CommonIconProps,
  LogoIconProps
} from 'shared/entities/components/Icon';

const VKIcon: React.FC<LogoIconProps> = ({ color, ...rest }) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{ viewBox: '0 0 32 32' }}
      {...rest}
    >
      <g clipPath="url(#clip0_1435_95726)">
        <mask
          id="mask0_1435_95726"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <path d="M32 0H0V32H32V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1435_95726)">
          <path
            d="M15.3434 32H16.6758C23.9091 32 27.5264 32 29.7728 29.7536C32.0192 27.5072 32 23.8912 32 16.6759V15.3242C32 8.11006 32 4.49281 29.7728 2.24641C27.5456 6.4373e-06 23.9091 0 16.6758 0H15.3434C8.10881 0 4.4928 6.4373e-06 2.2464 2.24641C-1.43051e-06 4.49281 0 8.1075 0 15.3242V16.6759C0 23.8912 -1.43051e-06 27.5072 2.2464 29.7536C4.4928 32 8.10881 32 15.3434 32Z"
            fill={color === 'original' ? '#0077FF' : 'currentColor'}
          />
          <path
            d="M17.1717 23.0719C9.95632 23.0719 5.57747 18.0658 5.40723 9.74707H9.06163C9.17556 15.8578 11.9557 18.4511 14.0869 18.9797V9.74707H17.5903V15.0194C19.6459 14.7916 21.7964 12.3928 22.5208 9.74707H25.9653C25.4136 13.0021 23.0725 15.4008 21.4162 16.3903C23.0725 17.1903 25.7374 19.2843 26.7653 23.0719H22.9778C22.1778 20.54 20.2168 18.5791 17.5903 18.3128V23.0719H17.1717Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1435_95726">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export const VKOriginalIcon: React.FC<CommonIconProps> = (props) => (
  <VKIcon color="original" {...props} />
);

export const VKTransparentIcon: React.FC<CommonIconProps> = (props) => (
  <VKIcon color="transparent" {...props} />
);
