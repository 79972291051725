import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const EllipsisIcon: React.FC<CommonIconProps> = (props) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{ viewBox: '0 0 32 32' }}
      {...props}
    >
      <rect
        x="32"
        width="32"
        height="32"
        rx="4"
        transform="rotate(90 32 0)"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6668 11.3335C17.1371 11.3335 18.3335 10.1371 18.3335 8.66675C18.3335 7.19637 17.1371 6 15.6668 6C14.1964 6 13 7.19637 13 8.66675C13 10.1371 14.1964 11.3335 15.6668 11.3335ZM15.6668 20.6665C14.1964 20.6665 13 21.8629 13 23.3332C13 24.8036 14.1964 26 15.6668 26C17.1371 26 18.3335 24.8036 18.3335 23.3332C18.3335 21.8629 17.1371 20.6665 15.6668 20.6665ZM13 16.001C13 14.5306 14.1964 13.3342 15.6668 13.3342C17.1371 13.3342 18.3335 14.5306 18.3335 16.001C18.3335 17.4713 17.1371 18.6677 15.6668 18.6677C14.1964 18.6677 13 17.4713 13 16.001Z"
        fill="#BFC7CF"
      />
    </BaseIcon>
  );
};

export default EllipsisIcon;
