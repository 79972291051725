import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const NewUserIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M9.48453 11.1656C11.7394 11.1656 13.5673 9.33767 13.5673 7.0828C13.5673 4.82793 11.7394 3 9.48453 3C7.22966 3 5.40173 4.82793 5.40173 7.0828C5.40173 9.33767 7.22966 11.1656 9.48453 11.1656Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1446 20.8531C13.8717 20.9476 13.5778 21 13.2734 21H5.71656C4.14222 21 2.88274 19.6566 3.00869 18.0928C3.2186 15.3534 4.44659 11.6799 9.54747 11.6799C11.7201 11.6799 13.1685 12.3412 14.1446 13.3172"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9661 16.34H20.3369"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1567 13.1598V19.5201"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default NewUserIcon;
