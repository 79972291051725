import * as React from 'react';
import { useAnimate } from 'framer-motion';

import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { useModalState } from 'shared/entities/common/hooks';

export const useAnimation = () => {
  const { store } = useChannelsModalStore();
  const [ref, animate] = useAnimate();
  const { opened: showSelectedChannel, open, close } = useModalState();

  React.useEffect(() => {
    const changeState = async () => {
      if (store.selectedChannel) {
        await animate(
          ref.current,
          {
            width: 0,
            opacity: 0,
            height: 0
          },
          {
            stiffness: 0
          }
        );
        open();
      } else {
        close();
        animate(
          ref.current,
          {
            width: '100%',
            opacity: 1,
            height: '100%'
          },
          {
            stiffness: 0
          }
        );
      }
    };

    changeState();
  }, [store.selectedChannel]);

  return {
    ref,
    showSelectedChannel
  };
};
