import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const YooKassaIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M14.9255 5C10.9623 5 7.80981 8.19757 7.80981 12.1157C7.80981 16.0789 11.0074 19.2314 14.9255 19.2314C18.8437 19.2314 22.0412 16.0338 22.0412 12.1157C22.0412 8.19757 18.8437 5 14.9255 5ZM14.9255 14.7278C13.4844 14.7278 12.2684 13.5118 12.2684 12.0706C12.2684 10.6295 13.4844 9.41354 14.9255 9.41354C16.3667 9.41354 17.5826 10.6295 17.5826 12.0706C17.5376 13.5568 16.3667 14.7278 14.9255 14.7278Z"
        fill="url(#paint0_linear_4689_859)"
      />
      <path
        d="M7.76461 7.02637V17.3847H5.24259L2 7.02637H7.76461Z"
        fill="url(#paint1_linear_4689_859)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4689_859"
          x1="4.88228"
          y1="7.02663"
          x2="4.88228"
          y2="17.3849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0160D1" />
          <stop offset="1" stopColor="#00479C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4689_859"
          x1="4.88231"
          y1="7.02637"
          x2="4.88231"
          y2="17.3847"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0160D1" />
          <stop offset="1" stopColor="#00479C" />
        </linearGradient>
      </defs>
    </BaseIcon>
  );
};

export default YooKassaIcon;
