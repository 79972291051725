import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const SQIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M5.80361 11.86C3.43375 11.86 1.52002 9.96126 1.52002 7.60999C1.52002 5.25871 3.43375 3.35999 5.80361 3.35999C8.17347 3.35999 10.0872 5.25871 10.0872 7.60999"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M5.80361 11.86C8.17347 11.86 10.0872 13.7587 10.0872 16.11C10.0872 18.4613 8.17347 20.36 5.80361 20.36C3.43375 20.36 1.52002 18.4613 1.52002 16.11"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M21.5201 7.60999C21.5201 5.25871 19.6063 3.35999 17.2365 3.35999C14.8666 3.35999 12.9529 5.25871 12.9529 7.60999V16.11C12.9529 18.4613 14.8666 20.36 17.2365 20.36C19.6063 20.36 21.5201 18.4613 21.5201 16.11V7.60999Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.9207 16.7986L21.5201 20.3599"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </BaseIcon>
  );
};

export default SQIcon;
