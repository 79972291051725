import { FileBaseServer, FileServer } from './server';
import { IFileBase, IFile } from './client';

export const normalizeFileBase = (raw: FileBaseServer): IFileBase => ({
  id: raw._id,
  title: raw.filename,
  fileType: raw.type,
  size: raw.size,
  preview: raw.preview_url,
  date: raw.created ? new Date(raw.created) : new Date(),
  url: raw.url,
  extension: raw.extension,
  extra: raw.extra,
  areaSize:
    raw.height && raw.width
      ? {
          height: raw.height,
          width: raw.width
        }
      : null
});

export const normalizeFile = (raw: FileServer): IFile => ({
  availability: raw.availability,
  ...normalizeFileBase(raw)
});
