import * as React from 'react';
import cn from 'classnames';

import { TranslationNode } from 'shared/entities/localization';
import {
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import { ComponentType } from 'shared/entities/components/Component';

import TransitionTitle from '../TransitionTitle';

import './Section.modules.scss';

type Props = React.PropsWithChildren<{
  className?: string;
  active?: boolean;
  title?: TranslationNode;
  goBack?: {
    onClick: () => void;
    title: TranslationNode;
  };
  contentClassName?: string;
}>;

const Section: React.FC<Props> = ({
  children,
  active = false,
  title,
  className,
  goBack,
  contentClassName
}) => {
  return (
    <div
      styleName={cn('section', active && 'section_active')}
      className={className}
    >
      {goBack && (
        <TransitionTitle
          styleName="section__back"
          onClick={goBack.onClick}
          type={ComponentType.button}
        >
          {goBack.title}
        </TransitionTitle>
      )}
      {title && (
        <Typography
          styleName="section__title"
          fontType={TypographyType.text}
          fontSize={TypographySize.m}
          fontWeight={TypographyWeight.heavy}
        >
          {title}
        </Typography>
      )}
      <div styleName="section__content" className={contentClassName}>
        {children}
      </div>
    </div>
  );
};

export default Section;
