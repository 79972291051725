import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import { generateId } from 'shared/entities/common/utils';
import {
  CreateGetCourseTokenError,
  GetCourseTokenServer,
  IGetCourseToken,
  mapCreateGetCourseTokenErrorToMessage
} from 'shared/entities/getCourse';
import { validateIsEmpty } from 'shared/entities/validator';
import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStage } from 'shared/entities/meta';
import { apiUrls } from 'shared/entities/domain';
import { FieldModel } from 'shared/models/form';

export class GetCourseTokenModel implements IGetCourseToken {
  private _id: string;
  readonly title: string;
  readonly token: FieldModel;
  private rootStore: IRootStore;
  private _creatingStage: LoadingStage = LoadingStage.NOT_STARTED;
  private _removingStage: LoadingStage = LoadingStage.NOT_STARTED;
  private _verified: boolean;

  constructor(
    {
      id,
      token,
      title,
      verified
    }: Omit<IGetCourseToken, 'token'> & { token: FieldModel },
    rootStore: IRootStore
  ) {
    this._id = id;
    this.title = title;
    this.token = token;
    this._verified = verified;
    this.rootStore = rootStore;

    makeObservable<
      GetCourseTokenModel,
      '_creatingStage' | '_id' | '_verified' | '_removingStage'
    >(this, {
      _id: observable,
      _creatingStage: observable,
      _verified: observable,
      _removingStage: observable,

      create: action.bound,

      creatingStage: computed,
      id: computed,
      verified: computed,
      removingStage: computed
    });
  }

  get removingStage(): LoadingStage {
    return this._removingStage;
  }

  get settingsLink(): string {
    return `https://${this.domain}/saas/account/api`;
  }

  get domain(): string {
    return `${this.title}.getcourse.ru`;
  }

  get verified(): boolean {
    return this._verified;
  }

  get id(): string {
    return this._id;
  }

  get creatingStage(): LoadingStage {
    return this._creatingStage;
  }

  async create(): Promise<BaseResponse> {
    if (
      this.creatingStage === LoadingStage.LOADING ||
      this.token.isError ||
      this.verified
    ) {
      return {
        isError: true
      };
    }

    this._creatingStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api<
      GetCourseTokenServer,
      CreateGetCourseTokenError
    >(apiUrls.GET_COURSE_TOKENS_CREATE, {
      method: 'POST',
      data: {
        account_name: this.title,
        token: this.token.value
      },
      errorsMap: mapCreateGetCourseTokenErrorToMessage
    });

    if (!response.isError) {
      runInAction(() => {
        this._creatingStage = LoadingStage.SUCCESS;
        this._id = response.data._id;
        this._verified = true;
      });
    } else {
      runInAction(() => {
        this._creatingStage = LoadingStage.ERROR;
      });
    }

    return {
      isError: response.isError
    };
  }

  async remove(): Promise<BaseResponse> {
    if (!this.verified || this._removingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this._removingStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api(
      apiUrls.GET_COURSE_TOKENS_REMOVE,
      {
        method: 'POST',
        data: {
          _id: this.id
        }
      }
    );

    runInAction(() => {
      this._removingStage = response.isError
        ? LoadingStage.ERROR
        : LoadingStage.SUCCESS;
    });

    return {
      isError: response.isError
    };
  }

  static fromDefaultParams(
    title: string,
    rootStore: IRootStore
  ): GetCourseTokenModel {
    return new GetCourseTokenModel(
      {
        title,
        token: new FieldModel<string>('', [validateIsEmpty]),
        verified: false,
        id: generateId()
      },
      rootStore
    );
  }

  static fromJson(
    raw: GetCourseTokenServer,
    rootStore: IRootStore
  ): GetCourseTokenModel {
    return new GetCourseTokenModel(
      {
        title: raw.account_name,
        token: new FieldModel<string>(raw.token, [validateIsEmpty]),
        id: raw._id,
        verified: true
      },
      rootStore
    );
  }
}
