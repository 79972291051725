import { computed, makeObservable } from 'mobx';

import { validateIsEmpty, validateUrl } from 'shared/entities/validator';
import {
  ChannelTgButtonServer,
  IChannelTgButton
} from 'shared/entities/channels';
import { FieldModel } from 'shared/models/form';
import { LoadingStageModel } from 'shared/models/loadingStage';

export default class TgButtonMenuModel implements IChannelTgButton {
  readonly text: FieldModel;
  readonly url: FieldModel;
  readonly saved: FieldModel<boolean>;
  readonly updatingStage: LoadingStageModel = new LoadingStageModel();
  readonly removingStage: LoadingStageModel = new LoadingStageModel();

  constructor({
    text,
    url,
    saved
  }: {
    text: string;
    url: string;
    saved: boolean;
  }) {
    this.text = new FieldModel<string>(text, [validateIsEmpty]);
    this.url = new FieldModel<string>(url, [validateIsEmpty, validateUrl]);
    this.saved = new FieldModel<boolean>(saved);

    makeObservable<TgButtonMenuModel>(this, {
      isError: computed
    });
  }

  validate = (): boolean => {
    this.text.validate();
    this.url.validate();

    return this.isError;
  };

  reset = (): void => {
    this.text.changeValue('');
    this.url.changeValue('');
    this.saved.changeValue(false);
  };

  get isError(): boolean {
    return !!this.text.error || !!this.url.error;
  }

  static fromJson(raw: ChannelTgButtonServer): TgButtonMenuModel {
    return new TgButtonMenuModel({
      text: raw.text,
      url: raw.url,
      saved: true
    });
  }

  static fromDefaultParams(): TgButtonMenuModel {
    return new TgButtonMenuModel({
      text: '',
      url: '',
      saved: false
    });
  }

  toJson(): ChannelTgButtonServer | null {
    return {
      text: this.text.value,
      url: this.url.value
    };
  }
}
