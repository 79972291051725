import { DateFieldType } from '../dateVar';

import { parseValueForGettingVarKey } from './parseValueForGettingVarKey';

export const getDateVarValueFieldType = (value: string): DateFieldType => {
  if (parseValueForGettingVarKey({ value })) {
    return DateFieldType.variable;
  }

  return DateFieldType.date;
};
