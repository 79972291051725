import { action, computed, makeObservable, observable } from 'mobx';

import {
  CommonVarErrorCode,
  formatVariableKey,
  mapVarCommonErrorToMessage,
  mapVarCreateErrorToMessage,
  mapVarUpdateErrorToMessage,
  ProjectBoolVarServer,
  ProjectCommonVarServer,
  ProjectVarServer,
  UpdateVarErrorCode,
  VarBoolValue,
  VarLevel,
  VarType
} from 'shared/entities/vars';
import { IRootStore } from 'shared/entities/store/rootStore';
import { BaseResponseCode } from 'shared/entities/network';
import SqConditionModel from 'shared/models/condition/SqConditionModel';
import {
  validateArrayJsonValue,
  validateIsEmpty,
  validateMapJsonValue,
  validateNumberValue,
  validateTextField,
  validateVarKey,
  ValidatorResult
} from 'shared/entities/validator';
import { apiUrls } from 'shared/entities/domain';

import { LoadingStageModel } from '../loadingStage';
import { FieldModel } from '../form';

export type EditProjectCommonVarData =
  | {
      type: VarType.str | VarType.num | VarType.list | VarType.dict;
      value: string;
    }
  | {
      type: VarType.bool;
      value: VarBoolValue;
    }
  | {
      type: VarType.expr;
      value: SqConditionModel;
    }
  | {
      type: VarType.attachment;
      value: null;
    }
  | {
      type: VarType.date | VarType.datetime;
      value: Date | null;
    };

export default class EditProjectCommonVarModel {
  readonly id: string | null;
  readonly key: FieldModel<string>;
  readonly projectId: string;
  readonly level: FieldModel<VarLevel>;
  data: EditProjectCommonVarData;
  readonly protectedProperty: FieldModel<boolean | undefined>;

  readonly updatingStage: LoadingStageModel = new LoadingStageModel();
  readonly addingStage: LoadingStageModel = new LoadingStageModel();

  readonly rootStore: IRootStore;

  readonly valueError: FieldModel<ValidatorResult> =
    new FieldModel<ValidatorResult>(null);
  readonly typeError: FieldModel<ValidatorResult> =
    new FieldModel<ValidatorResult>(null);

  constructor({
    key,
    level,
    projectId,
    id,
    rootStore,
    protectedProperty,
    data
  }: {
    id: string | null;
    key: string;
    projectId: string;
    level: VarLevel;
    protectedProperty: boolean | undefined;
    data: EditProjectCommonVarData;
    rootStore: IRootStore;
  }) {
    this.level = new FieldModel<VarLevel>(level);
    this.projectId = projectId;
    this.data = data;
    this.id = id;
    this.rootStore = rootStore;
    this.key = new FieldModel<string>(key, [validateTextField, validateVarKey]);
    this.data = data;
    this.protectedProperty = new FieldModel<boolean | undefined>(
      protectedProperty
    );

    makeObservable<EditProjectCommonVarModel>(this, {
      data: observable,

      update: action.bound,
      add: action.bound,
      validateValue: action,
      changeValue: action,
      changeLevel: action,

      enclosedKey: computed,
      keyError: computed,
      error: computed,
      valueErrorResult: computed
    });
  }

  get error(): ValidatorResult {
    return this.keyError || this.valueErrorResult || this.typeError.value;
  }

  get valueErrorResult(): ValidatorResult {
    if (this.data.type === VarType.expr) {
      return this.data.value.error;
    }

    return this.valueError.value;
  }

  get keyError(): ValidatorResult {
    return this.key.error;
  }

  get enclosedKey(): string {
    return formatVariableKey(this.key.value);
  }

  validate = (): void => {
    this.key.validate();
    this.validateValue();
  };

  validateValue = (): void => {
    if (
      this.data.type === VarType.attachment ||
      this.data.type === VarType.str ||
      this.data.type === VarType.date ||
      this.data.type === VarType.datetime
    ) {
      return;
    }

    if (this.data.type === VarType.expr) {
      return this.data.value.validate();
    }

    this.valueError.changeValue(validateIsEmpty(this.data.value));

    if (this.valueError.value) {
      return;
    }

    switch (this.data.type) {
      case VarType.num: {
        this.valueError.changeValue(validateNumberValue(this.data.value));
        break;
      }
      case VarType.list: {
        this.valueError.changeValue(validateArrayJsonValue(this.data.value));
        break;
      }
      case VarType.dict: {
        this.valueError.changeValue(validateMapJsonValue(this.data.value));
      }
    }
  };

  resetValueError = (): void => {
    this.valueError.changeValue(null);

    if (this.data.type === VarType.expr) {
      this.data.value.resetError();
    }
  };

  resetError = (): void => {
    this.resetValueError();
    this.key.resetError();
    this.typeError.changeValue(null);
  };

  changeValue = (value: string | Date | null): void => {
    this.resetValueError();

    if (
      this.data.type === VarType.expr ||
      this.data.type === VarType.attachment
    ) {
      return;
    }

    this.data.value = value;
  };

  changeType = (value: VarType): void => {
    if (this.data.type === value) {
      return;
    }

    this.typeError.changeValue(null);

    if (value === VarType.attachment) {
      this.data = {
        type: VarType.attachment,
        value: null
      };

      return;
    }

    if (value === VarType.expr) {
      this.data = {
        type: VarType.expr,
        value: SqConditionModel.fromDefaultParams({
          rootStore: this.rootStore
        })
      };

      return;
    }

    if (value === VarType.bool) {
      this.data = {
        type: VarType.bool,
        value: VarBoolValue.True
      };

      return;
    }

    if (value === VarType.num) {
      this.data = {
        type: VarType.num,
        value: '0'
      };

      return;
    }

    if (value === VarType.dict) {
      this.data = {
        type: VarType.dict,
        value: '{}'
      };

      return;
    }

    if (value === VarType.list) {
      this.data = {
        type: VarType.list,
        value: '[]'
      };

      return;
    }

    if (value === VarType.date) {
      this.data = {
        type: VarType.date,
        value: null
      };

      return;
    }

    if (value === VarType.datetime) {
      this.data = {
        type: VarType.datetime,
        value: null
      };

      return;
    }

    this.data = {
      type: value,
      value: ''
    };
  };

  changeLevel = (value: VarLevel): void => {
    if (this.level.value === value) {
      return;
    }

    if (value === VarLevel.user && this.data.type === VarType.expr) {
      this.data = {
        type: VarType.str,
        value: ''
      };
    }

    if (
      this.rootStore.pluginsStore.allowProtectedVariables &&
      value !== VarLevel.user
    ) {
      this.protectedProperty.changeValue(undefined);
    }

    this.level.changeValue(value);
  };

  async add(): Promise<
    BaseResponse<
      { createdVariable: ProjectVarServer },
      {
        errorCode: CommonVarErrorCode | BaseResponseCode.conflict | null;
      }
    >
  > {
    if (this.addingStage.isLoading) {
      return {
        isError: true,
        data: {
          errorCode: null
        }
      };
    }

    this.addingStage.loading();

    const enclosedKey = formatVariableKey(this.key.value);

    let value;
    switch (this.data.type) {
      case VarType.expr:
        value = this.data.value.expr;
        break;
      case VarType.date:
        value = this.data.value?.toISOString() ?? null;
        break;
      case VarType.datetime:
        value = this.data.value?.toISOString() ?? null;
        break;
      default:
        value = this.data.value;
    }

    const response = await this.rootStore.networkStore.api<
      ProjectVarServer,
      CommonVarErrorCode | BaseResponseCode.conflict
    >(apiUrls.VARS_CREATE, {
      method: 'POST',
      data: {
        key: enclosedKey,
        value,
        level: this.level.value,
        type: this.data.type,
        protected: this.protectedProperty.value
      },
      errorsMap: (code) => {
        if (
          Object.values(CommonVarErrorCode).includes(code as CommonVarErrorCode)
        ) {
          return mapVarCommonErrorToMessage(code as CommonVarErrorCode);
        }

        return mapVarCreateErrorToMessage(code as BaseResponseCode.conflict);
      },
      showExpectedError: false
    });

    if (!response.isError) {
      this.addingStage.success();
    } else {
      this.addingStage.error();
    }

    return !response.isError
      ? {
          isError: false,
          data: {
            createdVariable: response.data
          }
        }
      : {
          isError: true,
          data: {
            errorCode: response.data?.code || null
          }
        };
  }

  /**
   * Отправляет обновленную  переменную на бэк.
   * Возвращает переменную с бэка
   */
  async update(): Promise<
    BaseResponse<
      {
        updatedVariable: ProjectCommonVarServer | ProjectBoolVarServer;
      },
      { errorCode: UpdateVarErrorCode | CommonVarErrorCode | null }
    >
  > {
    if (!this.id || this.updatingStage.isLoading) {
      return {
        isError: true,
        data: {
          errorCode: null
        }
      };
    }

    this.updatingStage.loading();

    const enclosedKey = formatVariableKey(this.key.value);

    const response = await this.rootStore.networkStore.api<
      ProjectCommonVarServer | ProjectBoolVarServer,
      CommonVarErrorCode | UpdateVarErrorCode
    >(apiUrls.VARS_UPDATE, {
      method: 'POST',
      data: {
        id: this.id,
        key: enclosedKey,
        value:
          this.data.type === VarType.expr
            ? this.data.value.expr
            : this.data.value,
        level: this.level.value,
        type: this.data.type,
        protected: this.protectedProperty.value,
        _id: this.id
      },
      errorsMap: (code) => {
        if (
          Object.values(CommonVarErrorCode).includes(code as CommonVarErrorCode)
        ) {
          return mapVarCommonErrorToMessage(code as CommonVarErrorCode);
        }

        return mapVarUpdateErrorToMessage(code as UpdateVarErrorCode);
      },
      showExpectedError: false
    });

    if (!response.isError) {
      this.updatingStage.success();

      return {
        isError: false,
        data: {
          updatedVariable: response.data
        }
      };
    }
    this.updatingStage.error();

    return {
      isError: true,
      data: {
        errorCode: response.data?.code || null
      }
    };
  }

  getCopy(): EditProjectCommonVarModel {
    return new EditProjectCommonVarModel({
      key: this.key.value,
      data:
        this.data.type === VarType.expr
          ? {
              type: VarType.expr,
              value: this.data.value.getCopy()
            }
          : {
              ...this.data
            },
      level: this.level.value,
      projectId: this.projectId,
      id: this.id,
      rootStore: this.rootStore,
      protectedProperty: this.protectedProperty.value
    });
  }

  static fromDefaultParams({
    rootStore,
    projectId,
    varLevel = VarLevel.user
  }: {
    projectId: string;
    rootStore: IRootStore;
    varLevel?: VarLevel;
  }): EditProjectCommonVarModel {
    return new EditProjectCommonVarModel({
      id: null,
      key: '',
      data: {
        type: VarType.str,
        value: ''
      },
      level: varLevel,
      projectId,
      rootStore,
      protectedProperty: rootStore.pluginsStore.allowProtectedVariables
        ? false
        : undefined
    });
  }
}
