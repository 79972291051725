import {
  ProjectCommonVarType,
  ProjectCommonVarServer,
  IProjectBaseVar
} from 'shared/entities/vars';
import { IRootStore } from 'shared/entities/store/rootStore';

import ProjectVarBaseModel from './ProjectVarBaseModel';

export default class ProjectCommonVarModel
  extends ProjectVarBaseModel<ProjectCommonVarType>
  implements IProjectBaseVar<ProjectCommonVarType>
{
  get example(): string {
    return this.value;
  }

  static fromJson(
    raw: ProjectCommonVarServer & { rootStore: IRootStore }
  ): ProjectCommonVarModel {
    return new ProjectCommonVarModel({
      id: raw._id,
      projectId: raw.project_id,
      level: raw.level,
      key: raw.key,
      protectedProperty: raw.protected,
      rootStore: raw.rootStore,
      type: raw.type,
      value: raw.value
    });
  }

  getCopy(): ProjectCommonVarModel {
    return new ProjectCommonVarModel({
      key: this.key,
      level: this.level,
      value: this.value,
      projectId: this.projectId,
      type: this.type,
      id: this.id,
      rootStore: this.rootStore,
      protectedProperty: this.protectedProperty
    });
  }
}
