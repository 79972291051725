import * as React from 'react';
import cn from 'classnames';

import { DropdownContentItem } from 'shared/newEntities/components/DropdownContent';

import DropdownContent from '../DropdownContent';

import './DropdownContentList.modules.scss';

type Props = {
  items: DropdownContentItem[];
  className?: string;
  defaultId?: string | null;
  titleLimited?: boolean;
  withModal?: boolean;
};

const DropdownContentList: React.FC<Props> = ({
  items,
  className,
  defaultId,
  titleLimited,
  withModal
}) => {
  const [selectedId, setSelectedId] = React.useState<string | null>(
    defaultId || null
  );

  React.useEffect(() => {
    if (defaultId === undefined) {
      return;
    }

    setSelectedId(defaultId);
  }, [defaultId]);

  return (
    <div styleName="list" className={className}>
      {items.map((item) => {
        const selected = selectedId === item.id;

        return (
          <DropdownContent
            {...item}
            key={item.id}
            opened={selected}
            onClick={setSelectedId}
            titleLimited={titleLimited}
            styleName={cn('item', selected && !withModal && 'item_selected')}
            withModal={withModal}
          />
        );
      })}
    </div>
  );
};

export default React.memo(DropdownContentList);
