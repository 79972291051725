import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const CloseIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.S}
      svgProps={{
        viewBox: '0 0 16 16'
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2.68571L13.3143 2L8 7.31429L2.68571 2L2 2.68571L7.31429 8L2 13.3143L2.68571 14L8 8.68571L13.3143 14L14 13.3143L8.68571 8L14 2.68571Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CloseIcon;
