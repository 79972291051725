import * as React from 'react';

import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const MembersIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XS}
      svgProps={{
        viewBox: '0 0 12 12'
      }}
      {...props}
    >
      <path
        d="M4.5 5.5C5.60457 5.5 6.5 4.60457 6.5 3.5C6.5 2.39543 5.60457 1.5 4.5 1.5C3.39543 1.5 2.5 2.39543 2.5 3.5C2.5 4.60457 3.39543 5.5 4.5 5.5Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 10.5V9.5C1.5 8.96957 1.71071 8.46086 2.08579 8.08579C2.46086 7.71071 2.96957 7.5 3.5 7.5H5.5C6.03043 7.5 6.53914 7.71071 6.91421 8.08579C7.28929 8.46086 7.5 8.96957 7.5 9.5V10.5"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 1.56494C8.43021 1.67509 8.81152 1.92529 9.08382 2.2761C9.35612 2.6269 9.50392 3.05836 9.50392 3.50244C9.50392 3.94653 9.35612 4.37798 9.08382 4.72879C8.81152 5.07959 8.43021 5.32979 8 5.43994"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 10.4997V9.49971C10.4975 9.05829 10.349 8.63011 10.0776 8.28191C9.80631 7.93371 9.42741 7.68506 9 7.57471"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default MembersIcon;
