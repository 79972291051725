import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import {
  IconSize,
  CommonIconProps,
  LogoIconProps
} from 'shared/entities/components/Icon';

const WazzupIcon: React.FC<LogoIconProps> = ({ color, ...rest }) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{ viewBox: '0 0 32 32' }}
      {...rest}
    >
      <g clipPath="url(#clip0_6303_4)">
        <mask
          id="mask0_6303_4"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <path d="M32 0H0V32H32V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_6303_4)">
          <path
            d="M16 32C24.8365 32 32 24.8365 32 16C32 7.16346 24.8365 0 16 0C7.16346 0 0 7.16346 0 16C0 24.8365 7.16346 32 16 32Z"
            fill={color === 'original' ? '#4CAF50' : 'currentColor'}
          />
          <g clipPath="url(#clip1_6303_4)">
            <path
              d="M18.5894 12L15.697 20.5H13.9765L11.8196 14.21L9.62538 20.5H7.89242L5 12H6.68309L8.85241 18.46L11.109 12H12.6051L14.8118 18.4964L17.0434 12H18.5894Z"
              fill="white"
            />
            <path
              d="M26.9841 18.9082V20.5H19.45V19.2424L24.2385 13.5918H19.5481V12H26.8533V13.2575L22.0485 18.9082H26.9841Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6303_4">
          <rect width="32" height="32" fill="white" />
        </clipPath>
        <clipPath id="clip1_6303_4">
          <rect
            width="22.1"
            height="8.5"
            fill="white"
            transform="translate(5 12)"
          />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export const WazzupOriginalIcon: React.FC<CommonIconProps> = (props) => (
  <WazzupIcon color="original" {...props} />
);

export const WazzupTransparentIcon: React.FC<CommonIconProps> = (props) => (
  <WazzupIcon color="transparent" {...props} />
);
