import * as React from 'react';
import cn from 'classnames';

import {
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import { BadgeColor } from 'shared/newEntities/components/Badge';
import { TranslationString } from 'shared/entities/localization';

import './Badge.modules.scss';

type Props = {
  color?: BadgeColor;
  className?: string;
  children: TranslationString;
};

const Badge: React.FC<Props> = ({
  color = BadgeColor.blue,
  className,
  children
}) => {
  return (
    <div styleName={cn('badge', `badge_${color}`)} className={className}>
      <Typography
        fontType={TypographyType.text}
        fontSize={TypographySize.xs}
        fontColor={null}
        fontWeight={TypographyWeight.heavy}
      >
        {children}
      </Typography>
    </div>
  );
};

export default React.memo(Badge);
