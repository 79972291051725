import * as React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import debounce from 'lodash/debounce';

import { IPaymentStore } from 'shared/entities/store/paymentStore';
import {
  convertStringToNumber,
  mapErrorCodeToMessage
} from 'shared/entities/common/utils';
import { IRootStore } from 'shared/entities/store/rootStore';
import { AnalyticsEvent } from 'shared/entities/analytics';
import { FieldModel } from 'shared/models/form';
import { LoadingStageModel } from 'shared/models/loadingStage';
import {
  availableMonthPayments,
  CalculatePayError,
  InfoMessageType,
  IPaymentFill,
  mapCalculatePayErrorToMessage,
  PaymentAddition,
  PaymentParams,
  PaymentPromoServer
} from 'shared/entities/payment';
import { apiUrls } from 'shared/entities/domain';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import { ReactionsHandlerStore } from 'stores/reactionsHandlerStore';

export const CALCULATE_LOADING_DEBOUNCE = 400;

export default class PaymentFillModel
  extends ReactionsHandlerStore
  implements IPaymentFill
{
  private _paymentStore: IPaymentStore;
  private _rootStore: IRootStore;
  private _infoMessage: InfoMessageType;

  readonly selectedMonthCount: FieldModel<number | null> = new FieldModel<
    number | null
  >(availableMonthPayments[2].count);
  readonly promoCode: FieldModel = new FieldModel<string>('');
  readonly amount: FieldModel = new FieldModel<string>('');
  readonly priceToPay: FieldModel<number | null> = new FieldModel<
    number | null
  >(null);
  readonly calculatingPriceStage: LoadingStageModel = new LoadingStageModel();
  readonly payStage: LoadingStageModel = new LoadingStageModel();
  readonly addition: FieldModel<PaymentAddition> =
    new FieldModel<PaymentAddition>(PaymentAddition.month);

  constructor(paymentStore: IPaymentStore, rootStore: IRootStore) {
    super();
    this._paymentStore = paymentStore;
    this._rootStore = rootStore;

    makeObservable<PaymentFillModel, '_infoMessage'>(this, {
      _infoMessage: observable,

      numberAmount: computed,
      lessThanMinimalPayment: computed,
      infoMessage: computed,
      paymentDisabled: computed,
      paymentByInvoiceDisabled: computed,

      calculatePrice: action,
      setInfoMessage: action
    });
  }

  get infoMessage(): InfoMessageType {
    return this._infoMessage;
  }

  get numberAmount(): number {
    return convertStringToNumber(this.amount.value);
  }

  get lessThanMinimalPayment(): boolean {
    if (
      !this._paymentStore.info ||
      (this.infoMessage &&
        !this.infoMessage.isError &&
        !this._paymentStore.isCorporate)
    ) {
      return false;
    }

    return this.numberAmount < this._paymentStore.info.minimalPayment;
  }

  get paymentParams(): PaymentParams {
    const extraParams = {
      promocode: this.promoCode.value || undefined
    };

    if (
      this.addition.value === PaymentAddition.month &&
      this.selectedMonthCount.value !== null
    ) {
      return {
        ...extraParams,
        number_of_months: this.selectedMonthCount.value
      };
    }

    return {
      ...extraParams,
      amount: this.numberAmount
    };
  }

  get calculationForInvoice(): boolean {
    return this._rootStore.cabinetStore.documentsStore.creatingModalState
      .opened;
  }

  get paymentDisabled(): boolean {
    return this.calculatingPriceStage.isLoading || !!this.infoMessage?.isError;
  }

  get paymentByInvoiceDisabled(): boolean {
    return (
      this.calculatingPriceStage.isLoading ||
      (!!this.infoMessage &&
        this.infoMessage.isError &&
        this.infoMessage.code !== CalculatePayError.shouldPayAsLegalEntity)
    );
  }

  setInfoMessage = (value: InfoMessageType): void => {
    this._infoMessage = value;
  };

  calculatePrice = async (): Promise<void> => {
    if (this.calculatingPriceStage.isLoading) {
      return;
    }

    this.calculatingPriceStage.loading();

    const response = await this._rootStore.networkStore.api<
      PaymentPromoServer,
      CalculatePayError
    >(apiUrls.PAYMENTS_CALCULATE, {
      method: 'POST',
      data: this.paymentParams,
      errorsMap: mapCalculatePayErrorToMessage,
      showExpectedError: false
    });

    if (response.isError) {
      this.handleError(response.data?.code || null);
      setTimeout(() => {
        this.calculatingPriceStage.error();
      }, CALCULATE_LOADING_DEBOUNCE);
    } else {
      this.priceToPay.changeValue(response.data.price_to_pay);
      if (this.selectedMonthCount.value !== null) {
        this.amount.changeValue(String(response.data.price_to_pay));
      }
      if (response.data.original_price === response.data.price_to_pay) {
        this.setInfoMessage(null);
      } else {
        const originalPrice = response.data.original_price;
        const priceToPay = response.data.price_to_pay;

        if (this.calculationForInvoice) {
          this.setInfoMessage({
            isError: false,
            data: (t) =>
              t('payment.successCalculate.forInvoice', {
                originalPrice,
                priceToPay,
                ns: 'entities',
                currency: this._paymentStore.currency
              })
          });
        } else {
          this.setInfoMessage({
            isError: false,
            data: (t) =>
              t('payment.successCalculate.forPayment', {
                originalPrice,
                priceToPay,
                ns: 'entities',
                currency: this._paymentStore.currency
              })
          });
        }
      }

      setTimeout(() => {
        this.calculatingPriceStage.success();
      }, CALCULATE_LOADING_DEBOUNCE);
    }
  };

  debouncedCalculatePrice = debounce(this.calculatePrice, 1000);

  handleInputBlur = (): void => {
    this.validateInputValue();
  };

  validateInputValue = () => {
    if (!this._paymentStore.info) {
      return;
    }

    if (this.lessThanMinimalPayment) {
      const { minimalPayment } = this._paymentStore.info;
      this.setInfoMessage({
        isError: true,
        code: CalculatePayError.amountTooLow,
        data: (t) =>
          t('payment.minError', {
            ns: 'entities',
            minimalPayment,
            currency: this._paymentStore.currency
          })
      });
    } else {
      this.calculatePrice();
    }
  };

  selectMonthCount = (value: number | null): void => {
    if (this.calculatingPriceStage.isLoading) {
      return;
    }

    this.selectedMonthCount.changeValue(value);
    this.setInfoMessage(null);
    this.calculatePrice();
  };

  pay = async (): Promise<BaseResponse> => {
    if (this.payStage.isLoading) {
      return {
        isError: true
      };
    }

    this.payStage.loading();

    this._rootStore.analyticsStore.sendEvent(AnalyticsEvent.paymentBalance, {
      ...this.paymentParams,
      cabinet_origin: window.location.origin
    });

    const response = await this._paymentStore.pay(this.paymentParams);

    response.isError ? this.payStage.error() : this.payStage.success();

    return response;
  };

  /**
   * Устанавливает значение инпута сумму и сбрасывает выбранные месяцы, info
   * @param value
   * @param withDebounce
   */
  changeInputValueWithReset = (value: string, withDebounce = true): void => {
    this.amount.changeValue(value);
    this.selectedMonthCount.changeValue(null);
    this.setInfoMessage(null);

    //TO DO Убрать, когда бэк будет верно рассчитывать minimal_payment
    if (this._paymentStore.isCorporate) {
      this.validateInputValue();
      return;
    }

    if (withDebounce) {
      this.debouncedCalculatePrice();
    } else {
      this.calculatePrice();
    }
  };

  changePromocode = (value: string): void => {
    this.setInfoMessage(null);
    this.promoCode.changeValue(value);
    this.debouncedCalculatePrice();
  };

  changePaymentAddition = (addition: PaymentAddition): void => {
    this.setInfoMessage(null);
    this.addition.changeValue(addition);
    if (addition === PaymentAddition.sum) {
      const availableSumPayments =
        this._paymentStore.availableSumPayments.value;
      if (availableSumPayments) {
        this.changeInputValueWithReset(String(availableSumPayments[1]), false);
      }
    } else {
      this.selectMonthCount(availableMonthPayments[2].count);
    }
  };

  handleError(code: CalculatePayError | null): void {
    if (code === CalculatePayError.amountTooLow) {
      if (this._paymentStore.info) {
        this.setInfoMessage({
          isError: true,
          code: CalculatePayError.amountTooLow,
          data: (t) =>
            t('payment.errors.calculatePay.amount_too_low', {
              ns: 'entities',
              amount: this._paymentStore.info?.minimalPayment,
              currency: this._paymentStore.currency
            })
        });
      }
    } else if (code === CalculatePayError.shouldPayAsLegalEntity) {
      this.setInfoMessage({
        isError: true,
        code: CalculatePayError.shouldPayAsLegalEntity,
        data: (
          <TypedTransComponent
            i18nKey="payment.errors.should_pay_as_legal_entity_error"
            ns={'entities'}
          >
            Оплата с&nbsp;карты недоступна, так как подписка оформлена
            на&nbsp;юрлицо, для пополнения баланса обратитесь к&nbsp;вашему
            менеджеру или в&nbsp;
            <span
              className="link link_inherit"
              onClick={this._rootStore.supportPopupStore.openChat}
            >
              техподдержку
            </span>{' '}
            для выставления счета
          </TypedTransComponent>
        )
      });
    } else {
      this.setInfoMessage({
        isError: true,
        code: code,
        data: mapErrorCodeToMessage({
          errorCode: code || null,
          map: mapCalculatePayErrorToMessage
        })
      });
    }
  }
}
