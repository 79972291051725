import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const ThumbsUpIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <path
        d="M7.00002 16.3067C7.00002 16.3067 9.65523 14.9615 10.0888 14.4384C10.5224 13.9153 10.4777 13.1681 11.26 12.3087C12.0422 11.4493 12.9273 11.3325 13.6872 10.5852C14.4471 9.83796 15.7032 7.23179 16.0295 6.05481C16.3558 4.87783 15.7255 3.14506 17.2006 3.00961C18.6757 2.87417 19.7574 4.19593 20.1508 5.34489C20.487 6.48269 20.4995 7.69808 20.1866 8.84313C19.9273 10.0435 18.5014 12.5469 18.5014 12.5469C18.5014 12.5469 18.9797 12.7103 21.9701 12.687C24.9606 12.6636 26.1004 13.8266 25.9931 15.1157C25.9254 15.9071 25.6004 16.6504 25.0723 17.2221C25.3415 17.5599 25.5389 17.9536 25.6514 18.3771C25.7639 18.8007 25.789 19.2444 25.7249 19.6788C25.6612 20.1014 25.5034 20.5024 25.2643 20.8496C25.0252 21.1968 24.7114 21.4805 24.3482 21.6778C24.5624 22.0096 24.6964 22.3908 24.7384 22.7886C24.7805 23.1865 24.7294 23.5891 24.5895 23.9617C24.4581 24.2836 24.2649 24.574 24.022 24.815C23.7791 25.0559 23.5204 25.4882 23.2059 25.6087C23.2059 25.6087 23.6955 26.7033 23.0027 27.6748C22.3098 28.6463 20.9867 28.9592 20.0972 28.9919C17.3213 29.1086 8.87743 27.927 8.87743 27.927C8.87743 27.927 7.7063 25.956 7.34869 21.944C6.99109 17.932 7.00002 16.3067 7.00002 16.3067Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </BaseIcon>
  );
};

export default ThumbsUpIcon;
