export enum KeyboardEventType {
  // Delete/Backspace
  delete = 'delete',
  // Ctrl+C
  copy = 'copy',
  // Ctrl+V
  paste = 'paste',
  // Ctrl+Z
  cancel = 'cancel',
  // Ctrl+D
  duplication = 'duplication',
  enter = 'enter',
  escape = 'escape'
}

export const keyboardEventTypeOrder: KeyboardEventType[] =
  Object.values(KeyboardEventType);

// возвращаемое значение определяет, нужно ли блокировать выполнение задач в стеке дальше или нет
export type KeyboardEventListener = (params: {
  withCmd: boolean;
  withShift: boolean;
  originalEvent: KeyboardEvent;
}) => Promise<boolean> | boolean;
