import { FieldModel } from 'shared/models/form';
import { validateIsEmpty } from 'shared/entities/validator';
import { generateId } from 'shared/entities/common/utils';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import { UserPaymentTinkoffMethodServer } from 'shared/entities/userPayment';
import { TinkoffTerminalCreationError } from 'shared/entities/integrations';

export class NotConnectedTinkoffTerminal {
  readonly id: string;
  readonly key: FieldModel = new FieldModel<string>('', [validateIsEmpty]);
  readonly secret: FieldModel = new FieldModel<string>('', [validateIsEmpty]);
  readonly creatingStage: LoadingStageModel = new LoadingStageModel();
  readonly rootStore: IRootStore;

  constructor({ id }: { id: string }, rootStore: IRootStore) {
    this.id = id;
    this.rootStore = rootStore;
  }

  async create(): Promise<BaseResponse<UserPaymentTinkoffMethodServer>> {
    if (
      this.creatingStage.isLoading ||
      this.key.isError ||
      this.secret.isError
    ) {
      return {
        isError: true
      };
    }

    this.creatingStage.loading();

    const response = await this.rootStore.networkStore.api<
      UserPaymentTinkoffMethodServer,
      TinkoffTerminalCreationError
    >(apiUrls.OAUTH_TINKOFF_OBTAIN_TOKEN, {
      method: 'POST',
      data: {
        terminal_key: this.key.value,
        secret: this.secret.value
      },
      errorsMap: (code) => (t) =>
        t(`integrations.tinkoff.creationErrors.${code}`, {
          ns: 'entities'
        })
    });

    if (response.isError) {
      this.creatingStage.error();

      return {
        isError: true
      };
    } else {
      this.creatingStage.success();

      return {
        isError: false,
        data: response.data
      };
    }
  }

  static fromDefaultParams(rootStore: IRootStore): NotConnectedTinkoffTerminal {
    return new NotConnectedTinkoffTerminal({ id: generateId() }, rootStore);
  }
}
