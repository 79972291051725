import { IUser, UserServer } from 'shared/entities/user';

export default class UserModel implements IUser {
  name: string | null;

  email: string;

  confirmed: boolean;

  // сделать когда появится
  avatar?: string | null = null;

  constructor({ name, email, confirmed }: IUser) {
    this.email = email;
    this.name = name;
    this.confirmed = confirmed;
  }

  static fromJson(raw: UserServer): UserModel {
    return new UserModel(raw);
  }
}
