import { makeObservable, observable, action } from 'mobx';

import BaseEditStore from 'stores/editStore/BaseEditStore';
import { validateTextField } from 'shared/entities/validator';
import { ListEntityType, IListEntityModel } from 'shared/entities/lists';
import { IListsStore } from 'shared/entities/store/listsStore';
import { ListEditModel } from 'shared/models/lists';

export default class ListEditStore extends BaseEditStore<ListEditModel> {
  validators = {
    name: [validateTextField]
  };

  listsStore: IListsStore;
  lType: ListEntityType;

  constructor({
    listsStore,
    lType
  }: {
    listsStore: IListsStore;
    lType: ListEntityType;
  }) {
    super({
      initialData: new ListEditModel({
        name: ''
      })
    });
    this.listsStore = listsStore;
    this.lType = lType;

    makeObservable(this, {
      listsStore: observable,
      create: action
    });
  }

  async create(): Promise<BaseResponse<IListEntityModel>> {
    const params = this.save();

    if (!params) {
      return {
        isError: true
      };
    }

    const entity = await this.listsStore.createList({
      lType: this.lType,
      name: params.name
    });

    if (entity) {
      return {
        isError: false,
        data: entity
      };
    }

    return {
      isError: true
    };
  }
}
