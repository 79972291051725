import * as React from 'react';
import cn from 'classnames';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

import './ArrowIcon.modules.scss';

type Props = CommonIconProps & {
  direction?: 'left' | 'right' | 'top' | 'bottom';
};

const ArrowIcon: React.FC<Props> = ({
  direction = 'right',
  ...rest
}: Props) => {
  return (
    <BaseIcon
      iconSize={IconSize.XM}
      svgProps={{
        viewBox: '0 0 20 20'
      }}
      {...rest}
      styleName={cn('arrow-icon', `arrow-icon_${direction}`)}
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default ArrowIcon;
