import { action, computed, makeObservable, observable } from 'mobx';

import { INewList } from 'shared/entities/list';

import { LoadingStageModel } from '../loadingStage';
import { FieldModel } from '../form';

type KeyType = string | number | symbol;

export default class NewListModel<T, C extends KeyType = string>
  implements INewList<T, C>
{
  private _keys: C[];

  private _entities: Map<C, T>;

  readonly loadingStage: LoadingStageModel = new LoadingStageModel();

  readonly creatingStage: LoadingStageModel = new LoadingStageModel();

  readonly isInitialLoadModel: FieldModel<boolean> = new FieldModel<boolean>(
    true
  );

  readonly hasMoreModel: FieldModel<boolean> = new FieldModel<boolean>(true);

  readonly totalModel: FieldModel<number>;

  // дата последнего запроса
  readonly lastDateUpdateModel: FieldModel<Date | null> =
    new FieldModel<Date | null>(null);

  readonly disabledLoadMore: boolean;

  constructor(
    {
      keys,
      entities,
      disabledLoadMore = false
    }: { keys: C[]; entities: Map<C, T>; disabledLoadMore?: boolean } = {
      keys: [],
      entities: new Map<C, T>()
    }
  ) {
    makeObservable<NewListModel<T, C>, '_keys' | '_entities'>(this, {
      _keys: observable,
      _entities: observable,

      reset: action,
      removeEntity: action,
      removeEntities: action,
      addEntity: action,
      addEntities: action.bound,
      toStart: action,
      addEntityByIndex: action,

      keys: computed,
      entities: computed,
      length: computed,
      items: computed,
      hasMore: computed,
      isEmpty: computed
    });

    this._keys = keys;
    this._entities = entities;
    this.disabledLoadMore = disabledLoadMore;
  }

  get keys(): C[] {
    return this._keys;
  }

  get entities(): Record<C, T> {
    const obj = {} as Record<C, T>;
    this._keys.forEach((key) => {
      const entity = this.getEntity(key);
      if (entity) {
        obj[key] = entity;
      }
    });
    return obj;
  }

  get isEmpty(): boolean {
    return !this.length;
  }

  get isInitialLoad(): boolean {
    return this.isInitialLoadModel.value;
  }

  get hasMore(): boolean {
    return this.disabledLoadMore ? false : this.hasMoreModel.value;
  }

  get lastDateUpdate(): Date | null {
    return this.lastDateUpdateModel.value;
  }

  get total(): number {
    return this.totalModel.value;
  }

  get items(): T[] {
    const arr: T[] = [];
    this._keys.forEach((id: C) => {
      const item = this._entities.get(id);

      if (item) {
        arr.push(item);
      }
    });

    return arr;
  }

  get length(): number {
    return this.items.length;
  }

  addEntity = ({
    entity,
    key,
    start = false
  }: {
    entity: T;
    key: C;
    start?: boolean;
  }): void => {
    this._entities.set(key, entity);
    if (start) {
      this._keys.unshift(key);
    } else {
      this._keys.push(key);
    }
  };

  addEntities = ({
    entities,
    keys,
    initial,
    start
  }: {
    entities: Map<C, T>;
    keys: C[];
    initial: boolean;
    start?: boolean;
  }): void => {
    if (initial) {
      this._entities = entities;
      this._keys = keys;
      return;
    }

    keys.forEach((key) => {
      const entity = entities.get(key);

      if (!entity) {
        return;
      }
      this._entities.set(key, entity);
    });

    if (start) {
      this._keys.unshift(...keys);
    } else {
      this._keys.push(...keys);
    }
  };

  addEntityByIndex = ({
    entity,
    key,
    index
  }: {
    entity: T;
    key: C;
    index: number;
  }): void => {
    this._entities.set(key, entity);
    this.keys.splice(index, 0, key);
  };

  reset = (): void => {
    this._keys = [];
    this._entities.clear();
    this.hasMoreModel.changeValue(true);
    this.isInitialLoadModel.changeValue(true);
    this.loadingStage.reset();
  };

  removeEntity = (keyParam: C): void => {
    this._keys = this._keys.filter((key) => key !== keyParam);
    this._entities.delete(keyParam);
  };

  removeEntities = (keyParams: C[]): void => {
    keyParams.forEach(this.removeEntity);
  };

  getEntity = (keyParam: C): T | null => {
    return this._entities.get(keyParam) ?? null;
  };

  getEntityByIndex = (index: number): T | null => {
    const id = this.keys[index];

    return this.getEntity(id);
  };

  getEntityAndIndex = (key: C): { item: T; index: number } | null => {
    const index = this.keys.indexOf(key);

    if (index === -1) {
      return null;
    }

    const item = this.items[index];

    if (!item) {
      return null;
    }

    return {
      item,
      index
    };
  };

  /**
   * Перемещает элемент с ключом keyParam в начало списка
   * @param keyParam
   */
  toStart = (keyParam: C): void => {
    const foundIndex = this.keys.indexOf(keyParam);

    if (foundIndex === -1) {
      return;
    }

    this.keys.splice(foundIndex, 1);
    this.keys.splice(0, 0, keyParam);
  };

  static getListFromArray<S, T, C extends KeyType = string>(
    array: S[],
    normalizer: (raw: S) => { entity: T; key: C } | null
  ): { keys: C[]; entities: Map<C, T> } {
    const keys: C[] = [];
    const entities: Map<C, T> = new Map();

    array.forEach((item) => {
      const normalizedData = normalizer(item);
      if (!normalizedData) {
        return;
      }
      const { entity, key } = normalizedData;

      keys.push(key);
      entities.set(key, entity);
    });

    return {
      keys,
      entities
    };
  }

  fillByRawData<S>({
    raw,
    normalizer,
    initial = true
  }: {
    raw: S[];
    normalizer: (raw: S) => { entity: T; key: C } | null;
    initial?: boolean;
  }): void {
    const { keys, entities } = NewListModel.getListFromArray(raw, normalizer);
    this.addEntities({
      entities,
      keys,
      initial
    });
  }

  static fromJson<S, T, C extends KeyType = string>(
    raw: S[],
    normalizer: (raw: S) => { entity: T; key: C } | null
  ): NewListModel<T, C> {
    const { keys, entities } = NewListModel.getListFromArray(raw, normalizer);

    return new NewListModel({ keys, entities });
  }

  getCopy(
    getEntityCopy: (entity: T) => { entity: T; key?: C }
  ): NewListModel<T, C> {
    const keys: C[] = [];
    const entities: Map<C, T> = new Map<C, T>();
    this.keys.forEach((copiedKey) => {
      const copiedEntity = this.getEntity(copiedKey);
      if (!copiedEntity) {
        return;
      }

      const { entity, key } = getEntityCopy(copiedEntity);
      const actualKey = key ?? copiedKey;
      keys.push(actualKey);
      entities.set(actualKey, entity);
    });
    return new NewListModel<T, C>({
      keys,
      entities,
      disabledLoadMore: this.disabledLoadMore
    });
  }
}
