import BaseEditStore from 'stores/editStore/BaseEditStore';
import {
  CabinetManagerRoleType,
  IProjectManagerEdit,
  ProjectManagerRoleType
} from 'shared/entities/manager';
import {
  Validator,
  validateTextField,
  validateIsEmpty,
  validatePassword,
  validateName,
  validateEmailField
} from 'shared/entities/validator';
import { ProjectManagersStore } from 'stores/managers/projectManagersStore';
import ProjectManagerEditModel from 'shared/models/manager/ProjectManagerEditModel';

export default class ProjectManagerEditStore extends BaseEditStore<ProjectManagerEditModel> {
  validators: Record<
    keyof Omit<IProjectManagerEdit, 'projectId'>,
    Validator<any>[]
  > = {
    email: [validateTextField, validateEmailField],
    role: [validateIsEmpty],
    name: [validateTextField, validateName],
    password: [validateTextField, validatePassword]
  };

  managersStore: ProjectManagersStore;

  constructor({
    managersStore,
    role,
    projectId,
    cabinetRole
  }: {
    projectId: string | null;
    managersStore: ProjectManagersStore;
    role: ProjectManagerRoleType;
    cabinetRole: CabinetManagerRoleType;
  }) {
    super({
      initialData: new ProjectManagerEditModel({
        name: '',
        email: '',
        password: '',
        role,
        projectId: projectId ? projectId : '',
        cabinetRole
      })
    });

    this.managersStore = managersStore;
  }

  validateRole(): BaseResponse<{ role: ProjectManagerRoleType }> {
    const isError = this.validateField('role');

    if (isError || !this.entity.role) {
      return {
        isError: true
      };
    }

    return {
      isError: false,
      data: {
        role: this.entity.role
      }
    };
  }
}
