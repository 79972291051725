import { isSameDay } from 'date-fns';
import { computed, makeObservable } from 'mobx';

import { IDatePicker } from 'shared/newEntities/components/DatePicker';

import { CalendarDateModel } from './CalendarDateModel';

export default class DateModel extends CalendarDateModel<IDatePicker> {
  constructor(params: {
    date: Date;
    monthDate: Date;
    datePicker: IDatePicker;
  }) {
    super(params);

    makeObservable(this, {
      isActive: computed
    });
  }

  get isActive(): boolean {
    return (
      !!this.datePicker.calendarDateModel.value &&
      isSameDay(this.datePicker.calendarDateModel.value.date, this.date)
    );
  }
}
