import { action, computed, makeObservable, observable, reaction } from 'mobx';
import {
  isSameMonth,
  addMonths,
  differenceInCalendarMonths,
  isSameYear,
  isSameDay,
  addDays
} from 'date-fns';

import { FieldModel } from 'shared/models/form';
import { validateIntervalAndReshuffle } from 'shared/entities/common/utils';
import { DateFormat, transformDate } from 'shared/entities/date';
import { ReactionsHandlerStore } from 'stores/reactionsHandlerStore';
import { OpenStateModel } from 'shared/models/openState';
import {
  DatePickerKind,
  DateRangePickerValue,
  IDateRangePicker
} from 'shared/newEntities/components/DatePicker';

import { DateBaseModel, IntervalDateModel } from '../DateModel';
import { CalendarMonthModel } from '../CalendarMonthModel';

export class DateRangePickerModel
  extends ReactionsHandlerStore
  implements IDateRangePicker
{
  readonly kind: DatePickerKind.range = DatePickerKind.range;
  readonly firstValue: FieldModel<DateBaseModel | null> =
    new FieldModel<DateBaseModel | null>(null);
  readonly secondValue: FieldModel<DateBaseModel | null> =
    new FieldModel<DateBaseModel | null>(null);

  readonly hoverableDate: FieldModel<DateBaseModel | null> =
    new FieldModel<DateBaseModel | null>(null);
  readonly initialValue: FieldModel<DateRangePickerValue> =
    new FieldModel<DateRangePickerValue>(null);
  readonly cb: FieldModel<(value: DateRangePickerValue) => void>;

  readonly modalOpened: OpenStateModel = new OpenStateModel();

  firstMonthModel: CalendarMonthModel<DatePickerKind.range>;
  secondMonthModel: CalendarMonthModel<DatePickerKind.range>;

  constructor(
    value: DateRangePickerValue,
    cb: (value: DateRangePickerValue) => void
  ) {
    super();

    this.cb = new FieldModel(cb);
    this.initialize(value);

    makeObservable(this, {
      firstMonthModel: observable.ref,
      secondMonthModel: observable.ref,

      applyButtonDisabled: computed,
      initialValueString: computed,
      isEqual: computed,
      isTodayActive: computed,
      isYesterdayActive: computed,
      isPreviousWeekActive: computed,
      isPreviousMonthActive: computed,
      isPreviousYearActive: computed,

      handleCalendarDateClick: action.bound,
      handleCalendarDateHover: action.bound,
      moveFirstMonth: action.bound,
      moveSecondMonth: action.bound,
      handleChangeSecondValue: action.bound,
      handleChangeFirstValue: action.bound,
      apply: action.bound,
      reset: action.bound,
      showInterval: action.bound,
      handleChangeInterval: action.bound,
      chooseTodayInterval: action.bound,
      chooseYesterdayInterval: action.bound,
      choosePreviousWeekInterval: action.bound,
      choosePreviousMonthInterval: action.bound,
      choosePreviousYearInterval: action.bound
    });
  }

  get applyButtonDisabled(): boolean {
    return !this.firstValue.value || !this.secondValue.value;
  }

  get isTodayActive(): boolean {
    const model = new DateBaseModel(new Date());
    return (
      !!this.firstValue.value &&
      !!this.secondValue.value &&
      this.firstValue.value.isEqual(model) &&
      this.secondValue.value.isEqual(model)
    );
  }

  get isYesterdayActive(): boolean {
    const model = new DateBaseModel(addDays(new Date(), -1));
    return (
      !!this.firstValue.value &&
      !!this.secondValue.value &&
      this.firstValue.value.isEqual(model) &&
      this.secondValue.value.isEqual(model)
    );
  }

  get isPreviousWeekActive(): boolean {
    const model = new DateBaseModel(new Date());
    const model1 = new DateBaseModel(addDays(new Date(), -7));
    return (
      !!this.firstValue.value &&
      !!this.secondValue.value &&
      this.firstValue.value.isEqual(model1) &&
      this.secondValue.value.isEqual(model)
    );
  }

  get isPreviousMonthActive(): boolean {
    const model = new DateBaseModel(new Date());
    const model1 = new DateBaseModel(addDays(new Date(), -30));
    return (
      !!this.firstValue.value &&
      !!this.secondValue.value &&
      this.firstValue.value.isEqual(model1) &&
      this.secondValue.value.isEqual(model)
    );
  }

  get isPreviousYearActive(): boolean {
    const model = new DateBaseModel(new Date());
    const model1 = new DateBaseModel(addDays(new Date(), -365));
    return (
      !!this.firstValue.value &&
      !!this.secondValue.value &&
      this.firstValue.value.isEqual(model1) &&
      this.secondValue.value.isEqual(model)
    );
  }

  get isEqual(): boolean {
    return (
      (!this.initialValue.value &&
        !this.firstValue.value &&
        !this.secondValue.value) ||
      (!!this.initialValue.value &&
        !!this.firstValue.value &&
        !!this.secondValue.value &&
        isSameDay(this.initialValue.value[0], this.firstValue.value.date) &&
        isSameDay(this.initialValue.value[1], this.secondValue.value.date))
    );
  }

  get initialValueString(): string | null {
    if (!this.initialValue.value) {
      return null;
    }
    const curDate = new Date();

    if (
      isSameYear(this.initialValue.value[0], curDate) &&
      isSameYear(this.initialValue.value[1], curDate)
    ) {
      if (isSameDay(this.initialValue.value[0], this.initialValue.value[1])) {
        return `${transformDate(
          this.initialValue.value[0],
          DateFormat.dayMonth
        )}`;
      }
      return `${transformDate(
        this.initialValue.value[0],
        DateFormat.dayMonth
      )} - ${transformDate(this.initialValue.value[1], DateFormat.dayMonth)}`;
    }

    return `${transformDate(
      this.initialValue.value[0],
      DateFormat.date
    )} - ${transformDate(this.initialValue.value[1], DateFormat.date)}`;
  }

  initializeReactions(): void {
    this.addReaction({
      key: 'opened',
      reaction: reaction(
        () => this.modalOpened.opened,
        (opened) => {
          if (!opened) {
            this.reset();
          }
        }
      )
    });
  }

  initialize(value: DateRangePickerValue): void {
    /*
     * Проверка начального значения: если даты в интервале идут не по порядку, изменяем
     * */
    const interval = value
      ? validateIntervalAndReshuffle(value[0], value[1])
      : null;
    this.initialValue.changeValue(interval);

    this.firstValue.changeValue(
      interval ? new DateBaseModel(interval[0]) : null
    );
    this.secondValue.changeValue(
      interval ? new DateBaseModel(interval[1]) : null
    );

    /*
     * Определение начальных месяцев
     * */
    if (this.firstValue.value) {
      this.firstMonthModel = CalendarMonthModel.fromDefaultParams({
        monthDate: this.firstValue.value.date,
        datePicker: this
      });

      if (this.secondValue.value) {
        /*
         * Если интервал располагает в одном и том же месяце, то второй месяц - следующий
         * */
        if (
          isSameMonth(this.firstValue.value.date, this.secondValue.value.date)
        ) {
          this.secondMonthModel = CalendarMonthModel.fromDefaultParams({
            monthDate: addMonths(this.firstValue.value.date, 1),
            datePicker: this
          });
        } else {
          /*
           * Если интервал располагает в разных месяцах, то второй месяц - месяц второй даты в интервале
           * */
          this.secondMonthModel = CalendarMonthModel.fromDefaultParams({
            monthDate: this.secondValue.value.date,
            datePicker: this
          });
        }
      } else {
        this.secondMonthModel = CalendarMonthModel.fromDefaultParams({
          monthDate: addMonths(this.firstValue.value.date, 1),
          datePicker: this
        });
      }
    } else {
      /*
       * Если нет начального интервала, показываем текущий месяц + следующий
       * */
      const firstMonthDate = new Date();

      this.firstMonthModel = CalendarMonthModel.fromDefaultParams({
        monthDate: firstMonthDate,
        datePicker: this
      });
      this.secondMonthModel = CalendarMonthModel.fromDefaultParams({
        monthDate: addMonths(firstMonthDate, 1),
        datePicker: this
      });
    }
  }

  showDateOnFirstCalendar(model: DateBaseModel): void {
    const diffInMothBetweenDateAndFirstMonth = differenceInCalendarMonths(
      this.firstMonthModel.monthDate,
      model.date
    );

    this.moveFirstMonth(-diffInMothBetweenDateAndFirstMonth);
  }

  showDateOnSecondCalendar(model: DateBaseModel): void {
    const diffInMothBetweenDateAndSecondMonth = differenceInCalendarMonths(
      this.secondMonthModel.monthDate,
      model.date
    );

    this.moveSecondMonth(-diffInMothBetweenDateAndSecondMonth);
  }

  handleChangeFirstValue(value: DateBaseModel): void {
    const model = value.getCopy();

    if (this.secondValue.value && model.date > this.secondValue.value.date) {
      this.firstValue.changeValue(this.secondValue.value);
      this.secondValue.changeValue(model);
    } else {
      this.firstValue.changeValue(model);
    }

    this.showInterval();
  }

  handleChangeSecondValue(value: DateBaseModel): void {
    const model = value.getCopy();

    if (this.firstValue.value && model.date < this.firstValue.value.date) {
      this.secondValue.changeValue(this.firstValue.value);
      this.firstValue.changeValue(model);
    } else {
      this.secondValue.changeValue(model);
    }

    this.showInterval();
  }

  handleChangeInterval(value1: DateBaseModel, value2: DateBaseModel): void {
    if (value2.date > value1.date) {
      this.firstValue.changeValue(value1);
      this.secondValue.changeValue(value2);
    } else {
      this.firstValue.changeValue(value2);
      this.secondValue.changeValue(value1);
    }

    this.showInterval();
    this.apply();
  }

  chooseTodayInterval(): void {
    const model = new DateBaseModel(new Date());
    const model1 = model.getCopy();
    this.handleChangeInterval(model, model1);
  }

  chooseYesterdayInterval(): void {
    const model = new DateBaseModel(addDays(new Date(), -1));
    const model1 = model.getCopy();
    this.handleChangeInterval(model, model1);
  }

  choosePreviousWeekInterval(): void {
    const model = new DateBaseModel(new Date());
    const model1 = new DateBaseModel(addDays(model.date, -7));
    this.handleChangeInterval(model1, model);
  }

  choosePreviousMonthInterval(): void {
    const model = new DateBaseModel(new Date());
    const model1 = new DateBaseModel(addDays(model.date, -30));
    this.handleChangeInterval(model1, model);
  }

  choosePreviousYearInterval(): void {
    const model = new DateBaseModel(new Date());
    const model1 = new DateBaseModel(addDays(model.date, -365));
    this.handleChangeInterval(model1, model);
  }

  /**
   * Отобразить интервал. Первое значение в первом месяце, второе либо также в первом, либо во втором
   */
  showInterval(): void {
    if (this.firstValue.value) {
      const diffInMonths = differenceInCalendarMonths(
        this.firstMonthModel.monthDate,
        this.firstValue.value.date
      );

      this.moveFirstMonth(-diffInMonths);
    }

    if (this.secondValue.value) {
      if (this.firstValue.value) {
        const diffInMonthsBetweenDaysInInterval = differenceInCalendarMonths(
          this.firstValue.value.date,
          this.secondValue.value.date
        );

        /*
         * Если интервал располагает в одном месяце, то выходим
         * */
        if (diffInMonthsBetweenDaysInInterval === 0) {
          return;
        }
      }

      const diffInMonths = differenceInCalendarMonths(
        this.secondMonthModel.monthDate,
        this.secondValue.value.date
      );

      this.moveSecondMonth(-diffInMonths);
    }
  }

  /**
   * Обработчик клика на дату в календаре
   * @param value
   */
  handleCalendarDateClick(value: IntervalDateModel): void {
    const model = new DateBaseModel(value.date);
    this.hoverableDate.changeValue(null);

    if (this.firstValue.value) {
      if (!this.secondValue.value) {
        if (model.date > this.firstValue.value.date) {
          this.secondValue.changeValue(model);
        } else {
          this.secondValue.changeValue(this.firstValue.value);
          this.firstValue.changeValue(model);
        }
      } else {
        this.firstValue.changeValue(model);
        this.secondValue.changeValue(null);
        this.hoverableDate.changeValue(model);
      }
    } else {
      this.firstValue.changeValue(model);
      this.secondValue.changeValue(null);
      this.hoverableDate.changeValue(model);
    }

    if (value.isInCalendarMonth) {
      return;
    }

    /*
     * Если дата, которую выбрали, не находится в месяце(disabled-дата), где ее отображают,
     * тогда месяц, где ее отображают, перемещают, чтобы она была видна
     * */
    const isClickInFirstCalendar =
      differenceInCalendarMonths(
        this.firstMonthModel.monthDate,
        value.monthDate
      ) === 0;

    if (isClickInFirstCalendar) {
      this.showDateOnFirstCalendar(model);
    } else {
      this.showDateOnSecondCalendar(model);
    }
  }

  /**
   * Обработчик наведения на дату в календаре
   * @param value
   */
  handleCalendarDateHover(value: IntervalDateModel): void {
    if (this.firstValue.value && !this.secondValue.value) {
      this.hoverableDate.changeValue(new DateBaseModel(value.date));
    }
  }

  /**
   * Перемещение первого месяца
   * @param offset на сколько переместить
   */
  moveFirstMonth(offset: number): void {
    /*
     * Переместили первый месяц
     * */
    this.firstMonthModel = CalendarMonthModel.fromDefaultParams({
      monthDate: addMonths(this.firstMonthModel.monthDate, offset),
      datePicker: this
    });

    const diffInMonths = differenceInCalendarMonths(
      this.secondMonthModel.monthDate,
      this.firstMonthModel.monthDate
    );

    /*
     * Первый месяц должен идти перед вторым, если после перемещения первого месяца, второй оказался сзади, делаем его следующим после первого
     * */
    if (diffInMonths < 1) {
      this.secondMonthModel = CalendarMonthModel.fromDefaultParams({
        monthDate: addMonths(this.firstMonthModel.monthDate, 1),
        datePicker: this
      });
    }
  }

  /**
   * Перемещение второго месяца
   * @param offset на сколько переместить
   */
  moveSecondMonth(offset: number): void {
    /*
     * Переместили второй месяц
     * */
    this.secondMonthModel = CalendarMonthModel.fromDefaultParams({
      monthDate: addMonths(this.secondMonthModel.monthDate, offset),
      datePicker: this
    });

    const diffInMonths = differenceInCalendarMonths(
      this.secondMonthModel.monthDate,
      this.firstMonthModel.monthDate
    );

    /*
     * Первый месяц должен идти перед вторым, если после перемещения второго месяца, первый оказался спереди, делаем его предыдущим
     * */
    if (diffInMonths < 1) {
      this.firstMonthModel = CalendarMonthModel.fromDefaultParams({
        monthDate: addMonths(this.secondMonthModel.monthDate, -1),
        datePicker: this
      });
    }
  }

  moveFirstMonthForward = (): void => {
    this.moveFirstMonth(1);
  };

  moveFirstMonthBack = (): void => {
    this.moveFirstMonth(-1);
  };

  moveSecondMonthBack = (): void => {
    this.moveSecondMonth(-1);
  };

  moveSecondMonthForward = (): void => {
    this.moveSecondMonth(1);
  };

  apply(): BaseResponse<DateRangePickerValue> {
    if (this.firstValue.value && this.secondValue.value) {
      const newInitialData: [Date, Date] = [
        new Date(this.firstValue.value.date),
        new Date(this.secondValue.value.date)
      ];
      this.initialValue.changeValue(newInitialData);
      this.cb.value(newInitialData);

      return {
        isError: false,
        data: newInitialData
      };
    }

    return {
      isError: true
    };
  }

  reset(): DateRangePickerValue {
    this.initialize(this.initialValue.value);

    return this.initialValue.value;
  }
}
