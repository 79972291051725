import { computed, makeObservable } from 'mobx';

import {
  ChannelCommonServer,
  CreateChannelError,
  CreateWazzupError,
  mapCreateChannelErrorToMessage,
  mapCreateWazzupChannelErrorToMessage
} from 'shared/entities/channels';
import { apiUrls } from 'shared/entities/domain';
import { IRootStore } from 'shared/entities/store/rootStore';
import { validateIsEmpty } from 'shared/entities/validator';
import { WazzupTokenKind } from 'shared/entities/wazzup';
import { CommonChannelModel } from 'shared/models/channel';
import { FieldModel } from 'shared/models/form';
import { LoadingStageModel } from 'shared/models/loadingStage';

export class WazzupCreationStore {
  readonly token: FieldModel<string> = new FieldModel<string>('', [
    validateIsEmpty
  ]);
  readonly tokenKind: WazzupTokenKind;
  private rootStore: IRootStore;
  readonly creationStage: LoadingStageModel = new LoadingStageModel();

  constructor({
    rootStore,
    tokenKind
  }: {
    rootStore: IRootStore;
    tokenKind: WazzupTokenKind;
  }) {
    this.rootStore = rootStore;
    this.tokenKind = tokenKind;

    makeObservable(this, {
      isError: computed
    });
  }

  get isError(): boolean {
    return this.token.isError;
  }

  validate(): boolean {
    this.token.validate();

    return this.isError;
  }

  reset(): void {
    this.token.reset();
  }

  create = async (): Promise<BaseResponse> => {
    if (this.creationStage.isLoading) {
      return {
        isError: true
      };
    }

    const isError = this.validate();

    if (isError) {
      return {
        isError: true
      };
    }

    this.creationStage.loading();

    const response = await this.rootStore.networkStore.api<
      {
        channel: ChannelCommonServer;
      },
      CreateChannelError | CreateWazzupError
    >(apiUrls.CHANNELS_ADD_WZ, {
      method: 'POST',
      data: {
        token: this.token.value,
        token_kind: this.tokenKind
      },
      errorsMap: mapCreateWazzupChannelErrorToMessage
    });

    if (response.isError) {
      this.creationStage.error();

      if (
        response.data?.code &&
        Object.values(CreateChannelError).includes(response.data?.code)
      ) {
        this.token.setError(mapCreateChannelErrorToMessage(response.data.code));
      }

      return {
        isError: true
      };
    }

    const channel = CommonChannelModel.fromJson(
      response.data.channel,
      this.rootStore
    );
    this.rootStore.channelsStore.addCreatedChannel(channel);

    this.reset();
    this.creationStage.success();

    return {
      isError: false
    };
  };
}
