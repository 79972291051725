import * as React from 'react';

import { ChannelsModalStore } from './ChannelsModalStore';
import { ChannelsModalStoreContext } from './context';

const useChannelsModalStore = (): {
  store: ChannelsModalStore;
  close?: () => void;
} => {
  const Context = React.useContext(ChannelsModalStoreContext);

  return { store: Context.store as ChannelsModalStore, close: Context.close };
};

export * from './context';
export { ChannelsModalStore, useChannelsModalStore };
