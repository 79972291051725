import { action, computed, makeObservable, observable } from 'mobx';

import { IRootStore } from 'shared/entities/store/rootStore';
import { IBaseManagersStore } from 'shared/entities/store/managersStore';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';
import NewListModel from 'shared/models/NewListModel';
import { LoadingStageModel } from 'shared/models/loadingStage';

export default abstract class BaseManagersStore<T>
  extends ComponentLoadingStore
  implements IBaseManagersStore<T>
{
  readonly managers: NewListModel<T> = new NewListModel<T>();

  readonly rootStore: IRootStore;

  selectedManagerEmail: string | null = null;

  readonly addingManagerStage: LoadingStageModel = new LoadingStageModel();

  constructor({ rootStore }: { rootStore: IRootStore }) {
    super();
    this.rootStore = rootStore;

    makeObservable(this, {
      selectedManagerEmail: observable,
      rootStore: observable,

      selectedManager: computed,

      changeSelectedManagerEmail: action,
      unselectSelectedManager: action
    });
  }

  get loaded(): boolean {
    return this.managers.loadingStage.successfullyLoaded;
  }

  abstract get canBeLoaded(): boolean;

  changeSelectedManagerEmail = (email: string | null) => {
    this.selectedManagerEmail = email;
  };

  unselectSelectedManager = () => {
    this.changeSelectedManagerEmail(null);
  };

  get selectedManager(): T | null {
    return this.selectedManagerEmail
      ? this.managers.getEntity(this.selectedManagerEmail)
      : null;
  }

  abstract load(): Promise<BaseResponse>;

  remove({ email }: { email: string }): void {
    if (this.selectedManagerEmail === email) {
      this.unselectSelectedManager();
    }
    this.managers.removeEntity(email);
  }

  reset(): void {
    this.managers.reset();
  }
}
