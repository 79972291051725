import * as React from 'react';

import {
  CommonIconProps,
  LogoIconProps,
  IconSize
} from 'shared/entities/components/Icon';
import BaseIcon from 'shared/newComponents/icons/BaseIcon';

const OKIcon: React.FC<LogoIconProps> = ({ color, ...rest }: LogoIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXL}
      svgProps={{
        viewBox: '0 0 40 40'
      }}
      {...rest}
    >
      <g clipPath="url(#clip0_6307_69)">
        <path
          d="M40 20C40 40 40 40 20 40C0 40 0 40 0 20C0 0 0 0 20 0C40 0 40 0 40 20Z"
          fill={color === 'original' ? '#E27E35' : 'currentColor'}
        />
        <path
          d="M23.1328 26.8008C24.7109 26.4414 26.2305 25.8164 27.6289 24.9375C28.6875 24.2695 29.0078 22.8711 28.3398 21.8086C27.6719 20.7461 26.2735 20.4258 25.2109 21.0938C22.0391 23.0898 17.957 23.0859 14.7851 21.0938C13.7227 20.4258 12.3242 20.7461 11.6562 21.8086C10.9883 22.8672 11.3086 24.2695 12.3672 24.9375C13.7656 25.8164 15.2851 26.4414 16.8633 26.8008L12.5391 31.1289C11.6524 32.0156 11.6524 33.4492 12.5391 34.3359C12.9844 34.7773 13.5625 35 14.1406 35C14.7226 35 15.3008 34.7773 15.7461 34.3359L20 30.082L24.2539 34.3359C25.1406 35.2227 26.5742 35.2227 27.4609 34.3359C28.3476 33.4492 28.3476 32.0117 27.4609 31.1289L23.1328 26.8008ZM20 9.53906C21.7696 9.53906 23.2071 10.9766 23.2071 12.7461C23.2071 14.5117 21.7696 15.9492 20 15.9492C18.2344 15.9492 16.793 14.5117 16.793 12.7461C16.793 10.9766 18.2344 9.53906 20 9.53906ZM20 20.4844C24.2696 20.4844 27.7422 17.0117 27.7422 12.7461C27.7422 8.47266 24.2696 5 20 5C15.7305 5 12.2578 8.47266 12.2578 12.7422C12.2578 17.0117 15.7305 20.4844 20 20.4844Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6307_69">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export const OKOriginalIcon: React.FC<CommonIconProps> = (props) => (
  <OKIcon color="original" {...props} />
);

export const OKTransparentIcon: React.FC<CommonIconProps> = (props) => (
  <OKIcon color="transparent" {...props} />
);
