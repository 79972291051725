import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const VKBaseIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M12.7698 17.29C7.29981 17.29 4.1798 13.54 4.0498 7.29999H6.7898C6.8798 11.88 8.8998 13.82 10.4998 14.22V7.29999H13.0798V11.25C14.6598 11.08 16.3198 9.27999 16.8798 7.29999H19.4598C19.0298 9.73999 17.2298 11.54 15.9498 12.28C17.2298 12.88 19.2798 14.45 20.0598 17.29H17.2198C16.6098 15.39 15.0898 13.92 13.0798 13.72V17.29H12.7698Z"
        fill="white"
      />
    </BaseIcon>
  );
};

export default VKBaseIcon;
