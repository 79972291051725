import * as React from 'react';

import './FadeBackground.modules.scss';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const FadeBackground: React.FC<Props> = ({ className, children }) => {
  return (
    <div styleName="bg" className={className}>
      {children}
    </div>
  );
};

export default FadeBackground;
