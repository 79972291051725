import * as React from 'react';
import cn from 'classnames';

import { PopupContainer } from 'shared/components/popups';
import {
  PopupEventType,
  PopupPosition,
  PopupRef
} from 'shared/entities/components/Popup';
import { ReferenceProps } from 'shared/newEntities/components/Reference';
import ExternalLink from 'shared/newComponents/ExternalLink';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import { CircledQuestionIcon } from 'shared/newComponents/icons';
import { IconSize } from 'shared/entities/components/Icon';
import { useUiStore } from 'stores/index';

import Typography from '../Typography';

import styles from './Reference.modules.scss';

type Props = ReferenceProps & {
  className?: string;
};

const Reference = React.forwardRef<PopupRef, Props>(
  (
    {
      title,
      description,
      docsLink,
      className,
      trigger,
      position = [PopupPosition.topCenter],
      disabled = false,
      titleWeight = TypographyWeight.bold,
      disabledOnMobile
    }: Props,
    ref
  ) => {
    const uiStore = useUiStore();
    return (
      <PopupContainer
        triggerClassname={cn(styles['icon-container'], className)}
        trigger={
          trigger || (
            <div className={styles.icon}>
              <CircledQuestionIcon iconSize={IconSize.S} />
            </div>
          )
        }
        on={PopupEventType.hover}
        position={position}
        ref={ref}
        disabled={
          disabled || (disabledOnMobile ? uiStore.isMobileDevice : false)
        }
      >
        <div
          className={cn(
            styles.popup,
            description && styles['popup_with-description']
          )}
        >
          <Typography
            fontType={TypographyType.text}
            fontSize={TypographySize.xs}
            fontWeight={titleWeight}
          >
            {title}
          </Typography>
          {description && (
            <div className={styles['popup__description']}>
              <Typography
                Element="span"
                fontType={TypographyType.text}
                fontSize={TypographySize.xs}
                fontWeight={TypographyWeight.heavy}
                fontColor={TypographyColor.secondary}
              >
                {description}
              </Typography>{' '}
              {docsLink && (
                <ExternalLink
                  fontType={TypographyType.text}
                  fontSize={TypographySize.xs}
                  fontWeight={TypographyWeight.heavy}
                  href={docsLink.link}
                  className={styles['popup__link']}
                  fontColor={null}
                >
                  {docsLink.title
                    ? docsLink.title
                    : (t) =>
                        t('Reference.docsLink', {
                          ns: 'newComponents'
                        })}
                </ExternalLink>
              )}
            </div>
          )}
        </div>
      </PopupContainer>
    );
  }
);

export default React.memo(Reference);
