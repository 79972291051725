const cropLongNumber = (num: number): string => {
  if (num <= 999) {
    return num.toString();
  }
  if (num >= 1000 && num <= 999999) {
    return `${num / 1000}k`;
  }
  if (num >= 1000000 && num <= 999999999) {
    return `${num / 1000000}m`;
  }

  if (num >= 1000000000 && num <= 999999999999) {
    return `${num / 1000000000}b`;
  }

  if (num >= 1000000000000) {
    return `+${num / 1000000000}b`;
  }

  return '';
};

export default cropLongNumber;
