import { TranslationString } from 'shared/entities/localization';
import {
  AIIntentKind,
  AIIntentKindServer,
  mapAIIntentKindToTitle
} from 'shared/entities/smartbotAIInfo';

export class AIIntentKindModel {
  kind: AIIntentKind;
  text: string;

  constructor({ kind, text }: { kind: AIIntentKind; text: string }) {
    this.kind = kind;
    this.text = text;
  }

  // получаем значение не из this.text для того,  чтобы поддержать английскую версию
  get title(): TranslationString {
    return mapAIIntentKindToTitle(this.kind);
  }

  get id(): string {
    return this.kind;
  }

  static fromJson(raw: AIIntentKindServer): AIIntentKindModel {
    return new AIIntentKindModel({
      kind: raw.kind,
      text: raw.text
    });
  }
}
