type IReactionDisposer = () => void;

export default class ReactionsHandlerStore {
  reactions: Map<string, IReactionDisposer> = new Map<
    string,
    IReactionDisposer
  >();

  addReaction({
    key,
    reaction
  }: {
    key: string;
    reaction: IReactionDisposer;
  }): void {
    this.removeReaction({ key });

    this.reactions.set(key, reaction);
  }

  removeReaction({ key }): void {
    const prevDisposer = this.reactions.get(key);

    if (prevDisposer) {
      this.dispose({ key });
      this.reactions.delete(key);
    }
  }

  dispose({ key }: { key: string }): void {
    const disposer = this.reactions.get(key);

    if (disposer) {
      disposer();
    }
  }

  disposeAll(): void {
    Array.from(this.reactions.keys()).forEach((key) => this.dispose({ key }));
  }
}
