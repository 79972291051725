import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const PayMasterIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M21.1656 15.0419C21.1656 15.1932 21.0392 15.395 20.8875 15.4706L12.2655 19.3294C12.1138 19.4051 11.8609 19.4051 11.7345 19.3294L3.08723 15.4706C2.93552 15.395 2.8091 15.2184 2.8091 15.0419V7.42519C2.8091 7.27387 2.93552 7.17299 3.08723 7.24865L11.7598 11.3092C11.9115 11.3849 12.1391 11.3849 12.2908 11.3092L20.9128 7.27387C21.0645 7.19821 21.1909 7.27387 21.1909 7.45041V15.0419H21.1656ZM4.14918 6.87034V6.36592C4.14918 6.21459 4.2756 6.11371 4.42731 6.18937L11.7345 9.59418C11.8862 9.66985 12.1138 9.66985 12.2655 9.59418L19.3451 6.18937C19.4968 6.11371 19.6233 6.18937 19.598 6.36592V6.99644L12.2908 10.4265C12.1391 10.5021 11.9115 10.5021 11.7598 10.4265L4.14918 6.87034C4.14918 6.87034 4.12389 6.87034 4.14918 6.87034ZM21.7219 5.98761L20.3565 6.64335V5.20576C20.3565 5.05444 20.2301 4.97877 20.0784 5.02922L12.2655 8.78712C12.1138 8.86278 11.8862 8.86278 11.7345 8.78712L3.69406 5.02922C3.54235 4.95355 3.41593 5.02922 3.41593 5.20576V6.54246L2.27813 5.98761C2.12642 5.93717 2 6.01283 2 6.16415V15.5715C2 15.7228 2.12642 15.9246 2.27813 16.0003L11.7345 20.2374C11.8862 20.313 12.1391 20.313 12.2655 20.2374L21.7219 16.0003C21.8736 15.9246 22 15.7481 22 15.5715V6.16415C21.9747 6.01283 21.8483 5.93717 21.7219 5.98761Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default PayMasterIcon;
