import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const PlusIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XM}
      svgProps={{
        viewBox: '0 0 20 20'
      }}
      {...props}
    >
      <path
        d="M9.99999 4.16663V15.8333M4.16666 9.99996H15.8333"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default PlusIcon;
