import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const YooMoneyIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      svgProps={{ viewBox: '0 0 24 24' }}
      iconSize={IconSize.L}
      {...props}
    >
      <path
        d="M14.6237 5C10.4464 5 7.12366 8.37026 7.12366 12.5C7.12366 16.6772 10.4939 20 14.6237 20C18.7534 20 22.1237 16.6297 22.1237 12.5C22.1711 8.37026 18.7534 5 14.6237 5ZM14.6237 15.3006C13.1047 15.3006 11.823 14.019 11.823 12.5C11.823 10.981 13.1047 9.69937 14.6237 9.69937C16.1426 9.69937 17.4243 10.981 17.4243 12.5C17.4243 14.019 16.1901 15.3006 14.6237 15.3006Z"
        fill="#8B3FFD"
      />
      <path
        d="M7.12344 7.18336V18.1011H4.41772L1 7.18336H7.12344Z"
        fill="#8B3FFD"
      />
    </BaseIcon>
  );
};

export default YooMoneyIcon;
