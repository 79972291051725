import {
  GoogleAnalytics,
  YandexAnalytics,
  RoistatAnalytics
} from 'shared/models/analytics';
import { IRootStore } from 'shared/entities/store/rootStore';
import { IAnalyticsStore } from 'shared/entities/store/analyticsStore';
import {
  AnalyticsEventObj,
  AnalyticsEventType,
  AnalyticsPayload
} from 'shared/entities/analytics';
import { FieldModel } from 'shared/models/form';

export default class Analytics implements IAnalyticsStore {
  readonly ga: GoogleAnalytics = new GoogleAnalytics();
  readonly ym: YandexAnalytics = new YandexAnalytics();
  readonly roistat: RoistatAnalytics = new RoistatAnalytics();
  readonly finished: FieldModel<boolean> = new FieldModel<boolean>(false);
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  initialize = async (): Promise<void> => {
    await Promise.all([
      this.ga.initialize(),
      this.ym.initialize(),
      this.roistat.initialize()
    ]);
    this.finished.changeValue(true);
  };

  reset = (): void => {
    this.ga.reset();
    this.ym.reset();
    this.roistat.reset();
  };

  sendEvent(event: AnalyticsEventType, payload: AnalyticsPayload = {}): void {
    const actualEvent: AnalyticsEventObj = {
      type: event,
      payload: {
        user_email: this.rootStore.userStore.user?.email || null,
        ...payload
      }
    };

    this.ga.addToQueue(actualEvent);
    this.ym.addToQueue(actualEvent);
    this.roistat.addToQueue(actualEvent);
  }
}
