import { REG_EXP_CIRILLICA } from 'shared/entities/regExp/url';

import getURLObjectFromUrl from './getURLObjectFromUrl';

const getDomainNameFromUrl = (url: string): string | null => {
  if (!url.match(REG_EXP_CIRILLICA)) {
    const urlObj = getURLObjectFromUrl(url);
    if (!urlObj) {
      return null;
    }

    return urlObj.hostname;
  }
  const matchGroups = url.match(/^((\w+:\/\/)*([^/]+))/);
  if (!matchGroups) {
    return null;
  }
  return matchGroups[3];
};

export default getDomainNameFromUrl;
