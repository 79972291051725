import {
  AIAggregatedDocType,
  AICommonDocModelParams,
  AIDocServer
} from 'shared/entities/smartbotAI';
import { getUrlWithoutProtocol } from 'shared/entities/common/utils';
import { IRootStore } from 'shared/entities/store/rootStore';

import { AICommonDocModel } from './AICommonDocModel';

export class AICommonSiteDocModel extends AICommonDocModel<AIAggregatedDocType.site> {
  constructor(
    params: Omit<AICommonDocModelParams<AIAggregatedDocType.site>, 'type'>
  ) {
    super({ ...params, type: AIAggregatedDocType.site });
  }
  static fromJson({
    raw,
    botId,
    rootStore
  }: {
    raw: AIDocServer;
    botId: string;
    rootStore: IRootStore;
  }): AICommonSiteDocModel {
    return new AICommonSiteDocModel({
      rootStore,
      id: raw.id,
      botId,
      title: getUrlWithoutProtocol(raw.url) || raw.url,
      url: raw.url,
      cost: raw.cost,
      dateCreated: new Date(raw.date_created),
      errors: raw.errors || null,
      progress: raw.progress
    });
  }
}
