export enum IntervalUnit {
  seconds = 'seconds',
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
  weeks = 'weeks',
  months = 'months',
  tomorrow = 'tomorrow'
}

export type TimeoutServer = {
  interval: string;
  unit: IntervalUnit;
};
