import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const UnlinkIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XM}
      svgProps={{
        viewBox: '0 0 20 20'
      }}
      {...props}
    >
      <g clipPath="url(#clip0_2732_1288)">
        <path
          d="M7.08268 12.9166L12.916 7.08331M7.49935 3.33332V1.66666M12.4993 16.6667V18.3333M3.33268 7.49999H1.66602M16.666 12.5H18.3327M4.09453 4.09517L2.91602 2.91666M15.9042 15.9047L17.0827 17.0832M9.99938 14.714L8.23161 16.4817C6.92986 17.7835 4.81931 17.7835 3.51757 16.4817C2.21582 15.18 2.21582 13.0694 3.51757 11.7677L5.28533 9.99992M14.7134 9.99992L16.4812 8.23215C17.7829 6.9304 17.7829 4.81985 16.4812 3.5181C15.1794 2.21636 13.0689 2.21636 11.7671 3.5181L9.99938 5.28587"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2732_1288">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export default UnlinkIcon;
