import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import {
  ConfirmationType,
  DomainServer,
  DomainsListServer,
  VerifiedDomainsError,
  mapVerifiedDomainsErrorToMessage
} from 'shared/entities/verifiedDomains';
import { AppNotificationType } from 'shared/entities/appNotifications';
import {
  VerifiedConfirmedDomainModel,
  VerifiedDomainsBaseResponse,
  VerifiedUnconfirmedDomainModel
} from 'shared/models/domain';
import {
  validateIsEmpty,
  validateUrl,
  ValidatorResult
} from 'shared/entities/validator';
import { mapErrorCodeToMessage } from 'shared/entities/common/utils';
import { IDomainsStore } from 'shared/entities/store/domainsStore';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';
import { FieldModel } from 'shared/models/form';
import NewListModel from 'shared/models/NewListModel';
import { LoadingStageModel } from 'shared/models/loadingStage';

export default class DomainsStore
  extends ComponentLoadingStore
  implements IDomainsStore
{
  private _rootStore: IRootStore;
  readonly creatingStage: LoadingStageModel = new LoadingStageModel();
  readonly urlCheckingStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingStage: LoadingStageModel = new LoadingStageModel();

  readonly url: FieldModel<string> = new FieldModel<string>('', [
    validateIsEmpty,
    validateUrl
  ]);

  readonly confirmedDomainsList: NewListModel<VerifiedConfirmedDomainModel> =
    new NewListModel<VerifiedConfirmedDomainModel>();

  readonly unconfirmedDomainsList: NewListModel<VerifiedUnconfirmedDomainModel> =
    new NewListModel<VerifiedUnconfirmedDomainModel>();

  // настройка, определяющая используем ли мы проверку и подтверждение доменов
  readonly withVerification: boolean = false;

  constructor(rootStore: IRootStore) {
    super();

    this._rootStore = rootStore;

    makeObservable<DomainsStore, '_rootStore'>(this, {
      _rootStore: observable,

      add: action.bound,
      check: action.bound,
      remove: action.bound,
      getDomainInfo: action,
      canAddDomain: computed
    });
  }

  get canBeLoaded(): boolean {
    return this._rootStore.initialized;
  }

  get loaded(): boolean {
    return this.loadingStage.successfullyLoaded;
  }

  get canAddDomain(): boolean {
    return !this.url.error && !!this.url.value;
  }

  checkUrl = async (url: string): Promise<BaseResponse<ValidatorResult>> => {
    if (this.urlCheckingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.urlCheckingStage.loading();

    const response = await this._rootStore.networkStore.api<
      {},
      VerifiedDomainsError
    >(apiUrls.VERIFIED_DOMAINS_CHECK_URL, {
      method: 'POST',
      data: {
        url
      },
      errorsMap: mapVerifiedDomainsErrorToMessage,
      showExpectedError: false
    });

    if (response.isError) {
      this.urlCheckingStage.error();

      return {
        isError: false,
        data: mapErrorCodeToMessage({
          errorCode: response.data?.code || null,
          map: mapVerifiedDomainsErrorToMessage,
          defaultMessage: (t) =>
            t('verifiedDomains.errors.default', {
              ns: 'entities'
            })
        })
      };
    }

    this.urlCheckingStage.success();

    return {
      isError: true
    };
  };

  getDomainInfo = (id: string): VerifiedDomainsBaseResponse => {
    const itemConfirmed = this.confirmedDomainsList.getEntity(id);

    if (itemConfirmed) {
      return {
        isError: false,
        data: {
          item: itemConfirmed,
          type: ConfirmationType.confirmed
        }
      };
    }

    const itemUnconfirmed = this.unconfirmedDomainsList.getEntity(id);

    if (itemUnconfirmed) {
      return {
        isError: false,
        data: {
          item: itemUnconfirmed,
          type: ConfirmationType.unconfirmed
        }
      };
    }

    return {
      isError: true
    };
  };

  async add(): Promise<ConfirmationType | null> {
    if (this.creatingStage.isLoading) {
      return null;
    }

    this.creatingStage.loading();

    const response = await this._rootStore.networkStore.api<
      DomainServer,
      VerifiedDomainsError
    >(apiUrls.VERIFIED_DOMAINS_ADD, {
      method: 'POST',
      data: {
        domain: this.url.value
      },
      errorsMap: mapVerifiedDomainsErrorToMessage
    });

    if (!response.isError) {
      let type: ConfirmationType | null = null;

      runInAction(() => {
        this.creatingStage.success();
        if (response.data.verified_by) {
          const entity = VerifiedConfirmedDomainModel.fromJson(
            response.data,
            this._rootStore
          );

          this.confirmedDomainsList.addEntity({
            entity: entity,
            key: entity.id,
            start: true
          });

          type = ConfirmationType.confirmed;
        } else {
          const entity = VerifiedUnconfirmedDomainModel.fromJson(
            response.data,
            this._rootStore
          );
          entity.create();

          this.unconfirmedDomainsList.addEntity({
            entity: entity,
            key: entity.id,
            start: true
          });
          type = ConfirmationType.unconfirmed;
        }
        this._rootStore.appNotificationsStore.open({
          type: AppNotificationType.success,
          title: (t) =>
            t('DomainsStore.notifications.added', {
              ns: 'stores'
            })
        });
        this.url.changeValue('');
      });
      return type;
    } else {
      runInAction(() => {
        this.creatingStage.error();
      });

      return null;
    }
  }

  async load(): Promise<BaseResponse> {
    if (this.loadingStage.isLoading) {
      return { isError: true };
    }

    this.loadingStage.loading();

    const response = await this._rootStore.networkStore.api<DomainsListServer>(
      apiUrls.VERIFIED_DOMAINS_LIST
    );

    if (!response.isError) {
      this.unconfirmedDomainsList.reset();
      this.confirmedDomainsList.reset();

      runInAction(() => {
        response.data.items.forEach((item) => {
          item.verified_by
            ? this.confirmedDomainsList.addEntity({
                entity: VerifiedConfirmedDomainModel.fromJson(
                  item,
                  this._rootStore
                ),
                key: item._id
              })
            : this.unconfirmedDomainsList.addEntity({
                entity: VerifiedUnconfirmedDomainModel.fromJson(
                  item,
                  this._rootStore
                ),
                key: item._id
              });
        });

        this.loadingStage.success();
      });
    } else {
      runInAction(() => {
        this.loadingStage.error();
      });
    }

    return { isError: response.isError };
  }

  async check(unconfirmedItemId: string): Promise<void> {
    const unconfirmedItem =
      this.unconfirmedDomainsList.getEntity(unconfirmedItemId);

    if (!unconfirmedItem) {
      return;
    }

    const response = await unconfirmedItem.check();

    if (!response.isError && response.data.verified_by) {
      runInAction(() => {
        this.confirmedDomainsList.addEntity({
          entity: VerifiedConfirmedDomainModel.fromJson(
            response.data,
            this._rootStore
          ),
          key: response.data._id,
          start: true
        });

        this.unconfirmedDomainsList.removeEntity(unconfirmedItemId);
      });
    }
  }

  async remove(id: string): Promise<BaseResponse> {
    const itemInfo = this.getDomainInfo(id);

    if (itemInfo.isError) {
      return {
        isError: true
      };
    }

    const response = await itemInfo.data.item.remove();

    if (response.isError) {
      return response;
    }

    runInAction(() => {
      if (itemInfo.data.type === ConfirmationType.confirmed) {
        this.confirmedDomainsList.removeEntity(id);
      } else if (itemInfo.data.type === ConfirmationType.unconfirmed) {
        this.unconfirmedDomainsList.removeEntity(id);
      }
    });

    return response;
  }

  reset(): void {
    this.url.changeValue('');
    this.creatingStage.reset();
    this.urlCheckingStage.reset();
    this.loadingStage.reset();
    this.confirmedDomainsList.reset();
    this.unconfirmedDomainsList.reset();
  }
}
