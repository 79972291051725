import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { apiUrls, shopbackApiUrls } from 'shared/entities/domain';
import { localStorageHandler } from 'stores/localStorageHandler';
import { createShopbackTokenKey } from 'shared/entities/localStorage';
import { IShopbackStore } from 'shared/entities/store/shopbackStore';
import {
  mapCreatingProjectErrorToMessage,
  ShopbackProjectServer
} from 'shared/entities/shopback';

import { ComponentLoadingStore } from '../../componentLoadingStore';

export class ShopbackStore
  extends ComponentLoadingStore
  implements IShopbackStore
{
  readonly rootStore: IRootStore;
  readonly shopbackAuthorizeStage: LoadingStageModel = new LoadingStageModel();

  constructor(rootStore: IRootStore) {
    super();

    this.rootStore = rootStore;
  }

  get canBeLoaded(): boolean {
    return this.rootStore.initialized;
  }

  get loaded(): boolean {
    return this.shopbackAuthorizeStage.successfullyLoaded;
  }

  get shopbackTokenFromLS(): string | null {
    if (!this.rootStore.projectId) {
      return null;
    }

    return localStorageHandler.getItem(
      createShopbackTokenKey(this.rootStore.projectId)
    );
  }

  loadProject = async (
    shopId: number
  ): Promise<BaseResponse<ShopbackProjectServer>> => {
    const response =
      await this.rootStore.networkStore.apiShopback<ShopbackProjectServer>(
        shopbackApiUrls.PROJECT_GET,
        {
          data: {
            shopId
          },
          errorsMap: mapCreatingProjectErrorToMessage
        }
      );

    if (response.isError) {
      return {
        isError: true
      };
    } else {
      return {
        isError: false,
        data: response.data
      };
    }
  };

  async load(): Promise<BaseResponse> {
    if (this.shopbackAuthorizeStage.isLoading || !this.rootStore.projectId) {
      return {
        isError: true
      };
    }

    this.shopbackAuthorizeStage.loading();

    if (this.shopbackTokenFromLS) {
      this.shopbackAuthorizeStage.success();

      return {
        isError: false
      };
    }

    const response = await this.rootStore.networkStore.api<{
      auth_token: string;
    }>(apiUrls.SHOPBACK_OBTAIN_TOKEN);

    if (!response.isError) {
      this.shopbackAuthorizeStage.success();
      ShopbackStore.writeShopbackTokenToLS(
        response.data.auth_token,
        this.rootStore.projectId
      );
    } else {
      this.shopbackAuthorizeStage.error();
    }

    return {
      isError: response.isError
    };
  }

  async reload(): Promise<BaseResponse> {
    if (!this.rootStore.projectId) {
      return {
        isError: true
      };
    }

    localStorageHandler.remove(
      createShopbackTokenKey(this.rootStore.projectId)
    );

    return this.load();
  }

  reset(): void {
    this.shopbackAuthorizeStage.reset();
  }

  private static writeShopbackTokenToLS(
    token: string,
    projectId: string
  ): void {
    localStorageHandler.setItem(createShopbackTokenKey(projectId), token);
  }
}
