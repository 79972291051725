import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const WarningFilledCircledIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      iconSize={IconSize.S}
      svgProps={{
        viewBox: '0 0 16 16'
      }}
      {...props}
    >
      <circle cx="8" cy="8" r="8" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3C8.55228 3 9 3.23923 9 3.53434V9.46566C9 9.76077 8.55228 10 8 10C7.44772 10 7 9.76077 7 9.46566V3.53434C7 3.23923 7.44772 3 8 3Z"
        fill="white"
      />
      <path
        d="M8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z"
        fill="white"
      />
    </BaseIcon>
  );
};

export default WarningFilledCircledIcon;
