// eslint-disable-next-line import/no-duplicates
import { isSameDay, format, parse, isValid } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru, enUS } from 'date-fns/locale';
import { format as timezoneFormat, utcToZonedTime } from 'date-fns-tz';
import i18n from 'i18next';

import { DateFormat } from '../client';

// TODO переделать на более надежный способ
const getLocale = () => (i18n.language === 'ru' ? ru : enUS);

export const transformDate = (
  date: Date,
  dateFormat: DateFormat = DateFormat.normal,
  withTodayTransform = false,
  timezone?: string
): string => {
  const isSame = isSameDay(date, Date.now());

  if (isSame && withTodayTransform) {
    return 'Сегодня';
  }

  if (timezone) {
    return timezoneFormat(utcToZonedTime(date, timezone), dateFormat, {
      locale: getLocale(),
      timeZone: timezone
    });
  }

  return format(date, dateFormat, { locale: getLocale() });
};

export const transformTime = (date: Date): string => {
  return format(date, DateFormat.timeWithSec, { locale: getLocale() });
};

export const transformTimeShort = (date: Date): string => {
  return format(date, DateFormat.time, { locale: getLocale() });
};

export const getCurTime = (): string => {
  return transformTime(new Date(Date.now()));
};

export const parseDate = (
  dateString: string,
  dateFormat: DateFormat
): Date | null => {
  const date = parse(dateString, dateFormat, new Date());

  return isValid(date) ? date : null;
};
