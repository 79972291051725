import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const HamburgerIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <rect x="4" y="6" width="16" height="1.33333" fill="currentColor" />
      <rect x="4" y="11.3333" width="16" height="1.33333" fill="currentColor" />
      <rect x="4" y="16.6667" width="16" height="1.33333" fill="currentColor" />
    </BaseIcon>
  );
};

export default HamburgerIcon;
