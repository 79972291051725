import cn from 'classnames';
import * as React from 'react';

import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';
import BaseIcon from 'shared/newComponents/icons/BaseIcon';

import './TransitionArrowIcon.modules.scss';

type Props = CommonIconProps & {
  direction?: 'left' | 'right' | 'top' | 'bottom';
};

export const TransitionArrowIcon: React.FC<Props> = ({
  direction = 'right',
  ...rest
}: Props) => {
  return (
    <BaseIcon
      iconSize={IconSize.S}
      svgProps={{
        viewBox: '0 0 16 16'
      }}
      {...rest}
      styleName={cn('arrow-icon', `arrow-icon_${direction}`)}
    >
      <path
        d="M12.6666 8.62669H3.33325M3.33325 8.62669L7.99992 13.2934M3.33325 8.62669L7.99992 3.96002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export const TransitionRightArrowIcon: React.FC<CommonIconProps> = (props) => (
  <TransitionArrowIcon {...props} direction="right" />
);

export const TransitionLeftArrowIcon: React.FC<CommonIconProps> = (props) => (
  <TransitionArrowIcon {...props} direction="left" />
);
