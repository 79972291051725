const regExp = /[-]{0,1}[\d]*/g;

// определяет также и отрицательные числа
// "12345px" => 12345: number
const getPxFromString = (str: string): null | number => {
  const result = str.match(regExp);
  if (!result) {
    return null;
  }

  // @ts-ignore
  return Number(result[0]);
};

export default getPxFromString;
