import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import {
  CommonIconProps,
  IconSize,
  LogoIconProps
} from 'shared/entities/components/Icon';

const TelegramIcon: React.FC<LogoIconProps> = ({ color, ...rest }) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{ viewBox: '0 0 32 32' }}
      {...rest}
    >
      <g clipPath="url(#clip0_1435_95687)">
        <path
          d="M16 32C24.8365 32 32 24.8365 32 16C32 7.16346 24.8365 0 16 0C7.16346 0 0 7.16346 0 16C0 24.8365 7.16346 32 16 32Z"
          fill={color === 'original' ? '#34AADF' : 'currentColor'}
        />
        <path
          d="M6.65876 15.8612C6.65876 15.8612 14.6588 12.578 17.4333 11.422C18.4969 10.9596 22.1038 9.47974 22.1038 9.47974C22.1038 9.47974 23.7686 8.83238 23.6298 10.4046C23.5836 11.052 23.2136 13.3179 22.8437 15.7688C22.2888 19.237 21.6876 23.0289 21.6876 23.0289C21.6876 23.0289 21.5951 24.0925 20.809 24.2774C20.0229 24.4624 18.7281 23.6301 18.4969 23.4451C18.3119 23.3064 15.0287 21.2254 13.8264 20.2081C13.5027 19.9306 13.1327 19.3757 13.8726 18.7283C15.5374 17.2023 17.5258 15.3064 18.7281 14.1041C19.283 13.5491 19.8379 12.2543 17.5258 13.8266C14.2426 16.0925 11.0056 18.2196 11.0056 18.2196C11.0056 18.2196 10.2657 18.682 8.87841 18.2659C7.49108 17.8497 5.87259 17.2948 5.87259 17.2948C5.87259 17.2948 4.76283 16.6011 6.65876 15.8612Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1435_95687">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export const TelegramOriginalIcon: React.FC<CommonIconProps> = (props) => {
  return <TelegramIcon color="original" {...props} />;
};

export const TelegramTransparentIcon: React.FC<CommonIconProps> = (props) => {
  return <TelegramIcon color="transparent" {...props} />;
};
