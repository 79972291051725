import * as React from 'react';
import { MutableRefObject } from 'react';

import { ExternalLinkProps } from 'shared/newEntities/components/ExternalLink';
import Typography from 'shared/newComponents/Typography';

const ExternalLink = React.forwardRef(
  (
    {
      rel = 'noreferrer noopener',
      target = '_blank',
      ...rest
    }: ExternalLinkProps,
    ref: MutableRefObject<HTMLAnchorElement>
  ) => {
    return (
      <Typography
        Element="a"
        ref={ref}
        rel={rel}
        target={target}
        {...rest}
      ></Typography>
    );
  }
);

export default React.memo(ExternalLink);
