import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const SberIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M19.1201 6.50793C19.5457 7.05717 19.9004 7.65954 20.2019 8.29734L12.0088 14.3388L8.56848 12.1773V9.59068L11.9911 11.7344L19.1201 6.50793Z"
        fill="#21A038"
      />
      <path
        d="M5.07486 12.0002C5.07486 11.8762 5.07486 11.7699 5.0926 11.6459L3.01773 11.5396C3.01773 11.6813 3 11.8407 3 11.9825C3 14.4629 4.01083 16.7129 5.64234 18.3428L7.11425 16.8723C5.85515 15.6321 5.07486 13.9136 5.07486 12.0002Z"
        fill="url(#paint0_linear_5091_1527)"
      />
      <path
        d="M11.9911 5.09058C12.1152 5.09058 12.2216 5.09057 12.3457 5.10829L12.4521 3.03542C12.3103 3.03542 12.1507 3.0177 12.0088 3.0177C9.52604 3.0177 7.27385 4.02756 5.64233 5.65751L7.11424 7.12802C8.35561 5.87012 10.0935 5.09058 11.9911 5.09058Z"
        fill="url(#paint1_linear_5091_1527)"
      />
      <path
        d="M11.991 18.9096C11.8669 18.9096 11.7604 18.9096 11.6363 18.8919L11.5299 20.9648C11.6718 20.9648 11.8314 20.9825 11.9733 20.9825C14.4559 20.9825 16.7082 19.9727 18.3397 18.3427L16.8677 16.8722C15.6264 18.1478 13.9062 18.9096 11.991 18.9096Z"
        fill="url(#paint2_linear_5091_1527)"
      />
      <path
        d="M15.8927 6.29534L17.6483 5.00201C16.1055 3.76183 14.137 3 11.9912 3V5.07287C13.4454 5.09059 14.7932 5.53351 15.8927 6.29534Z"
        fill="url(#paint3_linear_5091_1527)"
      />
      <path
        d="M21 12.0002C21 11.451 20.9468 10.9195 20.8581 10.3879L18.9251 11.823C18.9251 11.8762 18.9251 11.947 18.9251 12.0002C18.9251 14.0376 18.0384 15.8625 16.6375 17.1203L18.0384 18.6617C19.8472 17.0318 21 14.64 21 12.0002Z"
        fill="#21A038"
      />
      <path
        d="M11.991 18.9096C9.95166 18.9096 8.12507 18.0238 6.86597 16.6241L5.32312 18.0238C6.97237 19.8486 9.3487 20.9825 11.991 20.9825V18.9096Z"
        fill="url(#paint4_linear_5091_1527)"
      />
      <path
        d="M7.36253 6.87988L5.96156 5.3385C4.13497 6.98617 3 9.36024 3 12.0001H5.07486C5.07486 9.98032 5.96156 8.13778 7.36253 6.87988Z"
        fill="url(#paint5_linear_5091_1527)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5091_1527"
          x1="5.42093"
          y1="18.0906"
          x2="3.33475"
          y2="11.9617"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1444" stopColor="#F2E913" />
          <stop offset="0.3037" stopColor="#E7E518" />
          <stop offset="0.5823" stopColor="#CADB26" />
          <stop offset="0.891" stopColor="#A3CD39" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5091_1527"
          x1="6.23377"
          y1="5.42361"
          x2="11.6002"
          y2="3.3451"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0592" stopColor="#0FA8E0" />
          <stop offset="0.5385" stopColor="#0099F9" />
          <stop offset="0.9234" stopColor="#0291EB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5091_1527"
          x1="11.4081"
          y1="19.2196"
          x2="17.8144"
          y2="17.7481"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1226" stopColor="#A3CD39" />
          <stop offset="0.2846" stopColor="#86C339" />
          <stop offset="0.8693" stopColor="#21A038" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5091_1527"
          x1="11.5649"
          y1="4.32027"
          x2="16.8878"
          y2="5.98949"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0566" stopColor="#0291EB" />
          <stop offset="0.79" stopColor="#0C8ACB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5091_1527"
          x1="5.85068"
          y1="18.3522"
          x2="11.5645"
          y2="20.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1324" stopColor="#F2E913" />
          <stop offset="0.2977" stopColor="#EBE716" />
          <stop offset="0.5306" stopColor="#D9E01F" />
          <stop offset="0.8023" stopColor="#BBD62D" />
          <stop offset="0.9829" stopColor="#A3CD39" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5091_1527"
          x1="4.5898"
          y1="12.2504"
          x2="6.84599"
          y2="6.28522"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0698" stopColor="#A3CD39" />
          <stop offset="0.2599" stopColor="#81C55F" />
          <stop offset="0.9216" stopColor="#0FA8E0" />
        </linearGradient>
      </defs>
    </BaseIcon>
  );
};

export default SberIcon;
