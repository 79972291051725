import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const GlobalPayIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C17.5228 2 22 6.47719 22 12C22 17.0935 18.1918 21.2976 13.2673 21.9204L2.00295 12.2344C2.00117 12.1564 2 12.0784 2 12C2 6.47719 6.47713 2 12 2Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default GlobalPayIcon;
