import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import { apiUrls } from 'shared/entities/domain';
import { IProjectsStore } from 'shared/entities/store/projectsStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStage } from 'shared/entities/meta';
import { ProjectServer } from 'shared/entities/projects';
import ListModel from 'shared/models/ListModel';
import { ProjectEditModel, ProjectModel } from 'shared/models/project';

export default class ProjectsStore implements IProjectsStore {
  rootStore: IRootStore;

  projects: ListModel<ProjectModel> = new ListModel<ProjectModel>();

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      projects: observable,

      hasProjects: computed,
      loadingStage: computed,

      load: action,
      create: action
    });
    this.rootStore = rootStore;
  }

  get loadingStage(): LoadingStage {
    return this.projects.loadingStage;
  }

  get creatingStage(): LoadingStage {
    return this.projects.creatingStage;
  }

  get hasProjects(): boolean {
    return (
      !!this.projects.keys.length &&
      this.projects.loadingStage === LoadingStage.SUCCESS
    );
  }

  async load(
    { initial }: { initial: boolean } = { initial: false }
  ): Promise<BaseResponse> {
    if (this.projects.loadingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }
    this.projects.setLoadingStage(LoadingStage.LOADING);
    this.projects.setIsInitialLoad(initial);

    const response = await this.rootStore.networkStore.api<{
      projects: ProjectServer[];
    }>(apiUrls.PROJECTS_LIST);

    if (!response.isError) {
      const { entities, keys } = response.data.projects.reduce(
        (acc, project) => ({
          entities: {
            ...acc.entities,
            [project._id]: ProjectModel.fromJson(project, this.rootStore)
          },
          keys: [...acc.keys, project._id]
        }),
        { entities: {}, keys: [] }
      );

      runInAction(() => {
        this.projects.addEntities({ entities, keys, initial: true });
        this.projects.setLoadingStage(LoadingStage.SUCCESS);
      });
    } else {
      runInAction(() => {
        this.projects.setLoadingStage(LoadingStage.ERROR);
      });
    }

    this.projects.setIsInitialLoad(false);

    return { isError: response.isError };
  }

  async create({
    projectEditModel
  }: {
    projectEditModel: ProjectEditModel;
  }): Promise<BaseResponse<ProjectModel>> {
    if (this.projects.creatingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this.projects.setCreatingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      project: ProjectServer;
    }>(apiUrls.PROJECTS_CREATE, {
      method: 'POST',
      data: projectEditModel.toJson()
    });

    if (!response.isError) {
      const newProject = ProjectModel.fromJson(
        response.data.project,
        this.rootStore
      );

      runInAction(() => {
        this.projects.addEntity({ entity: newProject, key: newProject.id });
        this.projects.setCreatingStage(LoadingStage.SUCCESS);
      });

      return {
        isError: false,
        data: newProject
      };
    } else {
      runInAction(() => {
        this.projects.setCreatingStage(LoadingStage.ERROR);
      });

      return {
        isError: true
      };
    }
  }
}
