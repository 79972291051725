import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const VisaIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M8.44531 15.4376L9.51946 9.11328H11.2288L10.1605 15.4376H8.44531Z"
        fill="#004686"
      />
      <path
        d="M16.3615 9.26764C16.0208 9.13964 15.4895 9 14.8253 9C13.1333 9 11.9436 9.85527 11.9321 11.0887C11.9205 11.9964 12.781 12.5025 13.4336 12.8051C14.0977 13.1135 14.3229 13.3113 14.3229 13.5905C14.3171 14.0153 13.7916 14.2073 13.3008 14.2073C12.6135 14.2073 12.2497 14.1142 11.6895 13.8756L11.4701 13.7767L11.2275 15.1905C11.626 15.3651 12.3652 15.5222 13.1333 15.528C14.9293 15.528 16.1016 14.6785 16.1132 13.3695C16.1189 12.648 15.6627 12.1011 14.6752 11.6531C14.0746 11.3622 13.7108 11.1644 13.7165 10.8676C13.7165 10.6058 14.0284 10.3207 14.6983 10.3207C15.2585 10.3091 15.6627 10.4371 15.9803 10.5651L16.1363 10.6407L16.3615 9.26764Z"
        fill="#004686"
      />
      <path
        d="M20.7498 9.11719H19.4273C19.0173 9.11719 18.7112 9.22773 18.5322 9.64082L15.9912 15.4357H17.7872C17.7872 15.4357 18.0817 14.6561 18.1453 14.4874C18.3416 14.4874 20.0857 14.4874 20.334 14.4874C20.386 14.7085 20.5419 15.4357 20.5419 15.4357H22.13L20.7498 9.11719ZM18.6419 13.1957C18.7863 12.8292 19.3234 11.427 19.3234 11.427C19.3118 11.4445 19.462 11.0605 19.5486 10.8219L19.6641 11.3688C19.6641 11.3688 19.9933 12.8816 20.0626 13.1957H18.6419Z"
        fill="#004686"
      />
      <path
        d="M7.01154 9.11719L5.3368 13.4285L5.15777 12.5499C4.84593 11.5376 3.87573 10.4437 2.79004 9.89682L4.3204 15.4299H6.13374L8.83065 9.11719H7.01154Z"
        fill="#004686"
      />
      <path
        d="M3.78353 9.11328H1.0231L1 9.2471C3.14829 9.77074 4.56893 11.0333 5.15797 12.5576L4.55738 9.64855C4.45343 9.2471 4.15313 9.12492 3.78353 9.11328Z"
        fill="#EF9B11"
      />
    </BaseIcon>
  );
};

export default VisaIcon;
