import * as React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import Typography from 'shared/newComponents/Typography';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { MobileChannelsModalTab } from 'shared/entities/channels';

import './Tab.modules.scss';

type Props = {
  tab: MobileChannelsModalTab;
  className?: string;
};

export const Tab: React.FC<Props> = ({ tab, className }: Props) => {
  const { store } = useChannelsModalStore();

  const selectTab = React.useCallback(() => {
    store.channelsModalSelectedMobileTab.changeValue(tab);
    store.unselect();
  }, []);

  return (
    <Typography
      styleName={cn(
        'tab',
        store.channelsModalSelectedMobileTab.value === tab && 'tab_active'
      )}
      className={className}
      fontType={TypographyType.text}
      fontSize={TypographySize.s}
      fontWeight={TypographyWeight.semiBold}
      fontColor={TypographyColor.secondary}
      onClick={selectTab}
    >
      {(t) =>
        t(`ChannelsModal.Tabs.${tab}`, {
          ns: 'newContainers'
        })
      }
    </Typography>
  );
};

export default observer(Tab);
