import {
  getDay,
  addDays,
  setDate,
  getMonth,
  isSameDay,
  isBefore
} from 'date-fns';

import { DatePositionInInterval } from 'shared/newEntities/components/DatePicker';

/**
 * Получение первых дней недель месяца
 * @params monthDate
 * @return date[]
 */
export const getMonthView = (anyDateOfMonth: Date): Date[] => {
  // первый день месяца
  const firstDayOfMonth = setDate(anyDateOfMonth, 1);
  // день недели первого дня месяца (0|1|2|3|4|5|6), где 0 - воскресенье
  const weekDayOfFirstDayOfMonthUnNormalized = getDay(firstDayOfMonth);

  // день недели первого дня месяца (0|1|2|3|4|5|6), где 0 - понедельник
  const weekDayOfFirstDayOfMonth =
    weekDayOfFirstDayOfMonthUnNormalized === 0
      ? 6
      : weekDayOfFirstDayOfMonthUnNormalized - 1;
  const distance = 0 - weekDayOfFirstDayOfMonth;

  // первый день первой недели месячного календаря
  const firstWeekDayOfFirstWeek = addDays(firstDayOfMonth, distance);
  const weeks = [firstWeekDayOfFirstWeek];
  for (let i = 0; i < 5; i++) {
    weeks.push(addDays(weeks[weeks.length - 1], 7));
  }
  return weeks;
};

export const areMonthsEqual = (...days: Date[]): boolean => {
  return days.every((day) => getMonth(day) === getMonth(days[0]));
};

/**
 * Обнуление времени даты 00:00:00:00
 * @param date
 * @returns {Date}
 */
export const resetDateTime = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate());
  newDate.setMonth(date.getMonth());
  newDate.setFullYear(date.getFullYear());
  newDate.setHours(0, 0, 0, 0);

  return newDate;
};

/**
 * Формирование интервала
 * @param date1
 * @param date2
 * @returns {[Date, Date]}
 */
export const validateIntervalAndReshuffle = (
  date1: Date,
  date2: Date
): [Date, Date] => {
  return isBefore(date1, date2) ? [date1, date2] : [date2, date1];
};

export const isDateInInterval = ({
  date,
  interval
}: {
  date: Date;
  interval: [Date, Date];
}): boolean => {
  return date >= interval[0] && date <= interval[1];
};

export const getPositionOfDateInInterval = ({
  date,
  interval
}: {
  date: Date;
  interval: [Date, Date];
}): DatePositionInInterval => {
  if (!isDateInInterval({ date, interval })) {
    return null;
  }

  if (isSameDay(date, interval[0])) {
    if (isSameDay(date, interval[1])) {
      return 'all-interval';
    }

    return 'start';
  }

  if (isSameDay(date, interval[1])) {
    return 'end';
  }

  return 'middle';
};
