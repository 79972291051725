import * as React from 'react';

import { FunnelIcon } from 'shared/components/icons';
import { TranslationString } from 'shared/entities/localization';
import { BaseResponseCode } from 'shared/entities/network';

import { TemplateCategory } from '../templates';
import { Color } from '../color';
import { CommonIconProps } from '../components/Icon';
import { BlockKind, BlockSection } from '../block/common';
import { IGraphSettings } from '../graph';
import { ReactionKind } from '../reaction';
import { MailingServer } from '../mailing';

export enum ScenarioPreset {
  shopback = 'shopback_template',
  smartbotAI = 'smartbot_ai'
}

export enum ScenarioTag {}

export enum ScenarioKind {
  common = 'common',
  reaction = 'reaction',
  mailing = 'mailing'
}

export type ScenarioBaseServer = {
  _id: string;
  name: string;
};

export type ScenarioBaseParamsServer = ScenarioBaseServer & {
  update_id: string;
  date_updated: string;
  date_created: string;
  kind: ScenarioKind;
  channel_ids: string[] | null;
  has_been_published: boolean;
  has_changes: boolean;
  enabled_in: {
    dev: true;
    prod: true;
  };
  preset?: ScenarioPreset;
  tags?: ScenarioTag[];
};

export type ScenarioTemplateServer = {
  categories?: TemplateCategory[];
  visible: boolean;
  description?: string;
  use_count?: number;
};

export type ScenarioMailingServer = ScenarioBaseParamsServer & {
  kind: ScenarioKind.mailing;
  mailing: MailingServer;
};

export type ScenarioCommonServer = ScenarioBaseParamsServer & {
  kind: ScenarioKind.common;
  template?: ScenarioTemplateServer | null;
};

export type ScenarioReactionServer = ScenarioBaseParamsServer & {
  kind: ScenarioKind.reaction;
  reaction: ReactionKind;
};

export const mapReactionKindToEntity: Record<
  ReactionKind,
  {
    name: TranslationString;
    Icon: React.FC<CommonIconProps>;
    color: Color;
  }
> = {
  [ReactionKind.preFilter]: {
    name: (t) =>
      t('scenario.reaction.preFilter', {
        ns: 'entities'
      }),
    Icon: FunnelIcon,
    color: Color.COLOR_PINK_3
  }
};

export const reactionsOrder: ReactionKind[] = [ReactionKind.preFilter];

export const mapReactionKindToGraphSettings: Record<
  ReactionKind,
  IGraphSettings
> = {
  [ReactionKind.preFilter]: {
    excludeBlockKind: [],
    excludeBlockSection: [BlockSection.inputs],
    requiredBlocks: [BlockKind.preFilter]
  }
};

export const commonScenarioGraphSettings: IGraphSettings = {
  excludeBlockKind: [BlockKind.preFilterBreak, BlockKind.preFilterContinue],
  excludeBlockSection: [],
  requiredBlocks: [BlockKind.firstMessage]
};

export const mailingScenarioGraphSettings: IGraphSettings = {
  excludeBlockKind: [BlockKind.preFilterBreak, BlockKind.preFilterContinue],
  excludeBlockSection: [BlockSection.inputs],
  requiredBlocks: [BlockKind.mailingSend]
};

export enum ScenarioUpdateError {
  CAN_NOT_MAKE_AS_A_TEMPLATE = 'can_not_make_as_a_template'
}

export const mapScenarioUpdateErrorToMessage =
  (code: ScenarioUpdateError): TranslationString =>
  (t) =>
    t(`scenario.errors.updating.${code}`, {
      ns: 'entities'
    });

export enum ScenarioTemplateError {
  TEMPLATE_WAS_NOT_FOUND = 'template_was_not_found'
}

export const mapTemplateApplyingErrorToMessage =
  (code: ScenarioTemplateError): TranslationString =>
  (t) =>
    t(`scenario.errors.applying.${code}`, {
      ns: 'entities'
    });

export enum GettingScenarioError {
  scenarioNotFound = 'scenario_not_found'
}

export const mapBaseErrorCodeToScenarioErrorToMessage =
  (
    code: GettingScenarioError | BaseResponseCode.forbidden
  ): TranslationString =>
  (t) =>
    t(`scenario.errors.getting.${code}`, {
      ns: 'entities'
    });

export const enum ScenariosEventType {
  SCENARIO_IS_NO_LONGER_A_TEMPLATE = 'SCENARIO_IS_NO_LONGER_A_TEMPLATE'
}
export type ScenarioIsNoLongerTemplateDataType = {
  id: string;
};

export type ScenarioDataServer = ScenarioBaseServer & {
  block_id: string;
};
