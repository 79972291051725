import {
  ActAndSFStatus,
  ICommonDocument,
  PaymentDocumentKind,
  PaymentDocumentSubject,
  SetupSFDocumentServer,
  normalizeCommonDocument
} from 'shared/entities/documents';

import { BaseDocumentModel } from './BaseDocumentModel';
import { SetupDocumentParamsTableModel } from './SetupDocumentParamsTableModel';

export class SetupSFDocumentModel extends BaseDocumentModel {
  readonly status: ActAndSFStatus;
  readonly kind: PaymentDocumentKind.sf = PaymentDocumentKind.sf;
  readonly subject: PaymentDocumentSubject.setup = PaymentDocumentSubject.setup;
  readonly params: SetupDocumentParamsTableModel;

  constructor({
    params,
    status,
    ...rest
  }: ICommonDocument & {
    params: SetupDocumentParamsTableModel;
    status: ActAndSFStatus;
    cabinetId: string;
  }) {
    super(rest);
    this.params = params;
    this.status = status;
  }

  static fromJson(
    raw: SetupSFDocumentServer,
    cabinetId: string
  ): SetupSFDocumentModel {
    return new SetupSFDocumentModel({
      ...normalizeCommonDocument(raw),
      params: SetupDocumentParamsTableModel.fromJson(raw.params),
      status: raw.status,
      cabinetId
    });
  }
}
