import { useRef } from 'react';

const useLocal = <T>(initialValueCreator: () => T): T => {
  const ref = useRef<T | null>(null);
  if (!ref.current) {
    ref.current = initialValueCreator();
  }

  return ref.current;
};

export default useLocal;
