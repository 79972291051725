import * as React from 'react';
import cn from 'classnames';

import {
  TypographySize,
  TypographyType,
  TypographyColor,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import { TranslationString } from 'shared/entities/localization';

import Typography from '../Typography';

import './Switch.modules.scss';

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  title?: TranslationString;
  className?: string;
};

const Switch: React.FC<Props> = ({
  value,
  onChange,
  disabled,
  title,
  className
}) => {
  const handleChange = React.useCallback(() => {
    onChange(!value);
  }, [value, onChange]);

  return (
    <div styleName="switch-container" className={className}>
      <div
        tabIndex={0}
        onClick={handleChange}
        styleName={cn(
          'switch',
          value && 'switch_active',
          disabled && 'switch_disabled'
        )}
      >
        <div styleName="switch__inner" />
      </div>
      <Typography
        styleName="switch__title"
        fontType={TypographyType.text}
        fontSize={TypographySize.s}
        fontWeight={TypographyWeight.heavy}
        fontColor={TypographyColor.secondary}
      >
        {title}
      </Typography>
    </div>
  );
};

export default Switch;
