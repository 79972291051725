import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const SwitchUsersIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M11.36 18.9597L9.27997 17.5997C8.84001 17.3198 8.32006 17.6396 8.32006 18.1197V18.1598C6.07999 18.0797 4.60008 16.1996 4.28005 14.7998C4.12003 14.2397 3.36005 14.3997 3.36005 14.9197C3.40013 17.3198 5.12007 20.0398 8.3598 20.7598V20.8799C8.3598 21.3999 8.91987 21.6798 9.35983 21.3999L11.4399 20.0399C11.7198 19.7999 11.7198 19.2398 11.3599 18.9599L11.36 18.9597ZM9.03993 20.6397V20.4397C9.03993 20.2396 8.92 20.0796 8.72006 20.0397C6.75994 19.6798 5.39997 18.5596 4.68006 17.1997C5.63997 18.2797 7.07997 18.9998 8.67999 18.9197C8.87992 18.9197 9.03994 18.7196 9.03994 18.5197V18.3597L10.7199 19.4796L9.03993 20.6397Z"
        fill="currentColor"
      />
      <path
        d="M11.1599 5.96002L13.2399 7.31999C13.4399 7.44008 13.6799 7.48001 13.88 7.36007C14.0799 7.23998 14.1999 7.04004 14.1999 6.8V6.76008C16.4399 6.84009 17.9198 8.72003 18.2399 10.1201C18.3999 10.64 19.1599 10.56 19.1599 9.99998C19.2 7.68006 17.5999 4.91999 14.2399 4.15983V4.0399C14.2399 3.51978 13.6799 3.23983 13.28 3.51978L11.2 4.87976C10.76 5.15987 10.7999 5.71978 11.1599 5.95978V5.96002ZM13.4398 4.32009V4.48011C13.4398 4.68005 13.5599 4.84007 13.7599 4.87999C15.7999 5.24011 17.1598 6.39998 17.8398 7.76012C16.92 6.72 15.5999 6.00009 14.0799 6.00009H13.8C13.5999 6.00009 13.4398 6.20003 13.4398 6.39997V6.55999L11.76 5.44002L13.4398 4.32009Z"
        fill="currentColor"
      />
      <path
        d="M6.72011 6.72001C8.0001 6.72001 9.08012 5.68007 9.08012 4.36001C9.08012 3.08002 8.04001 2 6.72011 2C5.44013 2 4.36011 3.04011 4.36011 4.36001C4.36011 5.68007 5.40005 6.72001 6.72011 6.72001ZM6.72011 2.80011C7.60004 2.80011 8.32013 3.52003 8.32013 4.40013C8.32013 5.28005 7.60004 6.00014 6.72011 6.00014C5.84002 6.00014 5.1201 5.28005 5.1201 4.40013C5.1201 3.48012 5.84002 2.80011 6.72011 2.80011V2.80011Z"
        fill="currentColor"
      />
      <path
        d="M11.0399 12.68C11.2399 12.68 11.44 12.52 11.44 12.2799V10.5199C11.44 8.55996 9.83996 6.95994 7.83993 6.95994C7.67991 6.95994 7.55999 7.03995 7.47998 7.15988L6.71999 8.67987L5.95999 7.15988C5.87999 7.03995 5.76005 6.95994 5.60004 6.95994C3.60001 6.92002 2 8.55996 2 10.5199V12.2799C2 12.4799 2.16002 12.68 2.40004 12.68H11.0399ZM2.76008 10.5199C2.76008 9.04003 3.88005 7.8399 5.36009 7.71996L6.36012 9.67991C6.48005 9.91994 6.92002 9.91994 7.04013 9.67991L8.03999 7.71996C9.48016 7.83989 10.64 9.04003 10.64 10.5199V11.8799H9.88001L9.68007 10.4399C9.63998 10.24 9.44004 10.08 9.2401 10.1199C9.04 10.16 8.87998 10.3599 8.92007 10.56L9.08009 11.8799H4.28009L4.48003 10.56C4.52012 10.3599 4.3601 10.16 4.16016 10.1199C3.96006 10.08 3.76012 10.24 3.72003 10.4399L3.52009 11.8799H2.7601L2.76008 10.5199Z"
        fill="currentColor"
      />
      <path
        d="M17.28 10.72C16 10.72 14.92 11.7599 14.92 13.08C14.92 14.36 15.9599 15.4398 17.28 15.4398C18.56 15.4398 19.64 14.3999 19.64 13.08C19.64 11.7999 18.5999 10.72 17.28 10.72V10.72ZM17.28 14.6798C16.3999 14.6798 15.68 13.9599 15.68 13.08C15.68 12.1999 16.3999 11.48 17.28 11.48C18.1599 11.48 18.88 12.1999 18.88 13.08C18.88 13.9599 18.1599 14.6798 17.28 14.6798V14.6798Z"
        fill="currentColor"
      />
      <path
        d="M18.4 15.6802C18.24 15.6802 18.12 15.7602 18.04 15.8803L17.28 17.4003L16.52 15.8803C16.44 15.7602 16.3199 15.6802 16.1601 15.6802C14.2 15.6802 12.5999 17.2802 12.5999 19.2401V21.0002C12.5999 21.2003 12.76 21.4002 13 21.4002H21.5999C21.7999 21.4002 22 21.2402 22 21.0002V19.2401C22 17.2802 20.4 15.6802 18.4 15.6802H18.4ZM21.24 20.6001H20.48L20.2799 19.1599C20.24 18.96 20.0401 18.8 19.84 18.8401C19.64 18.88 19.48 19.0801 19.5199 19.28L19.6799 20.6001H14.8799L15.04 19.28C15.08 19.0801 14.92 18.88 14.7201 18.8401C14.52 18.8 14.3201 18.96 14.28 19.1599L14.08 20.6001H13.32V19.24C13.32 17.7601 14.44 16.5601 15.92 16.44L16.9201 18.3999C17.0001 18.52 17.12 18.6001 17.28 18.6001C17.44 18.6001 17.56 18.52 17.64 18.3999L18.64 16.44C20.08 16.5601 21.24 17.7601 21.24 19.24V20.6001Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SwitchUsersIcon;
