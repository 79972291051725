import * as React from 'react';
import cn from 'classnames';
// eslint-disable-next-line import/named
import { throttle } from 'lodash';
import ResizeObserver from 'resize-observer-polyfill';

import './FadeScroll.modules.scss';

type Props = React.PropsWithChildren<{
  withoutFade?: boolean;
  correctBoxShadow?: ('top' | 'bottom' | 'right' | 'left')[] | boolean;
  className?: string;
  id?: string;
}>;

const FadeScroll: React.FC<Props> = ({
  className,
  children,
  id,
  correctBoxShadow = true,
  withoutFade = false
}) => {
  const [showFade, setShowFade] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement | null>(null);

  const handleScroll = React.useCallback(
    throttle(() => {
      if (!ref.current) {
        return;
      }
      const element = ref.current;

      // показываем fade только, если есть скролл и не дошли до конца при скролле
      const hasScroll = element.scrollHeight > element.clientHeight;
      const isBottom =
        element.scrollTop + element.offsetHeight >= element.scrollHeight;

      setShowFade(hasScroll && !isBottom);
    }, 100),
    []
  );

  React.useEffect(() => {
    let observer: ResizeObserver | null = null;

    if (ref.current) {
      observer = new ResizeObserver(handleScroll);

      observer.observe(ref.current);
      handleScroll();
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  const actualPositions = Array.isArray(correctBoxShadow)
    ? correctBoxShadow
    : correctBoxShadow
    ? ['top', 'bottom', 'right', 'left']
    : [];
  const correctBoxShadowClassName = actualPositions.reduce((acc, pos) => {
    return acc + ` container__content_box-shadow-correct-${pos}`;
  }, '');

  return (
    <div styleName="container" className={className}>
      <div
        ref={ref}
        styleName={cn(
          'container__content',
          showFade && !withoutFade && 'container__content_fade',
          correctBoxShadow && correctBoxShadowClassName
        )}
        onScroll={handleScroll}
        id={id}
      >
        {children}
      </div>
    </div>
  );
};

export default FadeScroll;
