import * as React from 'react';
import { motion } from 'framer-motion';

import { CheckMarkIcon, CopyIcon } from 'shared/newComponents/icons';
import { IconSize } from 'shared/entities/components/Icon';
import { ComponentType } from 'shared/entities/components/Component';
import { useModalState } from 'shared/entities/common/hooks';
import { copyToClipboard } from 'shared/entities/common/utils';

import './CopyComponent.modules.scss';

type CopySignProps = {
  value: string;
  className?: string;
};

const CopyComponent: React.FC<CopySignProps> = ({
  value,
  className
}: CopySignProps) => {
  const copyingTimeout = React.useRef<ReturnType<typeof setTimeout> | null>(
    null
  );
  const {
    opened: copied,
    open: copy,
    close: cancelCopy
  } = useModalState(false);

  React.useEffect(() => {
    return () => {
      if (copyingTimeout.current) {
        clearTimeout(copyingTimeout.current);
      }
    };
  }, []);

  const handleCopy = React.useCallback(async () => {
    const response = await copyToClipboard(value);

    if (response.isError) {
      return;
    }
    copy();

    copyingTimeout.current = setTimeout(cancelCopy, 2000);
  }, [value]);

  return (
    <div styleName="copy-sign" className={className}>
      {copied && (
        <motion.div
          key={1}
          initial={{ opacity: 0, display: 'flex' }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.7
          }}
        >
          <CheckMarkIcon iconSize={IconSize.XM} />
        </motion.div>
      )}
      {!copied && (
        <motion.div
          key={2}
          initial={{ opacity: 0, display: 'flex' }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.7
          }}
        >
          <CopyIcon
            action={{
              type: ComponentType.svg,
              onClick: handleCopy
            }}
            iconSize={IconSize.XM}
          />
        </motion.div>
      )}
    </div>
  );
};

export default React.memo(CopyComponent);
