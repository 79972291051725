import * as React from 'react';
import cn from 'classnames';

import { BaseModalProps } from 'shared/newEntities/components/BaseModal';
import { Button } from 'shared/newComponents/buttons';
import {
  TranslationNode,
  TranslationString
} from 'shared/entities/localization';
import {
  ButtonColor,
  ButtonProps,
  ButtonSize
} from 'shared/newEntities/components/Button';
import { ComponentType } from 'shared/entities/components/Component';
import { useKeyboardEventsStore } from 'stores/index';
import {
  KeyboardEventListener,
  KeyboardEventType
} from 'shared/entities/keyboardEvents';
import TransComp from 'shared/components/TransComp';

import Modal from '../Modal';

import './FormModal.modules.scss';

type Props = React.PropsWithChildren<
  BaseModalProps & {
    title: TranslationString;
    ok: ButtonProps;
    withoutCancelButton?: boolean;
    onEnter?: () => void;
    className?: string;
    rightFooter?: boolean;
    footnotes?: TranslationNode;
    cancelButtonTitle?: TranslationString;
  }
>;

const FormModal: React.FC<Props> = ({
  children,
  ok,
  onEnter,
  withoutCancelButton = false,
  className,
  rightFooter,
  footnotes,
  cancelButtonTitle,
  ...rest
}) => {
  const keyboardEventsStore = useKeyboardEventsStore();

  const handleEnter = React.useCallback<KeyboardEventListener>(() => {
    onEnter?.();

    return true;
  }, [onEnter]);

  React.useEffect(() => {
    if (rest.opened) {
      keyboardEventsStore.addListener({
        type: KeyboardEventType.enter,
        listener: handleEnter
      });
    }

    return () => {
      if (rest.opened) {
        keyboardEventsStore.removeListener({
          type: KeyboardEventType.enter,
          listener: handleEnter
        });
      }
    };
  }, [handleEnter, rest.opened]);

  const buttonsOnTheRight = !withoutCancelButton && rightFooter;

  return (
    <Modal {...rest} styleName="form-modal" className={className}>
      <div styleName="form-modal__content">
        <div styleName="form-modal__content-container">{children}</div>
      </div>
      <div
        styleName={cn(
          'form-modal__footer',
          !withoutCancelButton && 'form-modal__footer_two-button',
          buttonsOnTheRight && 'form-modal__footer_two-button_right'
        )}
      >
        {footnotes && (
          <div styleName="form-modal__footer-footnotes">
            <TransComp>{footnotes}</TransComp>
          </div>
        )}
        {!withoutCancelButton && (
          <Button
            color={ButtonColor.gray}
            type={ComponentType.button}
            onClick={rest.onClose}
            size={buttonsOnTheRight ? ButtonSize.sm : ButtonSize.md}
          >
            {cancelButtonTitle
              ? cancelButtonTitle
              : (t) =>
                  t('FormModal.cancel', {
                    ns: 'newComponents'
                  })}
          </Button>
        )}
        <Button
          {...ok}
          color={ButtonColor.blue}
          fullWidth={withoutCancelButton}
          size={buttonsOnTheRight ? ButtonSize.sm : ButtonSize.md}
        >
          {ok.children ||
            ((t) =>
              t('FormModal.ok', {
                ns: 'newComponents'
              }))}
        </Button>
      </div>
    </Modal>
  );
};

export default FormModal;
