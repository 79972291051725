import { addDays } from 'date-fns';

import { getMonthView } from 'shared/entities/common/utils';
import {
  DatePickerKind,
  IDatePicker,
  IDateRangePicker
} from 'shared/newEntities/components/DatePicker';

import { DateModel, IntervalDateModel } from './DateModel';

export type DayType<T> = T extends DatePickerKind.oneDate
  ? DateModel
  : IntervalDateModel;

type DatePicker<T> = T extends DatePickerKind.oneDate
  ? IDatePicker
  : IDateRangePicker;

export class CalendarMonthModel<T extends DatePickerKind> {
  readonly calendarDays: DayType<T>[][];
  readonly monthDate: Date;
  readonly datePicker: DatePicker<T>;

  constructor({
    calendarDays,
    monthDate,
    datePicker
  }: {
    calendarDays: DayType<T>[][];
    monthDate: Date;
    datePicker: DatePicker<T>;
  }) {
    this.calendarDays = calendarDays;
    this.monthDate = monthDate;
    this.datePicker = datePicker;
  }

  /**
   * Создание CalendarMonthModel
   * @param monthDate - день месяца
   * @param datePicker
   */
  static fromDefaultParams({
    monthDate,
    datePicker
  }: {
    monthDate: Date;
    datePicker: IDateRangePicker | IDatePicker;
  }): CalendarMonthModel<typeof datePicker.kind> {
    const firstWeekDays = getMonthView(monthDate);

    const calendarDays: DayType<typeof datePicker.kind>[][] = [];

    const createDateModel = (date: Date): DayType<typeof datePicker.kind> => {
      if (datePicker.kind === DatePickerKind.oneDate) {
        return new DateModel({
          date,
          datePicker,
          monthDate
        });
      } else {
        return new IntervalDateModel({
          date,
          datePicker,
          monthDate
        });
      }
    };

    firstWeekDays.forEach((firstDateInWeek, index) => {
      const dateModel = createDateModel(firstDateInWeek);

      const weekDays = [dateModel];

      for (let i = 0; i < 6; i++) {
        const prevDateModel = weekDays[weekDays.length - 1];
        const nextDate = addDays(prevDateModel.date, 1);
        const nextDateModel = createDateModel(nextDate);
        weekDays.push(nextDateModel);
      }
      calendarDays[index] = weekDays;
    });

    return new CalendarMonthModel({
      monthDate,
      calendarDays,
      datePicker
    });
  }
}
