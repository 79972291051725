import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const ItalicIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{ viewBox: '0 0 24 24', ...(props.svgProps || {}) }}
    >
      <path
        d="M17.75 5H10.25C9.91848 5 9.60054 5.1317 9.36612 5.36612C9.1317 5.60054 9 5.91848 9 6.25C9 6.58152 9.1317 6.89946 9.36612 7.13388C9.60054 7.3683 9.91848 7.5 10.25 7.5H12.15L8.15 17.5H5.25C4.91848 17.5 4.60054 17.6317 4.36612 17.8661C4.1317 18.1005 4 18.4185 4 18.75C4 19.0815 4.1317 19.3995 4.36612 19.6339C4.60054 19.8683 4.91848 20 5.25 20H12.75C13.0815 20 13.3995 19.8683 13.6339 19.6339C13.8683 19.3995 14 19.0815 14 18.75C14 18.4185 13.8683 18.1005 13.6339 17.8661C13.3995 17.6317 13.0815 17.5 12.75 17.5H10.85L14.85 7.5H17.75C18.0815 7.5 18.3995 7.3683 18.6339 7.13388C18.8683 6.89946 19 6.58152 19 6.25C19 5.91848 18.8683 5.60054 18.6339 5.36612C18.3995 5.1317 18.0815 5 17.75 5Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ItalicIcon;
