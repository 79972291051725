import { computed, makeObservable, observable } from 'mobx';
import * as React from 'react';

import { OpenStateModel } from 'shared/models/openState';
import { LoadingStageModel } from 'shared/models/loadingStage';
import ListModel from 'shared/models/ListModel';
import { DocumentServer, IDocumentsStore } from 'shared/entities/documents';
import {
  DocumentModelType,
  ServiceActDocumentModel
} from 'shared/models/documents';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import { getDocumentModelFromRaw } from 'shared/models/documents/utils';
import { CreatingInvoiceModel } from 'shared/models/documents/creating';
import { AppNotificationType } from 'shared/entities/appNotifications';
import TypedTransComponent from 'shared/components/TypedTransComponent';

export class DocumentsStore implements IDocumentsStore {
  private _cabinetId: string | null = null;
  creatingModel: CreatingInvoiceModel | null = null;
  readonly creatingModalState: OpenStateModel = new OpenStateModel();
  readonly gettingDocumentStage: LoadingStageModel = new LoadingStageModel();
  readonly documents: ListModel<DocumentModelType> =
    new ListModel<DocumentModelType>();
  readonly rootStore: IRootStore;

  constructor({ rootStore }: { rootStore: IRootStore }) {
    this.rootStore = rootStore;
    makeObservable<DocumentsStore, '_cabinetId'>(this, {
      _cabinetId: observable,

      cabinetId: computed
    });
  }

  get cabinetId(): string | null {
    return this._cabinetId;
  }

  get serviceActDocuments(): DocumentModelType[] {
    return this.documents.items.filter(
      (doc) => doc instanceof ServiceActDocumentModel
    );
  }

  init(cabinetId: string) {
    this._cabinetId = cabinetId;
    this.getDocuments();
    this.creatingModel = new CreatingInvoiceModel({
      cabinetId,
      documentsStore: this,
      rootStore: this.rootStore
    });
  }

  async getDocuments(): Promise<BaseResponse> {
    if (this.gettingDocumentStage.isLoading) {
      return {
        isError: true
      };
    }

    this.gettingDocumentStage.loading();

    const response = await this.rootStore.networkStore.api<{
      documents: DocumentServer[];
    }>(apiUrls.PAYMENT_DOCUMENTS_LIST);

    if (response.isError) {
      this.gettingDocumentStage.error();
    } else {
      const { entities, keys } = response.data.documents.reduce<{
        entities: Record<string, DocumentModelType>;
        keys: string[];
      }>(
        (acc, raw) => {
          if (!this.cabinetId) {
            return acc;
          }

          const doc = getDocumentModelFromRaw(raw, this.cabinetId);

          if (!doc) {
            return acc;
          }
          return {
            ...acc,
            entities: {
              ...acc.entities,
              [doc.id]: doc
            },
            keys: [...acc.keys, doc.id]
          };
        },
        {
          entities: {},
          keys: []
        }
      );
      this.documents.addEntities({
        entities,
        keys,
        initial: true
      });
      this.gettingDocumentStage.success();
    }

    return {
      isError: response.isError
    };
  }

  create = async (): Promise<BaseResponse> => {
    if (!this.creatingModel || !this.cabinetId) {
      return { isError: true };
    }
    const response = await this.creatingModel.create();

    if (!response.isError) {
      this.creatingModalState.close();

      const model = getDocumentModelFromRaw(response.data, this.cabinetId);

      if (!model) {
        return response;
      }

      this.documents.addEntity({
        entity: model,
        key: model?.id,
        start: true
      });
    }

    return response;
  };

  remove = async (id: string): Promise<BaseResponse> => {
    const entity = this.documents.getEntity(id);

    if (!entity || entity.removingStage.isLoading) {
      return {
        isError: true
      };
    }

    entity.removingStage.loading();

    const response = await this.rootStore.networkStore.api(
      apiUrls.PAYMENT_DOCUMENTS_REMOVE,
      {
        method: 'POST',
        data: {
          _id: id
        }
      }
    );

    if (response.isError) {
      entity.removingStage.error();

      return {
        isError: true
      };
    }

    entity.removingStage.success();
    this.documents.removeEntity(id);

    return {
      isError: false
    };
  };

  notify = async (id: string): Promise<BaseResponse> => {
    const entity = this.documents.getEntity(id);

    if (!entity || entity.notifyingStage.isLoading) {
      return {
        isError: true
      };
    }

    entity.notifyingStage.loading();

    const response = await this.rootStore.networkStore.api(
      apiUrls.PAYMENT_DOCUMENTS_NOTIFY_MANAGERS,
      {
        method: 'POST',
        data: {
          _id: id
        }
      }
    );

    if (response.isError) {
      entity.notifyingStage.error();
    } else {
      entity.managersNotified.changeValue(true);
      entity.notifyingStage.success();
      this.rootStore.appNotificationsStore.open({
        title: (
          <TypedTransComponent i18nKey="DocumentsStore.notify" ns={'stores'}>
            Документ <b>{{ name: entity.name }}</b> успешно отправлен на почту
            менеджерам.
          </TypedTransComponent>
        ),
        type: AppNotificationType.success
      });
    }

    return response;
  };
}
