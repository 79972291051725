import { runInAction } from 'mobx';

import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import {
  AIAggregatedDocModelParams,
  AIAggregatedDocType,
  AIDocErrorCode,
  AIDocServer,
  AIDocsServer,
  AIUploadDocServerError,
  AIUploadDocServerResponse
} from 'shared/entities/smartbotAI';
import { LoadingStage } from 'shared/entities/meta';
import {
  addProtocolToUrl,
  getDomainNameFromUrl
} from 'shared/entities/common/utils';

import { OpenStateModel } from '../openState';
import ListModel from '../ListModel';
import { LoadingStageModel } from '../loadingStage';
import { FieldModel } from '../form';

import { AIAggregatedDocModel } from './AIAggregatedDocModel';
import { AICommonSiteDocModel } from './AICommonSiteDocModel';

const DOCS_LIMIT = 15;
const UPLOADING_PART_PROGRESS = 20;
const INDEXING_PART_PROGRESS = 100 - UPLOADING_PART_PROGRESS;

export class AIAggregatedSiteDocModel extends AIAggregatedDocModel<AIAggregatedDocType.site> {
  readonly docsModalState = new OpenStateModel();
  docs: ListModel<AICommonSiteDocModel> = new ListModel<AICommonSiteDocModel>();
  readonly docsLoadingStage = new LoadingStageModel();
  readonly searchValue: FieldModel<string> = new FieldModel<string>('');

  constructor(
    params: Omit<AIAggregatedDocModelParams<AIAggregatedDocType.site>, 'type'>
  ) {
    super({ ...params, type: AIAggregatedDocType.site });
  }
  //до 20 - это прогресс загрузки  сайта,  20-100 прогресс рассчитывается 20 + прогресс, приходящий из запроса poll * 0.8
  get progressView(): number {
    if (this.loadingStage.isLoading) {
      return this.loadingProgress.value;
    }
    return this.progress.value === 100
      ? this.progress.value
      : UPLOADING_PART_PROGRESS +
          Math.floor((this.progress.value * INDEXING_PART_PROGRESS) / 100);
  }

  getDocs = async (): Promise<BaseResponse> => {
    if (this.docsLoadingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.docsLoadingStage.loading();

    const response = await this.rootStore.networkStore.api<AIDocsServer>(
      apiUrls.AI_LIST_DOCS,
      {
        method: 'GET',
        data: {
          ai_bot_id: this.botId,
          agg_key: this.id.value,
          offset: this.docs.length,
          limit: DOCS_LIMIT
        }
      }
    );

    if (response.isError) {
      runInAction(() => {
        this.docsLoadingStage.error();
        this.docs.setHasMore(false);
      });
      return {
        isError: true
      };
    }

    const collection: {
      keys: string[];
      entities: Record<string, AICommonSiteDocModel>;
    } = response.data.docs.reduce(
      (
        acc: { keys: string[]; entities: Record<string, AICommonSiteDocModel> },
        doc
      ) => {
        const model = AICommonSiteDocModel.fromJson({
          raw: doc,
          botId: this.botId,
          rootStore: this.rootStore
        });
        acc.keys.push(model.renderId);
        acc.entities[model.renderId] = model;
        return acc;
      },
      {
        entities: {},
        keys: []
      }
    );

    runInAction(() => {
      this.docsLoadingStage.success();
      this.docs.addEntities({ ...collection, initial: false });
      this.docs.setHasMore(collection.keys.length === DOCS_LIMIT);
    });

    return {
      isError: false
    };
  };

  upload = async ({ url }: { url: string }): Promise<BaseResponse> => {
    if (this.loadingStage.isLoading) {
      return {
        isError: true
      };
    }
    this.loadingStage.loading();
    const urlWithProtocol = addProtocolToUrl(url, 'https');

    const response = await this.rootStore.networkStore.api<
      AIUploadDocServerResponse,
      AIDocErrorCode,
      AIUploadDocServerError
    >(apiUrls.AI_INDEX_SITE, {
      method: 'POST',
      data: {
        ai_bot_id: this.botId,
        url: urlWithProtocol,
        deep: false
      },
      config: {
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total === undefined) {
            return;
          }
          this.loadingProgress.changeValue(
            Math.floor(
              (progressEvent.loaded / progressEvent.total) *
                UPLOADING_PART_PROGRESS
            )
          );
        }
      }
    });

    if (response.isError) {
      const errors = response.data && [
        {
          // @ts-ignore
          code: response.data.data.code || response.data.code,
          text:
            // @ts-ignore
            response.data.data.text ||
            // @ts-ignore
            response.data.data.fields?.url?.join(', ') ||
            response.data.message
        }
      ];
      this.errors.changeValue(errors);
      this.loadingStage.error();
      if (response.data?.status === 500) {
        this.showNotification();
      }
      return {
        isError: true
      };
    }

    this.id.changeValue(response.data.agg_key);
    this.loadingStage.success();
    return {
      isError: false
    };
  };

  closeDocsModal = () => {
    this.docsModalState.close();
    this.docs.reset();
    this.searchValue.changeValue('');
  };

  removeDoc = async (renderId: string): Promise<BaseResponse> => {
    const doc = this.docs.getEntity(renderId);
    if (!doc) {
      return { isError: true };
    }
    const response = await doc.remove();
    if (response.isError) {
      return {
        isError: true
      };
    }

    this.docs.removeEntity(renderId);
    return { isError: false };
  };

  static fromDefaultParams({
    url,
    botId,
    rootStore
  }: {
    url: string;
    botId: string;
    rootStore: IRootStore;
  }): AIAggregatedSiteDocModel {
    const model = new AIAggregatedSiteDocModel({
      rootStore,
      id: null,
      botId,
      title: getDomainNameFromUrl(url) || url,
      url: null,
      loadingStage: LoadingStage.NOT_STARTED,
      cost: 1,
      errors: null,
      progress: 0,
      loadingProgress: 0
    });

    return model;
  }

  static fromJson({
    raw,
    botId,
    rootStore
  }: {
    raw: AIDocServer;
    botId: string;
    rootStore: IRootStore;
  }): AIAggregatedSiteDocModel {
    return new AIAggregatedSiteDocModel({
      rootStore,
      id: raw.id,
      botId,
      title: raw.id,
      url: raw.url,
      loadingStage: LoadingStage.SUCCESS,
      cost: raw.cost,
      errors: raw.errors || null,
      progress: raw.progress,
      loadingProgress: UPLOADING_PART_PROGRESS
    });
  }
}
