const isRightClick = (e: MouseEvent | TouchEvent): boolean => {
  let isRightClick = false;
  if (e instanceof MouseEvent) {
    if (e.which) {
      // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightClick = e.which === 3;
    } else if (e.button) {
      // IE, Opera
      isRightClick = e.button === 2;
    }
  } else {
    isRightClick = e.touches.length > 1;
  }

  return isRightClick;
};

export default isRightClick;
