import * as React from 'react';

import {
  PopupPosition,
  PopupProps,
  PopupRef
} from 'shared/entities/components/Popup';
import PopupContainer from 'shared/components/popups/PopupContainer';

import './Popup.modules.scss';

type Props = Omit<PopupProps, 'children'> & {
  children:
    | React.ReactNode
    | ((close: () => void, isOpen: boolean) => React.ReactElement);
};

const Popup = React.forwardRef<PopupRef, Props>(
  ({ children, position = [PopupPosition.topCenter], ...rest }: Props, ref) => {
    return (
      <PopupContainer ref={ref} position={position} {...rest}>
        {/* @ts-ignore TODO bug in i18next https://github.com/i18next/react-i18next/issues/1543 */}
        <div styleName="popup">{children}</div>
      </PopupContainer>
    );
  }
);

export default React.memo(Popup);
