import { ChannelKind } from '../channels';
import { Color } from '../color';
import { TranslationMap, TranslationString } from '../localization';

export enum ChatRealmType {
  bot = 'bot',
  chat = 'chat',
  feed = 'feed',
  comments = 'comments',
  businessAccount = 'business_account'
}
export type ChatServer = {
  _id: string;
  external_id: number;
  title: string;
  channel_kind: ChannelKind;
  channel_id: string;
  realm: ChatRealmType;
  is_enabled?: boolean;
};

export const mapChatRealmTypeToTitle: TranslationMap<
  ChatRealmType,
  TranslationString
> = {
  [ChatRealmType.bot]: (t) => t('chats.chatRealmType.bot', { ns: 'entities' }),
  [ChatRealmType.chat]: (t) =>
    t('chats.chatRealmType.chat', { ns: 'entities' }),
  [ChatRealmType.feed]: (t) =>
    t('chats.chatRealmType.feed', { ns: 'entities' }),
  [ChatRealmType.comments]: (t) =>
    t('chats.chatRealmType.comments', { ns: 'entities' }),
  [ChatRealmType.businessAccount]: (t) =>
    t('chats.chatRealmType.business_account', { ns: 'entities' })
};

export const mapChatRealmTypeToColor: Record<ChatRealmType, Color> = {
  [ChatRealmType.bot]: Color.COLOR_BLUE_14,
  [ChatRealmType.chat]: Color.COLOR_BLUE_12,
  [ChatRealmType.feed]: Color.COLOR_BLUE_3,
  [ChatRealmType.comments]: Color.COLOR_GRAY_2,
  [ChatRealmType.businessAccount]: Color.COLOR_PURPLE_1
};
