const getCurrentCabinetDomain = (): string => {
  const splitedHostname = window.location.hostname.split('.');
  const domainZone = splitedHostname[splitedHostname.length - 1];
  const domain = splitedHostname[splitedHostname.length - 2];
  const cabinet = splitedHostname[splitedHostname.length - 3];

  return `${cabinet}.${domain}.${domainZone}`;
};

export default getCurrentCabinetDomain;
