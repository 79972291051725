import { makeObservable, observable, reaction, action } from 'mobx';

import { AnalyticsEventObj } from 'shared/entities/analytics';
import { FieldModel } from 'shared/models/form';
import { ReactionsHandlerStore } from 'stores/reactionsHandlerStore';

export abstract class AnalyticsModel extends ReactionsHandlerStore {
  initialized: FieldModel<boolean | null> = new FieldModel(null);
  private eventsQueue: AnalyticsEventObj[] = [];

  constructor() {
    super();
    this.applyInitialized = this.applyInitialized.bind(this);
    this.applyError = this.applyError.bind(this);

    makeObservable<AnalyticsModel, 'handleEventFromQueue' | 'eventsQueue'>(
      this,
      {
        eventsQueue: observable,
        handleEventFromQueue: action.bound
      }
    );
  }

  /**
   * Доступна ли аналитика в данном окружении
   */
  protected abstract get enabled(): boolean;

  protected abstract executeScript(
    onSuccess: () => void,
    onError: () => void
  ): void;

  protected abstract sendEvent(event: AnalyticsEventObj): void;

  async initialize(): Promise<BaseResponse> {
    if (!this.enabled) {
      return {
        isError: false
      };
    }

    this.initializeReactions();

    return new Promise((resolve) => {
      this.executeScript(
        () => {
          this.applyInitialized();
          resolve({
            isError: false
          });
        },
        () => {
          this.applyError();
          resolve({
            isError: true
          });
        }
      );
    });
  }

  reset(): void {
    this.disposeAll();
    this.initialized.changeValue(false);
  }

  addToQueue(event: AnalyticsEventObj): void {
    if (!this.enabled) {
      return;
    }

    this.eventsQueue.push(event);
  }

  private applyInitialized(): void {
    this.initialized.changeValue(true);
  }

  private applyError(): void {
    this.initialized.changeValue(false);
  }

  private handleEventFromQueue(): void {
    const event = this.eventsQueue.shift();

    if (!event) {
      return;
    }

    this.sendEvent(event);
  }

  private initializeReactions(): void {
    this.addReaction({
      key: 'initialized',
      reaction: reaction(
        () => this.initialized.value,
        (value) => {
          if (!value) {
            return;
          }

          this.handleEventFromQueue();
        }
      )
    });

    this.addReaction({
      key: 'queue',
      reaction: reaction(
        () => this.eventsQueue.length,
        () => {
          if (!this.initialized.value) {
            return;
          }

          this.handleEventFromQueue();
        }
      )
    });
  }
}
