import { computed, makeObservable } from 'mobx';

import { apiUrls } from 'shared/entities/domain';
import {
  IYooMoneyPaymentMethodModel,
  UserPaymentMethodKind
} from 'shared/entities/userPayment';
import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { FieldModel } from 'shared/models/form';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';

export default class YooMoneyStore extends ComponentLoadingStore {
  readonly gettingUrlStage: LoadingStageModel = new LoadingStageModel();
  readonly redirectPaymentMethodId: FieldModel<string | null> = new FieldModel<
    string | null
  >(null);
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;

    makeObservable<YooMoneyStore>(this, {
      methods: computed
    });
  }

  get loaded(): boolean {
    return true;
  }

  get canBeLoaded(): boolean {
    return true;
  }

  get methods(): IYooMoneyPaymentMethodModel[] {
    const methods: IYooMoneyPaymentMethodModel[] = [];

    this.rootStore.userPaymentsStore.methods.items.forEach((method) => {
      if (method.providerKind === UserPaymentMethodKind.yooMoney) {
        methods.push(method);
      }
    });

    return methods;
  }

  getUrl = async (): Promise<BaseResponse> => {
    if (this.gettingUrlStage.isLoading) {
      return {
        isError: true
      };
    }

    this.gettingUrlStage.loading();

    const response = await this.rootStore.networkStore.api<{
      auth_url: string;
    }>(apiUrls.OAUTH_YOOMONEY_GET_URL);

    if (response.isError) {
      this.gettingUrlStage.error();

      return {
        isError: true
      };
    } else {
      this.gettingUrlStage.success();
      window.location.href = response.data.auth_url;

      return {
        isError: false
      };
    }
  };

  async load(): Promise<BaseResponse> {
    return {
      isError: false
    };
  }

  reset(): void {
    this.gettingUrlStage.reset();
    this.redirectPaymentMethodId.changeValue(null);
  }
}
