import { LoadingStageModel } from 'shared/models/loadingStage';
import { FieldModel } from 'shared/models/form';
import { ICommonDocument } from 'shared/entities/documents';
export class BaseDocumentModel
  implements Omit<ICommonDocument, 'managersNotified'>
{
  readonly id: string;
  readonly url: string;
  readonly date: Date;
  readonly cabinetId: string;
  readonly removingStage: LoadingStageModel = new LoadingStageModel();
  readonly notifyingStage: LoadingStageModel = new LoadingStageModel();
  readonly name: string;
  readonly managersNotified: FieldModel<boolean>;

  constructor({
    id,
    url,
    date,
    cabinetId,
    name,
    managersNotified
  }: ICommonDocument & {
    cabinetId: string;
  }) {
    this.id = id;
    this.url = url;
    this.date = date;
    this.cabinetId = cabinetId;
    this.name = name;
    this.managersNotified = new FieldModel<boolean>(managersNotified);
  }
}
