import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const BubbleIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <path
        d="M5.31937 20.7296C5.50233 21.0684 5.5546 21.4593 5.47619 21.8502L4.56138 25.6547C4.40456 26.3062 4.95344 26.9055 5.60688 26.8013L10.6253 26.1759C11.2003 26.0977 11.7753 26.1498 12.3242 26.3322C13.6572 26.7492 15.0686 26.9577 16.4539 26.9577C23.3803 26.9577 28.9999 21.8241 28.9999 15.4658C29.026 9.13355 23.4065 4 16.4801 4C9.55363 4 3.93408 9.13355 3.93408 15.4658C3.93408 17.316 4.40456 19.1401 5.31937 20.7296Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M11.9581 16.5342C12.5331 16.5342 13.0036 16.0652 13.0036 15.4919C13.0036 14.9186 12.5331 14.4495 11.9581 14.4495C11.3831 14.4495 10.9126 14.9186 10.9126 15.4919C10.9126 16.0652 11.3831 16.5342 11.9581 16.5342Z"
        fill="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M16.4801 16.5342C17.0551 16.5342 17.5256 16.0652 17.5256 15.4919C17.5256 14.9186 17.0551 14.4495 16.4801 14.4495C15.905 14.4495 15.4346 14.9186 15.4346 15.4919C15.4346 16.0652 15.905 16.5342 16.4801 16.5342Z"
        fill="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M20.9757 16.5342C21.5507 16.5342 22.0212 16.0652 22.0212 15.4919C22.0212 14.9186 21.5507 14.4495 20.9757 14.4495C20.4007 14.4495 19.9302 14.9186 19.9302 15.4919C19.9302 16.0652 20.4007 16.5342 20.9757 16.5342Z"
        fill="currentColor"
        strokeWidth="1.2"
      />
    </BaseIcon>
  );
};

export default BubbleIcon;
