import { computed, makeObservable } from 'mobx';

import {
  ITelegramPaymentMethodModel,
  UserPaymentMethodKind
} from 'shared/entities/userPayment';
import ListModel from 'shared/models/ListModel';
import { NotConnectedTelegramPaymentsCabinet } from 'shared/models/telegramPayments';
import { IRootStore } from 'shared/entities/store/rootStore';
import { TelegramPaymentMethodModel } from 'shared/models/userPayment/TelegramPaymentMethodModel';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';

export default class TelegramPaymentsStore extends ComponentLoadingStore {
  readonly notConnectedCabinets: ListModel<NotConnectedTelegramPaymentsCabinet> =
    new ListModel<NotConnectedTelegramPaymentsCabinet>();
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;

    this.addCabinet = this.addCabinet.bind(this);
    this.createMethod = this.createMethod.bind(this);
    this.removeCabinet = this.removeCabinet.bind(this);

    makeObservable(this, {
      entitiesLength: computed
    });
  }

  get loaded(): boolean {
    return true;
  }

  get canBeLoaded(): boolean {
    return true;
  }

  get methods(): ITelegramPaymentMethodModel[] {
    const methods: ITelegramPaymentMethodModel[] = [];

    this.rootStore.integrationsStore.userPaymentsStore.methods.items.forEach(
      (method) => {
        if (method.providerKind === UserPaymentMethodKind.tg) {
          methods.push(method);
        }
      }
    );

    return methods;
  }

  get entitiesLength(): number {
    return this.notConnectedCabinets.length + this.methods.length;
  }

  addCabinet(): void {
    const cabinet = NotConnectedTelegramPaymentsCabinet.fromDefaultParams(
      this.rootStore
    );

    this.notConnectedCabinets.addEntity({ entity: cabinet, key: cabinet.id });
  }

  removeCabinet(notConnectedCabinetId: string): void {
    this.notConnectedCabinets.removeEntity(notConnectedCabinetId);
  }

  async createMethod(notConnectedCabinetId: string): Promise<BaseResponse> {
    const cabinet = this.notConnectedCabinets.getEntity(notConnectedCabinetId);

    if (!cabinet) {
      return {
        isError: true
      };
    }

    const response = await cabinet.create();

    if (!response.isError) {
      const method = TelegramPaymentMethodModel.fromJson(
        response.data,
        this.rootStore
      );

      if (method) {
        this.notConnectedCabinets.removeEntity(notConnectedCabinetId);
        this.rootStore.integrationsStore.userPaymentsStore.methods.addEntity({
          entity: method,
          key: method.id
        });

        return {
          isError: false
        };
      }

      return {
        isError: true
      };
    }

    return {
      isError: true
    };
  }

  async load(): Promise<BaseResponse> {
    return {
      isError: false
    };
  }

  reset(): void {
    this.notConnectedCabinets.reset();
  }
}
