import { action, computed, makeObservable, observable, reaction } from 'mobx';
import {
  addMonths,
  isSameYear,
  isSameDay,
  differenceInCalendarMonths
} from 'date-fns';

import { FieldModel } from 'shared/models/form';
import { DateFormat, transformDate } from 'shared/entities/date';
import { ReactionsHandlerStore } from 'stores/reactionsHandlerStore';
import { OpenStateModel } from 'shared/models/openState';
import {
  DatePickerDateFormat,
  DatePickerKind,
  DatePickerTime,
  DatePickerValue,
  IDatePicker
} from 'shared/newEntities/components/DatePicker';
import { ValidatorResult } from 'shared/entities/validator';

import { DateBaseModel, DateModel } from '../DateModel';
import { CalendarMonthModel } from '../CalendarMonthModel';

export class DatePickerModel
  extends ReactionsHandlerStore
  implements IDatePicker
{
  readonly kind: DatePickerKind.oneDate = DatePickerKind.oneDate;
  readonly dateFormat: FieldModel<DatePickerDateFormat> =
    new FieldModel<DatePickerDateFormat>(DateFormat.dayMonthYear);

  readonly initialValue: FieldModel<DatePickerValue> =
    new FieldModel<DatePickerValue>(null);

  readonly cb: FieldModel<(dateModel: DatePickerValue) => void>;

  readonly inputDateModel: FieldModel<DateBaseModel | null> =
    new FieldModel<DateBaseModel | null>(null);

  readonly calendarDateModel: FieldModel<DateBaseModel | null> =
    new FieldModel<DateBaseModel | null>(null);

  readonly calendarTimeModel: FieldModel<DatePickerTime> =
    new FieldModel<DatePickerTime>(null);

  readonly calendarDateError: FieldModel<ValidatorResult> =
    new FieldModel<ValidatorResult>(null);

  readonly calendarTimeError: FieldModel<ValidatorResult> =
    new FieldModel<ValidatorResult>(null);

  readonly hoverableDate: FieldModel<DateBaseModel | null> =
    new FieldModel<DateBaseModel | null>(null);

  readonly modalOpened: OpenStateModel = new OpenStateModel();

  readonly required: boolean;

  monthModel: CalendarMonthModel<DatePickerKind.oneDate>;

  constructor({
    value,
    cb,
    dateFormat = DateFormat.dayMonthYear,
    required = false
  }: {
    value: DatePickerValue;
    cb: (value: DatePickerValue) => void;
    required?: boolean;
    dateFormat?: DatePickerDateFormat;
  }) {
    super();

    this.cb = new FieldModel(cb);
    this.initialize(value);
    this.required = required;
    this.dateFormat = new FieldModel<DatePickerDateFormat>(dateFormat);

    makeObservable(this, {
      monthModel: observable.ref,

      applyButtonDisabled: computed,
      initialValueString: computed,
      isEqual: computed,
      isTodayActive: computed,
      hours: computed,
      minutes: computed,

      handleCalendarDateClick: action.bound,
      handleCalendarDateHover: action.bound,
      moveMonth: action.bound,
      apply: action.bound,
      reset: action.bound,
      chooseToday: action.bound
    });
  }

  get applyButtonDisabled(): boolean {
    return (
      !!this.calendarDateError.value ||
      !!this.calendarTimeError.value ||
      (this.required && !this.calendarDateModel)
    );
  }

  get isTodayActive(): boolean {
    const model = new DateBaseModel(new Date(), this.dateFormat.value);
    return (
      !!this.calendarDateModel.value &&
      this.calendarDateModel.value.isEqual(model)
    );
  }

  get isEqual(): boolean {
    return (
      (!this.initialValue.value && !this.calendarDateModel.value) ||
      (!!this.initialValue.value &&
        !!this.calendarDateModel.value &&
        isSameDay(this.initialValue.value, this.calendarDateModel.value.date) &&
        this.isEqualTime)
    );
  }

  get isEqualTime(): boolean {
    return (
      (!this.initialValue.value && !this.calendarTimeModel.value) ||
      (!!this.calendarTimeModel.value &&
        !!this.initialValue.value &&
        this.calendarTimeModel.value.hours === this.hours &&
        this.calendarTimeModel.value.minutes === this.minutes)
    );
  }

  get hours(): string | null {
    return this.initialValue.value
      ? `${
          this.initialValue.value.getHours() < 10 ? '0' : ''
        }${this.initialValue.value.getHours()}`
      : null;
  }

  get minutes(): string | null {
    return this.initialValue.value
      ? `${
          this.initialValue.value.getMinutes() < 10 ? '0' : ''
        }${this.initialValue.value.getMinutes()}`
      : null;
  }

  get initialValueString(): string | null {
    if (!this.initialValue.value) {
      return null;
    }
    const curDate = new Date();

    if (isSameYear(this.initialValue.value, curDate)) {
      return `${transformDate(this.initialValue.value, DateFormat.dayMonth)}`;
    }

    return `${transformDate(this.initialValue.value, DateFormat.date)}`;
  }

  changeInitialTime = (): void => {
    if (!this.initialValue.value) {
      this.calendarTimeModel.changeValue(null);
      return;
    }
    this.calendarTimeModel.changeValue({
      hours: this.hours ?? '',
      minutes: this.minutes ?? ''
    });
  };

  initializeReactions(): void {
    this.addReaction({
      key: 'opened',
      reaction: reaction(
        () => this.modalOpened.opened,
        (opened) => {
          if (opened) {
            this.reset();
          }
        }
      )
    });
    this.addReaction({
      key: 'time',
      reaction: reaction(() => this.initialValue.value, this.changeInitialTime)
    });
  }

  initialize(value: DatePickerValue): void {
    this.initialValue.changeValue(value);
    const dateModel = value
      ? new DateBaseModel(value, this.dateFormat.value)
      : null;

    this.inputDateModel.changeValue(dateModel);
    this.calendarDateModel.changeValue(dateModel);

    this.changeInitialTime();

    /*
     * Определение месяца
     * */
    if (this.calendarDateModel.value) {
      this.monthModel = CalendarMonthModel.fromDefaultParams({
        monthDate: this.calendarDateModel.value.date,
        datePicker: this
      });
    } else {
      /*
       * Если нет начальной даты, показываем текущий месяц
       * */
      const firstMonthDate = new Date();

      this.monthModel = CalendarMonthModel.fromDefaultParams({
        monthDate: firstMonthDate,
        datePicker: this
      });
    }
  }

  chooseToday(): void {
    const model = new DateBaseModel(new Date(), this.dateFormat.value);
    this.calendarDateModel.changeValue(model);
    this.monthModel = CalendarMonthModel.fromDefaultParams({
      monthDate: model.date,
      datePicker: this
    });
  }

  /**
   * Обработчик клика на дату в календаре
   * @param value
   */
  handleCalendarDateClick(value: DateModel): void {
    const model = new DateBaseModel(value.date, this.dateFormat.value);
    this.hoverableDate.changeValue(null);

    this.calendarDateModel.changeValue(model);

    if (value.isInCalendarMonth) {
      return;
    }

    this.showDateOnCalendar(model);
  }

  /**
   * Обработчик наведения на дату в календаре
   * @param value
   */
  handleCalendarDateHover(value: DateModel): void {
    if (this.calendarDateModel.value) {
      this.hoverableDate.changeValue(new DateBaseModel(value.date));
    }
  }

  /**
   * Перемещение месяца
   * @param offset на сколько переместить
   */
  moveMonth(offset: number): void {
    this.monthModel = CalendarMonthModel.fromDefaultParams({
      monthDate: addMonths(this.monthModel.monthDate, offset),
      datePicker: this
    });
  }

  moveMonthForward = (): void => {
    this.moveMonth(1);
  };

  moveMonthBack = (): void => {
    this.moveMonth(-1);
  };

  showDateOnCalendar(model: DateBaseModel): void {
    const diffInMothBetweenDateAndFirstMonth = differenceInCalendarMonths(
      this.monthModel.monthDate,
      model.date
    );

    this.moveMonth(-diffInMothBetweenDateAndFirstMonth);
  }

  apply(): BaseResponse<DatePickerValue> {
    if (!this.required && !this.calendarDateModel.value) {
      this.initialValue.changeValue(null);
      this.cb.value(null);
      this.inputDateModel.changeValue(null);
      return {
        isError: false,
        data: null
      };
    }
    if (this.calendarDateModel.value) {
      const newInitialData: Date = new Date(this.calendarDateModel.value.date);

      if (this.dateFormat.value === DateFormat.dayMonthYearTime) {
        if (this.required && !this.calendarTimeModel.value) {
          return {
            isError: true
          };
        }
        if (!this.calendarTimeModel.value) {
          this.changeInitialTime();
        }
        if (this.calendarTimeModel.value) {
          newInitialData.setHours(+this.calendarTimeModel.value.hours);
          newInitialData.setMinutes(+this.calendarTimeModel.value.minutes);
        }
      }

      this.initialValue.changeValue(newInitialData);
      this.cb.value(newInitialData);
      this.inputDateModel.changeValue(this.calendarDateModel.value);

      return {
        isError: false,
        data: newInitialData
      };
    }

    return {
      isError: true
    };
  }

  applyInputValue(): void {
    if (!this.inputDateModel.value) {
      if (this.required) {
        return;
      }
      this.initialValue.changeValue(null);
      this.cb.value(null);
      this.calendarDateModel.changeValue(null);
      return;
    }
    const newInitialData: Date = new Date(this.inputDateModel.value.date);
    this.initialValue.changeValue(newInitialData);
    this.cb.value(newInitialData);
    this.calendarDateModel.changeValue(this.inputDateModel.value);
  }

  reset(): DatePickerValue {
    const dateModel = this.initialValue.value
      ? new DateBaseModel(this.initialValue.value, this.dateFormat.value)
      : null;
    this.calendarDateModel.changeValue(dateModel);
    this.changeInitialTime();
    this.calendarDateError.changeValue(null);

    return this.initialValue.value;
  }
}
