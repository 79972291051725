import { Bucket } from '../../bucket';

import useSearchParams from './useSearchParams';

const useBucket = (): Bucket => {
  const search = useSearchParams();

  let { bucket } = search;

  if (!(bucket in Bucket)) {
    bucket = Bucket.dev;
  }

  return bucket;
};

export default useBucket;
