import { computed, makeObservable } from 'mobx';

import {
  ITinkoffPaymentMethodModel,
  UserPaymentMethodKind
} from 'shared/entities/userPayment';
import ListModel from 'shared/models/ListModel';
import { NotConnectedTinkoffTerminal } from 'shared/models/tinkoff';
import { IRootStore } from 'shared/entities/store/rootStore';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';
import { TinkoffPaymentMethodModel } from 'shared/models/userPayment/TinkkoffPaymentMethodModel';

export default class TinkoffStore extends ComponentLoadingStore {
  readonly notConnectedTerminals: ListModel<NotConnectedTinkoffTerminal> =
    new ListModel<NotConnectedTinkoffTerminal>();
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;

    makeObservable(this, {
      entitiesLength: computed
    });
  }

  get loaded(): boolean {
    return true;
  }

  get canBeLoaded(): boolean {
    return true;
  }

  get methods(): ITinkoffPaymentMethodModel[] {
    return this.rootStore.integrationsStore.userPaymentsStore.methods.items.reduce(
      (acc: ITinkoffPaymentMethodModel[], method) => {
        if (method.providerKind === UserPaymentMethodKind.tinkoff) {
          acc.push(method);
        }
        return acc;
      },
      []
    );
  }

  get entitiesLength(): number {
    return this.notConnectedTerminals.length + this.methods.length;
  }

  addTerminal = (): void => {
    const terminal = NotConnectedTinkoffTerminal.fromDefaultParams(
      this.rootStore
    );

    this.notConnectedTerminals.addEntity({
      entity: terminal,
      key: terminal.id
    });
  };

  removeTerminal = (notConnectedTerminalId: string): void => {
    this.notConnectedTerminals.removeEntity(notConnectedTerminalId);
  };

  createTerminal = async (
    notConnectedTerminalId: string
  ): Promise<BaseResponse> => {
    const terminal = this.notConnectedTerminals.getEntity(
      notConnectedTerminalId
    );

    if (!terminal) {
      return {
        isError: true
      };
    }

    const response = await terminal.create();

    if (!response.isError) {
      const method = TinkoffPaymentMethodModel.fromJson(
        response.data,
        this.rootStore
      );
      if (!method) {
        return {
          isError: true
        };
      }
      this.notConnectedTerminals.removeEntity(notConnectedTerminalId);
      this.rootStore.integrationsStore.userPaymentsStore.methods.addEntity({
        entity: method,
        key: method.id
      });

      return {
        isError: false
      };
    }

    return {
      isError: true
    };
  };

  async load(): Promise<BaseResponse> {
    return {
      isError: false
    };
  }

  reset(): void {
    this.notConnectedTerminals.reset();
  }
}
