import * as React from 'react';
import { observer } from 'mobx-react';

import ContentModal from 'shared/newComponents/modals/ContentModal';
import { BaseModalProps } from 'shared/newEntities/components/BaseModal';
import { useLocalStore } from 'shared/entities/common/hooks';
import {
  ChannelsModalStore,
  ChannelsModalStoreProvider
} from 'stores/ChannelsModalStore';
import { useChannelsStore, useRootStore } from 'stores';
import { ScenarioBaseModel } from 'shared/models/scenario';
import ChannelsContent from 'shared/newContainers/ChannelsContent';
import ChannelAccounts from 'shared/newComponents/ChannelAccounts';

import './ChannelsModal.modules.scss';

type Props = BaseModalProps & {
  scenario?: ScenarioBaseModel;
  withAccounts?: boolean;
  footer?: React.ReactNode;
};
const ChannelsModal: React.FC<Props> = ({
  scenario,
  withAccounts = true,
  onClose,
  opened,
  footer
}) => {
  const rootStore = useRootStore();
  const channelsStore = useChannelsStore();
  const storeCtx = useLocalStore(
    () =>
      new ChannelsModalStore({
        rootStore,
        scenario: scenario || null,
        withAccounts,
        isModal: true
      })
  );

  React.useEffect(() => {
    if (opened) {
      channelsStore.load();
    }
  }, [opened]);

  const handleClose = React.useCallback(() => {
    onClose();
    storeCtx.store.unselect();
  }, [onClose, storeCtx.store.unselect]);

  return (
    <ChannelsModalStoreProvider
      value={{ store: storeCtx.store, close: onClose }}
    >
      <ContentModal
        onClose={handleClose}
        opened={opened}
        title={(t) =>
          t('ChannelsModal.title', {
            ns: 'newContainers'
          })
        }
        headerExtraComponent={
          !storeCtx.store.scenario && storeCtx.store.withAccounts ? (
            <ChannelAccounts
              styleName="channels-modal__accounts"
              onClick={onClose}
            />
          ) : undefined
        }
      >
        <ChannelsContent footer={footer} />
      </ContentModal>
    </ChannelsModalStoreProvider>
  );
};

export default observer(ChannelsModal);
