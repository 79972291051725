import { CommonDocumentServer, CommonEditionFieldsServer } from './server';
import { ICommonDocument } from './client';

export const normalizeCommonDocument = (
  raw: CommonDocumentServer & CommonEditionFieldsServer
): ICommonDocument => ({
  ...raw,
  id: raw._id,
  date: new Date(raw.date_created),
  managersNotified: raw.managers_notified
});
