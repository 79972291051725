import { FieldModel } from 'shared/models/form';
import { IRootStore } from 'shared/entities/store/rootStore';
import {
  KeyboardEventListener,
  KeyboardEventType
} from 'shared/entities/keyboardEvents';

export type ModalEventsHandlerOnCloseCb = (() => void) | null;

const resetEvents: KeyboardEventType[] = [
  KeyboardEventType.copy,
  KeyboardEventType.paste,
  KeyboardEventType.delete,
  KeyboardEventType.cancel,
  KeyboardEventType.enter,
  KeyboardEventType.duplication
];

export class ModalEventsHandlerStore {
  readonly state: FieldModel<{ zIndex: number } | null>;
  readonly cb: FieldModel<ModalEventsHandlerOnCloseCb>;
  readonly closeOnEscape: boolean;

  private rootStore: IRootStore;

  constructor({
    opened,
    cb = null,
    rootStore,
    closeOnEscape
  }: {
    opened: boolean;
    cb?: ModalEventsHandlerOnCloseCb;
    rootStore: IRootStore;
    closeOnEscape: boolean;
  }) {
    this.rootStore = rootStore;
    this.state = new FieldModel(null);
    this.changeState(opened);
    this.cb = new FieldModel<ModalEventsHandlerOnCloseCb>(cb);
    this.closeOnEscape = closeOnEscape;
  }

  changeState = (opened: boolean): void => {
    if (opened) {
      if (!this.state.value) {
        this.state.changeValue({
          zIndex: this.rootStore.uiStore.modalsStore.useZIndex()
        });
      }

      return;
    }

    this.resetState();
  };

  resetState = (): void => {
    if (this.state.value === null) {
      return;
    }

    this.rootStore.uiStore.modalsStore.stopUsingZIndex(this.state.value.zIndex);
    this.state.changeValue(null);
  };

  addListeners = (): void => {
    resetEvents.forEach((event) => {
      this.rootStore.keyboardEventsStore.addListener({
        type: event,
        listener: this.handleResetKeyboardEvent
      });
    });

    if (this.closeOnEscape) {
      this.rootStore.keyboardEventsStore.addListener({
        type: KeyboardEventType.escape,
        listener: this.handleEscapeKeyboardEvent
      });
    }
  };

  removeListeners = (): void => {
    resetEvents.forEach((event) => {
      this.rootStore.keyboardEventsStore.removeListener({
        type: event,
        listener: this.handleResetKeyboardEvent
      });
    });

    if (this.closeOnEscape) {
      this.rootStore.keyboardEventsStore.removeListener({
        type: KeyboardEventType.escape,
        listener: this.handleEscapeKeyboardEvent
      });
    }
  };

  handleClosing = (): void => {
    if (this.state.value && this.cb.value) {
      this.cb.value();
    }
  };

  private handleEscapeKeyboardEvent: KeyboardEventListener =
    async (): Promise<boolean> => {
      this.handleClosing();

      return true;
    };

  private handleResetKeyboardEvent: KeyboardEventListener = async () => {
    return !!this.state.value;
  };
}
