import * as React from 'react';

const useScript = ({
  url,
  ref,
  attrs = {}
}: {
  url: string;
  ref: HTMLElement | null;
  attrs?: Record<string, string>;
}): {
  loaded: boolean;
} => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!ref) {
      return;
    }

    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.onload = () => setLoaded(true);
    script.onerror = () => setLoaded(false);

    Object.keys(attrs).forEach((key) => {
      script.setAttribute(key, attrs[key]);
    });

    ref.appendChild(script);

    return () => {
      ref.removeChild(script);
    };
  }, [ref, url]);

  return {
    loaded
  };
};

export default useScript;
