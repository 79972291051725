import * as React from 'react';

import { TranslationNode } from 'shared/entities/localization';

import { LoadingStage } from '../../meta';
import { ComponentType } from '../Component';
import { CommonIconProps, IconSize } from '../Icon';

export enum ButtonColor {
  gray = 'gray',
  blue = 'blue',
  green = 'green',
  red = 'red',
  white = 'white',
  purple = 'purple',
  yellow = 'yellow'
}

export enum ButtonStyle {
  common = 'common',
  transparent = 'transparent'
}

export enum ButtonFontStyle {
  base = 'base',
  light = 'light',
  bold = 'bold'
}

export enum ButtonRadius {
  m = 'radius-m',
  l = 'radius-l'
}

export type ButtonProps = {
  className?: string;
  children?: TranslationNode | null;
  style?: ButtonStyle;
  color?: ButtonColor;
  radius?: ButtonRadius;
  fontStyle?: ButtonFontStyle;
  loadingStage?: LoadingStage;
  disabled?: boolean;
  fullWidth?: boolean;
  widthBoxShadow?: boolean;
  Icon?: React.FC<CommonIconProps>;
  collapse?: boolean;
  fixHeight?: boolean;
  iconSize?: IconSize;
  hideCollapsedReference?: boolean;
} & (
  | {
      type: ComponentType.link;
      target?: string;
      to: string;
    }
  | {
      type: ComponentType.button;
      onClick: () => void;
    }
  | {
      type: ComponentType.a;
      href: string;
      target?: string;
      rel?: string;
    }
);
