import {
  validateArrayJsonValue,
  validateMapJsonValue,
  validateNumberValue,
  Validator,
  ValidatorResult,
  validatorTransFunctions
} from '../../validator';
import { VarBoolValue, VarType } from '../server';
import { IBaseVarModel, IValidateVar } from '../client';

import { parseValueForGettingVarKey } from './parseValueForGettingVarKey';
import { parseValueForGettingVarsKeys } from './parseValueForGettingVarsKeys';
import { parseValueForGettingSQs } from './parseValueForGettingSQs';

export const validateVariable: Validator<string> = (value) => {
  return parseValueForGettingVarKey({ value })
    ? null
    : validatorTransFunctions.requiredField;
};

export const validateBoolValue: Validator<string> = (value) => {
  return value in VarBoolValue
    ? null
    : (t) =>
        t('validators.errors.invalidBoolValue', {
          ns: 'entities'
        });
};

export const validateVar = ({
  variable,
  projectVariables,
  specialsVariables
}: {
  variable: IValidateVar;
  projectVariables: IBaseVarModel[];
  specialsVariables: IBaseVarModel[];
}): ValidatorResult => {
  variable.value = variable.value.trim();

  const sQsCoincidences = parseValueForGettingSQs({ variable });

  // если значение переменной - sq скрипт, то ошибки нет, и дальше нет смысла проверять
  if (
    sQsCoincidences.length === 1 &&
    variable.value.length === sQsCoincidences[0].length
  ) {
    return null;
  }

  if (sQsCoincidences.length > 1) {
    return (t) =>
      t('validators.errors.numberVarErrors.invalidSqsCount', {
        ns: 'entities'
      });
  }

  const varsKeysCoincidences = parseValueForGettingVarsKeys({
    variable,
    availableVars: [...projectVariables, ...specialsVariables]
  });

  // если значение переменной - переменная
  if (
    varsKeysCoincidences.length === 1 &&
    varsKeysCoincidences[0].key.length === variable.value.length
  ) {
    // если одинаковый тип
    if (varsKeysCoincidences[0].type === variable.type) {
      return null;
    }
    return (t) =>
      t('validators.errors.numberVarErrors.invalidVarKeyType', {
        ns: 'entities'
      });
  }

  if (variable.type === VarType.num) {
    return validateNumberValue(variable.value);
  }

  if (variable.type === VarType.list) {
    return validateArrayJsonValue(variable.value);
  }

  if (variable.type === VarType.dict) {
    return validateMapJsonValue(variable.value);
  }

  return null;
};
