import { action, makeObservable, observable } from 'mobx';

import BaseEditStore from 'stores/editStore/BaseEditStore';
import {
  CabinetManagerRoleType,
  mapProjectManagerAddingErrorCodeToMessage,
  ProjectManagerRoleType
} from 'shared/entities/manager';
import {
  validateEmailField,
  validateIsEmpty,
  validateTextField
} from 'shared/entities/validator';
import { ProjectManagerAddingEditModel } from 'shared/models/manager';
import { mapErrorCodeToMessage } from 'shared/entities/common/utils';
import { ProjectManagersStore } from 'stores/managers/projectManagersStore';

export default class ProjectManagerAddingEditStore extends BaseEditStore<ProjectManagerAddingEditModel> {
  validators = {
    email: [validateTextField, validateEmailField],
    role: [validateIsEmpty]
  };

  managersStore: ProjectManagersStore;

  constructor({
    managersStore,
    role,
    cabinetRole,
    projectId
  }: {
    managersStore: ProjectManagersStore;
    role: ProjectManagerRoleType;
    cabinetRole: CabinetManagerRoleType;
    projectId: string;
  }) {
    super({
      initialData: new ProjectManagerAddingEditModel({
        email: '',
        role,
        cabinetRole,
        projectId
      })
    });

    this.managersStore = managersStore;

    makeObservable(this, {
      managersStore: observable,

      add: action.bound
    });
  }

  async add(): Promise<void> {
    const entity = this.save();

    if (!entity) {
      return;
    }

    const response = await this.managersStore.add(entity.toJson());

    if (!response.isError) {
      this.reset();
    } else {
      this.setError(
        'email',
        mapErrorCodeToMessage({
          errorCode: response.data.errorCode,
          map: mapProjectManagerAddingErrorCodeToMessage
        })
      );
    }
  }

  validateRole(): BaseResponse<{ role: ProjectManagerRoleType }> {
    const isError = this.validateField('role');

    if (!isError) {
      return {
        isError: false,
        data: { role: this.entity.role }
      };
    }

    return {
      isError: true
    };
  }
}
