import { TranslationString } from 'shared/entities/localization';

import { SelectorItem } from '../components/Selector';

import { StatsEventType } from './server';

export type IStatsEvent = Omit<SelectorItem<string>, 'id'> & {
  id: string;
  type: StatsEventType;
};

export interface IStatsDataItem {
  date: string;
  data: {
    title: string;
    count: number;
    eventId: string;
  }[];
}

export interface IStatLabel {
  id: string;
  title: string;
}

export interface IStatsTableDataItem {
  type: StatsEventType;
  id: string;
  title: string;
  eventId: string;
  count: number;
}

export const ALL_MESSAGES_EVENT_TITLE: TranslationString = (t) =>
  t('stats.allMessagesEventTitle', { ns: 'entities' });
export const ALL_USERS_EVENT_TITLE: TranslationString = (t) =>
  t('stats.allUsersEventTitle', { ns: 'entities' });
export const SPECIAL_EVENT_ID = '1__special';

export enum StatsStoreType {
  common = 'common',
  block = 'block'
}

export type StatsStoreData =
  | { type: StatsStoreType.common }
  | {
      type: StatsStoreType.block;
      block: { id: string; name: TranslationString };
      channelIds: string[];
    };
