import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const DeletedCommentIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M16.895 4H7.105C5.953 4 5.365 4.113 4.755 4.44C4.19509 4.73706 3.73706 5.19509 3.44 5.755C3.113 6.365 3 6.953 3 8.105V13.895C3 15.047 3.113 15.635 3.44 16.245C3.742 16.81 4.19 17.258 4.755 17.56L4.895 17.631C5.412 17.881 5.945 17.983 6.849 17.998L7.1 18V20.215C7.1 20.647 7.27 21.061 7.572 21.37L7.688 21.478C8.00199 21.7419 8.40303 21.8795 8.81292 21.8637C9.2228 21.848 9.61214 21.6802 9.905 21.393L13.366 18H16.896C18.047 18 18.634 17.887 19.246 17.56C19.8055 17.2628 20.2632 16.8048 20.56 16.245C20.887 15.635 21 15.047 21 13.895V8.105C21 6.953 20.887 6.365 20.56 5.755C20.2629 5.19509 19.8049 4.73706 19.245 4.44C18.635 4.113 18.047 4 16.895 4ZM6.912 5.801L16.895 5.8C17.775 5.8 18.082 5.86 18.397 6.028C18.647 6.162 18.838 6.353 18.972 6.603C19.141 6.918 19.2 7.225 19.2 8.105V13.895L19.196 14.263C19.179 14.87 19.115 15.13 18.972 15.397C18.838 15.647 18.647 15.838 18.397 15.972C18.082 16.141 17.775 16.2 16.895 16.2H13L12.883 16.208C12.6894 16.2335 12.5094 16.3212 12.37 16.458L8.9 19.856V17.1C8.9 16.8613 8.80518 16.6324 8.6364 16.4636C8.46761 16.2948 8.23869 16.2 8 16.2H7.105L6.737 16.196C6.13 16.179 5.87 16.115 5.603 15.972C5.35763 15.8428 5.15716 15.6424 5.028 15.397C4.859 15.082 4.8 14.775 4.8 13.895V8.105L4.804 7.737C4.821 7.13 4.885 6.87 5.028 6.603C5.162 6.353 5.353 6.162 5.603 6.028C5.894 5.872 6.177 5.81 6.912 5.801Z"
        fill="currentColor"
      />
      <path
        d="M4 19L20 3"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 3L20 19"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default DeletedCommentIcon;
