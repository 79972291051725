import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const LogoIcon: React.FC<CommonIconProps> = (props) => {
  return (
    <BaseIcon
      svgProps={{
        viewBox: '0 0 776 580'
      }}
      iconSize={IconSize.XXL}
      {...props}
    >
      <path
        d="M307.426 474.234C231.672 494.988 114.437 440.156 103.749 378.851C37.1199 364.606 -13.5797 242.643 33.1434 193.627C-2.23912 77.9316 258.026 -29.9292 345.064 37.6682C390.57 -11.8153 577.015 27.0601 600.917 83.8708C666.261 74.7759 766.111 236.479 743.807 304.664C782.624 346.165 768.831 530.51 563.055 454.795C555.502 452.016 547.19 450.482 539.382 452.431C494.415 463.656 453.212 511.304 495.28 560.77"
        stroke="currentColor"
        strokeWidth="30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.926 312.48C281.507 457.729 280.119 299.245 351.287 337.444"
        stroke="currentColor"
        strokeWidth="30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M252.009 145.183C288.826 209.675 275.988 238.983 256.168 264.187"
        stroke="currentColor"
        strokeWidth="30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.646 183.357C250.28 221.75 261.371 323.493 346.382 332.655"
        stroke="currentColor"
        strokeWidth="30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M391.309 119.88C370.68 228.387 359.634 312.885 359.635 376.836C359.636 402.021 364.495 432.987 378.633 462.548C398.073 503.194 418.397 534.122 497.04 562.84"
        stroke="currentColor"
        strokeWidth="30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M571.967 280.686C474.226 308.707 497.551 252.671 430.461 242.734"
        stroke="currentColor"
        strokeWidth="30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M589.42 384.598C467.541 395.102 448.66 319.049 365.387 393.396"
        stroke="currentColor"
        strokeWidth="30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M450.588 121.466C447.968 149.52 444.546 180.777 394.728 178.94"
        stroke="currentColor"
        strokeWidth="30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M549.569 173.038C531.538 248.437 410.113 222.948 369.799 274.781"
        stroke="currentColor"
        strokeWidth="30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        rx="14.043"
        ry="13.2544"
        transform="matrix(0.935235 -0.354027 0.406867 0.913487 390.434 83.5853)"
        fill="currentColor"
      />
      <ellipse
        rx="14.043"
        ry="13.2544"
        transform="matrix(0.935235 -0.354027 0.406867 0.913487 453.569 85.4096)"
        fill="currentColor"
      />
      <ellipse
        rx="14.043"
        ry="13.2544"
        transform="matrix(0.935235 -0.354027 0.406867 0.913487 563.888 139.05)"
        fill="currentColor"
      />
      <ellipse
        rx="14.043"
        ry="13.2544"
        transform="matrix(0.935235 -0.354027 0.406867 0.913487 608.89 267.507)"
        fill="currentColor"
      />
      <ellipse
        rx="14.043"
        ry="13.2544"
        transform="matrix(0.994759 -0.102251 0.158902 0.987294 629.765 383.197)"
        fill="currentColor"
      />
      <ellipse
        rx="14.043"
        ry="13.2544"
        transform="matrix(0.935235 -0.354027 0.406867 0.913487 114.401 285.855)"
        fill="currentColor"
      />
      <ellipse
        rx="14.043"
        ry="13.2544"
        transform="matrix(0.935235 -0.354027 0.406867 0.913487 141.89 168.187)"
        fill="currentColor"
      />
      <ellipse
        rx="14.043"
        ry="13.2544"
        transform="matrix(0.935235 -0.354027 0.406867 0.913487 234.714 112.484)"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default LogoIcon;
