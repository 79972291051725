import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const ResizeDown: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
    >
      <path
        d="M20.7803 3.21967C20.4874 2.92678 20.0125 2.92678 19.7197 3.21967L14.25 8.68935V5.25001C14.25 4.8358 13.9142 4.50002 13.5 4.50002C13.0858 4.50002 12.75 4.8358 12.75 5.25001V10.5C12.75 10.5002 12.75 10.5005 12.75 10.5008C12.7501 10.5252 12.7513 10.5496 12.7537 10.574C12.7548 10.5852 12.7569 10.596 12.7585 10.607C12.7604 10.6202 12.762 10.6334 12.7645 10.6465C12.767 10.6591 12.7705 10.6714 12.7736 10.6838C12.7765 10.6951 12.7789 10.7065 12.7823 10.7177C12.786 10.7298 12.7906 10.7416 12.7949 10.7535C12.7989 10.7647 12.8026 10.7759 12.8071 10.787C12.8117 10.7981 12.8171 10.8087 12.8222 10.8195C12.8276 10.8309 12.8327 10.8423 12.8386 10.8535C12.8441 10.8639 12.8505 10.8737 12.8565 10.8838C12.863 10.8947 12.8692 10.9058 12.8763 10.9164C12.8837 10.9275 12.892 10.938 12.9 10.9487C12.9067 10.9576 12.9128 10.9667 12.92 10.9753C12.9513 11.0136 12.9864 11.0486 13.0246 11.08C13.0333 11.0871 13.0424 11.0933 13.0513 11.1C13.062 11.108 13.0725 11.1163 13.0836 11.1237C13.0942 11.1308 13.1053 11.137 13.1162 11.1435C13.1263 11.1495 13.1361 11.1558 13.1465 11.1614C13.1576 11.1673 13.1691 11.1724 13.1805 11.1777C13.1913 11.1828 13.2019 11.1883 13.213 11.1929C13.224 11.1974 13.2353 11.2011 13.2465 11.2051C13.2584 11.2094 13.2701 11.214 13.2823 11.2176C13.2935 11.221 13.3049 11.2235 13.3162 11.2264C13.3286 11.2295 13.3408 11.233 13.3535 11.2355C13.3666 11.2381 13.3798 11.2396 13.3929 11.2415C13.404 11.2431 13.4148 11.2451 13.426 11.2463C13.4503 11.2486 13.4748 11.25 13.4992 11.25C13.4995 11.25 13.4997 11.25 13.5 11.25H18.75C19.1642 11.25 19.5 10.9142 19.5 10.5C19.5 10.0858 19.1642 9.75001 18.75 9.75001H15.3106L20.7803 4.28033C21.0732 3.98744 21.0732 3.51259 20.7803 3.21967Z"
        fill="currentColor"
      />
      <path
        d="M11.2463 13.426C11.2452 13.4148 11.2431 13.4039 11.2415 13.3929C11.2396 13.3798 11.238 13.3666 11.2355 13.3535C11.233 13.3409 11.2295 13.3286 11.2264 13.3162C11.2235 13.3049 11.2211 13.2935 11.2176 13.2823C11.214 13.2701 11.2094 13.2584 11.2051 13.2465C11.2011 13.2353 11.1974 13.224 11.1929 13.213C11.1883 13.2019 11.1829 13.1913 11.1777 13.1805C11.1724 13.1691 11.1673 13.1576 11.1614 13.1465C11.1558 13.1361 11.1495 13.1263 11.1435 13.1162C11.137 13.1053 11.1308 13.0942 11.1237 13.0836C11.1163 13.0725 11.108 13.062 11.1 13.0513C11.0933 13.0424 11.0871 13.0333 11.08 13.0246C11.0486 12.9864 11.0136 12.9513 10.9753 12.92C10.9667 12.9129 10.9576 12.9067 10.9487 12.9C10.938 12.892 10.9275 12.8837 10.9164 12.8763C10.9058 12.8692 10.8947 12.863 10.8838 12.8565C10.8737 12.8505 10.8639 12.8441 10.8535 12.8386C10.8424 12.8327 10.8309 12.8276 10.8195 12.8222C10.8087 12.8171 10.7981 12.8117 10.787 12.8071C10.7759 12.8026 10.7647 12.7989 10.7535 12.7949C10.7416 12.7906 10.7298 12.786 10.7177 12.7823C10.7065 12.779 10.6951 12.7765 10.6838 12.7736C10.6714 12.7705 10.6592 12.767 10.6465 12.7645C10.6334 12.7619 10.6202 12.7604 10.607 12.7585C10.596 12.7569 10.5852 12.7549 10.574 12.7537C10.5494 12.7513 10.5247 12.75 10.5 12.75H5.25001C4.8358 12.75 4.50002 13.0858 4.50002 13.5C4.50002 13.9142 4.8358 14.25 5.25001 14.25H8.68935L3.21967 19.7197C2.92678 20.0125 2.92678 20.4874 3.21967 20.7803C3.51255 21.0732 3.98744 21.0732 4.28033 20.7803L9.75001 15.3106V18.75C9.75001 19.1642 10.0858 19.5 10.5 19.5C10.9142 19.5 11.25 19.1642 11.25 18.75V13.5C11.25 13.4753 11.2487 13.4506 11.2463 13.426Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ResizeDown;
