import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const NotificationIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M17.6641 19.6323H6.33609C5.47209 19.6323 4.75209 19.2003 4.32009 18.4803C3.88809 17.7603 3.88809 16.8963 4.32009 16.1283C4.32009 16.0803 4.36809 16.0323 4.41609 16.0323L6.38409 14.1603C6.38409 13.6803 6.33609 12.4803 6.33609 10.8963C6.33609 8.16027 7.87209 5.95227 9.31209 5.56827C9.50409 4.56027 10.2721 3.07227 12.0001 3.07227C13.7281 3.07227 14.4481 4.56027 14.6881 5.56827C16.1761 5.90427 17.6641 7.87227 17.6641 10.8963C17.6641 12.4803 17.6161 13.6803 17.6161 14.1603L19.5841 16.0323C19.6321 16.0803 19.6321 16.1283 19.6801 16.1283C20.1121 16.8483 20.0641 17.7603 19.6801 18.4803C19.2481 19.2003 18.4801 19.6323 17.6641 19.6323ZM5.08809 16.6563C4.89609 17.0883 4.89609 17.5683 5.13609 18.0003C5.37609 18.4323 5.85609 18.6723 6.33609 18.6723H17.6641C18.1441 18.6723 18.6241 18.4323 18.8641 18.0003C19.1041 17.5683 19.1041 17.0883 18.9121 16.6563L16.8001 14.6883C16.7041 14.5923 16.6561 14.4483 16.6561 14.3043C16.6561 14.3043 16.7041 12.8643 16.7041 10.8483C16.7041 8.01627 15.2641 6.43227 14.2561 6.43227C14.0161 6.43227 13.8241 6.24027 13.7761 6.00027C13.7761 5.90427 13.5361 3.98427 12.0001 3.98427C10.4161 3.98427 10.1761 5.90427 10.1761 6.00027C10.1761 6.24027 9.93609 6.43227 9.69609 6.43227C8.97609 6.43227 7.24809 8.11227 7.24809 10.8483C7.24809 12.8643 7.29609 14.2563 7.29609 14.3043C7.29609 14.4483 7.24809 14.5923 7.15209 14.6883L5.08809 16.6563Z"
        fill="currentColor"
      />
      <path
        d="M12.1446 22.5605C10.4166 22.5605 9.07257 21.1205 9.02457 21.0725C8.83257 20.8805 8.88057 20.5925 9.07257 20.4005C9.26457 20.2085 9.55257 20.2565 9.74457 20.4485C9.74457 20.4485 10.8486 21.6005 12.1446 21.6005H12.1926C12.9126 21.6005 13.6326 21.2165 14.2566 20.4485C14.4486 20.2565 14.7366 20.2085 14.9286 20.4005C15.1206 20.5925 15.1686 20.8805 14.9766 21.0725C14.1606 22.0325 13.2006 22.5605 12.1926 22.5605C12.1446 22.5605 12.1446 22.5605 12.1446 22.5605Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default NotificationIcon;
