import * as React from 'react';

import { TranslationNode } from 'shared/entities/localization';

export enum TypographyWeight {
  normal = 400,
  heavy = 500,
  semiBold = 600,
  bold = 700
}

export type TypographyTag = 'div' | 'span' | 'input' | 'a' | 'th' | 'td';

export enum TypographySize {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l'
}

export enum TypographyType {
  title = 'title',
  text = 'text',
  mono = 'mono'
}

export enum TypographyColor {
  main = 'main',
  secondary = 'secondary',
  tertiary = 'tertiary',
  error = 'error',
  blue = 'blue',
  red = 'red',
  white = 'white',
  warning = 'warning',
  success = 'success',
  yellow = 'yellow',
  green = 'green',

  link = 'link'
}

export type TypographyProps<T extends TypographyTag | undefined> = {
  fontType: TypographyType;
  fontSize: TypographySize;
  fontWeight?: TypographyWeight;
  fontColor?: TypographyColor | null;
  className?: string;
  children?: TranslationNode;
  underline?: boolean;
} & ({
  Element?: T extends TypographyTag ? T : undefined;
} & Omit<
  React.ComponentProps<T extends TypographyTag ? T : 'div'>,
  'styleName' | 'ref' | 'children'
>);
