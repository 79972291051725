import { TranslationString } from 'shared/entities/localization';

import { ChannelKind } from '../channels';
import { Color } from '../color';

export enum FileType {
  all = 'all',
  doc = 'document',
  image = 'image',
  music = 'music',
  video = 'video',
  sticker = 'sticker',
  voice = 'voice',
  other = 'other',
  table = 'table',
  site = 'site'
}

export enum FileStatus {
  yes = 'yes',
  limited = 'limited',
  no = 'no'
}

export type FileAvailability = {
  reason: string | null;
  status: FileStatus;
};

export enum FileAvailabilityKey {
  vk = 'vk',
  tg = 'tg',
  vkWall = 'vk_wall'
}

export const mapFileAvailabilityKeyToChannelKind: Record<
  FileAvailabilityKey,
  ChannelKind
> = {
  [FileAvailabilityKey.vk]: ChannelKind.VK,
  [FileAvailabilityKey.vkWall]: ChannelKind.VK,
  [FileAvailabilityKey.tg]: ChannelKind.TELEGRAM
};

export type FileTags = Record<FileAvailabilityKey, FileAvailability>;

export type FileBaseServer = {
  _id: string;
  type: FileType;
  size: number;
  preview_url: string | null;
  filename: string;
  url: string;
  extension: string;
  created: string;
  extra: any;
  height: number | null;
  width: number | null;
};

export type FileServer = FileBaseServer & {
  availability: FileTags;
};

export type AttachmentServer = FileBaseServer & {
  as_document: boolean;
};

export type ConversationAttachmentServer = Omit<AttachmentServer, '_id'>;

export type BlockAttachmentServer = AttachmentServer & {
  attachment_id: string;
};

export const fileStatusEntities: Record<FileStatus, Color> = {
  [FileStatus.yes]: Color.COLOR_GREEN_1,
  [FileStatus.limited]: Color.COLOR_YELLOW,
  [FileStatus.no]: Color.COLOR_RED
};

export enum BaseUploadFileError {
  entityTooBig = 'entity_too_big'
}

export enum VkUploadFileError {
  managerHasNoToken = 'manager_has_no_token',
  noVkChannelsAvailable = 'no_vk_channels_available',
  forbidden = 'forbidden'
}

export type UploadFileError = VkUploadFileError | BaseUploadFileError;

export const mapUploadFileErrorToTitle =
  (code: UploadFileError): TranslationString =>
  (t) =>
    t(`file.errors.uploadFile.${code}`, {
      ns: 'entities'
    });
