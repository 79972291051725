import { BlockKind, BlockKindType, BlockType } from './server';

export const DISABLED_CREATING_BEFORE_SETTINGS_FETCH: BlockKindType[] = [];

// список блоков у которых запрещено изменять порты
export const DISABLED_PORTS_EDITING_BLOCKS: BlockKindType[] = [
  BlockKind.portalIn
];

// список блоков у которых запрещено изменять параметры
export const DISABLED_PARAMS_EDITING_BLOCKS: BlockKindType[] = [];

// список блоков у которых запрещено удаления
export const DISABLED_REMOVING_BLOCKS: BlockKindType[] = [
  BlockKind.preFilter,
  BlockKind.mailingSend
];

//список блоков, у которых запрещено копирование
export const DISABLED_COPYING_BLOCKS: BlockKindType[] = [
  BlockKind.preFilter,
  BlockKind.mailingSend
];

// список блоков, данные которых перезапрашиваются при открытии настроек
export const REPEATED_FETCH_BLOCKS: BlockKindType[] = [
  BlockKind.portalIn,
  BlockKind.portalOut,
  BlockKind.sendMessage,
  BlockKind.notifyManagers,
  BlockKind.mailingSend
];
// список блоков у которых обязательно наличие name
export const REQUIRED_NAME_BLOCKS: BlockKindType[] = [];

export const UPDATE_ATTACHMENTS_BLOCKS: BlockKindType[] = [
  BlockKind.sendMessage,
  BlockKind.notifyManagers,
  BlockKind.mailingSend
];

// список type блоков, которые нельзя использовать как target
export const CAN_NOT_BE_TARGET_BLOCKS: BlockType[] = [
  BlockType.information,
  BlockType.input,
  BlockType.mailing
];

export const DISABLED_TEST_TYPES: BlockType[] = [BlockType.information];
