import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const CapIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXM}
      svgProps={{
        viewBox: '0 0 22 19'
      }}
      {...props}
    >
      <path
        d="M4 7.5V13.5111C4 13.8701 4 14.0496 4.05465 14.208C4.10299 14.3482 4.18187 14.4758 4.28558 14.5817C4.40287 14.7014 4.5634 14.7817 4.88446 14.9422L10.2845 17.6422C10.5468 17.7734 10.678 17.839 10.8156 17.8648C10.9375 17.8877 11.0625 17.8877 11.1844 17.8648C11.322 17.839 11.4532 17.7734 11.7155 17.6422L17.1155 14.9422C17.4366 14.7817 17.5971 14.7014 17.7144 14.5817C17.8181 14.4758 17.897 14.3482 17.9453 14.208C18 14.0496 18 13.8701 18 13.5111V7.5M1 6L10.6422 1.17889C10.7734 1.1133 10.839 1.0805 10.9078 1.0676C10.9687 1.05616 11.0313 1.05616 11.0922 1.0676C11.161 1.0805 11.2266 1.1133 11.3578 1.17889L21 6L11.3578 10.8211C11.2266 10.8867 11.161 10.9195 11.0922 10.9324C11.0313 10.9438 10.9687 10.9438 10.9078 10.9324C10.839 10.9195 10.7734 10.8867 10.6422 10.8211L1 6Z"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default CapIcon;
