import { TranslationString } from 'shared/entities/localization';

export type PaymentMethodServer = {
  _id: string;
  title: string;
  type: string;
  date_created: string;
};

export type PriceServer = {
  max_users_count: number | null;
  price: number;
  is_profitable: boolean;
};

export type BillingServer = {
  balance: number;
  trial_until: string;
};

export type PaymentInfoServer = {
  methods: PaymentMethodServer[];
  billing: BillingServer;
  days_left: number;
  day_price: number;
  month_price: number;
  should_pay: boolean;
  users_count: number;
  rounded_users_count: number;
  next_payment_date: string | null;
  is_profitable: boolean;
  minimal_payment: number;
};

export type PaymentPromoServer = {
  original_price: number;
  price_to_pay: number;
  discount_per_months: {
    discount: number;
    number_of_months: number;
  } | null;
  discount_for_promocode: {
    discount: number;
    promocode: string;
  } | null;
};

export type PaymentParams = (
  | {
      amount: number;
    }
  | {
      number_of_months: number;
    }
  | {}
) & {
  promocode?: string;
};

export enum CalculatePayError {
  notValid = 'promocode_not_valid',
  notFound = 'promocode_not_found',
  alreadyUsed = 'promocode_already_used_for_cabinet',
  amountTooLow = 'amount_too_low',
  shouldPayAsLegalEntity = 'should_pay_as_legal_entity'
}

export const mapCalculatePayErrorToMessage =
  (code: CalculatePayError): TranslationString =>
  (t) =>
    t(`payment.errors.calculatePay.${code}`, {
      ns: 'entities'
    });

export enum PaymentAddition {
  month = 'month',
  sum = 'sum'
}

export const availableMonthPayments: { count: number; sale: string }[] = [
  { count: 1, sale: '' },
  { count: 3, sale: '-5%' },
  { count: 6, sale: '-10%' },
  { count: 12, sale: '-20%' }
];
export const getAvailableSumPayments = (minimalPayment: number): number[] => {
  const availableSumPayments = [
    1500, 3000, 5000, 10000, 15000, 30000, 50000, 100000
  ];

  const sums = availableSumPayments.reduce<number[]>(
    (acc, sum) => {
      if (sum > minimalPayment) {
        acc.push(sum);
      }

      return acc;
    },
    [minimalPayment]
  );

  return sums.slice(0, 6);
};
