import {
  InvoiceStatus,
  PaymentDocumentSubject,
  ServiceAndSetupInvoiceDocumentServer,
  ICommonDocument,
  normalizeCommonDocument
} from 'shared/entities/documents';

import { BaseInvoiceDocumentModel } from './BaseInvoiceDocumentModel';
import { SetupDocumentParamsTableModel } from './SetupDocumentParamsTableModel';
import { ServiceDocumentCommonParamsTableModel } from './ServiceDocumentCommonParamsTableModel';

export class ServiceAndSetupInvoiceDocumentModel extends BaseInvoiceDocumentModel {
  readonly subject: PaymentDocumentSubject.serviceAndSetup =
    PaymentDocumentSubject.serviceAndSetup;
  readonly params: {
    setup: SetupDocumentParamsTableModel;
    service: ServiceDocumentCommonParamsTableModel;
  };

  constructor({
    service,
    setup,
    ...rest
  }: ICommonDocument & {
    setup: SetupDocumentParamsTableModel;
    service: ServiceDocumentCommonParamsTableModel;
    status: InvoiceStatus;
    cabinetId: string;
  }) {
    super(rest);
    this.params = {
      service,
      setup
    };
  }

  static fromJson(
    raw: ServiceAndSetupInvoiceDocumentServer,
    cabinetId: string
  ): ServiceAndSetupInvoiceDocumentModel {
    return new ServiceAndSetupInvoiceDocumentModel({
      ...normalizeCommonDocument(raw),
      status: raw.status,
      cabinetId,
      setup: SetupDocumentParamsTableModel.fromJson(raw.params.setup),
      service: ServiceDocumentCommonParamsTableModel.fromJson(
        raw.params.service
      )
    });
  }
}
