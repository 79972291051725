import { createContext } from 'react';

import { DocumentsStore } from './DocumentsStore';

const DocumentsStoreStoreContext = createContext<{
  store: DocumentsStore | null;
}>({
  store: null
});

const DocumentsStoreProvider = DocumentsStoreStoreContext.Provider;

export { DocumentsStoreStoreContext, DocumentsStoreProvider };
