import * as React from 'react';
import cn from 'classnames';

import './NotificationCircle.modules.scss';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { FolderIcon, CheckVerifiedIcon } from 'shared/newComponents/icons';
import { IconSize } from 'shared/entities/components/Icon';

const mapNotificationTypeToIcon = {
  [AppNotificationType.success]: CheckVerifiedIcon,
  [AppNotificationType.warning]: FolderIcon,
  [AppNotificationType.error]: FolderIcon
};

type NotificationCircleProps = {
  type: AppNotificationType;
  className?: string;
};

const NotificationCircle: React.FC<NotificationCircleProps> = ({
  type,
  className
}: NotificationCircleProps) => {
  const Icon = mapNotificationTypeToIcon[type];
  return (
    <div
      styleName={cn('notification-circle', `notification-circle_${type}`)}
      className={className}
    >
      <Icon iconSize={IconSize.XM} />
    </div>
  );
};

export default NotificationCircle;
