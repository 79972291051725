import * as React from 'react';
import cn from 'classnames';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import './PointerArrowIcon.modules.scss';

type Props = CommonIconProps & {
  direction?: 'left' | 'right' | 'top' | 'bottom';
};

const PointerArrowIcon: React.FC<Props> = ({
  direction = 'right',
  ...rest
}: Props) => {
  return (
    <BaseIcon
      iconSize={IconSize.XM}
      svgProps={{
        viewBox: '0 0 20 20'
      }}
      {...rest}
      styleName={cn('arrow-icon', `arrow-icon_${direction}`)}
    >
      <path
        d="M4.66663 10H16.3333M16.3333 10L10.5 4.16667M16.3333 10L10.5 15.8333"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default PointerArrowIcon;
