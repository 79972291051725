import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const XIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M5.53287 3.11888H6.24335C6.55244 3.11888 6.80279 2.86853 6.80279 2.55944C6.80279 2.25035 6.55244 2 6.24335 2H5.53287C3.58462 2 2 3.58462 2 5.53287V18.4671C2 20.4154 3.58462 22 5.53287 22H6.24335C6.55244 22 6.80279 21.7497 6.80279 21.4406C6.80279 21.1315 6.55244 20.8811 6.24335 20.8811H5.53287C4.2021 20.8811 3.11888 19.7979 3.11888 18.4671V5.53287C3.11888 4.2014 4.2014 3.11888 5.53287 3.11888Z"
        fill="currentColor"
      />
      <path
        d="M18.2738 2H17.5633C17.2543 2 17.0039 2.25035 17.0039 2.55944C17.0039 2.86853 17.2543 3.11888 17.5633 3.11888H18.2738C19.6046 3.11888 20.6878 4.2021 20.6878 5.53287V18.4671C20.6878 19.7979 19.6046 20.8811 18.2738 20.8811H17.5633C17.2543 20.8811 17.0039 21.1315 17.0039 21.4406C17.0039 21.7497 17.2543 22 17.5633 22H18.2738C20.2221 22 21.8067 20.4154 21.8067 18.4671V5.53287C21.8067 3.58462 20.2214 2 18.2738 2Z"
        fill="currentColor"
      />
      <path
        d="M15.4184 8.4632C15.2002 8.24502 14.8457 8.24502 14.6275 8.4632L11.9016 11.1891L9.17574 8.4632C8.95756 8.24502 8.60301 8.24502 8.38483 8.4632C8.16665 8.68138 8.16665 9.03592 8.38483 9.25411L11.11 11.9793L8.38483 14.7045C8.16665 14.9226 8.16665 15.2772 8.38483 15.4954C8.49392 15.6045 8.63729 15.659 8.78064 15.659C8.924 15.659 9.06665 15.6045 9.17644 15.4954L11.9023 12.7695L14.6282 15.4954C14.7373 15.6045 14.8806 15.659 15.024 15.659C15.1674 15.659 15.31 15.6045 15.4198 15.4954C15.638 15.2772 15.638 14.9226 15.4198 14.7045L12.6946 11.9793L15.4198 9.25411C15.6366 9.03592 15.6366 8.68138 15.4184 8.4632Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default XIcon;
