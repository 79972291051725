import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const PauseFilledIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 25' }}
      {...props}
    >
      <rect y="0.666748" width="24" height="24" rx="12" fill="#168EE0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5714 17.8096C10.5714 18.0369 10.4811 18.255 10.3204 18.4157C10.1596 18.5764 9.94161 18.6667 9.71429 18.6667H8.85714C8.62981 18.6667 8.4118 18.5764 8.25105 18.4157C8.09031 18.255 8 18.0369 8 17.8096V7.52389C8 7.29656 8.09031 7.07855 8.25105 6.9178C8.4118 6.75705 8.62981 6.66675 8.85714 6.66675H9.71429C9.94161 6.66675 10.1596 6.75705 10.3204 6.9178C10.4811 7.07855 10.5714 7.29656 10.5714 7.52389V17.8096ZM16.5714 17.8096C16.5714 18.0369 16.4811 18.255 16.3204 18.4157C16.1596 18.5764 15.9416 18.6667 15.7143 18.6667H14.8571C14.6298 18.6667 14.4118 18.5764 14.2511 18.4157C14.0903 18.255 14 18.0369 14 17.8096V7.52389C14 7.29656 14.0903 7.07855 14.2511 6.9178C14.4118 6.75705 14.6298 6.66675 14.8571 6.66675H15.7143C15.9416 6.66675 16.1596 6.75705 16.3204 6.9178C16.4811 7.07855 16.5714 7.29656 16.5714 7.52389V17.8096Z"
        fill="white"
      />
    </BaseIcon>
  );
};

export default PauseFilledIcon;
