import { TranslationString } from 'shared/entities/localization';

export enum Permission {
  DELETE_CABINET = 'delete_cabinet',
  CREATE_CABINET = 'create_cabinet',
  EDIT_CABINET = 'edit_cabinet',
  CREATE_PROJECT = 'create_project',
  DELETE_PROJECT = 'delete_project',
  VIEW_PROJECT = 'view_project',
  EDIT_PROJECT = 'edit_project',
  ACCESS_BILLING = 'access_billing',
  EDIT_MANAGERS = 'edit_managers',
  VIEW_MANAGERS = 'view_managers',
  PUBLISH_SCENARIOS = 'publish_scenarios',

  SA_LIST_ALL_PROJECTS = 'sa_list_all_projects',
  SA_SEARCH_PROJECTS = 'sa_search_projects',
  SA_CHECK_LIST = 'sa_check_list',
  SA_VERIFY_DOMAIN = 'sa_verify_domain',
  SA_VIEW_UNPUBLISHED_FEATURES = 'sa_view_unpublished_features',
  SA_MANAGE_TEMPLATES = 'sa_manage_templates'
}

export const permissionEntities: Record<Permission, TranslationString> = {
  [Permission.DELETE_CABINET]: (t) =>
    t('permissions.deleteCabinet', {
      ns: 'entities'
    }),
  [Permission.CREATE_CABINET]: (t) =>
    t('permissions.createCabinet', {
      ns: 'entities'
    }),
  [Permission.EDIT_CABINET]: (t) =>
    t('permissions.editCabinet', {
      ns: 'entities'
    }),
  [Permission.CREATE_PROJECT]: (t) =>
    t('permissions.createProject', {
      ns: 'entities'
    }),
  [Permission.DELETE_PROJECT]: (t) =>
    t('permissions.deleteProject', {
      ns: 'entities'
    }),
  [Permission.VIEW_PROJECT]: (t) =>
    t('permissions.viewProject', {
      ns: 'entities'
    }),
  [Permission.EDIT_PROJECT]: (t) =>
    t('permissions.editProject', {
      ns: 'entities'
    }),
  [Permission.ACCESS_BILLING]: (t) =>
    t('permissions.accessBilling', {
      ns: 'entities'
    }),
  [Permission.EDIT_MANAGERS]: (t) =>
    t('permissions.editManagers', {
      ns: 'entities'
    }),
  [Permission.VIEW_MANAGERS]: (t) =>
    t('permissions.viewManagers', {
      ns: 'entities'
    }),
  [Permission.PUBLISH_SCENARIOS]: (t) =>
    t('permissions.publishScenarios', {
      ns: 'entities'
    }),
  [Permission.SA_LIST_ALL_PROJECTS]: (t) =>
    t('permissions.saListAllProjects', {
      ns: 'entities'
    }),
  [Permission.SA_SEARCH_PROJECTS]: (t) =>
    t('permissions.saSearchProjects', {
      ns: 'entities'
    }),
  [Permission.SA_CHECK_LIST]: (t) =>
    t('permissions.saCheckList', {
      ns: 'entities'
    }),
  [Permission.SA_VERIFY_DOMAIN]: (t) =>
    t('permissions.saVerifyDomain', {
      ns: 'entities'
    }),
  [Permission.SA_MANAGE_TEMPLATES]: (t) =>
    t('permissions.saManageTemplates', {
      ns: 'entities'
    }),
  [Permission.SA_VIEW_UNPUBLISHED_FEATURES]: (t) =>
    t('permissions.saViewUnpublishedFeatures', {
      ns: 'entities'
    })
};
