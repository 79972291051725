import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction
} from 'mobx';

import {
  ChannelModelType,
  CommonChannelModel,
  TgChannelModel
} from 'shared/models/channel';
import {
  ChannelKind,
  ChannelServer,
  ChannelTokenStatus,
  OpenedChannelKind,
  openedChannelKindOrder
} from 'shared/entities/channels';
import { apiUrls, urls } from 'shared/entities/domain';
import { IChannelsStore } from 'shared/entities/store/channelsStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStage } from 'shared/entities/meta';
import { mapErrorCodeToMessage } from 'shared/entities/common/utils';
import { localStorageHandler } from 'stores/localStorageHandler';
import { LocalStorageKey } from 'shared/entities/localStorage';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { IList } from 'shared/entities/list';
import ListModel from 'shared/models/ListModel';
import { AnalyticsEvent } from 'shared/entities/analytics';
import { TranslationString } from 'shared/entities/localization';
import { OpenStateModel } from 'shared/models/openState';

import { ComponentLoadingStore } from '../componentLoadingStore';

const CHANNEL_BANNER_ID = 'CHANNEL_BANNER_ID';

export default class ChannelsStore
  extends ComponentLoadingStore
  implements IChannelsStore
{
  rootStore: IRootStore;

  channels: IList<ChannelModelType> = new ListModel();

  selectedChannelId: string | null = null;

  readonly channelsModalOpened: OpenStateModel = new OpenStateModel();

  constructor(rootStore: IRootStore) {
    super();

    makeObservable(this, {
      channels: observable,
      selectedChannelId: observable,
      rootStore: observable,

      unlinkedSocialNetworks: computed,
      hasChannels: computed,
      selectedChannel: computed,
      linkedState: computed,
      channelWithTokenError: computed,
      channelCountWithTokenError: computed,
      modalOpened: computed,

      createChannel: action,
      changeSelectedChannelId: action,
      unselectedSelectedChannelId: action,
      remove: action.bound,
      addChannel: action
    });
    this.rootStore = rootStore;
  }

  get channelCountWithTokenError(): number {
    return this.channels.items.reduce(
      (acc, channel) => (channel.hasTokenErrors ? acc + 1 : acc),
      0
    );
  }

  get channelWithTokenError(): CommonChannelModel | TgChannelModel | null {
    return (
      this.channels.items.find((channel) =>
        channel.tokens.items.some(
          (token) => token.status !== ChannelTokenStatus.ok
        )
      ) || null
    );
  }

  get loaded(): boolean {
    return this.channels.loadingStage === LoadingStage.SUCCESS;
  }

  get canBeLoaded(): boolean {
    return this.rootStore.initialized;
  }

  get modalOpened(): boolean {
    return this.channelsModalOpened.opened;
  }

  openModal = (): void => {
    this.channelsModalOpened.open();
  };

  closeModal = (): void => {
    this.channelsModalOpened.close();
  };

  initReactions = (): void => {
    super.initReactions();

    this.addReaction({
      key: 'channels',
      reaction: reaction(
        () => this.unlinkedSocialNetworks,
        this.handleOpenBanner
      )
    });

    this.addReaction({
      key: 'channelCountWithTokenError',
      reaction: reaction(
        () => this.channelCountWithTokenError,
        this.handleOpenBanner
      )
    });

    this.addReaction({
      key: 'channelWithTokenError',
      reaction: reaction(
        () => this.channelWithTokenError,
        this.handleOpenBanner
      )
    });

    this.addReaction({
      key: 'channelsLoadingStage',
      reaction: reaction(
        () => this.channels.loadingStage,
        () => {
          if (this.channels.loadingStage === LoadingStage.SUCCESS) {
            this.handleOpenBanner();
          }
        }
      )
    });
  };

  get linkedState(): Record<ChannelKind, boolean> {
    return this.channels.items.reduce<Record<ChannelKind, boolean>>(
      (acc, channel) => {
        if (
          !openedChannelKindOrder.includes(channel.kind as OpenedChannelKind)
        ) {
          return acc;
        }
        if (acc[channel.kind] === undefined) {
          return {
            ...acc,
            [channel.kind]: channel.linked
          };
        }

        return acc;
      },
      {} as Record<ChannelKind, boolean>
    );
  }

  get unlinkedSocialNetworks(): number {
    return Object.values(this.linkedState).reduce(
      (acc, linked) => (!linked ? acc + 1 : acc),
      0
    );
  }

  handleCloseBanner = () => {
    localStorageHandler.setItem(LocalStorageKey.bannerWasClosed, 'true');
  };

  handleOpenBanner = () => {
    if (!this.unlinkedSocialNetworks && !this.channelCountWithTokenError) {
      this.rootStore.appNotificationsStore.closeBanner(CHANNEL_BANNER_ID);
      return;
    }

    if (
      !this.rootStore.projectId ||
      !this.rootStore.permissionsStore.canViewProject ||
      !this.rootStore.permissionsStore.canEditProject
    ) {
      return;
    }

    if (this.channelCountWithTokenError && this.channelWithTokenError) {
      const error: TranslationString =
        this.channelCountWithTokenError > 1
          ? (t) =>
              t('channelsStore.tokensError', {
                ns: 'stores',
                count: this.channelCountWithTokenError
              })
          : (t) =>
              t('channelsStore.tokenError', {
                ns: 'stores',
                channel: this.channelWithTokenError?.name
              });

      this.rootStore.appNotificationsStore.openBanner({
        id: CHANNEL_BANNER_ID,
        type: AppNotificationType.error,
        title: error,
        onClose: this.handleCloseBanner,
        link: {
          title: (t) =>
            t('channelsStore.goToChannels', {
              ns: 'stores'
            }),
          onClick: this.rootStore.channelsStore.openModal
        }
      });

      return;
    }

    const bannerWasClosed = localStorageHandler.getItem(
      LocalStorageKey.bannerWasClosed
    );

    if (bannerWasClosed === 'true' || !this.channels.length) {
      return;
    }

    this.rootStore.appNotificationsStore.openBanner({
      id: CHANNEL_BANNER_ID,
      type: AppNotificationType.warning,
      title: (t) =>
        t('channelsStore.unlinkedSocialNetworks.error', {
          ns: 'stores',
          count: this.unlinkedSocialNetworks
        }),
      onClose: this.handleCloseBanner,
      link: {
        title: (t) =>
          t('channelsStore.link', {
            ns: 'stores'
          }),
        to: urls.SETTINGS.PROFILE.create()
      }
    });
  };

  get selectedChannel(): ChannelModelType | null {
    return this.selectedChannelId
      ? this.channels.entities[this.selectedChannelId]
      : null;
  }

  changeSelectedChannelId = (channelId: string | null): void => {
    this.selectedChannelId = channelId;
  };

  unselectedSelectedChannelId = (): void => {
    this.changeSelectedChannelId(null);
  };

  get hasChannels(): boolean {
    return !!this.channels.items.length;
  }

  addChannel = (channel: CommonChannelModel): void => {
    this.channels.addEntity({ entity: channel, key: channel.id });
  };

  remove(id: string): void {
    this.channels.removeEntity(id);
  }

  async load(): Promise<BaseResponse> {
    if (
      !this.canBeLoaded ||
      this.channels.loadingStage === LoadingStage.LOADING
    ) {
      return {
        isError: true
      };
    }

    this.channels.setLoadingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      channels: Array<ChannelServer>;
    }>(apiUrls.CHANNELS_LIST);

    if (!response.isError) {
      const { entities, keys } = response.data.channels.reduce(
        (acc, channel) => ({
          ...acc,
          entities: {
            ...acc.entities,
            [channel._id]:
              channel.kind === ChannelKind.TELEGRAM
                ? TgChannelModel.fromJson(channel, this.rootStore)
                : CommonChannelModel.fromJson(channel, this.rootStore)
          },
          keys: [...acc.keys, channel._id]
        }),
        {
          entities: {},
          keys: []
        }
      );

      runInAction(() => {
        this.channels.addEntities({ entities, keys, initial: true });
        this.channels.setLoadingStage(LoadingStage.SUCCESS);
      });
    } else {
      runInAction(() => {
        this.channels.setLoadingStage(LoadingStage.ERROR);
      });
    }

    return {
      isError: response.isError
    };
  }

  addCreatedChannel(channel: ChannelModelType) {
    this.channels.addEntity({ entity: channel, key: channel.id });

    this.rootStore.analyticsStore.sendEvent(AnalyticsEvent.channelCreationOk, {
      created_channel_kind: channel.kind
    });
  }

  async createChannel({
    channel,
    apiUrl,
    errorsMap,
    showExpectedError = false
  }: {
    channel: Record<string, any>;
    apiUrl: string;
    errorsMap: (code: string) => TranslationString;
    showExpectedError?: boolean;
  }): Promise<BaseResponse<ChannelModelType, TranslationString | null>> {
    if (this.channels.creatingStage === LoadingStage.LOADING) {
      return {
        isError: true,
        data: null
      };
    }
    this.channels.setCreatingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      channel: ChannelServer;
    }>(apiUrl, {
      method: 'POST',
      data: channel,
      errorsMap,
      showExpectedError
    });

    if (!response.isError) {
      const newChannel =
        response.data.channel.kind === ChannelKind.TELEGRAM
          ? TgChannelModel.fromJson(response.data.channel, this.rootStore)
          : CommonChannelModel.fromJson(response.data.channel, this.rootStore);

      runInAction(() => {
        this.channels.addEntity({ entity: newChannel, key: newChannel.id });
        this.channels.setCreatingStage(LoadingStage.SUCCESS);
      });

      this.rootStore.analyticsStore.sendEvent(
        AnalyticsEvent.channelCreationOk,
        {
          created_channel_kind: newChannel.kind
        }
      );

      return {
        isError: false,
        data: newChannel
      };
    } else {
      runInAction(() => {
        this.channels.setCreatingStage(LoadingStage.ERROR);
      });

      return {
        isError: true,
        data: mapErrorCodeToMessage({
          errorCode: response.data?.code || null,
          map: errorsMap
        })
      };
    }
  }

  reset(): void {
    this.channels.reset();
  }
}
