export enum ProjectSettingsTypes {
  rootChat = 'root_chat',
  allChats = 'all_chats'
}

export enum ProjectEnableForSetting {
  managers = 'managers',
  all = 'all'
}

export enum SettingsType {
  likeThreshold = 'like_threshold',
  useUnpublished = 'use_unpublished',
  debugMode = 'debug_mode',
  timezone = 'timezone',
  botEnabled = 'bot_enabled',
  eventsQueueLockMode = 'events_queue_lock_mode',
  unstopByManagerDelay = 'unstop_by_manager_delay'
}
