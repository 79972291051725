import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const ReturnIconWithoutBorder: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M4 11.2L9.58814 4V7.99999C19.1304 7.99999 20.2312 15.7424 19.9663 20C19.5654 17.8519 19.3794 14.3999 9.58814 14.3999V18.4L4 11.2Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default ReturnIconWithoutBorder;
