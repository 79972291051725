import { action, makeObservable, observable } from 'mobx';

import {
  IJob,
  JobKind,
  JobServer,
  JobStatus,
  JobListener,
  IJobResult,
  IJobParams
} from 'shared/entities/jobs';
import { generateId } from 'shared/entities/common/utils';
import { apiUrls } from 'shared/entities/domain';
import { IJobsStore } from 'shared/entities/store/jobsStore';
import { IRootStore } from 'shared/entities/store/rootStore';

const POLLING_INTERVAL = 5000;

export default class JobModel implements IJob {
  key: string | null;
  kind: JobKind;
  progress: number;
  result: IJobResult | null;
  params: IJobParams | null;
  startedAt: Date | null;
  finishedAt: Date | null;
  dateCreated: Date | null;
  status: JobStatus;
  traceId: string | null;

  pollingInterval: ReturnType<typeof setInterval> | null = null;

  jobsStore: IJobsStore;
  rootStore: IRootStore;

  listeners: Map<string, JobListener> = new Map<string, JobListener>();

  constructor({
    key,
    kind,
    progress = 0,
    result = null,
    params = null,
    startedAt = null,
    finishedAt = null,
    dateCreated = null,
    status,
    jobsStore,
    traceId = null,
    rootStore
  }: {
    kind: JobKind;
    key: string | null;
    progress?: number;
    result?: IJobResult | null;
    params?: IJobParams | null;
    startedAt?: Date | null;
    finishedAt?: Date | null;
    dateCreated?: Date | null;
    status: JobStatus;
    jobsStore: IJobsStore;
    rootStore: IRootStore;
    traceId?: string | null;
  }) {
    this.key = key;
    this.kind = kind;
    this.progress = progress;
    this.result = result;
    this.startedAt = startedAt;
    this.finishedAt = finishedAt;
    this.dateCreated = dateCreated;
    this.status = status;
    this.jobsStore = jobsStore;
    this.traceId = traceId;
    this.params = params;
    this.rootStore = rootStore;

    makeObservable(this, {
      progress: observable,
      result: observable,
      params: observable,
      startedAt: observable,
      finishedAt: observable,
      dateCreated: observable,
      jobsStore: observable,
      status: observable,
      stopPolling: action,
      startPolling: action,
      updateStatus: action,
      traceId: observable
    });
  }

  startPolling(): void {
    if (!this.pollingInterval) {
      this.pollingInterval = setInterval(this.handler, POLLING_INTERVAL);
    }
  }

  stopPolling(): void {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
  }

  update(raw: JobServer): void {
    this.progress = raw.progress;
    this.result = raw.result;
    this.startedAt = new Date(raw.started_at);
    this.finishedAt = raw.finished_at ? new Date(raw.finished_at) : null;
    this.dateCreated = raw.date_created ? new Date(raw.date_created) : null;
    this.status = raw.status as JobStatus;
    this.traceId = raw.trace_id;
  }

  updateStatus(status: JobStatus): void {
    this.status = status;
  }

  callListeners(): void {
    Array.from(this.listeners.values()).forEach((listener) =>
      listener({
        status: this.status,
        result: this.result,
        error: this.traceId,
        progress: this.progress,
        params: this.params
      })
    );
  }

  handler = async (): Promise<void> => {
    const status = await this.getStatus();
    this.callListeners();

    if (!status) {
      return;
    }

    if (
      status === JobStatus.finished ||
      status === JobStatus.failed ||
      status === JobStatus.cancelled
    ) {
      this.stopPolling();
    }
  };

  async getStatus(): Promise<JobStatus | null> {
    const response = await this.rootStore.networkStore.api<JobServer>(
      apiUrls.JOBS_STATUS,
      {
        method: 'GET',
        data: {
          kind: this.kind,
          key: this.key
        }
      }
    );

    if (!response.isError) {
      this.update(response.data);

      return response.data.status;
    }

    return null;
  }

  addListener(jobListener: JobListener): string {
    const id = generateId();

    this.listeners.set(id, jobListener);
    jobListener({
      status: this.status,
      result: this.result,
      error: this.traceId,
      progress: this.progress,
      params: this.params
    });

    return id;
  }

  removeListener(id: string): void {
    this.listeners.delete(id);
  }

  static async getJob({
    kind,
    key,
    jobsStore,
    rootStore
  }: {
    kind: JobKind;
    key: string;
    jobsStore: IJobsStore;
    rootStore: IRootStore;
  }): Promise<JobModel | null> {
    const response = await rootStore.networkStore.api<JobServer>(
      apiUrls.JOBS_STATUS,
      {
        method: 'GET',
        data: {
          kind,
          key
        }
      }
    );

    if (!response.isError && response.data.status) {
      return new JobModel({
        rootStore,
        kind,
        key,
        progress: response.data.progress,
        result: response.data.result,
        params: response.data.params,
        startedAt: new Date(response.data.started_at),
        finishedAt: response.data.finished_at
          ? new Date(response.data.finished_at)
          : null,
        dateCreated: response.data.date_created
          ? new Date(response.data.date_created)
          : null,
        status: response.data.status,
        traceId: response.data.trace_id,
        jobsStore
      });
    }

    return null;
  }
}
