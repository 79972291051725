const PATTERN_URL_WITHOUT_PROTOCOL =
  '([-a-zA-Z0-9а-яА-Яё_]{1,63}\\.)+[-a-zA-Z0-9а-яА-Яё]{2,63}(/[-a-zA-Z0-9а-яА-Яё()@:%_+.~#?&//=]*)?';
const PATTERN_URL_WITH_OPTIONAL_PROTOCOL = `(\\w+://){0,1}${PATTERN_URL_WITHOUT_PROTOCOL}`;
const PATTERN_URL_WITH_OPTIONAL_PROTOCOL_WORD = `^${PATTERN_URL_WITH_OPTIONAL_PROTOCOL}$`;
const PATTERN_URL_WITH_REQUIRED_PROTOCOL = `(\\w+://)${PATTERN_URL_WITHOUT_PROTOCOL}`;
const PATTERN_URL_WITH_REQUIRED_PROTOCOL_WORD = `^(\\w+://)${PATTERN_URL_WITHOUT_PROTOCOL}$`;

export const REG_EXP_URL_WITH_OPTIONAL_PROTOCOL = new RegExp(
  PATTERN_URL_WITH_OPTIONAL_PROTOCOL
);

export const REG_EXP_URL_WITH_REQUIRED_PROTOCOL = new RegExp(
  PATTERN_URL_WITH_REQUIRED_PROTOCOL
);

export const REG_EXP_URL_WITH_OPTIONAL_PROTOCOL_GLOBALLY = new RegExp(
  REG_EXP_URL_WITH_OPTIONAL_PROTOCOL,
  'g'
);
export const REG_EXP_URL_WITH_OPTIONAL_PROTOCOL_WORD = new RegExp(
  PATTERN_URL_WITH_OPTIONAL_PROTOCOL_WORD
);

export const REG_EXP_URL_WITH_REQUIRED_PROTOCOL_WORD = new RegExp(
  PATTERN_URL_WITH_REQUIRED_PROTOCOL_WORD
);

export const REG_EXP_CIRILLICA = '[а-яА-Яё]+';
