import {
  ICommonUserPaymentMethodModel,
  IUserPaymentMethod,
  UserPaymentCommonMethodServer,
  UserPaymentMethodKind
} from 'shared/entities/userPayment';
import { IRootStore } from 'shared/entities/store/rootStore';

import { UserPaymentMethodModel } from './UserPaymentMethodModel';

export class CommonUserPaymentMethodModel
  extends UserPaymentMethodModel
  implements ICommonUserPaymentMethodModel
{
  readonly providerKind:
    | UserPaymentMethodKind.qiwi
    | UserPaymentMethodKind.prodamus
    | UserPaymentMethodKind.yookassa;

  constructor(
    params: Omit<IUserPaymentMethod, 'providerKind'> & {
      providerKind:
        | UserPaymentMethodKind.qiwi
        | UserPaymentMethodKind.prodamus
        | UserPaymentMethodKind.yookassa;
    },
    rootStore: IRootStore
  ) {
    super(params, rootStore);

    this.providerKind = params.providerKind;
  }

  static fromJson(
    raw: UserPaymentCommonMethodServer,
    rootStore: IRootStore
  ): CommonUserPaymentMethodModel {
    return new CommonUserPaymentMethodModel(
      {
        id: raw._id,
        externalId: raw.external_id,
        title: raw.verbose_name,
        providerKind: raw.provider_kind,
        dateCreated: new Date(raw.date_created),
        isEnabled: raw.is_enabled,
        isTest: raw.is_test,
        vatCode: raw.vat_code
      },
      rootStore
    );
  }
}
