import * as React from 'react';

import {
  CommonIconProps,
  LogoIconProps,
  IconSize
} from 'shared/entities/components/Icon';
import BaseIcon from 'shared/newComponents/icons/BaseIcon';

const AvitoFullIcon: React.FC<LogoIconProps> = ({
  color,
  ...rest
}: LogoIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXXXL}
      svgProps={{
        viewBox: '0 0 160 160'
      }}
      {...rest}
    >
      <rect width="160" height="160" />
      <g clipPath="url(#clip0_6301_49)">
        <path
          d="M92.5652 84.1169L87.6152 69.8945H80.8516L89.7539 93.9128H95.3538L104.249 69.8945H97.5L92.5652 84.1169Z"
          fill={color === 'original' ? '#000' : 'currentColor'}
        />
        <path
          d="M124.52 84.6535V75.9285H130.264V69.8956H124.52V64.6094H118.127V69.8956H114.363V75.9285H118.127V86.1164C118.127 91.8929 121.512 94.3512 125.812 94.3512C127.573 94.3512 129.002 94.0571 130.256 93.5217V87.5417C130.256 87.5417 129.175 87.9715 127.868 87.9715C126.1 87.9715 124.52 87.2099 124.52 84.6535Z"
          fill={color === 'original' ? '#000' : 'currentColor'}
        />
        <path
          d="M143.602 69.4648C136.725 69.4648 131.133 75.0452 131.133 81.9076C131.133 88.7699 136.725 94.3503 143.602 94.3503C150.479 94.3503 156.071 88.7699 156.071 81.9076C156.071 75.0452 150.479 69.4648 143.602 69.4648ZM143.602 87.9706C140.254 87.9706 137.526 85.2483 137.526 81.9076C137.526 78.5669 140.254 75.8446 143.602 75.8446C146.95 75.8446 149.678 78.5669 149.678 81.9076C149.678 85.2483 146.95 87.9706 143.602 87.9706Z"
          fill={color === 'original' ? '#000' : 'currentColor'}
        />
        <path
          d="M112.12 69.8936H105.727V93.9118H112.12V69.8936Z"
          fill={color === 'original' ? '#000' : 'currentColor'}
        />
        <path
          d="M108.92 58.8691C106.343 58.8691 104.25 60.958 104.25 63.5295C104.25 66.101 106.343 68.1899 108.92 68.1899C111.497 68.1899 113.591 66.101 113.591 63.5295C113.591 60.958 111.497 58.8691 108.92 58.8691Z"
          fill={color === 'original' ? '#000' : 'currentColor'}
        />
        <path
          d="M66.7439 63.5303L55 93.9131H61.945L64.0611 88.1518H75.7142L77.8227 93.9131H84.7677L73.0314 63.5303H66.7439ZM73.5 82.1189H66.2829L69.8952 72.3005L73.5 82.1189Z"
          fill={color === 'original' ? '#000' : 'currentColor'}
        />
        <path
          d="M34.3008 81.5547C34.3008 73.4627 27.7424 66.9043 19.6504 66.9043C11.5584 66.9043 5 73.4627 5 81.5547C5 89.6467 11.5584 96.2051 19.6504 96.2051C27.7349 96.2051 34.3008 89.6467 34.3008 81.5547Z"
          fill={color === 'original' ? '#00E066' : 'currentColor'}
        />
        <path
          d="M52.9999 65.8613C52.9999 59.8611 48.1387 55 42.1386 55C36.1385 55 31.2773 59.8611 31.2773 65.8613C31.2773 71.8614 36.1459 76.7225 42.1386 76.7225C48.1387 76.7225 52.9999 71.8614 52.9999 65.8613Z"
          fill={color === 'original' ? '#00AAFF' : 'currentColor'}
        />
        <path
          d="M51.4978 86.3475C51.4978 81.9703 47.9543 78.4268 43.577 78.4268C39.1997 78.4268 35.6562 81.9703 35.6562 86.3475C35.6562 90.7248 39.1997 94.2683 43.577 94.2683C47.9468 94.2757 51.4978 90.7248 51.4978 86.3475Z"
          fill={color === 'original' ? '#FF4053' : 'currentColor'}
        />
        <path
          d="M25.294 56.3848C22.5992 56.3848 20.418 58.5659 20.418 61.2608C20.418 63.9556 22.5992 66.1368 25.294 66.1368C27.9888 66.1368 30.17 63.9556 30.17 61.2608C30.17 58.5659 27.9888 56.3848 25.294 56.3848Z"
          fill={color === 'original' ? '#965EEB' : 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_6301_49">
          <rect
            width="152"
            height="42"
            fill="white"
            transform="translate(5 55)"
          />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export const AvitoFullOriginalIcon: React.FC<CommonIconProps> = (props) => (
  <AvitoFullIcon color="original" {...props} />
);

export const AvitoFullTransparentIcon: React.FC<CommonIconProps> = (props) => (
  <AvitoFullIcon color="transparent" {...props} />
);
