import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const SendDataIcon: React.FC<CommonIconProps> = (props) => {
  return (
    <BaseIcon iconSize={IconSize.L} {...props}>
      <path
        d="M18.2042 4H5.79576C4.25434 4 3 5.25381 3 6.79575V16.2043C3 17.7458 4.25434 19.0001 5.79576 19.0001H18.2042C19.7457 19.0001 21 17.7463 21 16.2043V6.79575C20.9995 5.25434 19.7457 4 18.2042 4ZM20.1597 16.2043C20.1597 17.2829 19.2823 18.1604 18.2037 18.1604H5.79576C4.71724 18.1604 3.83972 17.2829 3.83972 16.2043V6.79575C3.83972 5.71723 4.71724 4.83972 5.79576 4.83972H18.2042C19.2828 4.83972 20.1603 5.71723 20.1603 6.79575V16.2043H20.1597Z"
        fill="currentColor"
      />
      <path
        d="M8.59045 11.0393C9.82275 11.0393 10.822 10.04 10.822 8.80774C10.822 7.57544 9.82275 6.57617 8.59045 6.57617C7.35816 6.57617 6.35889 7.57544 6.35889 8.80774C6.35889 10.04 7.35816 11.0393 8.59045 11.0393ZM8.59045 7.41642C9.35775 7.41642 9.9823 8.04097 9.9823 8.80826C9.9823 9.57556 9.35775 10.2001 8.59045 10.2001C7.82316 10.2001 7.19861 9.57556 7.19861 8.80826C7.19861 8.04097 7.82316 7.41642 8.59045 7.41642Z"
        fill="currentColor"
      />
      <path
        d="M8.59104 11.4468C5.46412 11.4468 5.55177 14.0316 5.84514 15.4754C5.95746 16.028 6.44661 16.4232 7.01027 16.4232H10.1718C10.736 16.4232 11.2252 16.028 11.3369 15.4754C11.6303 14.0316 11.718 11.4468 8.59104 11.4468ZM10.5145 15.3085C10.482 15.468 10.3382 15.5835 10.1724 15.5835H7.0108C6.84495 15.5835 6.70062 15.468 6.66861 15.3085C6.36788 13.8263 6.7043 13.1383 6.93155 12.8607C7.24277 12.4802 7.80118 12.287 8.59209 12.287C9.38248 12.287 9.94143 12.4802 10.2527 12.8607C10.4789 13.1383 10.8153 13.8263 10.5145 15.3085Z"
        fill="currentColor"
      />
      <path
        d="M12.8305 7.92249H17.5503C17.7822 7.92249 17.9701 7.7346 17.9701 7.50263C17.9701 7.27065 17.7822 7.08276 17.5503 7.08276H12.8305C12.5985 7.08276 12.4106 7.27065 12.4106 7.50263C12.4106 7.7346 12.5985 7.92249 12.8305 7.92249Z"
        fill="currentColor"
      />
      <path
        d="M17.9711 10.4069C17.9711 10.1749 17.7832 9.98706 17.5513 9.98706H12.8315C12.5995 9.98706 12.4116 10.1749 12.4116 10.4069C12.4116 10.6389 12.5995 10.8268 12.8315 10.8268H17.5513C17.7832 10.8268 17.9711 10.6389 17.9711 10.4069Z"
        fill="currentColor"
      />
      <path
        d="M17.3964 16.4252C17.9664 16.3688 18.3827 15.861 18.3263 15.2911C18.2698 14.7211 17.7621 14.3048 17.1921 14.3612C16.6222 14.4176 16.2059 14.9254 16.2623 15.4954C16.3187 16.0653 16.8265 16.4816 17.3964 16.4252Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SendDataIcon;
