import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const SearchIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.32862 4.26223C7.56028 3.43926 9.00832 3 10.4896 3V3.69232L10.4897 3C12.476 3.00013 14.3809 3.78925 15.7855 5.19379C17.19 6.59833 17.9791 8.50326 17.9793 10.4896V10.4896C17.9793 11.9709 17.54 13.419 16.717 14.6506C15.8941 15.8823 14.7243 16.8423 13.3558 17.4091C11.9872 17.976 10.4813 18.1243 9.02848 17.8353C7.57563 17.5464 6.24111 16.833 5.19367 15.7856C4.14622 14.7382 3.4329 13.4036 3.14391 11.9508C2.85493 10.4979 3.00325 8.99202 3.57012 7.62347C4.13699 6.25492 5.09695 5.0852 6.32862 4.26223ZM10.4896 4.38464C12.1087 4.38476 13.6615 5.028 14.8064 6.17288C15.9513 7.31776 16.5945 8.87052 16.5946 10.4896M10.4896 4.38464C9.28215 4.38465 8.10183 4.7427 7.09789 5.41352C6.09393 6.08434 5.31143 7.03781 4.84936 8.15335C4.38729 9.26889 4.26639 10.4964 4.50195 11.6807C4.73752 12.8649 5.31896 13.9527 6.17276 14.8065C7.02655 15.6603 8.11436 16.2417 9.29861 16.4773C10.4829 16.7129 11.7104 16.592 12.8259 16.1299C13.9415 15.6678 14.8949 14.8853 15.5657 13.8814C16.2366 12.8774 16.5946 11.6971 16.5946 10.4897"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0709 15.0712C15.3413 14.8008 15.7797 14.8008 16.05 15.0712L20.7972 19.8184C21.0676 20.0887 21.0676 20.5271 20.7972 20.7974C20.5268 21.0678 20.0885 21.0678 19.8181 20.7974L15.0709 16.0503C14.8006 15.7799 14.8006 15.3416 15.0709 15.0712Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default React.memo(SearchIcon);
