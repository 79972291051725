import * as React from 'react';
import { observer } from 'mobx-react';

import { ButtonSize } from 'shared/newEntities/components/Button';
import { ComponentType } from 'shared/entities/components/Component';
import { useLocal } from 'shared/entities/common/hooks';
import { WazzupCreationStore } from 'stores/WazzupCreationStore';
import { useRootStore } from 'stores/index';
import { WazzupTokenKind } from 'shared/entities/wazzup';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { AnalyticsEvent } from 'shared/entities/analytics';
import { Button } from 'shared/newComponents/buttons';
import { Input } from 'shared/newComponents/form';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import ExternalLink from 'shared/newComponents/ExternalLink';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import { wazzupLinks } from 'shared/entities/app';
import Typography from 'shared/newComponents/Typography';

import '../Wazzup.modules.scss';

const NoCRM: React.FC = () => {
  const rootStore = useRootStore();
  const { store: modalStore } = useChannelsModalStore();
  const store = useLocal(
    () => new WazzupCreationStore({ rootStore, tokenKind: WazzupTokenKind.api })
  );

  React.useEffect(() => {
    return () => {
      store.reset();
    };
  }, []);

  const handleCreate = React.useCallback(async () => {
    const { isError } = await store.create();
    if (!isError) {
      if (modalStore.selectedViaWazzupChannelKind) {
        rootStore.analyticsStore.sendEvent(
          AnalyticsEvent.channelCreationViaWazzupOk,
          {
            created_channel_kind_via_wazzup:
              modalStore.selectedViaWazzupChannelKind
          }
        );
      }
      modalStore.unSelectChannelKind();
    }
  }, [modalStore.selectedViaWazzupChannelKind]);

  return (
    <Typography
      styleName="wazzup-case"
      fontType={TypographyType.text}
      fontSize={TypographySize.s}
      fontWeight={TypographyWeight.normal}
    >
      <TypedTransComponent
        ns="newContainers"
        i18nKey="ChannelsModal.ChannelForm.Wazzup.noCRM.instruction"
      >
        Получите ключ API по{' '}
        <ExternalLink
          fontType={TypographyType.text}
          fontSize={TypographySize.s}
          fontWeight={TypographyWeight.normal}
          fontColor={TypographyColor.link}
          underline
          href={wazzupLinks.howConnect}
        >
          инструкции
        </ExternalLink>
        .
        <br />
        1. Зайдите в&nbsp;раздел «Интеграция с&nbsp;CRM»
        <br />
        2. Из&nbsp;списка выберите API (иконка шестеренки).
        <br />
        3. В&nbsp;открывшемся окне нажмите кнопку «Подключить»
        <br />
        4. Скопируйте из&nbsp;поля ключ API и&nbsp;введите его в&nbsp;поле ниже.
      </TypedTransComponent>
      <Input
        styleName="wazzup-case__input"
        label={(t) =>
          t('ChannelsModal.ChannelForm.Wazzup.noCRM.input.label', {
            ns: 'newContainers'
          })
        }
        placeholder={(t) =>
          t('ChannelsModal.ChannelForm.Wazzup.noCRM.input.placeholder', {
            ns: 'newContainers'
          })
        }
        value={store.token.value}
        onChange={store.token.changeValue}
        error={store.token.error}
        onBlur={store.token.validate}
      />
      <Button
        styleName="wazzup-case__button"
        size={ButtonSize.m}
        type={ComponentType.button}
        onClick={handleCreate}
        loadingStage={store.creationStage.value}
        disabled={store.isError}
        fullWidth
      >
        {(t) =>
          t('ChannelsModal.ChannelForm.button', {
            ns: 'newContainers'
          })
        }
      </Button>
    </Typography>
  );
};

export default observer(NoCRM);
