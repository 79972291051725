import { FieldModel } from '../form';

export default class ProjectEditModel {
  readonly name: FieldModel;

  constructor({ name }: { name: string }) {
    this.name = new FieldModel<string>(name);
  }

  toJson(): {
    name: string;
  } {
    return {
      name: this.name.value || 'Основной проект'
    };
  }

  static fromDefaultParams(): ProjectEditModel {
    return new ProjectEditModel({
      name: ''
    });
  }
}
