import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const CartIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXLM}
      svgProps={{
        viewBox: '0 0 48 48'
      }}
      {...props}
    >
      <path
        d="M17 45C18.6569 45 20 43.6569 20 42C20 40.3431 18.6569 39 17 39C15.3431 39 14 40.3431 14 42C14 43.6569 15.3431 45 17 45Z"
        fill="currentColor"
      />
      <path
        d="M38 45C39.6569 45 41 43.6569 41 42C41 40.3431 39.6569 39 38 39C36.3431 39 35 40.3431 35 42C35 43.6569 36.3431 45 38 45Z"
        fill="currentColor"
      />
      <path
        d="M44 10.5003H10.73L9.5 4.20031C9.42987 3.85639 9.24136 3.54796 8.96729 3.3287C8.69321 3.10943 8.35092 2.99323 8 3.00031H2V6.00031H6.77L12.5 34.8003C12.5701 35.1442 12.7586 35.4527 13.0327 35.6719C13.3068 35.8912 13.6491 36.0074 14 36.0003H41V33.0003H15.23L14 27.0003H41C41.3467 27.0088 41.6857 26.8968 41.9592 26.6835C42.2327 26.4702 42.4238 26.1687 42.5 25.8303L45.5 12.3303C45.5503 12.1077 45.5491 11.8766 45.4966 11.6546C45.4441 11.4326 45.3416 11.2254 45.1969 11.049C45.0523 10.8725 44.8693 10.7314 44.6619 10.6363C44.4545 10.5412 44.2281 10.4947 44 10.5003ZM39.8 24.0003H13.43L11.33 13.5003H42.125L39.8 24.0003Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CartIcon;
