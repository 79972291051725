import * as React from 'react';
import { observer } from 'mobx-react';

import DropdownContentList from 'shared/newComponents/DropdownContentList';
import { DropdownContentItem } from 'shared/newEntities/components/DropdownContent';
import Typography from 'shared/newComponents/Typography';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import ExternalLink from 'shared/newComponents/ExternalLink';
import { documentationLinks, wazzupLinks } from 'shared/entities/app';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { channelKindEntities } from 'shared/entities/channels';
import { useUiStore } from 'stores/index';
import { useTypedTranslation } from 'shared/entities/localization';
import { ButtonSize, ButtonStyle } from 'shared/newEntities/components/Button';
import { ComponentType } from 'shared/entities/components/Component';
import { Button } from 'shared/newComponents/buttons';

import BannedChannelText from '../BannedChannelText';

import NoCRM from './NoCRM';
import WithAccountAndCRM from './WithAccountAndCRM';
import NoAccount from './NoAccount';

import './Wazzup.modules.scss';

enum WazzupCreationCaseId {
  noAccount = 'noAccount',
  noCRM = 'noCRM',
  withAccountAndCRM = 'withAccountAndCRM'
}

const Wazzup: React.FC = () => {
  const { store: modalStore } = useChannelsModalStore();
  const uiStore = useUiStore();
  const { t } = useTypedTranslation('newContainers');

  const connectedChannel =
    modalStore.selectedViaWazzupChannelKind &&
    channelKindEntities[modalStore.selectedViaWazzupChannelKind];

  const items: DropdownContentItem[] = [
    {
      id: WazzupCreationCaseId.withAccountAndCRM,
      title: (t) =>
        t('ChannelsModal.ChannelForm.Wazzup.withAccountAndCRM.title', {
          ns: 'newContainers'
        }),
      children: <WithAccountAndCRM />
    },
    {
      id: WazzupCreationCaseId.noCRM,
      title: (t) =>
        t('ChannelsModal.ChannelForm.Wazzup.noCRM.title', {
          ns: 'newContainers'
        }),
      children: <NoCRM />
    },
    {
      id: WazzupCreationCaseId.noAccount,
      title: (t) =>
        t('ChannelsModal.ChannelForm.Wazzup.noAccount.title', {
          ns: 'newContainers'
        }),
      children: <NoAccount />
    }
  ];
  return (
    <div styleName="wazzup">
      <Typography fontType={TypographyType.text} fontSize={TypographySize.s}>
        {!connectedChannel && (
          <Button
            styleName="wazzup__instruction"
            size={ButtonSize.sm}
            withoutPadding
            style={ButtonStyle.transparent}
            type={ComponentType.a}
            rel="noreferrer noopener"
            target="_blank"
            href={documentationLinks.createWazzupChannel}
          >
            {(t) =>
              t('ChannelsModal.ChannelForm.Wazzup.instruction', {
                ns: 'newContainers'
              })
            }
          </Button>
        )}
        {!uiStore.isMobileSize && connectedChannel && (
          <>
            <TypedTransComponent
              ns="newContainers"
              i18nKey="ChannelsModal.ChannelForm.Wazzup.instructionForChannel"
            >
              Интеграция с&nbsp;каналом происходит через сервис партнера{' '}
              <ExternalLink
                fontType={TypographyType.text}
                fontSize={TypographySize.s}
                fontColor={TypographyColor.link}
                underline
                href={wazzupLinks.referal}
              >
                wazzup24
              </ExternalLink>
              .
            </TypedTransComponent>
            <br />
          </>
        )}
        {t('ChannelsModal.ChannelForm.Wazzup.variants')}
      </Typography>
      <DropdownContentList
        styleName="wazzup-content"
        items={items}
        titleLimited={false}
        withModal={uiStore.isMobileSize}
      />
      {modalStore.selectedViaWazzupChannelKind && (
        <Typography fontType={TypographyType.text} fontSize={TypographySize.s}>
          {t('ChannelsModal.ChannelForm.Wazzup.howConnect.topText')}
          <ExternalLink
            fontType={TypographyType.text}
            fontSize={TypographySize.s}
            fontWeight={TypographyWeight.normal}
            fontColor={TypographyColor.link}
            href={wazzupLinks.connect[modalStore.selectedViaWazzupChannelKind]}
          >
            {t(
              `ChannelsModal.ChannelForm.Wazzup.howConnect.link.${modalStore.selectedViaWazzupChannelKind}`
            )}
          </ExternalLink>
          <br />
          {t('ChannelsModal.ChannelForm.Wazzup.howConnect.bottomText')}
        </Typography>
      )}
      {connectedChannel?.banned && (
        <BannedChannelText styleName="wazzup__banned" />
      )}
    </div>
  );
};

export default observer(Wazzup);
