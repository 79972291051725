import { LoadingStageModel } from 'shared/models/loadingStage';
import { FieldModel } from 'shared/models/form';
import {
  InvoiceStatus,
  PaymentDocumentKind,
  ICommonDocument
} from 'shared/entities/documents';

import { BaseDocumentModel } from './BaseDocumentModel';

export class BaseInvoiceDocumentModel extends BaseDocumentModel {
  readonly kind: PaymentDocumentKind.invoice = PaymentDocumentKind.invoice;
  readonly status: FieldModel<InvoiceStatus>;
  readonly confirmingStage: LoadingStageModel = new LoadingStageModel();

  constructor({
    status,
    ...rest
  }: ICommonDocument & {
    status: InvoiceStatus;
    cabinetId: string;
  }) {
    super(rest);

    this.status = new FieldModel<InvoiceStatus>(status);
  }
}
