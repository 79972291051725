import * as React from 'react';
import { observer } from 'mobx-react';

import { Input } from 'shared/newComponents/form';
import { SearchIcon } from 'shared/newComponents/icons';
import { useRootStore } from 'stores';
import { useLocalStore } from 'shared/entities/common/hooks';
import ScenariosStore from 'stores/scenariosStore/ScenariosStore';
import { ScenarioKind } from 'shared/entities/scenario';
import { ScenariosStoreProvider } from 'stores/scenariosStore';

import Content from './Content';

import './Scenarios.modules.scss';

const Scenarios: React.FC = () => {
  const rootStore = useRootStore();
  const storeCtx = useLocalStore(
    () => new ScenariosStore({ rootStore, kind: ScenarioKind.common })
  );
  const store = storeCtx.store;

  return (
    <ScenariosStoreProvider value={storeCtx}>
      <div styleName="scenarios">
        <div styleName="scenarios__input">
          <Input
            Icon={SearchIcon}
            value={store.searchText.value}
            onChange={store.searchText.changeValue}
            placeholder={(t) =>
              t(
                'ChannelsModal.SelectedChannel.Content.Scenarios.inputPlaceholder',
                {
                  ns: 'newContainers'
                }
              )
            }
          />
        </div>
        <Content styleName="scenarios__content" />
      </div>
    </ScenariosStoreProvider>
  );
};

export default observer(Scenarios);
