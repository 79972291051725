import * as React from 'react';
import { observer } from 'mobx-react';

import { useLocalStore } from 'shared/entities/common/hooks';
import {
  VKCreationStore,
  VKCreationStoreProvider
} from 'stores/VKCreationStore';
import { useRootStore } from 'stores/index';

import VKAccount from './VKAccount';
import NotConnectedStub from './NotConnectedStub';
import Groups from './Groups';

import './VK.modules.scss';

const VK: React.FC = () => {
  const rootStore = useRootStore();
  const storeCtx = useLocalStore(() => new VKCreationStore(rootStore));
  const store = storeCtx.store;

  React.useEffect(() => {
    store.init();

    return () => {
      store.reset();
    };
  }, []);

  return (
    <VKCreationStoreProvider value={storeCtx}>
      <div styleName="vk">
        <VKAccount styleName="vk__account" />
        <div styleName="vk__content">
          {!store.vkAccount && <NotConnectedStub />}
          {store.vkAccount && <Groups />}
        </div>
      </div>
    </VKCreationStoreProvider>
  );
};

export default observer(VK);
