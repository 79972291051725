import { createContext } from 'react';

import ScenariosStore from './ScenariosStore';

const ScenariosStoreContext = createContext<{ store: ScenariosStore | null }>({
  store: null
});

const ScenariosStoreProvider = ScenariosStoreContext.Provider;

export { ScenariosStoreContext, ScenariosStoreProvider };
