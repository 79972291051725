import * as React from 'react';

import WithBorder from 'shared/newComponents/WithBorder';
import { useModalState } from 'shared/entities/common/hooks';
import { TranslationString } from 'shared/entities/localization';
import CopyComponent from 'shared/newComponents/CopyComponent';
import Typography from 'shared/newComponents/Typography';
import {
  TypographyColor,
  TypographySize,
  TypographyType
} from 'shared/newEntities/components/Typography';

import WithMeta from '../WithMeta';

import './TemplateForCopy.modules.scss';

type Props = {
  value: string;
  label?: TranslationString;
  className?: string;
};

const TemplateForCopy: React.FC<Props> = ({ value, label, className }) => {
  const { opened: focused, open: focus, close: unfocus } = useModalState();

  return (
    <WithMeta label={label} className={className}>
      <div styleName="template__container">
        <WithBorder focus={focused} styleName="template__left">
          <Typography
            fontColor={TypographyColor.main}
            fontSize={TypographySize.s}
            fontType={TypographyType.text}
            onClick={focus}
            onBlur={unfocus}
            styleName="template"
          >
            {value}
          </Typography>
        </WithBorder>
        <div styleName="template__copying">
          <CopyComponent value={value} />
        </div>
      </div>
    </WithMeta>
  );
};

export default React.memo(TemplateForCopy);
