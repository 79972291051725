import ListModel from './ListModel';

/**
 * Из массива сырых данных формирует ListModel
 * @see ListModel
 * @param entitiesArray массив сырых данных
 * @param key по какому ключу из полей сырых даннных складывать преобразованные данные
 * @param creator создатель преобразованных данных,
 * на вход получает единицу сырых данных, должен вернуть преобразованные
 */
export const collectListModelByRawDataKey = <
  RawEntityT extends { [x: string | number | symbol]: any },
  EntityT,
  RawEntityKeyT extends string | number | symbol = keyof RawEntityT
>(
  entitiesArray: RawEntityT[],
  key: RawEntityKeyT,
  creator: (raw: RawEntityT) => EntityT
): ListModel<EntityT, RawEntityT[RawEntityKeyT]> => {
  const options = entitiesArray.reduce(
    (acc, item) => {
      const entity = creator(item);

      return {
        ...acc,
        entities: {
          ...acc.entities,
          [item[key]]: entity
        },
        keys: [...acc.keys, item[key]]
      };
    },
    {
      entities: {} as Record<RawEntityT[RawEntityKeyT], EntityT>,
      keys: [] as RawEntityT[RawEntityKeyT][]
    }
  );

  return new ListModel<EntityT, RawEntityT[RawEntityKeyT]>(options);
};

/**
 * Из массива сырых данных формирует ListModel
 * @see ListModel
 * @param entitiesArray массив сырых данных
 * @param key по какому ключу из полей преобразованных данных складывать преобразованные данные
 * @param creator создатель преобразованных данных,
 * на вход получает единицу сырых данных, должен вернуть преобразованные
 */
export const collectListModelByEntityKey = <
  RawEntityT extends { [x: string | number | symbol]: any },
  EntityT extends { [x: string | number | symbol]: any },
  EntityKeyT extends string | number | symbol = keyof EntityT
>(
  entitiesArray: RawEntityT[],
  key: EntityKeyT,
  creator: (raw: RawEntityT) => EntityT
): ListModel<EntityT, EntityT[EntityKeyT]> => {
  const options = entitiesArray.reduce(
    (acc, item) => {
      const entity = creator(item);

      return {
        ...acc,
        entities: {
          ...acc.entities,
          [entity[key]]: entity
        },
        keys: [...acc.keys, entity[key]]
      };
    },
    {
      entities: {} as Record<EntityT[EntityKeyT], EntityT>,
      keys: [] as EntityT[EntityKeyT][]
    }
  );

  return new ListModel<EntityT, EntityT[EntityKeyT]>(options);
};
