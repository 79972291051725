import { TranslationString } from 'shared/entities/localization';

export enum VerifiedDomainsError {
  INVALID_URL = 'invalid_url',
  DOMAIN_NOT_PASS_VERIFICATION = 'domain_not_pass_verification',
  VERIFIED_DOMAIN_NOT_FOUND = 'verified_domain_not_found',
  DOMAIN_ALREADY_ADDED = 'domain_already_added'
}

export const mapVerifiedDomainsErrorToMessage =
  (code: VerifiedDomainsError): TranslationString =>
  (t) =>
    t(`verifiedDomains.errors.${code}`, {
      ns: 'entities'
    });
