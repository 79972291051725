import { makeObservable, observable } from 'mobx';

import BaseEditStore from 'stores/editStore/BaseEditStore';
import {
  CabinetManagerRoleType,
  ICabinetManagerEdit
} from 'shared/entities/manager';
import {
  Validator,
  validateTextField,
  validateIsEmpty,
  validatePassword,
  validateName,
  validateEmailField
} from 'shared/entities/validator';
import { CabinetManagersStore } from 'stores/managers/cabinetManagersStore';
import { CabinetManagerEditModel } from 'shared/models/manager';

export default class CabinetManagerEditStore extends BaseEditStore<CabinetManagerEditModel> {
  validators: Record<keyof ICabinetManagerEdit, Validator<any>[]> = {
    email: [validateTextField, validateEmailField],
    role: [validateIsEmpty],
    name: [validateTextField, validateName],
    password: [validateTextField, validatePassword]
  };

  managersStore: CabinetManagersStore;

  constructor({
    managersStore,
    role
  }: {
    managersStore: CabinetManagersStore;
    role: CabinetManagerRoleType | null;
  }) {
    super({
      initialData: new CabinetManagerEditModel({
        name: '',
        email: '',
        password: '',
        role
      })
    });

    this.managersStore = managersStore;

    makeObservable(this, {
      managersStore: observable
    });
  }

  validateRole(): BaseResponse<{ role: CabinetManagerRoleType }> {
    const isError = this.validateField('role');

    if (!isError && this.entity.role) {
      return {
        isError: false,
        data: { role: this.entity.role }
      };
    }

    return {
      isError: true
    };
  }
}
