import { BlockKind } from 'shared/entities/block/common';
import { BlockStaticConfigMap } from 'shared/entities/block/constructors';
import { documentationLinks } from 'shared/entities/app';

export const blockStaticConfigMap: BlockStaticConfigMap = {
  [BlockKind.sendMessage]: {
    name: (t) =>
      t('blockStaticConfigMap.sendMessage', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.sendMessage
  },
  [BlockKind.chatMessage]: {
    name: (t) =>
      t('blockStaticConfigMap.chatMessage', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.chatMessage
  },
  [BlockKind.setVar]: {
    name: (t) => t('blockStaticConfigMap.setVar', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.setVar
  },
  [BlockKind.notifyOperator]: {
    name: (t) =>
      t('blockStaticConfigMap.notifyOperator', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.userInput]: {
    name: (t) =>
      t('blockStaticConfigMap.userInput', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.userInput
  },
  [BlockKind.basic]: {
    name: (t) => t('blockStaticConfigMap.basic', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.basic
  },
  [BlockKind.isGroupMember]: {
    name: (t) =>
      t('blockStaticConfigMap.isGroupMember', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.isGroupMember
  },
  [BlockKind.extractData]: {
    name: (t) =>
      t('blockStaticConfigMap.extractData', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.extractData
  },
  [BlockKind.sendStats]: {
    name: (t) =>
      t('blockStaticConfigMap.sendStats', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.sendStats
  },
  [BlockKind.executeSq]: {
    name: (t) =>
      t('blockStaticConfigMap.executeSq', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.executeSq
  },
  [BlockKind.addToList]: {
    name: (t) =>
      t('blockStaticConfigMap.addToList', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.addToList
  },
  [BlockKind.removeFromList]: {
    name: (t) =>
      t('blockStaticConfigMap.removeFromList', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.removeFromList
  },
  [BlockKind.portalOut]: {
    name: (t) =>
      t('blockStaticConfigMap.portalOut.title', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.portalOut
  },
  [BlockKind.portalIn]: {
    name: (t) =>
      t('blockStaticConfigMap.portalIn.title', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.setTimer]: {
    name: (t) => t('blockStaticConfigMap.setTimer', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.setTimer
  },
  [BlockKind.cancelEvent]: {
    name: (t) =>
      t('blockStaticConfigMap.cancelEvent', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.setTimer
  },
  [BlockKind.httpRequest]: {
    name: (t) =>
      t('blockStaticConfigMap.httpRequest', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.httpRequest
  },
  [BlockKind.conditionSwitch]: {
    name: (t) =>
      t('blockStaticConfigMap.conditionSwitch', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.conditionSwitch
  },
  [BlockKind.preFilter]: {
    name: (t) =>
      t('blockStaticConfigMap.preFilter', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.preFilter
  },
  [BlockKind.preFilterBreak]: {
    name: (t) =>
      t('blockStaticConfigMap.preFilterBreak', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.preFilter
  },
  [BlockKind.preFilterContinue]: {
    name: (t) =>
      t('blockStaticConfigMap.preFilterContinue', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.preFilter
  },
  [BlockKind.notifyManagers]: {
    name: (t) =>
      t('blockStaticConfigMap.notifyManagers', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.notifyManagers
  },
  [BlockKind.readGoogleSheets]: {
    name: (t) =>
      t('blockStaticConfigMap.readGoogleSheets', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.googleTables
  },
  [BlockKind.setCellGoogleSheets]: {
    name: (t) =>
      t('blockStaticConfigMap.setCellGoogleSheets', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.googleTables
  },
  [BlockKind.addRowGoogleSheets]: {
    name: (t) =>
      t('blockStaticConfigMap.addRowGoogleSheets', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.googleTables
  },
  [BlockKind.note]: {
    name: (t) => t('blockStaticConfigMap.note', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.sendEmail]: {
    name: (t) =>
      t('blockStaticConfigMap.sendEmail', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.mailingSend]: {
    name: (t) =>
      t('blockStaticConfigMap.mailingSend', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.defaultAnswer]: {
    name: (t) =>
      t('blockStaticConfigMap.defaultAnswer', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.firstMessage]: {
    name: (t) =>
      t('blockStaticConfigMap.firstMessage', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.messageAllow]: {
    name: (t) =>
      t('blockStaticConfigMap.messageAllow', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.messageDeny]: {
    name: (t) =>
      t('blockStaticConfigMap.messageDeny', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.groupJoin]: {
    name: (t) =>
      t('blockStaticConfigMap.groupJoin', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.groupLeave]: {
    name: (t) =>
      t('blockStaticConfigMap.groupLeave', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.likeAdd]: {
    name: (t) => t('blockStaticConfigMap.likeAdd', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.likeRemove]: {
    name: (t) =>
      t('blockStaticConfigMap.likeRemove', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.wallRepost]: {
    name: (t) =>
      t('blockStaticConfigMap.wallRepost', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.vkMarketItemMessage]: {
    name: (t) =>
      t('blockStaticConfigMap.vkMarketItemMessage', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.webhook]: {
    name: (t) => t('blockStaticConfigMap.webhook', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.webhook
  },
  [BlockKind.randomChoice]: {
    name: (t) =>
      t('blockStaticConfigMap.randomChoice', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.getCourseUserImport]: {
    name: (t) =>
      t('blockStaticConfigMap.getCourseUserImport', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.getCourseDealImport]: {
    name: (t) =>
      t('blockStaticConfigMap.getCourseDealImport', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.commentAdd]: {
    name: (t) =>
      t('blockStaticConfigMap.commentAdd', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.commentRemove]: {
    name: (t) =>
      t('blockStaticConfigMap.commentRemove', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.switchContext]: {
    name: (t) =>
      t('blockStaticConfigMap.switchContext', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.switchContext
  },
  [BlockKind.webAppData]: {
    name: (t) =>
      t('blockStaticConfigMap.webAppData', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.vkFormLead]: {
    name: (t) =>
      t('blockStaticConfigMap.vkFormLead', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.amoCRMAddLead]: {
    name: (t) =>
      t('blockStaticConfigMap.amoCRMAddLead', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.amoCRMAddLead
  },
  [BlockKind.amoCRMEditLead]: {
    name: (t) =>
      t('blockStaticConfigMap.amoCRMEditLead', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.amoCRMEditLead
  },
  [BlockKind.communicateAI]: {
    name: (t) =>
      t('blockStaticConfigMap.communicateAI', { ns: 'smartGraphBlocks' }),
    dock: documentationLinks.smartbotAI
  },
  [BlockKind.askGpt]: {
    name: (t) => t('blockStaticConfigMap.askGpt', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.tgSendDice]: {
    name: (t) =>
      t('blockStaticConfigMap.tgSendDice', { ns: 'smartGraphBlocks' }),
    dock: ''
  },
  [BlockKind.kickFromChat]: {
    name: (t) =>
      t('blockStaticConfigMap.kickFromChat', { ns: 'smartGraphBlocks' }),
    dock: ''
  }
};
