import { action, makeObservable, observable } from 'mobx';

import {
  IModeProject,
  ModeProjectServer,
  normalizeModeProject
} from 'shared/entities/projectSettings';
import {
  ProjectSettingsTypes,
  SettingsType
} from 'shared/entities/projectSettingsTypes';
import { ISettingsStore } from 'shared/entities/store/settingsStore';

export default class ModeProjectModel implements IModeProject {
  enabled: boolean;
  in: ProjectSettingsTypes;
  settingsStore: ISettingsStore;
  type: SettingsType;

  constructor({
    params,
    settingsStore,
    type
  }: {
    params: IModeProject;
    settingsStore: ISettingsStore;
    type: SettingsType;
  }) {
    makeObservable(this, {
      enabled: observable,
      in: observable,
      changeEnabled: action,
      changeIn: action
    });

    this.type = type;
    this.enabled = params.enabled;
    this.in = params.in;
    this.settingsStore = settingsStore;
  }

  changeEnabled = async (value: boolean, withRequest = true): Promise<void> => {
    this.enabled = value;
    if (!withRequest) {
      return;
    }
    const { isError } = await this.settingsStore.update(
      { [`${this.type}`]: this.toJson() },
      (t) =>
        t('models.projectSettings.ModeProjectModel.notifications.enableError', {
          ns: 'models'
        })
    );
    if (isError) {
      this.enabled = !this.enabled;
    }
  };

  changeIn = async (
    value: ProjectSettingsTypes,
    withRequest = true
  ): Promise<void> => {
    const prevValue = this.in;
    const prevEnabled = this.enabled;
    this.in = value;
    this.enabled = true;
    if (!withRequest) {
      return;
    }
    const { isError } = await this.settingsStore.update(
      { [`${this.type}`]: this.toJson() },
      (t) =>
        t('models.projectSettings.ModeProjectModel.notifications.inError', {
          ns: 'models'
        })
    );
    if (isError) {
      this.in = prevValue;
      this.enabled = prevEnabled;
    }
  };

  toJson() {
    return {
      enabled: this.enabled,
      in: this.in
    };
  }

  static fromJson({
    raw,
    type,
    settingsStore
  }: {
    raw: ModeProjectServer;
    settingsStore: ISettingsStore;
    type: SettingsType;
  }): ModeProjectModel {
    return new ModeProjectModel({
      params: normalizeModeProject(raw),
      settingsStore,
      type
    });
  }
}
