import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import {
  IconSize,
  CommonIconProps,
  ChannelIconExtraProps
} from 'shared/entities/components/Icon';

const ElamaIcon: React.FC<CommonIconProps & ChannelIconExtraProps> = (
  props: CommonIconProps & ChannelIconExtraProps
) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 26 26' }}
      {...props}
    >
      <path
        fill="#3A64F8"
        d="M13 25.9999c7.1797 0 13-5.8203 13-12.9999 0-7.1797-5.8203-13-13-13S0 5.8203 0 13c0 7.1796 5.8203 12.9999 13 12.9999Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.6887 8.7485c.8421.0215.9115-.7243.9705-1.357.0143-.1542.0281-.3016.0522-.4296.0199-.1054.051-.2355.0858-.3808.1805-.7547.4587-1.9179-.2336-2.1452-.8256-.271-1.5772 1.8483-1.6511 2.4767l-.0114.0945c-.0787.645-.2092 1.716.7876 1.7414ZM9.286 10.2889c.8089-.3136.6025-1.2942.4111-2.204-.014-.0667-.028-.133-.0415-.1987-.056-.2735-.0733-.6424-.0913-1.0295-.0453-.9727-.096-2.0602-.7835-2.0386-.961.0303-1.2566 2.3965-.9734 3.8566 0 0 .218 2.1017 1.4786 1.6142Zm7.9942.2733c.3619.0322.772.0686.9639.3953.3817.6498.1969 1.7957-.4875 2.6116-.6844.8158-2.3344 1.3527-2.9258 1.4759-.4843.1009-.429.3774-.3479.7836.018.09.0372.1863.0521.2884.0824.5635.1802 2.4765.1849 4.2756.0046 1.7991-.5244 4.9646-.5244 4.9646-.1021.5177.1509.5757.1509.5757-3.5959.3436-5.8546-.7382-5.8546-.7382s.1463.0256.2321-.0278c.2121-.2252.2945-.646.664-2.5347l.072-.3671c.9865-5.0305.6668-7.7034.5531-8.3413-.0082-.0462-.0208-.1082-.0359-.1826-.0955-.4713-.2919-1.4395-.1243-2.0353.1941-.6899.5438-1.8517 2.4858-2.2418 1.9419-.39 3.9954-.3827 4.6238-.161.6284.2218.694.639.5668.6285-.025-.0021-.0683-.013-.1229-.0267-.2686-.0675-.8115-.204-.8012.2977.0061.3003.3171.3279.6751.3596Zm-5.7311 1.0481c0 .3714.3018.6732.6732.6732a.6736.6736 0 0 0 .6732-.6732.6736.6736 0 0 0-.6732-.6732.6737.6737 0 0 0-.6732.6732Z"
        clipRule="evenodd"
      />
    </BaseIcon>
  );
};

export default ElamaIcon;
