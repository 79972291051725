import { action, makeObservable, runInAction } from 'mobx';

import { CabinetManagerModel } from 'shared/models/manager';
import {
  CabinetManagerRoleType,
  CabinetManagerServer
} from 'shared/entities/manager';
import { apiUrls } from 'shared/entities/domain';
import { IRootStore } from 'shared/entities/store/rootStore';
import { AppNotificationType } from 'shared/entities/appNotifications';

import { BaseManagersStore } from '../baseManagersStore';

export default class CabinetManagersStore extends BaseManagersStore<CabinetManagerModel> {
  constructor(rootStore: IRootStore) {
    super({
      rootStore
    });

    makeObservable(this, {
      add: action
    });
  }

  get canBeLoaded(): boolean {
    return this.rootStore.cabinetInitialized;
  }

  addEntity = (manager: CabinetManagerModel): void => {
    this.managers.addEntity({ entity: manager, key: manager.email });
  };

  async load(): Promise<BaseResponse> {
    if (!this.canBeLoaded || this.managers.loadingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.managers.loadingStage.loading();

    const response = await this.rootStore.networkStore.api<{
      managers: CabinetManagerServer[];
    }>(apiUrls.MANAGERS_CABINET_LIST);

    if (!response.isError) {
      this.managers.fillByRawData({
        raw: response.data.managers,
        normalizer: (manager) => {
          return {
            entity: CabinetManagerModel.fromJson({
              ...manager,
              managersStore: this,
              rootStore: this.rootStore
            }),
            key: manager.email
          };
        }
      });

      this.managers.loadingStage.success();
    } else {
      this.managers.loadingStage.error();
    }

    return {
      isError: response.isError
    };
  }

  async add(params: {
    cabinet_role: CabinetManagerRoleType;
    email: string;
  }): Promise<BaseResponse<null, { errorCode: string | null }>> {
    if (this.addingManagerStage.isLoading) {
      return {
        isError: true,
        data: {
          errorCode: null
        }
      };
    }

    this.addingManagerStage.loading();

    const response = await this.rootStore.networkStore.api<
      Omit<CabinetManagerServer, 'projects'>
    >(apiUrls.MANAGERS_ADD, { method: 'POST', data: params });

    runInAction(() => {
      if (!response.isError) {
        if (response.data.name) {
          const manager = CabinetManagerModel.fromJson({
            ...response.data,
            projects: [],
            managersStore: this,
            rootStore: this.rootStore
          });

          this.managers.addEntity({ entity: manager, key: manager.email });
        }

        this.addingManagerStage.success();
        this.rootStore.appNotificationsStore.open({
          type: AppNotificationType.success,
          title: (t) =>
            `${t('managers.common.sendToEmail', {
              ns: 'stores'
            })} ${params.email}`,
          highlightedParts: [params.email]
        });
      } else {
        this.addingManagerStage.error();
      }
    });

    if (response.isError) {
      return {
        isError: true,
        data: {
          errorCode: response.data?.code || null
        }
      };
    }

    return {
      isError: false,
      data: null
    };
  }
}
