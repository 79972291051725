import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const AllowMessageIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <path
        d="M10.5105 25.0449H8.79512C7.17328 25.0449 5.89453 23.7349 5.89453 22.1443V11.1657V9.91817C5.89453 8.32753 7.20447 7.01758 8.79512 7.01758H23.2045C24.8263 7.01758 26.1051 8.32753 26.1051 9.91817V22.1443C26.1051 23.7661 24.7951 25.0449 23.2045 25.0449H21.4891H10.5105Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.89453 11.1345L13.6606 15.2515C15.1265 16.0312 16.8731 16.0312 18.339 15.2515L26.1051 11.1345"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default AllowMessageIcon;
