import * as React from 'react';
import cn from 'classnames';

import {
  TypographySize,
  TypographyType,
  TypographyWeight,
  TypographyColor
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import {
  AvatarBorderColor,
  AvatarBorderPosition,
  AvatarBorderSize,
  AvatarColor,
  AvatarSize,
  AvatarType
} from 'shared/newEntities/components/user/Avatar';
import {
  getFirstLetterFromTitle,
  getInitialsFromUsername
} from 'shared/entities/common/utils';

import './Avatar.modules.scss';

type Props = {
  className?: string;
  size?: AvatarSize;
  type?: AvatarType;
  // ссылка на изображение или имя пользователя
  content: string;
  border?: {
    color?: AvatarBorderColor;
    size?: AvatarBorderSize;
    position?: AvatarBorderPosition;
  };
  color?: AvatarColor;
};

const Avatar: React.FC<Props> = ({
  className,
  size = AvatarSize.s,
  type,
  content,
  border,
  color = AvatarColor.secondary
}: Props) => {
  const borderClassnames = border
    ? `avatar_border_${border.color ?? AvatarBorderColor.main} avatar_border_${
        border.size ?? AvatarBorderSize.s
      } avatar_border_${border.position ?? AvatarBorderPosition.full}`
    : '';

  const actualLetter = React.useMemo(() => {
    switch (type) {
      case AvatarType.name:
        return getInitialsFromUsername(content);
      case AvatarType.title:
        return getFirstLetterFromTitle(content);
      case AvatarType.count:
        return content.length > 2 ? content[0] : content;
      default:
        return content[0];
    }
  }, [type, content.length]);

  return (
    <div
      styleName={cn(
        'avatar',
        `avatar_${color}`,
        `avatar_${size}`,
        borderClassnames
      )}
      className={className}
      style={
        type === AvatarType.img
          ? {
              backgroundImage: `url(${content})`
            }
          : {}
      }
    >
      {type !== AvatarType.img && (
        <Typography
          fontType={TypographyType.text}
          fontSize={TypographySize.s}
          fontWeight={TypographyWeight.heavy}
          fontColor={TypographyColor.tertiary}
        >
          {actualLetter}
        </Typography>
      )}
    </div>
  );
};

export default React.memo(Avatar);
