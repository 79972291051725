import * as React from 'react';

import ScenariosStore from './ScenariosStore';
import { ScenariosStoreContext, ScenariosStoreProvider } from './context';

const useScenariosStore = (): ScenariosStore => {
  const scenariosStoreContext = React.useContext(ScenariosStoreContext);

  return scenariosStoreContext.store as ScenariosStore;
};

export { ScenariosStore, useScenariosStore, ScenariosStoreProvider };
