import * as React from 'react';

import LoaderSpinner, { LoadingSpinnerSize } from '../LoaderSpinner';

import './PageSpinner.modules.scss';

const PageSpinner: React.FC = () => {
  return (
    <div styleName="page-spinner">
      <LoaderSpinner size={LoadingSpinnerSize.m} centered />
    </div>
  );
};

export default PageSpinner;
