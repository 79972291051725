import { REG_EXP_URL_WITH_REQUIRED_PROTOCOL_WORD } from 'shared/entities/regExp/url';

const getURLObjectFromUrl = (url: string): URL | null => {
  if (REG_EXP_URL_WITH_REQUIRED_PROTOCOL_WORD.test(url)) {
    try {
      return new URL(url);
    } catch (e) {
      return null;
    }
  }
  const fakeProtocol = 'https://';
  const fullUrl = `${fakeProtocol}${url}`;

  try {
    return new URL(fullUrl);
  } catch (e) {
    return null;
  }
};

export default getURLObjectFromUrl;
