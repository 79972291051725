import { observer } from 'mobx-react';
import * as React from 'react';

import {
  TypographyColor,
  TypographySize,
  TypographyType
} from 'shared/newEntities/components/Typography';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import ExternalLink from 'shared/newComponents/ExternalLink/ExternalLink';
import Typography from 'shared/newComponents/Typography';
import { useLocal } from 'shared/entities/common/hooks';
import { JivoCreationStore } from 'stores/JivoCreationStore';
import { useRootStore } from 'stores/index';
import LoaderSpinner from 'shared/newComponents/animations/LoaderSpinner';
import { LoaderSpinnerSize } from 'shared/newEntities/components/LoaderSpinner';
import { Input, TextArea } from 'shared/newComponents/form';
import {
  documentationLinks,
  JIVO_LINK,
  JIVO_SUPPORT_LINK
} from 'shared/entities/app';
import { ButtonSize, ButtonStyle } from 'shared/newEntities/components/Button';
import { ComponentType } from 'shared/entities/components/Component';
import { Button } from 'shared/newComponents/buttons';
import { noop } from 'shared/entities/common/utils';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { channelKindEntities } from 'shared/entities/channels';
import { AnalyticsEvent } from 'shared/entities/analytics';
import TemplateForCopy from 'shared/newComponents/form/TemplateForCopy';

import BannedChannelText from '../BannedChannelText';

import './Jivo.modules.scss';

const Jivo: React.FC = () => {
  const rootStore = useRootStore();
  const { store: modalStore } = useChannelsModalStore();
  const store = useLocal(() => new JivoCreationStore(rootStore));

  const connectedChannel =
    modalStore.connectWithJivoSelectedChannelKind.value &&
    channelKindEntities[modalStore.connectWithJivoSelectedChannelKind.value];

  React.useEffect(() => {
    store.generateWebhook();
  }, []);

  const handleCreate = React.useCallback(async () => {
    const { isError } = await store.create();
    if (!isError) {
      if (modalStore.connectWithJivoSelectedChannelKind.value) {
        rootStore.analyticsStore.sendEvent(
          AnalyticsEvent.channelCreationViaJivoOk,
          {
            created_channel_kind_via_jivo:
              modalStore.connectWithJivoSelectedChannelKind.value
          }
        );
      }
      modalStore.unSelectChannelKind();
    }
  }, [modalStore.connectWithJivoSelectedChannelKind.value]);

  const smallBodyForm = modalStore.isModal || rootStore.uiStore.isMobileSize;

  const url = store.url;

  return (
    <div styleName="jivo">
      {store.generatingWebhookStage.isLoading && (
        <div styleName="jivo__loader">
          <LoaderSpinner size={LoaderSpinnerSize.md} />
        </div>
      )}
      {!store.generatingWebhookStage.isLoading && store.mailToLink && (
        <>
          <div styleName="jivo__top">
            <Typography
              fontType={TypographyType.text}
              fontSize={TypographySize.s}
            >
              {!modalStore.connectWithJivoSelectedChannelKind.value && (
                <TypedTransComponent
                  ns="newContainers"
                  i18nKey="ChannelsModal.ChannelForm.Jivo.instruction"
                >
                  Напишите письмо на{' '}
                  <ExternalLink
                    fontType={TypographyType.text}
                    fontSize={TypographySize.s}
                    fontColor={TypographyColor.link}
                    underline
                    href={store.mailToLink}
                  >
                    {JIVO_SUPPORT_LINK}
                  </ExternalLink>{' '}
                  по шаблону ниже и вставьте полученный от Jivo URL:
                </TypedTransComponent>
              )}
              {modalStore.connectWithJivoSelectedChannelKind.value && (
                <TypedTransComponent
                  ns="newContainers"
                  i18nKey="ChannelsModal.ChannelForm.Jivo.instructionForConnectedChannel"
                >
                  1. Авторизуйтесь или зарегистрируйтесь на&nbsp;
                  <ExternalLink
                    fontType={TypographyType.text}
                    fontSize={TypographySize.s}
                    fontColor={TypographyColor.link}
                    underline
                    href={JIVO_LINK}
                  >
                    {JIVO_LINK}
                  </ExternalLink>
                  . В&nbsp;разделе «Управление» → «Каналы связи» добавьте нужный
                  канал.
                  <br />
                  2. Чтобы подключить бота к&nbsp;каналу, напишите письмо на{' '}
                  <ExternalLink
                    fontType={TypographyType.text}
                    fontSize={TypographySize.s}
                    fontColor={TypographyColor.link}
                    underline
                    href={store.mailToLink}
                  >
                    {JIVO_SUPPORT_LINK}
                  </ExternalLink>{' '}
                  по шаблону ниже и вставьте полученный от Jivo URL:
                </TypedTransComponent>
              )}
            </Typography>
            <Input
              styleName="jivo__topic"
              label={(t) =>
                t('ChannelsModal.ChannelForm.Jivo.topic', {
                  ns: 'newContainers'
                })
              }
              value={store.mailSubject}
              onChange={noop}
              withCopying
              fontSize="s"
            />
            {store.mailBody && smallBodyForm && (
              <TextArea
                styleName="jivo__body"
                label={(t) =>
                  t('ChannelsModal.ChannelForm.Jivo.body', {
                    ns: 'newContainers'
                  })
                }
                value={store.mailBody}
                onChange={noop}
                withCopying
                maxRows={2}
                fontSize="s"
              />
            )}
            {store.mailBody && !smallBodyForm && (
              <TemplateForCopy
                styleName="jivo__body"
                label={(t) =>
                  t('ChannelsModal.ChannelForm.Jivo.body', {
                    ns: 'newContainers'
                  })
                }
                value={store.mailBody}
              />
            )}
          </div>
          <div styleName="jivo__bottom">
            <Input
              label="URL"
              placeholder="bot.jivosite.com/webhooks/..."
              value={url.value}
              onChange={url.changeValue}
              error={url.error}
              onBlur={url.validate}
              fontSize="s"
            />
            <Typography
              styleName="jivo__instruction"
              fontType={TypographyType.text}
              fontSize={TypographySize.s}
            >
              <TypedTransComponent
                ns="newContainers"
                i18nKey="ChannelsModal.ChannelForm.Jivo.instructionContinuation"
              >
                Выберите в личном кабинете Jivo опции бота и сохраните канал.
              </TypedTransComponent>
            </Typography>
            <Button
              styleName="jivo__instruction-button"
              size={ButtonSize.sm}
              withoutPadding
              style={ButtonStyle.transparent}
              type={ComponentType.a}
              rel="noreferrer noopener"
              target="_blank"
              href={documentationLinks.createJivoChannel}
            >
              {(t) =>
                t('ChannelsModal.ChannelForm.Jivo.instructionButton', {
                  ns: 'newContainers'
                })
              }
            </Button>
            <Button
              styleName="jivo__button"
              size={ButtonSize.lg}
              type={ComponentType.button}
              onClick={handleCreate}
              loadingStage={store.creationStage.value}
              disabled={store.isError}
              fullWidth
            >
              {(t) =>
                t('ChannelsModal.ChannelForm.button', {
                  ns: 'newContainers'
                })
              }
            </Button>
            {connectedChannel?.banned && (
              <BannedChannelText styleName="jivo__banned" />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default observer(Jivo);
