import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const DenyMessageIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <path
        d="M7.36042 24.6705C6.48712 24.1715 5.89453 23.2358 5.89453 22.1442V11.1657V9.91811C5.89453 8.32746 7.20447 7.01752 8.79512 7.01752H23.2045C23.7347 7.01752 24.2025 7.14228 24.6392 7.39179"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.1052 9.91815V22.1443C26.1052 23.7661 24.7953 25.0449 23.2046 25.0449H21.4892H11.228"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7993 12.9123L26.1054 11.1345"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.86377 11.1345L13.6299 15.2515C14.4096 15.6569 15.2829 15.8753 16.1562 15.8441"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.02148 26.9785L26.9786 5.05261"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default DenyMessageIcon;
