import { TranslationString } from 'shared/entities/localization';

export type GetCourseTokenServer = {
  _id: string;
  account_name: string;
  token: string;
  date_created: string;
};

export enum CreateGetCourseTokenError {
  invalidCredentials = 'invalid_credentials'
}

export const mapCreateGetCourseTokenErrorToMessage =
  (code: CreateGetCourseTokenError): TranslationString =>
  (t) =>
    t(`getCourse.errors.${code}`, {
      ns: 'entities'
    });
