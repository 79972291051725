import * as React from 'react';
import cn from 'classnames';

import { CloseIcon } from 'shared/components/icons';
import { ComponentType } from 'shared/entities/components/Component';
import { IconSize } from 'shared/entities/components/Icon';

import FadeBackground from '../FadeBackground';

import './ContentModalWindow.modules.scss';

type Props = {
  opened: boolean;
  onClose: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
  headerRight?: React.ReactNode;
  withoutPadding?: boolean;
};

const ContentModalWindow: React.FC<Props> = ({
  opened,
  onClose,
  title,
  footer,
  children,
  className,
  headerRight,
  withoutPadding
}: Props) => {
  return (
    <FadeBackground
      styleName="modal-container"
      opened={opened}
      onClose={onClose}
      className={className}
    >
      <div styleName="modal">
        <div styleName="modal__header">
          <div styleName="modal__header-container">{title}</div>
          <div styleName="modal__header-container">
            {headerRight && (
              <div styleName="modal__header-right">{headerRight}</div>
            )}
            <CloseIcon
              className="gray_colored hoverable"
              iconSize={IconSize.M}
              action={{
                type: ComponentType.svg,
                onClick: onClose
              }}
            />
          </div>
        </div>
        <div
          styleName={cn(
            'modal__content',
            withoutPadding && 'modal__content_without-padding'
          )}
        >
          {children}
        </div>
        {footer && <div styleName="modal__footer">{footer}</div>}
      </div>
    </FadeBackground>
  );
};

export default React.memo(ContentModalWindow);
