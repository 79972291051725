import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import {
  CabinetManagerRoleType,
  CabinetManagerServer,
  ICabinetManager,
  IProjectManagerEntity,
  normalizeProjectManagerEntity
} from 'shared/entities/manager';
import { apiUrls } from 'shared/entities/domain';
import { LoadingStage } from 'shared/entities/meta';
import { IBaseManagersStore } from 'shared/entities/store/managersStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import {
  ManagerExternals,
  normalizeManagerExternals
} from 'shared/entities/user';

import BaseManagerModel from './BaseManagerModel';

export default class CabinetManagerModel
  extends BaseManagerModel<CabinetManagerModel>
  implements ICabinetManager
{
  cabinetRole: CabinetManagerRoleType | null;

  projects: IProjectManagerEntity[];

  isRoleChangeable: boolean;

  externalIds: ManagerExternals | null;

  constructor({
    cabinetRole,
    email,
    name,
    managersStore,
    externalIds,
    rootStore,
    projects,
    isRoleChangeable
  }: ICabinetManager & {
    managersStore: IBaseManagersStore<CabinetManagerModel>;
    rootStore: IRootStore;
  }) {
    super({ email, managersStore, name, rootStore });
    this.cabinetRole = cabinetRole;
    this.projects = projects;
    this.isRoleChangeable = isRoleChangeable;
    this.externalIds = externalIds;

    makeObservable(this, {
      changeRole: action,
      cabinetRole: observable,

      linkedInTg: computed,
      linkedInVb: computed,
      linkedInVk: computed
    });
  }

  get linkedInTg(): boolean {
    return !!this.externalIds?.tg;
  }

  get linkedInVb(): boolean {
    return !!this.externalIds?.vb;
  }

  get linkedInVk(): boolean {
    return !!this.externalIds?.vk;
  }

  get id(): string {
    return this.email;
  }

  async changeRole({
    role
  }: {
    role: CabinetManagerRoleType;
  }): Promise<LoadingStage> {
    if (this.changingRoleStage === LoadingStage.LOADING) {
      return LoadingStage.ERROR;
    }

    this.changingRoleStage = LoadingStage.LOADING;

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.MANAGERS_CHANGE_CABINET_ROLE,
      {
        method: 'POST',
        data: {
          email: this.email,

          cabinet_role: role
        }
      }
    );

    runInAction(() => {
      if (!isError) {
        this.cabinetRole = role;
        this.changingRoleStage = LoadingStage.SUCCESS;
      } else {
        this.changingRoleStage = LoadingStage.ERROR;
      }
    });

    return this.changingRoleStage;
  }

  static fromJson(
    raw: CabinetManagerServer & {
      managersStore: IBaseManagersStore<CabinetManagerModel>;
      rootStore: IRootStore;
    }
  ): CabinetManagerModel {
    return new CabinetManagerModel({
      email: raw.email,
      name: raw.name,
      cabinetRole: raw.cabinet_role,
      projects: raw.projects.map(normalizeProjectManagerEntity),
      managersStore: raw.managersStore,
      isRoleChangeable: raw.is_role_changeable,
      rootStore: raw.rootStore,
      externalIds: normalizeManagerExternals(raw.external_ids)
    });
  }
}
