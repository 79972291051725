import { ABGroup, IABStore } from 'shared/entities/store/abStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import { localStorageHandler } from 'stores/localStorageHandler';
import { LocalStorageKey } from 'shared/entities/localStorage';

export default class ABStore implements IABStore {
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  get authAB(): ABGroup {
    const data = localStorageHandler.getItem(LocalStorageKey.authAB);

    if (!data || !data.match(/^(?:A|B)$/)) {
      const random = Math.floor(Math.random() * 2);
      const value = random % 2 === 0 ? ABGroup.A : ABGroup.B;
      localStorageHandler.setItem(LocalStorageKey.authAB, value);

      return value;
    } else {
      return data as ABGroup;
    }
  }
}
