import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const PlayFilledIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 25' }}
      {...props}
    >
      <rect y="0.666748" width="24" height="24" rx="12" fill="currentColor" />
      <path
        d="M9.19437 7.41675C9.03233 7.41675 8.87026 7.45856 8.72216 7.54218C8.42684 7.70942 8.25 8.00891 8.25 8.34339V16.3862C8.25 16.7207 8.42684 17.0202 8.72216 17.1874C9.01749 17.3546 9.37121 17.3546 9.66653 17.1874L16.7778 13.166C17.0732 12.9988 17.25 12.6993 17.25 12.3656C17.25 12.0312 17.0732 11.7317 16.7778 11.5644L9.66653 7.54303C9.51844 7.45856 9.35641 7.41675 9.19437 7.41675Z"
        fill="white"
        fillOpacity="0.8"
      />
    </BaseIcon>
  );
};

export default PlayFilledIcon;
