import { DOMAIN } from 'shared/entities/domain';
import { FieldModel } from 'shared/models/form';
import { AnalyticsModel } from 'shared/models/analytics/AnalyticsModel';
import { AnalyticsEventObj } from 'shared/entities/analytics';

export default class RoistatAnalytics extends AnalyticsModel {
  readonly id: FieldModel<string | null> = new FieldModel<string | null>(null);

  protected get enabled(): boolean {
    return false;
  }

  protected executeScript(onSuccess: () => void, onError: () => void): void {
    window.roistatCookieDomain = DOMAIN;

    window.onRoistatAllModulesLoaded = () => {
      onSuccess();
      window.roistat.registerOnVisitProcessedCallback(() => {
        this.id.changeValue(window.roistat.getVisit());
      });
    };

    (function (w, d, s, h, id) {
      // @ts-ignore
      w.roistatProjectId = id;
      // @ts-ignore
      w.roistatHost = h;
      const p = d.location.protocol == 'https:' ? 'https://' : 'http://';
      const u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie)
        ? '/dist/module.js'
        : '/api/site/1.0/' +
          id +
          '/init?referrer=' +
          encodeURIComponent(d.location.href);
      const js = d.createElement(s);
      js.onerror = onError;
      // @ts-ignore
      js.charset = 'UTF-8';
      // @ts-ignore
      js.async = 1;
      // @ts-ignore
      js.src = p + h + u;
      const js2 = d.getElementsByTagName(s)[0];
      // @ts-ignore
      js2.parentNode.insertBefore(js, js2);
    })(
      window,
      document,
      'script',
      'cloud.roistat.com',
      'a8cabd84a3b2e343f99dfac679462726'
    );
  }

  sendEvent(event: AnalyticsEventObj): void {
    window.roistat.event.send(event.type, event.payload || {});
  }
}
