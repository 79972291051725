import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const TimerIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      svgProps={{ viewBox: '0 0 24 24' }}
      iconSize={IconSize.L}
      {...props}
    >
      <path
        d="M15.0544 14.0918H12.3094V10.7204C12.3094 10.4494 12.09 10.23 11.819 10.23C11.5481 10.23 11.3286 10.4494 11.3286 10.7204V14.5816C11.3286 14.8526 11.5481 15.072 11.819 15.072H15.0544C15.3254 15.072 15.5448 14.8526 15.5448 14.5816C15.5448 14.3107 15.3254 14.0918 15.0544 14.0918Z"
        fill="currentColor"
      />
      <path
        d="M20.8765 5.71614C20.6841 5.48679 20.334 5.45079 20.0949 5.63613L17.9912 7.26486C16.6303 6.02814 14.8337 5.22812 12.8488 5.10144V3.0667H13.7298C14.0368 3.0667 14.2855 2.82803 14.2855 2.53335C14.2855 2.23867 14.0368 2 13.7298 2H10.8563C10.5493 2 10.3005 2.23867 10.3005 2.53335C10.3005 2.82803 10.5493 3.0667 10.8563 3.0667H11.7373V5.10078C7.42296 5.37679 4 8.83224 4 13.0417C4 17.4299 7.72031 21 12.2931 21C16.8658 21 20.5861 17.4299 20.5861 13.0417C20.5861 11.153 19.897 9.41626 18.7472 8.05021L20.7931 6.46616C21.0321 6.28149 21.0696 5.94548 20.8765 5.71614ZM12.2931 19.9333C8.33306 19.9333 5.11158 16.8418 5.11158 13.0417C5.11158 9.24159 8.33306 6.14948 12.2931 6.14948C16.2531 6.14948 19.4746 9.24092 19.4746 13.0411C19.4746 16.8412 16.2531 19.9333 12.2931 19.9333Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TimerIcon;
