import {
  KeyboardEventListener,
  KeyboardEventType,
  keyboardEventTypeOrder
} from 'shared/entities/keyboardEvents';
import { IKeyboardEventsStore } from 'shared/entities/store/keyboardEventsStore';

export class KeyboardEventsStore implements IKeyboardEventsStore {
  private stacks: Map<KeyboardEventType, KeyboardEventListener[]>;

  constructor() {
    this.stacks = new Map<KeyboardEventType, KeyboardEventListener[]>(
      keyboardEventTypeOrder.map((type) => [type, []])
    );
  }

  addEventListener = (): void => {
    document.addEventListener('keydown', this.handleKeyboardEvent);
  };

  removeEventListener = (): void => {
    document.removeEventListener('keydown', this.handleKeyboardEvent);
  };

  addListener = ({
    type,
    listener
  }: {
    type: KeyboardEventType;
    listener: KeyboardEventListener;
  }): void => {
    const stack = this.stacks.get(type);

    if (!stack) {
      return;
    }

    stack.push(listener);
  };

  removeListener = ({
    type,
    listener
  }: {
    type: KeyboardEventType;
    listener: KeyboardEventListener;
  }): void => {
    const stack = this.stacks.get(type);

    if (!stack) {
      return;
    }

    this.stacks.set(
      type,
      stack.filter((item) => item !== listener)
    );
  };

  private getEventType = (
    e: KeyboardEvent
  ): BaseResponse<{
    type: KeyboardEventType;
    withCmd: boolean;
    withShift: boolean;
  }> => {
    const withCmd = e.ctrlKey || e.metaKey;
    const withShift = e.shiftKey;

    const defaultResponseParams = {
      withCmd,
      withShift
    };

    if (e.key === 'Backspace' || e.key === 'Delete') {
      return {
        isError: false,
        data: { type: KeyboardEventType.delete, ...defaultResponseParams }
      };
    }

    if ((e.key === 'c' || e.code === 'KeyC') && withCmd) {
      return {
        isError: false,
        data: { type: KeyboardEventType.copy, ...defaultResponseParams }
      };
    }

    if ((e.key === 'v' || e.code === 'KeyV') && withCmd) {
      return {
        isError: false,
        data: { type: KeyboardEventType.paste, ...defaultResponseParams }
      };
    }

    if ((e.key === 'd' || e.code === 'KeyD') && withCmd) {
      return {
        isError: false,
        data: { type: KeyboardEventType.duplication, ...defaultResponseParams }
      };
    }

    if ((e.key === 'z' || e.code === 'KeyZ') && withCmd) {
      return {
        isError: false,
        data: { type: KeyboardEventType.cancel, ...defaultResponseParams }
      };
    }

    if (e.key === 'Escape') {
      return {
        isError: false,
        data: { type: KeyboardEventType.escape, ...defaultResponseParams }
      };
    }

    if (e.key === 'Enter') {
      return {
        isError: false,
        data: { type: KeyboardEventType.enter, ...defaultResponseParams }
      };
    }

    return {
      isError: true
    };
  };

  private executeListeners = async ({
    type,
    event,
    ...params
  }: {
    type: KeyboardEventType;
    event: KeyboardEvent;
    withCmd: boolean;
    withShift: boolean;
  }): Promise<void> => {
    const stack = this.stacks.get(type);

    if (!stack) {
      return;
    }

    for (let i = stack.length - 1; i >= 0; i--) {
      const blockNextListener = await stack[i]({
        originalEvent: event,
        ...params
      });

      if (blockNextListener) {
        return;
      }
    }
  };

  private handleKeyboardEvent = (e: KeyboardEvent): void => {
    const eventResponse = this.getEventType(e);

    if (eventResponse.isError) {
      return;
    }

    this.executeListeners({ event: e, ...eventResponse.data });
  };
}
