import { IChat } from 'shared/entities/chats';
import { ChatRealmType, ChatServer } from 'shared/entities/chats/server';
import { ChannelKindType } from 'shared/entities/channels';
import { TranslationString } from 'shared/entities/localization';

export default class ChatModel implements IChat {
  id: string;
  title: TranslationString | string;
  readonly channelId: string;
  readonly channelKind: ChannelKindType;
  readonly realm: ChatRealmType;
  readonly isEnabled: boolean;

  constructor({ id, title, channelId, channelKind, realm, isEnabled }: IChat) {
    this.id = id;
    this.title = title;
    this.channelId = channelId;
    this.channelKind = channelKind;
    this.realm = realm;
    this.isEnabled = isEnabled;
  }

  static fromJson(raw: ChatServer): ChatModel {
    return new ChatModel({
      id: raw._id,
      title: raw.title || ((t) => t('chatModel.title', { ns: 'models' })),
      channelId: raw.channel_id,
      channelKind: raw.channel_kind,
      realm: raw.realm,
      isEnabled: raw.is_enabled ?? true
    });
  }
}
