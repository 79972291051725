import { createContext } from 'react';

import { VKCreationStore } from './VKCreationStore';

const VKCreationStoreContext = createContext<{ store: VKCreationStore | null }>(
  {
    store: null
  }
);

const VKCreationStoreProvider = VKCreationStoreContext.Provider;

export { VKCreationStoreContext, VKCreationStoreProvider };
