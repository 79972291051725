import * as React from 'react';
import { observer } from 'mobx-react';

import { ModalWindow } from 'shared/components/windows';
import { Content } from 'shared/components/windows/ModalWindow';
import { usePluginsStore, useRouterStore, useUiStore } from 'stores/index';
import { ComponentType } from 'shared/entities/components/Component';
import { useTypedTranslation } from 'shared/entities/localization';

const AuthModal: React.FC = () => {
  const uiStore = useUiStore();
  const pluginsStore = usePluginsStore();
  const { t } = useTypedTranslation();
  const routerStore = useRouterStore();

  const handleClick = React.useCallback(() => {
    if (!pluginsStore.linkToAuth) {
      routerStore.changeToAuthDomain();
    } else {
      window.location.href = pluginsStore.linkToAuth;
    }
  }, [pluginsStore.linkToAuth]);

  return (
    <ModalWindow title="" opened={uiStore.isAuthorizedModalOpen}>
      <Content
        cancel={null}
        ok={{
          children: (t) =>
            t('buttonTitle', {
              ns: 'AuthModal'
            }),
          type: ComponentType.button,
          onClick: handleClick
        }}
      >
        {t('content', {
          ns: 'AuthModal'
        })}
      </Content>
    </ModalWindow>
  );
};

export default observer(AuthModal);
