import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import { OpenedChannelKind } from 'shared/entities/channels';
import { ObtainTokenServer } from 'shared/entities/obtainToken';
import {
  IAccount,
  LinkParams,
  UpdateAccountAction
} from 'shared/entities/account';
import { IManagerExternal } from 'shared/entities/user';

import { LoadingStageModel } from './loadingStage';
import { FieldModel } from './form';

export class AccountModel implements IAccount {
  readonly rootStore: IRootStore;
  readonly kind: OpenedChannelKind;
  readonly manager: FieldModel<IManagerExternal | null>;
  readonly linkParams: FieldModel<LinkParams | null>;
  readonly unlinkStage: LoadingStageModel = new LoadingStageModel();
  constructor({
    kind,
    manager,
    linkParams,
    rootStore
  }: {
    kind: OpenedChannelKind;
    manager: IManagerExternal | null;
    linkParams: LinkParams | null;
    rootStore: IRootStore;
  }) {
    this.kind = kind;
    this.rootStore = rootStore;
    this.manager = new FieldModel<IManagerExternal | null>(manager);
    this.linkParams = new FieldModel<LinkParams | null>(linkParams);
  }

  static init = async ({
    kind,
    manager,
    rootStore
  }: {
    kind: OpenedChannelKind;
    manager: IManagerExternal | null;
    rootStore: IRootStore;
  }) => {
    if (manager) {
      return new AccountModel({ kind, manager, linkParams: null, rootStore });
    }
    const linkParams = await AccountModel.getLinkParams({ kind, rootStore });

    return new AccountModel({ kind, manager: null, linkParams, rootStore });
  };

  static async getLinkParams({
    kind,
    rootStore
  }: {
    kind: OpenedChannelKind;
    rootStore: IRootStore;
  }): Promise<LinkParams | null> {
    const response = await rootStore.networkStore.api<ObtainTokenServer>(
      apiUrls.MANAGERS_OBTAIN_TOKEN,
      {
        method: 'GET',
        data: {
          kind
        }
      }
    );

    if (response.isError) {
      return null;
    }

    const urlObj = response.data[kind];

    if (!urlObj) {
      return null;
    }

    return {
      command: response.data.command,
      url: urlObj.url
    };
  }

  unlink = async (): Promise<BaseResponse> => {
    if (this.unlinkStage.isLoading) {
      return {
        isError: true
      };
    }

    this.unlinkStage.loading();

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.MANAGERS_UNLINK_FROM_CHANNEL,
      {
        method: 'POST',
        data: {
          kind: this.kind
        }
      }
    );

    if (!isError) {
      const response = await this.updateAccount({ action: 'unlink' });
      if (!response.isError) {
        this.unlinkStage.success();
        return {
          isError: false
        };
      }
    }
    this.unlinkStage.error();

    return {
      isError: true
    };
  };

  updateAccount = async ({
    action
  }: {
    action: UpdateAccountAction;
  }): Promise<BaseResponse> => {
    if (action === 'link') {
      const response = await this.rootStore.userStore.loadAuthInfo();
      if (!response.isError) {
        this.manager.changeValue(
          this.rootStore.userStore.userExternals?.[this.kind] || null
        );
        return {
          isError: false
        };
      }

      return {
        isError: true
      };
    } else {
      if (!this.linkParams.value) {
        const response = await AccountModel.getLinkParams({
          kind: this.kind,
          rootStore: this.rootStore
        });
        this.linkParams.changeValue(response);
      }
      if (!this.rootStore.userStore.userExternals) {
        return {
          isError: true
        };
      }
      delete this.rootStore.userStore.userExternals[this.kind];
      this.manager.changeValue(null);
      return {
        isError: false
      };
    }
  };
}
