import { isValid } from 'date-fns';

import { ListEntityType } from 'shared/entities/lists';
import { DateFormat, transformDate } from 'shared/entities/date';
import {
  ExternalChannelKind,
  mapExternalChannelToChannelKind
} from 'shared/entities/channels';

import { TFunctionType } from '../../localization';
import {
  varBoolEntities,
  varRealmValueEntities,
  VarSpecial,
  VarType
} from '../server';
import { IBaseVar } from '../client';
import { IRootStore } from '../../store/rootStore';
import { DateFieldType } from '../dateVar';

import { getDateVarValueFieldType } from './getDateVarValueFieldType';

export const getVarActualName = (
  variable: IBaseVar,
  rootStore: IRootStore,
  t: TFunctionType
): string => {
  if (variable.key === VarSpecial.channelKind) {
    return !variable.value
      ? variable.value
      : rootStore.pluginsStore.channelKindEntities[variable.value]?.title ||
          variable.value;
  }

  if (variable.key === VarSpecial.externalChannelKind) {
    if (!variable.value) {
      return '';
    }
    return getExternalChannelKindActualName({
      value: variable.value,
      rootStore
    });
  }

  if (variable.key === VarSpecial.channelOid) {
    return variable.value && rootStore
      ? rootStore.channelsStore.channels.getEntity(variable.value)?.name ||
          variable.value
      : variable.value;
  }

  if (variable.key === VarSpecial.realm) {
    return variable.value
      ? varRealmValueEntities[variable.value]?.title(t) || variable.value
      : variable.value;
  }

  if (variable.key === VarSpecial.userOid) {
    if (!variable.value || !rootStore) {
      return variable.value;
    }

    const list = rootStore.listsStore.getList({
      lType: ListEntityType.users
    });

    return list.getEntity(variable.value)?.title || variable.value;
  }

  const projectVar = rootStore.variablesStore.getProjectVariableByKey(
    variable.key
  );

  if (!projectVar) {
    return variable.value;
  }

  switch (projectVar.type) {
    case VarType.bool: {
      const item = varBoolEntities[variable.value];

      if (!item) {
        return variable.value;
      }

      return item.title(t);
    }

    case VarType.date:
    case VarType.datetime: {
      return getDateVarActualName(variable.value, projectVar.type);
    }
    default: {
      return variable.value;
    }
  }
};

export const getDateVarActualName = (
  value: string,
  type: VarType.date | VarType.datetime
) => {
  if (!value) {
    return value;
  }
  const fieldType = getDateVarValueFieldType(value);

  if (fieldType === DateFieldType.date) {
    const date = new Date(value);
    if (isValid(date)) {
      return transformDate(
        date,
        type === VarType.date
          ? DateFormat.dayMonthYear
          : DateFormat.dayMonthYearTime
      );
    }
  }
  return value;
};

export const getExternalChannelKindActualName = ({
  value,
  rootStore
}: {
  value: string;
  rootStore: IRootStore;
}): string => {
  const externalChannelKinds = Object.values(ExternalChannelKind);
  // @ts-ignore
  if (externalChannelKinds.includes(value)) {
    const varValue = mapExternalChannelToChannelKind[value];
    return (
      rootStore.pluginsStore.channelKindEntities[varValue]?.title || varValue
    );
  }
  return rootStore.pluginsStore.channelKindEntities[value]?.title || value;
};
