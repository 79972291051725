import { REG_EXP_URL_WITH_REQUIRED_PROTOCOL } from '../../regExp/url';

/**
 * Добавляет протокол к url-у, если его нет
 * @param url
 * @return {any}
 */
const addProtocolToUrl = (url, protocol: 'http' | 'https' = 'http'): string => {
  return REG_EXP_URL_WITH_REQUIRED_PROTOCOL.test(url)
    ? url
    : `${protocol}://${url}`;
};

export default addProtocolToUrl;
