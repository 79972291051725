import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const DeleteUserIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M9.48453 11.1656C11.7394 11.1656 13.5673 9.33768 13.5673 7.08282C13.5673 4.82795 11.7394 3 9.48453 3C7.22966 3 5.40173 4.82795 5.40173 7.08282C5.40173 9.33768 7.22966 11.1656 9.48453 11.1656Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2629 20.9999H5.71656C4.14222 20.9999 2.88274 19.6565 3.00869 18.0927C3.2186 15.3533 4.44659 11.6798 9.54747 11.6798C10.9014 11.6798 11.9825 11.9422 12.8326 12.3725"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3457 14.1777H14.8268L15.0577 18.7748C15.0997 19.604 15.7819 20.2547 16.611 20.2547H17.3457H18.0804C18.9096 20.2547 19.5918 19.604 19.6338 18.7748L19.8647 14.1777H17.3457Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9661 14.1777H20.7148"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1493 13.4221C16.1493 12.7608 16.6846 12.2256 17.3458 12.2256C18.007 12.2256 18.5423 12.7608 18.5423 13.4221"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default DeleteUserIcon;
