import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const MouseIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXLM}
      svgProps={{
        viewBox: '0 0 48 48'
      }}
      {...props}
    >
      <path
        d="M23.5 11.5C23.8315 11.5 24.1495 11.6317 24.3839 11.8661C24.6183 12.1005 24.75 12.4185 24.75 12.75V17.75C24.75 18.0815 24.6183 18.3995 24.3839 18.6339C24.1495 18.8683 23.8315 19 23.5 19C23.1685 19 22.8505 18.8683 22.6161 18.6339C22.3817 18.3995 22.25 18.0815 22.25 17.75V12.75C22.25 12.4185 22.3817 12.1005 22.6161 11.8661C22.8505 11.6317 23.1685 11.5 23.5 11.5ZM33.5 31.5C33.5 34.1522 32.4464 36.6957 30.5711 38.5711C28.6957 40.4464 26.1522 41.5 23.5 41.5C20.8478 41.5 18.3043 40.4464 16.4289 38.5711C14.5536 36.6957 13.5 34.1522 13.5 31.5V16.5C13.5 13.8478 14.5536 11.3043 16.4289 9.42893C18.3043 7.55357 20.8478 6.5 23.5 6.5C26.1522 6.5 28.6957 7.55357 30.5711 9.42893C32.4464 11.3043 33.5 13.8478 33.5 16.5V31.5ZM23.5 4C20.1848 4 17.0054 5.31696 14.6612 7.66117C12.317 10.0054 11 13.1848 11 16.5V31.5C11 34.8152 12.317 37.9946 14.6612 40.3388C17.0054 42.683 20.1848 44 23.5 44C26.8152 44 29.9946 42.683 32.3388 40.3388C34.683 37.9946 36 34.8152 36 31.5V16.5C36 13.1848 34.683 10.0054 32.3388 7.66117C29.9946 5.31696 26.8152 4 23.5 4V4Z"
        fill="#99A6B2"
      />
    </BaseIcon>
  );
};

export default MouseIcon;
