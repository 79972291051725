import { IField } from '../form';
import { ILoadingStageModel } from '../loadingStage';
import { TranslationNode, TranslationString } from '../localization';

import { CalculatePayError, PaymentAddition } from './server';

export interface IPaymentInfo {
  readonly monthPrice: number;
  readonly minimalPayment: number;
  readonly roundedUsersCount: number;
  readonly billing: IBilling;
  readonly shouldPay: boolean;
  readonly trialDaysLeft: number;
  readonly daysLeft: number;
  readonly isTrialOver: boolean;
}

export interface IPaymentMethod {
  id: string;
  title: string;
  type: string;
  dateCreated: Date;
}

export interface IPrice {
  id: string;
  maxUsersCount: number | null;
  maxUsersCountTitle: string;
  price: number;
  profitably: boolean;
}

export interface IBilling {
  balance: number;
  trialUntil: Date;
}

export enum PaymentState {
  subscriptionAvailable = 'subscription_available',
  subscriptionActive = 'subscription_active',
  subscriptionStopped = 'subscription_stopped',
  receivingMoneyNotAvailable = 'receiving_money_not_available'
}

export const mapMaxUsersCountToString: Record<number, string> = {
  1000: '< 1 000',
  2000: '1 000 - 2 000',
  5000: '2 000 - 5 000',
  10000: '5 000 - 10 000',
  30000: '10 000 - 30 000',
  50000: '30 000 - 50 000',
  100000: '50 000 - 100 000',
  1000000: '100 000 - ∞'
};

export type InfoMessageType =
  | {
      isError: false;
      data: TranslationString;
    }
  | {
      isError: true;
      data: TranslationNode;
      code: CalculatePayError | null;
    }
  | null;

export interface IPaymentFill {
  readonly selectedMonthCount: IField<number | null>;
  readonly promoCode: IField<string>;
  readonly amount: IField<string>;
  readonly priceToPay: IField<number | null>;
  readonly calculatingPriceStage: ILoadingStageModel;
  readonly payStage: ILoadingStageModel;
  readonly addition: IField<PaymentAddition>;
}
