import * as React from 'react';
import Lottie from 'react-lottie';
import cn from 'classnames';

import {
  LoaderSpinnerSize,
  LoaderSpinnerColor
} from 'shared/newEntities/components/LoaderSpinner';

import animationDataWhite from './loaderWhite.json';
import animationDataBlue from './loaderBlue.json';

import './LoaderSpinner.modules.scss';

type Props = {
  className?: string;
  color?: LoaderSpinnerColor;
  size: LoaderSpinnerSize;
  centered?: boolean;
};

const LoaderSpinner: React.FC<Props> = ({
  color = LoaderSpinnerColor.white,
  className,
  size,
  centered = false
}: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData:
      color === LoaderSpinnerColor.white
        ? animationDataWhite
        : animationDataBlue,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div
      styleName={cn(
        'loader-spinner-wrapper',
        centered && 'loader-spinner-wrapper_centered'
      )}
      className={className}
    >
      <div
        styleName={cn('loader-spinner', `loader-spinner_${size}`)}
        className={className}
      >
        <Lottie options={defaultOptions} />
      </div>
    </div>
  );
};

export default React.memo(LoaderSpinner);
