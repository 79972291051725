import * as React from 'react';
import Highlighter from 'react-highlight-words';

type Props = {
  text: string;
  query?: string[];
  caseSensitive?: boolean;
  className?: string;
};

const TextHighlighter: React.FC<Props> = ({
  text,
  query = [],
  caseSensitive = true,
  className
}: Props) => {
  // ошибка внутри либы, ругается на символы
  const actualQuery = query?.reduce((acc, item) => {
    try {
      new RegExp(item);

      return [...acc, item];
    } catch (e) {
      return acc;
    }
  }, []);

  return (
    <Highlighter
      highlightClassName="bold-text"
      className={className}
      textToHighlight={text}
      searchWords={actualQuery}
      caseSensitive={caseSensitive}
    />
  );
};

export default React.memo(TextHighlighter);
