import { TranslationString } from '../localization';
import { AIModelKind } from '../smartbotAI/server';

export type AITargetServer = {
  kind: AITarget;
  text: string;
};

// Цель использования AI-бота
export enum AIIntentKind {
  buy = 'buy',
  consult = 'consult',
  other = 'other'
}

export type AIIntentKindServer = {
  kind: AIIntentKind;
  text: string;
};

// Цель AI-бота
export enum AITarget {
  contacts = 'contacts',
  consult = 'consult'
}
export type AIInfoServer = {
  kinds: AIIntentKindServer[];
  targets: AITargetServer[];
  default_context_prompt: string;
  default_question_prompt: string;
  tariffication: Record<AIModelKind, number>;
  ai_messages_generated_count: number;
  ai_spent_rubles_amount: number;
};

export const mapAITargetToTitle =
  (target: AITarget): TranslationString =>
  (t) =>
    t(`education.left.intentTargetSelector.${target}`, {
      ns: 'smartbotAIPage'
    });

export const mapAIIntentKindToTitle =
  (kind: AIIntentKind): TranslationString =>
  (t) =>
    t(`education.left.intentKind.selector.${kind}`, {
      ns: 'smartbotAIPage'
    });
