import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const PortalOutIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M6.08764 19.9558C7.7929 19.9558 9.17529 16.6078 9.17529 12.4779C9.17529 8.34796 7.7929 5 6.08764 5C4.38239 5 3 8.34796 3 12.4779C3 16.6078 4.38239 19.9558 6.08764 19.9558Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2595 15.2374H12.7069C13.2086 15.2374 13.6139 15.6427 13.6139 16.1444V16.7716C13.6139 18.0935 15.2059 18.7593 16.1515 17.8426L20.5514 13.5489C21.1496 12.9603 21.1496 11.9954 20.5514 11.4165L16.1515 7.12271C15.2059 6.19642 13.6139 6.87184 13.6139 8.19374V8.82092C13.6139 9.32267 13.2086 9.72792 12.7069 9.72792H11.2595"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default PortalOutIcon;
