import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const CircledCheckIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      svgProps={{ viewBox: '0 0 16 16' }}
      iconSize={IconSize.S}
      {...props}
    >
      <rect width="16" height="16" rx="8" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.18182 10.0227L3.79545 7.63636L3 8.43182L6.18182 11.6136L13 4.79545L12.2045 4L6.18182 10.0227Z"
        fill="white"
      />
    </BaseIcon>
  );
};

export default CircledCheckIcon;
