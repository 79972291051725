import {
  InvoiceStatus,
  PaymentDocumentSubject,
  ServiceInvoiceDocumentServer,
  ICommonDocument,
  normalizeCommonDocument
} from 'shared/entities/documents';

import { BaseInvoiceDocumentModel } from './BaseInvoiceDocumentModel';
import { ServiceDocumentCommonParamsTableModel } from './ServiceDocumentCommonParamsTableModel';

export class ServiceInvoiceDocumentModel extends BaseInvoiceDocumentModel {
  readonly subject: PaymentDocumentSubject.service =
    PaymentDocumentSubject.service;
  readonly params: ServiceDocumentCommonParamsTableModel;

  constructor({
    params,
    ...rest
  }: ICommonDocument & {
    params: ServiceDocumentCommonParamsTableModel;
    status: InvoiceStatus;
    cabinetId: string;
  }) {
    super(rest);
    this.params = params;
  }

  static fromJson(
    raw: ServiceInvoiceDocumentServer,
    cabinetId: string
  ): ServiceInvoiceDocumentModel {
    return new ServiceInvoiceDocumentModel({
      ...normalizeCommonDocument(raw),
      params: ServiceDocumentCommonParamsTableModel.fromJson(raw.params),
      status: raw.status,
      cabinetId
    });
  }
}
