import * as React from 'react';
// eslint-disable-next-line import/named
import { throttle } from 'lodash';
const MAX_DIFF = 5;

const useAddClassNameToStickyTableColumns = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  {
    firstColumnClassName,
    lastColumnClassName
  }: { firstColumnClassName?: string; lastColumnClassName?: string }
) => {
  React.useEffect(() => {
    const handleCalculateClassnames = () => {
      const target = ref.current;

      if (!target) {
        return;
      }

      if (firstColumnClassName) {
        if (target.scrollLeft > MAX_DIFF) {
          target.classList.add(firstColumnClassName);
        } else {
          target.classList.remove(firstColumnClassName);
        }
      }

      if (lastColumnClassName) {
        if (
          target.scrollLeft + target.clientWidth <
          target.scrollWidth - MAX_DIFF
        ) {
          target.classList.add(lastColumnClassName);
        } else {
          target.classList.remove(lastColumnClassName);
        }
      }
    };

    const calculateClassnames = throttle(handleCalculateClassnames, 200);

    handleCalculateClassnames();
    ref.current?.addEventListener('scroll', calculateClassnames);
    window.addEventListener('resize', calculateClassnames);

    return () => {
      ref.current?.removeEventListener('scroll', calculateClassnames);
      window.removeEventListener('resize', calculateClassnames);
    };
  }, [firstColumnClassName, lastColumnClassName]);
};

export default useAddClassNameToStickyTableColumns;
