import { observer } from 'mobx-react';
import * as React from 'react';

import { Selector } from 'shared/newComponents/form';
import { ITgChannelModel } from 'shared/entities/channels';
import { SelectorType } from 'shared/entities/components/Selector';
import { PlusIcon } from 'shared/newComponents/icons';

type Props = {
  channel: ITgChannelModel;
};

const CommandSelector: React.FC<Props> = ({ channel }) => {
  const keys = channel.commands.items.map((item) => item.id);
  const entities = channel.commands.items.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: {
        id: item.id,
        title: item.title,
        description: item.description
      }
    }),
    {}
  );

  return (
    <Selector
      type={SelectorType.single}
      value={channel.selectedCommandId.value}
      onChange={channel.selectedCommandId.changeValue}
      options={{
        keys,
        entities
      }}
      withRemoving={false}
      topItem={{
        Icon: PlusIcon,
        title: (t) =>
          t(
            'ChannelsModal.SelectedChannel.Content.Commands.CommandSelector.topItemLabel',
            {
              ns: 'newContainers'
            }
          ),
        onClick: (onClose) => {
          channel.unselectCommand();
          onClose();
          channel.commandsFormState.open();
        }
      }}
    />
  );
};

export default observer(CommandSelector);
