import * as React from 'react';
import { motion } from 'framer-motion';

import { PopupChildren } from 'shared/entities/components/Popup';

import './Content.modules.scss';

type Props = {
  children: PopupChildren;
  extraTopNode?: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  width: number | string;
};

const Content: React.FC<Props> = (props) => {
  const { onClose, width, extraTopNode, children, isOpen } = props;

  return (
    <motion.div
      style={{
        width
      }}
      initial={{
        y: '-4px'
      }}
      animate={{
        y: '0px'
      }}
      transition={{
        stiffness: 0
      }}
    >
      {extraTopNode && <div styleName="top-item">{extraTopNode}</div>}
      <div styleName="container">
        {React.isValidElement(children) || children === null ? (
          <>{children}</>
        ) : (
          children(onClose, isOpen)
        )}
      </div>
    </motion.div>
  );
};

export default React.memo(Content);
