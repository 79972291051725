import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

import './PercentageCircleIcon.scss';

type Props = {
  percent: number;
} & CommonIconProps;

const PercentageCircleIcon: React.FC<Props> = ({
  percent,
  ...props
}: Props) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXL}
      svgProps={{
        viewBox: '0 0 36 36'
      }}
      {...props}
    >
      <svg viewBox="0 0 36 36" className="circular-chart blue">
        <path
          className="circle-bg"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          strokeDasharray={`${percent}, 100`}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="21" className="percentage">
          {percent}%
        </text>
      </svg>
    </BaseIcon>
  );
};

export default PercentageCircleIcon;
