import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const ReturnIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M18.5655 2H5.4345C3.54118 2 2 3.54049 2 5.43451V18.5655C2 20.4588 3.54049 22 5.4345 22H18.5655C20.4588 22 22 20.4595 22 18.5655V5.43381C22 3.54049 20.4588 2 18.5655 2ZM20.8887 18.5648C20.8887 19.8455 19.8469 20.888 18.5655 20.888H5.4345C4.15377 20.888 3.11127 19.8462 3.11127 18.5648V5.43381C3.11127 4.15308 4.15307 3.11057 5.4345 3.11057H18.5655C19.8462 3.11057 20.8887 4.15238 20.8887 5.43381V18.5648V18.5648Z"
        fill="currentColor"
      />
      <path
        d="M6.16669 11.4165L10.25 6.1665V9.08317C17.2226 9.08317 18.027 14.7287 17.8334 17.8332C17.5405 16.2669 17.4046 13.7498 10.25 13.7498V16.6665L6.16669 11.4165Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default ReturnIcon;
