import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';
import * as React from 'react';

import { LoadingStage } from 'shared/entities/meta';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import ListModel from 'shared/models/ListModel';
import {
  IPrice,
  PaymentInfoServer,
  PaymentParams,
  PaymentState,
  PriceServer,
  mapCalculatePayErrorToMessage,
  getAvailableSumPayments,
  CalculatePayError,
  normalizePrices
} from 'shared/entities/payment';
import { PaymentInfoModel, PaymentFillModel } from 'shared/models/payment';
import { IPaymentStore } from 'shared/entities/store/paymentStore';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { AnalyticsEvent } from 'shared/entities/analytics';
import { LocalStorageKey } from 'shared/entities/localStorage';
import { localStorageHandler } from 'stores/localStorageHandler';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import { FieldModel } from 'shared/models/form';
import ExternalLink from 'shared/newComponents/ExternalLink';
import {
  TypographyColor,
  TypographySize,
  TypographyType
} from 'shared/newEntities/components/Typography';
import { CabinetSplit } from 'shared/entities/cabinet';
import { CarrotQuestEvent } from 'shared/entities/carrotquest';
import { waitUntilDomElementNotExists } from 'shared/entities/common/utils';

import { PAYMENT_LINK_BANNER_ID } from './config';

export default class PaymentStore implements IPaymentStore {
  private _getInfoStage: LoadingStage = LoadingStage.NOT_STARTED;
  private _getLinkStage: LoadingStage = LoadingStage.NOT_STARTED;
  private _getPricesStage: LoadingStage = LoadingStage.NOT_STARTED;
  private rootStore: IRootStore;
  private _prices: ListModel<IPrice> | null = null;
  private _info: PaymentInfoModel | null = null;
  private _paymentFill: PaymentFillModel;
  readonly availableSumPayments: FieldModel<number[] | null> = new FieldModel(
    null
  );

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this._paymentFill = new PaymentFillModel(this, rootStore);
    makeObservable<
      PaymentStore,
      | '_getInfoStage'
      | '_getLinkStage'
      | '_getPricesStage'
      | '_prices'
      | '_info'
      | 'getLink'
    >(this, {
      _getInfoStage: observable,
      _getLinkStage: observable,
      _prices: observable.ref,
      _info: observable.ref,
      _getPricesStage: observable,

      info: computed,
      getInfoStage: computed,
      getLinkStage: computed,
      getPricesStage: computed,
      prices: computed,
      shouldPay: computed,
      isCorporate: computed,

      getInfo: action,
      getLink: action,
      getPrices: action
    });
  }

  get currency(): '₽' | '$' {
    return this.rootStore.pluginsStore.internationalDomainTurnOn ? '$' : '₽';
  }

  get paymentFill(): PaymentFillModel {
    return this._paymentFill;
  }

  get shouldPay(): boolean | null {
    return this.info ? this.info.shouldPay : null;
  }

  get info(): PaymentInfoModel | null {
    return this._info;
  }

  get getInfoStage(): LoadingStage {
    return this._getInfoStage;
  }

  get getLinkStage(): LoadingStage {
    return this._getLinkStage;
  }

  get getPricesStage(): LoadingStage {
    return this._getPricesStage;
  }

  get prices(): ListModel<IPrice> | null {
    return this._prices;
  }

  get isCorporate(): boolean {
    return this.rootStore.cabinetStore.entity?.split === CabinetSplit.corporate;
  }

  saveStateBeforeOpenPaymentWindow = (): void => {
    if (!this.info) {
      return;
    }

    const data = {
      state: this.info.state,
      balance: this.info.billing.balance,
      price: this.paymentFill.priceToPay.value,
      ...this.paymentFill.paymentParams
    };

    localStorage.setItem(
      LocalStorageKey.paymentStateBeforePayment,
      JSON.stringify(data)
    );
  };

  handleSuccessPaymentEvent = (): void => {
    if (!this.info) {
      return;
    }

    const localStorageRawData = localStorageHandler.getItem(
      LocalStorageKey.paymentStateBeforePayment
    );

    if (!localStorageRawData) {
      return;
    }

    try {
      const data = JSON.parse(localStorageRawData);

      if (
        data.state &&
        Object.values(PaymentState).includes(data.state) &&
        typeof data.balance === 'number' &&
        data.balance < this.info.billing.balance
      ) {
        const price = this.info.billing.balance - data.balance;
        const payload = {
          payment_state_before_payment: data.state,
          ...data,
          price
        };

        this.rootStore.analyticsStore.sendEvent(
          AnalyticsEvent.paymentSuccess,
          payload
        );

        this.rootStore.analyticsStore.ym.addToQueue({
          type: AnalyticsEvent.paymentSuccessYm,
          payload: {
            order_price: price,
            currency: 'RUB'
          }
        });

        if (data.state === PaymentState.subscriptionAvailable) {
          this.rootStore.analyticsStore.sendEvent(
            AnalyticsEvent.paymentSuccessSubscriptionAvailable
          );
        }

        localStorageHandler.remove(LocalStorageKey.paymentStateBeforePayment);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error while parse localstorage value');
      localStorageHandler.remove(LocalStorageKey.paymentStateBeforePayment);
    }
  };

  activateSubscription = (): void => {
    this.rootStore.analyticsStore.sendEvent(
      AnalyticsEvent.paymentSubscriptionActivation,
      {
        cabinet_origin: window.location.origin
      }
    );
    this.pay({});
  };

  pay = async (params: PaymentParams): Promise<BaseResponse> => {
    const response = await this.getLink(params);

    if (response.isError) {
      return {
        isError: true
      };
    }

    this.rootStore.analyticsStore.sendEvent(AnalyticsEvent.paymentOpenKassa, {
      ...params,
      cabinet_origin: window.location.origin
    });
    this.rootStore.appNotificationsStore.openBanner({
      id: PAYMENT_LINK_BANNER_ID,
      type: AppNotificationType.warning,
      title: (
        <TypedTransComponent
          ns="stores"
          i18nKey="PaymentStore.notifications.windows"
        >
          Если у вас не открылась страница оплаты, перейдите по{' '}
          <ExternalLink
            href={response.data}
            fontType={TypographyType.text}
            fontSize={TypographySize.s}
            fontColor={TypographyColor.link}
          >
            ссылке
          </ExternalLink>
        </TypedTransComponent>
      )
    });

    this.saveStateBeforeOpenPaymentWindow();

    window.location.href = response.data;

    return {
      isError: false
    };
  };

  getInfo = async (): Promise<BaseResponse> => {
    if (
      !this.rootStore.permissionsStore.canViewBilling ||
      this._getInfoStage === LoadingStage.LOADING
    ) {
      return {
        isError: true
      };
    }

    this._getInfoStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api<PaymentInfoServer>(
      apiUrls.PAYMENTS_GET_INFO
    );

    runInAction(() => {
      if (!response.isError) {
        this._getInfoStage = LoadingStage.SUCCESS;
        this._info = PaymentInfoModel.fromJson(response.data, this.rootStore);
        this.availableSumPayments.changeValue(
          getAvailableSumPayments(this._info.minimalPayment)
        );
        this.handleSuccessPaymentEvent();
      } else {
        this._getInfoStage = LoadingStage.ERROR;
      }
    });

    return {
      isError: response.isError
    };
  };

  getPrices = async (): Promise<BaseResponse> => {
    if (this._getPricesStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this._getPricesStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api<{
      prices: PriceServer[];
    }>(apiUrls.PAYMENTS_GET_PRICES);

    runInAction(() => {
      if (!response.isError) {
        this._getPricesStage = LoadingStage.SUCCESS;
        const { entities, keys } = normalizePrices(response.data.prices);
        this._prices = new ListModel({ entities, keys });
      } else {
        this._getPricesStage = LoadingStage.ERROR;
      }
    });

    return {
      isError: response.isError
    };
  };

  private getLink = async (
    params: PaymentParams
  ): Promise<BaseResponse<string>> => {
    if (this._getLinkStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this._getLinkStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api<
      {
        redirect_url: string;
      },
      CalculatePayError
    >(apiUrls.PAYMENTS_PAY, {
      method: 'POST',
      data: params,
      errorsMap: mapCalculatePayErrorToMessage,
      showExpectedError: false // слева не будет появляться всплывашка для обработанных ошибок
    });

    runInAction(() => {
      if (!response.isError) {
        this._getLinkStage = LoadingStage.SUCCESS;
      } else {
        this.paymentFill.handleError(response.data?.code || null);
        this._getLinkStage = LoadingStage.ERROR;
      }
    });

    if (response.isError) {
      return {
        isError: true
      };
    }

    return {
      isError: false,
      data: response.data.redirect_url
    };
  };

  connectCorporateTariff = async () => {
    if (!this.rootStore.cabinetStore.entity) {
      return;
    }
    this.rootStore.analyticsStore.sendEvent(
      AnalyticsEvent.connectBusinessTariff,
      {
        cabinet_connect_business: this.rootStore.cabinetStore.entity.domain
      }
    );
    this.rootStore.supportPopupStore.track(
      CarrotQuestEvent.requestBusinessTier
    );
    this.addCarrotPopupEventListener();
  };

  addCarrotPopupEventListener = async () => {
    const response = await waitUntilDomElementNotExists('carrot-popup-frame');
    if (response.isError) {
      return;
    }
    const iframe = response.data as HTMLIFrameElement;
    const el = iframe.contentWindow?.document.body.querySelector(
      '#carrotquest-messenger-initialmsg button'
    );

    if (el) {
      const sendEventToGA = () => {
        if (!this.rootStore.cabinetStore.entity) {
          return;
        }

        this.rootStore.analyticsStore.sendEvent(
          AnalyticsEvent.carrotquestSendPhone,
          {
            cabinet_send_phone: this.rootStore.cabinetStore.entity.domain
          }
        );
      };
      el.addEventListener('click', sendEventToGA, {
        once: true,
        capture: true
      });
    }
  };
}
