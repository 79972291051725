import { TranslationString } from 'shared/entities/localization';
import { IconSize } from 'shared/entities/components/Icon';

import { TypographyColor, TypographySize } from '../Typography';

export type TagProps = {
  withIndicator?: boolean;
  size?: TagSize;
  type?: TagType;
  className?: string;
  children: TranslationString | string;
  withIcon?: boolean;
};

export enum TagSize {
  xs = 'xs',
  s = 's',
  sm = 'sm',
  md = 'md',
  lg = 'lg'
}

export enum TagType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  main = 'main',
  neutral = 'neutral',
  base = 'base',
  purple = 'purple'
}

export const mapTagTypeToFontColor = {
  [TagType.error]: TypographyColor.red,
  [TagType.warning]: TypographyColor.warning,
  [TagType.success]: TypographyColor.success,
  [TagType.main]: TypographyColor.blue,
  [TagType.neutral]: TypographyColor.secondary,
  [TagType.base]: TypographyColor.white,
  [TagType.purple]: TypographyColor.white
};

export const mapTagSizeToIconSize: Record<TagSize, IconSize> = {
  [TagSize.xs]: IconSize.XS,
  [TagSize.s]: IconSize.XS,
  [TagSize.sm]: IconSize.XSM,
  [TagSize.md]: IconSize.S,
  [TagSize.lg]: IconSize.M
};

export const mapTagSizeToFontSize: Record<TagSize, TypographySize> = {
  [TagSize.xs]: TypographySize.xxs,
  [TagSize.s]: TypographySize.xs,
  [TagSize.sm]: TypographySize.xs,
  [TagSize.md]: TypographySize.s,
  [TagSize.lg]: TypographySize.s
};
