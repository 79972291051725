import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const ErrorIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      {...props}
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
    >
      <path
        d="M12 3C13.1819 3 14.3522 3.23279 15.4442 3.68508C16.5361 4.13738 17.5282 4.80031 18.364 5.63604C19.1997 6.47177 19.8626 7.46392 20.3149 8.55585C20.7672 9.64778 21 10.8181 21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C9.61305 21 7.32387 20.0518 5.63604 18.364C3.94821 16.6761 3 14.3869 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3ZM12 4.5C10.0109 4.5 8.10322 5.29018 6.6967 6.6967C5.29018 8.10322 4.5 10.0109 4.5 12C4.5 13.9891 5.29018 15.8968 6.6967 17.3033C8.10322 18.7098 10.0109 19.5 12 19.5C13.9891 19.5 15.8968 18.7098 17.3033 17.3033C18.7098 15.8968 19.5 13.9891 19.5 12C19.5 10.0109 18.7098 8.10322 17.3033 6.6967C15.8968 5.29018 13.9891 4.5 12 4.5ZM12 15C12.2984 15 12.5845 15.1185 12.7955 15.3295C13.0065 15.5405 13.125 15.8266 13.125 16.125C13.125 16.4234 13.0065 16.7095 12.7955 16.9205C12.5845 17.1315 12.2984 17.25 12 17.25C11.7016 17.25 11.4155 17.1315 11.2045 16.9205C10.9935 16.7095 10.875 16.4234 10.875 16.125C10.875 15.8266 10.9935 15.5405 11.2045 15.3295C11.4155 15.1185 11.7016 15 12 15ZM12 6.75C12.1755 6.74994 12.3455 6.81146 12.4804 6.92383C12.6152 7.03621 12.7064 7.19233 12.738 7.365L12.75 7.5V12.75C12.7503 12.9374 12.6805 13.1182 12.5542 13.2567C12.428 13.3952 12.2544 13.4814 12.0678 13.4983C11.8811 13.5152 11.6949 13.4617 11.5458 13.3482C11.3966 13.2347 11.2954 13.0694 11.262 12.885L11.25 12.75V7.5C11.25 7.30109 11.329 7.11032 11.4697 6.96967C11.6103 6.82902 11.8011 6.75 12 6.75Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default React.memo(ErrorIcon);
