import { SmartSource, SmartTarget } from './client';

const ID_DIVIDER = '_';
const REG_EXP_ID = /^(?<blockId>[^_]+)_(?<portName>.+)$/;

export const createSmartSourceId = ({
  blockId,
  portName
}: SmartSource): string => [blockId, portName].join(ID_DIVIDER);

export const createSmartTargetId = ({ blockId }: SmartTarget): string =>
  blockId;

export const parseSmartSourceId = (
  sourceId: string
): BaseResponse<SmartSource> => {
  const matchGroups = sourceId.match(REG_EXP_ID)?.groups;

  if (!matchGroups) {
    return {
      isError: true
    };
  }

  return {
    isError: false,
    data: {
      blockId: matchGroups.blockId,
      portName: matchGroups.portName
    }
  };
};

export const parseSmartTargetId = (id: string): SmartTarget => {
  return {
    blockId: id
  };
};
