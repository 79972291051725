import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const AIIcon: React.FC<CommonIconProps> = (props) => {
  return (
    <BaseIcon
      svgProps={{
        viewBox: '0 0 40 40'
      }}
      iconSize={IconSize.XXL}
      {...props}
    >
      <path
        d="M23.0462 37.5677V35.549C23.0462 35.1174 23.2177 34.7034 23.5229 34.3982C23.8281 34.093 24.242 33.9216 24.6736 33.9216H29.167C29.3985 33.9153 29.6266 33.8634 29.8381 33.7689C30.0495 33.6743 30.2402 33.5388 30.3992 33.3704C30.5582 33.2019 30.6824 33.0037 30.7646 32.7871C30.8467 32.5705 30.8854 32.3399 30.8782 32.1083V32.1417C30.8783 32.0984 30.8784 29.8197 30.8784 29.7376C30.8902 29.4264 31.0093 29.1289 31.2155 28.8955C31.5563 28.5026 31.7425 27.9992 31.7393 27.4791L31.7344 26.9885C31.7337 26.837 31.7792 26.6888 31.8647 26.5637C31.9502 26.4386 32.0718 26.3424 32.2132 26.288H32.9981C33.1435 26.2916 33.288 26.2634 33.4215 26.2056C33.555 26.1477 33.6743 26.0614 33.7711 25.9528C33.8901 25.8131 33.9665 25.6422 33.9912 25.4604C34.016 25.2786 33.9881 25.0935 33.9108 24.927C33.0553 23.1325 32.3092 21.2878 31.6766 19.4031C31.5644 19.0448 31.5105 18.6708 31.517 18.2954L31.5161 14.6219C31.5159 13.6363 31.3792 12.6556 31.1099 11.7075L31.1052 11.6913C30.6773 10.1312 29.8868 8.69426 28.7981 7.49771C28.5633 7.24454 28.3167 6.99852 28.0584 6.75964C27.8964 6.60366 27.7313 6.45007 27.5601 6.30161C25.1024 4.16538 21.9537 2.99242 18.6974 3.00005C15.3326 2.99161 12.0707 4.15947 9.47594 6.30162C7.24245 8.22908 5.73641 10.8633 5.20845 13.7658C4.6805 16.6684 5.16248 19.6642 6.57418 22.2547C8.21758 25.4366 9.50443 30.6699 9.83199 36.0967V37.5677"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M21.4357 11.0903H24.0099"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4357 21.3871H24.0099"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7228 11.0903V21.3871"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5492 21.3871L15.5426 11.0903H15.0439L12.0527 21.3871"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2993 17.8076H17.2871"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default AIIcon;
