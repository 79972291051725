import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const UnderlineIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{ viewBox: '0 0 24 24', ...(props.svgProps || {}) }}
    >
      <path
        d="M12.0002 15.9994C12.6584 15.9988 13.3099 15.869 13.9168 15.6176C14.5237 15.3661 15.0739 14.9981 15.5354 14.5349C16.4748 13.5919 17.0006 12.3219 16.9991 10.9993V5.99922C16.9991 5.73394 16.8935 5.47971 16.7064 5.2918C16.6139 5.19937 16.5039 5.12598 16.3825 5.07589C16.2611 5.02579 16.1309 5 15.9993 5C15.8678 5 15.7375 5.02579 15.6161 5.07589C15.4948 5.12598 15.3847 5.19937 15.2923 5.2918C15.1051 5.47971 14.9995 5.73394 14.9995 5.99922V10.9993C15.0005 11.7929 14.685 12.555 14.1213 13.1208C13.8444 13.3987 13.5143 13.6196 13.1501 13.7704C12.786 13.9213 12.3951 13.9992 12.0002 13.9996C11.2044 13.9996 10.4421 13.6837 9.87907 13.1208C9.3154 12.555 8.99994 11.7929 9.00086 10.9993V5.99922C9.00086 5.73394 8.89529 5.47971 8.70813 5.2918C8.61572 5.19937 8.50563 5.12598 8.38426 5.07589C8.26289 5.02579 8.13264 5 8.00109 5C7.86953 5 7.73928 5.02579 7.61791 5.07589C7.49654 5.12598 7.38645 5.19937 7.29404 5.2918C7.10688 5.47971 7.00131 5.73394 7.00131 5.99922V10.9993C7.00131 12.3257 7.52759 13.5969 8.46498 14.5349C8.9265 14.9981 9.47671 15.3661 10.0836 15.6176C10.6905 15.869 11.342 15.9988 12.0002 15.9994V15.9994ZM16.9991 18.0001H7.00131C6.73577 18.0001 6.48142 18.1051 6.29426 18.2922C6.10573 18.4807 6 18.7348 6 18.9997C6 19.2645 6.10573 19.5187 6.29426 19.7071C6.48222 19.8942 6.73577 20 7.00131 20H16.9991C17.2638 20 17.519 19.8942 17.7061 19.7071C17.8944 19.5187 18 19.2647 18 19.0001C18 18.7354 17.8944 18.4814 17.7061 18.293C17.6138 18.2004 17.5038 18.1268 17.3824 18.0765C17.261 18.0262 17.1307 18.0003 16.9991 18.0001V18.0001Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default UnderlineIcon;
