export enum WsConnectionState {
  disconnected = 'disconnected',
  connecting = 'connecting',
  connected = 'connected'
}

export enum WsSubscriptionState {
  unsubscribed = 'unsubscribed',
  subscribing = 'subscribing',
  subscribed = 'subscribed'
}

export enum WsSubscriptionEvent {
  publication = 'publication'
}

export type WsSubscriptionListener = (data) => void;

export type QueueChannel = {
  key: string;
  listeners: WsSubscriptionListener[];
  subscriptionToken: string;
};

export const wsChannelCreator = {
  conversations: (projectId: string): string =>
    `project:${projectId}-conversations`
};

export enum PubSubWsEvent {
  // удаление подписки на канал сообщений
  subscriptionRemove = 'subscriptionRemove'
}

export type PubSubWsEventSubscriptionRemoveData = {
  channel: string;
};
