export enum DateFormat {
  normal = 'dd MMMM yyyy',
  time = 'HH:mm',
  timeWithSec = 'HH:mm:ss',
  date = 'dd.MM.yy',
  dateTime = 'dd.MM.yy HH:mm',
  dateTimeFull = 'dd MMMM yyyy HH:mm',
  dateTimeFullWithoutYear = 'dd MMMM HH:mm',
  timeDate = 'HH:mm dd.MM.yy',
  timeDateTz = 'HH:mm dd.MM.yy zzz',
  dateMonth = 'd MMMM',
  monthYear = 'LLLL yyyy',
  day = 'd',
  dayMonth = 'dd.MM',
  dayMonthYear = 'dd.MM.yyyy',
  dayMonthYearTime = 'dd.MM.yyyy HH:mm',
  dayMonthYearWithoutDots = 'ddMMyyyy',
  dayMonthYearTimeWithoutDots = 'ddMMyyyyHHmm'
}
