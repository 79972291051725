import * as React from 'react';

import Typography from 'shared/newComponents/Typography';
import {
  TypographyType,
  TypographySize,
  TypographyColor
} from 'shared/newEntities/components/Typography';
import { useUiStore } from 'stores';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';

import LinkedReference from '../LinkedReference';

import './Header.modules.scss';

type Props = {
  className?: string;
};

const Header: React.FC<Props> = ({ className }) => {
  const uiStore = useUiStore();
  const { store } = useChannelsModalStore();

  return (
    <div styleName="header" className={className}>
      <Typography
        fontType={TypographyType.text}
        fontSize={TypographySize.s}
        fontColor={TypographyColor.secondary}
        styleName="header__name"
      >
        {(t) =>
          t('ChannelsModal.ChannelTable.Header.name', {
            ns: 'newContainers'
          })
        }
      </Typography>
      {!uiStore.isMobileSize && (
        <>
          {store.withAccounts && (
            <div styleName="header__item">
              <Typography
                fontType={TypographyType.text}
                fontSize={TypographySize.s}
                fontColor={TypographyColor.secondary}
              >
                {(t) =>
                  t('ChannelsModal.ChannelTable.Header.linked', {
                    ns: 'newContainers'
                  })
                }
              </Typography>
              <LinkedReference />
            </div>
          )}
          <div styleName="header__item">
            <Typography
              fontType={TypographyType.text}
              fontSize={TypographySize.s}
              fontColor={TypographyColor.secondary}
            >
              {(t) =>
                t('ChannelsModal.ChannelTable.Header.enabled', {
                  ns: 'newContainers'
                })
              }
            </Typography>
          </div>
          <div styleName="header__stub" />
        </>
      )}
      {uiStore.isMobileSize && (
        <Typography
          fontType={TypographyType.text}
          fontSize={TypographySize.s}
          fontColor={TypographyColor.secondary}
          styleName="header__item"
        >
          {(t) =>
            t(
              `ChannelsModal.ChannelTable.Header.${
                store.withAccounts
                  ? 'mobileHeaderWithAccount'
                  : 'mobileHeaderWithoutAccount'
              }`,
              {
                ns: 'newContainers'
              }
            )
          }
        </Typography>
      )}
    </div>
  );
};

export default Header;
