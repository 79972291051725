import * as React from 'react';

import {
  SelectorKey,
  SelectorOptions,
  SelectorType,
  SelectorTopItem
} from 'shared/newEntities/components/Selector';
import Typography from 'shared/newComponents/Typography';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import { LoadingStage } from 'shared/entities/meta';
import LoaderSpinner from 'shared/newComponents/animations/LoaderSpinner';
import { LoaderSpinnerSize } from 'shared/newEntities/components/LoaderSpinner';

import SelectorOption from './SelectorOption';
import TopItem from './TopItem';

import './Content.modules.scss';

type Props = {
  options: SelectorOptions;
  onClose: () => void;
  onOptionClick: (id: SelectorKey) => void;
  loadingStage?: LoadingStage;
  topItem?: SelectorTopItem;
  onRightIconClick?: (key: SelectorKey) => void;
  largeOption?: boolean;
} & (
  | {
      type: SelectorType.single;
      value: SelectorKey | null;
      withRemoving?: boolean;
    }
  | {
      type: SelectorType.multi;
      value: SelectorKey[];
    }
);

const Content: React.FC<Props> = (props) => {
  const { onClose, onOptionClick, loadingStage, options, topItem, ...rest } =
    props;

  const handleOptionClick = React.useCallback(
    (id: SelectorKey) => {
      onOptionClick(id);

      if (props.type === SelectorType.multi) {
        return;
      }

      onClose();
    },
    [props.type, onClose, onOptionClick]
  );

  const isEmpty = !options.keys.length;
  const isLoading = loadingStage === LoadingStage.LOADING;

  return (
    <>
      {topItem && <TopItem {...topItem} onClose={onClose} />}
      <div styleName="content-container">
        <div styleName="content">
          {isLoading && (
            <div className="flex-horizontal-align">
              <LoaderSpinner size={LoaderSpinnerSize.sm} />
            </div>
          )}
          {!isLoading && (
            <>
              {isEmpty && (
                <div className="flex-horizontal-align">
                  <Typography
                    fontType={TypographyType.text}
                    fontSize={TypographySize.s}
                    fontColor={TypographyColor.secondary}
                    fontWeight={TypographyWeight.heavy}
                  >
                    {(t) =>
                      t('Selector.emptyList', {
                        ns: 'newComponents'
                      })
                    }
                  </Typography>
                </div>
              )}
              {!isEmpty &&
                options.keys.map((optionKey) => {
                  const option = options.entities[optionKey];

                  if (!option) {
                    return null;
                  }

                  return (
                    <SelectorOption
                      key={optionKey}
                      {...option}
                      {...rest}
                      onClick={handleOptionClick}
                    />
                  );
                })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(Content);
