import { computed, makeObservable } from 'mobx';
import createMailTo from 'mailto-link';

import {
  validateIsEmpty,
  validateUrlWithOptionalProtocol
} from 'shared/entities/validator';
import { apiUrls } from 'shared/entities/domain';
import {
  ChannelCommonServer,
  CreateChannelError,
  CreateJivoError,
  mapCreateJivoChannelErrorToMessage
} from 'shared/entities/channels';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { FieldModel } from 'shared/models/form';
import { IRootStore } from 'shared/entities/store/rootStore';
import { CommonChannelModel } from 'shared/models/channel';
import { JIVO_SUPPORT_LINK } from 'shared/entities/app';

export class JivoCreationStore {
  readonly generatingWebhookStage: LoadingStageModel = new LoadingStageModel();
  readonly webhook: FieldModel<string | null> = new FieldModel<string | null>(
    null
  );
  readonly url: FieldModel = new FieldModel<string>('', [
    validateIsEmpty,
    validateUrlWithOptionalProtocol
  ]);
  readonly rootStore: IRootStore;
  readonly creationStage: LoadingStageModel = new LoadingStageModel();

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      isError: computed,
      webhookToken: computed,
      mailBody: computed,
      mailToLink: computed
    });
  }

  get isError(): boolean {
    return this.url.isError;
  }

  validate(): boolean {
    this.url.validate();

    return this.isError;
  }

  get webhookToken(): string | null {
    if (this.webhook.value === null) {
      return null;
    }

    const groups = this.webhook.value.match(
      /^.*\/api\/web_hooks\/jv\/(?<token>.+)$/
    )?.groups;

    if (!groups || !groups.token) {
      return null;
    }

    return groups.token;
  }

  get mailSubject(): string {
    return 'Запрос на подключение бота';
  }

  get mailBody(): string | null {
    if (this.webhook.value === null || this.webhookToken === null) {
      return null;
    }

    return `Необходим provider_id чат-бота для интеграции, а также адрес, куда чат-бот мог бы отправлять запросы. Данные для подключения:
    Адрес сервера, куда нужно отправлять запросы: ${this.webhook.value}
    Токен бота: ${this.webhookToken}
    Почта аккаунта Jivo: [ваша почта]
    Канал: [название канала]`;
  }

  get mailToLink(): string | null {
    if (this.mailBody === null) {
      return null;
    }

    return createMailTo({
      to: JIVO_SUPPORT_LINK,
      subject: this.mailSubject,
      body: this.mailBody
    });
  }

  async generateWebhook(): Promise<BaseResponse> {
    if (this.generatingWebhookStage.isLoading) {
      return {
        isError: true
      };
    }

    this.generatingWebhookStage.loading();

    const response = await this.rootStore.networkStore.api<{
      url: string;
    }>(apiUrls.CHANNELS_GET_JV_WEBHOOK_URL);

    if (!response.isError) {
      this.webhook.changeValue(response.data.url);
      this.generatingWebhookStage.success();
    } else {
      this.generatingWebhookStage.error();
    }

    return {
      isError: response.isError
    };
  }

  reset(): void {
    this.url.reset();
  }

  create = async (): Promise<BaseResponse> => {
    if (this.creationStage.isLoading) {
      return {
        isError: true
      };
    }

    const isError = this.validate();

    if (isError) {
      return {
        isError: true
      };
    }

    this.creationStage.loading();

    const response = await this.rootStore.networkStore.api<
      {
        channel: ChannelCommonServer;
      },
      CreateChannelError.channelAlreadyExist | CreateJivoError
    >(apiUrls.CHANNELS_ADD_JV, {
      method: 'POST',
      data: {
        url: this.url.value
      },
      errorsMap: mapCreateJivoChannelErrorToMessage,
      showExpectedError: false
    });

    if (!response.isError) {
      this.reset();
      const channel = CommonChannelModel.fromJson(
        response.data.channel,
        this.rootStore
      );
      this.rootStore.channelsStore.addCreatedChannel(channel);
      this.creationStage.success();

      return {
        isError: false
      };
    } else {
      if (response.data?.code) {
        this.url.setError(
          mapCreateJivoChannelErrorToMessage(response.data.code)
        );
      }
      this.creationStage.error();

      return {
        isError: true
      };
    }
  };
}
