import * as React from 'react';
import cn from 'classnames';

import { MobileChannelsModalTab } from 'shared/entities/channels';

import Tab from './Tab';

import './Tabs.modules.scss';

type Props = {
  inModal: boolean;
};

const Tabs: React.FC<Props> = ({ inModal }: Props) => {
  return (
    <div styleName={cn('tabs', inModal && 'tabs_centered')}>
      <Tab tab={MobileChannelsModalTab.createChannel} />
      <Tab tab={MobileChannelsModalTab.selectChannels} />
    </div>
  );
};

export default Tabs;
