import * as React from 'react';
import InfiniteScrollBase from 'react-infinite-scroll-component';

import LoaderSpinner from 'shared/newComponents/animations/LoaderSpinner';
import {
  LoaderSpinnerSize,
  LoaderSpinnerColor
} from 'shared/newEntities/components/LoaderSpinner';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import { InfiniteScrollProps } from 'shared/newEntities/components/InfiniteScroll';

import './InfiniteScroll.modules.scss';

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  children,
  hasMore,
  load,
  dataLength,
  isLoading,
  isInitialLoad,
  className,
  scrollableTarget,
  withStub = true
}) => {
  const showStub = withStub && !isLoading && !dataLength;

  const loader = (
    <div styleName="loader">
      <LoaderSpinner
        color={LoaderSpinnerColor.blue}
        size={LoaderSpinnerSize.md}
      />
    </div>
  );

  return (
    <div styleName="container" className={className}>
      {showStub && (
        <div styleName="stub">
          <Typography
            fontType={TypographyType.text}
            fontSize={TypographySize.s}
            fontColor={TypographyColor.secondary}
            fontWeight={TypographyWeight.heavy}
          >
            {(t) =>
              t('Selector.emptyList', {
                ns: 'newComponents'
              })
            }
          </Typography>
        </div>
      )}
      {!showStub && (
        <>
          {isInitialLoad && isLoading && (
            <div styleName="loader-container">{loader}</div>
          )}
          {!isInitialLoad && (
            <InfiniteScrollBase
              scrollableTarget={scrollableTarget}
              dataLength={dataLength}
              next={load}
              hasMore={hasMore}
              loader={loader}
            >
              {children}
            </InfiniteScrollBase>
          )}
        </>
      )}
    </div>
  );
};
export default InfiniteScroll;
