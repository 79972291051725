import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const CalendarIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M15.5547 11.2891C15.8567 11.2891 16.1016 11.0442 16.1016 10.7422C16.1016 10.4402 15.8567 10.1953 15.5547 10.1953C15.2527 10.1953 15.0078 10.4402 15.0078 10.7422C15.0078 11.0442 15.2527 11.2891 15.5547 11.2891Z"
        fill="currentColor"
      />
      <path
        d="M16.8125 6.09375H16.1016V5.54688C16.1016 5.24484 15.8567 5 15.5547 5C15.2526 5 15.0078 5.24484 15.0078 5.54688V6.09375H12.5195V5.54688C12.5195 5.24484 12.2747 5 11.9727 5C11.6706 5 11.4258 5.24484 11.4258 5.54688V6.09375H8.96484V5.54688C8.96484 5.24484 8.72001 5 8.41797 5C8.11593 5 7.87109 5.24484 7.87109 5.54688V6.09375H7.1875C5.98131 6.09375 5 7.07506 5 8.28125V16.8125C5 18.0187 5.98131 19 7.1875 19H11.3711C11.6731 19 11.918 18.7552 11.918 18.4531C11.918 18.1511 11.6731 17.9062 11.3711 17.9062H7.1875C6.58441 17.9062 6.09375 17.4156 6.09375 16.8125V8.28125C6.09375 7.67816 6.58441 7.1875 7.1875 7.1875H7.87109V7.73438C7.87109 8.03641 8.11593 8.28125 8.41797 8.28125C8.72001 8.28125 8.96484 8.03641 8.96484 7.73438V7.1875H11.4258V7.73438C11.4258 8.03641 11.6706 8.28125 11.9727 8.28125C12.2747 8.28125 12.5195 8.03641 12.5195 7.73438V7.1875H15.0078V7.73438C15.0078 8.03641 15.2526 8.28125 15.5547 8.28125C15.8567 8.28125 16.1016 8.03641 16.1016 7.73438V7.1875H16.8125C17.4156 7.1875 17.9062 7.67816 17.9062 8.28125V11.3984C17.9062 11.7005 18.1511 11.9453 18.4531 11.9453C18.7552 11.9453 19 11.7005 19 11.3984V8.28125C19 7.07506 18.0187 6.09375 16.8125 6.09375Z"
        fill="currentColor"
      />
      <path
        d="M15.6914 12.3828C13.867 12.3828 12.3828 13.867 12.3828 15.6914C12.3828 17.5158 13.867 19 15.6914 19C17.5158 19 19 17.5158 19 15.6914C19 13.867 17.5158 12.3828 15.6914 12.3828ZM15.6914 17.9062C14.4702 17.9062 13.4766 16.9127 13.4766 15.6914C13.4766 14.4701 14.4702 13.4766 15.6914 13.4766C16.9127 13.4766 17.9062 14.4701 17.9062 15.6914C17.9062 16.9127 16.9127 17.9062 15.6914 17.9062Z"
        fill="currentColor"
      />
      <path
        d="M16.4844 15.1445H16.2383V14.5703C16.2383 14.2683 15.9934 14.0234 15.6914 14.0234C15.3894 14.0234 15.1445 14.2683 15.1445 14.5703V15.6914C15.1445 15.9934 15.3894 16.2383 15.6914 16.2383H16.4844C16.7864 16.2383 17.0312 15.9934 17.0312 15.6914C17.0312 15.3894 16.7864 15.1445 16.4844 15.1445Z"
        fill="currentColor"
      />
      <path
        d="M13.1758 11.2891C13.4778 11.2891 13.7227 11.0442 13.7227 10.7422C13.7227 10.4402 13.4778 10.1953 13.1758 10.1953C12.8738 10.1953 12.6289 10.4402 12.6289 10.7422C12.6289 11.0442 12.8738 11.2891 13.1758 11.2891Z"
        fill="currentColor"
      />
      <path
        d="M10.7969 13.668C11.0989 13.668 11.3438 13.4231 11.3438 13.1211C11.3438 12.8191 11.0989 12.5742 10.7969 12.5742C10.4948 12.5742 10.25 12.8191 10.25 13.1211C10.25 13.4231 10.4948 13.668 10.7969 13.668Z"
        fill="currentColor"
      />
      <path
        d="M8.41797 11.2891C8.72 11.2891 8.96484 11.0442 8.96484 10.7422C8.96484 10.4402 8.72 10.1953 8.41797 10.1953C8.11594 10.1953 7.87109 10.4402 7.87109 10.7422C7.87109 11.0442 8.11594 11.2891 8.41797 11.2891Z"
        fill="currentColor"
      />
      <path
        d="M8.41797 13.668C8.72 13.668 8.96484 13.4231 8.96484 13.1211C8.96484 12.8191 8.72 12.5742 8.41797 12.5742C8.11594 12.5742 7.87109 12.8191 7.87109 13.1211C7.87109 13.4231 8.11594 13.668 8.41797 13.668Z"
        fill="currentColor"
      />
      <path
        d="M8.41797 16.0469C8.72 16.0469 8.96484 15.802 8.96484 15.5C8.96484 15.198 8.72 14.9531 8.41797 14.9531C8.11594 14.9531 7.87109 15.198 7.87109 15.5C7.87109 15.802 8.11594 16.0469 8.41797 16.0469Z"
        fill="currentColor"
      />
      <path
        d="M10.7969 16.0469C11.0989 16.0469 11.3438 15.802 11.3438 15.5C11.3438 15.198 11.0989 14.9531 10.7969 14.9531C10.4948 14.9531 10.25 15.198 10.25 15.5C10.25 15.802 10.4948 16.0469 10.7969 16.0469Z"
        fill="currentColor"
      />
      <path
        d="M10.7969 11.2891C11.0989 11.2891 11.3438 11.0442 11.3438 10.7422C11.3438 10.4402 11.0989 10.1953 10.7969 10.1953C10.4948 10.1953 10.25 10.4402 10.25 10.7422C10.25 11.0442 10.4948 11.2891 10.7969 11.2891Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CalendarIcon;
