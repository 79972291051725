import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStageModel } from 'shared/models/loadingStage';
import NewListModel from 'shared/models/NewListModel';
import { apiUrls } from 'shared/entities/domain';
import { AIModelKind } from 'shared/entities/smartbotAI';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';
import { AIIntentKindModel, AITargetModel } from 'shared/models/smartbotAI';
import { ISmartbotAIInfoStore } from 'shared/entities/store/smartbotAIInfoStore';
import { AIInfoServer } from 'shared/entities/smartbotAIInfo';

export class SmartbotAIInfoStore
  extends ComponentLoadingStore
  implements ISmartbotAIInfoStore
{
  readonly rootStore: IRootStore;
  readonly loadingStage: LoadingStageModel = new LoadingStageModel();
  private _messagesGeneratedCount: number | null = null;
  private _aiSpentRublesAmount: number | null = null;
  private _tariffingInfo: Record<AIModelKind, number> | null = null;
  private _defaultContextPrompt = '';
  private _defaultQuestionPrompt = '';
  readonly intentKinds: NewListModel<AIIntentKindModel> =
    new NewListModel<AIIntentKindModel>();
  readonly targets: NewListModel<AITargetModel> =
    new NewListModel<AITargetModel>();

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;
  }

  get messagesGeneratedCount(): number | null {
    return this._messagesGeneratedCount;
  }

  get aiSpentRublesAmount(): number | null {
    return this._aiSpentRublesAmount;
  }

  get tariffingInfo(): Record<AIModelKind, number> | null {
    return this._tariffingInfo;
  }

  get defaultContextPrompt(): string {
    return this._defaultContextPrompt;
  }

  get defaultQuestionPrompt(): string {
    return this._defaultQuestionPrompt;
  }

  get gpt35Price(): number {
    if (!this._tariffingInfo) {
      return 0;
    }
    return this._tariffingInfo[AIModelKind.gpt35];
  }

  get gpt4oPrice(): number {
    if (!this._tariffingInfo) {
      return 0;
    }
    return this._tariffingInfo[AIModelKind.gpt4o];
  }

  get gpt4oMiniPrice(): number {
    if (!this._tariffingInfo) {
      return 0;
    }
    return this._tariffingInfo[AIModelKind.gpt4oMini];
  }

  get yandexGptPrice(): number {
    if (!this._tariffingInfo) {
      return 0;
    }
    return this._tariffingInfo[AIModelKind.yandexGpt];
  }

  get loaded(): boolean {
    return this.loadingStage.successfullyLoaded;
  }

  get canBeLoaded(): boolean {
    return this.rootStore.initialized;
  }

  async load(): Promise<BaseResponse> {
    if (this.loadingStage.isLoading) {
      return {
        isError: true
      };
    }
    this.loadingStage.loading();

    const response = await this.rootStore.networkStore.api<AIInfoServer>(
      apiUrls.AI_BOTS_INFO
    );

    if (response.isError) {
      this.loadingStage.error();
      return {
        isError: true
      };
    }

    this.intentKinds.fillByRawData({
      raw: response.data.kinds,
      normalizer: (raw) => {
        return {
          entity: AIIntentKindModel.fromJson(raw),
          key: raw.kind
        };
      }
    });

    this.targets.fillByRawData({
      raw: response.data.targets,
      normalizer: (raw) => {
        return {
          entity: AITargetModel.fromJson(raw),
          key: raw.kind
        };
      }
    });

    this._messagesGeneratedCount = response.data.ai_messages_generated_count;

    this._aiSpentRublesAmount = response.data.ai_spent_rubles_amount;

    this._tariffingInfo = response.data.tariffication;

    this._defaultQuestionPrompt = response.data.default_question_prompt;

    this._defaultContextPrompt = response.data.default_context_prompt;

    this.loadingStage.success();
    return {
      isError: false
    };
  }

  reset(): void {
    this.loadingStage.reset();
    this._messagesGeneratedCount = null;
    this._aiSpentRublesAmount = null;
    this._tariffingInfo = null;
    this._defaultContextPrompt = '';
    this._defaultQuestionPrompt = '';
    this.intentKinds.reset();
    this.targets.reset();
  }
}
