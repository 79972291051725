import { computed, makeObservable } from 'mobx';

import { TgCommandServer, ITgCommand } from 'shared/entities/tgCommand';
import { generateId } from 'shared/entities/common/utils';
import {
  validateIsEmpty,
  validateTgCommandField
} from 'shared/entities/validator';

import { FieldModel } from '../form';
import { LoadingStageModel } from '../loadingStage';

export default class CommandModel implements ITgCommand {
  readonly id: string;
  readonly commandModel: FieldModel;
  readonly descriptionModel: FieldModel;
  readonly updatingStage: LoadingStageModel = new LoadingStageModel();
  readonly removingStage: LoadingStageModel = new LoadingStageModel();
  readonly saved: FieldModel<boolean>;

  constructor({
    command,
    description,
    saved
  }: {
    command: string;
    description: string;
    saved: boolean;
  }) {
    this.id = generateId();
    this.commandModel = new FieldModel<string>(command, [
      validateIsEmpty,
      validateTgCommandField
    ]);
    this.descriptionModel = new FieldModel<string>(description, [
      validateIsEmpty
    ]);
    this.saved = new FieldModel<boolean>(saved);

    makeObservable(this, {
      description: computed,
      title: computed,
      isError: computed,
      hasChanged: computed
    });
  }

  validate = (): boolean => {
    this.commandModel.validate();
    this.descriptionModel.validate();

    return this.isError;
  };

  get isError(): boolean {
    return this.commandModel.isError || this.descriptionModel.isError;
  }

  get description(): string {
    return this.descriptionModel.value;
  }

  get title(): string {
    return `/${this.commandModel.value}`;
  }

  get hasChanged(): boolean {
    return this.commandModel.touched || this.descriptionModel.touched;
  }

  reset(): void {
    this.commandModel.reset();
    this.descriptionModel.reset();
  }

  save(): void {
    this.commandModel.resetTouched();
    this.descriptionModel.resetTouched();
  }

  getCopy(): CommandModel {
    return new CommandModel({
      command: this.commandModel.value,
      description: this.descriptionModel.value,
      saved: this.saved.value
    });
  }

  static fromJson(raw: TgCommandServer): CommandModel {
    return new CommandModel({
      ...raw,
      saved: true
    });
  }

  static fromDefaultParams(): CommandModel {
    return new CommandModel({ command: '', description: '', saved: false });
  }

  toJson(): TgCommandServer {
    return {
      command: this.commandModel.value,
      description: this.description
    };
  }
}
