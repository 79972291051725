export enum PaymentDocumentKind {
  invoice = 'invoice',
  act = 'act',
  suppAgreement = 'supp_agreement',
  sf = 'sf',
  other = 'other'
}

export type CreatablePaymentDocumentKind = Exclude<
  PaymentDocumentKind,
  PaymentDocumentKind.sf
>;

export const creatablePaymentDocumentKindOrder: CreatablePaymentDocumentKind[] =
  [
    PaymentDocumentKind.invoice,
    PaymentDocumentKind.act,
    PaymentDocumentKind.suppAgreement,
    PaymentDocumentKind.other
  ];

export const paymentDocumentKindEntities: Record<
  PaymentDocumentKind,
  { value: PaymentDocumentKind; label: string }
> = {
  [PaymentDocumentKind.invoice]: {
    value: PaymentDocumentKind.invoice,
    label: 'Счет'
  },
  [PaymentDocumentKind.act]: {
    value: PaymentDocumentKind.act,
    label: 'Акт'
  },
  [PaymentDocumentKind.suppAgreement]: {
    value: PaymentDocumentKind.suppAgreement,
    label: 'Доп. соглашение'
  },
  [PaymentDocumentKind.sf]: {
    value: PaymentDocumentKind.sf,
    label: 'Счёт-фактура'
  },
  [PaymentDocumentKind.other]: {
    value: PaymentDocumentKind.other,
    label: 'Другое'
  }
};

export const paymentKindToShowFile: PaymentDocumentKind[] = [
  PaymentDocumentKind.suppAgreement,
  PaymentDocumentKind.other
];

export const creatablePaymentDocumentKindItems: {
  value: PaymentDocumentKind;
  label: string;
}[] = creatablePaymentDocumentKindOrder.map(
  (kind) => paymentDocumentKindEntities[kind]
);

export enum PaymentDocumentSubject {
  service = 'service',
  setup = 'setup',
  serviceAndSetup = 'service_and_setup',
  other = 'other'
}

export const paymentDocumentSubjectEntities: Record<
  PaymentDocumentSubject,
  { value: PaymentDocumentSubject; label: string }
> = {
  [PaymentDocumentSubject.service]: {
    value: PaymentDocumentSubject.service,
    label: 'Лицензия'
  },
  [PaymentDocumentSubject.setup]: {
    value: PaymentDocumentSubject.setup,
    label: 'Настройка'
  },
  [PaymentDocumentSubject.serviceAndSetup]: {
    value: PaymentDocumentSubject.serviceAndSetup,
    label: 'Лицензия и настройка'
  },
  [PaymentDocumentSubject.other]: {
    value: PaymentDocumentSubject.other,
    label: 'Другое'
  }
};

export const mapDocumentKindToDocumentSubjects: Record<
  PaymentDocumentKind,
  PaymentDocumentSubject[]
> = {
  [PaymentDocumentKind.invoice]: [
    PaymentDocumentSubject.service,
    PaymentDocumentSubject.setup,
    PaymentDocumentSubject.serviceAndSetup
  ],
  [PaymentDocumentKind.act]: [
    PaymentDocumentSubject.service,
    PaymentDocumentSubject.setup,
    PaymentDocumentSubject.serviceAndSetup
  ],
  [PaymentDocumentKind.suppAgreement]: [PaymentDocumentSubject.setup],
  [PaymentDocumentKind.sf]: [
    PaymentDocumentSubject.service,
    PaymentDocumentSubject.setup
  ],
  [PaymentDocumentKind.other]: [PaymentDocumentSubject.other]
};

export enum InvoiceStatus {
  notPaid = 'not_paid',
  markedPaid = 'marked_paid',
  paymentConfirmed = 'payment_confirmed'
}

export enum ActAndSFStatus {
  notSigned = 'not_signed',
  signed = 'signed'
}

// service stage
export enum ServiceDocumentStage {
  start = 'start',
  prolong = 'prolong',
  interm = 'interm',
  close = 'close'
}

export const serviceDocumentStageEntities: Record<
  ServiceDocumentStage,
  { value: ServiceDocumentStage; label: string }
> = {
  [ServiceDocumentStage.start]: {
    value: ServiceDocumentStage.start,
    label: 'Открытие лицензии'
  },
  [ServiceDocumentStage.prolong]: {
    value: ServiceDocumentStage.prolong,
    label: 'Продление лицензии'
  },
  [ServiceDocumentStage.interm]: {
    value: ServiceDocumentStage.interm,
    label: 'Промежуточный акт'
  },
  [ServiceDocumentStage.close]: {
    value: ServiceDocumentStage.close,
    label: 'Закрытие лицензии'
  }
};

export type ServiceStageWithServiceInvoice =
  | ServiceDocumentStage.start
  | ServiceDocumentStage.prolong;

export type ServiceStageWithServiceAndSetup = ServiceDocumentStage.start;

export type ServiceStageWithServiceAct = ServiceDocumentStage;

export type ServiceStageWithServiceSF =
  | ServiceDocumentStage.interm
  | ServiceDocumentStage.close;

export const serviceStageWithServiceInvoiceOrder: ServiceStageWithServiceInvoice[] =
  [ServiceDocumentStage.start, ServiceDocumentStage.prolong];

export const serviceStageWithServiceAndSetupOrder: ServiceStageWithServiceAndSetup[] =
  [ServiceDocumentStage.start];

export const serviceStageWithServiceActOrder: ServiceStageWithServiceAct[] = [
  ServiceDocumentStage.start,
  ServiceDocumentStage.prolong,
  ServiceDocumentStage.interm,
  ServiceDocumentStage.close
];

export const serviceStageWithServiceSFOrder: ServiceStageWithServiceSF[] = [
  ServiceDocumentStage.interm,
  ServiceDocumentStage.close
];

//service mode
export enum ServiceDocumentMode {
  sum = 'sum',
  days = 'days'
}

export const serviceDocumentModeOrder: ServiceDocumentMode[] = [
  ServiceDocumentMode.sum
];

export const serviceDocumentModeEntities: Record<
  ServiceDocumentMode,
  { value: ServiceDocumentMode; label: string }
> = {
  [ServiceDocumentMode.sum]: {
    value: ServiceDocumentMode.sum,
    label: 'Деньги на баланс'
  },
  [ServiceDocumentMode.days]: {
    value: ServiceDocumentMode.days,
    label: 'Дни использования'
  }
};

//common
export type CommonDocumentServer = {
  _id: string;
  url: string;
  date_created: string;
};

export type CommonEditionFieldsServer = {
  managers_notified: boolean;
  name: string;
};

export type CommonActParamsServer = {
  invoice_id?: string;
};

export type ServiceDocumentParamsServer = {
  stage: ServiceDocumentStage;
  mode: ServiceDocumentMode;
  sum: number;
  bonus?: number;
  days?: number;
};

export type SetupDocumentParamsServer = {
  sum: number;
  supp_ag_id?: string;
  tech_spec_url?: string;
};

type mapSubjectToDocumentParams = {
  [PaymentDocumentSubject.setup]: SetupDocumentParamsServer;
  [PaymentDocumentSubject.service]: ServiceDocumentParamsServer;
};

//invoices
export type CommonInvoiceFieldsServer = CommonDocumentServer & {
  status: InvoiceStatus;
};

export type ServiceInvoiceDocumentBaseServer = CommonEditionFieldsServer & {
  kind: PaymentDocumentKind.invoice;
  subject: PaymentDocumentSubject.service;
  params: ServiceDocumentParamsServer;
};

export type ServiceInvoiceDocumentServer = ServiceInvoiceDocumentBaseServer &
  CommonInvoiceFieldsServer;

export type SetupInvoiceDocumentBaseServer = CommonEditionFieldsServer & {
  kind: PaymentDocumentKind.invoice;
  subject: PaymentDocumentSubject.setup;
  params: SetupDocumentParamsServer;
};

export type SetupInvoiceDocumentServer = SetupInvoiceDocumentBaseServer &
  CommonInvoiceFieldsServer;

export type ServiceAndSetupInvoiceDocumentBaseServer =
  CommonEditionFieldsServer & {
    kind: PaymentDocumentKind.invoice;
    subject: PaymentDocumentSubject.serviceAndSetup;
    params: {
      service: ServiceDocumentParamsServer;
      setup: SetupDocumentParamsServer;
    };
  };

export type ServiceAndSetupInvoiceDocumentServer =
  ServiceAndSetupInvoiceDocumentBaseServer & CommonInvoiceFieldsServer;

//acts
export type CommonActAndSFFieldsServer = CommonDocumentServer & {
  status: ActAndSFStatus;
  signed_url: string | null;
};

export type ServiceActDocumentBaseServer = CommonEditionFieldsServer & {
  kind: PaymentDocumentKind.act;
  subject: PaymentDocumentSubject.service;
  params: CommonActParamsServer & ServiceDocumentParamsServer;
};

export type ServiceActDocumentServer = ServiceActDocumentBaseServer &
  CommonActAndSFFieldsServer;

export type SetupActDocumentBaseServer = CommonEditionFieldsServer & {
  kind: PaymentDocumentKind.act;
  subject: PaymentDocumentSubject.setup;
  params: CommonActParamsServer & SetupDocumentParamsServer;
};

export type SetupActDocumentServer = SetupActDocumentBaseServer &
  CommonActAndSFFieldsServer;

export type ServiceSetupActDocumentBaseServer = CommonEditionFieldsServer & {
  kind: PaymentDocumentKind.act;
  subject: PaymentDocumentSubject.serviceAndSetup;
  params: CommonActParamsServer & {
    service: ServiceDocumentParamsServer;
    setup: SetupDocumentParamsServer;
  };
};

export type ServiceSetupActDocumentServer = ServiceSetupActDocumentBaseServer &
  CommonActAndSFFieldsServer;

//supp_agreement
export type SuppAgreementDocumentBaseServer = CommonEditionFieldsServer & {
  kind: PaymentDocumentKind.suppAgreement;
  subject: PaymentDocumentSubject.setup;
  params: {};
};

export type SuppAgreementDocumentServer = SuppAgreementDocumentBaseServer &
  CommonDocumentServer & {
    signed_url: string | null;
  };

//other
export type OtherDocumentBaseServer = CommonEditionFieldsServer & {
  kind: PaymentDocumentKind.other;
  subject: PaymentDocumentSubject.other;
  params: {};
};

export type OtherDocumentServer = OtherDocumentBaseServer &
  CommonDocumentServer & {
    signed_url: string | null;
  };

// sf
type SFDocumentServer<
  T extends PaymentDocumentSubject.setup | PaymentDocumentSubject.service
> = CommonEditionFieldsServer &
  CommonActAndSFFieldsServer & {
    kind: PaymentDocumentKind.sf;
    subject: T;
    params: CommonActParamsServer & mapSubjectToDocumentParams[T];
  };

export type SetupSFDocumentServer =
  SFDocumentServer<PaymentDocumentSubject.setup>;
export type ServiceSFDocumentServer =
  SFDocumentServer<PaymentDocumentSubject.service>;

export type CreateDocumentRequestData =
  | ServiceInvoiceDocumentBaseServer
  | SetupInvoiceDocumentBaseServer
  | ServiceAndSetupInvoiceDocumentBaseServer
  | ServiceActDocumentBaseServer
  | SetupActDocumentBaseServer
  | ServiceSetupActDocumentBaseServer
  | SuppAgreementDocumentBaseServer
  | OtherDocumentBaseServer;

export type BaseDocumentServer =
  | ServiceInvoiceDocumentServer
  | SetupInvoiceDocumentServer
  | ServiceAndSetupInvoiceDocumentServer
  | ServiceActDocumentServer
  | SetupActDocumentServer
  | ServiceSetupActDocumentServer
  | SuppAgreementDocumentServer
  | OtherDocumentServer;

export type DocumentServer =
  | BaseDocumentServer
  | SetupSFDocumentServer
  | ServiceSFDocumentServer;
