import {
  ServiceDocumentStage,
  ServiceDocumentMode,
  ServiceDocumentParamsServer
} from 'shared/entities/documents';

export class ServiceDocumentCommonParamsTableModel {
  readonly stage: ServiceDocumentStage;
  readonly mode: ServiceDocumentMode;
  readonly sum: number;
  readonly days: number | null;
  readonly bonus: number | null;

  constructor({
    sum,
    days,
    stage,
    mode,
    bonus
  }: {
    stage: ServiceDocumentStage;
    sum: number;
    days: number | null;
    mode: ServiceDocumentMode;
    bonus: number | null;
  }) {
    this.sum = sum;
    this.days = days;
    this.stage = stage;
    this.mode = mode;
    this.bonus = bonus;
  }

  static fromJson(
    raw: ServiceDocumentParamsServer
  ): ServiceDocumentCommonParamsTableModel {
    return new ServiceDocumentCommonParamsTableModel({
      stage: raw.stage,
      sum: raw.sum,
      bonus: raw.bonus ?? null,
      days: raw.days === undefined ? null : raw.days,
      mode: raw.mode
    });
  }
}
