import { action, computed, makeObservable } from 'mobx';

import { IRootStore } from 'shared/entities/store/rootStore';
import { CabinetEditModel } from 'shared/models/cabinet';
import { ProjectEditModel } from 'shared/models/project';
import { CabinetCategory } from 'shared/entities/cabinet';
import { AnalyticsEvent } from 'shared/entities/analytics';
import { LoadingStageModel } from 'shared/models/loadingStage';

export default class CabinetCreationStore {
  private _rootStore: IRootStore;
  readonly creatingStage: LoadingStageModel = new LoadingStageModel();

  readonly cabinetEditModel: CabinetEditModel = new CabinetEditModel();
  readonly projectEditModel: ProjectEditModel;

  private _newUser: boolean;

  constructor({
    rootStore,
    newUser
  }: {
    rootStore: IRootStore;
    newUser: boolean;
  }) {
    this._rootStore = rootStore;
    //newUser === true: создание кабинета с регистрации
    //newUser === false: создание кабинета существующим юзером
    this._newUser = newUser;
    const { t } = this._rootStore.translationsStore;
    this.projectEditModel = new ProjectEditModel({
      name: t('CabinetCreationStore.mainProject', {
        ns: 'stores'
      })
    });

    makeObservable<CabinetCreationStore>(this, {
      buttonDisabled: computed,

      createInitialData: action.bound
    });
  }

  get buttonDisabled(): boolean {
    return this.cabinetEditModel.isError || this.creatingStage.isLoading;
  }

  async createInitialData(): Promise<BaseResponse> {
    if (this.creatingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.cabinetEditModel.validate();

    if (this.cabinetEditModel.isError) {
      return {
        isError: true
      };
    }

    this.creatingStage.loading();

    const cabinetResponse = await this._rootStore.cabinetsStore.create(
      this.cabinetEditModel
    );

    if (cabinetResponse.isError) {
      this.creatingStage.error();

      return {
        isError: true
      };
    }

    this._rootStore.analyticsStore.sendEvent(
      AnalyticsEvent.authCabinetCreationOk,
      {
        category: CabinetCategory.other,
        //данные передаем, только если регистрируется новый юзер
        auth_a_b: this._newUser ? this._rootStore.aBStore.authAB : undefined
      }
    );

    const cabinetInitResponse = await this._rootStore.initCabinet(
      cabinetResponse.data.cabinet.domain
    );

    if (cabinetInitResponse.isError) {
      this.creatingStage.error();

      return {
        isError: true
      };
    }

    const projectResponse = await this._rootStore.projectsStore.create({
      projectEditModel: this.projectEditModel
    });

    if (projectResponse.isError) {
      this.creatingStage.error();

      return {
        isError: true
      };
    }

    this.creatingStage.success();

    this._rootStore.routerStore.changeSubDomain({
      subDomain: cabinetResponse.data.cabinet.domain
    });

    return {
      isError: false
    };
  }
}
