import { observer } from 'mobx-react';
import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { useAppNotificationsStore } from 'stores/index';
import Banner from 'shared/newComponents/Banner';
import { ComponentType } from 'shared/entities/components/Component';
import { ComponentButtonProps } from 'shared/newEntities/components/Button';

const NotificationBanner: React.FC = () => {
  const appNotificationsStore = useAppNotificationsStore();

  const linkProps = React.useMemo((): ComponentButtonProps | undefined => {
    if (!appNotificationsStore.banner?.link) {
      return undefined;
    }
    if (!appNotificationsStore.banner.link.external) {
      if (appNotificationsStore.banner.link.to) {
        return {
          type: ComponentType.link,
          to: appNotificationsStore.banner.link.to
        };
      }
      if (appNotificationsStore.banner.link.onClick) {
        return {
          type: ComponentType.button,
          onClick: appNotificationsStore.banner.link.onClick
        };
      }
    }
    return {
      type: ComponentType.a,
      href: appNotificationsStore.banner.link.to ?? ''
    };
  }, [appNotificationsStore.banner?.link]);

  return (
    <AnimatePresence>
      {appNotificationsStore.banner && appNotificationsStore.bannerOpened && (
        <motion.div
          style={{ overflowY: 'hidden' }}
          initial={{
            height: 0,
            opacity: 0
          }}
          animate={{
            height: 'auto',
            opacity: 1
          }}
          exit={{ height: 0, opacity: 0 }}
        >
          <Banner
            type={appNotificationsStore.banner.type}
            title={appNotificationsStore.banner.title}
            linkProps={linkProps}
            linkTitle={appNotificationsStore.banner.link?.title}
            onClose={appNotificationsStore.closeOpenedBanner}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default observer(NotificationBanner);
