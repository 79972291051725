import { computed, makeObservable } from 'mobx';

import {
  AIBotPayload,
  AIBotServer,
  AIModelKind,
  IAIEducationParams
} from 'shared/entities/smartbotAI';
import {
  ValidatorResult,
  validateIsEmpty,
  validateTextFieldMaxLength
} from 'shared/entities/validator';
import { IRootStore } from 'shared/entities/store/rootStore';
import { AIIntentKind, AITarget } from 'shared/entities/smartbotAIInfo';

import { FieldModel } from '../form';

const MAX_TEXT_LENGTH = 5000;
export const TEXTS_MAX_LENGTH = 5500;

export class AIEducationParamsModel implements IAIEducationParams {
  readonly name: FieldModel<string>;
  readonly testMessagesAvailable: FieldModel<number | null>;
  readonly companyDescription: FieldModel<string>;
  readonly faq: FieldModel;
  readonly limitations: FieldModel;

  readonly intentKind: FieldModel<AIIntentKind>;
  readonly intentKindText: FieldModel;
  readonly target: FieldModel<AITarget>;
  readonly aiModelKind: FieldModel<AIModelKind>;
  readonly contextPrompt: FieldModel<string>;
  readonly questionPrompt: FieldModel<string>;

  constructor({
    name,
    testMessagesAvailable,
    companyDescription,

    faq,
    limitations,

    intentKind,
    intentKindText,
    target,
    aiModelKind,
    contextPrompt,
    questionPrompt
  }: {
    name: string;
    testMessagesAvailable: number | null;
    companyDescription: string;

    faq: string;
    limitations: string;

    intentKind: AIIntentKind;
    intentKindText: string;
    target: AITarget;
    aiModelKind: AIModelKind;
    contextPrompt: string;
    questionPrompt: string;
  }) {
    this.name = new FieldModel(name, [validateIsEmpty]);
    this.testMessagesAvailable = new FieldModel<number | null>(
      testMessagesAvailable
    );
    this.faq = new FieldModel<string>(faq, [
      (value) => validateTextFieldMaxLength(value, MAX_TEXT_LENGTH)
    ]);
    this.limitations = new FieldModel<string>(limitations, [
      (value) => validateTextFieldMaxLength(value, MAX_TEXT_LENGTH)
    ]);
    this.companyDescription = new FieldModel<string>(companyDescription, [
      validateIsEmpty,
      (value) => validateTextFieldMaxLength(value, MAX_TEXT_LENGTH)
    ]);

    this.intentKind = new FieldModel<AIIntentKind>(intentKind, [
      validateIsEmpty
    ]);
    this.intentKindText = new FieldModel<string>(intentKindText, [
      validateIsEmpty
    ]);
    this.target = new FieldModel<AITarget>(target, [validateIsEmpty]);
    this.aiModelKind = new FieldModel<AIModelKind>(aiModelKind);
    this.contextPrompt = new FieldModel<string>(contextPrompt, [
      validateTextFieldMaxLength
    ]);
    this.questionPrompt = new FieldModel<string>(questionPrompt, [
      validateTextFieldMaxLength
    ]);

    makeObservable(this, {
      isError: computed,
      isTouched: computed,
      companyDescriptionError: computed,
      limitationsError: computed,
      faqError: computed,
      numberOfExtraCharacters: computed,
      textsMaxLengthError: computed
    });
  }

  get isTouched(): boolean {
    if (this.target.value === AITarget.consult) {
      return true;
    }

    return (
      this.name.touched ||
      this.aiModelKind.touched ||
      this.limitations.touched ||
      this.faq.touched ||
      this.companyDescription.touched ||
      this.intentKind.touched ||
      this.intentKindText.touched ||
      this.target.touched
    );
  }

  get isError(): boolean {
    if (this.name.isError) {
      return true;
    }

    if (this.target.value === AITarget.consult) {
      return this.contextPrompt.isError || this.questionPrompt.isError;
    }

    if (
      this.textsMaxLengthError ||
      this.companyDescription.isError ||
      this.faqError ||
      this.limitationsError
    ) {
      return true;
    }

    return this.intentKind.value === AIIntentKind.other
      ? this.intentKindText.isError
      : this.intentKind.isError;
  }

  get numberOfExtraCharacters(): number {
    return (
      this.companyDescription.value.length +
      this.faq.value.length +
      this.limitations.value.length -
      TEXTS_MAX_LENGTH
    );
  }

  get textsMaxLengthError(): ValidatorResult {
    const error = (t) =>
      t('education.left.errors.textsMaxLength', { ns: 'smartbotAIPage' });
    if (this.numberOfExtraCharacters > 0) {
      return error;
    } else {
      return null;
    }
  }

  get faqError(): ValidatorResult {
    return this.textsMaxLengthError || this.faq.error;
  }

  get companyDescriptionError(): ValidatorResult {
    return this.textsMaxLengthError || this.companyDescription.error;
  }

  get limitationsError(): ValidatorResult {
    return this.textsMaxLengthError || this.limitations.error;
  }

  validateIntentKind = (): ValidatorResult => {
    if (this.intentKind.value === AIIntentKind.other) {
      return this.intentKindText.validate();
    }

    return this.intentKind.validate();
  };

  validate = (): void => {
    if (this.target.value === AITarget.consult) {
      this.contextPrompt.validate();
      this.questionPrompt.validate();
      return;
    }

    this.name.validate();
    this.limitations.validate();
    this.faq.validate();
    this.companyDescription.validate();
    this.validateIntentKind();
    this.intentKind.validate();
  };

  resetTouched = (): void => {
    this.name.resetTouched();
    this.aiModelKind.resetTouched();
    this.faq.resetTouched();
    this.limitations.resetTouched();
    this.companyDescription.resetTouched();
    this.intentKind.resetTouched();
    this.intentKindText.resetTouched();
    this.target.resetTouched();
  };

  static fromDefaultParams(rootStore: IRootStore): AIEducationParamsModel {
    const length = rootStore.smartbotAIBotsStore.bots.items.filter((bot) =>
      bot.name.startsWith('Smartbot AI')
    ).length;
    const name = !length ? 'Smartbot AI' : `Smartbot AI (${length})`;
    return new AIEducationParamsModel({
      name,
      testMessagesAvailable: null,
      companyDescription: '',
      intentKind: rootStore.smartbotAIInfoStore.intentKinds.items[0].kind,
      intentKindText: '',
      target: AITarget.consult,
      faq: '',
      limitations: rootStore.translationsStore.t(
        'education.left.limitations.defaultValue',
        {
          ns: 'smartbotAIPage'
        }
      ),
      aiModelKind: AIModelKind.gpt4oMini,
      contextPrompt: rootStore.smartbotAIInfoStore.defaultContextPrompt,
      questionPrompt: rootStore.smartbotAIInfoStore.defaultQuestionPrompt
    });
  }

  static fromJson(raw: AIBotServer): AIEducationParamsModel {
    return new AIEducationParamsModel({
      name: raw.name,
      testMessagesAvailable: raw.test_messages_available,
      companyDescription: raw.company_description || '',

      intentKind: raw.intent_kind,
      intentKindText: raw.intent_text || '',
      target: raw.target,

      faq: raw.faq || '',
      limitations: raw.limitations || '',
      aiModelKind: raw.model_type,
      contextPrompt: raw.context_prompt,
      questionPrompt: raw.question_prompt
    });
  }

  toJson(): AIBotPayload {
    return {
      name: this.name.value,
      target: this.target.value,
      company_description: this.companyDescription.value,
      intent_kind: this.intentKind.value,
      intent_text:
        this.intentKind.value === AIIntentKind.other
          ? this.intentKindText.value
          : undefined,
      faq: this.faq.value || null,
      limitations: this.limitations.value || null,
      model_type: this.aiModelKind.value,
      context_prompt: this.contextPrompt.value,
      question_prompt: this.questionPrompt.value
    };
  }
}
