import * as React from 'react';
import * as ReactDOM from 'react-dom';

type Props = {
  children?: React.ReactNode;
  portalId: string;
};

const Portal: React.FC<Props> = ({ children, portalId }: Props) => {
  const container = document.getElementById(portalId);

  return container ? ReactDOM.createPortal(children, container) : null;
};

export default Portal;
