import { DateFormat } from 'shared/entities/date';
import { IField } from 'shared/entities/form';
import { IOpenState } from 'shared/entities/state';

export enum DatePickerKind {
  range = 'range',
  oneDate = 'oneDate'
}

export type DateRangePickerValue = [Date, Date] | null;

export interface IDateRangePicker
  extends IBaseDatePicker<DatePickerKind.range> {
  readonly firstValue: IField<IDateBase | null>;
  readonly secondValue: IField<IDateBase | null>;
  readonly hoverableDate: IField<IDateBase | null>;
}

export interface IDateBase {
  readonly date: Date;
}

export type DatePickerDateFormat =
  | DateFormat.dayMonthYear
  | DateFormat.dayMonthYearTime;

export type DatePositionInInterval =
  | 'middle'
  | 'start'
  | 'end'
  | 'all-interval'
  | null;

export type DatePickerValue = Date | null;

export interface IDatePicker extends IBaseDatePicker<DatePickerKind.oneDate> {
  readonly inputDateModel: IField<IDateBase | null>;
  readonly calendarDateModel: IField<IDateBase | null>;
  readonly dateFormat: IField<DatePickerDateFormat>;
}

export interface IBaseDatePicker<T extends DatePickerKind> {
  readonly kind: T;
  readonly modalOpened: IOpenState;
  readonly applyButtonDisabled: boolean;
  readonly isEqual: boolean;

  apply(): BaseResponse<
    T extends DatePickerKind.oneDate ? DatePickerValue : DateRangePickerValue
  >;
  reset(): void;
  handleCalendarDateHover(value: IDateBase): void;
  handleCalendarDateClick(value: IDateBase): void;
}

export type DatePickerTime = {
  hours: string;
  minutes: string;
} | null;
