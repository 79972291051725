import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const LinkArrowIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M4.47915 24L16.6453 11.9457L21.1232 16.3835L24 0L7.46862 2.85043L12.1668 7.50673L1.13092e-06 19.5619L2.23958 21.781L4.47915 24Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default LinkArrowIcon;
