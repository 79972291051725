import { action, computed, makeObservable } from 'mobx';

import {
  IProjectVariablesStore,
  IVariablesStore
} from 'shared/entities/store/variablesStore';
import {
  ProjectAttachmentVarModel,
  ProjectCommonVarModel,
  ProjectBoolVarModel,
  ProjectDateVarModel,
  ProjectVarModelType
} from 'shared/models/vars';
import { LoadingStage } from 'shared/entities/meta';
import {
  ProjectBoolVarServer,
  ProjectCommonVarServer,
  ProjectVarServer,
  VarLevel,
  VarType
} from 'shared/entities/vars';
import ListModel from 'shared/models/ListModel';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';

export default class ProjectVariablesStore implements IProjectVariablesStore {
  readonly vars: ListModel<ProjectVarModelType> =
    new ListModel<ProjectVarModelType>();

  readonly variablesStore: IVariablesStore;
  readonly rootStore: IRootStore;

  constructor(variablesStore: IVariablesStore, rootStore: IRootStore) {
    makeObservable(this, {
      projectVars: computed,
      localVars: computed,

      addVariable: action,

      removeVariable: action,
      updateVariable: action
    });

    this.variablesStore = variablesStore;
    this.rootStore = rootStore;
  }

  get projectVars(): ProjectVarModelType[] {
    return this.vars.items.filter((item) => item.level === VarLevel.project);
  }

  get localVars(): ProjectVarModelType[] {
    return this.vars.items.filter((item) => item.level === VarLevel.user);
  }

  get loaded(): boolean {
    return this.vars.loadingStage === LoadingStage.SUCCESS;
  }

  async load(): Promise<BaseResponse> {
    if (this.vars.loadingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this.vars.setLoadingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      vars: ProjectVarServer[];
    }>(apiUrls.VARS_LIST);

    if (!response.isError) {
      const { entities, keys } = response.data.vars.reduce(
        (acc, varItem) => {
          const model: ProjectVarModelType =
            ProjectVariablesStore.createVariableModelFromJson(
              varItem,
              this.rootStore
            );

          return {
            ...acc,
            entities: {
              ...acc.entities,
              [varItem._id]: model
            },
            keys: [...acc.keys, varItem._id]
          };
        },
        {
          entities: {},
          keys: []
        }
      );

      this.vars.addEntities({ entities, keys, initial: true });
      this.vars.setLoadingStage(LoadingStage.SUCCESS);
    } else {
      this.vars.setLoadingStage(LoadingStage.ERROR);
    }

    return {
      isError: response.isError
    };
  }

  async removeVariable(id: string): Promise<BaseResponse> {
    const variable = this.vars.getEntity(id);

    if (!variable) {
      return {
        isError: true
      };
    }

    const response = await variable.remove();

    if (!response.isError) {
      this.vars.removeEntity(id);
    }

    return response;
  }

  addVariable(variable: ProjectVarServer): ProjectVarModelType {
    const model = ProjectVariablesStore.createVariableModelFromJson(
      variable,
      this.rootStore
    );

    this.vars.addEntity({ entity: model, key: model.id, start: true });
    return model;
  }

  updateVariable(
    variable: ProjectCommonVarServer | ProjectBoolVarServer
  ): void {
    const model = ProjectVariablesStore.createVariableModelFromJson(
      variable,
      this.rootStore
    );

    this.vars.entities[model.id] = model;
  }

  static createVariableModelFromJson = (
    variable: ProjectVarServer,
    rootStore: IRootStore
  ): ProjectVarModelType => {
    let model: ProjectVarModelType;
    switch (variable.type) {
      case VarType.bool:
        model = ProjectBoolVarModel.fromJson({
          ...variable,
          rootStore
        });
        break;
      case VarType.attachment:
        model = ProjectAttachmentVarModel.fromJson({
          ...variable,
          rootStore
        });
        break;
      case VarType.date:
        model = ProjectDateVarModel.fromJson({
          ...variable,
          rootStore
        });
        break;
      case VarType.datetime:
        model = ProjectDateVarModel.fromJson({
          ...variable,
          rootStore
        });
        break;
      default:
        model = ProjectCommonVarModel.fromJson({
          ...variable,
          rootStore
        });
    }
    return model;
  };

  reset(): void {
    this.vars.reset();
    this.vars.setLoadingStage(LoadingStage.NOT_STARTED);
  }
}
