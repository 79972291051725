import { action, computed, makeObservable } from 'mobx';

import { ComponentLoadingStore } from 'stores/componentLoadingStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import ListModel from 'shared/models/ListModel';
import { LoadingStage } from 'shared/entities/meta';
import { apiUrls } from 'shared/entities/domain';
import { GetCourseTokenModel } from 'shared/models/getCourse';
import { GetCourseTokenServer } from 'shared/entities/getCourse';
import { validateLatin, validateIsEmpty } from 'shared/entities/validator';
import { FieldModel } from 'shared/models/form';

export default class GetCourseStore extends ComponentLoadingStore {
  private rootStore: IRootStore;
  private _tokens: ListModel<GetCourseTokenModel> =
    new ListModel<GetCourseTokenModel>();
  readonly accountName: FieldModel = new FieldModel('', [
    validateIsEmpty,
    validateLatin
  ]);

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;

    makeObservable(this, {
      disabledCreating: computed,

      removeToken: action.bound
    });
  }

  get disabledCreating(): boolean {
    return !this.accountName.value.length || this.accountName.isError;
  }

  get tokens(): ListModel<GetCourseTokenModel> {
    return this._tokens;
  }

  get loaded(): boolean {
    return this._tokens.loadingStage === LoadingStage.SUCCESS;
  }

  get canBeLoaded(): boolean {
    return this.rootStore.initialized;
  }

  create = (): void => {
    if (this.disabledCreating) {
      return;
    }

    const token = GetCourseTokenModel.fromDefaultParams(
      this.accountName.value,
      this.rootStore
    );

    this.tokens.addEntity({
      entity: token,
      key: token.id
    });

    this.accountName.changeValue('');
    this.accountName.resetError();
  };

  async load(): Promise<BaseResponse> {
    if (this._tokens.loadingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this._tokens.setLoadingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      tokens: GetCourseTokenServer[];
    }>(apiUrls.GET_COURSE_TOKENS_LIST);

    if (response.isError) {
      this._tokens.setLoadingStage(LoadingStage.ERROR);
    } else {
      const { keys, entities } = response.data.tokens.reduce<{
        entities: Record<string, GetCourseTokenModel>;
        keys: string[];
      }>(
        (acc, token) => ({
          ...acc,
          entities: {
            ...acc.entities,
            [token._id]: GetCourseTokenModel.fromJson(token, this.rootStore)
          },
          keys: [...acc.keys, token._id]
        }),
        { entities: {}, keys: [] }
      );
      this._tokens.addEntities({ keys, entities, initial: true });
      this._tokens.setLoadingStage(LoadingStage.SUCCESS);
    }

    return {
      isError: response.isError
    };
  }

  async removeToken(id: string): Promise<BaseResponse> {
    const token = this._tokens.getEntity(id);

    if (!token) {
      return {
        isError: true
      };
    }

    if (!token.verified) {
      this._tokens.removeEntity(id);

      return {
        isError: false
      };
    }

    const response = await token.remove();

    if (response.isError) {
      return {
        isError: true
      };
    }

    this._tokens.removeEntity(id);

    return {
      isError: false
    };
  }

  reset(): void {
    this._tokens.reset();
    this.accountName.changeValue('');
    this.accountName.resetError();
  }
}
