import { makeObservable, computed } from 'mobx';

import { IRootStore } from 'shared/entities/store/rootStore';
import { FieldModel } from 'shared/models/form';
import {
  MobileChannelsModalTab,
  IChannelModel,
  OpenedChannelKind,
  ChannelKind,
  ConnectedViaJivoChannelKind,
  ConnectedViaWazzupChannelKind
} from 'shared/entities/channels';
import { ScenarioBaseModel } from 'shared/models/scenario';
import { AnalyticsEvent } from 'shared/entities/analytics';

export class ChannelsModalStore {
  readonly selectedChannelId: FieldModel<string | null> = new FieldModel<
    string | null
  >(null);
  readonly searchScenarios: FieldModel = new FieldModel('');
  readonly selectedChannelKind: FieldModel<OpenedChannelKind | null> =
    new FieldModel<OpenedChannelKind | null>(null);
  readonly channelsModalSelectedMobileTab: FieldModel<MobileChannelsModalTab> =
    new FieldModel<MobileChannelsModalTab>(
      MobileChannelsModalTab.createChannel
    );
  readonly connectWithJivoSelectedChannelKind: FieldModel<ConnectedViaJivoChannelKind | null> =
    new FieldModel<ConnectedViaJivoChannelKind | null>(null);

  readonly connectViaWazzupSelectedChannelKind: FieldModel<ConnectedViaWazzupChannelKind | null> =
    new FieldModel<ConnectedViaWazzupChannelKind | null>(null);

  private rootStore: IRootStore;

  readonly scenario: ScenarioBaseModel | null;
  readonly isModal: boolean;

  /**
   * Показывать привязку аккаунтов или нет
   * @type {boolean}
   */
  readonly withAccounts: boolean;

  constructor({
    rootStore,
    scenario,
    withAccounts,
    isModal
  }: {
    rootStore: IRootStore;
    scenario: ScenarioBaseModel | null;
    withAccounts: boolean;
    isModal: boolean;
  }) {
    this.rootStore = rootStore;
    this.scenario = scenario;
    this.withAccounts = withAccounts;
    this.isModal = isModal;

    makeObservable(this, {
      selectedChannel: computed,
      selectedViaJivoChannelKind: computed,
      selectedViaWazzupChannelKind: computed,
      wazzupOrJivoSelected: computed,
      withSections: computed
    });
  }

  get selectedChannel(): IChannelModel | null {
    return !this.selectedChannelId.value
      ? null
      : this.rootStore.channelsStore.channels.getEntity(
          this.selectedChannelId.value
        );
  }

  get wazzupOrJivoSelected(): boolean {
    return (
      this.selectedChannelKind.value === ChannelKind.JIVO ||
      this.selectedChannelKind.value === ChannelKind.WZ
    );
  }

  get selectedViaJivoChannelKind(): ConnectedViaJivoChannelKind | null {
    if (this.selectedChannelKind.value !== ChannelKind.JIVO) {
      return null;
    }
    return this.connectWithJivoSelectedChannelKind.value;
  }

  get selectedViaWazzupChannelKind(): ConnectedViaWazzupChannelKind | null {
    if (this.selectedChannelKind.value !== ChannelKind.WZ) {
      return null;
    }
    return this.connectViaWazzupSelectedChannelKind.value;
  }

  get withSections(): boolean {
    return this.isModal
      ? !this.rootStore.uiStore.isMobileSize
      : !this.rootStore.uiStore.isSmallContentSize;
  }

  unselect = (): void => {
    this.selectedChannelId.changeValue(null);
  };

  unSelectChannelKind = (): void => {
    this.selectedChannelKind.changeValue(null);
    this.connectWithJivoSelectedChannelKind.changeValue(null);
    this.connectViaWazzupSelectedChannelKind.changeValue(null);
  };

  handleScenarioClick = (scenarioId: string): void => {
    if (!this.selectedChannel) {
      return;
    }

    this.selectedChannel.handleChangeScenarioIds(scenarioId);
  };

  changeConnectAllChannels = (value: boolean): void => {
    if (!this.scenario) {
      return;
    }

    this.scenario.setChannelsIds(
      value ? null : this.rootStore.channelsStore.channels.keys
    );
  };

  selectChannelViaJivo = (kind: ConnectedViaJivoChannelKind | null) => {
    if (kind) {
      this.rootStore.analyticsStore.sendEvent(
        AnalyticsEvent.creationChannelViaJivoOpenForm,
        {
          channel_kind_via_jivo: kind
        }
      );
    }
    this.selectedChannelKind.changeValue(kind ? ChannelKind.JIVO : null);
    this.connectWithJivoSelectedChannelKind.changeValue(kind);
  };

  selectChannelViaWazzup = (kind: ConnectedViaWazzupChannelKind | null) => {
    if (kind) {
      this.rootStore.analyticsStore.sendEvent(
        AnalyticsEvent.creationChannelViaWazzupOpenForm,
        {
          channel_kind_via_wazzup: kind
        }
      );
    }
    this.selectedChannelKind.changeValue(kind ? ChannelKind.WZ : null);
    this.connectViaWazzupSelectedChannelKind.changeValue(kind);
  };

  isSelectedChannel = (
    via: ChannelKind.JIVO | ChannelKind.WZ,
    channelKind: ChannelKind
  ): boolean => {
    if (via === ChannelKind.JIVO) {
      return this.selectedViaJivoChannelKind === channelKind;
    }
    return this.selectedViaWazzupChannelKind === channelKind;
  };
}
