import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import {
  IUserPaymentMethodModel,
  IUserPaymentMethod,
  VatCode
} from 'shared/entities/userPayment';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import { LoadingStageModel } from 'shared/models/loadingStage';

export class UserPaymentMethodModel implements IUserPaymentMethodModel {
  readonly id: string;
  readonly externalId: string;
  readonly dateCreated: Date;
  private _isEnabled: boolean;
  readonly isTest: boolean;
  private _vatCode: VatCode;
  private _title: string;

  private _removingStage: LoadingStageModel = new LoadingStageModel();
  protected _rootStore: IRootStore;

  constructor(
    {
      id,
      isTest,
      isEnabled,
      externalId,
      title,
      dateCreated,
      vatCode
    }: IUserPaymentMethod,
    rootStore: IRootStore
  ) {
    this.id = id;
    this.isTest = isTest;
    this._isEnabled = isEnabled;
    this.externalId = externalId;
    this.dateCreated = dateCreated;
    this._vatCode = vatCode;
    this._title = title;

    this._rootStore = rootStore;

    makeObservable<
      UserPaymentMethodModel,
      '_removingStage' | '_vatCode' | '_title' | '_isEnabled'
    >(this, {
      _removingStage: observable,
      _vatCode: observable,
      _title: observable,
      _isEnabled: observable,

      removingStage: computed,
      vatCode: computed,
      title: computed,
      isEnabled: computed,

      remove: action.bound,
      updateVatCode: action,
      changeTitle: action,
      changeIsEnabled: action
    });
  }

  get isEnabled(): boolean {
    return this._isEnabled;
  }

  get vatCode(): VatCode {
    return this._vatCode;
  }

  get title(): string {
    return this._title;
  }

  get removingStage(): LoadingStageModel {
    return this._removingStage;
  }

  changeIsEnabled = (value: boolean): void => {
    this._isEnabled = value;
  };

  changeTitle = (value: string): void => {
    this._title = value;
  };

  updateVerboseName = async (): Promise<void> => {
    await this._rootStore.networkStore.api(
      apiUrls.USERS_PAYMENTS_METHODS_UPDATE,
      {
        method: 'POST',
        data: {
          _id: this.id,
          verbose_name: this._title
        }
      }
    );
  };

  updateVatCode = async (code: VatCode): Promise<void> => {
    const prevCode = this._vatCode;
    this._vatCode = code;

    const { isError } = await this._rootStore.networkStore.api(
      apiUrls.USERS_PAYMENTS_METHODS_UPDATE,
      {
        method: 'POST',
        data: {
          _id: this.id,
          vat_code: code
        }
      }
    );

    runInAction(() => {
      if (isError) {
        this._vatCode = prevCode;
      }
    });
  };

  async remove(): Promise<BaseResponse> {
    if (this.removingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.removingStage.loading();

    const response = await this._rootStore.networkStore.api(
      apiUrls.USER_PAYMENTS_METHODS_DELETE,
      {
        method: 'POST',
        data: {
          _id: this.id
        }
      }
    );

    if (response.isError) {
      this.removingStage.error();
    } else {
      this.removingStage.success();
    }

    return {
      isError: response.isError
    };
  }
}
