import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const TemplateIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      svgProps={{
        viewBox: '0 0 16 16'
      }}
      iconSize={IconSize.S}
      {...props}
    >
      <path
        d="M10.5 7H13.5C13.7652 7 14.0196 6.89464 14.2071 6.70711C14.3946 6.51957 14.5 6.26522 14.5 6V3C14.5 2.73478 14.3946 2.48043 14.2071 2.29289C14.0196 2.10536 13.7652 2 13.5 2H10.5C10.2348 2 9.98043 2.10536 9.79289 2.29289C9.60536 2.48043 9.5 2.73478 9.5 3V4H9C8.47007 4.00165 7.96232 4.21289 7.58761 4.58761C7.21289 4.96232 7.00165 5.47007 7 6V7.5H5.5V6.75C5.5 6.48478 5.39464 6.23043 5.20711 6.04289C5.01957 5.85536 4.76522 5.75 4.5 5.75H2C1.73478 5.75 1.48043 5.85536 1.29289 6.04289C1.10536 6.23043 1 6.48478 1 6.75V9.25C1 9.51522 1.10536 9.76957 1.29289 9.95711C1.48043 10.1446 1.73478 10.25 2 10.25H4.5C4.76522 10.25 5.01957 10.1446 5.20711 9.95711C5.39464 9.76957 5.5 9.51522 5.5 9.25V8.5H7V10C7.00165 10.5299 7.21289 11.0377 7.58761 11.4124C7.96232 11.7871 8.47007 11.9984 9 12H9.5V13C9.5 13.2652 9.60536 13.5196 9.79289 13.7071C9.98043 13.8946 10.2348 14 10.5 14H13.5C13.7652 14 14.0196 13.8946 14.2071 13.7071C14.3946 13.5196 14.5 13.2652 14.5 13V10C14.5 9.73478 14.3946 9.48043 14.2071 9.29289C14.0196 9.10536 13.7652 9 13.5 9H10.5C10.2348 9 9.98043 9.10536 9.79289 9.29289C9.60536 9.48043 9.5 9.73478 9.5 10V11H9C8.73478 11 8.48043 10.8946 8.29289 10.7071C8.10536 10.5196 8 10.2652 8 10V6C8 5.73478 8.10536 5.48043 8.29289 5.29289C8.48043 5.10536 8.73478 5 9 5H9.5V6C9.5 6.26522 9.60536 6.51957 9.79289 6.70711C9.98043 6.89464 10.2348 7 10.5 7ZM4.5 9.25H2V6.75H4.5V9.25ZM10.5 10H13.5V13H10.5V10ZM10.5 3H13.5V6H10.5V3Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TemplateIcon;
