import { action, makeObservable, observable, runInAction } from 'mobx';
import * as React from 'react';

import {
  IListEntity,
  IListEntityModel,
  ListEntityServer,
  ListEntityTag,
  ListEntityType,
  normalizeList
} from 'shared/entities/lists';
import { apiUrls } from 'shared/entities/domain';
import { LoadingStage } from 'shared/entities/meta';
import { IListsStore } from 'shared/entities/store/listsStore';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { IRootStore } from 'shared/entities/store/rootStore';
import TypedTransComponent from 'shared/components/TypedTransComponent';

export default class ListEntityModel implements IListEntityModel {
  id: string;
  title: string;
  type: ListEntityType;
  tag: ListEntityTag;
  count: number;

  editedTitle: string;

  removingStage: LoadingStage = LoadingStage.NOT_STARTED;
  listsStore: IListsStore;
  rootStore: IRootStore;

  constructor({
    id,
    tag,
    title,
    type,
    listsStore,
    count,
    rootStore
  }: Omit<IListEntity, 'remove' | 'removingStage'> & {
    listsStore: IListsStore;
    rootStore: IRootStore;
  }) {
    this.id = id;
    this.tag = tag;
    this.title = title;
    this.type = type;
    this.listsStore = listsStore;
    this.rootStore = rootStore;
    this.count = count;
    this.editedTitle = title;

    makeObservable(this, {
      removingStage: observable,
      listsStore: observable,
      remove: action,
      changeTitle: action,
      title: observable,
      editedTitle: observable
    });
  }

  remove = async (): Promise<BaseResponse> => {
    if (this.removingStage === LoadingStage.LOADING) {
      return { isError: true };
    }

    this.removingStage = LoadingStage.LOADING;

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.LISTS_REMOVE,
      {
        method: 'POST',
        data: {
          _id: this.id
        }
      }
    );

    runInAction(() => {
      if (!isError) {
        this.removingStage = LoadingStage.SUCCESS;
        this.rootStore.appNotificationsStore.open({
          type: AppNotificationType.success,
          title: (
            <TypedTransComponent
              ns="models"
              i18nKey="lists.ListEntityModel.notifications.listSuccessfullyRemoved"
            >
              Список <b>{{ fileName: this.title }}</b> успешно удален
            </TypedTransComponent>
          )
        });
        this.listsStore.removeList({ id: this.id, lType: this.type });
      } else {
        this.removingStage = LoadingStage.ERROR;
      }
    });

    return { isError };
  };

  changeTitle = (value: string): void => {
    this.editedTitle = value;
  };

  update = async (): Promise<void> => {
    if (this.editedTitle === this.title) {
      return;
    }

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.LISTS_RENAME,
      {
        method: 'POST',
        data: {
          _id: this.id,
          name: this.editedTitle
        }
      }
    );

    runInAction(() => {
      if (!isError) {
        this.rootStore.appNotificationsStore.open({
          type: AppNotificationType.success,
          title: (t) =>
            t(
              'lists.ListEntityModel.notifications.listNameSuccessfullyUpdated',
              {
                ns: 'models'
              }
            )
        });

        this.title = this.editedTitle;
      }
    });
  };

  static fromJson(
    raw: ListEntityServer,
    stores: {
      listsStore: IListsStore;
      rootStore: IRootStore;
    }
  ): ListEntityModel {
    return new ListEntityModel({
      ...normalizeList(raw),
      ...stores
    });
  }
}
