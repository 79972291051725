import * as React from 'react';
import PhoneInputBase from 'react-phone-number-input';

import WithMeta from 'shared/newComponents/form/WithMeta';
import { WithMetaProps } from 'shared/newEntities/components/WithMeta';
import WithBorder from 'shared/newComponents/WithBorder';

import CountrySelect from './CountrySelect';

import 'react-phone-number-input/style.css';

import './PhoneInput.modules.scss';

type Props = WithMetaProps & {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  autoComplete?: string;
  disabled?: boolean;
  className?: string;
};

const PhoneInput: React.FC<Props> = ({
  value,
  onChange,
  onBlur,
  placeholder = '9887653425',
  autoComplete,
  disabled,
  ...meta
}) => {
  const isError = !!meta.error;
  const [isFocused, setIsFocused] = React.useState(false);

  const handleChange = React.useCallback(
    (value?: string) => {
      onChange(value || '');
    },
    [onChange]
  );

  const handleFocus = React.useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = React.useCallback(() => {
    onBlur?.();
    setIsFocused(false);
  }, [onBlur]);

  return (
    <WithMeta {...meta}>
      <WithBorder
        styleName="container"
        focus={isFocused}
        error={isError}
        disabled={disabled}
      >
        <PhoneInputBase
          defaultCountry="RU"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          countrySelectComponent={CountrySelect}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onFocus={handleFocus}
        />
      </WithBorder>
    </WithMeta>
  );
};

export default React.memo(PhoneInput);
