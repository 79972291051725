import * as React from 'react';

import { useEventListener } from 'shared/entities/common/hooks';

import './Blurable.modules.scss';

type Props = {
  onBlur: (e: Event) => void;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const BlurrableWrapper: React.FC<Props> = ({
  children,
  onBlur,
  className,
  style
}: Props) => {
  const container = React.useRef<HTMLDivElement>(null);

  const handleClick = React.useCallback(
    (e: MouseEvent) => {
      if (e.target !== container.current) {
        return;
      }
      onBlur(e);
    },
    [onBlur]
  );

  useEventListener({
    eventName: 'click',
    getElement: () => container.current,
    handler: handleClick
  });

  return (
    <div
      ref={container}
      style={style}
      styleName="blurable"
      className={className}
    >
      {children}
    </div>
  );
};

export default BlurrableWrapper;
