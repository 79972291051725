import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const TransitionIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 5.95C8.5 5.77761 8.56849 5.61228 8.69038 5.49038C8.81228 5.36848 8.97761 5.3 9.15 5.3H19.55C19.7224 5.3 19.8877 5.36848 20.0096 5.49038C20.1315 5.61228 20.2 5.77761 20.2 5.95V17.65C20.2 17.8224 20.1315 17.9877 20.0096 18.1096C19.8877 18.2315 19.7224 18.3 19.55 18.3H9.15C8.97761 18.3 8.81228 18.2315 8.69038 18.1096C8.56849 17.9877 8.5 17.8224 8.5 17.65V15.05C8.5 14.8776 8.43152 14.7123 8.30962 14.5904C8.18772 14.4685 8.02239 14.4 7.85 14.4C7.67761 14.4 7.51228 14.4685 7.39038 14.5904C7.26848 14.7123 7.2 14.8776 7.2 15.05V17.65C7.2 18.1671 7.40544 18.6631 7.77114 19.0289C8.13684 19.3945 8.63283 19.6 9.15 19.6H19.55C20.0671 19.6 20.5631 19.3945 20.9289 19.0289C21.2945 18.6631 21.5 18.1671 21.5 17.65V5.95C21.5 5.43283 21.2945 4.93684 20.9289 4.57114C20.5631 4.20544 20.0671 4 19.55 4H9.15C8.63283 4 8.13684 4.20544 7.77114 4.57114C7.40544 4.93684 7.2 5.43283 7.2 5.95V8.55C7.2 8.72239 7.26848 8.88772 7.39038 9.00962C7.51228 9.13152 7.67761 9.2 7.85 9.2C8.02239 9.2 8.18772 9.13152 8.30962 9.00962C8.43152 8.88772 8.5 8.72239 8.5 8.55V5.95Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1102 12.2602C16.1707 12.1998 16.2188 12.128 16.2516 12.0491C16.2843 11.9701 16.3011 11.8855 16.3011 11.8C16.3011 11.7145 16.2843 11.6298 16.2516 11.5508C16.2188 11.4719 16.1707 11.4001 16.1102 11.3398L12.2102 7.43978C12.1498 7.37935 12.078 7.33141 11.9991 7.29871C11.9201 7.266 11.8355 7.24916 11.75 7.24916C11.6645 7.24916 11.5799 7.266 11.5009 7.29871C11.422 7.33141 11.3502 7.37935 11.2898 7.43978C11.2294 7.50022 11.1815 7.57197 11.1487 7.65093C11.116 7.72988 11.0992 7.81452 11.0992 7.89998C11.0992 7.98545 11.116 8.07008 11.1487 8.14905C11.1815 8.22801 11.2294 8.29975 11.2898 8.36018L14.0809 11.15H2.65C2.47761 11.15 2.31228 11.2185 2.19038 11.3403C2.06848 11.4623 2 11.6276 2 11.8C2 11.9724 2.06848 12.1377 2.19038 12.2596C2.31228 12.3815 2.47761 12.45 2.65 12.45H14.0809L11.2898 15.2398C11.2294 15.3002 11.1815 15.372 11.1487 15.4509C11.116 15.5299 11.0992 15.6146 11.0992 15.7C11.0992 15.7855 11.116 15.8701 11.1487 15.949C11.1815 16.028 11.2294 16.0997 11.2898 16.1602C11.3502 16.2206 11.422 16.2685 11.5009 16.3013C11.5799 16.334 11.6645 16.3508 11.75 16.3508C11.8355 16.3508 11.9201 16.334 11.9991 16.3013C12.078 16.2685 12.1498 16.2206 12.2102 16.1602L16.1102 12.2602Z"
          fill="currentColor"
        />
      </svg>
    </BaseIcon>
  );
};

export default TransitionIcon;
