import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const DndArrowIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M11.943 12.4658C12.3572 12.4658 12.693 12.13 12.693 11.7158L12.693 3.98056L15.125 6.41255C15.4178 6.70544 15.8927 6.70542 16.1856 6.41254C16.4785 6.11965 16.4785 5.64478 16.1856 5.35189L12.4733 1.63958C12.4731 1.63941 12.4729 1.63921 12.4728 1.63904C12.4555 1.62176 12.4373 1.6054 12.4184 1.58987C12.4097 1.58274 12.4005 1.57655 12.3916 1.5699C12.3809 1.56192 12.3706 1.55365 12.3595 1.54625C12.3488 1.53909 12.3376 1.53289 12.3267 1.52631C12.3166 1.52035 12.3069 1.51402 12.2965 1.5085C12.2853 1.50251 12.2738 1.49739 12.2624 1.49203C12.2517 1.48695 12.2411 1.48158 12.23 1.47703C12.2189 1.47243 12.2076 1.46874 12.1963 1.46469C12.1845 1.46048 12.1728 1.45591 12.1607 1.45228C12.1495 1.44883 12.138 1.44639 12.1266 1.44353C12.1143 1.44039 12.1021 1.43695 12.0896 1.43444C12.0765 1.43181 12.0632 1.4303 12.05 1.4284C12.039 1.42684 12.0282 1.42469 12.017 1.42367C11.9678 1.41878 11.9182 1.41879 11.869 1.42362C11.8578 1.42476 11.847 1.42682 11.836 1.42839C11.8228 1.43029 11.8096 1.4318 11.7965 1.4344C11.7839 1.43692 11.7717 1.4404 11.7593 1.4435C11.748 1.44639 11.7365 1.44885 11.7253 1.45228C11.7132 1.45592 11.7015 1.46047 11.6897 1.46469C11.6784 1.46879 11.6671 1.47246 11.656 1.47705C11.645 1.48159 11.6344 1.48697 11.6236 1.49206C11.6122 1.49744 11.6007 1.50251 11.5895 1.50853C11.5792 1.51406 11.5694 1.52028 11.5593 1.52633C11.5484 1.53289 11.5373 1.53905 11.5265 1.54626C11.5155 1.55362 11.505 1.56193 11.4944 1.56987C11.4854 1.57661 11.4763 1.58278 11.4676 1.5899C11.4487 1.60539 11.4305 1.6218 11.4133 1.63905C11.4131 1.63919 11.4129 1.63938 11.4127 1.63959L7.70035 5.3519C7.40746 5.64478 7.40752 6.11959 7.70041 6.41248C7.9933 6.70537 8.46811 6.70543 8.76099 6.41254L11.193 3.98052L11.193 11.7158C11.193 12.1301 11.5288 12.4658 11.943 12.4658Z"
        fill="currentColor"
      />
      <path
        d="M11.4676 22.2958C11.4763 22.303 11.4855 22.3092 11.4944 22.3159C11.505 22.3238 11.5154 22.332 11.5265 22.3395C11.5372 22.3466 11.5484 22.3529 11.5593 22.3594C11.5693 22.3654 11.5791 22.3717 11.5895 22.3772C11.6007 22.3832 11.6122 22.3883 11.6236 22.3937C11.6344 22.3988 11.645 22.4042 11.656 22.4087C11.6671 22.4133 11.6784 22.417 11.6897 22.421C11.7015 22.4253 11.7132 22.4298 11.7253 22.4335C11.7365 22.4369 11.748 22.4394 11.7593 22.4422C11.7717 22.4453 11.7839 22.4488 11.7964 22.4513C11.8095 22.4539 11.8228 22.4554 11.836 22.4574C11.847 22.4589 11.8578 22.461 11.869 22.4621C11.9182 22.467 11.9678 22.467 12.017 22.4621C12.0281 22.461 12.039 22.4589 12.05 22.4573C12.0632 22.4555 12.0765 22.4539 12.0896 22.4513C12.1021 22.4488 12.1143 22.4454 12.1266 22.4422C12.138 22.4393 12.1495 22.4369 12.1607 22.4335C12.1728 22.4298 12.1845 22.4253 12.1963 22.421C12.2076 22.417 12.2189 22.4133 12.23 22.4087C12.241 22.4041 12.2516 22.3987 12.2623 22.3936C12.2738 22.3883 12.2853 22.3832 12.2965 22.3773C12.3068 22.3717 12.3166 22.3654 12.3267 22.3594C12.3376 22.3529 12.3488 22.3467 12.3595 22.3395C12.3706 22.3321 12.381 22.3238 12.3916 22.3158C12.4005 22.3092 12.4096 22.3029 12.4184 22.2959C12.4375 22.2802 12.4558 22.2636 12.4733 22.2462L16.1856 18.5339C16.4785 18.241 16.4785 17.7661 16.1856 17.4732C15.8927 17.1803 15.4178 17.1803 15.125 17.4732L12.693 19.9052L12.6929 12.1699C12.693 11.7557 12.3572 11.4199 11.943 11.4199C11.5288 11.4199 11.193 11.7557 11.193 12.1699L11.193 19.9052L8.76099 17.4732C8.46811 17.1803 7.99323 17.1803 7.70034 17.4732C7.40745 17.7661 7.40746 18.241 7.70035 18.5338L11.4127 22.2462C11.4301 22.2636 11.4485 22.2802 11.4676 22.2958Z"
        fill="currentColor"
      />
      <path
        d="M12.466 12.1334C12.466 11.7192 12.1302 11.3834 11.716 11.3834L3.98075 11.3834L6.41273 8.95146C6.70562 8.65857 6.70561 8.18369 6.41272 7.89081C6.11984 7.59793 5.64496 7.59791 5.35207 7.8908L1.63977 11.6031C1.63994 11.6029 1.6396 11.6033 1.63977 11.6031C1.62249 11.6204 1.60558 11.6391 1.59005 11.658C1.58293 11.6667 1.57673 11.6759 1.57008 11.6848C1.5621 11.6955 1.55384 11.7059 1.54643 11.7169C1.53928 11.7276 1.53308 11.7388 1.52649 11.7498C1.52053 11.7598 1.5142 11.7695 1.50868 11.7799C1.50269 11.7911 1.49757 11.8026 1.49221 11.814C1.48714 11.8247 1.48177 11.8353 1.47721 11.8464C1.47261 11.8575 1.46893 11.8688 1.46488 11.8801C1.46066 11.892 1.45609 11.9036 1.45246 11.9157C1.44902 11.9269 1.44658 11.9384 1.44371 11.9498C1.44057 11.9621 1.43713 11.9743 1.43462 11.9869C1.432 12 1.43048 12.0132 1.42858 12.0264C1.42703 12.0375 1.42488 12.0482 1.42385 12.0594C1.41896 12.1086 1.41897 12.1582 1.42381 12.2074C1.42494 12.2186 1.427 12.2294 1.42857 12.2404C1.43047 12.2536 1.43198 12.2668 1.43458 12.2799C1.4371 12.2925 1.44058 12.3048 1.44368 12.3171C1.44658 12.3285 1.44903 12.3399 1.45246 12.3511C1.45611 12.3632 1.46065 12.3749 1.46487 12.3867C1.46897 12.398 1.47264 12.4093 1.47723 12.4204C1.48178 12.4314 1.48716 12.442 1.49224 12.4528C1.49762 12.4642 1.50269 12.4757 1.50871 12.4869C1.51424 12.4973 1.52047 12.507 1.52651 12.5171C1.53307 12.5281 1.53923 12.5392 1.54645 12.5499C1.5538 12.5609 1.56212 12.5714 1.57006 12.582C1.57679 12.591 1.58296 12.6001 1.59008 12.6088C1.60558 12.6277 1.62198 12.6459 1.63923 12.6632C1.63902 12.663 1.63938 12.6633 1.63923 12.6632L5.35208 16.3761C5.64497 16.669 6.11977 16.6689 6.41266 16.376C6.70555 16.0831 6.70561 15.6083 6.41273 15.3154L3.9807 12.8834L11.716 12.8834C12.1303 12.8834 12.466 12.5477 12.466 12.1334Z"
        fill="currentColor"
      />
      <path
        d="M22.296 12.609C22.3031 12.6003 22.3093 12.5911 22.316 12.5823C22.3239 12.5716 22.3321 12.5612 22.3396 12.5501C22.3467 12.5395 22.353 12.5283 22.3595 12.5173C22.3655 12.5073 22.3718 12.4975 22.3773 12.4872C22.3834 12.476 22.3884 12.4645 22.3938 12.453C22.3989 12.4423 22.4043 12.4317 22.4088 12.4207C22.4134 12.4096 22.4171 12.3983 22.4211 12.387C22.4254 12.3752 22.4299 12.3634 22.4336 12.3514C22.437 12.3401 22.4395 12.3287 22.4424 12.3173C22.4454 12.305 22.4489 12.2928 22.4514 12.2802C22.454 12.2671 22.4556 12.2539 22.4575 12.2407C22.4591 12.2296 22.4611 12.2188 22.4622 12.2076C22.4671 12.1585 22.4671 12.1089 22.4622 12.0597C22.4611 12.0485 22.459 12.0377 22.4575 12.0267C22.4556 12.0135 22.4541 12.0002 22.4514 11.9871C22.4489 11.9746 22.4455 11.9623 22.4423 11.95C22.4395 11.9387 22.437 11.9272 22.4336 11.916C22.4299 11.9039 22.4254 11.8922 22.4212 11.8803C22.4171 11.8691 22.4134 11.8577 22.4088 11.8467C22.4042 11.8357 22.3988 11.8251 22.3938 11.8143C22.3884 11.8029 22.3834 11.7913 22.3774 11.7801C22.3718 11.7699 22.3655 11.7601 22.3596 11.75C22.353 11.739 22.3468 11.7279 22.3396 11.7172C22.3322 11.7061 22.3239 11.6957 22.316 11.6851C22.3093 11.6761 22.303 11.667 22.296 11.6583C22.2803 11.6392 22.2638 11.6208 22.2463 11.6033L18.534 7.89105C18.2411 7.59815 17.7662 7.59817 17.4733 7.89105C17.1804 8.18394 17.1804 8.65881 17.4733 8.95171L19.9053 11.3837L12.17 11.3837C11.7559 11.3837 11.4201 11.7195 11.4201 12.1337C11.4201 12.5479 11.7558 12.8836 12.17 12.8837L19.9053 12.8836L17.4733 15.3157C17.1804 15.6085 17.1804 16.0834 17.4733 16.3763C17.7662 16.6692 18.2411 16.6692 18.534 16.3763L22.2463 12.664C22.2638 12.6466 22.2803 12.6282 22.296 12.609Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DndArrowIcon;
