import * as React from 'react';
import { observer } from 'mobx-react';

import DropdownContentList from 'shared/newComponents/DropdownContentList';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { ChannelKind } from 'shared/entities/channels';
import { DropdownContentItem } from 'shared/newEntities/components/DropdownContent';
import { documentationLinks } from 'shared/entities/app';

import Scenarios from './Scenarios';
import Tokens from './Tokens';
import Commands from './Commands';
import TelegramMenu from './TelegramMenu';

type Props = {
  className?: string;
};

enum ContentSectionId {
  scenarios = 'scenarios',
  tokens = 'tokens',
  telegramMenu = 'telegramMenu',
  telegramCommands = 'telegramCommands'
}

const Content: React.FC<Props> = ({ className }) => {
  const { store } = useChannelsModalStore();

  const channel = store.selectedChannel;

  const items: DropdownContentItem[] = [
    {
      id: ContentSectionId.scenarios,
      title: (t) =>
        t('ChannelsModal.SelectedChannel.Content.Scenarios.title', {
          ns: 'newContainers'
        }),
      children: <Scenarios />
    },
    {
      id: ContentSectionId.tokens,
      title: (t) =>
        t(
          `ChannelsModal.SelectedChannel.Content.Tokens.${
            store.selectedChannel?.kind === ChannelKind.VK
              ? 'titleMany'
              : 'titleOne'
          }`,
          {
            ns: 'newContainers'
          }
        ),
      children: <Tokens />
    }
  ];

  if (channel?.kind === ChannelKind.TELEGRAM) {
    items.push(
      {
        id: ContentSectionId.telegramMenu,
        title: (t) =>
          t('ChannelsModal.SelectedChannel.Content.MenuTelegram.title', {
            ns: 'newContainers'
          }),
        children: <TelegramMenu channel={channel} />
      },
      {
        id: ContentSectionId.telegramCommands,
        title: (t) =>
          t('ChannelsModal.SelectedChannel.Content.Commands.title', {
            ns: 'newContainers'
          }),
        children: <Commands channel={channel} />,
        reference: {
          title: (t) =>
            t(
              'ChannelsModal.SelectedChannel.Content.Commands.Reference.title',
              {
                ns: 'newContainers'
              }
            ),
          description: (t) =>
            t(
              'ChannelsModal.SelectedChannel.Content.Commands.Reference.description',
              {
                ns: 'newContainers'
              }
            ),
          docsLink: {
            title: (t) =>
              t(
                'ChannelsModal.SelectedChannel.Content.Commands.Reference.docsLink',
                {
                  ns: 'newContainers'
                }
              ),
            link: documentationLinks.menuForTgBot
          }
        }
      }
    );
  }

  return (
    <DropdownContentList
      className={className}
      defaultId={
        !channel
          ? null
          : channel.hasNoTokens || channel.hasTokenErrors
          ? ContentSectionId.tokens
          : null
      }
      items={items}
    />
  );
};

export default observer(Content);
