import * as React from 'react';
import cn from 'classnames';

import './WithBorder.modules.scss';

type Props = React.PropsWithChildren<{
  focus?: boolean;
  error?: boolean;
  disabled?: boolean;
  className?: string;
  borderRadius?: number;
}>;

const WithBorder = React.forwardRef<HTMLDivElement, Props>(
  ({ focus, error, children, className, borderRadius = 8 }, ref) => {
    return (
      <div
        styleName={cn(
          'container',
          focus && 'container_focus',
          error && 'container_error'
        )}
        className={className}
        style={{
          borderRadius
        }}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

export default WithBorder;
