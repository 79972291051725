import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import {
  ApiTokenScope,
  ApiTokenServer,
  IApiToken
} from 'shared/entities/apiIntegration';
import { LoadingStage } from 'shared/entities/meta';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';

export default class ApiTokenModel implements IApiToken {
  readonly id: string;
  readonly title: string;
  readonly accessToken: string;
  readonly scope: ApiTokenScope[];
  readonly dateCreated: Date;
  private _revokeTokenStage: LoadingStage = LoadingStage.NOT_STARTED;
  private rootStore: IRootStore;

  constructor(
    { id, accessToken, title, scope, dateCreated }: IApiToken,
    rootStore: IRootStore
  ) {
    this.id = id;
    this.title = title;
    this.accessToken = accessToken;
    this.scope = scope;
    this.dateCreated = dateCreated;
    this.rootStore = rootStore;

    makeObservable<ApiTokenModel, '_revokeTokenStage'>(this, {
      _revokeTokenStage: observable,

      revokeTokenStage: computed,

      revoke: action
    });
  }

  get revokeTokenStage(): LoadingStage {
    return this._revokeTokenStage;
  }

  async revoke(): Promise<BaseResponse> {
    if (this._revokeTokenStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this._revokeTokenStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api(
      apiUrls.PUBLIC_API_TOKENS_REVOKE,
      {
        method: 'POST',
        data: {
          _id: this.id
        }
      }
    );

    runInAction(() => {
      this._revokeTokenStage = response.isError
        ? LoadingStage.ERROR
        : LoadingStage.SUCCESS;
    });

    return {
      isError: response.isError
    };
  }

  static fromJson(raw: ApiTokenServer, rootStore: IRootStore): ApiTokenModel {
    return new ApiTokenModel(
      {
        id: raw._id,
        title: raw.name,
        accessToken: raw.access_token,
        scope: raw.scope,
        dateCreated: new Date(raw.date_created)
      },
      rootStore
    );
  }
}
