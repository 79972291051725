import * as React from 'react';

import AuthModal from './AuthModal';

const ServicePopups: React.FC = () => {
  return (
    <>
      <AuthModal />
    </>
  );
};

export default ServicePopups;
