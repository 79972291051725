import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const DownloadIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.S}
      svgProps={{
        viewBox: '0 0 16 16'
      }}
      {...props}
    >
      <path
        d="M8.00027 0C8.31047 0 8.5619 0.251429 8.5619 0.561633V11.3763L10.4819 9.45633C10.7007 9.23755 11.0566 9.23755 11.2754 9.45633C11.4941 9.6751 11.4941 10.031 11.2754 10.2498L8.39864 13.1265C8.29088 13.2343 8.14721 13.2898 8.00354 13.2898C7.85986 13.2898 7.71619 13.2343 7.60843 13.1265L4.7317 10.2498C4.51292 10.031 4.51292 9.6751 4.7317 9.45633C4.95047 9.23755 5.30639 9.23755 5.52517 9.45633L7.44517 11.3763V0.558367C7.4419 0.248163 7.69007 0 8.00027 0Z"
        fill="currentColor"
      />
      <path
        d="M0.911885 15.4412V12.1857C0.911885 11.8755 1.16331 11.624 1.47352 11.624C1.78372 11.624 2.03515 11.8755 2.03515 12.1857V14.8828H13.9698V12.1857C13.9698 11.8755 14.2213 11.624 14.5282 11.624C14.8351 11.624 15.0898 11.8755 15.0898 12.1857V15.4412C15.0898 15.7514 14.8384 15.9995 14.5282 15.9995H1.47352C1.16331 15.9995 0.911885 15.7514 0.911885 15.4412Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DownloadIcon;
