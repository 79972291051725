import { TranslationString } from 'shared/entities/localization';

const mapErrorCodeToMessage = <C extends string>({
  errorCode,
  map,
  defaultMessage
}: {
  errorCode: C | null;
  map: (code: C) => TranslationString;
  defaultMessage?: TranslationString;
}): TranslationString => {
  const defaultText: TranslationString =
    defaultMessage || ((t) => t('common.unknownError', { ns: 'entities' }));

  if (!errorCode) {
    return defaultText;
  }

  return map(errorCode);
};

export default mapErrorCodeToMessage;
