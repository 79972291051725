import {
  IProjectBaseVar,
  MapProjectTypeToValue,
  VarLevel,
  VarType,
  removeEnclosedSymbols
} from 'shared/entities/vars';
import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { apiUrls } from 'shared/entities/domain';

export default abstract class ProjectVarBaseModel<T extends VarType>
  implements IProjectBaseVar<T>
{
  readonly id: string;
  readonly key: string;
  readonly type: T;
  value: MapProjectTypeToValue[T];

  readonly projectId: string;
  readonly level: VarLevel;

  readonly removingStage: LoadingStageModel = new LoadingStageModel();

  readonly rootStore: IRootStore;

  readonly protectedProperty: boolean | undefined;

  constructor({
    id,
    key = '',
    type,
    value,
    level = VarLevel.project,
    projectId,
    rootStore,
    protectedProperty
  }: IProjectBaseVar<T> & { rootStore: IRootStore }) {
    this.id = id;
    this.key = key;
    this.type = type;
    this.value = value;
    this.level = level;
    this.projectId = projectId;
    this.rootStore = rootStore;
    this.protectedProperty = protectedProperty;

    this.remove = this.remove.bind(this);
  }

  get enclosedKey(): string | null {
    return removeEnclosedSymbols(this.key);
  }

  async remove(): Promise<BaseResponse> {
    if (this.removingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.removingStage.loading();

    const response = await this.rootStore.networkStore.api(
      apiUrls.VARS_DELETE,
      {
        method: 'POST',
        data: { _id: this.id }
      }
    );

    if (!response.isError) {
      this.removingStage.success();
    } else {
      this.removingStage.error();
    }

    return response;
  }
}
