import { computed, makeObservable } from 'mobx';

import { IVariablesStore } from 'shared/entities/store/variablesStore';
import { ProjectVarModelType, SpecialVarModel } from 'shared/models/vars';
import { VarType } from 'shared/entities/vars';
import { IRootStore } from 'shared/entities/store/rootStore';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';

import ProjectVariablesStore from './ProjectVariablesStore';
import SpecialVariablesStore from './SpecialVariablesStore';

export default class VariablesStore
  extends ComponentLoadingStore
  implements IVariablesStore
{
  readonly rootStore: IRootStore;

  // глобальные/локальные переменные проекта
  readonly projectVariables: ProjectVariablesStore;

  // специальные переменные
  readonly specialVariables: SpecialVariablesStore;

  constructor(rootStore: IRootStore) {
    super();

    makeObservable(this, {
      varsKeys: computed,
      variables: computed
    });
    this.rootStore = rootStore;
    this.projectVariables = new ProjectVariablesStore(this, rootStore);
    this.specialVariables = new SpecialVariablesStore(rootStore);
  }

  get loaded(): boolean {
    return this.projectVariables.loaded && this.specialVariables.loaded;
  }

  get canBeLoaded(): boolean {
    return this.rootStore.initialized && this.rootStore.userStore.isAuthorized;
  }

  get varsKeys(): string[] {
    return [
      ...this.projectVariables.vars.items.map((item) => item.key),
      ...this.specialVariables.vars.items.map((item) => item.key)
    ];
  }

  get variables(): (SpecialVarModel | ProjectVarModelType)[] {
    return [
      ...this.projectVariables.vars.items,
      ...this.specialVariables.vars.items
    ];
  }

  getProjectVariableById(id: string): ProjectVarModelType | null {
    return this.projectVariables.vars.getEntity(id);
  }

  getSpecialVariableByKey(key: string): SpecialVarModel | null {
    return this.specialVariables.vars.getEntity(key);
  }

  getProjectVariableByKey(key: string): ProjectVarModelType | null {
    const foundIndex = this.projectVariables.vars.items.findIndex(
      (varItem) => varItem.key === key
    );

    if (foundIndex !== -1) {
      return this.projectVariables.vars.items[foundIndex];
    }

    return null;
  }

  getProjectVariablesByType(type: VarType): ProjectVarModelType[] {
    return this.projectVariables.vars.items.filter(
      (varItem) => varItem.type === type
    );
  }

  getVariableByKey(key: string): ProjectVarModelType | SpecialVarModel | null {
    const projectVar = this.getProjectVariableByKey(key);

    if (projectVar) {
      return projectVar;
    }

    const specialVar = this.getSpecialVariableByKey(key);

    return specialVar || null;
  }

  getVariablesByType(type: VarType): (SpecialVarModel | ProjectVarModelType)[] {
    return [
      ...this.projectVariables.vars.items.filter(
        (varItem) => varItem.type === type
      ),
      ...this.specialVariables.vars.items.filter(
        (varItem) => varItem.type === type
      )
    ];
  }

  async load(): Promise<BaseResponse> {
    if (!this.canBeLoaded) {
      return {
        isError: true
      };
    }

    const responses = await Promise.all([
      this.projectVariables.load(),
      this.specialVariables.load()
    ]);

    return {
      isError: responses.some((response) => response.isError)
    };
  }

  reset(): void {
    this.projectVariables.reset();
    this.specialVariables.reset();
  }
}
