import * as React from 'react';

import { LoadingStage } from 'shared/entities/meta';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';
import LoaderSpinner from 'shared/newComponents/animations/LoaderSpinner';
import {
  LoaderSpinnerSize,
  LoaderSpinnerColor
} from 'shared/newEntities/components/LoaderSpinner';

import './IconButton.modules.scss';

export type ButtonIconProps = {
  Icon: React.FC<CommonIconProps>;
  href?: string;
  rel?: string;
  onClick: () => void;
  loadingStage?: LoadingStage;
  className?: string;
};

const IconButton: React.FC<ButtonIconProps> = ({
  Icon,
  onClick,
  className,
  loadingStage,
  href,
  rel
}) => {
  const isLoading = loadingStage === LoadingStage.LOADING;

  return (
    <a
      href={href}
      rel={rel}
      styleName="button"
      type="button"
      className={className}
      onClick={onClick}
    >
      {!isLoading && <Icon iconSize={IconSize.XL} />}
      {isLoading && (
        <div styleName="button__loader">
          <LoaderSpinner
            size={LoaderSpinnerSize.sm}
            color={LoaderSpinnerColor.blue}
          />
        </div>
      )}
    </a>
  );
};
export default React.memo(IconButton);
