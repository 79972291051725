import * as React from 'react';
import { useTransition, animated } from 'react-spring';

import { noop } from 'shared/entities/common/utils';

export enum AppearFrom {
  LEFT = 'LEFT',
  TOP = 'TOP',
  RIGHT = 'RIGHT',
  BOTTOM = 'BOTTOM'
}

const tranforms = {
  [AppearFrom.BOTTOM]: 'translate3d(0,80px,0)',
  [AppearFrom.TOP]: 'translate3d(0,-80px,0)',
  [AppearFrom.LEFT]: 'translate3d(-100%,0,0)',
  [AppearFrom.RIGHT]: 'translate3d(100%,0,0)'
};

type Props = {
  opened: boolean;
  appearFrom?: AppearFrom;
  children?: React.ReactNode;
  className?: string;
  onTransitionStart?: () => void;
  onTransitionEnd?: () => void;
};

const HidingArea: React.FC<Props> = ({
  opened,
  children,
  className,
  appearFrom = AppearFrom.RIGHT,
  onTransitionEnd = noop,
  onTransitionStart = noop
}: Props) => {
  const transform = tranforms[appearFrom];

  const transitions = useTransition(opened, null, {
    from: { transform, WebkitTransform: transform, opacity: 0 },
    enter: {
      transform: 'translate3d(0,0,0)',
      WebkitTransform: 'translate3d(0,0,0)',
      opacity: 1
    },
    leave: { transform, WebkitTransform: transform, opacity: 0 },
    // @ts-ignore
    onRest: onTransitionEnd,
    onStart: onTransitionStart
  });

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props} className={className}>
              {children}
            </animated.div>
          )
      )}
    </>
  );
};

export default React.memo(HidingArea);
