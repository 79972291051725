import { observer } from 'mobx-react';
import * as React from 'react';

import {
  OpenedChannelKind,
  channelKindEntities
} from 'shared/entities/channels';
import { IconSize } from 'shared/entities/components/Icon';
import { VerifiedIcon } from 'shared/newComponents/icons';

import './Account.modules.scss';

type Props = {
  kind: OpenedChannelKind;
  verified: boolean;
  className?: string;
};

const Account: React.FC<Props> = ({ kind, verified, className }) => {
  const OriginalIcon = channelKindEntities[kind].Icon;

  return (
    <div styleName="account-container" className={className}>
      <div styleName="account">
        <OriginalIcon iconSize={IconSize.L} />
      </div>
      {verified && <VerifiedIcon styleName="account__verified-icon" />}
    </div>
  );
};

export default observer(Account);
