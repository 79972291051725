import * as React from 'react';

import {
  ComponentExternalLinkProps,
  ComponentLinkProps,
  ComponentSvgProps
} from '../Component';

export enum IconSize {
  XXS = 'XXS',
  XS = 'XS',
  S = 'S',
  XM = 'XM',
  XXM = 'XXM',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XLL = 'XLL',
  XXL = 'XXL',
  XXXL = 'XXXL',
  XXXXL = 'XXXXL',
  XXLM = 'XXLM',
  XXML = 'XXML',
  XXLL = 'XXLL',

  XSS = 'XSS',
  XSM = 'XSM'
}

export enum IconStyle {
  blue = 'blue',
  gray = 'gray',
  white = 'white',
  red = 'red'
}

export const iconSideSizes = {
  [IconSize.XXXXL]: 160,
  [IconSize.XXXL]: 146,
  [IconSize.XXLL]: 60,
  [IconSize.XXML]: 50,
  [IconSize.XXLM]: 48,
  [IconSize.XXL]: 40,
  [IconSize.XLL]: 38,
  [IconSize.XL]: 32,
  [IconSize.L]: 24,
  [IconSize.XXM]: 22,
  [IconSize.XM]: 20,
  [IconSize.M]: 18,
  [IconSize.S]: 16,
  [IconSize.XSM]: 14,
  [IconSize.XS]: 12,
  [IconSize.XSS]: 10,
  [IconSize.XXS]: 8
};

export type IconActionProps =
  | ComponentLinkProps
  | ComponentExternalLinkProps
  | ComponentSvgProps;

export type CommonIconProps = {
  className?: string;
  iconSize?: IconSize;
  iconStyle?: IconStyle;
  action?: IconActionProps;
  svgProps?: React.SVGProps<SVGSVGElement>;
};

export type LogoIconProps = CommonIconProps & {
  color: 'transparent' | 'original';
};

export type IconComponent = React.FC<CommonIconProps>;

export type ChannelIconExtraProps = {
  original?: boolean;
  transparent?: boolean;
};
