import { computed, makeObservable } from 'mobx';

import { FieldModel } from 'shared/models/form';
import { generateId } from 'shared/entities/common/utils';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import { UserPaymentTelegramMethodServer } from 'shared/entities/userPayment';
import { TypeOfTgProducts } from 'shared/entities/telegramPayments';
import { IChannelModel } from 'shared/entities/channels';

import { TgPaymentsCabinetPhysicalProductsParams } from './TgPaymentsCabinetPhysicalProductsParams';
import { TgPaymentsCabinetDigitalProductsParams } from './TgPaymentsCabinetDigitalProductsParams';

type NotConnectedTelegramPaymentsCabinetData =
  | {
      typeOfTgProducts: TypeOfTgProducts.physical;
      params: TgPaymentsCabinetPhysicalProductsParams;
    }
  | {
      typeOfTgProducts: TypeOfTgProducts.digital;
      params: TgPaymentsCabinetDigitalProductsParams;
    };

export class NotConnectedTelegramPaymentsCabinet {
  readonly id: string;

  readonly physicalProductsParams: TgPaymentsCabinetPhysicalProductsParams =
    new TgPaymentsCabinetPhysicalProductsParams();
  readonly digitalProductsParams: TgPaymentsCabinetDigitalProductsParams =
    new TgPaymentsCabinetDigitalProductsParams();

  readonly data: FieldModel<NotConnectedTelegramPaymentsCabinetData> =
    new FieldModel<NotConnectedTelegramPaymentsCabinetData>({
      typeOfTgProducts: TypeOfTgProducts.physical,
      params: this.physicalProductsParams
    });

  readonly creatingStage: LoadingStageModel = new LoadingStageModel();
  readonly rootStore: IRootStore;

  constructor({ id }: { id: string }, rootStore: IRootStore) {
    this.id = id;
    this.rootStore = rootStore;

    makeObservable(this, {
      channel: computed
    });
  }

  get params():
    | TgPaymentsCabinetPhysicalProductsParams
    | TgPaymentsCabinetDigitalProductsParams {
    return this.data.value.params;
  }

  get creatingDisabled(): boolean {
    return this.params.creatingDisabled;
  }

  get channel(): IChannelModel | null {
    return this.params.channelId.value
      ? this.rootStore.channelsStore.channels.getEntity(
          this.params.channelId.value
        )
      : null;
  }

  get typeOfTgProducts(): TypeOfTgProducts {
    return this.data.value.typeOfTgProducts;
  }

  changeData = (value: TypeOfTgProducts) => {
    if (value === TypeOfTgProducts.physical) {
      this.data.changeValue({
        typeOfTgProducts: TypeOfTgProducts.physical,
        params: this.physicalProductsParams
      });
    } else {
      this.data.changeValue({
        typeOfTgProducts: TypeOfTgProducts.digital,
        params: this.digitalProductsParams
      });
    }
  };

  async create(): Promise<BaseResponse<UserPaymentTelegramMethodServer, null>> {
    this.params.validate();

    if (this.creatingStage.isLoading || this.creatingDisabled) {
      return {
        isError: true,
        data: null
      };
    }

    this.creatingStage.loading();

    const response =
      await this.rootStore.networkStore.api<UserPaymentTelegramMethodServer>(
        apiUrls.OAUTH_TG_OBTAIN_TOKEN,
        {
          method: 'POST',
          data: this.params.toJson()
        }
      );

    if (response.isError) {
      this.creatingStage.error();

      return {
        isError: true,
        data: null
      };
    } else {
      this.creatingStage.success();

      return {
        isError: false,
        data: response.data
      };
    }
  }

  static fromDefaultParams(
    rootStore: IRootStore
  ): NotConnectedTelegramPaymentsCabinet {
    return new NotConnectedTelegramPaymentsCabinet(
      { id: generateId() },
      rootStore
    );
  }
}
