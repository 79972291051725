import { SpecialVarModel } from 'shared/models/vars';
import ListModel from 'shared/models/ListModel';
import { LoadingStage } from 'shared/entities/meta';
import { ISpecialVariablesStore } from 'shared/entities/store/variablesStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import { normalizeCollection } from 'shared/entities/collection';
import { apiUrls } from 'shared/entities/domain';
import { SpecialVarServer } from 'shared/entities/vars';

export default class SpecialVariablesStore implements ISpecialVariablesStore {
  readonly vars: ListModel<SpecialVarModel> = new ListModel<SpecialVarModel>();
  readonly rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  get loaded(): boolean {
    return this.vars.loadingStage === LoadingStage.SUCCESS;
  }

  async load(): Promise<BaseResponse> {
    if (this.vars.loadingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }
    this.vars.setLoadingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      vars: SpecialVarServer[];
    }>(apiUrls.VARS_LIST_SPECIAL);

    if (response.isError) {
      this.vars.setLoadingStage(LoadingStage.ERROR);
    } else {
      const { keys, entities } = normalizeCollection<
        SpecialVarServer,
        SpecialVarModel
      >(response.data.vars, SpecialVarModel.fromJson).collection;

      this.vars.addEntities({
        entities,
        keys,
        initial: true
      });
      this.vars.setLoadingStage(LoadingStage.SUCCESS);
    }

    return {
      isError: response.isError
    };
  }

  reset(): void {
    this.vars.reset();
  }
}
