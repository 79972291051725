import * as React from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import cn from 'classnames';

import ContentWindow from 'shared/components/windows/ContentWindow';
import HidingArea, { AppearFrom } from 'shared/components/HidingArea';
import FadeBackground from 'shared/components/windows/FadeBackground';
import { TranslationNode } from 'shared/entities/localization';
import { ModalWindowSize } from 'shared/entities/modalWindow';

import './ModalWindow.modules.scss';

type Props = {
  opened: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  title?: TranslationNode;
  className?: string;
  onTransitionStart?: () => void;
  onTransitionEnd?: () => void;
  size?: ModalWindowSize;
};

const ModalWindow: React.FC<Props> = ({
  title,
  children,
  opened,
  onClose,
  className,
  onTransitionStart,
  onTransitionEnd,
  size = ModalWindowSize.s
}: Props) => {
  const content = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!content.current) {
      return;
    }

    if (opened) {
      disableBodyScroll(content.current);
    } else {
      enableBodyScroll(content.current);
    }

    return (): void => {
      if (content.current) {
        enableBodyScroll(content.current);
      }
    };
  }, [opened]);

  return (
    <FadeBackground opened={opened} onClose={onClose}>
      <HidingArea
        onTransitionStart={onTransitionStart}
        onTransitionEnd={onTransitionEnd}
        opened={opened}
        appearFrom={AppearFrom.BOTTOM}
      >
        <ContentWindow
          title={title}
          contentRef={content}
          onClose={onClose}
          className={className}
          styleName={cn('modal-window', `modal-window_${size}`)}
        >
          {children}
        </ContentWindow>
      </HidingArea>
    </FadeBackground>
  );
};

export default ModalWindow;
