import { differenceInDays } from 'date-fns';

import { TFunctionType } from '../localization';
import { ComponentType } from '../components/Component';
import { Bucket } from '../bucket';
import { DateFormat, transformDate } from '../date';
import { IListBase } from '../list';
import { IPluginsStore } from '../store/pluginsStore';
import { EntityTypes, urls } from '../domain';

import {
  mapStatsEventTypeToTitle,
  StatLabelServer,
  StatsDataItemServer,
  StatsEventsServer,
  StatsEventType,
  StatsTableDataItemServer
} from './server';
import {
  IStatLabel,
  IStatsDataItem,
  IStatsEvent,
  IStatsTableDataItem
} from './client';

const createStatEventId = (id: string, type: StatsEventType): string =>
  `${id}__${type}`;

export const getParamsFromStatEventId = (
  actualId: string
): { id: string; type: StatsEventType } => {
  const [id, type] = actualId.split('__');

  return {
    id,
    type: type as StatsEventType
  };
};

export const normalizeStatsEvents = (
  statsRaw: StatsEventsServer,
  pluginsStore: IPluginsStore,
  projectId: string,
  t: TFunctionType
): { keys: Array<string>; entities: Record<string, IStatsEvent> } => {
  return Object.keys(statsRaw).reduce(
    (acc, statsType) => {
      const { keys, entities } = statsRaw[statsType].reduce(
        (accArray, event) => {
          const actualId = createStatEventId(
            event._id,
            statsType as StatsEventType
          );

          return {
            ...accArray,
            keys: [...accArray.keys, actualId],
            entities: {
              ...accArray.entities,
              [actualId]: {
                id: actualId,
                title:
                  statsType === StatsEventType.block
                    ? pluginsStore.blockStaticMap[event.title]?.name ||
                      event.title
                    : event.title,
                type: statsType,
                aggregateProp: mapStatsEventTypeToTitle[statsType](t),
                action:
                  statsType === StatsEventType.namedBlock && event.scenario_id
                    ? {
                        type: ComponentType.link,
                        to: urls.PROJECT.tabs.SCENARIO.EDIT.create({
                          projectId,
                          entityId: event._id,
                          scenarioId: event.scenario_id,
                          bucket: Bucket.prod,
                          entityType: EntityTypes.BLOCK
                        }),
                        target: '_blank'
                      }
                    : undefined
              }
            }
          };
        },
        {
          keys: [],
          entities: {}
        }
      );

      return {
        ...acc,
        keys: [...acc.keys, ...keys],
        entities: {
          ...acc.entities,
          ...entities
        }
      };
    },
    {
      keys: [],
      entities: {}
    }
  );
};

const DIFFERENCE_IN_DAYS_TO_SHOW_TIME = 14;

export const normalizeStatsDataItem = ({
  rawItem,
  events,
  startDate,
  endDate,
  timezone
}: {
  rawItem: StatsDataItemServer;
  events: IListBase<IStatsEvent>;
  startDate: Date;
  endDate: Date;
  timezone: string;
}): IStatsDataItem => ({
  date: transformDate(
    new Date(rawItem.datetime),
    differenceInDays(endDate, startDate) > DIFFERENCE_IN_DAYS_TO_SHOW_TIME
      ? DateFormat.date
      : DateFormat.timeDate,
    false,
    timezone
  ),
  data: rawItem.data.map((dataItem) => {
    const eventId = createStatEventId(dataItem._id, dataItem.type);

    return {
      count: dataItem.count,
      title: events.entities[eventId]?.title || eventId,
      eventId
    };
  })
});

export const normalizeStatsTableDataItem = (
  raw: StatsTableDataItemServer,
  events: IListBase<IStatsEvent>
): IStatsTableDataItem => {
  const eventId = createStatEventId(raw._id, raw.type);
  return {
    id: raw._id,
    type: raw.type,
    count: raw.count,
    eventId,
    title: events.entities[eventId]?.title || eventId
  };
};

export const normalizeStatLabel = (raw: StatLabelServer): IStatLabel => ({
  id: raw._id,
  title: raw.title
});
