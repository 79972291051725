import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const ShowIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M9.54932 12C9.54932 13.3513 10.6487 14.4507 12 14.4507C13.3513 14.4507 14.4507 13.3513 14.4507 12C14.4507 10.6487 13.3513 9.54932 12 9.54932C10.6487 9.54932 9.54932 10.6487 9.54932 12Z"
        fill="currentColor"
      />
      <path
        d="M23.787 11.6318C22.612 9.6007 20.9224 7.89712 18.9008 6.7054C16.819 5.4782 14.4348 4.82915 12.0055 4.82817C12.0037 4.82817 12.0019 4.82812 12 4.82812C11.9981 4.82812 11.9964 4.82817 11.9945 4.82817C9.56519 4.82915 7.18096 5.4782 5.0992 6.7054C3.07763 7.89712 1.38797 9.60065 0.212965 11.6318L0 12L0.212965 12.3681C1.38802 14.3992 3.07763 16.1028 5.0992 17.2945C7.18096 18.5217 9.56519 19.1708 11.9945 19.1718C11.9963 19.1718 11.9981 19.1718 12 19.1718C12.0019 19.1718 12.0036 19.1718 12.0055 19.1718C14.4348 19.1708 16.819 18.5217 18.9008 17.2945C20.9224 16.1028 22.612 14.3993 23.787 12.3681L24 12L23.787 11.6318ZM17.7014 12C17.7014 15.142 15.1466 17.6985 12.0052 17.7014C12.0034 17.7014 12.0017 17.7014 12 17.7014C11.9982 17.7014 11.9965 17.7014 11.9947 17.7014C8.85331 17.6985 6.29847 15.142 6.29847 12C6.29847 8.85794 8.85331 6.30143 11.9947 6.29859C11.9965 6.29859 11.9982 6.29854 12 6.29854C12.0017 6.29854 12.0034 6.29859 12.0052 6.29859C15.1466 6.30143 17.7014 8.85794 17.7014 12ZM1.70759 12C2.83197 10.2067 4.39399 8.76359 6.20662 7.77773C5.3404 8.96308 4.82815 10.4227 4.82815 12C4.82815 13.5772 5.34035 15.0369 6.20662 16.2222C4.39399 15.2364 2.83197 13.7932 1.70759 12ZM17.7934 16.2222C18.6597 15.0369 19.1719 13.5772 19.1719 12C19.1719 10.4227 18.6597 8.96308 17.7934 7.77773C19.606 8.76359 21.168 10.2067 22.2925 12C21.168 13.7932 19.606 15.2364 17.7934 16.2222Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ShowIcon;
