import * as React from 'react';

import Portal from 'shared/components/Portal';
import HidingArea from 'shared/components/HidingArea';
import { PORTAL_IDS } from 'shared/entities/app';

import './SideWindowContainer.modules.scss';

type Props = {
  children: React.ReactNode;
  opened: boolean;
  className?: string;
};

const SideWindowContainer: React.FC<Props> = ({
  opened,
  children,
  className
}: Props) => {
  return (
    <Portal portalId={PORTAL_IDS.SIDE_WINDOW}>
      <HidingArea
        className={className}
        opened={opened}
        styleName="side-window-container"
      >
        {children}
      </HidingArea>
    </Portal>
  );
};

export default React.memo(SideWindowContainer);
