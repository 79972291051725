import { action, makeObservable, observable } from 'mobx';

const INIT_Z_INDEX = 1000;

export class ModalsStore {
  private usedZIndexes: number[] = [];

  constructor() {
    makeObservable<ModalsStore, 'usedZIndexes'>(this, {
      usedZIndexes: observable,

      useZIndex: action,
      stopUsingZIndex: action
    });
  }

  useZIndex = (): number => {
    const maxZIndex = this.usedZIndexes.length
      ? Math.max(...this.usedZIndexes)
      : INIT_Z_INDEX;

    const newZIndex = maxZIndex + 1;

    this.usedZIndexes.push(newZIndex);

    return newZIndex;
  };

  stopUsingZIndex = (zIndex: number): void => {
    this.usedZIndexes = this.usedZIndexes.filter((value) => value !== zIndex);
  };
}
