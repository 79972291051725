import * as React from 'react';
import { observer } from 'mobx-react';

import Avatar from 'shared/newComponents/user/Avatar';
import {
  AvatarSize,
  AvatarType
} from 'shared/newEntities/components/user/Avatar';
import {
  TypographySize,
  TypographyType
} from 'shared/newEntities/components/Typography';
import ExternalLink from 'shared/newComponents/ExternalLink/ExternalLink';
import { CheckMarkIcon, PlusIcon } from 'shared/newComponents/icons';
import { VKGroupModel } from 'shared/models/channel';
import { ComponentType } from 'shared/entities/components/Component';
import { IconSize } from 'shared/entities/components/Icon';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';

import './VKGroup.modules.scss';

type Props = {
  item: VKGroupModel;
  className?: string;
};

const VKGroup: React.FC<Props> = ({ item, className }) => {
  const { store: modalStore } = useChannelsModalStore();

  React.useEffect(() => {
    return () => {
      item.reset();
    };
  }, []);

  const handleCreate = React.useCallback(async () => {
    const { isError } = await item.createChannel();
    if (!isError) {
      modalStore.unSelectChannelKind();
    }
  }, []);

  const Icon = item.created.value ? CheckMarkIcon : PlusIcon;

  return (
    <div styleName="group" className={className}>
      <div styleName="group__left">
        <Avatar
          styleName="group__avatar"
          size={AvatarSize.s}
          type={AvatarType.img}
          content={item.img}
        />
        <ExternalLink
          fontType={TypographyType.text}
          fontSize={TypographySize.s}
          fontColor={null}
          href={item.url}
          styleName="group__title"
        >
          {item.name}
        </ExternalLink>
      </div>
      <Icon
        action={
          !item.created.value
            ? { type: ComponentType.svg, onClick: handleCreate }
            : undefined
        }
        styleName="group__icon"
        iconSize={IconSize.L}
      />
    </div>
  );
};

export default observer(VKGroup);
