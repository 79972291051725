import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { OpenedChannelKind } from 'shared/entities/channels';
import { useChannelsStore, useProjectStore } from 'stores/index';
import {
  TypographySize,
  TypographyType,
  TypographyWeight,
  TypographyColor
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import { urls } from 'shared/entities/domain';

import Account from './Account';

import './ChannelAccounts.modules.scss';

type Props = {
  className?: string;
  onClick?: () => void;
};
const ChannelAccounts: React.FC<Props> = ({ onClick, className }) => {
  const channelsStore = useChannelsStore();
  const projectStore = useProjectStore();

  if (
    !projectStore.projectId ||
    Object.keys(channelsStore.linkedState).length === 0
  ) {
    return null;
  }

  return (
    <Link
      to={urls.SETTINGS.PROFILE.create()}
      onClick={onClick}
      styleName="accounts"
      className={className}
    >
      {Object.entries(channelsStore.linkedState).map(([kind, verified]) => {
        return (
          <Account
            styleName="accounts__account"
            key={kind}
            kind={kind as OpenedChannelKind}
            verified={verified}
          />
        );
      })}
      <div styleName="accounts__plus">
        <Typography
          fontType={TypographyType.text}
          fontSize={TypographySize.xs}
          fontColor={TypographyColor.tertiary}
          fontWeight={TypographyWeight.heavy}
        >
          +
        </Typography>
      </div>
    </Link>
  );
};

export default observer(ChannelAccounts);
