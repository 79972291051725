import { AnalyticsEventObj } from 'shared/entities/analytics';

import { AnalyticsModel } from './AnalyticsModel';

const GTM_CONTAINER = process.env.IS_PROD
  ? 'GTM-KM4CTTZ'
  : (process.env.GTM_CONTAINER as string);

export default class GoogleAnalytics extends AnalyticsModel {
  protected get enabled(): boolean {
    return true;
  }

  protected executeScript(onSuccess: () => void, onError: () => void): void {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      // @ts-ignore
      j.async = true;
      // @ts-ignore
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      j.onload = onSuccess;
      j.onerror = onError;
      // @ts-ignore
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', GTM_CONTAINER);
  }

  protected sendEvent(event: AnalyticsEventObj): void {
    window.dataLayer.push({
      event: event.type,
      ...(event.payload || {})
    });
  }
}
