import { TranslationString } from 'shared/entities/localization';

import { VarSpecial } from '../server';

export const getVarSpecialEntity = (
  variableKey: VarSpecial
): {
  title: TranslationString;
  description: TranslationString;
  example: TranslationString;
} => {
  return {
    title: (t) =>
      t(`vars.special.${variableKey}.title`, {
        ns: 'entities'
      }),
    description: (t) =>
      t(`vars.special.${variableKey}.description`, {
        ns: 'entities'
      }),
    example: (t) =>
      t(`vars.special.${variableKey}.example`, {
        ns: 'entities'
      })
  };
};
