import {
  ConfirmedVerifiedDomainParamsServer,
  UnconfirmedVerifiedDomainParamsServer,
  DomainBaseServer
} from './server';
import {
  IVerifiedConfirmedDomainParams,
  IVerifiedDomainBase,
  IVerifiedUnconfirmedDomainParams
} from './client';

export const normalizeDomainBase = (
  raw: DomainBaseServer
): IVerifiedDomainBase => {
  return {
    id: raw._id,
    projectId: raw.project_id,
    domain: raw.domain,
    dateCreated: new Date(raw.date_created)
  };
};

export const normalizeVerifiedConfirmedDomainParams = (
  raw: ConfirmedVerifiedDomainParamsServer
): IVerifiedConfirmedDomainParams => ({
  verifiedBy: raw.verified_by,
  verifiedAt: new Date(raw.verified_at)
});

export const normalizeVerifiedUnconfirmedDomainParams = (
  raw: UnconfirmedVerifiedDomainParamsServer
): IVerifiedUnconfirmedDomainParams => ({
  verifiedBy: null,
  verifiedAt: null,
  metaContent: raw.meta_content,
  dnsContent: raw.dns_content || null,
  file: raw.file
});
