import * as React from 'react';

const useLocalAsyncStore = <T>(
  initialValueCreator: () => Promise<T> | T
): { store: T } | null => {
  const [storeCtx, setStoreCtx] = React.useState<{ store: T } | null>(null);

  React.useEffect(() => {
    const init = async () => {
      const store = await initialValueCreator();

      setStoreCtx({ store });
    };

    init();
  }, []);

  return storeCtx;
};

export default useLocalAsyncStore;
