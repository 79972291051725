import {
  VarType,
  ProjectBoolVarServer,
  IProjectBaseVar,
  varBoolEntities
} from 'shared/entities/vars';
import { IRootStore } from 'shared/entities/store/rootStore';
import { TranslationString } from 'shared/entities/localization';

import ProjectVarBaseModel from './ProjectVarBaseModel';

export default class ProjectBoolVarModel
  extends ProjectVarBaseModel<VarType.bool>
  implements IProjectBaseVar<VarType.bool>
{
  get example(): TranslationString {
    return varBoolEntities[this.value].title;
  }

  static fromJson(
    raw: ProjectBoolVarServer & { rootStore: IRootStore }
  ): ProjectBoolVarModel {
    return new ProjectBoolVarModel({
      id: raw._id,
      projectId: raw.project_id,
      level: raw.level,
      key: raw.key,
      protectedProperty: raw.protected,
      rootStore: raw.rootStore,
      type: raw.type,
      value: raw.value
    });
  }

  getCopy(): ProjectBoolVarModel {
    return new ProjectBoolVarModel({
      key: this.key,
      level: this.level,
      value: this.value,
      projectId: this.projectId,
      type: this.type,
      id: this.id,
      rootStore: this.rootStore,
      protectedProperty: this.protectedProperty
    });
  }
}
