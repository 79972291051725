import * as React from 'react';
import { observer } from 'mobx-react';
import { motion } from 'framer-motion';

import Content from '../../Content';

import { useAnimation } from './hooks';

const ChannelsList: React.FC = () => {
  const { showSelectedChannel, ref } = useAnimation();

  return (
    <motion.div ref={ref}>{!showSelectedChannel && <Content />}</motion.div>
  );
};

export default observer(ChannelsList);
