import { BaseResponseCode } from 'shared/entities/network';

import { TranslationMap, TranslationString } from '../localization';
import { SelectorEntities } from '../components/Selector';

export enum StatsEventType {
  block = 'block',
  custom = 'custom',
  namedBlock = 'named_block',
  special = 'special'
}

export type StatsEventServer = {
  _id: string;
  title: string;
  scenario_id?: string;
};

export type StatLabelServer = {
  _id: string;
  title: string;
};

export type StatsEventsServer = Record<StatsEventType, StatsEventServer[]>;

export type StatsDataItemServer = {
  datetime: string;
  data: StatsTableDataItemServer[];
};

export type StatsTableDataItemServer = {
  count: number;
  type: StatsEventType;
  _id: string;
};

export type StatsTableLoadDataItemServer = {
  type: StatsEventType;
  _id: string;
};

export enum StatsSortKind {
  events = 'events',
  users = 'users'
}

export const sortKindEntities: SelectorEntities<
  StatsSortKind,
  TranslationString
> = {
  [StatsSortKind.events]: {
    id: StatsSortKind.events,
    title: (t) => t('stats.statsSortKind.events', { ns: 'entities' })
  },
  [StatsSortKind.users]: {
    id: StatsSortKind.users,
    title: (t) => t('stats.statsSortKind.users', { ns: 'entities' })
  }
};

export const sortKindOrder = [StatsSortKind.events, StatsSortKind.users];

export const mapStatsEventTypeToTitle: TranslationMap<
  StatsEventType,
  TranslationString
> = {
  [StatsEventType.special]: (t) =>
    t('stats.statsEventType.special', { ns: 'entities' }),
  [StatsEventType.custom]: (t) =>
    t('stats.statsEventType.custom', { ns: 'entities' }),
  [StatsEventType.namedBlock]: (t) =>
    t('stats.statsEventType.namedBlock', { ns: 'entities' }),
  [StatsEventType.block]: (t) =>
    t('stats.statsEventType.block', { ns: 'entities' })
};

export const mapStatLabelCreatingErrorCodeToMessage =
  (code: BaseResponseCode.conflict): TranslationString =>
  (t) =>
    t(`stats.errors.createStatsLabel.${code}`, {
      ns: 'entities'
    });
