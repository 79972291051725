export enum AvatarType {
  img = 'img',
  name = 'name',
  title = 'title',
  count = 'count'
}

export enum AvatarSize {
  xs = 'xs',
  s = 's',
  l = 'l'
}

export enum AvatarBorderSize {
  xs = 'xs',
  s = 's'
}

export enum AvatarBorderPosition {
  right = 'right',
  left = 'left',
  full = 'full'
}

export enum AvatarBorderColor {
  white = 'white',
  main = 'main'
}

export enum AvatarColor {
  main = 'main',
  secondary = 'secondary'
}
