import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const PlusIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4445 17.4445V27.5555C17.4445 27.9386 17.2923 28.306 17.0214 28.5769C16.7505 28.8478 16.3831 29 16 29C15.6169 29 15.2495 28.8478 14.9786 28.5769C14.7077 28.306 14.5555 27.9386 14.5555 27.5555V17.4445H4.44445C4.06135 17.4445 3.69395 17.2923 3.42307 17.0214C3.15218 16.7505 3 16.3831 3 16C3 15.6169 3.15218 15.2495 3.42307 14.9786C3.69395 14.7077 4.06135 14.5555 4.44445 14.5555H14.5555V4.44445C14.5555 4.06135 14.7077 3.69395 14.9786 3.42307C15.2495 3.15218 15.6169 3 16 3C16.3831 3 16.7505 3.15218 17.0214 3.42307C17.2923 3.69395 17.4445 4.06135 17.4445 4.44445V14.5555H27.5555C27.9386 14.5555 28.306 14.7077 28.5769 14.9786C28.8478 15.2495 29 15.6169 29 16C29 16.3831 28.8478 16.7505 28.5769 17.0214C28.306 17.2923 27.9386 17.4445 27.5555 17.4445H17.4445Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default PlusIcon;
