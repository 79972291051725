import * as React from 'react';
// eslint-disable-next-line import/named
import { throttle } from 'lodash';

const BOTTOM_DISTANCE = 100;

const useInifiniteScroll = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  params?: { load: () => Promise<any>; hasMore: boolean }
) => {
  const [isAchieveBottom, setAchieveBottom] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!ref.current || !params) {
      return;
    }
    const handler = throttle(() => {
      if (!ref.current) {
        return;
      }
      if (
        ref.current.scrollHeight - ref.current.scrollTop <
        ref.current.clientHeight + BOTTOM_DISTANCE
      ) {
        setAchieveBottom(true);
      } else {
        setAchieveBottom(false);
      }
    }, 500);

    ref.current.addEventListener('scroll', handler);

    return () => {
      if (!ref.current || !params) {
        return;
      }
      ref.current.removeEventListener('scroll', handler);
    };
  }, []);

  React.useEffect(() => {
    if (!params) {
      return;
    }
    if (params.hasMore && isAchieveBottom) {
      params.load();
    }
  }, [params?.hasMore, isAchieveBottom]);
};

export default useInifiniteScroll;
