import * as React from 'react';

import {
  CommonIconProps,
  LogoIconProps,
  IconSize
} from 'shared/entities/components/Icon';
import BaseIcon from 'shared/newComponents/icons/BaseIcon';

const AvitoIcon: React.FC<LogoIconProps> = ({
  color,
  ...rest
}: LogoIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXML}
      svgProps={{
        viewBox: '0 0 50 50'
      }}
      {...rest}
    >
      <g clipPath="url(#clip0_6335_18)">
        <g mask="url(#mask0_6335_18)">
          <path
            d="M29.8502 30.5567C29.8502 22.4647 23.1688 15.9062 14.9251 15.9062C6.68137 15.9062 0 22.4647 0 30.5567C0 38.6487 6.68137 45.2071 14.9251 45.2071C23.1612 45.2071 29.8502 38.6487 29.8502 30.5567Z"
            fill={color === 'original' ? '#00E066' : 'currentColor'}
          />
          <path
            d="M48.8999 14.8613C48.8999 8.8611 43.9476 4 37.835 4C31.7224 4 26.77 8.8611 26.77 14.8613C26.77 20.8614 31.7299 25.7225 37.835 25.7225C43.9476 25.7225 48.8999 20.8614 48.8999 14.8613Z"
            fill={color === 'original' ? '#00AAFF' : 'currentColor'}
          />
          <path
            d="M47.3696 35.3504C47.3696 30.9732 43.7596 27.4297 39.3003 27.4297C34.8409 27.4297 31.231 30.9732 31.231 35.3504C31.231 39.7277 34.8409 43.2712 39.3003 43.2712C43.752 43.2786 47.3696 39.7277 47.3696 35.3504Z"
            fill={color === 'original' ? '#FF4053' : 'currentColor'}
          />
          <path
            d="M20.6745 5.38281C17.9291 5.38281 15.707 7.56391 15.707 10.2588C15.707 12.9536 17.9291 15.1348 20.6745 15.1348C23.4198 15.1348 25.6419 12.9536 25.6419 10.2588C25.6419 7.56391 23.4198 5.38281 20.6745 5.38281Z"
            fill={color === 'original' ? '#965EEB' : 'currentColor'}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6335_18">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export const AvitoOriginalIcon: React.FC<CommonIconProps> = (props) => (
  <AvitoIcon color="original" {...props} />
);

export const AvitoTransparentIcon: React.FC<CommonIconProps> = (props) => (
  <AvitoIcon color="transparent" {...props} />
);
