import * as React from 'react';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import {
  IScenarioBaseModel,
  IScenarioParams,
  normalizeScenarioBaseParamsServer,
  ScenarioBaseParamsServer,
  ScenarioKind,
  ScenarioPreset,
  ScenarioTag,
  ScenarioUpdateError,
  mapScenarioUpdateErrorToMessage,
  mapBaseErrorCodeToScenarioErrorToMessage
} from 'shared/entities/scenario';
import { apiUrls, urls } from 'shared/entities/domain';
import { LoadingStage } from 'shared/entities/meta';
import { IRootStore } from 'shared/entities/store/rootStore';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { Bucket } from 'shared/entities/bucket';
import { ComponentType } from 'shared/entities/components/Component';
import {
  renderTranslationString,
  TranslationString
} from 'shared/entities/localization';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import {
  ChannelKind,
  ChannelKindType,
  IChannelModel,
  PubSubChannelEvent,
  PubSubHandleScenarioEventPayload
} from 'shared/entities/channels';
import PubSubObserver from 'lib/PubSubObserver';
import { FieldModel } from 'shared/models/form';
import { SmartIds } from 'shared/entities/network';

export default class ScenarioBaseModel
  extends PubSubObserver
  implements IScenarioBaseModel
{
  id: string;

  updateId: string;

  name: TranslationString | string;

  readonly channelIds: FieldModel<string[] | null>;

  hasChanges: boolean;

  hasProdVersion: boolean;

  prodEnabled: boolean;

  devEnabled: boolean;

  publishStage: LoadingStage = LoadingStage.NOT_STARTED;

  resetStage: LoadingStage = LoadingStage.NOT_STARTED;

  updatingStage: LoadingStage = LoadingStage.NOT_STARTED;

  created: boolean;

  rootStore: IRootStore;

  kind: ScenarioKind;

  preset: ScenarioPreset | null;

  tags: ScenarioTag[] | null;

  dateUpdated: Date | null;

  dateCreated: Date | null;

  private abortController: AbortController | null = null;

  constructor({
    id,
    updateId,
    name,
    channelIds,
    rootStore,
    hasChanges,
    hasProdVersion,
    prodEnabled,
    devEnabled,
    kind,
    created = true,
    dateUpdated,
    tags,
    dateCreated
  }: Omit<IScenarioParams, 'channelIds'> & {
    channelIds: string[] | null;
    created?: boolean;
    rootStore: IRootStore;
  }) {
    super();

    this.id = id;
    this.updateId = updateId;
    this.name = name;
    this.channelIds = new FieldModel<string[] | null>(channelIds);
    this.rootStore = rootStore;
    this.hasChanges = hasChanges;
    this.hasProdVersion = hasProdVersion;
    this.prodEnabled = prodEnabled;
    this.devEnabled = devEnabled;
    this.created = created;
    this.kind = kind;
    this.tags = tags;
    this.dateUpdated = dateUpdated;
    this.dateCreated = dateCreated;

    makeObservable(this, {
      name: observable,
      hasChanges: observable,
      hasProdVersion: observable,
      prodEnabled: observable,
      devEnabled: observable,
      publishStage: observable,
      resetStage: observable,
      updatingStage: observable,
      kind: observable,
      dateUpdated: observable,

      channelListIds: computed,
      unlinkedChannelKinds: computed,
      channels: computed,
      connectAllChannels: computed,

      setProdEnabled: action.bound,
      setDevEnabled: action.bound,
      publish: action.bound,
      setHasChanges: action,
      reset: action.bound,
      update: action.bound
    });
  }

  get connectAllChannels(): boolean {
    return this.channelIds.value === null;
  }

  get channels(): IChannelModel[] {
    const channels: IChannelModel[] = [];

    this.channelListIds.forEach((channelId) => {
      const channel =
        this.rootStore.channelsStore.channels.getEntity(channelId);

      if (!channel) {
        return;
      }

      channels.push(channel);
    });

    return channels;
  }

  get channelListIds(): string[] {
    const channelIds = this.channelIds.value;
    if (channelIds === null) {
      return this.rootStore.channelsStore.channels.keys;
    }

    return channelIds.filter((channelId) =>
      this.rootStore.channelsStore.channels.keys.includes(channelId)
    );
  }

  get unlinkedChannelKinds(): Record<ChannelKindType, boolean> {
    return this.channelListIds.reduce<Record<ChannelKind, boolean>>(
      (acc, channelId) => {
        const channel =
          this.rootStore.channelsStore.channels.getEntity(channelId);

        if (!channel) {
          return acc;
        }

        if (acc[channel.kind] === undefined) {
          return {
            ...acc,
            [channel.kind]: channel.linked
          };
        }

        return acc;
      },
      {} as Record<ChannelKind, boolean>
    );
  }

  cancelUpdatingChannelIds(): void {
    if (!this.abortController) {
      return;
    }

    this.abortController.abort();
    this.abortController = null;
  }

  async updateChannelIds(): Promise<BaseResponse> {
    this.cancelUpdatingChannelIds();

    const abortController = new AbortController();
    this.abortController = abortController;

    const response = await this.rootStore.networkStore.api<{
      scenario: ScenarioBaseParamsServer;
    }>(apiUrls.SCENARIOS_GET, {
      method: 'GET',
      config: {
        signal: abortController.signal
      },
      data: {
        [SmartIds.bucket]: Bucket.dev,
        scenario_id: this.id
      },
      errorsMap: mapBaseErrorCodeToScenarioErrorToMessage
    });

    if (response.isError) {
      return {
        isError: true
      };
    }
    this.channelIds.changeValue(response.data.scenario.channel_ids);

    return {
      isError: false
    };
  }

  initializeReactions(): void {
    this.subscribeEvent(
      PubSubChannelEvent.addScenarioId,
      (_, payload: PubSubHandleScenarioEventPayload) => {
        if (payload.scenarioId !== this.id) {
          return;
        }

        this.updateChannelIds();
      }
    );

    this.subscribeEvent(
      PubSubChannelEvent.removeScenarioId,
      (_, payload: PubSubHandleScenarioEventPayload) => {
        if (payload.scenarioId !== this.id) {
          return;
        }

        this.updateChannelIds();
      }
    );
  }

  toJson(params: Partial<IScenarioParams>): Partial<ScenarioBaseParamsServer> {
    return {
      name: renderTranslationString(
        params.name || this.name,
        this.rootStore.translationsStore.t
      ),
      channel_ids:
        params.channelIds === undefined
          ? this.channelIds.value
          : params.channelIds.value,
      kind: params.kind || this.kind,
      update_id: params.updateId
    };
  }

  setChannelsIds = async (
    channelsIds: string[] | null
  ): Promise<BaseResponse> => {
    this.channelIds.changeValue(channelsIds);
    const response = await this.update({ channelIds: this.channelIds });

    if (!response.isError) {
      return this.rootStore.channelsStore.load();
    }

    return {
      isError: true
    };
  };

  addChannelId = async (channelId: string): Promise<BaseResponse> => {
    const channelIds = this.channelIds.value;

    if (!channelIds) {
      return {
        isError: true
      };
    }

    this.channelIds.changeValue([...channelIds, channelId]);

    const response = await this.update({ channelIds: this.channelIds });

    if (!response.isError) {
      return this.rootStore.channelsStore.load();
    }

    return {
      isError: true
    };
  };

  removeChannelId = async (value: string): Promise<BaseResponse> => {
    const channelIds = this.channelIds.value;

    if (channelIds === null) {
      return {
        isError: true
      };
    }

    this.channelIds.changeValue(channelIds.filter((item) => item != value));

    const response = await this.update({ channelIds: this.channelIds });

    if (!response.isError) {
      return this.rootStore.channelsStore.load();
    }

    return {
      isError: true
    };
  };

  async update(params: Partial<IScenarioParams>): Promise<BaseResponse> {
    if (this.updatingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    const channelIds = this.channelIds.value;

    const prevParams: {
      name: string | TranslationString;
      channelIds: FieldModel<string[] | null>;
    } = {
      name: this.name,
      channelIds: new FieldModel(channelIds ? [...channelIds] : null)
    };

    this.updatingStage = LoadingStage.LOADING;

    const response = await this.rootStore.networkStore.api<
      {
        scenario: ScenarioBaseParamsServer;
      },
      ScenarioUpdateError
    >(apiUrls.SCENARIOS_UPDATE, {
      method: 'POST',
      data: {
        ...this.toJson(params),
        _id: this.id,
        bucket: Bucket.dev
      },
      showExpectedError: false,
      errorsMap: mapScenarioUpdateErrorToMessage
    });

    if (!response.isError) {
      runInAction(() => {
        this.name = response.data.scenario.name;
        this.updateId = response.data.scenario.update_id;
        this.channelIds.changeValue(response.data.scenario.channel_ids);
        this.dateUpdated = new Date(response.data.scenario.date_updated);
        this.updatingStage = LoadingStage.SUCCESS;
      });
    } else {
      runInAction(() => {
        this.updatingStage = LoadingStage.ERROR;
        this.name = prevParams.name;
        if (prevParams.channelIds) {
          this.channelIds.changeValue(prevParams.channelIds.value);
        }
      });
    }

    return {
      isError: response.isError
    };
  }

  async publish(): Promise<BaseResponse> {
    if (this.publishStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this.publishStage = LoadingStage.LOADING;

    const publishResponse = await this.rootStore.networkStore.api(
      apiUrls.PROJECTS_PUBLISH,
      {
        method: 'POST',
        data: {
          scenario_id: this.id
        }
      }
    );

    if (!publishResponse.isError) {
      const scenarioListResponse = await this.rootStore.networkStore.api<{
        scenarios: Array<ScenarioBaseParamsServer>;
      }>(apiUrls.SCENARIOS_LIST, {
        method: 'GET',
        data: {
          offset: 0,
          limit: 1,
          has_changes: true
        }
      });

      const hasAnotherChanges =
        !scenarioListResponse.isError &&
        scenarioListResponse.data.scenarios.length;

      this.rootStore.appNotificationsStore.open({
        title: (t) =>
          t(
            hasAnotherChanges
              ? 'scenario.ScenarioBaseModel.notifications.publishHasAnotherChanges'
              : 'scenario.ScenarioBaseModel.notifications.publishHasNoAnotherChanges',
            {
              ns: 'models'
            }
          ),
        type: AppNotificationType.success,
        breakText: false,
        timeout: 7000,
        button:
          hasAnotherChanges && this.rootStore.projectId
            ? {
                children: (t) =>
                  t(
                    'scenario.ScenarioBaseModel.notifications.publishButtonTitle',
                    {
                      ns: 'models'
                    }
                  ),
                to: urls.PROJECT.tabs.SCENARIOS.COMMON.create({
                  projectId: this.rootStore.projectId
                }),
                type: ComponentType.link
              }
            : undefined
      });

      runInAction(() => {
        this.setHasChanges(false);
        this.publishStage = LoadingStage.SUCCESS;
      });
    } else {
      runInAction(() => {
        this.publishStage = LoadingStage.ERROR;
      });
    }

    return {
      isError: publishResponse.isError
    };
  }

  async reset(): Promise<BaseResponse> {
    if (this.resetStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this.resetStage = LoadingStage.LOADING;

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.PROJECTS_RESET,
      {
        method: 'POST',
        data: {
          scenario_id: this.id
        }
      }
    );

    if (!isError) {
      this.rootStore.appNotificationsStore.open({
        title: (
          <TypedTransComponent
            ns="models"
            i18nKey={`scenario.ScenarioBaseModel.notifications.changesReset.${this.kind}`}
          >
            Изменения сценария <b>{{ scenarioName: this.name }}</b> успешно
            сброшены
          </TypedTransComponent>
        ),
        type: AppNotificationType.success
      });

      runInAction(() => {
        this.setHasChanges(false);
        this.resetStage = LoadingStage.SUCCESS;
      });
    } else {
      runInAction(() => {
        this.resetStage = LoadingStage.ERROR;
      });
    }

    return {
      isError
    };
  }

  async setProdEnabled(value: boolean): Promise<void> {
    const prevValue = this.prodEnabled;
    this.prodEnabled = value;

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.SCENARIOS_ENABLE,
      {
        method: 'POST',
        data: {
          scenario_id: this.id,
          enabled_in_prod: value
        }
      }
    );

    if (isError) {
      runInAction(() => {
        this.prodEnabled = prevValue;
      });

      this.rootStore.appNotificationsStore.open({
        type: AppNotificationType.error,
        title: (t) =>
          t(
            `scenario.ScenarioBaseModel.notifications.errorWhileEnabling.${
              value ? 'enable' : 'disable'
            }`,
            {
              ns: 'models'
            }
          )
      });
    }
  }

  async setDevEnabled(value: boolean): Promise<void> {
    const prevValue = this.devEnabled;
    this.devEnabled = value;

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.SCENARIOS_ENABLE,
      {
        method: 'POST',
        data: {
          scenario_id: this.id,
          enabled_in_dev: value
        }
      }
    );

    if (isError) {
      runInAction(() => {
        this.devEnabled = prevValue;
      });
    }
  }

  setHasChanges = (value: boolean): void => {
    this.hasChanges = value;

    if (!value) {
      this.hasProdVersion = true;
    }
  };

  static fromJson(
    serverScenario: ScenarioBaseParamsServer,
    rootStore: IRootStore
  ): ScenarioBaseModel {
    return new ScenarioBaseModel({
      ...normalizeScenarioBaseParamsServer(serverScenario),
      rootStore
    });
  }
}
