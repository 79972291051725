import {
  ConfirmedVerifiedDomainServer,
  IVerifiedConfirmedDomain,
  normalizeDomainBase,
  normalizeVerifiedConfirmedDomainParams,
  VerifiedByType
} from 'shared/entities/verifiedDomains';
import { IRootStore } from 'shared/entities/store/rootStore';

import VerifiedDomainBaseModel from './VerifiedDomainBaseModel';

export default class VerifiedConfirmedDomainModel
  extends VerifiedDomainBaseModel
  implements IVerifiedConfirmedDomain
{
  verifiedBy: VerifiedByType;
  verifiedAt: Date;

  constructor(
    {
      id,
      projectId,
      domain,
      dateCreated,
      verifiedBy,
      verifiedAt
    }: IVerifiedConfirmedDomain,
    rootStore: IRootStore
  ) {
    super({ id, projectId, domain, dateCreated }, rootStore);

    this.verifiedBy = verifiedBy;
    this.verifiedAt = verifiedAt;
  }

  static fromJson(
    raw: ConfirmedVerifiedDomainServer,
    rootStore: IRootStore
  ): VerifiedConfirmedDomainModel {
    return new VerifiedConfirmedDomainModel(
      {
        ...normalizeVerifiedConfirmedDomainParams(raw),
        ...normalizeDomainBase(raw)
      },
      rootStore
    );
  }
}
