import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const AlertIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      iconSize={IconSize.L}
      {...props}
    >
      <path
        d="M11.9996 9.50019V12.8335M11.9996 16.1669H12.008M10.8457 5.24329L3.99166 17.0821C3.61149 17.7388 3.4214 18.0671 3.4495 18.3366C3.474 18.5716 3.59714 18.7852 3.78828 18.9242C4.00741 19.0835 4.38679 19.0835 5.14556 19.0835H18.8537C19.6125 19.0835 19.9919 19.0835 20.211 18.9242C20.4021 18.7852 20.5253 18.5716 20.5498 18.3366C20.5779 18.0671 20.3878 17.7388 20.0076 17.0821L13.1535 5.24329C12.7747 4.58899 12.5853 4.26184 12.3382 4.15196C12.1227 4.05612 11.8766 4.05612 11.6611 4.15196C11.4139 4.26184 11.2245 4.58899 10.8457 5.24329Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default AlertIcon;
