import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const PlayIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
    >
      <path
        d="M15.2988 11.6076L11.1459 8.58877C10.9935 8.4783 10.7912 8.46166 10.6241 8.54751C10.4557 8.6327 10.3506 8.80574 10.3506 8.99275V15.0285C10.3506 15.2175 10.4557 15.3899 10.6241 15.4751C10.6953 15.511 10.7732 15.529 10.8517 15.529C10.9542 15.529 11.058 15.4964 11.1459 15.4318L15.2988 12.4156C15.43 12.3191 15.5065 12.17 15.5065 12.0116C15.5072 11.8506 15.4286 11.7021 15.2988 11.6076Z"
        fill="currentColor"
      />
      <path
        d="M12.0003 2C6.47639 2 2 6.47639 2 12.0003C2 17.5223 6.47639 21.9973 12.0003 21.9973C17.5229 21.9973 22 17.5216 22 12.0003C22.0007 6.47639 17.5229 2 12.0003 2ZM12.0003 20.3288C7.40015 20.3288 3.67049 16.6012 3.67049 12.0003C3.67049 7.40148 7.40015 3.66916 12.0003 3.66916C16.5998 3.66916 20.3288 7.40082 20.3288 12.0003C20.3295 16.6012 16.5998 20.3288 12.0003 20.3288Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default PlayIcon;
