import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const BankIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      svgProps={{ viewBox: '0 0 24 24' }}
      iconSize={IconSize.L}
      {...props}
    >
      <path
        d="M2.9138 11.155H4.68022V14.676V20.3099H5.6549V15.7324V11.155H7.17773H9.37054V15.7324V20.3099H10.3452V11.155H14.0609V20.3099H15.0355V11.155H18.7512V20.3099H19.7258V11.155H21.1878C21.5904 11.155 21.9456 10.8502 22.0623 10.4047C22.1789 9.95925 22.0296 9.47791 21.6946 9.21967L12.5576 2.17736C12.2507 1.94088 11.8508 1.94088 11.5439 2.17736L2.40694 9.21967C2.07191 9.47791 1.92262 9.95925 2.03926 10.4047C2.15597 10.8502 2.51122 11.155 2.9138 11.155Z"
        fill="currentColor"
      />
      <path
        d="M21.1877 20.3096H20.274H18.4466H15.4009H13.5735H10.5279H8.70047H5.6548H3.8274H2.9137C2 20.3096 2 20.3096 2 20.7321V21.5772C2 21.9997 2 21.9997 2.9137 21.9997H21.1877C22.1014 21.9997 22.1014 21.9997 22.1014 21.5068V20.7321C22.1014 20.3096 22.1014 20.3096 21.1877 20.3096Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BankIcon;
