import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const SendMessageIcon: React.FC<CommonIconProps> = (props) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M3.26528 3.12806L21.6913 12.2073C21.7918 12.2568 21.8764 12.3334 21.9355 12.4285C21.9947 12.5237 22.0261 12.6334 22.0261 12.7455C22.0261 12.8575 21.9947 12.9673 21.9355 13.0624C21.8764 13.1575 21.7918 13.2341 21.6913 13.2837L3.26408 22.3629C3.16085 22.4136 3.04521 22.4335 2.93095 22.4204C2.81669 22.4072 2.70861 22.3615 2.61959 22.2887C2.53057 22.2159 2.46436 22.119 2.42885 22.0096C2.39334 21.9002 2.39002 21.7829 2.41928 21.6717L4.24448 14.7777C4.27407 14.6661 4.3352 14.5654 4.42055 14.4878C4.5059 14.4101 4.61185 14.3587 4.72568 14.3397L12.9829 12.9633C13.0321 12.9551 13.0785 12.9348 13.1179 12.9042C13.1573 12.8736 13.1884 12.8337 13.2085 12.7881L13.2301 12.7161C13.2413 12.6493 13.2296 12.5808 13.1969 12.5215C13.1642 12.4623 13.1125 12.4158 13.0501 12.3897L12.9829 12.3705L4.68008 10.9869C4.56646 10.9677 4.46078 10.9161 4.37566 10.8385C4.29054 10.7608 4.22958 10.6602 4.20008 10.5489L2.41928 3.82046C2.38971 3.70914 2.3928 3.59167 2.42819 3.48207C2.46357 3.37247 2.52976 3.27536 2.61883 3.20236C2.70791 3.12935 2.81612 3.08352 2.93053 3.07035C3.04495 3.05717 3.16074 3.07721 3.26408 3.12806H3.26528Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SendMessageIcon;
