import { observer } from 'mobx-react';
import * as React from 'react';

import Stub from 'shared/newComponents/Stub';
import { ComponentType } from 'shared/entities/components/Component';
import { useModalState } from 'shared/entities/common/hooks';
import { useAnalyticsStore, useSupportPopupStore } from 'stores';
import { AnalyticsEvent } from 'shared/entities/analytics';
import { CarrotQuestEvent } from 'shared/entities/carrotquest';

import CongratulationsModal from './CongratulationsModal';

const WhatsApp: React.FC = () => {
  const modalState = useModalState();
  const analyticsStore = useAnalyticsStore();
  const supportPopupStore = useSupportPopupStore();

  const handlePayClick = () => {
    analyticsStore.sendEvent(AnalyticsEvent.whatsAppExperimentClickPay);
    supportPopupStore.track(CarrotQuestEvent.whatsappExperimentClickPay);
    modalState.open();
  };

  return (
    <>
      <CongratulationsModal
        onClose={modalState.close}
        opened={modalState.opened}
      />
      <Stub
        title={(t) =>
          t('ChannelsModal.ChannelForm.WhatsApp.title', {
            ns: 'newContainers'
          })
        }
        description={(t) =>
          t('ChannelsModal.ChannelForm.WhatsApp.description', {
            ns: 'newContainers'
          })
        }
        button={{
          type: ComponentType.button,
          onClick: handlePayClick,
          children: (t) =>
            t('ChannelsModal.ChannelForm.WhatsApp.button', {
              ns: 'newContainers'
            })
        }}
      />
    </>
  );
};

export default observer(WhatsApp);
