import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const CircledCancelIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      svgProps={{ viewBox: '0 0 16 16' }}
      iconSize={IconSize.S}
      {...props}
    >
      <path
        d="M7.99765 15.996C6.94709 15.9971 5.90664 15.7909 4.93584 15.3894C3.96505 14.9878 3.08297 14.3988 2.34011 13.6559C1.59725 12.913 1.00819 12.031 0.606646 11.0602C0.205099 10.0894 -0.00104813 9.04892 4.00721e-06 7.99836V7.8384C0.0320013 6.27039 0.524085 4.74636 1.4151 3.45571C2.30611 2.16506 3.55676 1.16471 5.01162 0.578982C6.46647 -0.00674312 8.06138 -0.152016 9.59812 0.16122C11.1349 0.474456 12.5457 1.23239 13.6552 2.34082C14.7746 3.45933 15.5371 4.88479 15.8461 6.4368C16.155 7.98881 15.9967 9.5976 15.3909 11.0596C14.7852 12.5215 13.7594 13.7709 12.4434 14.6497C11.1273 15.5284 9.58012 15.9969 7.99765 15.996ZM7.99765 9.12602L10.069 11.1974L11.1967 10.0697L9.12532 7.99836L11.1967 5.92696L10.069 4.79929L7.99765 6.87069L5.92626 4.79929L4.79859 5.92696L6.86999 7.99836L4.79859 10.0697L5.92626 11.1974L7.99765 9.12682V9.12602Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CircledCancelIcon;
