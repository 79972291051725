import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const FunnelIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <path
        d="M25.3931 3.00014H6.58568C6.15958 3.00013 5.74007 3.10653 5.36449 3.30988C4.98891 3.51322 4.6689 3.8072 4.43289 4.16567C4.19688 4.52414 4.05218 4.93599 4.01169 5.36459C3.97121 5.79319 4.03621 6.22525 4.20086 6.62236L12.5804 17.5296V29L19.288 25.2966V17.5296L27.8231 6.55641C27.9767 6.15906 28.0315 5.72969 27.9827 5.30596C27.9338 4.88223 27.7829 4.47708 27.543 4.12603C27.3031 3.77499 26.9817 3.48876 26.6069 3.29247C26.232 3.09617 25.8153 2.99579 25.3931 3.00014V3.00014Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default FunnelIcon;
