import * as React from 'react';
import cn from 'classnames';

import './Tag.modules.scss';
import {
  TagProps,
  TagSize,
  TagType,
  mapTagSizeToFontSize,
  mapTagSizeToIconSize,
  mapTagTypeToFontColor
} from 'shared/newEntities/components/Tag';
import {
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import { CommonIconProps } from 'shared/entities/components/Icon';

import Typography from '../Typography';
import {
  AlertIcon,
  CircledCheckIcon,
  CircledExclamationMarkIcon
} from '../icons';

export const mapTagTypeToIcon: Record<
  TagType.error | TagType.warning | TagType.success,
  React.FC<CommonIconProps>
> = {
  [TagType.error]: CircledExclamationMarkIcon,
  [TagType.warning]: AlertIcon,
  [TagType.success]: CircledCheckIcon
};

const Tag: React.FC<TagProps> = ({
  type = TagType.main,
  size = TagSize.sm,
  withIndicator,
  withIcon,
  className,
  children
}: TagProps) => {
  const withDott =
    withIndicator ||
    (withIcon &&
      (type === TagType.main ||
        type === TagType.base ||
        type === TagType.neutral ||
        type === TagType.purple));

  const Icon = React.useMemo(() => {
    if (!withIcon || withDott) {
      return null;
    }
    const IconComp = mapTagTypeToIcon[type];
    return IconComp;
  }, [withIcon, withDott, type]);

  return (
    <div
      styleName={cn(
        'tag',
        `tag_${type}`,
        `tag_${size}`,
        !withIndicator && 'tag_without-indicator'
      )}
      className={className}
    >
      {Icon && (
        <Icon
          iconSize={mapTagSizeToIconSize[size]}
          styleName={cn('tag__icon', `tag__icon_${type}`)}
        />
      )}
      {withDott && (
        <div
          styleName={cn(
            'tag__indicator',
            `tag__indicator_${type}`,
            `tag__indicator_${size}`
          )}
        ></div>
      )}
      <Typography
        styleName="tag__text"
        fontType={TypographyType.text}
        fontSize={mapTagSizeToFontSize[size]}
        fontColor={mapTagTypeToFontColor[type]}
        fontWeight={TypographyWeight.heavy}
      >
        {children}
      </Typography>
    </div>
  );
};

export default Tag;
