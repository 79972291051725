import { useLocation } from 'react-router-dom';

import { parseParams } from 'shared/entities/domain';

const useSearchParams = <
  T extends Record<string, any> = Record<string, any>
>(): T => {
  const { search } = useLocation();
  return parseParams<T>(search);
};

export default useSearchParams;
