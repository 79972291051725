import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const MinusIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.77778 17.7783H17.7778H30.2222C30.6937 17.7783 31.1459 17.591 31.4793 17.2576C31.8127 16.9242 32 16.472 32 16.0005C32 15.529 31.8127 15.0768 31.4793 14.7434C31.1459 14.41 30.6937 14.2227 30.2222 14.2227H17.7778H1.77778C1.30628 14.2227 0.854097 14.41 0.520699 14.7434C0.187301 15.0768 0 15.529 0 16.0005C0 16.472 0.187301 16.9242 0.520699 17.2576C0.854097 17.591 1.30628 17.7783 1.77778 17.7783Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MinusIcon;
