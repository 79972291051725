import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const AttachOldIcon: React.FC<CommonIconProps> = ({
  svgProps,
  ...rest
}: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.S}
      svgProps={{
        viewBox: '0 0 16 16',
        ...svgProps
      }}
      {...rest}
    >
      <path
        d="M10.0079 4.19225L5.14154 9.08299C4.84369 9.38232 4.67637 9.7883 4.67637 10.2116C4.67637 10.6349 4.84369 11.0409 5.14154 11.3403C5.43938 11.6396 5.84334 11.8077 6.26455 11.8077C6.68576 11.8077 7.08973 11.6396 7.38757 11.3403L12.254 6.44952C12.8497 5.85085 13.1843 5.03889 13.1843 4.19225C13.1843 3.34561 12.8497 2.53365 12.254 1.93499C11.6583 1.33633 10.8504 1 10.0079 1C9.16551 1 8.35759 1.33633 7.76191 1.93499L2.8955 6.82573C2.00198 7.72372 1.5 8.94166 1.5 10.2116C1.5 11.4816 2.00198 12.6995 2.8955 13.5975C3.78903 14.4955 5.00091 15 6.26455 15C7.52819 15 8.74007 14.4955 9.6336 13.5975L14.5 8.70678"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default AttachOldIcon;
