const waitUntilDomElementNotExists = async (
  domElementId: string
): Promise<BaseResponse<HTMLElement>> => {
  return new Promise((resolve) => {
    const element = document.getElementById(domElementId);
    if (element) {
      return resolve({
        isError: false,
        data: element
      });
    }

    const observer = new MutationObserver(() => {
      const element = document.getElementById(domElementId);

      if (element) {
        resolve({
          isError: false,
          data: element
        });
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true
    });
  });
};

export default waitUntilDomElementNotExists;
