import { TFunctionType, TranslationString } from './client';

/**
 * Рендерит строку, исходя из языка, для рендера в компонентах необходимо использовать хук useTranslationString
 * @param str
 * @returns {string}
 * @param t
 */
export const renderTranslationString = (
  str: TranslationString | string,
  t: TFunctionType
): string => {
  if (typeof str === 'function') {
    return str(t);
  }

  return str;
};

/**
 * Сравнивает две TranslationString строки
 * @returns {string}
 * @param str1
 * @param str2
 * @param t
 */
export const compareTranslationStrings = (
  str1: TranslationString | string,
  str2: TranslationString | string,
  t: TFunctionType
): boolean => {
  const text1 = renderTranslationString(str1, t);
  const text2 = renderTranslationString(str2, t);

  return text1 === text2;
};
