import {
  ActAndSFStatus,
  PaymentDocumentKind,
  PaymentDocumentSubject,
  ServiceSetupActDocumentServer,
  ICommonDocument,
  normalizeCommonDocument
} from 'shared/entities/documents';

import { SetupDocumentParamsTableModel } from './SetupDocumentParamsTableModel';
import { BaseDocumentModel } from './BaseDocumentModel';
import { ServiceDocumentCommonParamsTableModel } from './ServiceDocumentCommonParamsTableModel';

export class ServiceAndSetupActDocumentModel extends BaseDocumentModel {
  readonly kind: PaymentDocumentKind.act = PaymentDocumentKind.act;
  readonly subject: PaymentDocumentSubject.serviceAndSetup =
    PaymentDocumentSubject.serviceAndSetup;
  readonly params: {
    setup: SetupDocumentParamsTableModel;
    service: ServiceDocumentCommonParamsTableModel;
  };
  readonly status: ActAndSFStatus;

  constructor({
    service,
    setup,
    status,
    ...rest
  }: ICommonDocument & {
    status: ActAndSFStatus;
    setup: SetupDocumentParamsTableModel;
    service: ServiceDocumentCommonParamsTableModel;
    cabinetId: string;
  }) {
    super(rest);
    this.params = {
      service,
      setup
    };
    this.status = status;
  }

  static fromJson(
    raw: ServiceSetupActDocumentServer,
    cabinetId: string
  ): ServiceAndSetupActDocumentModel {
    return new ServiceAndSetupActDocumentModel({
      ...normalizeCommonDocument(raw),
      setup: SetupDocumentParamsTableModel.fromJson(raw.params.setup),
      service: ServiceDocumentCommonParamsTableModel.fromJson(
        raw.params.service
      ),
      status: raw.status,
      cabinetId
    });
  }
}
