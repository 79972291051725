import {
  BaseVarServer,
  IUserCommonVar,
  VarBoolValue
} from 'shared/entities/vars';
import { UserItemModel } from 'shared/models/users';
import { IRootStore } from 'shared/entities/store/rootStore';

import UserVarModel from './UserVarModel';

export default class UserCommonVarModel
  extends UserVarModel<string>
  implements IUserCommonVar
{
  toJson(): BaseVarServer {
    return {
      value: this.value.value,
      key: this.key
    };
  }
  static fromJson(
    raw: BaseVarServer & {
      chatId: string;
      user: UserItemModel;
      rootStore: IRootStore;
    }
  ): UserCommonVarModel {
    return new UserCommonVarModel(raw);
  }

  saveBoolValue = (value: VarBoolValue) => {
    this.value.changeValue(value);
    this.save();
  };
}
