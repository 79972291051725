import { createContext } from 'react';

import { ChannelsModalStore } from './ChannelsModalStore';

const ChannelsModalStoreContext = createContext<{
  store: ChannelsModalStore | null;
  close?: () => void;
}>({
  store: null
});

const ChannelsModalStoreProvider = ChannelsModalStoreContext.Provider;

export { ChannelsModalStoreContext, ChannelsModalStoreProvider };
