import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import { ICabinetsStore } from 'shared/entities/store/cabinetsStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import { CabinetEditModel, CabinetModel } from 'shared/models/cabinet';
import { apiUrls, AuthOpeningBaseParams } from 'shared/entities/domain';
import { LoadingStage } from 'shared/entities/meta';
import {
  CabinetServer,
  ICabinetModel,
  mapCabinetCreationErrorToMessage
} from 'shared/entities/cabinet';
import ListModel from 'shared/models/ListModel';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';

export default class CabinetsStore
  extends ComponentLoadingStore
  implements ICabinetsStore
{
  readonly rootStore: IRootStore;
  readonly cabinets: ListModel<CabinetModel> = new ListModel();

  constructor(rootStore: IRootStore) {
    super();

    makeObservable(this, {
      cabinets: observable,

      loadingStage: computed,
      creatingStage: computed,

      create: action
    });
    this.rootStore = rootStore;
  }

  get loaded(): boolean {
    return this.cabinets.loadingStage === LoadingStage.SUCCESS;
  }

  get canBeLoaded(): boolean {
    return this.rootStore.userStore.isAuthorized;
  }

  get loadingStage(): LoadingStage {
    return this.cabinets.loadingStage;
  }

  get creatingStage(): LoadingStage {
    return this.cabinets.creatingStage;
  }

  async load(): Promise<BaseResponse<ICabinetModel[]>> {
    if (
      !this.canBeLoaded ||
      this.cabinets.loadingStage === LoadingStage.LOADING
    ) {
      return {
        isError: true
      };
    }
    this.cabinets.setLoadingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      cabinets: CabinetServer[];
    }>(apiUrls.CABINETS_LIST);

    if (!response.isError) {
      const { entities, keys } = response.data.cabinets.reduce(
        (acc, cabinet) => ({
          entities: {
            ...acc.entities,
            [cabinet._id]: CabinetModel.fromJson(cabinet, this.rootStore)
          },
          keys: [...acc.keys, cabinet._id]
        }),
        { entities: {}, keys: [] }
      );

      runInAction(() => {
        this.cabinets.addEntities({ entities, keys, initial: true });
        this.cabinets.setLoadingStage(LoadingStage.SUCCESS);
      });

      return {
        isError: false,
        data: this.cabinets.items
      };
    } else {
      runInAction(() => {
        this.cabinets.setLoadingStage(LoadingStage.ERROR);
      });

      return { isError: true };
    }
  }

  async create(
    cabinetModel: CabinetEditModel
  ): Promise<BaseResponse<{ cabinet: CabinetModel }>> {
    this.cabinets.setCreatingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      cabinet: CabinetServer;
    }>(apiUrls.CABINETS_CREATE, {
      method: 'POST',
      data: {
        ...cabinetModel.toJson(),
        promo:
          this.rootStore.routerStore.openingParams[
            AuthOpeningBaseParams.promo
          ] || undefined
      },
      errorsMap: mapCabinetCreationErrorToMessage
    });

    if (!response.isError) {
      const cabinet = CabinetModel.fromJson(
        response.data.cabinet,
        this.rootStore
      );

      runInAction(() => {
        this.cabinets.addEntity({
          entity: cabinet,
          key: cabinet.id,
          start: true
        });

        this.cabinets.setCreatingStage(LoadingStage.SUCCESS);
      });

      this.rootStore.routerStore.removeOpeningParam(
        AuthOpeningBaseParams.promo
      );

      return {
        isError: false,
        data: {
          cabinet
        }
      };
    } else {
      runInAction(() => {
        this.cabinets.setCreatingStage(LoadingStage.ERROR);
      });

      return {
        isError: true
      };
    }
  }

  reset(): void {
    this.cabinets.reset();
  }
}
