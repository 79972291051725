import * as React from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { useRouterStore } from 'stores/index';

export const useRouterStoreUpdates = (): void => {
  const location = useLocation();
  const history = useHistory();
  const store = useRouterStore();
  React.useEffect(() => {
    store.changePathname(location.pathname);
  }, [location.pathname]);

  React.useEffect(() => {
    store.changeSearch(location.search);
  }, [location.search]);

  React.useEffect(() => {
    store.changeHistory(history);
  }, [history]);
};
