import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const FilterIcon: React.FC<CommonIconProps> = (props) => {
  return (
    <BaseIcon
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      iconSize={IconSize.XL}
      {...props}
    >
      <path
        d="M13.7928 20.0741H18.2072C18.4885 20.0744 18.7591 20.1901 18.9636 20.3975C19.1682 20.6048 19.2912 20.8882 19.3077 21.1898C19.3242 21.4914 19.2329 21.7883 19.0523 22.0199C18.8718 22.2516 18.6157 22.4005 18.3364 22.4361L18.2072 22.4444H13.7928C13.5115 22.4441 13.2409 22.3284 13.0364 22.1211C12.8318 21.9137 12.7088 21.6303 12.6923 21.3287C12.6758 21.0272 12.7671 20.7302 12.9477 20.4986C13.1282 20.2669 13.3843 20.118 13.6636 20.0824L13.7928 20.0741ZM11.5855 15.037H20.4145C20.6958 15.0374 20.9663 15.153 21.1709 15.3604C21.3754 15.5678 21.4985 15.8512 21.515 16.1528C21.5314 16.4543 21.4401 16.7513 21.2595 16.9829C21.079 17.2146 20.8229 17.3634 20.5436 17.3991L20.4145 17.4074H11.5855C11.3042 17.4071 11.0337 17.2914 10.8291 17.084C10.6246 16.8767 10.5015 16.5932 10.485 16.2917C10.4686 15.9901 10.5599 15.6932 10.7405 15.4615C10.921 15.2299 11.1771 15.081 11.4564 15.0453L11.5855 15.037ZM9.10239 10H22.8976C23.1789 10.0003 23.4494 10.116 23.654 10.3234C23.8585 10.5307 23.9816 10.8142 23.9981 11.1157C24.0146 11.4173 23.9232 11.7142 23.7427 11.9459C23.5621 12.1775 23.3061 12.3264 23.0267 12.3621L22.8976 12.3704H9.10239C8.8211 12.37 8.55055 12.2544 8.34601 12.047C8.14147 11.8396 8.01838 11.5562 8.0019 11.2546C7.98541 10.9531 8.07677 10.6561 8.25732 10.4245C8.43786 10.1928 8.69395 10.044 8.97327 10.0083L9.10239 10Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default FilterIcon;
