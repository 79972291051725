import { EMOJI_REG_EXP } from 'shared/entities/regExp/emoji';

/**
 * Получает первую букву или эмоджи названия
 * @param title
 * @returns {`${string} ${string}` | string}
 */
const getFirstLetterFromTitle = (title: string): string => {
  if (!title.length) {
    return '';
  }

  const emojiPosition = title.search(EMOJI_REG_EXP);

  if (emojiPosition === 0) {
    return title.match(EMOJI_REG_EXP)?.[0] || title[0];
  }

  return title[0];
};

export default getFirstLetterFromTitle;
