import * as React from 'react';

import {
  CabinetServer,
  CabinetSplit,
  ICabinet,
  ICabinetModel
} from 'shared/entities/cabinet';
import { Permission } from 'shared/entities/permissions';
import { CabinetManagerRoleType } from 'shared/entities/manager';
import { apiUrls } from 'shared/entities/domain';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { IRootStore } from 'shared/entities/store/rootStore';
import TypedTransComponent from 'shared/components/TypedTransComponent';

import { LoadingStageModel } from '../loadingStage';
import { FieldModel } from '../form';

import CabinetRequisitesModel from './CabinetRequisitesModel';

export default class CabinetModel implements ICabinetModel {
  readonly id: string;
  readonly domain: string;
  readonly permissions: Permission[];
  readonly role: CabinetManagerRoleType;
  readonly rootStore: IRootStore;
  readonly split: CabinetSplit;
  readonly balance: number;
  readonly hadLinkedCard: boolean | null;
  readonly shouldPay: boolean;
  readonly requisites: CabinetRequisitesModel;
  readonly updatingStage: LoadingStageModel = new LoadingStageModel();

  private _name: FieldModel<string>;

  constructor({
    id,
    name,
    domain,
    permissions,
    role,
    rootStore,
    shouldPay,
    split,
    hadLinkedCard,
    balance,
    requisites
  }: ICabinet & {
    rootStore: IRootStore;
    requisites: CabinetRequisitesModel;
  }) {
    this.id = id;
    this.domain = domain;
    this.permissions = permissions;
    this.role = role;
    this.rootStore = rootStore;
    this._name = new FieldModel<string>(name);
    this.shouldPay = shouldPay;
    this.split = split;
    this.balance = balance;
    this.hadLinkedCard = hadLinkedCard;
    this.requisites = requisites;
  }

  get needToShowSubscriptionPage(): boolean {
    if (this.split !== CabinetSplit.increasedMinimumPaywall) {
      return false;
    }

    return !this.hadLinkedCard;
  }

  get name(): string {
    return this._name.value;
  }

  update = async (name: FieldModel<string>): Promise<BaseResponse> => {
    if (this.updatingStage.isLoading) {
      return { isError: true };
    }

    const error = name.validate();

    if (error) {
      const transError = (t) =>
        t('validators.errors.invalidEmail', {
          ns: 'entities'
        });

      if (error === transError) {
        this.rootStore.appNotificationsStore.open({
          title: error,
          type: AppNotificationType.error
        });
      }

      return { isError: true };
    }

    this.updatingStage.loading();

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.CABINETS_UPDATE,
      {
        method: 'POST',
        data: {
          name: name.value
        }
      }
    );

    if (!isError) {
      this.updatingStage.success();
      this._name.changeValue(name.value);
      this.rootStore.appNotificationsStore.open({
        title: (
          <TypedTransComponent
            ns="models"
            i18nKey="cabinet.CabinetModel.changingNameNotification"
          >
            Название кабинета успешно изменено на{' '}
            <b>{{ cabinetName: this.name }}</b>
          </TypedTransComponent>
        ),
        type: AppNotificationType.success,
        breakText: true
      });
    } else {
      this.updatingStage.error();
    }

    return { isError: isError };
  };

  static fromJson(raw: CabinetServer, rootStore: IRootStore): CabinetModel {
    return new CabinetModel({
      id: raw._id,
      domain: raw.domain,
      name: raw.name,
      permissions: raw.permissions,
      role: raw.cabinet_role,
      shouldPay: raw.should_pay,
      split: raw.billing.split,
      balance: raw.billing.balance,
      hadLinkedCard:
        raw.billing.had_linked_card !== undefined
          ? raw.billing.had_linked_card
          : null,
      rootStore,
      requisites: raw.billing.requisites
        ? CabinetRequisitesModel.fromJson(
            raw.billing.requisites,
            raw._id,
            rootStore
          )
        : CabinetRequisitesModel.fromDefaultParams(raw._id, rootStore)
    });
  }
}
