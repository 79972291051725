import * as React from 'react';

import { VKCreationStore } from './VKCreationStore';
import { VKCreationStoreContext } from './context';

const useVKCreationStore = (): VKCreationStore => {
  const VKCreationStoreCtx = React.useContext(VKCreationStoreContext);

  return VKCreationStoreCtx.store as VKCreationStore;
};

export { useVKCreationStore, VKCreationStore };
export * from './context';
