import { computed, makeObservable } from 'mobx';

import { FieldModel } from 'shared/models/form';
import { validateIsEmpty } from 'shared/entities/validator';
import { TelegramPaymentKind } from 'shared/entities/telegramPayments';

export class TgPaymentsCabinetDigitalProductsParams {
  readonly channelId: FieldModel<string | null> = new FieldModel<string | null>(
    null,
    [validateIsEmpty]
  );

  constructor() {
    makeObservable(this, {
      creatingDisabled: computed
    });
  }

  get creatingDisabled(): boolean {
    return this.channelId.isError || !this.channelId.value;
  }

  toJson() {
    return {
      token: '',
      channel_id: this.channelId.value,
      payment_provider_kind: TelegramPaymentKind.stars
    };
  }

  validate() {
    this.channelId.validate();
  }
}
