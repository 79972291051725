import * as React from 'react';
import { observer } from 'mobx-react';

import {
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import Switch from 'shared/newComponents/Switch';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import ChannelAccounts from 'shared/newComponents/ChannelAccounts';

import './Header.modules.scss';

type Props = {
  className?: string;
};

const Header: React.FC<Props> = ({ className }) => {
  const { store, close } = useChannelsModalStore();

  return (
    <div styleName="header" className={className}>
      <div styleName="header__left">
        {store.withSections && (
          <Typography
            styleName="header__title"
            fontType={TypographyType.text}
            fontSize={TypographySize.m}
            fontWeight={TypographyWeight.heavy}
          >
            {(t) =>
              t('ChannelsModal.Content.title', {
                ns: 'newContainers'
              })
            }
          </Typography>
        )}
        {!!store.scenario && (
          <Switch
            styleName="header__connect-all-channels"
            className={store.withSections ? 'form-item_xs' : ''}
            value={store.scenario.connectAllChannels}
            onChange={store.changeConnectAllChannels}
            title={(t) =>
              t('ChannelsModal.Content.scenario', {
                ns: 'newContainers'
              })
            }
          />
        )}
      </div>
      {store.withAccounts && !!store.scenario && (
        <ChannelAccounts styleName="header__accounts" onClick={close} />
      )}
    </div>
  );
};

export default observer(Header);
