export enum ProductOptionType {
  color = 'color',
  other = 'string'
}

/**
 * Сущность характеристики, приходящая с бэка
 */
export type ProductOptionServer = {
  id: number;
  option_id: number; // Служебное поле для бэка
  type: ProductOptionType;
  name: string;
  value: string; // Значение характеристики, в случае цвета - название цвета
  extra_value?: string; // В случае цвета - HEX-цвет
};

/**
 * Сущность характеристики, отправляемая на бэк
 */
export type ProductOptionEditingFieldsServer = Omit<
  ProductOptionServer,
  'id' | 'option_id'
> & {
  id: number | null;
  option_id: number | null;
};
