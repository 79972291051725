import * as React from 'react';

import { EventListenerProps } from '../../eventListener';

const useEventListener = ({
  eventName,
  options = {},
  getElement = () => window,
  handler,
  deps = []
}: EventListenerProps & {
  eventName: string;
}): void => {
  const savedHandler = React.useRef<(event: any) => void>();

  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(() => {
    const element = getElement();

    if (!element) {
      return;
    }

    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return;
    }

    const eventListener = (event) => savedHandler.current?.(event);
    element.addEventListener(eventName, eventListener, options);

    return () => {
      element.removeEventListener(eventName, eventListener, options);
    };
  }, [getElement, handler, ...deps]);
};

export default useEventListener;
