import * as React from 'react';
import { motion } from 'framer-motion';

import { BaseModalProps } from 'shared/newEntities/components/BaseModal';
import Portal from 'shared/components/Portal';
import { PORTAL_IDS } from 'shared/entities/app';
import BlurrableWrapper from 'shared/components/Blurable';
import ModalEventsHandler from 'shared/components/windows/ModalEventsHandler';

import FadeBackground from '../FadeBackground';

import './BaseModal.modules.scss';

type Props = React.PropsWithChildren<
  BaseModalProps & {
    className?: string;
  }
>;

const BaseModal: React.FC<Props> = ({
  opened,
  onClose,
  children,
  className
}) => {
  return (
    <ModalEventsHandler
      opened={opened}
      onClose={onClose}
      render={({ onClose, zIndex }) => (
        <Portal portalId={PORTAL_IDS.MODALS}>
          <motion.div
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
            exit={{
              opacity: 0
            }}
            styleName="base-modal-container"
            style={{ zIndex }}
          >
            <FadeBackground styleName="base-modal-fade" />
            <BlurrableWrapper styleName="base-modal" onBlur={onClose}>
              <motion.div
                initial={{
                  y: 50,
                  opacity: 0
                }}
                animate={{
                  y: 0,
                  opacity: 1
                }}
                exit={{
                  y: 50,
                  opacity: 0
                }}
                transition={{
                  stiffness: 0
                }}
                styleName="base-modal__content"
                className={className}
              >
                {children}
              </motion.div>
            </BlurrableWrapper>
          </motion.div>
        </Portal>
      )}
    />
  );
};

export default BaseModal;
