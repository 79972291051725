import * as React from 'react';
import cn from 'classnames';

import { ChannelKind, channelKindEntities } from 'shared/entities/channels';
import { IconSize } from 'shared/entities/components/Icon';
import {
  AvatarBorderColor,
  AvatarBorderSize,
  AvatarColor,
  AvatarSize,
  AvatarType
} from 'shared/newEntities/components/user/Avatar';

import Avatar from '../user/Avatar';

import './PhotoWithChannel.modules.scss';

type Props = {
  channelKind: ChannelKind;
  photoUrl: string | null;
  className?: string;
  name: string;
  size?: IconSize.XM | IconSize.L;
  borderColor?: AvatarBorderColor;
  borderSize?: AvatarBorderSize;
  defaultColor?: AvatarColor;
};

export const PhotoWithChannel: React.FC<Props> = ({
  channelKind,
  className,
  name,
  photoUrl,
  size = IconSize.XM,
  borderColor,
  borderSize,
  defaultColor
}: Props) => {
  const Icon = channelKindEntities[channelKind].Icon;

  const sideSize: AvatarSize = React.useMemo(() => {
    return size === IconSize.XM ? AvatarSize.xs : AvatarSize.s;
  }, [size]);

  return (
    <div
      styleName={cn('container', `container_${sideSize}`)}
      className={className}
    >
      <Avatar
        size={sideSize}
        type={photoUrl ? AvatarType.img : AvatarType.name}
        content={photoUrl || name}
        border={
          borderColor || borderSize
            ? { color: borderColor, size: borderSize }
            : undefined
        }
        color={defaultColor}
      />
      <Icon
        styleName={cn(
          'container__channel-logo',
          borderColor && `container__channel-logo_${borderColor}`
        )}
        iconSize={IconSize.XSS}
      />
    </div>
  );
};

export default React.memo(PhotoWithChannel);
