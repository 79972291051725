import {
  ActAndSFStatus,
  PaymentDocumentKind,
  PaymentDocumentSubject,
  ICommonDocument,
  normalizeCommonDocument,
  ServiceSFDocumentServer
} from 'shared/entities/documents';

import { ServiceDocumentCommonParamsTableModel } from './ServiceDocumentCommonParamsTableModel';
import { BaseDocumentModel } from './BaseDocumentModel';

export class ServiceSFDocumentModel extends BaseDocumentModel {
  readonly status: ActAndSFStatus;
  readonly kind: PaymentDocumentKind.sf = PaymentDocumentKind.sf;
  readonly subject: PaymentDocumentSubject.service =
    PaymentDocumentSubject.service;
  readonly params: ServiceDocumentCommonParamsTableModel;

  constructor({
    params,
    status,
    ...rest
  }: ICommonDocument & {
    params: ServiceDocumentCommonParamsTableModel;
    status: ActAndSFStatus;
    cabinetId: string;
  }) {
    super(rest);
    this.status = status;
    this.params = params;
  }

  static fromJson(
    raw: ServiceSFDocumentServer,
    cabinetId: string
  ): ServiceSFDocumentModel {
    return new ServiceSFDocumentModel({
      ...normalizeCommonDocument(raw),
      status: raw.status,
      params: ServiceDocumentCommonParamsTableModel.fromJson(raw.params),
      cabinetId
    });
  }
}
