import { action, makeObservable } from 'mobx';

import { ComponentLoadingStore } from 'stores/componentLoadingStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import ListModel from 'shared/models/ListModel';
import { ApiTokenModel } from 'shared/models/apiIntegration';
import { LoadingStage } from 'shared/entities/meta';
import { apiUrls } from 'shared/entities/domain';
import { ApiTokenScope, ApiTokenServer } from 'shared/entities/apiIntegration';
import { ApiTokenEditStore } from 'stores/editors';

export default class ApiIntegrationStore extends ComponentLoadingStore {
  private rootStore: IRootStore;
  private _tokens: ListModel<ApiTokenModel> = new ListModel<ApiTokenModel>();
  readonly editStore: ApiTokenEditStore = new ApiTokenEditStore();

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;

    makeObservable(this, {
      revokeToken: action.bound
    });
  }

  get tokens(): ListModel<ApiTokenModel> {
    return this._tokens;
  }

  get loaded(): boolean {
    return this._tokens.loadingStage === LoadingStage.SUCCESS;
  }

  get canBeLoaded(): boolean {
    return this.rootStore.initialized;
  }

  async load(): Promise<BaseResponse> {
    if (this._tokens.loadingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    this._tokens.setLoadingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      tokens: ApiTokenServer[];
    }>(apiUrls.PUBLIC_API_TOKENS_LIST);

    if (response.isError) {
      this._tokens.setLoadingStage(LoadingStage.ERROR);
    } else {
      const { keys, entities } = response.data.tokens.reduce<{
        entities: Record<string, ApiTokenModel>;
        keys: string[];
      }>(
        (acc, token) => ({
          ...acc,
          entities: {
            ...acc.entities,
            [token._id]: ApiTokenModel.fromJson(token, this.rootStore)
          },
          keys: [...acc.keys, token._id]
        }),
        { entities: {}, keys: [] }
      );
      this._tokens.addEntities({ keys, entities, initial: true });
      this._tokens.setLoadingStage(LoadingStage.SUCCESS);
    }

    return {
      isError: response.isError
    };
  }

  async createToken(): Promise<BaseResponse> {
    if (this._tokens.creatingStage === LoadingStage.LOADING) {
      return { isError: true };
    }

    const entity = this.editStore.save();

    if (!entity) {
      return { isError: true };
    }

    this._tokens.setCreatingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<ApiTokenServer>(
      apiUrls.PUBLIC_API_TOKENS_CREATE,
      {
        method: 'POST',
        data: entity.toJson()
      }
    );

    if (response.isError) {
      this._tokens.setCreatingStage(LoadingStage.ERROR);
    } else {
      const token = ApiTokenModel.fromJson(response.data, this.rootStore);
      this._tokens.addEntity({ entity: token, key: token.id, start: true });
      this._tokens.setCreatingStage(LoadingStage.SUCCESS);
    }

    return { isError: false };
  }

  async revokeToken(id: string): Promise<BaseResponse> {
    const token = this._tokens.getEntity(id);

    if (!token) {
      return {
        isError: true
      };
    }

    const response = await token.revoke();

    if (response.isError) {
      return {
        isError: true
      };
    }

    this._tokens.removeEntity(id);

    return {
      isError: false
    };
  }

  getTokensByScope = (
    scopes: ApiTokenScope[]
  ): { entities: Record<string, ApiTokenModel>; keys: string[] } => {
    const keys: string[] = [];
    const entities: Record<string, ApiTokenModel> = {};

    this._tokens.items.forEach((token) => {
      if (token.scope.some((scope) => scopes.includes(scope))) {
        keys.push(token.id);
        entities[token.id] = token;
      }
    });

    return {
      keys,
      entities
    };
  };

  reset(): void {
    this._tokens.reset();
  }
}
