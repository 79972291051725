import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const EditIcon: React.FC<CommonIconProps> = (props) => {
  return (
    <BaseIcon
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      iconSize={IconSize.L}
      {...props}
    >
      <path
        d="M4.04005 14.5513L3.05767 18.5665C2.89262 19.2393 3.08674 19.9332 3.57715 20.4236C3.95203 20.7985 4.44716 21.0005 4.96035 21.0005C5.11754 21.0005 5.27629 20.9816 5.43426 20.9431L9.44869 19.9607C9.79763 19.8751 10.1167 19.6959 10.3713 19.4412L20.4247 9.38709C20.7957 9.01614 21 8.52259 21 7.9976C21 7.47262 20.7957 6.97906 20.4247 6.60811L17.3927 3.57607C17.0217 3.20512 16.5282 3 16.0032 3C15.4782 3 14.9846 3.20434 14.6137 3.57607L4.55954 13.6287C4.30569 13.8825 4.12572 14.2016 4.04005 14.5513ZM5.13483 19.721C4.7851 19.8067 4.5509 19.6189 4.46602 19.534C4.38114 19.4491 4.19331 19.2141 4.27897 18.8652L5.08531 15.5722L8.42858 18.9155L5.13483 19.721ZM15.5018 4.46494C15.6354 4.33133 15.813 4.25746 16.0024 4.25746C16.1918 4.25746 16.3694 4.33133 16.503 4.46494L19.5351 7.49698C19.6687 7.63058 19.7425 7.8082 19.7425 7.9976C19.7425 8.18701 19.6687 8.36462 19.5351 8.49823L9.66246 18.3708L5.62916 14.3375L15.5018 4.46494Z"
        fill="currentColor"
      />
      <rect
        x="12.709"
        y="6"
        width="7.37947"
        height="1"
        transform="rotate(45.1132 12.709 6)"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default EditIcon;
