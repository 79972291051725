export const isProd = process.env.IS_PROD;
export const PORT = !isProd ? process.env.PORT || 8888 : '';

export const DOMAIN = process.env.IS_PROD
  ? 'smartbotpro.ru'
  : (process.env.BASE_DOMAIN as string);

export const WS_DOMAIN = process.env.IS_PROD
  ? 'ws.smartbotpro.ru'
  : (process.env.WS_DOMAIN as string);

export const PROTOCOL_STRING = `${window.location.protocol}//`;

export const DOMAIN_STRING = `${DOMAIN}${PORT ? `:${PORT}` : ''}`;

export enum ReservedDomain {
  auth = 'auth'
}
