import * as React from 'react';

import {
  CommonIconProps,
  LogoIconProps,
  IconSize
} from 'shared/entities/components/Icon';
import BaseIcon from 'shared/newComponents/icons/BaseIcon';

const AliExpressIcon: React.FC<LogoIconProps> = ({
  color,
  ...rest
}: LogoIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXML}
      svgProps={{
        viewBox: '0 0 50 50'
      }}
      {...rest}
    >
      <path
        d="M4.75 1C3.75544 1 2.80161 1.39509 2.09835 2.09835C1.39509 2.80161 1 3.75544 1 4.75L1 45.25C1 46.2446 1.39509 47.1984 2.09835 47.9017C2.80161 48.6049 3.75544 49 4.75 49H45.25C46.2446 49 47.1984 48.6049 47.9017 47.9017C48.6049 47.1984 49 46.2446 49 45.25V4.75C49 3.75544 48.6049 2.80161 47.9017 2.09835C47.1984 1.39509 46.2446 1 45.25 1L4.75 1Z"
        fill={color === 'original' ? '#E62E04' : 'currentColor'}
      />
      <path
        d="M14.1999 17.0928C14.3836 17.8428 14.2801 18.6528 14.4609 19.4156C15.0909 18.9543 15.5109 18.1931 15.4246 17.4003C15.4119 15.9813 13.8159 14.8691 12.4801 15.3468C11.6401 15.5868 11.0191 16.3406 10.8781 17.1918C10.7101 18.1668 11.2959 19.1643 12.1666 19.6098C12.0804 18.7698 11.8786 17.8848 12.1044 17.0628C12.4494 16.1673 13.8811 16.1853 14.1999 17.0928ZM39.0534 18.1098C39.3594 17.0088 38.6934 15.7323 37.6014 15.3798C36.5514 14.9673 35.2516 15.4923 34.7889 16.5153C34.2729 17.4626 34.5849 18.8103 35.5284 19.3653C35.7144 18.6048 35.5771 17.7903 35.7984 17.0516C36.1509 16.1688 37.5646 16.1943 37.8864 17.0891C38.0911 17.8886 37.9059 18.7286 37.8286 19.5356C38.4691 19.3376 38.8689 18.7256 39.0526 18.1121L39.0534 18.1098Z"
        fill={color === 'original' ? '#B32100' : 'currentColor'}
      />
      <path
        d="M35.7999 17.0484C35.5779 17.7947 35.7151 18.6024 35.5299 19.3622C34.9809 22.6622 32.7714 25.6112 29.7796 27.0894C28.2904 27.8388 26.6461 28.2282 24.979 28.2261C23.3118 28.224 21.6685 27.8306 20.1811 27.0774C17.2171 25.6029 15.0249 22.6787 14.4616 19.4139C14.2816 18.6504 14.3836 17.8487 14.2006 17.0912C13.8826 16.1912 12.4501 16.1649 12.1051 17.0612C11.8801 17.8907 12.0826 18.7689 12.1674 19.6082C12.7974 23.6882 15.5274 27.3519 19.2264 29.1707C20.9264 30.0117 22.7897 30.4707 24.6858 30.5155C26.5819 30.5604 28.4648 30.1899 30.2026 29.4302C34.2024 27.7029 37.1926 23.8457 37.8324 19.5302C37.9096 18.7232 38.0949 17.8824 37.8894 17.0837C37.5669 16.1889 36.1539 16.1642 35.8006 17.0462L35.7999 17.0484Z"
        fill="white"
      />
      <path
        d="M4.75 1C3.75544 1 2.80161 1.39509 2.09835 2.09835C1.39509 2.80161 1 3.75544 1 4.75L1 11.875C1 10.8804 1.39509 9.92661 2.09835 9.22335C2.80161 8.52009 3.75544 8.125 4.75 8.125H45.25C46.2446 8.125 47.1984 8.52009 47.9017 9.22335C48.6049 9.92661 49 10.8804 49 11.875V4.75C49 3.75544 48.6049 2.80161 47.9017 2.09835C47.1984 1.39509 46.2446 1 45.25 1L4.75 1Z"
        fill={color === 'original' ? '#FF9900' : 'currentColor'}
      />
    </BaseIcon>
  );
};

export const AliExpressOriginalIcon: React.FC<CommonIconProps> = (props) => (
  <AliExpressIcon color="original" {...props} />
);

export const AliExpressTransparentIcon: React.FC<CommonIconProps> = (props) => (
  <AliExpressIcon color="transparent" {...props} />
);
