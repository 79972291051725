import { SelectorEntities } from 'shared/entities/components/Selector';
import { TFunctionType } from 'shared/entities/localization';
import { IField } from 'shared/entities/form';
import { AreaSize } from 'shared/entities/geometry';

import { INewList } from '../list';

import { FileTags, FileType } from './server';

export enum FileSizeType {
  kb = 'KByte',
  mb = 'MByte'
}

export const mediaFileTypes = [
  FileType.image,
  FileType.music,
  FileType.video,
  FileType.voice
];

export interface IFileBase {
  id: string;
  fileType: FileType;
  title: string;
  preview: string | null;
  size: number;
  date: Date;
  url: string;
  extension: string;
  extra: any;
  areaSize: AreaSize | null;
}

export interface IFile extends IFileBase {
  availability: FileTags;
}

export interface IBlockAttachments {
  files: INewList<IBlockAttachment>;
}

export interface IAttachment extends IFileBase {
  readonly asDocument: IField<boolean>;
}

export interface IBlockAttachmentsModel extends IBlockAttachments {
  addAttachments(attachments: IFile[], asDocument: boolean): void;
  onRemove(ids: string[]): void;
  remove(id: string): void;
}

export interface IBlockAttachment extends IAttachment {
  readonly attachmentId: string;
}

export const mapFileExtensionToFileType = {
  doc: FileType.doc,
  docx: FileType.doc,
  mp3: FileType.music,
  png: FileType.image,
  jpg: FileType.image,
  svg: FileType.image,
  jpeg: FileType.image
};

export const sizeTypesOrder = [FileSizeType.kb, FileSizeType.mb];

export const sizeTypesEntities: SelectorEntities<FileSizeType> =
  sizeTypesOrder.reduce<SelectorEntities<FileSizeType>>(
    (acc, size) => ({
      ...acc,
      [size]: {
        id: size,
        title: (t: TFunctionType): string =>
          t(`file.size.${size}`, {
            ns: 'entities'
          })
      }
    }),
    {} as SelectorEntities<FileSizeType>
  );
export const typeMultiplier = {
  [FileSizeType.kb]: 1,
  [FileSizeType.mb]: 1024
};
