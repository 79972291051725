import * as React from 'react';

import TypedTransComponent from 'shared/components/TypedTransComponent';
import ExternalLink from 'shared/newComponents/ExternalLink';
import { wazzupLinks } from 'shared/entities/app';
import {
  TypographyColor,
  TypographySize,
  TypographyType
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';

import './NoAccount.modules.scss';

const NoAccount: React.FC = () => {
  return (
    <Typography
      fontType={TypographyType.text}
      fontSize={TypographySize.s}
      styleName="no-account"
    >
      <TypedTransComponent
        ns="newContainers"
        i18nKey="ChannelsModal.ChannelForm.Wazzup.noAccount.instruction"
      >
        Для того, чтобы подключить канал, зарегиструйтесь в&nbsp;Wazzup24
        по&nbsp;
        <ExternalLink
          fontType={TypographyType.text}
          fontSize={TypographySize.s}
          fontColor={TypographyColor.link}
          underline
          href={wazzupLinks.referal}
        >
          ссылке
        </ExternalLink>
        .
        <br />
        После создания аккаунта выберите один из&nbsp;вариантов выше
        и&nbsp;следуйте инструкции.
      </TypedTransComponent>
    </Typography>
  );
};

export default React.memo(NoAccount);
