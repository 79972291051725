import * as React from 'react';

import { PlusIcon } from 'shared/newComponents/icons';
import Stub from 'shared/newComponents/Stub';
import { ComponentType } from 'shared/entities/components/Component';
import { CREATE_VK_GROUP_LINK } from 'shared/entities/app';

const NoGroupsStub: React.FC = () => {
  return (
    <Stub
      title={(t) =>
        t('ChannelsModal.ChannelForm.VK.stubs.NoGroups.title', {
          ns: 'newContainers'
        })
      }
      description={(t) =>
        t('ChannelsModal.ChannelForm.VK.stubs.NoGroups.description', {
          ns: 'newContainers'
        })
      }
      button={{
        type: ComponentType.a,
        href: CREATE_VK_GROUP_LINK,
        target: '_blank',
        rel: 'noreferrer noopener',
        Icon: PlusIcon,
        children: (t) =>
          t('ChannelsModal.ChannelForm.VK.stubs.NoGroups.button', {
            ns: 'newContainers'
          })
      }}
    />
  );
};

export default React.memo(NoGroupsStub);
