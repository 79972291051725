import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const ExcelIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XM}
      svgProps={{
        viewBox: '0 0 20 20'
      }}
      {...props}
    >
      <path
        d="M3.3335 6.24984V2.49984C3.3335 2.27882 3.42129 2.06686 3.57757 1.91058C3.73385 1.7543 3.94582 1.6665 4.16683 1.6665H15.8335C16.0545 1.6665 16.2665 1.7543 16.4228 1.91058C16.579 2.06686 16.6668 2.27882 16.6668 2.49984V17.4998C16.6668 17.7209 16.579 17.9328 16.4228 18.0891C16.2665 18.2454 16.0545 18.3332 15.8335 18.3332H4.16683C3.94582 18.3332 3.73385 18.2454 3.57757 18.0891C3.42129 17.9328 3.3335 17.7209 3.3335 17.4998V13.7498"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9165 6.25H14.1665M11.6665 9.58333H14.1665M11.6665 12.9167H14.1665"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
      />
      <path
        d="M4.1665 8.75L6.6665 11.25M6.6665 8.75L4.1665 11.25M1.6665 6.25H9.1665V13.75H1.6665V6.25Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default ExcelIcon;
