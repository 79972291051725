import * as React from 'react';

import { ReferenceProps } from 'shared/entities/components/Reference';

import { TranslationString } from '../../localization';
import { ComponentLinkProps } from '../../components/Component';
import { IconComponent } from '../Icon';

export type SelectorKey = string | number;

export type SelectorItem<
  T extends string | TranslationString = TranslationString | string
> = {
  id: SelectorKey;
  title: T;
  Icon?: IconComponent;
  action?: ComponentLinkProps;
  aggregateProp?: string;
  reference?: ReferenceProps;
  actionTitle?: string;
  extraComponent?: { forOption: React.ReactNode; forValue?: React.ReactNode };
  hoverReference?: TranslationString;
  className?: string;
};

export type SelectorEntities<
  K extends SelectorKey = SelectorKey,
  // TODO убираем когда определимся
  T extends string | TranslationString = TranslationString | string
> = Record<K, SelectorItem<T>>;

export type SelectorOptions = {
  keys: SelectorKey[];
  entities: SelectorEntities;
};

export enum SelectorType {
  single = 'single',
  multi = 'multi'
}

export enum SelectorStyle {
  gray = 'gray',
  blue = 'blue'
}

export enum SelectorSize {
  m = 'm',
  s = 's'
}

export enum SelectorSortOrder {
  asc = 'asc',
  desc = 'desc'
}
