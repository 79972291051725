import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const ExitIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M20.8625 12.0206C20.8678 12.0138 20.8724 12.0069 20.8777 12.0001C20.8845 11.991 20.8914 11.9819 20.8982 11.9727C20.9035 11.9644 20.9088 11.9553 20.9142 11.9469C20.9195 11.9385 20.9248 11.9302 20.9294 11.9218C20.9339 11.9127 20.9385 11.9036 20.9423 11.8944C20.9461 11.8853 20.9507 11.877 20.9545 11.8678C20.9583 11.8587 20.9613 11.8496 20.9643 11.8405C20.9681 11.8306 20.9712 11.8215 20.9742 11.8116C20.9773 11.8025 20.9788 11.7933 20.9811 11.7834C20.9833 11.7736 20.9864 11.7637 20.9887 11.7538C20.9909 11.7432 20.9925 11.7318 20.994 11.7204C20.9955 11.712 20.997 11.7036 20.9978 11.6945C21.0016 11.6542 21.0016 11.6139 20.9978 11.5744C20.997 11.566 20.9955 11.5569 20.994 11.5485C20.9925 11.5371 20.9909 11.5265 20.9887 11.5151C20.9864 11.5052 20.9841 11.4953 20.9811 11.4855C20.9788 11.4763 20.9765 11.4664 20.9742 11.4573C20.9712 11.4474 20.9674 11.4383 20.9643 11.4284C20.9613 11.4193 20.9583 11.4102 20.9545 11.4011C20.9507 11.3919 20.9461 11.3836 20.9423 11.3745C20.9377 11.3653 20.9339 11.3562 20.9294 11.3471C20.9248 11.3387 20.9195 11.3304 20.9142 11.322C20.9088 11.3136 20.9043 11.3045 20.8982 11.2962C20.8921 11.287 20.8845 11.2779 20.8777 11.2688C20.8724 11.2619 20.8678 11.2551 20.8625 11.2483C20.8495 11.2323 20.8359 11.2179 20.8222 11.2034L17.6766 8.0607C17.4394 7.82352 17.054 7.82352 16.8169 8.0607C16.5797 8.29789 16.5797 8.68332 16.8169 8.9205L18.9233 11.027H10.2878C9.95183 11.027 9.67969 11.2992 9.67969 11.6352C9.67969 11.9712 9.95183 12.2434 10.2878 12.2434H18.9233L16.1973 14.9702C15.9602 15.2074 15.9602 15.5929 16.1973 15.83C16.3159 15.9486 16.4718 16.0079 16.6276 16.0079C16.7834 16.0079 16.9385 15.9486 17.0578 15.83L20.8222 12.0655C20.8359 12.0518 20.8495 12.0366 20.8625 12.0206Z"
        fill="currentColor"
      />
      <path
        d="M14.9448 21.6747H7.75901C6.35727 21.6747 5.21626 20.5344 5.21626 19.1318V4.75925C5.21626 3.35742 6.35651 2.21634 7.75901 2.21634H14.9448C15.2808 2.21634 15.553 1.94418 15.553 1.60817C15.553 1.27216 15.2808 1 14.9448 1H7.75901C5.6868 1 4 2.68615 4 4.75925V19.1318C4 21.2041 5.68604 22.891 7.75901 22.891H14.9448C15.2808 22.891 15.553 22.6189 15.553 22.2829C15.553 21.9469 15.2808 21.6747 14.9448 21.6747Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ExitIcon;
