import { observer } from 'mobx-react';
import { motion, AnimatePresence } from 'framer-motion';
import * as React from 'react';

import { WithMeta, Input } from 'shared/newComponents/form';
import { useVKCreationStore } from 'stores/VKCreationStore';
import FadeScroll from 'shared/newComponents/FadeScroll';
import LoaderSpinner from 'shared/newComponents/animations/LoaderSpinner';
import { LoaderSpinnerSize } from 'shared/newEntities/components/LoaderSpinner';
import Stub from 'shared/newComponents/Stub';

import NoGroupsStub from './NoGroupsStub';
import VKGroup from './VKGroup';

import './Groups.modules.scss';

const Groups: React.FC = () => {
  const vkCreationStore = useVKCreationStore();

  if (
    vkCreationStore.loadingGroupsStage.successfullyLoaded &&
    !vkCreationStore.groups.length
  ) {
    return <NoGroupsStub />;
  }

  const isFilteredGroupsEmpty = !vkCreationStore.filteredGroups.length;

  return (
    <WithMeta
      label={(t) =>
        t('ChannelsModal.ChannelForm.VK.Groups.label', {
          ns: 'newContainers'
        })
      }
      styleName="groups-container"
    >
      <div styleName="groups">
        {vkCreationStore.loadingGroupsStage.isLoading && (
          <div styleName="groups__loader">
            <LoaderSpinner size={LoaderSpinnerSize.md} />
          </div>
        )}
        {!vkCreationStore.loadingGroupsStage.isLoading && (
          <>
            <Input
              styleName="groups__search"
              value={vkCreationStore.search.value}
              onChange={vkCreationStore.search.changeValue}
              placeholder={(t) =>
                t('ChannelsModal.ChannelForm.VK.Groups.search', {
                  ns: 'newContainers'
                })
              }
            />
            <FadeScroll styleName="groups__list">
              {isFilteredGroupsEmpty && (
                <Stub
                  title={(t) =>
                    t('ChannelsModal.ChannelForm.VK.Groups.notFound.label', {
                      ns: 'newContainers'
                    })
                  }
                  description={(t) =>
                    t(
                      'ChannelsModal.ChannelForm.VK.Groups.notFound.description',
                      {
                        ns: 'newContainers'
                      }
                    )
                  }
                />
              )}
              {!isFilteredGroupsEmpty && (
                <AnimatePresence>
                  {vkCreationStore.filteredGroups.map((group) => (
                    <motion.div
                      initial={{
                        height: 'auto',
                        opacity: 1
                      }}
                      exit={{
                        height: 0,
                        opacity: 0,
                        overflow: 'hidden'
                      }}
                      transition={{
                        delay: 0.7
                      }}
                      key={group.id}
                    >
                      <VKGroup item={group} />
                    </motion.div>
                  ))}
                </AnimatePresence>
              )}
            </FadeScroll>
          </>
        )}
      </div>
    </WithMeta>
  );
};

export default observer(Groups);
