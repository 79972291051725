import { TranslationString } from 'shared/entities/localization';

import { TemplateCategory } from '../templates';
import { LoadingStage } from '../meta';
import { ReactionKind } from '../reaction';
import { IField } from '../form';

import { ScenarioKind, ScenarioPreset, ScenarioTag } from './server';

export interface IScenarioBase {
  id: string;
  name: TranslationString | string;
}

export interface IScenarioParams extends IScenarioBase {
  updateId: string;
  readonly channelIds: IField<string[] | null>;
  hasProdVersion: boolean;
  hasChanges: boolean;
  prodEnabled: boolean;
  devEnabled: boolean;
  kind: ScenarioKind;
  preset: ScenarioPreset | null;
  tags: ScenarioTag[] | null;
  dateUpdated: Date | null;
  dateCreated: Date | null;
}

export interface IScenarioBaseModel extends IScenarioParams {
  setHasChanges: (value: boolean) => void;
  publishStage: LoadingStage;
  resetStage: LoadingStage;
  setProdEnabled(value: boolean): Promise<void>;
  setDevEnabled(value: boolean): Promise<void>;
  publish(): Promise<BaseResponse>;
  reset(): Promise<BaseResponse>;
  update(params: Partial<IScenarioParams>): Promise<BaseResponse>;
}

export interface IScenarioCommon extends IScenarioParams {
  kind: ScenarioKind.common;
  template: ITemplateModel | null;
}

export interface IScenarioReaction extends IScenarioParams {
  kind: ScenarioKind.reaction;
  reaction: ReactionKind;
}

export interface IScenarioCommonModel extends IScenarioBaseModel {
  kind: ScenarioKind.common;
  template: ITemplateModel | null;
  remove(): Promise<LoadingStage>;
}

export interface ITemplateModel {
  readonly categories: IField<TemplateCategory[]>;
  visible: boolean;
  description: string;
}

export interface IScenarioReactionModel extends IScenarioBaseModel {
  kind: ScenarioKind.reaction;
  reaction: ReactionKind;
}

export interface IScenarioEdit {
  id: string | null;
  name: string;
  kind: ScenarioKind;
  channelIds: string[] | null;
}

export enum ScenarioClientStatus {
  hasChanges = 'hasChanges',
  hasProdVersion = 'hasProdVersion',
  hasNoProdVersion = 'hasNoProdVersion'
}

export interface IScenarioData {
  id: string;
  name: string;
  blockId: string;
}
