import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const CheckVerifiedIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      svgProps={{ viewBox: '0 0 20 20' }}
      iconSize={IconSize.XM}
      {...props}
    >
      <g clipPath="url(#clip0_4959_293)">
        <path
          d="M7.49996 10L9.16663 11.6667L12.9166 7.91667M6.11143 3.18225C6.78129 3.12879 7.41722 2.86538 7.92868 2.42952C9.12223 1.41238 10.8777 1.41238 12.0712 2.42952C12.5827 2.86538 13.2186 3.12879 13.8885 3.18225C15.4517 3.30699 16.693 4.54829 16.8177 6.11147C16.8712 6.78134 17.1346 7.41726 17.5704 7.92872C18.5876 9.12227 18.5876 10.8777 17.5704 12.0713C17.1346 12.5827 16.8712 13.2187 16.8177 13.8885C16.693 15.4517 15.4517 16.693 13.8885 16.8178C13.2186 16.8712 12.5827 17.1346 12.0712 17.5705C10.8777 18.5876 9.12223 18.5876 7.92868 17.5705C7.41722 17.1346 6.78129 16.8712 6.11143 16.8178C4.54825 16.693 3.30695 15.4517 3.18221 13.8885C3.12875 13.2187 2.86534 12.5827 2.42948 12.0713C1.41234 10.8777 1.41234 9.12227 2.42948 7.92872C2.86534 7.41726 3.12875 6.78134 3.18221 6.11147C3.30695 4.54829 4.54825 3.30699 6.11143 3.18225Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4959_293">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export default CheckVerifiedIcon;
