import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const MiniMapIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.S}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M23.82.96a.45.45 0 00-.4-.07L15.7 3.47l-7.3-1.71h-.05c-.05 0-.1-.01-.14 0L.34 3.5a.44.44 0 00-.34.43v18.76c0 .13.06.26.16.34.08.06.18.1.28.1l.1-.01 7.76-1.73 7.33 1.3h.08l.15-.03 7.86-3.05a.44.44 0 00.28-.41V1.3a.43.43 0 00-.18-.34zM.87 4.28l6.99-1.55v17.86L.87 22.15zm7.86-1.55l6.54 1.54v17.46l-6.54-1.15zm14.4 16.17l-6.99 2.72V4.24l6.99-2.33zm0 0"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MiniMapIcon;
