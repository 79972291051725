import { makeObservable, observable } from 'mobx';

import {
  CabinetManagerRoleType,
  IProjectManagerEdit,
  ProjectManagerRoleType
} from 'shared/entities/manager';
import { IEdit } from 'shared/entities/edit';

export default class ProjectManagerEditModel
  implements IProjectManagerEdit, IEdit
{
  name: string;
  email: string;
  password: string;
  projectId: string;
  role: ProjectManagerRoleType;
  cabinetRole: CabinetManagerRoleType;

  constructor({
    name,
    email,
    password,
    role,
    projectId,
    cabinetRole
  }: IProjectManagerEdit & { cabinetRole: CabinetManagerRoleType }) {
    this.name = name;
    this.email = email;
    this.password = password;
    this.role = role;
    this.projectId = projectId;
    this.cabinetRole = cabinetRole;

    makeObservable(this, {
      name: observable,
      email: observable,
      password: observable,
      role: observable,
      projectId: observable
    });
  }

  toJson() {
    return {
      name: this.name,
      email: this.email,
      password: this.password,
      projects: [
        {
          _id: this.projectId,
          role: this.role
        }
      ],
      cabinet_role: this.cabinetRole
    };
  }

  getCopy(): ProjectManagerEditModel {
    return new ProjectManagerEditModel({
      name: this.name,
      password: this.password,
      email: this.email,
      role: this.role,
      projectId: this.projectId,
      cabinetRole: this.cabinetRole
    });
  }
}
