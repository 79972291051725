import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const TrashIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.M}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M8.94021 4.62H8.76021C8.85921 4.62 8.94021 4.539 8.94021 4.44V4.62H15.7802V4.44C15.7802 4.539 15.8612 4.62 15.9602 4.62H15.7802V6.24H17.4002V4.44C17.4002 3.64575 16.7545 3 15.9602 3H8.76021C7.96596 3 7.32021 3.64575 7.32021 4.44V6.24H8.94021V4.62ZM20.2802 6.24H4.44021C4.04196 6.24 3.72021 6.56175 3.72021 6.96V7.68C3.72021 7.779 3.80121 7.86 3.90021 7.86H5.25921L5.81496 19.6275C5.85096 20.3948 6.48546 21 7.25271 21H17.4677C18.2372 21 18.8695 20.397 18.9055 19.6275L19.4612 7.86H20.8202C20.9192 7.86 21.0002 7.779 21.0002 7.68V6.96C21.0002 6.56175 20.6785 6.24 20.2802 6.24ZM17.2945 19.38H7.42596L6.88146 7.86H17.839L17.2945 19.38Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TrashIcon;
