import { computed, makeObservable } from 'mobx';

import {
  IUserPaymentMethodModel,
  UserPaymentMethodKind
} from 'shared/entities/userPayment';
import { IRootStore } from 'shared/entities/store/rootStore';
import ListModel from 'shared/models/ListModel';
import { NotConnectedProdamusCompany } from 'shared/models/prodamus';
import { CommonUserPaymentMethodModel } from 'shared/models/userPayment';
import { validateLatin, validateIsEmpty } from 'shared/entities/validator';
import { FieldModel } from 'shared/models/form';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';

export default class ProdamusStore extends ComponentLoadingStore {
  readonly accountName: FieldModel = new FieldModel('', [
    validateIsEmpty,
    validateLatin
  ]);
  readonly notConnectedCompanies: ListModel<NotConnectedProdamusCompany> =
    new ListModel<NotConnectedProdamusCompany>();
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;

    this.addCompany = this.addCompany.bind(this);
    this.createMethod = this.createMethod.bind(this);
    this.removeCompany = this.removeCompany.bind(this);

    makeObservable(this, {
      entitiesLength: computed
    });
  }

  get loaded(): boolean {
    return true;
  }

  get canBeLoaded(): boolean {
    return true;
  }

  get methods(): IUserPaymentMethodModel[] {
    return this.rootStore.integrationsStore.userPaymentsStore.methods.items.filter(
      (method) => method.providerKind === UserPaymentMethodKind.prodamus
    );
  }

  get entitiesLength(): number {
    return this.notConnectedCompanies.length + this.methods.length;
  }

  addCompany(): void {
    this.accountName.validate();
    if (this.accountName.isError) {
      return;
    }
    const company = NotConnectedProdamusCompany.fromDefaultParams(
      this.accountName.value,
      this.rootStore
    );

    this.accountName.changeValue('');

    this.notConnectedCompanies.addEntity({ entity: company, key: company.id });
  }

  removeCompany(notConnectedCompanyId: string): void {
    this.notConnectedCompanies.removeEntity(notConnectedCompanyId);
  }

  async createMethod(notConnectedCompanyId: string): Promise<BaseResponse> {
    const company = this.notConnectedCompanies.getEntity(notConnectedCompanyId);

    if (!company) {
      return {
        isError: true
      };
    }

    const response = await company.create();

    if (!response.isError) {
      const method = CommonUserPaymentMethodModel.fromJson(
        response.data,
        this.rootStore
      );

      this.notConnectedCompanies.removeEntity(notConnectedCompanyId);
      this.rootStore.integrationsStore.userPaymentsStore.methods.addEntity({
        entity: method,
        key: method.id
      });

      return {
        isError: false
      };
    }

    return {
      isError: true
    };
  }

  async load(): Promise<BaseResponse> {
    return {
      isError: false
    };
  }

  reset(): void {
    this.notConnectedCompanies.reset();
    this.accountName.changeValue('');
  }
}
