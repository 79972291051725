export enum VerifiedByType {
  file = 'file',
  dns = 'dns',
  meta = 'meta',
  whitelisted = 'whitelisted',
  manual = 'manual'
}

export type DomainBaseServer = {
  _id: string;
  project_id: string;
  domain: string;
  date_created: string;
};

export type UnconfirmedVerifiedDomainParamsServer = {
  verified_by: null;
  verified_at: null;
  meta_content: string;
  dns_content?: string;
  file: {
    content: string;
    path: string;
    name: string;
  };
};

export type UnconfirmedVerifiedDomainServer =
  UnconfirmedVerifiedDomainParamsServer & DomainBaseServer;

export type ConfirmedVerifiedDomainParamsServer = {
  verified_by: VerifiedByType;
  verified_at: string;
};

export type ConfirmedVerifiedDomainServer =
  ConfirmedVerifiedDomainParamsServer & DomainBaseServer;

export type DomainServer =
  | UnconfirmedVerifiedDomainServer
  | ConfirmedVerifiedDomainServer;

export type DomainsListServer = {
  items: DomainServer[];
};
