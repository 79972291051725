import { computed, makeObservable, reaction } from 'mobx';
import * as Sentry from '@sentry/browser';

import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { apiUrls, urls } from 'shared/entities/domain';
import {
  CarrotQuestAuthDataServer,
  CarrotQuestEvent,
  dateMonthKeys,
  i18nKeys
} from 'shared/entities/carrotquest';
import { ISupportPopupStore } from 'shared/entities/store/supportPopupStore';
import { CarrotQuestError } from 'shared/models/errors';
import { ReactionsHandlerStore } from 'stores/reactionsHandlerStore';

import getCurrentCabinetDomain from '../../scripts/getCurrentCabinetDomain';
import waitUntilDomElementNotExists from '../../shared/entities/common/utils/waitUntilDomElementNotExists';

export default class SupportPopupStore
  extends ReactionsHandlerStore
  implements ISupportPopupStore
{
  readonly rootStore: IRootStore;
  readonly carrotLoadingStage: LoadingStageModel = new LoadingStageModel();
  readonly getAuthDataStage: LoadingStageModel = new LoadingStageModel();
  readonly carrotquestAuthStage: LoadingStageModel = new LoadingStageModel();

  constructor(rootStore: IRootStore) {
    super();

    this.rootStore = rootStore;

    makeObservable(this, {
      isCarrotquestModalAvailable: computed
    });
  }

  get isEnabled(): boolean {
    const IS_SUPPORT_POPUP_ENABLED = process.env.IS_PROD
      ? 'true'
      : (process.env.IS_SUPPORT_POPUP_ENABLED as string);

    return String(IS_SUPPORT_POPUP_ENABLED) === 'true';
  }

  get isCarrotquestModalAvailable(): boolean {
    return this.carrotLoadingStage.successfullyLoaded;
  }

  carrotquestAuth = async (): Promise<
    BaseResponse<CarrotQuestAuthDataServer>
  > => {
    if (this.carrotquestAuthStage.isLoading) {
      return { isError: true };
    }

    this.carrotquestAuthStage.loading();

    const getAuthDataResponse = await this.getAuthData();

    if (getAuthDataResponse.isError) {
      this.carrotquestAuthStage.error();
      return { isError: true };
    }

    window.carrotquest.auth(
      getAuthDataResponse.data._id,
      getAuthDataResponse.data.auth_hash
    );

    this.carrotquestAuthStage.success();
    return {
      isError: false,
      data: getAuthDataResponse.data
    };
  };

  track = (event: CarrotQuestEvent): void => {
    if (!window.carrotquest?.track) {
      return;
    }

    window.carrotquest.track(event);
  };

  trackBotRequest = (): void => {
    this.track(CarrotQuestEvent.botRequest);
  };

  openChat = (): void => {
    if (!window.carrotquest) {
      return;
    }

    window.carrotquest.open();
  };

  initialize(): void {
    if (!this.isEnabled) {
      return;
    }

    this.initializeReactions();
    this.initScript();
  }

  private initializeReactions() {
    this.addReaction({
      key: 'isMobileSize',
      reaction: reaction(
        () => this.rootStore.uiStore.isMobileSize,
        this.handleUiChanging
      )
    });

    this.addReaction({
      key: 'menuState',
      reaction: reaction(
        () => this.rootStore.uiStore.menuState.opened,
        this.handleUiChanging
      )
    });

    this.addReaction({
      key: 'location',
      reaction: reaction(
        () => this.rootStore.routerStore.pathname,
        this.handleUiChanging
      )
    });
  }

  private initScript(): void {
    if (!this.isEnabled) {
      return;
    }

    function t(t) {
      return function () {
        // eslint-disable-next-line prefer-rest-params
        window.carrotquestasync.push(t, arguments);
      };
    }
    if ('undefined' == typeof window.carrotquest) {
      const e = document.createElement('script');
      e.onload = this.carrotquestCommon;
      (e.type = 'text/javascript'),
        (e.async = !0),
        (e.src = 'https://cdn.carrotquest.app/api.min.js'),
        document.getElementsByTagName('head')[0].appendChild(e),
        // @ts-ignore
        (window.carrotquest = {}),
        (window.carrotquestasync = []),
        (window.carrotquest.settings = {});
      for (
        let n = [
            'connect',
            'track',
            'identify',
            'auth',
            'onReady',
            'addCallback',
            'removeCallback',
            'trackMessageInteraction'
          ],
          a = 0;
        a < n.length;
        a++
      ) {
        window.carrotquest[n[a]] = t(n[a]);
      }
    }

    this.initSupportPopup();

    // открытие/закрытие окна заново встраивает иконку в дом, необходимо пересчитывать опять положение
    window.carrotquest.addCallback('messenger_opened', this.handleUiChanging);
    window.carrotquest.addCallback('messenger_closed', this.handleUiChanging);
  }

  private getAuthData = async (): Promise<
    BaseResponse<CarrotQuestAuthDataServer>
  > => {
    if (this.getAuthDataStage.isLoading) {
      return { isError: true };
    }

    this.getAuthDataStage.loading();

    const response =
      await this.rootStore.networkStore.api<CarrotQuestAuthDataServer>(
        apiUrls.CARROTQUEST_GET_AUTH_INFO
      );

    if (response.isError) {
      this.getAuthDataStage.error();
      return { isError: true };
    } else {
      this.getAuthDataStage.success();
      return {
        isError: false,
        data: response.data
      };
    }
  };

  private carrotquestIdentify = (data: CarrotQuestAuthDataServer): void => {
    const cabinet = this.rootStore.cabinetStore.entity;
    const cabinetLink = getCurrentCabinetDomain();
    const user = this.rootStore.userStore.user;

    const identifyData = {
      ...data.extra_info,
      cabinet_link: cabinetLink,
      cabinet_name: cabinet?.name,
      cabinet_smart_id: cabinet?.id,
      trial: data.extra_info.cabinet_trial,
      contact_smart_id: data._id,
      $phone: data.extra_info.phone,
      $email: user?.email,
      $name: user?.name
    };

    try {
      window.carrotquest.identify(identifyData);
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          identifyData: JSON.stringify(identifyData)
        });
        Sentry.captureException(new CarrotQuestError());
      });
    }
  };

  private carrotquestCommon = (): void => {
    this.handleUiChanging();
    this.carrotLoadingStage.success();
  };

  initCarrotquestIdentify = async (): Promise<void> => {
    const response = await this.carrotquestAuth();
    if (response.isError) {
      return;
    }

    this.carrotquestIdentify(response.data);
  };

  get supportPopupIndent(): { horizontal: number; vertical: number } {
    const isScenarioPath = this.rootStore.routerStore.matchPath(
      urls.PROJECT.tabs.SCENARIO.mask
    );

    const isAuthPath = this.rootStore.routerStore.matchPath(urls.AUTH.mask);

    const isSubscriptionPath = this.rootStore.routerStore.matchPath(
      urls.SUBSCRIPTION.mask
    );

    if (this.rootStore.uiStore.isMobileSize) {
      if (isSubscriptionPath) {
        return {
          horizontal: 12,
          vertical: 54
        };
      }

      if (isScenarioPath || isAuthPath) {
        return {
          horizontal: 12,
          vertical: 12
        };
      }

      return {
        horizontal: 11,
        vertical: this.rootStore.uiStore.menuState.opened ? 70 : 12
      };
    }

    if (isSubscriptionPath) {
      return {
        horizontal: 12,
        vertical: 54
      };
    }

    if (isAuthPath) {
      return {
        vertical: 25,
        horizontal: 25
      };
    }

    if (isScenarioPath) {
      return {
        vertical: 25,
        horizontal: 102
      };
    }

    const menuHiddenWidth = 56;
    const menuOpenedWidth = 319;

    return {
      vertical: 12,
      horizontal:
        (this.rootStore.uiStore.menuState.opened
          ? menuOpenedWidth
          : menuHiddenWidth) + 12
    };
  }

  private getSupportPopupSize(px: number): number {
    return px - 12;
  }

  private handleUiChanging = async () => {
    const response = await waitUntilDomElementNotExists(
      'carrotquest-messenger-collapsed-container'
    );

    if (response.isError) {
      return;
    }

    const container = response.data;

    container.style.setProperty(
      'margin-left',
      `${this.getSupportPopupSize(this.supportPopupIndent.horizontal)}px`,
      'important'
    );
    container.style.setProperty(
      'margin-bottom',
      `${this.getSupportPopupSize(this.supportPopupIndent.vertical)}px`,
      'important'
    );
  };

  private initSupportPopup() {
    const i18nT = this.rootStore.translationsStore.t<'supportPopup'>;

    window.carrotquest.connect('53761-ce1f6c187b6bc553996264db37', {
      i18n: i18nKeys.reduce((acc, key) => {
        return {
          ...acc,
          [key]:
            key === 'dateMonth'
              ? dateMonthKeys.reduce(
                  (dateMongthAcc, month) => ({
                    ...dateMongthAcc,
                    [month]: i18nT(
                      // @ts-ignore
                      `${key}.${month}`
                    )
                  }),
                  {}
                )
              : // @ts-ignore
                i18nT(key)
        };
      }, {}),
      settings: {
        messenger_position: 'left_bottom',
        messenger_indent: this.supportPopupIndent,
        messenger_mobile_indent: this.supportPopupIndent
      }
    });
  }
}
