import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps } from 'shared/entities/components/Icon';

const WarningIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon {...props} svgProps={{ viewBox: '0 0 19 16' }}>
      <path
        d="M16.4467 15.375H2.55392C1.89017 15.375 1.29562 15.0392 0.96375 14.4764C0.631875 13.9136 0.631875 13.2415 0.96375 12.6786L7.91039 0.898623C8.24226 0.335809 8.83681 0 9.50056 0C10.1643 0 10.7589 0.335809 11.0907 0.898623L18.0374 12.6781C18.3692 13.241 18.3692 13.9131 18.0374 14.4759C17.7055 15.0387 17.1104 15.375 16.4467 15.375ZM9.50056 0.791302C9.12878 0.791302 8.7964 0.979236 8.61051 1.29427L1.66387 13.0743C1.47798 13.3893 1.47798 13.7657 1.66387 14.0807C1.84976 14.3958 2.18214 14.5837 2.55392 14.5837H16.4467C16.8185 14.5837 17.1509 14.3958 17.3367 14.0807C17.5226 13.7657 17.5226 13.3893 17.3367 13.0743L10.3906 1.29427C10.2047 0.979236 9.87234 0.791302 9.50056 0.791302Z"
        fill="currentColor"
      />
      <path
        d="M9.50123 10.2025C9.28338 10.2025 9.10693 10.0255 9.10693 9.80689V3.23513C9.10693 3.01653 9.28338 2.83948 9.50123 2.83948C9.71907 2.83948 9.89552 3.01653 9.89552 3.23513V9.80689C9.89552 10.0255 9.71907 10.2025 9.50123 10.2025Z"
        fill="currentColor"
      />
      <path
        d="M9.50291 13.0902C9.84942 13.0902 10.1303 12.8083 10.1303 12.4606C10.1303 12.1129 9.84942 11.8311 9.50291 11.8311C9.15639 11.8311 8.87549 12.1129 8.87549 12.4606C8.87549 12.8083 9.15639 13.0902 9.50291 13.0902Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default WarningIcon;
