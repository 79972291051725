import { action, computed, makeObservable } from 'mobx';

import {
  ChannelTokenServer,
  ChannelTokenStatus,
  IBaseChannelModel,
  IChannelBase,
  mapRunFromBlockChannelErrorCodeToMessage,
  PubSubChannelEvent,
  PubSubHandleScenarioEventPayload,
  RunFromBlockChannelErrorCode
} from 'shared/entities/channels';
import { IRootStore } from 'shared/entities/store/rootStore';
import { apiUrls } from 'shared/entities/domain';
import { SmartIds } from 'shared/entities/network';
import { Bucket } from 'shared/entities/bucket';
import ListModel from 'shared/models/ListModel';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { FieldModel } from 'shared/models/form';
import { validateIsEmpty } from 'shared/entities/validator';
import { ReactionsHandlerStore } from 'stores/reactionsHandlerStore';

import ChannelTokenModel from './ChannelTokenModel';

export const CHANNEL_REMOVE_TIMEOUT_MS = 4000;

export default class ChannelBaseModel
  extends ReactionsHandlerStore
  implements IBaseChannelModel
{
  readonly id: string;
  readonly externalId: string;
  readonly domain: string;
  readonly name: string;
  readonly linked: boolean;
  readonly scenarioIds: FieldModel<string[]>;
  readonly url: string;
  readonly usersTotal: number;
  readonly tokens: ListModel<ChannelTokenModel>;
  readonly removingStage: LoadingStageModel = new LoadingStageModel();
  readonly runChannelStage: LoadingStageModel = new LoadingStageModel();
  readonly creatingNewTokenStage: LoadingStageModel = new LoadingStageModel();
  readonly rootStore: IRootStore;
  readonly newToken: FieldModel<string> = new FieldModel<string>('', [
    validateIsEmpty
  ]);
  readonly photoUrl: string | null;
  readonly rootChatId: string;
  readonly removingTimeout: FieldModel<ReturnType<typeof setTimeout> | null> =
    new FieldModel<ReturnType<typeof setTimeout> | null>(null);

  constructor({
    id,
    externalId,
    domain,
    name,
    linked,
    rootStore,
    scenarioIds,
    url,
    usersTotal,
    photoUrl,
    tokens,
    rootChatId
  }: Omit<IChannelBase, 'tokens' | 'scenarioIds'> & {
    rootStore: IRootStore;
    tokens: ListModel<ChannelTokenModel>;
    scenarioIds: FieldModel<string[]>;
  }) {
    super();

    this.id = id;
    this.externalId = externalId;
    this.domain = domain;
    this.name = name;
    this.linked = linked;
    this.rootStore = rootStore;
    this.scenarioIds = scenarioIds;
    this.url = url;
    this.tokens = tokens;
    this.usersTotal = usersTotal;
    this.photoUrl = photoUrl;
    this.rootChatId = rootChatId;

    this.removeToken = this.removeToken.bind(this);
    this.createToken = this.createToken.bind(this);

    makeObservable(this, {
      remove: action.bound,
      handleChangeScenarioIds: action,
      update: action,
      runFromBlock: action,
      hasNoTokens: computed,
      hasTokenErrors: computed,
      hasManyTokenErrors: computed
    });
  }

  get hasManyTokenErrors(): boolean {
    const errors = this.tokens.items.reduce(
      (acc, token) => (token.status !== ChannelTokenStatus.ok ? acc + 1 : acc),
      0
    );

    return errors > 1;
  }

  get hasTokenErrors(): boolean {
    return this.tokens.items.some(
      (token) => token.status !== ChannelTokenStatus.ok
    );
  }

  get hasNoTokens(): boolean {
    return !this.tokens.length;
  }

  addToken(token: ChannelTokenModel): void {
    this.tokens.addEntity({
      entity: token,
      key: token.id
    });
  }

  async createToken(): Promise<BaseResponse> {
    this.newToken.validate();

    if (this.creatingNewTokenStage.isLoading || this.newToken.isError) {
      return {
        isError: true
      };
    }

    this.creatingNewTokenStage.loading();

    const response = await this.rootStore.networkStore.api<ChannelTokenServer>(
      apiUrls.CHANNELS_ADD_TOKEN,
      {
        method: 'POST',
        data: {
          channel_id: this.id,
          token: this.newToken.value
        }
      }
    );

    if (!response.isError) {
      const token = ChannelTokenModel.fromJson({
        raw: response.data,
        rootStore: this.rootStore,
        channelId: this.id
      });
      this.tokens.addEntity({
        entity: token,
        key: token.id
      });
      this.newToken.reset();
      this.creatingNewTokenStage.success();
    } else {
      this.creatingNewTokenStage.error();
    }

    return {
      isError: response.isError
    };
  }

  async removeToken(id: string): Promise<BaseResponse> {
    const token = this.tokens.getEntity(id);

    if (!token) {
      return {
        isError: true
      };
    }

    const response = await token.remove();

    if (!response.isError) {
      this.tokens.removeEntity(id);
      return {
        isError: false
      };
    }

    return {
      isError: true
    };
  }

  runFromBlock = async ({
    blockId,
    bucket
  }: {
    blockId: string;
    bucket: Bucket;
  }): Promise<void> => {
    if (this.runChannelStage.isLoading) {
      return;
    }

    this.runChannelStage.loading();

    const { isError } = await this.rootStore.networkStore.api<
      {},
      RunFromBlockChannelErrorCode
    >(apiUrls.USER_CHAT_STATES_RUN_FROM_BLOCK, {
      method: 'POST',
      data: {
        block_id: blockId,
        channel_id: this.id,
        [SmartIds.bucket]: bucket
      },
      errorsMap: mapRunFromBlockChannelErrorCodeToMessage
    });

    isError ? this.runChannelStage.error() : this.runChannelStage.success();
  };

  handleChangeScenarioIds = async (scenarioId: string): Promise<void> => {
    if (this.scenarioIds.value.find((id) => id === scenarioId)) {
      this.removeScenarioIdWithSaving(scenarioId);
    } else {
      this.addScenarioIdWithSaving(scenarioId);
    }
  };

  addScenarioIdWithSaving = async (scenarioId: string): Promise<void> => {
    const prevValue = this.scenarioIds.value;

    this.addScenarioId(scenarioId);
    const response = await this.update({ prevScenariosIds: prevValue });

    if (response.isError) {
      this.removeScenarioId(scenarioId);
    } else {
      this.sendAddingScenarioIdEvent(scenarioId);
    }
  };

  removeScenarioIdWithSaving = async (scenarioId: string): Promise<void> => {
    const prevValue = this.scenarioIds.value;

    this.removeScenarioId(scenarioId);
    const response = await this.update({ prevScenariosIds: prevValue });

    if (response.isError) {
      this.addScenarioId(scenarioId);
    } else {
      this.sendRemovingScenarioIdEvent(scenarioId);
    }
  };

  initRemoving = (): void => {
    const timeout = setTimeout(this.remove, CHANNEL_REMOVE_TIMEOUT_MS);
    this.removingTimeout.changeValue(timeout);
  };

  cancelRemoving = (): void => {
    if (!this.removingTimeout.value) {
      return;
    }

    clearTimeout(this.removingTimeout.value);
    this.removingTimeout.changeValue(null);
  };

  async remove(): Promise<BaseResponse> {
    if (this.removingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.removingStage.loading();

    this.rootStore.channelsStore.remove(this.id);

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.CHANNELS_REMOVE,
      {
        method: 'POST',
        data: {
          _id: this.id
        }
      }
    );

    isError ? this.removingStage.error() : this.removingStage.success();

    return {
      isError
    };
  }

  update = async ({
    prevScenariosIds
  }: {
    prevScenariosIds: string[];
  }): Promise<BaseResponse> => {
    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.CHANNELS_UPDATE,
      {
        method: 'POST',
        data: {
          channel_id: this.id,
          scenario_ids: this.scenarioIds.value
        }
      }
    );

    if (isError) {
      this.scenarioIds.changeValue(prevScenariosIds);
    }

    return {
      isError
    };
  };

  private addScenarioId(scenarioId: string): void {
    const scenarioIds = this.scenarioIds.value;
    const newScenariosId = [...scenarioIds, scenarioId];
    this.scenarioIds.changeValue(newScenariosId);
  }

  private removeScenarioId(scenarioId: string): void {
    const filteredScenarioIds = this.scenarioIds.value.filter(
      (id) => id !== scenarioId
    );

    this.scenarioIds.changeValue(filteredScenarioIds);
  }

  private sendAddingScenarioIdEvent(scenarioId: string) {
    const payload: PubSubHandleScenarioEventPayload = {
      channelId: this.id,
      scenarioId
    };
    PubSub.publish(PubSubChannelEvent.addScenarioId, payload);
  }

  private sendRemovingScenarioIdEvent(scenarioId: string) {
    const payload: PubSubHandleScenarioEventPayload = {
      channelId: this.id,
      scenarioId
    };
    PubSub.publish(PubSubChannelEvent.removeScenarioId, payload);
  }
}
