/**
 * Форматирует размер
 * @param size - размер в байтах
 * @return {string}
 */
const beautifySize = (size: number): string => {
  if (size < 1024) {
    return `${size.toFixed(2)} B`;
  }

  const kbSize = size / 1024;

  if (kbSize < 1024) {
    return `${kbSize.toFixed(2)} KB`;
  }

  const mbSize = kbSize / 1024;

  return `${mbSize.toFixed(2)} MB`;
};

export default beautifySize;
